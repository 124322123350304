import { Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ApiService , AlertService } from '../../../../services/index';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;;
import Swal from 'sweetalert2'
import { jqxTreeComponent } from '../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../environments/environment"
import 'datatables.net';
import 'datatables.net-bs4';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    @ViewChild('myTree') myTree: jqxTreeComponent;
    @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;

    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    userData: any = {}//JSON.parse(localStorage.getItem("userData"));
    customerData: any = {}
    customerId: any = null
    checkTreeArray: any = null
    checkCustomerArray: any =  JSON.parse(localStorage.getItem("customerId")) || [];
    manageCustomerArray: any = this.customerData;
    dropdownList: any = []// this.userData.customers;
    selectedItems: any = this.customerData;
    dropdownSettings: any = {};
    dataAdapter: any = [];
    source: any = {};
    data: any = [];
    records: any = [];
    permissionData: any = [];
    isPermission:number=0;
    disableTreeArray: any = [];
    logo : any = "";
    userImage = "assets/img/favicon.ico";
    searchResult  : any = [];
    dataTable: any ;
    searchkey : any;

    intervalObj: any;
    intervalObjToken: any;
    tierDisable:any = false;
    customerDisable:any = false;
    IsAdvancedReportPermission: any = false;

    constructor(
      private router: Router,
      private apiService: ApiService,
      private alertService: AlertService,
      private chRef: ChangeDetectorRef,
      private http:Http,
      private ck:CookieService
    ) {
        this.permissionData = ApiService.permission;
        this.isPermission = ApiService.isPermission;


        if (ApiService.isPermission == 0 || ApiService.isPermission == undefined) {
            ApiService.permission = (localStorage.getItem("permission")?JSON.parse(localStorage.getItem("permission")):'');

            if(this.permissionData){
                this.permissionData = ApiService.permission;
            }else{
                localStorage.clear();
            }

        }

    }

  DateFormat(startDate){
    if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
      return "";
    }
  }


  ngOnInit() {

    // Capture the Enter Key press event for Global Search
    var _this = this;
    $(document).on('keypress',function(e) {
      if(e.which == 13) {
        _this.globalSearch()
      }
    })

    $('.jqx-tree-dropdown-root').scroll(function(e){
      e.stopPropagation();
    })
    this.router.events.subscribe((val)=>{
        window.scrollTo(0,0);
        if(val instanceof NavigationStart) {
            this.treeItemDisable()
          let user = JSON.parse(localStorage.getItem("userData"))
          if (user && user.clients && user.clients.length > 0) {
              this.logo = user.clients[0].clientInnerLogoPath ? this.apiService.imageBasePath + user.clients[0].clientInnerLogoPath : 'assets/img/cdm_logo_w.png'
              if(user.imageLocation){
                this.userImage =  this.apiService.imageBasePath + user.imageLocation;
              }else{
                this.userImage =  "assets/img/favicon.ico";
              }
            }

            if(user){
                this.userData = user;
                this.customerData = (localStorage.getItem("customeruserData") ? JSON.parse(localStorage.getItem("customeruserData")) : []);
                this.customerId = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
                this.checkTreeArray = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
                this.manageCustomerArray = this.customerData;
                this.dropdownList = this.userData.customers;
                this.selectedItems = this.customerData;
                if(this.userId != localStorage.getItem("userId")){
                    // this.getTierList();
                    this.userId = localStorage.getItem("userId")
                }

            }
            if (ApiService.isPermission == 0 || ApiService.isPermission == undefined) {
                ApiService.permission = (localStorage.getItem("permission")?JSON.parse(localStorage.getItem("permission")):'');

                if(this.permissionData){
                    this.permissionData = ApiService.permission;
                }else{
                    localStorage.clear();
                }

            }
          }


          /// for disable tier dropdown
          if (val instanceof NavigationEnd) {
            // console.log("route change 2",val)
            let arr1 = val.url.split('/')
            if (arr1.length > 1) {
                if (arr1[1] == 'customers') {
                    this.tierDisable = true;
                    this.customerDisable = false;						
                }
                else if (arr1[1] == 'customerdetail') {
                    this.tierDisable = true;
                    this.customerDisable = false;
                } 
                // else if (arr1[1] == 'users') {
                //     this.customerDisable = true;
                // } 
                else if (arr1[1] == 'ticket') {
                    this.tierDisable = false;
                    this.customerDisable = false;
                }
                else if (arr1[1] == 'users') {
                    this.tierDisable = false;
                    this.customerDisable = true;
                }
                else if (arr1[1] == 'role-management') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                } 
                else if (arr1[1] == 'template') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (arr1[1] == 'company-profile') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (arr1[1] == 'user-profile') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (arr1[1] == 'roles') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (arr1[1] == 'sites') {
                    this.tierDisable = false;
                    this.customerDisable = true;
                }else if (arr1[1] == 'assets') {
                    this.tierDisable = false;
                    this.customerDisable = true;
                }else if (arr1[1] == 'notifications') {
                    this.tierDisable = false;
                    this.customerDisable = true;
                }else if(arr1[1] == 'addreport'){
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if(arr1[1] == 'adhocreport'){
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if(arr1[1] == 'tier'){
                    this.tierDisable = true;
                    this.customerDisable = true;
                }
                else if (arr1[1] == 'site-management') {
                    this.customerDisable = true;
                } 
                else {
                    this.tierDisable = false;
                    this.customerDisable = false;
                }
            } else {
                this.tierDisable = false;
                this.customerDisable = false;				
            }
            
        }
    })
    // onrefresh check url and disable dropdown
    let Urlarr = this.router.url.split('/')
        // console.log(Urlarr,"Urlarr");
        
			if (Urlarr.length>1) {

                if (Urlarr[1] == 'customers') {
                    this.tierDisable = true;
                    this.customerDisable = false;							
                }
                else if (Urlarr[1] == 'customerdetail') {
                    this.tierDisable = true;
                    this.customerDisable = false;
                } 
                else if (Urlarr[1] == 'users') {
                    this.tierDisable = false;
                    this.customerDisable = true;
                }
                else if (Urlarr[1] == 'ticket') {
                    this.customerDisable = false;
                    this.tierDisable = false;
                }else if (Urlarr[1] == 'role-management') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                } 
                else if (Urlarr[1] == 'template') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (Urlarr[1] == 'company-profile') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (Urlarr[1] == 'user-profile') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (Urlarr[1] == 'roles') {
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (Urlarr[1] == 'sites') {
                    this.tierDisable = false;
                    this.customerDisable = true;
                }else if (Urlarr[1] == 'assets') {
                    this.tierDisable = false;
                    this.customerDisable = true;
                }else if (Urlarr[1] == 'notifications') {
                    this.tierDisable = false;
                    this.customerDisable = true;
                }else if(Urlarr[1] == 'addreport'){
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if(Urlarr[1] == 'adhocreport'){
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if(Urlarr[1] == 'tier'){
                    this.tierDisable = true;
                    this.customerDisable = true;
                }else if (Urlarr[1] == 'site-management') {
                    this.customerDisable = true;
                } 
                else {
                    this.tierDisable = false;
                    this.customerDisable = false;
                }

			} else {
                this.tierDisable = false;
                this.customerDisable = false;						
			}

    let user = JSON.parse(localStorage.getItem("userData"))

    if (!user) {
        setTimeout(()=>{
          this.router.navigate(['/login']);
        //    this.alertService.sweetMessage('error',"Please login!");
        },1000)
        this.router.navigate(["/login"])
    }
    if (user && user.clients && user.clients.length > 0) {
        this.logo = user.clients[0].clientInnerLogoPath ? this.apiService.imageBasePath + user.clients[0].clientInnerLogoPath : 'assets/img/cdm_logo_w.png'
        this.IsAdvancedReportPermission = user.clients[0] ? user.clients[0].isAdvanceRuntime : false;
        // this.userImage = user.imageLocation ? this.apiService.imageBasePath + user.imageLocation : "assets/img/avatar-1.jpg"
        // this.userImage =  "assets/img/avatar-1.jpg"
        // console.log(user.imageLocation);
        if(user.imageLocation){
          this.userImage =  this.apiService.imageBasePath + user.imageLocation;
        }else{
          this.userImage =  "assets/img/favicon.ico";
        }

        // console.log(user.imageLocation)
    }
    if(user){
        this.userData = user;
        this.customerData = (localStorage.getItem("customeruserData") ? JSON.parse(localStorage.getItem("customeruserData")) : []);
        this.customerId = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
        this.checkTreeArray = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
        this.manageCustomerArray = this.customerData;
        this.dropdownList = this.userData.customers;
        this.selectedItems = this.customerData;
        this.getTierList();
        setTimeout(() => {
            var header_height = $("header.main-header").height();
            $("body").removeClass('login').css({
                "padding-top": header_height
            });
        }, 200);
    }



    $(document).ready(function() {
        $("td .dropdown-toggle").click(function(e) {
            $('tr').removeClass('active');
            $(this).parents('tr').toggleClass('active');
        });
        $(document).click(function(e) {
            $('tr').removeClass('active');
        });

        $('.slide-main-menu li.has-chidren > a').on('click', function() {
            $(this).parent("li.has-chidren").toggleClass("dropdown-active");
            $(this).next("ul.menu-dropdown").slideToggle();
        });
        $('.main > a').on('click', function() {
            $('.sm-toggle').removeClass('smt-active');
            $('body').removeClass('body-smt-active');
            $('.slide-menu-wrap').removeClass('slide-menu-active');
        });
        var header_height = $("header.main-header").height();
        $("body").removeClass('login').css({
            "padding-top": header_height
        });
        $(window).resize(function(){
            var header_height = $("header.main-header").height();
            $("body").removeClass('login').css({
                "padding-top": header_height
            });
        });

        $('.menu-item.has-chidren').click(function(){
            $('.slide-menu-inner').scrollTop($('.slide-menu-inner')[0].scrollHeight);
        });
    });

    var data = "Selected"

    this.dropdownSettings = {
        singleSelection: false,
        idField: 'customerId',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };

        this.intervalObj = setInterval(()=>{
            this.getUserInfo();
        },300000)
   
        this.intervalObjToken = setInterval(()=>{
            this.refreshtoken();
        },900000)// 1800000 (30 min)
        // },900000)// 1800000 (30 min)
        // document.cookie = "refresh_token="+dataObj.refresh_token+"; expires=" + date;
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        clearInterval(this.intervalObj);
        clearInterval(this.intervalObjToken);
        // console.log("Interval close");
        
    }

    refreshtoken(){
        // console.log("refresh_token");
        
        let token = JSON.parse(localStorage.getItem('Token'))
        let lasttime = this.ck.get('t11')
        // console.log(lasttime,moment().diff(moment(new Date(parseInt(lasttime)))),new Date(parseInt(lasttime)));
        let diff = moment().diff(moment(new Date(parseInt(lasttime))))
        if (!token) {
            return
        }
        if(diff/1000 > 3600 /* 3600 */){
            let isLogin = this.ck.get('islogin')
            console.log(isLogin, "isLogin");
            
            if (isLogin) {
                
            }else{
                localStorage.removeItem('Token')            
                localStorage.clear();
                $('.modal.show').modal('hide') 
                // this.alertService.sweetMessage('error',"Session timed out.");
                Swal({
                    position: 'top-end',
                    type: 'error',
                    title: 'Session timed out.',
                    showConfirmButton: false,
                    timer: 10000
                })
                console.log("redirect-login");
                
                this.router.navigate(['/login']);
                return
            }
        }
        // if(diff/1000 > 1200){
            let obj ={
                refresh_token: token.refresh_token,
                grant_type: "refresh_token"
            }
            // console.log(token.access_token, "token");
            

            // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  uncomment for refresh token
            // -------------------------------------------------------------start -----------------------
           /* 
            // this.apiService.refreshToken('userauth/oauth/token',obj).subscribe(
            //     data => {
            //         // console.log(data.access_token, "tokenc", data);

            //         // this.apiService.getUserInfoData('user/updateSession?userId='+this.userId+'&token='+token.access_token+'&newToken='+data.access_token).subscribe(
            //         //     dataToken => {        
                            
            //         //     },
            //         //     errorToken => {
            //         //         console.log(errorToken);
                            
            //         //     }
            //         // );
            //         // setTimeout(() => {
            //             localStorage.setItem("Token", JSON.stringify(data));

            //             document.cookie = "refresh_token=;"//+data.refresh_token
            //             document.cookie = "refresh_token="+data.refresh_token+'; path=/; expires=' + new Date(moment().add(30, 'days')).toUTCString();
            //         // }, 500);
                    

            //     },
            //     error => {
            //         console.log(error);
                    
            //     }
            // ); 
            */
            // -------------------------------------------------------------end -----------------------
        // }
        
    }

    getUserInfo(){


        let token = JSON.parse(localStorage.getItem('Token'));

        /* ---------------------------- remove as per 0074192 and 75062----- */
        // if (token && token.access_token) {
        //     fetch(this.apiService.AppUrlService+'/user/getUserInfo', {method:"GET",headers :{ 'Content-Type': 'application/json',  /*  mode: 'no-cors' */  'Authorization': 'bearer '+token.access_token}})
        //         .then((response) => {                    
        //             return response.json();
        //         })
        //         .then((myJson) => {
        //             console.log(myJson);
        //             if (myJson.statusCode == 100) {
        //                 ApiService.permission = myJson.response.permissions;
        //                 if(ApiService.permission){
        //                     ApiService.isPermission = 1;
        //                 }
        //                 localStorage.setItem("permission",JSON.stringify(myJson.response.permissions));
    
        //                 localStorage.setItem("userData", JSON.stringify(myJson.response));
        //                 localStorage.setItem("userId", myJson.response.userId);
        //                 localStorage.setItem("clientId", myJson.response.defaultClientId);
        //                 localStorage.setItem("clientCode", myJson.response.clients[0].clientCode);
        //                 return myJson;
        //                 // localStorage.clear();
        //                 // // localStorage.removeItem("Token");
        //                 // routeNavigate.navigate(['/login']);
        //             }
        //         }).catch((error)=>{
        //             console.log(error.status, error.statusCode, error.errorStatus, error);
        //             return error
        //         });
        // }
        

        //  this.apiService.getUserInfoData('user/getUserInfo')
    //      .subscribe(
	// 	    	data => {
	// 			if(data.statusCode == 100){
					
	// 				ApiService.permission = data.response.permissions;
    //                 if(ApiService.permission){
    //                 	ApiService.isPermission = 1;
    //                 }
    //                 localStorage.setItem("permission",JSON.stringify(data.response.permissions));

	// 				localStorage.setItem("userData", JSON.stringify(data.response));
	// 				localStorage.setItem("userId", data.response.userId);
	// 				localStorage.setItem("clientId", data.response.defaultClientId);
	// 				localStorage.setItem("clientCode", data.response.clients[0].clientCode);
	// 				// this.router.navigate(['/dashboard']);
	// 				// Swal({
	// 				//   position: 'top-end',
	// 				//   type: 'success',
	// 				//   title: 'Login Successful',
	// 				//   showConfirmButton: false,
	// 				//   timer: 1500
	// 				// })
	// 			}else if(data.statusCode == 105){
	// 			 var msg = data.responseMessage;
	// 		 }else{

	// 		 }
    // 	},
    // 	error => {
    //     // Swal({
    //     //   position: 'top-end',
    //     //   type: 'error',
    //     //   title: 'Something went wrong, please try again later',
    //     //   showConfirmButton: false,
    //     //   timer: 1500
    //     // })
    // 	}
	// );
  	}



    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
        this.myDropDownButton.setContent(dropDownContent);

        setTimeout(() => {
            $(document).ready(function(){
                $('.menu-item.has-chidren:last-child').click(function(){
                    // $('.slide-menu-inner').scrollTop($('.slide-menu-inner')[0].scrollHeight);
                    setTimeout(() => {
                        // var objDiv = document.getElementById("mainMenuHeader");
                        // // console.log("objDiv.scrollHeight", objDiv.scrollHeight);
                        // objDiv.scrollTop = objDiv.scrollHeight;   ////dropdown-active
                        // $("#mainMenuHeader").scrollTop($(".menu-item.has-chidren:last-child")[0].scrollHeight + $("#mainMenuHeader").height());
                        // console.log($(".menu-item.has-chidren:last-child")[0].scrollHeight, $("#mainMenuHeader").height());
                        
                        // $("#mainMenuHeader").scrollTop($(".menu-item.has-chidren:last-child")[0].scrollHeight + $("#mainMenuHeader").height());
                        $("#mainMenuHeader").animate({ scrollTop: $(".menu-item.has-chidren:last-child")[0].scrollHeight + $("#mainMenuHeader").height() - 40 }, 2000);
                    }, 500);
                });
                $('.menu-item.has-chidren:nth-last-child(2)').click(function(){
                    // $('.slide-menu-inner').scrollTop($('.slide-menu-inner')[0].scrollHeight);
                    setTimeout(() => {
                        // var objDiv = document.getElementById("mainMenuHeader");
                        // // console.log("objDiv.scrollHeight", objDiv.scrollHeight);
                        // objDiv.scrollTop = objDiv.scrollHeight;   ////dropdown-active
                        // $("#mainMenuHeader").scrollTop($(".menu-item.has-chidren:last-child")[0].scrollHeight + $("#mainMenuHeader").height());
                        // console.log($(".menu-item.has-chidren:nth-last-child(2)")[0].scrollHeight, $("#mainMenuHeader").height());
                        // $("#mainMenuHeader").scrollTop($(".menu-item.has-chidren:nth-last-child(2)")[0].scrollHeight + $("#mainMenuHeader").height());
                        $("#mainMenuHeader").animate({ scrollTop: $(".menu-item.has-chidren:nth-last-child(2)")[0].scrollHeight + $("#mainMenuHeader").height()- 40 }, 2000);
                    }, 500);
                });
            });
        }, 1000);

        this.treeItemDisable()

    }

    onOpen(){
        if (!this.myDropDownButton.isOpened()) {
            this.myDropDownButton.open()
        }
        $('div.dd > jqxtree > div.jqx-widget.jqx-widget-content.jqx-tree').css({position:'absolute'})
        setTimeout(() => {
            this.treeItemDisable();
            this.checkedCheckboxOnLoad();
        }, 500);
    }

    /* Reset header Search Record */
    resetSearch() {
      // if(this.checkCustomerArray.length > 0 || this.checkTreeArray.length > 0){
        localStorage.removeItem('tierId');
        localStorage.removeItem('customerId');
        localStorage.removeItem('customeruserData');
        location.reload();
      // }
    }

    /* Get user permission */
    getPermissionData() {
        this.apiService.getAll('user/getPermissionsByUserId?userId=' + this.userId).subscribe(
          data => {
            if (data.statusCode == 100) {
                ApiService.permission = data.response;
                /*Object.keys(data.response).forEach(key => {
                    ApiService.permission[data.response[key]['permissionGroup']] = true;
                });*/
                this.permissionData = ApiService.permission;
            }else{
                ApiService.permission = {};
                this.permissionData = ApiService.permission;
            }
            
          },
          error => {
            ApiService.permission = {};
            this.permissionData = ApiService.permission;
            this.alertService.sweetMessage('error',"Something went wrong, please try again.");
          }
        );
    }

    /* Get tier list */
    getTierList() {
        var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
        /*if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }*/
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.data = data.responseList ? data.responseList : [];
                    var self = this;

                    Object.keys(this.data).forEach(key => {
                        if (this.data[key]['flag'] == false) {
                            this.disableTreeArray.push(this.data[key]['tierId']);
                        }
                    });
                    this.source = {
                        datatype: 'json',
                        datafields: [{
                            name: 'tierId'
                        },
                        {
                            name: 'parentTierId'
                        },
                        {
                            name: 'tierName'
                        },
                        ],
                        id: 'tierId',
                        localdata: this.data
                    };
                    this.dataAdapter = new jqx.dataAdapter(this.source, {
                        autoBind: true
                    });
                    this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{
                        name: 'tierName',
                        map: 'label'
                    }, {
                        name: 'tierId',
                        map: 'id'
                    }]);
                       console.log(this.disableTreeArray);
                       
                    setTimeout(function() {
                        self.treeItemDisable();
                        self.checkedCheckboxOnLoad();
                    }, 3000);
                } else {
                    let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> No Record Found</div>';
                    this.myDropDownButton.setContent(dropDownContent);
                   /* Swal({
                        position: 'top-end',
                        type: 'error',
                        title: data.responseMessage,
                        showConfirmButton: false,
                        timer: 1500
                    })*/
                }
            },
            error => {
                // this.alertService.sweetMessage('error',"Something went wrong, please try again.");
            }
            );
    }

    treeItemDisable() {
        console.log(this.disableTreeArray);
        
        Object.keys(this.disableTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.disableTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.disableItem(checkedItem);
        });
    }

    /* Handle tier tree chacked unchecked Event */
    CheckChange(event) {
        if (event.args.checked == true) {
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist == '-1') {
                this.checkTreeArray.push(event.args.element.id);
                localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
            }
        } else if (event.args.checked == false) {
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist != '-1') {
                this.checkTreeArray.splice(exist, 1);
                localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
            }
        }
        if (this.checkTreeArray.length > 0) {
            let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"><span class="textRight">+' + this.checkTreeArray.length + ' </span></div>';
            this.myDropDownButton.setContent(dropDownContent);
        } else {
            let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
            this.myDropDownButton.setContent(dropDownContent);
        }

    }

    /* check tree checkbox */
    checkedCheckboxOnLoad() {
        Object.keys(this.checkTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.checkTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.checkItem(checkedItem, true);
        });
    }

    /* On Item Select Customer */
    onItemSelect(item: any) {

        if (this.checkCustomerArray == null || this.checkCustomerArray.length > 0) {
            var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
            if (exist == '-1') {
                this.checkCustomerArray.push(item.customerId.toString());
                localStorage.setItem("customerId", JSON.stringify(this.checkCustomerArray));
                this.manageCustomerArray.push(item);
                localStorage.setItem("customeruserData", JSON.stringify(this.manageCustomerArray));
            }
        } else {
            this.checkCustomerArray.push(item.customerId.toString());
            localStorage.setItem("customerId", JSON.stringify(this.checkCustomerArray));
            this.manageCustomerArray.push(item);
            localStorage.setItem("customeruserData", JSON.stringify(this.manageCustomerArray));
        }
    }

    /* On Item DeSelect Customer */
    onItemDeselect(item: any) {
        var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
        //var exist1 = this.manageCustomerArray.indexOf(item);
        if (exist != '-1') {
            this.checkCustomerArray.splice(exist, 1);
            localStorage.setItem("customerId", JSON.stringify(this.checkCustomerArray));
            this.manageCustomerArray.splice(exist, 1);
            localStorage.setItem("customeruserData", JSON.stringify(this.manageCustomerArray));
        }
        // localStorage.removeItem('customerId');
        // localStorage.removeItem('customeruserData');
    }
    /* On All Select Customer */
    onSelectAll(items: any) {
        this.checkCustomerArray = [];
        this.manageCustomerArray = [];
        Object.keys(items).forEach(key => {
            this.checkCustomerArray.push(items[key]['customerId'].toString());
            this.manageCustomerArray.push(items[key]);
        });
        localStorage.setItem("customerId", JSON.stringify(this.checkCustomerArray));
        localStorage.setItem("customeruserData", JSON.stringify(this.manageCustomerArray));
    }
    /* On All DeSelect Customer */
    onDeSelectAll() {
        this.checkCustomerArray = [];
        this.manageCustomerArray = [];
        localStorage.removeItem('customerId');
        localStorage.removeItem('customeruserData');
    }
    setCustomer() {
      // if(this.checkCustomerArray.length > 0 || this.checkTreeArray.length > 0){
        location.reload();
      // }
    }


/***************************
  Method for Global Search
***********************************/

    globalSearch(){
      if(this.searchkey) {
        var keyValue = JSON.parse(JSON.stringify(this.searchkey));
        this.searchkey = ''
        if(keyValue.trim()){
          this.router.navigate(['/global-search',keyValue]);
	      }
      }
    }



    logout() {
        this.apiService.logout('userauth/oauth/revokeToken').subscribe(
            data => {
               if(data.status == 200) {
                    localStorage.clear();
                    $("body").removeClass('body-smt-active');
                    this.router.navigate(['/login']);
                    this.ck.delete('islogin');
                    document.cookie = "islogin=;"
                    document.cookie = "islogin=;"
                    this.ck.delete('refresh_token');
                    this.ck.deleteAll()
                } else {
                }
            },
            error => {
                //this.alertService.error(error.message?error.message:'Something went wrong please try again');
            }
            );
    }


    toggleSidebar() {
        $('.sm-toggle').toggleClass('smt-active');
        $('.slide-menu-wrap').toggleClass('slide-menu-active');
        $('body').toggleClass('body-smt-active');
    }

}
