import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService , AlertService}  from '../../../../../services/index';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})

export class TemplateComponent implements OnInit {
dataTable: any;
data = {
  "chart": {
       "caption": "Split of Revenue by Product Categories",
        "subCaption": "Last year",
        "numberPrefix": "$",
        "bgColor": "#ffffff",
        "startingAngle": "310",
        "showLegend": "1",
        "defaultCenterLabel": "Total revenue: $64.08K",
        "centerLabel": "Revenue from $label: $value",
        "centerLabelBold": "1",
        "showTooltip": "0",
        "decimals": "0",
        "showPlotBorder": "0",
        "showShadow": "0",
        "use3DLighting": "0",
        "plotFillAlpha":"100",
        "theme": "fusion"
  },
  "data": [
    {
      "label": "Ice Cream Sandwich",
      "value": "1000",
      "color": "#000000"
    },
    {
      "label": "Jelly Bean",
      "value": "5300"
    },
    {
      "label": "Kitkat",
      "value": "10500"
    },
    {
      "label": "Lollipop",
      "value": "18900"
    },
    {
      "label": "Marshmallow",
      "value": "17904"
    }
  ]
};
 width = 600;
      height = 400;
      type = 'doughnut2d';
      dataFormat = 'json';
      dataSource = this.data;
      permissionData:any=[];

  clientCode : any = '';
  templateList:any = []

  constructor(private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
   }


ngOnInit() {
  this.clientCode = this.alertService.getClientCode();
  this.getTemplateList()
  }

  getTemplateList(){
    
    this.apiService.getAll('template/getAllTemplateList').subscribe(
      data => {
        if(data.statusCode == 100){
          this.templateList = data.responseList ? data.responseList : [];
          if ($.fn.DataTable.isDataTable('#data_table')){
            $('#data_table').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#data_table');
          this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [2] }]});


        }else{
          this.templateList = [];
          if ($.fn.DataTable.isDataTable('#data_table')){
            $('#data_table').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#data_table');
          this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [2] }]});

        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
  );
  }

}
