import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService } from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $: any;
declare var jQuery: any;
declare var d3: any, moment: any;
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../../environments/environment"


@Component({
  selector: 'app-client-setting',
  templateUrl: './client-setting.component.html',
  styleUrls: ['./client-setting.component.css']
})

export class ClientSettingComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tierId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  countryList: any = [];
  stateList: any = [];
  dataTable: any;
  contactData: any = [];
  loginPageContent: any = ""
  tabName: any = 'Client';
  filePath: any = "assets/img/upload-img.png";
  filePathInner: any = "assets/img/upload-img.png";
  filePathMobile: any = "assets/img/upload-img.png";
  filePathBranding: any = "assets/img/upload-img.png";
  filePathBrandingApp: any = "assets/img/upload-img.png";
  defaultImage: any = "assets/img/upload-img.png";

  client: any = {
    "description": "", "maxDaysHistory": "1", "maxInactivityHours": "", "billingName": "", "deactivationTime": "",
    "requiresPinVerification": false, "isActive": true, "contactEmail": '', "criticalThresHold": "25", "contactPhone": '', "alternatePhone": '', "isNotification": false
  };


  criticalThresHold = []

  clientIdForContact: any;
  typeForContact: any = "Client Contact";

  @ViewChild("addClient") addClient: any;
  @ViewChild("clientContact") clientContact: any;
  @ViewChild("imageName") imageFileName: ElementRef;
  @ViewChild("filePathInnerName") filePathInnerName: ElementRef;
  @ViewChild("filePathBrandingName") filePathBrandingName: ElementRef;
  @ViewChild("filePathBrandingAppName") filePathBrandingAppName: ElementRef;



  imageName: any;
  imageNameInner: any;
  imageNameMobile: any;
  clientBrandLogoWeb: any;
  clientBrandLogoApp: any;

  contact: any =
    {
      "firstName": "",
      "lastName": "",
      "note": "",
      "title": "",
      "emailId": "",
      "phoneNumber": "",
      // "mobileNumber": "",
      "secondaryPhoneNumber": "",
      "userType": "",
      client: {}
    }

  branding: any = {};
  clientCode: any = '';
  permissionData: any = [];
  imageTitle = "";
  innerLogoTitle = "";
  mobileLoaderImgTitle = "";
  loaderImgTitle = "";
  canChangeEmailDomain: any = '';
  assetArrayForTeam: any = [];
  assetErrMsg: any = '';
  HistoryList: any = [];
  brandingHistoryList: any = [];

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
  }

  DateFormat(startDate) {
    if (startDate) {
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    } else {
      return "";
    }
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }
  ngOnInit() {

    this.clientCode = this.alertService.getClientCode();

    jQuery('.rst-date').datetimepicker({
      language: 'en',
      pickTime: true,
      pickDate: true,
      minDate: 0,
      inline: true,
      sideBySide: true,
      format: 'yyyy-MM-dd HH:mm:ss'
    });

    for (let i = 25; i <= 100; i++) {
      this.criticalThresHold.push(i);
    }
    this.getClientById();
    this.getContactData();

  }

  // change breadcrumb name on change tab.
  changeTab(tab) {
    this.tabName = tab;
  }


  /* Get Client by Id*/
  getClientById() {
    // this.apiService.getAll('public/client/getClientById?clientId='+this.clientId).subscribe(
    this.apiService.getAll('client/getClientById?clientId=' + this.clientId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.client = data.response;
          this.canChangeEmailDomain = this.client.canChangeEmailDomain;
          let obj = this.client;
          this.branding = {
            clientDomain: obj.clientDomain,
            clientCode: obj.clientCode,
          }
          this.loginPageContent = obj.loginPageContent ? obj.loginPageContent : ""

          this.filePath = obj.logoFile ? this.apiService.imageBasePath + obj.logoFile : "assets/img/upload-img.png";
          if (obj.logoFile) {
            let logoImgArr = obj.logoFile.split('/');
            this.imageTitle = logoImgArr[2];
          }

          this.filePathMobile = obj.appLogoPath ? this.apiService.imageBasePath + obj.appLogoPath : "assets/img/upload-img.png";

          // App Loader img
          this.filePathBrandingApp = obj.loaderImgMobile ? this.apiService.imageBasePath + obj.loaderImgMobile : "assets/img/upload-img.png";
          if (obj.loaderImgMobile) {
            let loaderImgMobileArr = obj.loaderImgMobile.split('/');;
            this.mobileLoaderImgTitle = loaderImgMobileArr[2];
          }

          // Web Loader img
          this.filePathBranding = obj.loaderImg ? this.apiService.imageBasePath + obj.loaderImg : "assets/img/upload-img.png";
          if (obj.loaderImg) {
            let loaderImgArr = obj.loaderImg.split('/');;
            this.loaderImgTitle = loaderImgArr[2];
          }

          // Set Loader image again when user change the loader image
          localStorage.setItem("loaderImg", obj.loaderImg);
          this.apiService.clinetLoader();

          // display none loader coz above method set and enable the loader
          $("#loaderImgIcon").css("display", "none");

          this.filePathInner = obj.clientInnerLogoPath ? this.apiService.imageBasePath + obj.clientInnerLogoPath : "assets/img/upload-img.png";
          if (obj.clientInnerLogoPath) {
            let innerLogoArr = obj.clientInnerLogoPath.split('/');
            this.innerLogoTitle = innerLogoArr[2];
          }

          // -------------------------------------------------------  branding details from client

        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Create Client */
  SubmitClient() {
    $("#allErrDiv").text("");
    this.client.deactivationTime = $('#deactivationTime').val();

    if (this.addClient.valid && (this.client.contactPhone != this.client.alternatePhone)) {
      localStorage.setItem("domain", this.client.emailDomain)

      let obj = this.client
      delete obj.logoFile
      delete obj.appLogoPath
      delete obj.clientInnerLogoPath

      // this.apiService.update('public/client/updateClient', obj).subscribe(
      this.apiService.update('client/updateClient', obj).subscribe(
        data => {
          if (data.statusCode == 100) {
            Swal({
              position: 'top-end',
              type: 'success',
              title: 'Client details updated successfully',
              showConfirmButton: false,
              timer: 1500
            })

          } else if (data.statusCode == 112) {
            // if(data.response){
            if (data.response.assetDtos.length > 0) {
              this.assetErrMsg = "";
              this.assetArrayForTeam = [];
              this.assetErrMsg = data.responseMessage;
              this.assetArrayForTeam = data.response.assetDtos;
              if ($.fn.DataTable.isDataTable('#notification_plan_table')) {
                $('#notification_plan_table').DataTable().destroy();
              }
              setTimeout(() => {
                const table: any = $('#notification_plan_table');
                this.dataTable = table.DataTable();
              }, 500);
              console.log("this.assetArrayForTeam--", this.assetArrayForTeam);
              this.notificationConfirmation();
            }
            else {
              this.assetArrayForTeam = [];
              if ($.fn.DataTable.isDataTable('#notification_plan_table')) {
                $('#notification_plan_table').DataTable().destroy();
              };
              this.chRef.detectChanges();
              setTimeout(() => {
                const table: any = $('#notification_plan_table');
                this.dataTable = table.DataTable();
              }, 500);
              $("#allErrDiv").text(data.responseMessage);
            }
          } else {

            if (this.client.maxDaysHistory == null) {
              $('#maxdays_err').css('display', 'block');
            }
            else {
              $('#maxdays_err').css('display', 'none');
            }

            if (this.client.description == '') {
              $('#description_err').css('display', 'block');
            }
            else {
              $('#description_err').css('display', 'none');
            }
            this.alertService.sweetMessage('error', data.responseMessage);
            // $("#allErrDiv").text(data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );

    } else {
      // Swal({
      //   position: 'top-end',
      //   type: 'error',
      //   title: 'Please fill in all the required fields.',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
    }

  }

  /* Get Contact data list */
  getContactData() {
    this.apiService.getAll('contactType/getClientContactListByClientId?clientId=' + this.clientId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.contactData = data.contactTypeList;
          if ($.fn.DataTable.isDataTable('#data_table')) {
            $('#data_table').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#data_table');
          this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": 4 }] });
        } else {

        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  SaveContact() {

    if (this.clientContact.valid && (this.contact.phoneNumber != this.contact.secondaryPhoneNumber)) {
      // if (this.contact.userId) {
        this.contact.title = "";

      if (this.contact.contactTypeId) {
        this.apiService.update('contactType/updateClientContact', this.contact).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.alertService.sweetMessage('success', data.responseMessage);

              this.contact =
              {
                "firstName": "",
                "lastName": "",
                "note": "",
                "title": "",
                "emailId": "",
                "phoneNumber": "",
                // "mobileNumber": "",
                "secondaryPhoneNumber": "",
                "userType": "",
                client: {}
              }

              this.clientContact.reset();
              this.clientContact.submitted = false;
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      } else {

        this.clientContact.submitted = false;
        this.contact.client.clientId = this.clientId;
        this.contact.userType = this.typeForContact;
        this.apiService.create('contactType/createClientContact', this.contact).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.alertService.sweetMessage('success', data.responseMessage);
              this.contact =
              {
                "firstName": "",
                "lastName": "",
                "note": "",
                "title": "",
                "emailId": "",
                "phoneNumber": "",
                // "mobileNumber": "",
                "secondaryPhoneNumber": "",
                "userType": "",
                // client: {}
                client: {}
              }
              this.clientContact.reset();

              this.clientContact.submitted = false;
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    }


  }

  SubmitBranding() {
    let obj = this.branding
    obj.clientId = this.clientId;
    let val = this.loginPageContent.replace('', "");
    obj.loginPagecontent = val//this.loginPageContent//.substring(1, 199);
    // console.log(obj,val,this.loginPageContent);

    // this.imageSave(6,this.imageName);
    // this.imageSave(4,this.imageNameMobile);
    // this.imageSave(5,this.imageNameInner);
    // this.imageSave(7,this.clientBrandLogo);
    var clientDomain = $('#clientDomain').val();
    var clientCode = $('#clientCode').val();

    if (clientDomain == '') {
      $('#clientDomain').addClass('is-invalid');
      $('#clientDomain_err').css('display', 'block');
      // $('#clientCode_err').css('display', 'block');
      // return;
    } else {
      $('#clientDomain').removeClass('is-invalid');
      $('#clientDomain_err').css('display', 'none');
      // $('#clientCode_err').css('display', 'none');
    }

    if (clientCode == '') {
      $('#clientCode').addClass('is-invalid');
      // $('#clientDomain_err').css('display', 'block');
      $('#clientCode_err').css('display', 'block');
      // return;
    } else {
      $('#clientCode').removeClass('is-invalid');
      // $('#clientDomain_err').css('display', 'none');
      $('#clientCode_err').css('display', 'none');
    }
    if (clientDomain == '' || clientCode == '') {
      return
    }

    // this.apiService.update('public/client/updateBranding', obj).subscribe(
    this.apiService.update('client/updateBranding', obj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.getContactData();
          this.getClientById();
          // localStorage.setItem("domain",this.branding.clientDomain)
          this.alertService.sweetMessage('success', 'Updated successfully');
          // this.branding = {}
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  imageSave(section, imageName) {
    const uploadData = new FormData();
    if (imageName) {
      uploadData.append('file', imageName[0], imageName[0].name);
      this.apiService.create('uploadImage/uploadImage?section=' + section + '&sectionId=' + this.clientId, uploadData).subscribe(
        data => {
          if (data.statusCode == 100) {
            setTimeout(() => {
              this.imageName = null;
              this.imageNameInner = null;
              this.imageNameMobile = null;
              this.clientBrandLogoWeb = null;
              this.clientBrandLogoApp = null;
            }, 2000)
          } else {
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }


  /**********************************************
    Image upload For Login Page Logo
  ************************************/
  onFileChanged(event) {
    const file = event.target.files;
 

    if ((file[0].size / 1024) > 500) {
      this.imageTitle = "";
      this.alertService.sweetMessage('error', "Size should be below 500 KB"); return;
    }
    else {
      if (file[0].type != 'image/png' && file[0].type != 'image/jpg' && file[0].type != 'image/jpeg') {
        this.imageTitle = "";
        this.alertService.sweetMessage('error', "Only PNG/JPG/JPEG image are allowed");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          if (img.width < 500 && img.height < 500) {
            this.imageTitle = file[0]['name'];
            this.filePath = reader.result;
            this.imageName = file;
            this.imageSave(6, this.imageName);
          } else {
            this.imageTitle = "";
            this.alertService.sweetMessage('error', "Image should not be greater than 500 X 500");
          }
        };
      }
    }
   

  }


  /**********************************************
    Image upload For Cleint Inner page Logo
  ************************************/
  onFileChangedInner(event) {
    const file = event.target.files;
    if ((file[0].size / 1024) > 500) {
      this.innerLogoTitle = "";
      this.alertService.sweetMessage('error', "Size should be below 500 KB");
    }
    else {
      if (file[0].type != 'image/png' && file[0].type != 'image/jpg' && file[0].type != 'image/jpeg') {
        this.innerLogoTitle = "";
        this.alertService.sweetMessage('error', "Only PNG/JPG/JPEG image are allowed");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          if (img.width <= 300 && img.height <= 300) {
            this.innerLogoTitle = file[0]['name'];
            this.filePathInner = reader.result;
            this.imageNameInner = file;
            this.imageSave(5, this.imageNameInner);
          } else {
            this.innerLogoTitle = "";
            this.alertService.sweetMessage('error', "Image should not be greater than 300 X 300");
          }
        };
      }
    }
    
  }


  /**********************************************
    Image upload For Mobile Logo
  ************************************/
  onFileChangedMobile(event) {
    const file = event.target.files;
    // console.log(file[0],(file[0].size/1024),file[0].height,file);
    // console.log(file[0].size/1024);
    if ((file[0].size / 1024) > 500) { this.alertService.sweetMessage('error', "Size should be below 500 KB"); }
    else {
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          if (img.width <= 500 && img.height <= 500) {
            this.filePathMobile = reader.result;
            this.imageNameMobile = file;
            this.imageSave(4, this.imageNameMobile);
          } else {
            this.alertService.sweetMessage('error', "Image should not be greater than 170 X 170");
          }
        };
      }
    }
  }



  /**********************************************
    Image upload For Cleint Branding Logo For WEB
  ****************************************************/

  onFileChangedClientBrandWeb(event) {
    const file = event.target.files;
    // console.log(file[0],(file[0].size/1024),file[0].height,file);
    // console.log(file[0].size/1024);

    if (file[0].type != 'image/gif') {
      this.loaderImgTitle = "";
      this.alertService.sweetMessage('error', "Only Gif image are allowed as loader");
      return;
    }

    if ((file[0].size / 1024) > 500) {
      this.loaderImgTitle = "";
      Swal(
        {
          position: 'top-end', type: 'error',
          title: 'Size should be below 500 KB',
          showConfirmButton: false, timer: 2500
        })
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          if (img.width <= 200 && img.height <= 200) {
            this.loaderImgTitle = file[0]['name'];
            this.filePathBranding = reader.result;
            this.clientBrandLogoWeb = file;
            this.imageSave(7, this.clientBrandLogoWeb);
          }
          else {
            this.loaderImgTitle = "";
            this.alertService.sweetMessage('error', "Image should not be greater than 200 X 200");
          }
        };
      }
    }
      
  }




  /**********************************************
    Image upload For Cleint Branding Logo For App
  ************************************************************/

  onFileChangedClientBrandApp(event) {
    const file = event.target.files;
    // console.log(file[0],(file[0].size/1024),file[0].height,file);
    // console.log(file[0].size/1024);

    if (file[0].type != 'image/gif') {
      this.mobileLoaderImgTitle = "";
      this.alertService.sweetMessage('error', "Only Gif image are allowed as loader");
      return;
    }

    if ((file[0].size / 1024) > 500) {
      this.mobileLoaderImgTitle = "";
      Swal({
        position: 'top-end',
        type: 'error',
        title: 'Size should be below 500 KB',
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          if (img.width <= 170 && img.height <= 170) {
            this.mobileLoaderImgTitle = file[0]['name'];
            this.filePathBrandingApp = reader.result;
            this.clientBrandLogoApp = file;
            this.imageSave(8, this.clientBrandLogoApp);
          }
          else {
            this.mobileLoaderImgTitle = "";
            this.alertService.sweetMessage('error', "Image should not be greater than 170 X 170");
          }
        };
      }
    }
   
  }

  // removeInputImage(type) {
  //    /* clear file data after operation */
  //   if(type = 6) {      
  //     this.imageFileName.nativeElement.value = ""; 
  //     } else if(type = 5) {
  //       this.filePathInnerName.nativeElement.value = "";  
  //     } else if(type = 7) {
  //       this.filePathBrandingName.nativeElement.value = "";  
  //     } else if(type = 8) { 
  //       this.filePathBrandingAppName.nativeElement.value = "";  
  //   }
  // }

  removeInputImage(type:any) {  
    //  clear file data after operation 
    if (type == 6) {      
    this.imageFileName.nativeElement.value = ""; 
    this.imageTitle = '';
    } else if (type == 5) {
    this.filePathInnerName.nativeElement.value = "";  
    this.innerLogoTitle = '';
    } else if (type == 7) {
    this.filePathBrandingName.nativeElement.value = "";      
    this.loaderImgTitle = '';
    } else if (type == 8) { 
    this.filePathBrandingAppName.nativeElement.value = "";  
    this.mobileLoaderImgTitle = '';
    }
  }

  getContactById(id) {
    this.apiService.getAll('contactType/getContactTypeById?contactTypeId=' + id).subscribe(
      data => {
        if (data.statusCode == 100) { this.contact = data.contactType; }
        else {
          // this.contactData = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  DeleteClientContact(userId) {
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Contact.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        // this.apiService.getAll('contactType/deleteClientContact?userId='+userId).subscribe(
        this.apiService.delete('contactType/deleteClientContact?contactTypeId=' + userId, "").subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.chRef.detectChanges();
              const table: any = $('.table');
              this.dataTable = table.DataTable();
              this.contact = {
                "firstName": "", "lastName": "",
                "note": "", "title": "",
                "emailId": "", "phoneNumber": "",
                "secondaryPhoneNumber": "", "userType": "", "client": {}
              }
              this.alertService.sweetMessage('success', 'Deleted Successfully');

            }
            else { this.alertService.sweetMessage('error', data.responseMessage); }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }
  assetNameList: any = []
  // get asset name
  getAssetName(assets) {
    let assetName = '';
    if (assets) {
      assets.map((obj, index) => {
        if (index == 0) {
          assetName += obj.assetName
        } else {
          assetName = assetName + ', ' + obj.assetName
        }
      })
    }
    return assetName;
  }
  /* Notification plan confirmation box*/
  notificationConfirmation() {
    Swal({
      title: 'Are you sure?',
      text: "You want Notification Plan",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value && this.assetArrayForTeam.length > 0) {
        $("#assetModal").click();
      } else {
        this.client.isNotification = false;
      }
    })
  }

  /* function for deleting image of branding */
  removeBrandingImage(section, sectionName) {
    Swal({
      title: 'Are you sure?',
      text: `You want to remove ${sectionName}.`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete("uploadImage/removeImage?section=" + section + "&sectionId=" + this.clientId, '').subscribe(
          data => {
            if (data.statusCode == 100) {
              this.alertService.sweetMessage('success', `${sectionName} removed successfully`);
              if (section == 6) {
                this.filePath = 'assets/img/upload-img.png'
              } else if (section == 5) {
                this.filePathInner = 'assets/img/upload-img.png'
              } else if (section == 7) {
                this.filePathBranding = 'assets/img/upload-img.png'
              } else if (section == 8) {
                this.filePathBrandingApp = 'assets/img/upload-img.png'
              }
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }

  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('contactType/getContactTypeHistoryById?contactTypeId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.contactTypeList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

getBrandingHistory(id){
  this.brandingHistoryList = []
  if ($.fn.DataTable.isDataTable('#brandingHistoryDatatable')) {
    $('#brandingHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('client/getClientBrandingHistoryById?clientId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.brandingHistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#brandingHistoryDatatable');
              table.DataTable({ "order": [], "destroy": true });
            }, 1000);
          } else {
            setTimeout(() => {
              const table: any = $('#brandingHistoryDatatable');
              table.DataTable({ "order": [], "destroy": true });
            }, 1000);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}
}
