import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-shift-management',
  templateUrl: './shift-management.component.html',
  styleUrls: ['./shift-management.component.css']
})
export class ShiftManagementComponent implements OnInit {
clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
shiftId:number=0;
dataTable: any;
isError: any;
shiftgroupData: any = [];
addShiftGroup : any = {
  "groupName": "",
  "client":{"clientId":this.clientId},
  "isActive": true,
  "assets":[],
  "tiers":[],
  time_zone_info:null

};
dropdownSettingsAsset={};
dropdownSettingsTier={};
assetDatalist:any=[];
tierDatalist:any=[];
checkAssetArray:any=[];
checkTierArray:any=[];
selectedItems :any = [];
selctedTiers:any = [];
assetArray:any=[];
tierArray:any=[];
assetList:any=[];
permissionData:any=[];
shiftGroupId:any = null;
clientCode : any = '';
timeZoneList:any = []
addBtnDisable:any = false
@ViewChild ('f') f:any;
isAssociated:boolean = false;
infoMsg:any = "";
shiftHistoryList: any = [];


constructor(private router: Router, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef, private formBuilder: FormBuilder) {
    this.permissionData = ApiService.permission;
}

 DateFormat(startDate){
  if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
  }else{
      return "";
    }
 }
 DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
        var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.getShiftGrouplist();
    this.getAllTierList();
    if(this.checkTierArray.length != 0){
        this.getAllAssetList();
    }else{
        this.assetDatalist = [];
        this.checkAssetArray = [];
    }
    // this.getTimeZoneList() //----------------------------------------timezone API

// For Asset Drop Down
    this.dropdownSettingsAsset = {
        singleSelection: false,
        idField: 'assetId',
        textField: 'assetName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };

// For Tier Drop Down
    this.dropdownSettingsTier = {
        singleSelection: false,
        idField: 'tierId',
        textField: 'tierName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };

}


getTimeZoneList(){
    this.apiService.getAll('shiftGroup/TimeZoneInfo').subscribe(
        data => {
            if (data.statusCode == 100) {
                this.timeZoneList = data.responseList;
            } else {
                this.timeZoneList = [];
            }
        },
        error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
         }
    );
}

/*****************************
For Asset Drop Down Actions
*********************************/

/* On Item Select Asset */
onItemSelectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetId.toString());
    if (exist == '-1') {
        this.checkAssetArray.push(item.assetId.toString());
    }
    this.checkAssetAssociation(this.checkAssetArray)
}

/* On Item DeSelect Asset */
onItemDeselectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetId.toString());
    if (exist != '-1') {
        this.checkAssetArray.splice(exist, 1);
    }
    this.infoMsg = "";
    this.checkAssetAssociation(this.checkAssetArray)
}

/* On All Select Asset */
onSelectAllAsset(items: any) {
    this.checkAssetArray = [];
    Object.keys(items).forEach(key => {
        this.checkAssetArray.push(items[key]['assetId'].toString());
    });
    this.checkAssetAssociation(this.checkAssetArray)
}

/* On All DeSelect Site */
onDeSelectAllAsset() {
    this.infoMsg = "";
    this.checkAssetArray = [];
}


/*****************************
For Tier Drop Down Actions
*********************************/

/* On Item Select Tier */
onItemSelectTier(item: any) {
    var exist = this.checkTierArray.indexOf(item.tierId.toString());
    if (exist == '-1') {
        this.checkTierArray.push(item.tierId.toString());
        let obj1 = {
            "clientId": this.clientId,
            "userId": this.userId,
            "tierId": this.checkTierArray.toString(),
            "rotatingnotificationplan":1
        } 
        // this.apiService.getAll('asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId +'&tierId='+this.checkTierArray+'&isAssetLive=true').subscribe(
        this.apiService.create('asset/getAssetDropDownByClient',obj1).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.assetDatalist = data.responseList;
                } else {
                    this.assetDatalist = [];
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }else{
        if(this.checkTierArray.length === 0){
            this.assetDatalist = [];
            this.checkAssetArray = [];
        }
    }
}

/* On Item DeSelect Tier */
onItemDeselectTier(item: any) {
    var exist = this.checkTierArray.indexOf(item.tierId.toString());
    if (exist != '-1') {
        this.checkTierArray.splice(exist, 1);
    }
    this.getAllAssetList()
    if(this.checkTierArray.length === 0){
        this.assetDatalist = [];
        this.checkAssetArray = [];
        this.selectedItems = [];
    }
}

/* On All Select Tier */
// onSelectAllTier(items: any) {
//     this.checkTierArray = [];
//     Object.keys(items).forEach(key => {
//         this.checkTierArray.push(items[key]['tierId'].toString());
//         this.apiService.getAll('asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId +'&tierId='+this.checkTierArray).subscribe(
//             data => {
//                 if (data.statusCode == 100) {
//                     this.assetDatalist = data.responseList;
//                 } else {
//                     this.assetDatalist = [];
//                     this.checkAssetArray = [];
//                 }
//             },
//            error =>{
//                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
//              }
//         );
//     });
//     if(this.checkTierArray.length === 0){
//         this.assetDatalist = [];
//         this.checkAssetArray = [];
//     }
// }

onSelectAllTier(items: any) {
    this.checkTierArray = [];
    Object.keys(items).forEach(key => {
        this.checkTierArray.push(items[key]['tierId'].toString());
    });
    if(this.checkTierArray.length === 0){
        this.assetDatalist = [];
        this.checkAssetArray = [];
    }else{
        let Tier = this.checkTierArray.join();
        var tierIds = Tier.toString();
         let obj1 = {
            "clientId": this.clientId,
            "userId": this.userId,
            "tierId": tierIds,
            "rotatingnotificationplan":1
        } 
        // this.apiService.getAll('asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId +'&tierId='+this.checkTierArray+'&isAssetLive=true').subscribe(
        this.apiService.create('asset/getAssetDropDownByClient',obj1).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.assetDatalist = data.responseList;
                } else {
                    this.assetDatalist = [];
                    this.checkAssetArray = [];
                }
            },
        error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }
}

/* On All DeSelect Tier */
onDeSelectAllTier() {
    this.checkTierArray = [];
    if(this.checkTierArray.length === 0){
        this.selectedItems = [];
        this.assetDatalist = [];
        this.checkAssetArray = [];
    }
}


/***********************
* Get All Asset List
***********************/
getAllAssetList() {
    var URL = 'asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId+'&isAssetLive=true';
    if (this.customerId != null && this.customerId != '') {
        var customerString = '&customerId=' + this.customerId.toString();
        var URL = URL.concat(customerString);
    }
    if (this.tierId != null && this.tierId != '') {
        var tierString = '&tierId=' + this.tierId.toString();
        var URL = URL.concat(tierString);
    }
    let obj1 = {
        "clientId": this.clientId,
        "userId": this.userId,
        "tierId": this.checkTierArray.toString(),
        "rotatingnotificationplan":1
    } 
    // this.apiService.getAll('asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId +'&tierId='+this.checkTierArray+'&isAssetLive=true').subscribe(
    this.apiService.create('asset/getAssetDropDownByClient',obj1).subscribe(
    // this.apiService.getAll(URL).subscribe(
        data => {
          if (data.statusCode == 100) {
              this.assetDatalist = data.responseList;
          } else {
              this.assetDatalist = [];
          }
        },
     error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
}

/***********************
* Get All Tier List
***********************/
getAllTierList() {
    // var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
    var URL = 'tier/getTiersByClient?clientId='+this.clientId+'&userId='+this.userId+'&alphabetSortingOrder=1';
    if (this.customerId != null && this.customerId != '') {
        var customerString = '&customerId=' + this.customerId.toString();
        var URL = URL.concat(customerString);
    }
    if (this.tierId != null && this.tierId != '') {
        var tierString = '&tierId=' + this.tierId.toString();
        var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100)
        {
          this.tierDatalist = data.responseList; }
        else{
          this.tierDatalist = [];
        }
      },
      error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
}


/* Get shiftGroup list  */
getShiftGroupById(shiftId) {
  this.shiftId = shiftId;
  this.f.submitted = false
  this.apiService.getAll('shiftGroup/getShiftGroupById?shiftGroupId=' + shiftId).subscribe(
    data => {
      if (data.statusCode == 100) {
          let arr = []
          this.checkTierArray = [];
          this.addShiftGroup = data.response;
          this.selectedItems = this.addShiftGroup.assets;
          this.selctedTiers  = this.addShiftGroup.tiers;
        if (this.addShiftGroup.tiers) {
            for (const iterator of this.addShiftGroup.tiers) {

                this.checkTierArray.push(iterator.tierId.toString())
            }
        }

          Object.keys(this.addShiftGroup.assets).forEach(key => {
              let asset = {
                assetId:this.addShiftGroup.assets[key]['assetId'],
                name:this.addShiftGroup.assets[key]['assetName']
              }
              arr.push(asset);
            this.checkAssetArray.push(this.addShiftGroup.assets[key]['assetId'].toString());
            
          });
          this.selectedItems = arr
          setTimeout(() => {
              this.getAssetByTier();
          }, 500);
      } else {
         /* this.alertService.sweetMessage('error',data.responseMessage);*/
      }
    },
    error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
}

getAssetByTier(){
    let obj1 = {
        "clientId": this.clientId,
        "userId": this.userId,
        "tierId": this.checkTierArray.toString(),
        "rotatingnotificationplan":1
    } 
    // this.apiService.getAll('asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId +'&tierId='+this.checkTierArray+'&isAssetLive=true').subscribe(
    this.apiService.create('asset/getAssetDropDownByClient',obj1).subscribe(
    // this.apiService.getAll('asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId +'&tierId='+this.checkTierArray+'&isAssetLive=true').subscribe(
        data => {
            if (data.statusCode == 100) {
                this.assetDatalist = data.responseList;
            } else {
                this.assetDatalist = [];
                this.checkAssetArray = [];
            }
        },
       error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
         }
    );
}

/* Get Asset list By Shift Group id */
getAssetListById(shiftGroupId) {
    this.shiftGroupId = shiftGroupId
    this.apiService.getAll('asset/getAssetByShiftGroup?shiftGroupId=' + shiftGroupId).subscribe(
        data => {
            if (data.statusCode == 100) {
                this.assetList = data.responseList;
                if ($.fn.DataTable.isDataTable('#asset_table')) {
                    $('#asset_table').DataTable().destroy();
                };
                this.chRef.detectChanges();
                const table: any = $('#asset_table');
                this.dataTable = table.DataTable();
            } else {
                this.assetList = [];
                if ($.fn.DataTable.isDataTable('#asset_table')) {
                    $('#asset_table').DataTable().destroy();
                };
                this.chRef.detectChanges();
                const table: any = $('#asset_table');
                this.dataTable = table.DataTable();

                /*this.alertService.sweetMessage('error',data.responseMessage);*/
            }
        },
        error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
}

setShiftGroupId() {
    this.shiftId = 0;
    this.checkAssetArray = [];
    this.assetArray = [];
    this.selctedTiers = [];
    this.selectedItems = [];
    this.addShiftGroup = {
        "groupName": "",
        "client": {
            "clientId": this.clientId
        },
        "isActive": true,
        "assets": [],
        timeZoneInfo:null
    };
    this.f.submitted = false
}
/* Get shiftGroup list  */
getShiftGrouplist() {
    var URL = 'shiftGroup/getShiftGroupByClient?clientId=' + this.clientId;
    if (this.customerId != null && this.customerId != '') {
        var customerString = '&customerId=' + this.customerId.toString();
        var URL = URL.concat(customerString);
    }
    if (this.tierId != null && this.tierId != '') {
        var tierString = '&tierId=' + this.tierId.toString();
        var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
        data => {
            if (data.statusCode == 100) {
                this.shiftgroupData = data.responseList;
                if ($.fn.DataTable.isDataTable('#shift_table')) {
                    $('#shift_table').DataTable().destroy();
                };
                this.chRef.detectChanges();
                const table: any = $('#shift_table');
                this.dataTable = table.DataTable({
                    "order": [],
                    "columnDefs": [{
                        "orderable": false,
                        "targets": 3
                    }]
                });
            } else {
                this.shiftgroupData = [];
                this.chRef.detectChanges();
                const table: any = $('#shift_table');
                this.dataTable = table.DataTable({
                    "order": [],
                    "columnDefs": [{
                        "orderable": false,
                        "targets": 3
                    }]
                });

            }
        },
        error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
}

/* Create Asset array */
createAssetArray() {
    Object.keys(this.checkAssetArray).forEach(key => {
        if (this.checkAssetArray[key] != 0) {
            this.assetArray.push({
                "assetId": this.checkAssetArray[key]
            });
        }
    });
    this.addShiftGroup.assets = this.assetArray;
};

/* Create Tier array */
createTierArray() {
    Object.keys(this.checkTierArray).forEach(key => {
        if (this.checkTierArray[key] != 0) {
            this.tierArray.push({
                "tierId": this.checkTierArray[key]
            });
        }
    });
    this.addShiftGroup.tiers = this.tierArray;
};

/* Function for add Shift Data */


addShiftGroupData(data) {
    this.createAssetArray();
    //this.createTierArray();
    // if(this.addShiftGroup.groupName.trim() == ""){
    //   this.addShiftGroup.groupName = "";
    //   this.isError = true;
    //   return;
    // }
    
    
    if (!data.invalid) {
        this.addBtnDisable = true
        if (this.shiftId == 0) {
            this.apiService.create('shiftGroup/createShiftGroup', this.addShiftGroup).subscribe(
                data => {
                    $("#createShiftGroup").modal('hide');
                    this.resetForm();
                    this.addBtnDisable = false
                    if (data.statusCode == 100) {
                        this.addShiftGroup = {
                            "groupName": "",
                            "client": {
                                "clientId": this.clientId
                            },
                            "isActive": true,
                            "assets": [],
                            time_zone_info:null

                        };
                        this.getShiftGrouplist();
                        this.checkAssetArray = [];
                        this.assetArray = [];
                        this.selectedItems = [];
                        this.selctedTiers = [];
                        Swal({
                            position: 'top-end',
                            type: 'success',
                            title: 'Calendar Created successfully',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        this.alertService.sweetMessage('error',data.responseMessage);
                          this.selctedTiers = [];
                    }
                },
                error =>{
                    this.addBtnDisable = false
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        } else {
                this.apiService.update('shiftGroup/updateShiftGroup', this.addShiftGroup).subscribe(
                data => {
                    $("#createShiftGroup").modal('hide');
                    this.addBtnDisable = false
                    this.resetForm();
                    if (data.statusCode == 100) {
                        this.addShiftGroup = {
                            "groupName": "",
                            "client": {
                                "clientId": this.clientId
                            },
                            "isActive": true,
                            "assets": [],
                            time_zone_info:null

                        };
                        this.getShiftGrouplist();
                        this.checkAssetArray = [];
                        this.assetArray = [];
                        this.selectedItems = [];
                        Swal({
                            position: 'top-end',
                            type: 'success',
                            title: 'Calendar updated successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        Swal({
                            position: 'top-end',
                            type: 'error',
                            title: data.responseMessage,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                error =>{
                    this.addBtnDisable = false
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        }
    }

}


/* Delete Shift Group  */
DeleteShiftGroup(groupId) {
    Swal({
        title: 'Are you sure?',
        text: "You want to delete this calendar?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.value) {
            this.apiService.delete('shiftGroup/deleteShiftGroupById?shiftGroupId=' + groupId, "").subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.getShiftGrouplist();
                        Swal({
                            position: 'top-end',
                            type: 'success',
                            title: 'Calendar deleted successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.alertService.sweetMessage('error',data.responseMessage);
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        }
    })
}

getShiftName(data) {
    localStorage.setItem('shiftGroupNameData', JSON.stringify(data));
}

resetForm(){
    this.f.reset()
    this.f.submitted = false;
    var note = $('#groupName').val('');
    this.assetDatalist = [];
    this.checkTierArray = [];
    if(this.checkTierArray.length === 0){
        this.assetDatalist = [];
        this.checkAssetArray = [];
    }
    this.isAssociated = false;
    this.infoMsg = "";
  }

  checkAssetAssociation(checkAssetArray){
    this.infoMsg = "";
    for (let index = 0; index < this.assetDatalist.length; index++) {
        const element = this.assetDatalist[index];
        var exist = checkAssetArray.indexOf(element.assetId.toString());
        if(checkAssetArray.length!=0){
            if (exist != -1) {
                if((element.shiftGroup!="") && (element.shiftGroup!=null)){
                    if(this.shiftId!=0){
                        if(element.shiftGroup.shiftGroupId != this.shiftId){
                            this.isAssociated = true;
                        }else{
                            this.isAssociated = false;
                        }
                    }else{
                        this.isAssociated = true;
                    }
                }
                break;
            }
        }else{
            this.isAssociated = false;
        }
    }
    this.showMsg();
  }

  showMsg(){
    this.infoMsg = "";
    if(this.isAssociated){
        this.infoMsg = "Some of selected assets associated with other calendar";
    }
  }
  shiftHistory(groupdata){
    this.shiftHistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('shiftGroup/getShiftGroupHistory?shiftGroupId=' + groupdata.shiftGroupId).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.shiftHistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                this.dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                this.dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

  getAssetTooltip(Assetlist){
    let str = ''
    if (Assetlist) {
      if (Assetlist.length) {
        Assetlist.map((obj,index)=>{
          if (index==0) {
            str += obj.assetName
          }else{
            str = str+', '+obj.assetName
          }
        })
      }
    }
    return str;
  }

}
