import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-modem-traffic',
  templateUrl: './modem-traffic.component.html',
  styleUrls: ['./modem-traffic.component.css']
})
export class ModemTrafficComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId :any = 0;
  dataTable: any;
  assetsumaryData : any ={};
  terminaltrafficData : any = [];
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  clientCode : any ='';
  permissionData:any=[];
  maxRecords : any = 50;
  eventDetails : any = [];
  eventNotification : any = {};
  outgoingData: any = {};
  title:any ="";
  objectType:any="";
  divType:any="";
  isLoading = false;
  breadcrumbObj: any;
  urlCustomerId: any;
  CdName: any;

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private chRef: ChangeDetectorRef
    ) {
    this.permissionData = ApiService.permission;
   }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {

  // Get current Date and Time for showing default date
  this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          this.urlCustomerId = params.cus_id;
          this.CdName = params.cusD_name;
      })
  var today = new Date();

  var hr  = (today.getHours() ? (today.getHours() > 9 ? today.getHours() : '0' + today.getHours()) : '00');

  var min = (today.getMinutes() ? (today.getMinutes() > 9 ? today.getMinutes() : '0' + today.getMinutes()) : '00');

  var sec = (today.getSeconds() ? (today.getSeconds() > 9 ? today.getSeconds() : '0' + today.getSeconds()) : '00');

  var month = ((today.getMonth()+1) ? ((today.getMonth()+1) > 9 ? (today.getMonth()+1) : '0' + (today.getMonth()+1)) : '00');

  var Tdate = (today.getDate() ? (today.getDate() > 9 ? today.getDate() : '0' + today.getDate()) : '00');

  var date     = today.getFullYear()+'-'+month+'-'+Tdate;
  var time     = hr + ":" + min + ":" + sec;
  var dateTime = date+' '+time;

// Fill default date time in the input fields
  $("#startDate").val(date+' '+"00:00:00");
  $("#endDate").val(dateTime);

    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
      if(params['id']){
        this.assetId = params['id'];
      }
    });

    if(this.assetId){
     this.getAssetSummaryData();
     // this.getTerminalTrafficData();
     this.getRecords();
    }

    jQuery('.rst-date').datetimepicker({
        language: 'en',
        pickTime: true,
        pickDate: true,
        minDate: 0,
        inline: true,
        sideBySide: true
    });
    jQuery('.rst-time').datetimepicker({
        pickDate: true,
        pickTime: true,
        pickSeconds: false,
        pickMinutes: true,
        timeFormat: "HH:mm:ss"
    });


  }

  // Function to set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }

  // Listen For event Recive from child
    reciveAsset(){
      this.getAssetSummaryData();
    }

  /* Function for getting Asset Summary Data */
  getAssetSummaryData(){
    this.apiService.getAll('asset/getAssetSummaryById?clientId='+this.clientId+'&userId='+this.userId+'&assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;

        }else{
           /*  this.alertService.sweetMessage('error',data.responseMessage);*/
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Function for getting Traffic Data */
  getTerminalTrafficData(){
    this.isLoading = true;
    this.apiService.getAll('packetHeader/getTerminalTrafficByAsset?assetId='+this.assetId).subscribe(
      data => {
        this.isLoading = false;
        if(data.statusCode == 100){
          this.terminaltrafficData = data.responseList;
          this.sortDataTableData(this.terminaltrafficData,'packetId',"desc");
          if ($.fn.DataTable.isDataTable('#data_table1')){
                $('#data_table1').DataTable().destroy();
              };
         this.chRef.detectChanges();
              const table: any = $('#data_table1');
               this.dataTable = table.DataTable();
        }else{
          this.terminaltrafficData= [];
           this.chRef.detectChanges();
              const table: any = $('#data_table1');
               this.dataTable = table.DataTable();

           /*  this.alertService.sweetMessage('error',data.responseMessage);*/
        }
      },
      error =>{
            this.isLoading = false;
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }

  /** Function to Sort Response Data **/
  sortDataTableData(items,sortBy,desc){
    if (sortBy && sortBy != null) {
      items = items.sort((a, b) => {
       const sortA = a[sortBy];
       const sortB = b[sortBy];
       if (desc) {
         if (sortA < sortB) return 1;
         if (sortA > sortB) return -1;
         return 0;
       } else {
           if (sortA < sortB) return -1;
           if (sortA > sortB) return 1;
           return 0;
       }
       });
      return items;
    }else{
      return items;
    }
  }


// Search method for Asset records according to date
  getRecords () {
    this.isLoading = true;
  var fromDate = $("#startDate").val();
  var endDate = $("#endDate").val();
  this.terminaltrafficData =[];
  if ($.fn.DataTable.isDataTable('#asset_terminal_traffic_table')){
    $('#asset_terminal_traffic_table').DataTable({"order": [], "destroy": true,/* "columnDefs": [{ "orderable": false, "targets": [9,10] }] */}).destroy();
  };
  //this.apiService.getAll('packetHeader/getTerminalTrafficByAsset?topClause=10&assetId=10200&fromDate=2019-01-06 00:00:00&toDate=2019-06-04 11:49:53').subscribe(
  this.apiService.getAll('packetHeader/getTerminalTrafficByAsset?topClause='+this.maxRecords+'&assetId='+this.assetId+'&fromDate='+fromDate+'&toDate='+endDate).subscribe(
    data => {
      this.isLoading = false;
      if(data.statusCode == 100){
        this.terminaltrafficData = data.responseList;
        setTimeout(() => {
          const table: any = $('#asset_terminal_traffic_table');
          this.dataTable = table.DataTable({"order": [], "search": { regex: false, smart: false }, "destroy": true,/* "columnDefs": [{ "orderable": false, "targets": [9,10] }] */});
        }, 1000);
      }
      else{
        this.terminaltrafficData =[];
        //this.alertService.sweetMessage('error',data.responseMessage);
      }
    },
    error =>
      {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }

  )
  }

// getDetailsByActivityType

getDetailsByActivityType (objectId, objectType) {
  this.objectType = objectType;
  if(this.objectType == 'OutgoingCellular' || this.objectType == 'OutgoingVistar' || this.objectType == 'OutgoingIDP'){
    this.title = "Command Details";
    this.divType = "OutgoingCellular";
  }else if(this.objectType =='PacketHeader' || this.objectType =='PacketHeaderArchive'){
    this.title   = "Message Details";
    this.divType = "PacketHeader";

  }else{
    this.title = "View Message Details";
    this.divType = "";
  }

  this.apiService.getAll('packetHeader/getSiteActivityByPacketHeader?objectTypeId='+objectId+'&objectType='+objectType).subscribe(
  //this.apiService.getAll('packetHeader/getSiteActivityByPacketHeader?objectTypeId=50943&objectType=OutgoingCellular').subscribe(
    data => {
      if(data.statusCode == 100){
        if(this.objectType == 'PacketHeader' || this.objectType == 'PacketHeaderArchive'){
          this.eventDetails = data.responseList;
          this.eventNotification = {};
          this.outgoingData = {};
        }else if(this.objectType == 'OutgoingCellular' || this.objectType == 'OutgoingVistar' || this.objectType == 'OutgoingIDP'){
          this.eventDetails = [];
          this.eventNotification = {};
          this.outgoingData = data.response;
        }else{
          this.eventDetails = [];
          this.eventNotification = data.response;
          this.outgoingData = {};
        }
        //document.getElementById("openModalButton").click();
      }else{
        this.eventDetails = [];
        this.eventNotification = {};
        this.outgoingData = {};
        //this.alertService.sweetMessage('error',data.responseMessage);
      }
    },
    error =>
      {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
  )
}


}
