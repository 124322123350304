import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService } from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'
import { UUID } from 'angular2-uuid';
import * as _ from "lodash";
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
declare var moment:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  	constructor(private router:Router, private apiService: ApiService,private cookieService:CookieService, private alertService:AlertService) { }
  	uuid = UUID.UUID();

  	// Default Values
  	user: any = {};
  	userForm: any = {"agent":this.uuid,"agentType":"WEB","grant_type" : "password","client_id":"my-trusted-client"};

  	// initializing Variables
  	loginPageContentData:any;
    imageFile:any;
    loaderImg:any;
	  rememberMe:any = false;
	errorMsg : any;
	resMessage:any = '';
	logoClass:any = false;

	ngOnInit() {
		$(document).ready(function () {
		    // CSS
    	   	// $("body").addClass('login').css({"padding-top": "0px"});
	    });
	    // Clear Storage Data
		//localStorage.clear();
		
		
		// let username = window.atob(this.cookieService.get('#1bcd3'))
		// let pass = window.atob(this.cookieService.get('zxcv'))
		let islogin = this.cookieService.get('islogin')
		// if (islogin) {
		// 	this.userForm.username = username
		// 	this.userForm.password = pass
		// 	if (this.userForm.password && this.userForm.username) {
		// 		this.rememberMe = true
		// 	}
		// } else {
			
		// }
		
		let user = this.cookieService.get('#1bcd3')
		let pass = this.cookieService.get('zxcv')
        this.userForm.username = this.decryptPassword(user);
        this.userForm.password = this.decryptPassword(pass);
        if (this.userForm.username && this.userForm.password) {
			// this.rememberMe = true;
			if (islogin) {
				// this.login();
			}
        }

        // Getting Login Data
		this.getLoginData();
		$(document).ready(function () {
		    $("body").addClass('login')//.css({"padding-top": "0px"});
		    $("body").removeClass('body-smt-active');
		});
		
		let permission = localStorage.getItem("permission");
        let token = JSON.parse(localStorage.getItem('Token'));

		let userData = localStorage.getItem("userData");
		let userId = localStorage.getItem("userId");
		let clientId = localStorage.getItem("clientId");

		if (token && permission && userData && userId && clientId) {
			this.router.navigate(['/dashboard']);
		}
		// if (token) {
		// 	this.getUserInfo();
		// }
		let errorMessage = localStorage.getItem('logoutMessage')
		if (errorMessage) {
			Swal({
				position: 'top-end',
				type: 'error',
				title: errorMessage,
				showConfirmButton: false,
				timer: 5000
			  })
		}
		setTimeout(() => {
			localStorage.removeItem('logoutMessage')
		}, 5000);


	    // localStorage.clear();
	}

	login(){
		// this.userForm.password = window.btoa(this.userForm.password);
       	// this.apiService.login('userauth/oauth/token', this.userForm).subscribe(
			localStorage.removeItem('logoutMessage')
			let obj ={
				username: this.userForm.username.trim(),
				password: window.btoa(this.userForm.password),
				grant_type: this.userForm.grant_type
			}
       	this.apiService.login('userauth/oauth/login', obj).subscribe(
			data => {
				if(data.status == 200){
					localStorage.setItem("Token", data["_body"]);
					
					let dataObj = JSON.parse(localStorage.getItem('Token')) //data["_body"]
					// this.cookieService.set("refresh_token",dataObj.refresh_token)
					document.cookie = "refresh_token="+dataObj.refresh_token+"; expires=" + new Date(moment().add(30, 'days')).toUTCString();
					  let UserName = dataObj ? ( dataObj.username.indexOf('@')>=0 ? dataObj.username.split('@') : dataObj.username.split('@')) : dataObj.username.split('@')

					// let UserName = dataObj.username
					let domain = UserName[1]
					localStorage.setItem('domain',domain)

					this.getUserInfo();
				}else {
          Swal({
    				position: 'top-end',
    				type: 'error',
    				title: data,
    				showConfirmButton: false,
    				timer: 1500
    			  })
        }

			},
			error => {
				if(error.error_description){
					this.errorMsg = error.error_description
				}else{
					this.errorMsg = "Please check your network connection."
				}
				this.alertService.errorMessage(error, error.status, this.errorMsg ? this.errorMsg : "Something went wrong, please try again ");
		    }
		);
    }

getUserInfo(){
	this.resMessage = null;
	 	this.apiService.getAll('user/getUserInfo').subscribe(
		    	data => {
				if(data.statusCode == 100){

					if (this.rememberMe) {
                        this.cookieService.set('#1bcd3',this.encryptPassword(this.userForm.username.trim()));
						this.cookieService.set('zxcv',this.encryptPassword(this.userForm.password));
						// this.cookieService.set('islogin',"true");
						document.cookie = "islogin=true; expires="+ new Date(moment().add(600, 'days')).toUTCString();
						
                    }else{
                        this.cookieService.delete('#1bcd3');
						this.cookieService.delete('zxcv');
                        this.cookieService.delete('islogin');
						
                    }


					// if (this.rememberMe) {
                    //     this.cookieService.set('#1bcd3',window.btoa(this.userForm.username) );
					// 	this.cookieService.set('zxcv',window.btoa(this.userForm.password));
					// 	document.cookie = "islogin=;"//+data.refresh_token
        			// 	document.cookie = "islogin="+true
                    // }else{
                    //     this.cookieService.delete('#1bcd3');
					// 	this.cookieService.delete('zxcv');
                    //     this.cookieService.delete('islogin');
						
                    // }
					ApiService.permission = data.response.permissions;
                    if(ApiService.permission){
                    	ApiService.isPermission = 1;
                    }
                    localStorage.setItem("permission",JSON.stringify(data.response.permissions));

					localStorage.setItem("userData", JSON.stringify(data.response));
					localStorage.setItem("userId", data.response.userId);
					localStorage.setItem("clientId", data.response.defaultClientId);
					localStorage.setItem("clientCode", data.response.clients[0].clientCode);
					this.router.navigate(['/dashboard']);
					// Swal({
					//   position: 'top-end',
					//   type: 'success',
					//   title: 'Login Successful',
					//   showConfirmButton: false,
					//   timer: 1500
					// })
				}else if(data.statusCode == 105){
				 var msg = data.responseMessage;
				 this.resMessage = data.responseMessage
				 this.logout(msg);
			 }else{
				this.resMessage = data.responseMessage
			 }
    	},
    	error => {
			this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    	}
	);
  	}


    logout(msg) {
    	this.apiService.logout('userauth/oauth/revokeToken').subscribe(
    		data => {
    		   if(data.status == 200) {
    			Swal({
    				position: 'top-end',
    				type: 'error',
    				title: msg,
    				showConfirmButton: false,
    				timer: 1500
    			  })
					localStorage.clear();
					this.cookieService.delete('islogin');					
    			} else {
    			}
    		},
    		error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    		}
    		);
    }


    // Getting Logged-In User Data
 	getLoginData(){
		this.logoClass = false;
		 var url = this.apiService.appUrl;
    	this.apiService.getLoginDatawithoutToken('client/getClientByDomain?domain='+url).subscribe(
	     	data => {
					if(data.statusCode == 100){
							this.loginPageContentData=data.response.loginPageContent;
							if(data.response.logoFile){
								this.imageFile = data.response.logoFile;
							}else{
								this.imageFile = "assets/img/default_logo.png";
								this.logoClass = true;
							}
							// Loader Image for client
							this.loaderImg = data.response.loaderImg;
							if (this.loaderImg) {
								localStorage.setItem("loaderImg", this.loaderImg);
							}
							document.getElementById('imageData').setAttribute( 'src',data.response.logoFile ? this.apiService.imageBasePath+this.imageFile :this.imageFile );
					}else{
						this.imageFile = "assets/img/default_logo.png";
						localStorage.removeItem("loaderImg");
						this.logoClass = true;
						document.getElementById('imageData').setAttribute( 'src',this.imageFile );
					}
	      },
	      error => {
			// this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
	      }
    	);
  	}

  	// Function to Encrypt Password
    encryptPassword(value){
		var encrypt = CryptoJS.AES.encrypt(value, 'TheBestSecretKey');
		return encrypt;
	}

	// Function to Decrypt Password
	decryptPassword(value){
		var bytes  = CryptoJS.AES.decrypt(value.toString(), 'TheBestSecretKey');
		var text = bytes.toString(CryptoJS.enc.Utf8);
		return text;

	}

}
