import { Component, OnInit, Input, ViewChild, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AlertService, ApiService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { range } from 'rxjs';
import { indexOf } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { jqxTreeComponent } from 'src/app/structure/admin/jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from 'src/app/structure/admin/jqwidgets-ts/angular_jqxdropdownbutton';
import * as _ from "lodash"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
// am4core.options.autoDispose = true;


// am4core.useTheme(am4themes_animated);
import { LoadingBarService } from '@ngx-loading-bar/core';
import { delay, finalize } from 'rxjs/operators';


declare var $: any, moment: any;
@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {


    w100: any = "w-100"

    @ViewChild('myTree') myTree: jqxTreeComponent;
    // @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
    dataAdapter: any = [];
    source: any = {};
    data: any = [];
    records: any = [];
    disableTreeArray: any = [];
    checkTreeArray: any = []

    @ViewChild('dataForm') dataForm: any

    @Input('name') name: any
    @Input('index') index: any
    @Input('widgetObj') widgetObj: any
    @Input('isPreview') isPreview: any;

    treeSelectedItem: any

    tierName: any = ""
    siteName: any = ""
    assetName: any = ""
    timeRange: any = 1
    dataPoint: any = ""
    selectedDataPoint: any = [];
    selectedDataPointId: any = []

    clientId: any = null;
    userId: any = null;

    tierList: any = [];
    siteList: any = [];
    assetList: any = [];
    rangeArrayList: any = [
        { value: 1, label: "past 1 day" },
        { value: 7, label: "past 7 days" },
        { value: 15, label: "past 15 days" },
        { value: 30, label: "past 30 days" },
        { value: 60, label: "past 60 days" },
        { value: 100, label: "past 100 days" },
        { value: 365, label: "past 1 year" },
        { value: 730, label: "past 2 years" }
    ]

    assetstatusPieChartSite: any = []
    DataPointList = [];

    ShowFormValue = false;
    fusionChartArrayDataObj: any = {}

    dropdownSettingsDatapoint = {
        singleSelection: false,
        idField: 'assetChannelId',
        textField: 'value',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };
    
    

    dropdownSettingsTierList = {
        singleSelection: false,
        idField: 'tierId',
        textField: 'tierName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };
    selectedTier: any = [];

    dropdownSettingsSiteList = {
        singleSelection: false,
        idField: 'siteId',
        textField: 'siteName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };
    selectedSite: any = [];

    dropdownSettingsAssetUnitList = {
        singleSelection: false,
        idField: 'Rcpid',
        textField: 'assetNameUnit',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };
    dropdownSettingsAssetUnitListSingleSelect = {
        singleSelection: false,
        idField: 'Rcpid',
        textField: 'assetNameUnit',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };

    dropdownSettingsAssetUnitListUPDOWMFaultCode = {
        singleSelection: false,
        idField: 'assetId',
        textField: 'assetNameUnit',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };

    dropdownSettingsAssetList = {
        singleSelection: false,
        idField: 'assetId',
        textField: 'assetName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };

    upDownTimeFirstFaultCodeDropdownSettingsAssetList = {
        singleSelection: true,
        idField: 'assetId',
        textField: 'assetName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };

    selectedAssetList: any = [];

    chartObj: any;
    amchartObj: any;
    handler: any;
    /**---UpDownFirstFaultCode variables----- */
    assetNameforUpDownFirstFaultCode: any = ''
    runtimeValueforUpDownFirstFaultCode: any = ''
    runtimePercentforUpDownFirstFaultCode: any = ''
    downtimeValueforUpDownFirstFaultCode: any = ''
    downtimePercentforUpDownFirstFaultCode: any = ''
    standbyValueforUpDownFirstFaultCode: any = ''
    standbyPercentforUpDownFirstFaultCode: any = ''

    // ---------------------asset status popup table variables
    assetDatalistPopup: any = []
    // -------------------------- asset event graph table variables
    eventDatalist: any = []
    // ?-------------------------- helth check Var 
    healthCheckList = [];
    dataTable: any;
    // ?-------------------------- first fault chart popup list Var 
    firstFaultChartPopupList: any = []
    upDownfirstFaultChartPopupList: any = [];
    HistoryList: any = [];
    isShowAsset: boolean = false;
    isShowDatapoint: boolean = false;
    isShowSite: boolean = false;
    isShowRange: boolean = false;
    isShowUnitNumber: boolean = false;


    constructor(private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService, private loading: LoadingBarService, @Inject(PLATFORM_ID) private platformId, private zone: NgZone,
        private alertService: AlertService, ) { }

    ngOnInit() {
        console.log(this.name, this.widgetObj, "---------------------widgetObj");
        // this.startLoading();

        this.clientId = localStorage.getItem("clientId");
        this.userId = localStorage.getItem("userId");

        this.fusionChartArrayDataObj.width = "100%";
        this.fusionChartArrayDataObj.height = "100%";
        this.fusionChartArrayDataObj.dataFormat = 'json';
        if (this.name == 'Asset Status Pie Chart') {

            // this.tierName = 191
            // this.siteName = 52

            this.fusionChartArrayDataObj.type = 'doughnut3d';
            this.fusionChartArrayDataObj.dataSource = {
                "chart": {
                    // "defaultcenterlabel": ,
                    "showlegend": "1",
                    "showpercentvalues": "0",
                    "aligncaptionwithcanvas": "0",
                    "captionpadding": "0",
                    "decimals": "1",
                    // "plottooltext": "<b>$Value</b> of Assets are <b>$label</b>",
                    // "plottooltext": "Status.",
                    "showPlotBorder": "2",
                    "showShadow": "2",
                    "use3DLighting": "2",
                    "theme": "fusion"
                },
                "data": []
            };
            let dataObj: any = {}
            if (this.widgetObj.tiers/*  && this.widgetObj.tiers.length */) {
                this.tierName = this.widgetObj.tiers//[0]
                this.StatusPieChartTierChangeGetSite({ target: { value: this.tierName } })
                dataObj.tier = this.widgetObj.tiers
                this.selectedTier = this.widgetObj.tiers

                this.checkTreeArray = this.widgetObj.tiers
                // this.treeSelectedItem = this.widgetObj.tier

            }
            if (this.widgetObj.sites /* && this.widgetObj.sites.length */) {
                this.siteName = this.widgetObj.sites[0]
                dataObj.site = this.widgetObj.sites
                this.selectedSite = this.widgetObj.sites;
            }

            this.getStatusPieChart(dataObj)
        }
        else if (this.name == 'Event Status') {
            this.fusionChartArrayDataObj.type = 'column3d';
            this.fusionChartArrayDataObj.dataSource = {
                "chart": {
                    "caption": "",
                    "subcaption": "",
                    "showlegend": "1",
                    "xaxisname": "Event Status",
                    "yaxisname": "Number of Events",
                    "theme": "fusion",
                },
                "data": []
            };
            let dataObj: any = {}
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
                this.tierName = this.widgetObj.tiers[0]
                this.StatusPieChartTierChangeGetSite({ target: { value: this.tierName } })
                // dataObj.tier = this.tierName
                this.FirstFaultPieChartTierChangeGetAsset({ target: { site: this.selectedSite } })

                dataObj.tier = this.widgetObj.tiers

                this.selectedTier = this.widgetObj.tiers

                this.checkTreeArray = this.widgetObj.tiers
            }
            // if (this.widgetObj.sites && this.widgetObj.sites.length) {
            //     this.siteName = this.widgetObj.sites[0]
            //     dataObj.site = this.widgetObj.sites
            //     // dataObj.site = this.siteName;
            //     this.selectedSite = this.widgetObj.sites;
            //     this.FirstFaultPieChartTierChangeGetAsset({target:{site:this.selectedSite}})
            // }
            if (this.widgetObj.assets && this.widgetObj.assets.length) {
                this.siteName = this.widgetObj.assets//[0]
                dataObj.asset = this.widgetObj.assets
                // dataObj.site = this.siteName;
                this.selectedAssetList = this.widgetObj.assets;
                // this.FirstFaultPieChartTierChangeGetAsset({target:{site:this.selectedSite}})
            }

            this.getEventBargraph(dataObj)
        }
        else if (this.name == 'First Fault pie chart summary') {
            this.fusionChartArrayDataObj.type = 'pie3d';
            this.fusionChartArrayDataObj.dataSource = {
                chart: {
                    caption: "First Fault Summary",
                    plottooltext: "$label, <b>$percentValue</b> ",
                    showlegend: "1",
                    showpercentvalues: "1",
                    legendposition: "bottom",
                    usedataplotcolorforlabels: "1",
                    theme: "fusion"
                },
                "data": []
            };
            let dataObj: any = {}
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
                this.tierName = this.widgetObj.tiers//[0]
                this.FirstFaultPieChartTierChangeGetAsset({ target: { value: this.tierName } })
                // dataObj.tier = this.tierName
                dataObj.tier = this.widgetObj.tiers
                this.selectedTier = this.widgetObj.tiers

            }
            if (this.widgetObj.assets && this.widgetObj.assets.length) {
                this.assetName = this.widgetObj.asset//[0]
                dataObj.asset = this.widgetObj.assets
                this.selectedAssetList = this.widgetObj.assets
                // dataObj.asset = this.assetName
            }
            if (this.widgetObj.dashboardWidget && this.widgetObj.dashboardWidget.timeRange) {
                this.timeRange = this.widgetObj.dashboardWidget.timeRange;
                dataObj.range = this.timeRange
                this.getFirstFaultgraph(dataObj)
                this.ShowFormValue = false;
            } else {
                this.ShowFormValue = true;
            }
        }
        else if (this.name == 'Gauge Chart') {
            this.fusionChartArrayDataObj.width = "95%";

            this.fusionChartArrayDataObj.type = 'angulargauge';
            this.fusionChartArrayDataObj.dataSource = {
                // chart: {
                //     caption: "",
                //     lowerlimit: "0",
                //     upperlimit: "100",
                //     showvalue: "1",
                //     // numbersuffix: "%",
                //     theme: "fusion",
                //     showtooltip: "0"
                //   },
                //   colorrange: {
                //     color: [
                //     //   {
                //     //     minvalue: "0",
                //     //     maxvalue: "50",
                //     //     code: "#F2726F"
                //     //   },
                //     //   {
                //     //     minvalue: "50",
                //     //     maxvalue: "75",
                //     //     code: "#FFC533"
                //     //   },
                //     //   {
                //     //     minvalue: "75",
                //     //     maxvalue: "100",
                //     //     code: "#62B58F"
                //     //   }
                //     ]
                //   },
                //   dials: {
                //     dial: [
                //     //   {
                //     //     value: "1"
                //     //   }
                //     ]
                //   }
            };

            let dataObj: any = {}
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
                this.tierName = this.widgetObj.tiers//[0]
                this.FirstFaultPieChartTierChangeGetAsset({ target: { value: this.tierName } })
                dataObj.tier = this.tierName

                this.selectedTier = this.widgetObj.tiers
            }
            if (this.widgetObj.assets && this.widgetObj.assets.length) {
                this.assetName = this.widgetObj.assets[0] ? this.widgetObj.assets[0].assetId : ""
                dataObj.assetId = this.widgetObj.assets//this.assetName
                this.selectedAssetList = this.widgetObj.assets;
                this.getDataPointListOnAssetChange({ target: { value: this.widgetObj.assets } })
            }
            if (this.widgetObj.datapoints && this.widgetObj.datapoints.length) {
                this.dataPoint = this.widgetObj.datapoints[0].assetChannelId//this.widgetObj.datapoints//[0];
                dataObj.assetChannelId = this.widgetObj.datapoints[0].assetChannelId
                this.selectedDataPoint = this.widgetObj.datapoints;
                this.ShowFormValue = false;
            } else {
                this.ShowFormValue = true;
            }

            this.getGaugeChartData(dataObj)
        }
        else if (this.name == 'Multiple data point last values bar graph') {
            this.fusionChartArrayDataObj.type = "column3d";
            this.fusionChartArrayDataObj.dataSource = {
                "chart": {
                    "caption": "Multiple data point last values bar graph",
                    "showlegend": "1",
                    "xaxisname": "Data Point",
                    // "labeldisplay" : "ROTATE",
                    plottooltext: "Last time : $lastEventTime<br> $label, <b>$Value</b>",
                    usePlotGradientColor: 0,
                    "allowAxisChange": 1,

                    // "yaxisname": "",
                    // "numberPrefix": "$",
                    "theme": "fusion"
                },
                "data": []
            };
            let dataObj: any = {}
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
                this.tierName = this.widgetObj.tiers//[0]
                this.FirstFaultPieChartTierChangeGetAsset({ target: { value: this.tierName } })
                dataObj.tier = this.tierName;
                this.selectedTier = this.widgetObj.tiers;
            }
            if (this.widgetObj.assets && this.widgetObj.assets.length) {
                this.assetName = this.widgetObj.assets//[0]
                dataObj.asset = this.assetName
                this.selectedAssetList = this.widgetObj.assets;
                this.getDataPointListOnAssetChange({ target: { value: this.assetName } }, true);
            }
            if (this.widgetObj.datapoints && this.widgetObj.datapoints.length) {
                // this.selectedDataPoint = this.widgetObj.datapoints
                let arr = []
                this.widgetObj.datapoints.map((val, index) => {
                    arr.push({ assetChannelId: val })
                })
                this.selectedDataPoint = this.widgetObj.datapoints
                this.getMultipleDataPointChart({ dataPoint: this.selectedDataPoint })
                this.ShowFormValue = false;
            }
            else {
                this.ShowFormValue = true;
            }
            // this.getFirstFaultgraph(dataObj)
        }
        else if (this.name == 'System Health Monitor') {
            this.fusionChartArrayDataObj.type = "pie3d";
            this.fusionChartArrayDataObj.dataSource = {
                "chart": {
                    //caption: "Market Share of Web Servers",
                    plottooltext: "<b>$percentValue</b> of web servers run on $label servers",
                    showlegend: "1",
                    showpercentvalues: "1",
                    legendposition: "bottom",
                    usedataplotcolorforlabels: "1",
                    theme: "fusion",
                    // width: '600', 
                    // height: '200',
                },
                "data": []
            };
            let dataObj: any = {}
            // if (this.widgetObj.tier.length) {
            //     this.tierName = this.widgetObj.tier[0]
            //     this.FirstFaultPieChartTierChangeGetAsset({ target: { value: this.tierName } })
            //     dataObj.tier = this.tierName
            // }
            // if (this.widgetObj.asset.length) {
            //     this.assetName = this.widgetObj.asset[0]
            //     dataObj.asset = this.assetName
            //     this.getDataPointListOnAssetChange({ target: { value: this.assetName } }, true);
            // }
            // if (this.widgetObj.datapoint.length) {
            //     this.selectedDataPointId = this.widgetObj.datapoint
            //     let arr = []
            //     this.widgetObj.datapoint.map((val, index) => {
            //         arr.push({ assetChannelId: val })
            //     })
            // }
            this.getHealthCheckPieChart()
        }
        else if (this.name == 'Up-Time/Downtime runtime chart') {
            this.fusionChartArrayDataObj.type = "mscolumn3d";
            this.fusionChartArrayDataObj.dataSource = {
            };
            let dataObj: any = {}
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
                this.tierName = this.widgetObj.tiers//[0]
                this.TierChangeGetAssetUnitDropdownList({ target: { value: this.tierName } })
                dataObj.tier = this.tierName

                dataObj.tier = this.widgetObj.tiers
                this.selectedTier = this.widgetObj.tiers
            }
            // if (this.widgetObj.assets && this.widgetObj.assets.length) {
            //     this.assetName = this.widgetObj.assets//[0]
            //     dataObj.asset = this.assetName

            //     this.selectedAssetList = this.widgetObj.assets
            //     // this.getDataPointListOnAssetChange({ target: { value: this.assetName } }, true);
            // }
            if (this.widgetObj.rcps && this.widgetObj.rcps.length) {
               
                let rcpList = []; 
                for (const iterator of this.widgetObj.rcps) {
                    rcpList.push({Rcpid:iterator.runTimeCollectionPointId})
                }
                // this.assetName = this.widgetObj.asset//[0]
                dataObj.asset = rcpList;
                this.selectedAssetList = rcpList;
               
                // dataObj.asset = this.assetName
            }
            if (this.widgetObj.dashboardWidget && this.widgetObj.dashboardWidget.timeRange) {
                this.timeRange = this.widgetObj.dashboardWidget.timeRange;
                dataObj.range = this.timeRange
            }
            if (this.timeRange && this.tierName) {
                this.getUpDownChart(dataObj)
                this.ShowFormValue = false;
            } else {
                this.ShowFormValue = true;
            }
        } 
        else if (this.name == 'UP/Down time first fault code') {
            this.assetNameforUpDownFirstFaultCode = '';

          
            let dataObj: any = {}
            console.log('obj----',this.widgetObj)
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
                this.tierName = this.widgetObj.tiers//[0]

                this.TierChangeGetAssetUnitDropdownList({ target: { value: this.tierName } })

                // dataObj.tier = this.tierName
                dataObj.tier = this.widgetObj.tiers
                this.selectedTier = this.widgetObj.tiers

            }
            // if (this.widgetObj.assets && this.widgetObj.assets.length) {
            //     this.assetName = this.widgetObj.asset//[0]
            //     dataObj.asset = this.widgetObj.assets
            //     this.selectedAssetList = this.widgetObj.assets
               
            //     // dataObj.asset = this.assetName
            // }
            if (this.widgetObj.rcps && this.widgetObj.rcps.length) {
                
                this.assetNameforUpDownFirstFaultCode = this.widgetObj.rcps[0].assetNameUnit;
                let rcpList = []; 
                for (const iterator of this.widgetObj.rcps) {
                    rcpList.push({Rcpid:iterator.runTimeCollectionPointId, assetNameUnit:iterator.assetNameUnit})
                }
                // this.assetName = this.widgetObj.asset//[0]
                dataObj.asset = rcpList;
                this.selectedAssetList = rcpList;
               
                // dataObj.asset = this.assetName
            }

            if (this.widgetObj.dashboardWidget && this.widgetObj.dashboardWidget.timeRange) {
                this.timeRange = this.widgetObj.dashboardWidget.timeRange;
                dataObj.range = this.timeRange
                // this.getFirstFaultgraph(dataObj)
                this.getUpDownTimeFirstFaultCode(dataObj)
                
                
                this.ShowFormValue = false;
            } else {
                this.ShowFormValue = true;
            }
        }


        // if (condition) {

        // } else {

        // }
        this.getTierManagementList();
    }

    getUpDownTimeFirstFaultCode(obj) {
        this.runtimeValueforUpDownFirstFaultCode = 0;
        this.runtimePercentforUpDownFirstFaultCode = 0;

        this.downtimePercentforUpDownFirstFaultCode = 0;
        this.downtimeValueforUpDownFirstFaultCode = 0;
        this.standbyPercentforUpDownFirstFaultCode = 0;
        this.standbyValueforUpDownFirstFaultCode = 0;

        if (this.chartObj) {
            // this.chartObj.dispose()
            this.chartObj.removeEventListener('dataplotClick', this.handler);
        }
        // {
        //     "clientId": "104",
        //      "tierIds": "137,139,141,150,151,155",
        //      "assetIds": "26099,26101,26099,26101",
        //      "days": "89",
        //      "type": "Detail"
        //   }
        // var URL = 'runTimeEvent/getUPDowntimefirstfaultcode'
        var URL = 'runTimeEvent/getUPDowntimefirstfaultcodesummary'
        let apiData: any = {
            clientId: this.clientId,
            type: "Summary"
        }

        if (obj.tier && obj.tier.length) {
            let tierIds = []
            obj.tier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            apiData.tierIds = tierIds.toString();

            // var tierString = '&tierIds=' + tierIds.toString();
            // var URL = URL.concat(tierString);
        }
        // if (obj.asset && obj.asset.length) {
        //     let assetIds = []
        //     obj.asset.map((obj, index) => {
        //         assetIds.push(obj.assetId)
        //     })
        //     apiData.assetIds = assetIds.toString();
            
        //     this.assetNameforUpDownFirstFaultCode =  obj.asset[0].assetName/* dataForm.value.asset[0]['assetName']; */
        //     // var assetString = '&assetIds=' + assetIds.toString();
        //     // var URL = URL.concat(assetString);
        // }

        if (obj.asset && obj.asset.length) {
            let assetIds = []
            obj.asset.map((obj, index) => {
                assetIds.push(obj.Rcpid)
            })
            apiData.rcpIds = assetIds.toString();
            this.assetNameforUpDownFirstFaultCode =  obj.asset[0].assetNameUnit/* dataForm.value.asset[0]['assetName']; */
        }

        if (obj.range) {
            var valString = '&days=' + obj.range;
            apiData.days = obj.range;
            // var URL = URL.concat(valString);
            // this.ShowFormValue = false;
        } else {
            this.ShowFormValue = true;
            var valString = '&days=1' //+ obj.range;
            // var URL = URL.concat(valString);
            this.ShowFormValue = false;
            this.timeRange = 1
            // return
        }
        this.startLoading();
        this.apiService.create(URL, apiData).pipe(delay(5000),finalize(()=> {
            this.stopLoading();
        })).subscribe(
            data => {
                this.stopLoading();
                if (data.statusCode == 100 || data.statusCode == 103 || data.statusCode == 101) {
                    console.log('hit')
                
                /* "totalD": "80.1",
                "totalDTH": "3.34",
                "totalDTP": "3.34",
                "totalRTH": "2319.5",
                "totalRTP": "2400.0" */
                this.runtimeValueforUpDownFirstFaultCode = data.totalRTH;
                this.runtimePercentforUpDownFirstFaultCode = data.totalRTP;

                this.downtimePercentforUpDownFirstFaultCode = data.totalDTP;
                this.downtimeValueforUpDownFirstFaultCode = data.totalDTH;
                this.standbyPercentforUpDownFirstFaultCode = data.totalSTP;
                this.standbyValueforUpDownFirstFaultCode = data.totalSTH;
            

                    let resData: any = data.responseList ? data.responseList : [];
                    let dataObj: any = []
                    if((resData.length || data.totalRTH) && data.statusCode == 100) {
                        dataObj.push({
                            label: 'Up-Time',
                            value: data.totalRTH
                        })
                    }
                     if((resData.length || data.totalSTH) && data.statusCode == 100) {
                        dataObj.push({
                            label: 'Stand By',
                            value: data.totalSTH
                        })
                    }
                    resData.map(item => {
                        let obj = {
                            label: (item.firstFaultCodeDescription ? item.firstFaultCodeDescription : 'NA' ) + "("+item.firstFaultCode+")",
                            value: item.downtimeHours,
                        };
                        dataObj.push(obj);
                    })
                 
                    console.log('dataObj------------>',dataObj)

                    let self = this

                    let chart = am4core.create("chart_amchart_" + this.index, am4charts.PieChart3D);
                    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
                    // chart.data = valueArr
                    chart.data = dataObj

                    let legend = new am4charts.Legend();
                    legend.position = "right"
                    legend.labels.template.fontSize = 10
                    legend.valueLabels.template.textAlign = "start";
                    legend.itemContainers.template.tooltipText = "{category}";
                    legend.paddingBottom = 10
                    legend.paddingLeft = -20
                    legend.paddingRight = 10
                    legend.valueLabels.template.disabled = true;
                    legend.labels.template.text = "{category}";
                    chart.legend = legend   

                    chart.colors.list = [
                        am4core.color("#b85f40"),
                        am4core.color("#2d802f"),
                        am4core.color("#cb9918"),
                        am4core.color("#52a39f"),
                        am4core.color("#222222"),
                    ];
                    let series = chart.series.push(new am4charts.PieSeries3D());
                    series.dataFields.value = "value";
                    series.dataFields.category = "label";
                    series.configField = "config";
                    chart.radius = am4core.percent(70)

                    series.slices.template.tooltipText = "{category}:{value.percent.formatNumber('#.000')}%({value} Hours)";
                 
                    series.slices.template.events.on("hit", function (ev) {
                        let series = ev.target.dataItem.component;
                        console.log(ev.target.dataItem);
                        
                        self.clickEvenUpDownFirstFaltChart(ev.target.dataItem)
                  
                    });
                    series.alignLabels = true;
                    series.labels.template.radius = am4core.percent(10);
                    //   series.ticks.template.disabled = true;
                    series.labels.template.text = "{value.percent.formatNumber('#.000')}%";//"{value}";//
                    //   series.minBulletDistance = 100
                    series.labels.template.fontSize = 8;
                    series.slices.template.fontSize = 8
                
                    var markerTemplate = chart.legend.markers.template;
                    markerTemplate.width = 10;
                    markerTemplate.height = 10;

                    // chart.legend.
                    if (data.statusCode != 100) {
                        let indicator = chart.tooltipContainer.createChild(am4core.Container);
                        indicator.background.fill = am4core.color("#fff");
                        indicator.background.fillOpacity = 0.8;
                        indicator.width = am4core.percent(100);
                        indicator.height = am4core.percent(100);

                        var indicatorLabel = indicator.createChild(am4core.Label);
                        indicatorLabel.text = "No Record Found";
                        indicatorLabel.align = "center";
                        indicatorLabel.valign = "middle";
                        indicatorLabel.fontSize = 20;
                    }





                    // this.fusionChartArrayDataObj.dataSource.data = JSON.parse(JSON.stringify(valueArr))



                    this.handler = this.clickEvenFirstFaltChart.bind(this);
                  
                } else {
                    this.fusionChartArrayDataObj.dataSource.data = [];
                }
            },
            error => {
                this.stopLoading();
                // this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }


    clickEvenUpDownFirstFaltChart(event) {
        // this.selectedAssetList

        // this.assetList
        //  _.find(users, function(o) { return o.age < 40; });
        let assetUnit = []
        for (const iterator of this.selectedAssetList) {
            let objectData  = _.find(this.assetList, function(o) { return o.Rcpid == iterator.Rcpid; });
            if(objectData)
                assetUnit.push(objectData.Rcpid)
        }
// console.log(assetUnit);

        // console.log('***********************=',this.isPreview, event.category.split("("), this.widgetObj);
        if (!this.isPreview) {
            // http://localhost:8764/api/v1/runTimeEvent/getUPDowntimefirstfaultcode
            var URL = "runTimeEvent/getUPDowntimefirstfaultcodeDetail";
            /* 
                "clientId": "104",
                "type": "Detail",
                "tierIds": "137,139,141,150,151,155",
                "assetIds":"22524,20694",
                "days": 110,
                "firstFaultDec":"Volts"
              } */
              let category = event.category.split("(");
              let apiData: any = {
                  clientId : this.clientId,
                  type: "Detail"                 
              }
              if(category[0].length) {
                  if(!(category[0] == 'NA')) {
                      apiData.firstFaultDec = category[0]                    
                  }                  
              }
              if(apiData.firstFaultDec == "Up-Time") {
                  return;
              }
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {

                let tierIds = []
                this.widgetObj.tiers.map((obj, index) => {
                    tierIds.push(obj.tierId)
                })
                apiData.tierIds = tierIds.toString();
                // var tierString = '&tierIds=' + tierIds.toString();
                //   var tierString = '&tierId=' + this.widgetObj.tier.toString();
                // var URL = URL.concat(tierString);
            }
            // if (this.widgetObj.assets && this.widgetObj.assets.length) {
            //     let assetIds = []
            //     this.widgetObj.assets.map((obj, index) => {
            //         assetIds.push(obj.assetId)
            //     })
            //     // apiData.assetIds =  assetIds.toString();
                
                
            //     // var assetString = '&assetIds=' + assetIds.toString();
            //     // var siteString = '&siteId=' + this.widgetObj.site.toString();
            //     // var URL = URL.concat(assetString);
            // }
            apiData.rcpIds =  assetUnit.toString();
            if (this.widgetObj.dashboardWidget && this.widgetObj.dashboardWidget.timeRange) {
                apiData.days = this.widgetObj.dashboardWidget.timeRange;
                // var timeRange = '&days=' + this.widgetObj.dashboardWidget.timeRange;
                // var URL = URL.concat(timeRange);
            }
            // /runTimeEvent/getFirstFaultDetail?days=730&tierIds=278&firstFaultDec=MANUAL SHUTDOWN&assetId=24383

            $('#UpDownFirstFaultChartpopupListModel' + this.index).modal("show");

            this.apiService.create(URL, apiData).subscribe(
                data => {
                    this.stopLoading();
                    if (data.statusCode == 100) {


                        this.upDownfirstFaultChartPopupList = JSON.parse(JSON.stringify(data.responseList));

                        // this.chRef.detectChanges();
                        if ($.fn.DataTable.isDataTable('#UpDownFirstFaultChartpopupList' + this.index)) {
                            $('#UpDownFirstFaultChartpopupList' + this.index).DataTable().destroy();
                        }
                        setTimeout(() => {
                            const table: any = $('#UpDownFirstFaultChartpopupList' + this.index);
                            this.dataTable = table.DataTable({
                                "columnDefs": [/* {
                                    "orderable": false,
                                    "targets": 2
                                } */]
                            }
                            );
                        }, 1000);

                    }
                    else {
                        this.upDownfirstFaultChartPopupList = [];
                        if ($.fn.DataTable.isDataTable('#UpDownFirstFaultChartpopupList' + this.index)) {
                            $('#UpDownFirstFaultChartpopupList' + this.index).DataTable().destroy();
                            this.upDownfirstFaultChartPopupList = [];
                        }
                        setTimeout(() => {
                            const table: any = $('#UpDownFirstFaultChartpopupList' + this.index);
                            this.dataTable = table.DataTable(
                                {
                                    "columnDefs": [/* {
                                        "orderable": false,
                                        "targets": 2
                                    } */]
                                }
                            );
                        }, 1000);

                    }
                },
                error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    }
    // ngDoCheck(): void {
    //     //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //     //Add 'implements DoCheck' to the class.
    //     console.log(this.treeSelectedItem);

    // }

    startLoading() {
        this.loading.start();
    }

    stopLoading() {
        this.loading.complete();
    }

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
        this.zone.runOutsideAngular(() => {
            f();
        });
        }
    }


    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.browserOnly(() => {
            if (this.chartObj) {
                this.chartObj.dispose();
            }
            if (this.amchartObj) {
                this.amchartObj.dispose()
            }
        })
    }

    showForm() {
        this.ShowFormValue = !this.ShowFormValue
    }

    tierChange() {
        this.siteName = null
        this.assetName = null
        this.dataPoint = null
        this.selectedSite = [];
        this.selectedAssetList = [];
        this.selectedDataPoint = [];
    }

    siteChange() {
        this.selectedAssetList = []
    }

    assetChange() {
        this.dataPoint = null
        this.selectedDataPoint = []
    }

    initializedChartD($event) {

        this.chartObj = $event.chart;
    }

    /* Get Tier Management list */
    getTierManagementList() {
        var URL = 'tier/getTiersByClient?clientId=' + this.clientId + '&userId=' + this.userId + '&alphabetSortingOrder=1'
        // var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
        this.apiService.getAll(URL).subscribe(
            data => {
                this.stopLoading();
                if (data.statusCode == 100) {
                    this.tierList = data.responseList;


                    // if (data.statusCode == 100) {
                    //     this.data = data.responseList ? data.responseList : [];
                    //     var self = this;

                    //     Object.keys(this.data).forEach(key => {
                    //         if (this.data[key]['flag'] == false) {
                    //             this.disableTreeArray.push(this.data[key]['tierId']);   
                    //         }
                    //         let isCheck = this.checkTreeArray.indexOf(this.data[key].tierId)
                    //         // console.log("selected --------------------------------------------",this.checkTreeArray.indexOf(this.data[key].tierId),this.data[key].tierId,this.checkTreeArray);
                    //         if (isCheck != -1) {
                    //             self.data[key].class = "widget"+this.index
                    //             console.log("selected --------------------------------------------  done");

                    //         }
                    //     });



                    //     this.source = {
                    //         datatype: 'json',
                    //         datafields: [{
                    //             name: 'tierId'
                    //         },
                    //         {
                    //             name: 'parentTierId'
                    //         },
                    //         {
                    //             name: 'tierName'
                    //         },
                    //         {
                    //             name: 'selected'
                    //         },
                    //         ],
                    //         id: 'tierId',
                    //         localdata: this.data
                    //     };
                    //     this.dataAdapter = new jqx.dataAdapter(this.source, {
                    //         autoBind: true
                    //     });
                    //     this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{
                    //         name: 'tierName',
                    //         map: 'label'
                    //     }, {
                    //         name: 'tierId',
                    //         map: 'id'
                    //     },{
                    //         name: 'selected',
                    //         map: 'selected'
                    //     }]);
                    //        console.log(this.disableTreeArray,this.source);

                    //     setTimeout(function() {
                    //         self.treeItemDisable();
                    //         // self.checkedCheckboxOnLoad();
                    //     }, 3000);
                    // // } 



                } else {
                    this.tierList = [];
                    let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> No Record Found</div>';
                    // this.myDropDownButton.setContent(dropDownContent);
                }
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    treeItemDisable() {
        console.log(this.disableTreeArray);

        Object.keys(this.disableTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.disableTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.disableItem(checkedItem);
        });
    }

    onOpen() {
        // if (!this.myDropDownButton.isOpened()) {
        //     this.myDropDownButton.open()
        // }
        $('div.dd > jqxtree > div.jqx-widget.jqx-widget-content.jqx-tree').css({ position: 'absolute' })
        setTimeout(() => {
            this.treeItemDisable();
            this.checkedCheckboxOnLoad();
        }, 500);
    }

    /* check tree checkbox */
    checkedCheckboxOnLoad() {
        console.log(this.checkTreeArray, "++++++++++++++++++", this.name);

        if (this.checkTreeArray) {
            Object.keys(this.checkTreeArray).forEach(key => {
                let ele = document.getElementById("Widget" + this.index)
                console.log(ele);

                // if (ele) {

                let checkedItem = document.getElementById(this.checkTreeArray[key]);
                // let checkedItem = $("#Widget"+this.index).document.getElementById(this.checkTreeArray[key]);
                this.myTree.expandItem(checkedItem);
                console.log(checkedItem);

                this.myTree.checkItem(checkedItem, true);
                // }
            });
        }
    }

    CheckChange(event) {
        console.log(this.treeSelectedItem);

        if (event.args.checked == true) {

            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist == '-1') {
                this.checkTreeArray.push(event.args.element.id);
                // localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
            }
        } else if (event.args.checked == false) {
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist != '-1') {
                this.checkTreeArray.splice(exist, 1);
                // localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
            }
        }
        if (this.checkTreeArray.length > 0) {
            let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"><span class="textRight">+' + this.checkTreeArray.length + ' </span></div>';
            // this.myDropDownButton.setContent(dropDownContent);
        } else {
            let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
            // this.myDropDownButton.setContent(dropDownContent);
        }

    }

    /* ------------------------------------------------------------------get Site list by tier */
    StatusPieChartTierChangeGetSite(event) {
        let tierIds = []
        setTimeout(() => {
            console.log(event/* .target.value, */, this.selectedTier);
            this.selectedTier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            if (tierIds.length) {
                var URL = 'site/getSiteByClient?alphabateSortingOreder=1&clientId=' + this.clientId + '&userId=' + this.userId + '&tierId=' + tierIds.toString();
                this.startLoading();
                this.apiService.getAll(URL).subscribe(
                    data => {
                        this.stopLoading();
                        if (data.statusCode == 100) {
                            this.siteList = data.responseList;
                        } else {
                            this.siteList = [];
                        }
                    },
                    error => {
                        this.stopLoading();
                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    }
                );
            } else {
                this.siteList = [];
            }
        }, 500);

    }

    /* ----------------------------------------------------------------------- get asset by tier */
    FirstFaultPieChartTierChangeGetAsset(event) {
        let tierIds = []
        setTimeout(() => {
            console.log(event/* .target.value, */, this.selectedTier);
            this.selectedTier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            // var URL = 'asset/getAssetDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId + "&isAssetLive=true";
            var URL = 'asset/getAssetDropDownByClient';
            let ObjData:any = {
                clientId: this.clientId,
                userId: this.userId
            }
            if (this.selectedTier.length) {
                // URL = URL + '&tierId=' + tierIds.toString();
                ObjData.tierId = tierIds.toString();
            }
            if (this.selectedSite.length) {
                let siteIds = []
                this.selectedSite.map((obj, index) => {
                    siteIds.push(obj.siteId)
                })
                if (siteIds.length) {
                    // URL = URL + "&siteId=" + siteIds.toString();
                    ObjData.siteId = siteIds.toString();

                }
            }

            if (tierIds.length) {
                this.startLoading();
                this.apiService.create(URL, ObjData).pipe(delay(5000),finalize(()=> {
                    this.stopLoading();
                })).subscribe(
                    data => {
                        this.stopLoading();
                        if (data.statusCode == 100) {
                            this.assetList = data.responseList;
                        } else {
                            this.assetList = [];
                        }
                    },
                    error => {
                        this.stopLoading();
                        this.alertService.errorMessage(error, error.status, "Can not get asset list, please try again");
                    }
                );
            } else {
                this.assetList = []
            }
        }, 500)

    }


    /* ----------------------------------------------------------------------- get asset /unit by tier */
    TierChangeGetAssetUnitDropdownList(event) {
        let tierIds = []
        setTimeout(() => {
            console.log(event/* .target.value, */, this.selectedTier);
            this.selectedTier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            // var URL = 'asset/getAssetDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId + "&isAssetLive=true";
            var URL = 'asset/getAssetDropDownUnitByClient';
            let ObjData:any = {
                clientId: this.clientId,
                userId: this.userId
            }
            if (this.selectedTier.length) {
                // URL = URL + '&tierId=' + tierIds.toString();
                ObjData.tierId = tierIds.toString();
            }
            if (this.selectedSite.length) {
                let siteIds = []
                this.selectedSite.map((obj, index) => {
                    siteIds.push(obj.siteId)
                })
                if (siteIds.length) {
                    // URL = URL + "&siteId=" + siteIds.toString();
                    ObjData.siteId = siteIds.toString();

                }
            }

            if (tierIds.length) {
                this.startLoading();
                this.apiService.create(URL, ObjData).pipe(delay(5000),finalize(()=> {
                    this.stopLoading();
                })).subscribe(
                    data => {
                        this.stopLoading();
                        if (data.statusCode == 100) {
                            this.assetList = data.responseList;
                        } else {
                            this.assetList = [];
                        }
                    },
                    error => {
                        this.stopLoading();
                        this.alertService.errorMessage(error, error.status, "Can not get asset list, please try again");
                    }
                );
            } else {
                this.assetList = []
            }
        }, 500)

    }

    formSubmit($event, id, dataForm, index) {
        let SaveObj: any = {
            selectedType: [],
            selectedValue: []
        }

        // tier ,asset ,dataPoint, range
        console.log(id, dataForm, index);
        if (this.name == 'Asset Status Pie Chart') {
            this.getStatusPieChart(dataForm.value)
            if (dataForm.value.tier) {
                SaveObj.selectedType.push('tier')
                SaveObj.selectedValue.push(dataForm.value.tier)
            }
            if (dataForm.value.site) {
                SaveObj.selectedType.push('site')
                SaveObj.selectedValue.push(dataForm.value.site)
            }
        } else if (this.name == 'Event Status') {
            this.getEventBargraph(dataForm.value)
            if (dataForm.value.tier) {
                SaveObj.selectedType.push('tier')
                SaveObj.selectedValue.push(dataForm.value.tier)
            }
            if (dataForm.value.site) {
                SaveObj.selectedType.push("site");
                SaveObj.selectedValue.push(dataForm.value.site)
            }
        } else if (this.name == 'First Fault pie chart summary') {
            this.getFirstFaultgraph(dataForm.value)
            if (dataForm.value.tier) {
                SaveObj.selectedType.push('tier')
                SaveObj.selectedValue.push(dataForm.value.tier)
            }
            if (dataForm.value.asset) {
                SaveObj.selectedType.push('asset')
                SaveObj.selectedValue.push(dataForm.value.asset)
            }
            if (dataForm.value.range) {
                SaveObj.selectedType.push('range')
                SaveObj.selectedValue.push(dataForm.value.range)
            }
        }
        else if (this.name == 'UP/Down time first fault code') {
            // this.getFirstFaultgraph(dataForm.value)
            this.assetNameforUpDownFirstFaultCode = ''
            this.getUpDownTimeFirstFaultCode(dataForm.value)
            if (dataForm.value.tier) {
                SaveObj.selectedType.push('tier')
                SaveObj.selectedValue.push(dataForm.value.tier)
            }
            // if (dataForm.value.asset) {
            //     SaveObj.selectedType.push('asset')
            //     SaveObj.selectedValue.push(dataForm.value.asset)        
            // }
            if (dataForm.value.asset) {
                SaveObj.selectedType.push('rcpids')
                let rcp = JSON.parse(JSON.stringify(dataForm.value.asset))
                delete dataForm.value.asset;
                dataForm.value.rcpids = rcp;
                SaveObj.selectedValue.push(dataForm.value.rcpids)        
            }
            if (dataForm.value.range) {
                SaveObj.selectedType.push('range')
                SaveObj.selectedValue.push(dataForm.value.range)
            }
        }
        else if (this.name == 'Gauge Chart') {
            this.getGaugeChartData(dataForm.value)
            if (dataForm.value.tier) {
                SaveObj.selectedType.push('tier')
                SaveObj.selectedValue.push(dataForm.value.tier)
            }
            if (dataForm.value.asset) {
                SaveObj.selectedType.push('asset')
                SaveObj.selectedValue.push(dataForm.value.asset)
            }
            if (dataForm.value.assetChannelId) {
                SaveObj.selectedType.push('dataPoint')
                SaveObj.selectedValue.push(dataForm.value.assetChannelId)
            }
        }
        else if (this.name == 'Multiple data point last values bar graph') {
            this.getMultipleDataPointChart(dataForm.value)
            if (dataForm.value.tier) {
                SaveObj.selectedType.push('tier')
                SaveObj.selectedValue.push(dataForm.value.tier)
            }
            if (dataForm.value.asset) {
                SaveObj.selectedType.push('asset')
                SaveObj.selectedValue.push(dataForm.value.asset)
            }
            if (dataForm.value.dataPoint && dataForm.value.dataPoint.length) {
                for (let index = 0; index < dataForm.value.dataPoint.length; index++) {
                    const element = dataForm.value.dataPoint[index];
                    SaveObj.selectedType.push('dataPoint')
                    SaveObj.selectedValue.push(element.assetChannelId)
                }
            }
        }
        else if (this.name == 'Up-Time/Downtime runtime chart') {
            // console.log(dataForm.value);
            this.getUpDownChart(JSON.parse(JSON.stringify(dataForm.value)))
            
            if (dataForm.value.tier) {
                SaveObj.selectedType.push('tier')
                SaveObj.selectedValue.push(dataForm.value.tier)
            }
            // if (dataForm.value.asset) {
            //     SaveObj.selectedType.push('asset')
            //     SaveObj.selectedValue.push(dataForm.value.asset)
            // }
            if (dataForm.value.asset) {
                SaveObj.selectedType.push('rcpids')
                let rcp = JSON.parse(JSON.stringify(dataForm.value.asset))
                delete dataForm.value.asset;
                dataForm.value.rcpids = rcp;
                SaveObj.selectedValue.push(dataForm.value.rcpids)        
            }
            if (dataForm.value.range) {
                SaveObj.selectedType.push('range')
                SaveObj.selectedValue.push(dataForm.value.range)
            }
        }

        this.checkTreeArray.map((val, index) => {
            SaveObj.selectedType.push('tier')
            SaveObj.selectedValue.push(val)
        })

        console.log(this.checkTreeArray, SaveObj);
        //   if (!this.isPreview) {
        this.SaveWidgetObj(SaveObj, dataForm.value);
        this.ShowFormValue = false;
        //   }

    }

    SaveWidgetObj(options, value) {
        console.log(value, options);
        // console.log(this.widgetObj.dashboardWidget/* .dashboardWidgetId */);
        // return
        if (this.widgetObj.dashboardWidget) {

            if (this.widgetObj.dashboardWidget && this.widgetObj.dashboardWidget.dashboardWidgetId) {




                let obj: any = {
                    widgetId: this.widgetObj.widgetId,
                    "dashboardWidget": {
                        "dashboardWidgetId": this.widgetObj.dashboardWidget.dashboardWidgetId,
                        timeRange: value.range ? value.range : null
                    },
                    // "optionId":3,
                    tiers: [],
                    sites: [],
                    assets: [],
                    rcpids:[],
                    datapoints: []
                }
                let objKeyData: any = {

                }

                if (value.site) {
                    let siteObjArr = []
                    value.site.map((objSite, index) => {
                        var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.sites, function (obj) {
                            return obj.siteId == objSite.siteId;
                        }), 'dashboardWidgetOptionId');
                        console.log(dashboardWidgetOptionId, "++++++++++++++++++++++++++++++++++++++++++++++++++++++sites++++++dashboardWidgetOptionId");
                        if (dashboardWidgetOptionId) {
                            objSite.dashboardWidgetOptionId = dashboardWidgetOptionId
                        }
                        siteObjArr.push(objSite)
                    })



                    obj.sites = siteObjArr//value.site
                }
                if (value.tier) {
                    let tierObjArr = []
                    value.tier.map((objTier, index) => {
                        var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.tiers, function (obj) {
                            return obj.tierId == objTier.tierId;
                        }), 'dashboardWidgetOptionId');
                        if (dashboardWidgetOptionId) {
                            objTier.dashboardWidgetOptionId = dashboardWidgetOptionId
                        }
                        // console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++tiers+++++++dashboardWidgetOptionId");
                        tierObjArr.push(objTier)
                    })

                    obj.tiers = tierObjArr//value.tier
                }
                if (value.rcpids) {
                    let assetObjArr = []
                    value.rcpids.map((objasset, index) => {
                        var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.rcps, function (obj) {
                            return obj.runTimeCollectionPointId == objasset.Rcpid;
                        }), 'dashboardWidgetOptionId');
                        if (dashboardWidgetOptionId) {
                            objasset.dashboardWidgetOptionId = dashboardWidgetOptionId
                        }
                        // console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++assets+++++dashboardWidgetOptionId");
                        assetObjArr.push({runTimeCollectionPointId:objasset.Rcpid, dashboardWidgetOptionId:objasset.dashboardWidgetOptionId})
                    })

                    obj.rcpids = assetObjArr//value.tier
                }

                if (value.asset) {
                    let assetObjArr = []
                    value.asset.map((objasset, index) => {
                        var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.assets, function (obj) {
                            return obj.assetId == objasset.assetId;
                        }), 'dashboardWidgetOptionId');
                        if (dashboardWidgetOptionId) {
                            objasset.dashboardWidgetOptionId = dashboardWidgetOptionId
                        }
                        // console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++assets+++++dashboardWidgetOptionId");
                        assetObjArr.push(objasset)
                    })

                    obj.assets = assetObjArr//value.tier
                } else if (value.assetId) {
                    let assetObjArr = []
                    // value.asset.map((objasset,index)=>{
                    var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.assets, function (obj) {
                        return obj.assetId == value.assetId;
                    }), 'dashboardWidgetOptionId');
                    let objasset: any = {
                        assetId: value.assetId
                    }
                    if (dashboardWidgetOptionId) {
                        objasset.dashboardWidgetOptionId = dashboardWidgetOptionId
                    }
                    console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++assets+++++dashboardWidgetOptionId");
                    assetObjArr.push(objasset)
                    // })

                    obj.assets = assetObjArr//value.tier
                }

                if (value.dataPoint) {
                    let dataPointObjArr = []
                    value.dataPoint.map((objdataPoint, index) => {
                        var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.datapoints, function (obj) {
                            return obj.assetChannelId == objdataPoint.assetChannelId;
                        }), 'dashboardWidgetOptionId');
                        if (dashboardWidgetOptionId) {
                            objdataPoint.dashboardWidgetOptionId = dashboardWidgetOptionId
                        }
                        // console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++datapoints+++++dashboardWidgetOptionId");
                        dataPointObjArr.push(objdataPoint)
                    })

                    obj.datapoints = dataPointObjArr//value.tier
                } else if (value.assetChannelId) {
                    let dataPointObjArr = []
                    // value.dataPoint.map((objdataPoint,index)=>{
                    var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.datapoints, function (obj) {
                        return obj.assetChannelId == value.assetChannelId;
                    }), 'dashboardWidgetOptionId');
                    let objDatapoint: any = {
                        assetChannelId: value.assetChannelId
                    }
                    if (dashboardWidgetOptionId) {
                        objDatapoint.dashboardWidgetOptionId = dashboardWidgetOptionId
                    }
                    // console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++assetChannelId+++++dashboardWidgetOptionId");
                    dataPointObjArr.push(objDatapoint)
                    // })

                    obj.datapoints = dataPointObjArr//value.tier
                }
                // options.selectedType.map((val,index)=>{
                //   if (objKeyData[val] && objKeyData[val].length) {
                //     objKeyData[val].push(options.selectedValue[index]);
                //   }else{
                //     objKeyData[val] = []
                //     objKeyData[val].push(options.selectedValue[index]);
                //   }
                // })


                /* 
                var delete_id = _.result(_.find(savedViews, function(obj) {
                    return obj.description === view;
                }), 'id');
                */



                console.log(obj, "::::::::::::::::::::::::::::::", this.widgetObj);
                // return
                this.apiService.create('dashboardWidgetOption/createDashBoardWidgetOption', obj).subscribe(
                    data => {
                        this.stopLoading();
                        if (data.statusCode == 100) {
                            this.alertService.sweetMessage('success', data.responseMessage);
                            if (objKeyData.tier) {
                                this.widgetObj.tier = objKeyData.tier
                            } else {
                                this.widgetObj.tier = []
                            }
                            if (objKeyData.asset) {
                                this.widgetObj.asset = objKeyData.asset
                            } else {
                                this.widgetObj.asset = []
                            }
                            if (objKeyData.dataPoint) {
                                this.widgetObj.datapoint = objKeyData.dataPoint
                            } else {
                                this.widgetObj.datapoint = []
                            }
                            if (objKeyData.site) {
                                this.widgetObj.site = objKeyData.site
                            } else {
                                this.widgetObj.site = []
                            }
                            if (objKeyData.range && objKeyData.range.length) {
                                this.widgetObj.timeRange = objKeyData.range[0]
                            } else {
                                this.widgetObj.timeRange = null
                            }
                            this.widgetObj = data.response

                            if (this.widgetObj.rcps && this.widgetObj.rcps.length) {
               
                                let rcpList = []; 
                                for (const iterator of this.widgetObj.rcps) {
                                    rcpList.push({Rcpid:iterator.runTimeCollectionPointId})
                                }
                                // this.assetName = this.widgetObj.asset//[0]
                                // dataObj.asset = rcpList;
                                this.selectedAssetList = rcpList;
                               
                                // dataObj.asset = this.assetName
                            }
                            
                            // response

                        } else {

                        }
                    },
                    error => {
                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    }
                );
            }
        }
    }

    getStatusPieChart(obj) {

        if (this.chartObj) {
            // this.chartObj.dispose()
            this.chartObj.removeEventListener('dataplotClick', this.handler);
        }

        var URL = 'asset/getAssetStatusGraphData';
        let apiData: any = {
            clientId: this.clientId,
            userId: this.userId
          }
        if (obj.tier && obj.tier.length) {
            let tierIds = []
            obj.tier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            apiData.tierIds = tierIds.toString();

            // var tierString = '&tierId=' + tierIds.toString();
            // var URL = URL.concat(tierString);
        }
        if (obj.site && obj.site.length) {

            let siteIds = []
            obj.site.map((obj, index) => {
                siteIds.push(obj.siteId)
            })
            apiData.siteIds = siteIds.toString();
            // var siteString = '&siteId=' + siteIds.toString();
            // var URL = URL.concat(siteString);
        }
        // if (this.asset != '') {
        //   var assetString = '&assetId=' + this.asset;
        //   var URL = URL.concat(assetString);
        // }
        // if (this.customerId != null && this.customerId != '') {
        //   var customerString = '&customerId=' + this.customerId.toString();
        //   var URL = URL.concat(customerString);
        // }
        // console.log(URL, ":::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::chart_amchart_{{index}}");
        this.startLoading();
        this.apiService.create(URL, apiData).pipe(delay(5000),finalize(()=> {
            this.stopLoading();
        })).subscribe(
            data => {
                this.stopLoading();
                if (data.statusCode == 100) {

                    let Arr = [];
                    let valueArr = data.responseList

                    valueArr.map((val, key) => {
                        if (val.fill) {
                            // delete val.fill;
                        }
                        if (val['label'] == "A") {
                            val['label'] = 'Alarm';
                            val.config = {
                                fill: "#b85"
                            }
                            // val['link'] = '/asset-management/A/'+this.asset;
                        }
                        if (val['label'] == "N") {
                            val['label'] = 'Normal';
                            val.config = {
                                fill: "#2d8"
                            }
                            // val['link'] = '/asset-management/N/'+this.asset;
                        }
                        if (val['label'] == "W") {
                            val['label'] = 'Warning';
                            val.config = {
                                fill: "#cb9"
                            }// val['link'] = '/asset-management/W/'+this.asset;
                        }
                        if (val['label'] == "S") {
                            val['label'] = 'Suspended';
                            val.config = {
                                fill: "#520"
                            }// val['link'] = '/asset-management/O/'+this.asset;
                        }
                        if (val['label'] == "C") {
                            val['label'] = 'No Comm';
                            val.config = {
                                fill: "#222"
                            }// val['link'] = '/asset-management/O/'+this.asset;
                        }


                        Arr.push(val);
                    })
                    // console.log(this.fusionChartArrayDataObj);


                    let chart = am4core.create("chart_amchart_" + this.index, am4charts.PieChart);
                    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                    chart.legend = new am4charts.Legend();

                    chart.data = Arr
                    chart.padding(15, 10, 15, 10);

                    chart.innerRadius = 50;
                    chart.legend.maxHeight = 90;
                    chart.legend.labels.template.fontSize = 10
                    chart.legend.itemContainers.template.tooltipText = "{category}";
                    chart.legend.labels.template.truncate = true;


                    chart.legend.scrollable = true;
                    chart.legend.valueLabels.template.disabled = true;

                    chart.legend.valueLabels.template.align = "left";

                    chart.legend.labels.template.text = "{category}";
                    // chart.colors.list = [
                    //     am4core.color("#b85f40"),
                    //     am4core.color("#2d802f"),
                    //     am4core.color("#cb9918"),
                    //     am4core.color("#52a39f"),
                    //     am4core.color("#222222"),
                    //   ];
                    let series = chart.series.push(new am4charts.PieSeries());
                    series.dataFields.value = "value";
                    series.dataFields.category = "label";
                    chart.configField = "config"
                    series.slices.template.propertyFields.fill = "fill";

                    // series.fill = 
                    // let colorlist = [
                    //     new am4core.color("#b85f40")
                    // ]
                    // chart.colors.list = ["#b85f40", "#2d802f", "#cb9918", "#52a39f", "#222222"].map((color:any)=> {
                    //    return new am4core.color(color);
                    //   });
                    // let self = this
                    series.slices.template.events.on("hit", function (ev) {
                        let series = ev.target.dataItem.component;
                        console.log(ev, "-----------------------------------", ev.target.dataItem);
                        self.clickChartAssetPieChartClick(ev.target.dataItem)
                        // series.slices.each(function(item) {
                        //   if (item.isActive && item != ev.target) {
                        //     item.isActive = false;
                        //   }
                        // })
                    });
                    series.alignLabels = true;
                    series.slices.template.cornerRadius = 5;
                    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";

                    // this.fusionChartArrayDataObj.type = 'doughnut3d';
                    // this.fusionChartArrayDataObj.dataSource = {
                    //     "chart": {
                    //         // "defaultcenterlabel": ,
                    //         "showlegend": "1",
                    //         "showpercentvalues": "0",
                    //         "aligncaptionwithcanvas": "0",
                    //         "captionpadding": "0",
                    //         "decimals": "1",
                    //         // "plottooltext": "<b>$Value</b> of Assets are <b>$label</b>",
                    //         "plottooltext": "Status.",
                    //         "showPlotBorder": "2",
                    //         "showShadow": "2",
                    //         "use3DLighting": "2",
                    //         "theme": "fusion"
                    //     },
                    //     "data": []
                    // };
                    let self = this
                    // setTimeout(() => {
                    this.fusionChartArrayDataObj.dataSource.data = Arr
                    // if (this.chartObj) {
                    this.handler = this.clickChartAssetPieChartClick.bind(this);
                    // this.message = this.clickPlotMsg;
                    // this.attached = true;
                    // this.chartObj.addEventListener('dataplotClick', this.handler);
                    // }



                    // }, 1000);


                } else {
                    // this.fusionChartArrayDataObj.type = 'doughnut3d';
                    // this.fusionChartArrayDataObj.dataSource = {
                    //     "chart": {
                    //         // "defaultcenterlabel": ,
                    //         "showlegend": "1",
                    //         "showpercentvalues": "0",
                    //         "aligncaptionwithcanvas": "0",
                    //         "captionpadding": "0",
                    //         "decimals": "1",
                    //         // "plottooltext": "<b>$Value</b> of Assets are <b>$label</b>",
                    //         "plottooltext": "Status.",
                    //         "showPlotBorder": "2",
                    //         "showShadow": "2",
                    //         "use3DLighting": "2",
                    //         "theme": "fusion"
                    //     },
                    //     "data": []
                    // };
                    this.fusionChartArrayDataObj.dataSource.data = []
                }
            }, error => {
                this.stopLoading()
            })
    }

    clickChartAssetPieChartClick(event) {

        console.log(this.isPreview);
        if (!this.isPreview) {


            var URL = 'asset/getAssetDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId + "&isAssetLive=true";
            let statusValue = ''
            this.assetDatalistPopup = [];
            var URL1 = 'asset/getAssetByClient';
            let apiData: any = {
                clientId: this.clientId,
                userId: this.userId,
                isAssetLive: true,
            }

            if ($.fn.DataTable.isDataTable('#asset_data_table' + this.index)) {
                $('#asset_data_table' + this.index).DataTable().destroy();
            };

            this.assetDatalistPopup = [];
            //console.log(event.data.categoryLabel)
            //   switch (event.data.categoryLabel) {
            switch (event.category) {
                case 'Normal':
                    statusValue = 'N'
                    break;

                case 'Alarm':
                    statusValue = 'A'
                    break;

                case 'Warning':
                    statusValue = 'W'
                    break;

                case 'Suspended':
                    statusValue = 'S'
                    break;

                case 'No Comm':
                    statusValue = 'C'
                    break;

                default:
                    break;
            }

            //   if (this.assetIdforGetData != '' && this.assetIdforGetData != undefined) {
            //       var assetString = '&assetId=' + this.assetIdforGetData;
            //       var URL = URL.concat(assetString);
            //   }

            //   if (this.customerId != null && this.customerId != '') {
            //       var customerString = '&customerId=' + this.customerId.toString();
            //       var URL = URL.concat(customerString);
            //   }
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {

                let tierIds = []
                this.widgetObj.tiers.map((obj, index) => {
                    tierIds.push(obj.tierId)
                })
                apiData.tierIds  = tierIds.toString();

                var tierString = '&tierId=' + tierIds.toString();
                //   var tierString = '&tierId=' + this.widgetObj.tier.toString();
                var URL = URL.concat(tierString);
            }
            if (this.widgetObj.sites && this.widgetObj.sites.length) {

                let siteIds = []
                this.widgetObj.sites.map((obj, index) => {
                    siteIds.push(obj.siteId)
                })

                apiData.siteIds  = siteIds.toString();

                var siteString = '&siteId=' + siteIds.toString();
                // var siteString = '&siteId=' + this.widgetObj.site.toString();
                var URL = URL.concat(siteString);
            }

            if (statusValue != '') {
                var statusString = '&status=' + statusValue;
                var URL = URL.concat(statusString);
                apiData.status  = [statusValue];

            }
            const table: any = $('#asset_data_table' + this.index);


            $('#assetListModel' + this.index).modal("show");
            this.apiService.create(URL1, apiData).subscribe(
                data => {
                    this.stopLoading();
                    // this.chRef.detectChanges();
                    if ($.fn.DataTable.isDataTable('#asset_data_table' + this.index)) {
                        $('#asset_data_table' + this.index).DataTable().destroy();
                    };
                    // console.log(data)
                    if (data.statusCode == 100) {
                        let Arr = []
                        //   this.assetDatalistPopup = [];

                        let objArr = data.responseList;
                        //   console.log(objArr);

                        objArr.map((val, key) => {
                            let objValue = val
                            if (!val.notificationPlan) {
                                objValue.notificationPlan = {};
                            }
                            if (!val.shiftGroup) {
                                objValue.shiftGroup = {};
                            }
                            if (!val.customer) {
                                objValue.customer = {};
                            }
                            if (!val.site) {
                                objValue.site = {};
                            }
                            Arr.push(objValue);
                        });
                        //   console.log(Arr);
                        if ($.fn.DataTable.isDataTable('#asset_data_table' + this.index)) {
                            $('#asset_data_table' + this.index).DataTable().destroy();
                        };

                        this.assetDatalistPopup = JSON.parse(JSON.stringify(Arr));
                        let self = this
                        setTimeout(() => {
                            let dataTable = $('#asset_data_table' + this.index).DataTable({

                                "bDestroy": true,
                                "destroy": true,
                                "columnDefs": [{
                                    "orderable": false,
                                    "targets": 8
                                }],
                            });
                        }, 1000);
                        setTimeout(() => {
                            console.log(self.assetDatalistPopup);
                        }, 2000);


                    }
                    else {
                        this.assetDatalistPopup = [];
                        //   this.chRef.detectChanges();
                        const table: any = $('#asset_data_table' + this.index);
                        setTimeout(() => {
                            let dataTable = table.DataTable({
                                "order": [],
                                "destroy": true,
                                "bDestroy": true,
                                "columnDefs": [{
                                    "orderable": false,
                                    "targets": 8
                                }],

                            });

                        }, 1000);
                    }
                },
                error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    }

    clickEventBarGraphList(event) {

        console.log(this.isPreview);
        if (!this.isPreview) {

            var URL = 'wffnotification/getEventListData';
            let apiData: any = {
                clientId: this.clientId,
                userId: this.userId
            }
            let statusValue = ''
            var runtimeUrlStatus = 'asset/getRuntimeLiveFeedByStatus?clientId=' + this.clientId + '&userId=' + this.userId + '';
            this.eventDatalist = []

            //   if (that.EventBarGraph[key]['label'] == "Runtime Open") {
            //     // that.EventBarGraph[key]['link'] = '/notification-event/1';
            // }
            // if (that.EventBarGraph[key]['label'] == "In-Progress") {
            //     // that.EventBarGraph[key]['link'] = '/notification-event/2';
            // }
            // if (that.EventBarGraph[key]['label'] == "Not Acknowledged") {
            //     // that.EventBarGraph[key]['link'] = '/notification-event/0';
            // }
            console.log(event);

            // if(event.data && event.data.categoryLabel){
            if (event && event.categoryX) {
                if (event.categoryX == "In Progress Runtime Events") {
                    apiData.search = 'InProgress Runtime';
                    // var statusApi = '&search=InProgress Runtime';
                    // var URL = URL.concat(statusApi);
                }
                if (event.categoryX == "Open Runtime Events") {
                    apiData.search = 'Open Runtime';
                    // var statusApi = '&search=Open Runtime';
                    // var URL = URL.concat(statusApi);
                }
                if (event.categoryX == "Acknowledgement Pending") {
                    apiData.search = 'Notification Pending';
                    // var statusApi = '&search=Notification Pending';
                    // var URL = URL.concat(statusApi);
                }
            }
            // if(event.data && event.data.categoryLabel == 'Runtime Open'){
            //   var statusApi  = '&status=1';
            //   var URL = URL.concat(statusApi);

            // }
            // if(event.data && event.data.categoryLabel == 'In-Progress'){
            //   var statusApi  = '&status=2';
            //   var URL = URL.concat(statusApi);

            // }

            //   if (this.assetIdforGetData != '' && this.assetIdforGetData != undefined) {
            //       var assetString = '&assetId=' + this.assetIdforGetData;
            //       var URL = URL.concat(assetString);
            //   }

            //   if (this.customerId != null && this.customerId != '') {
            //       var customerString = '&customerId=' + this.customerId.toString();
            //       var URL = URL.concat(customerString);
            //   }
            //   if (this.tierId != null && this.tierId != '') {
            //       // var tierString = '&tierId=' + this.tierId.toString();
            //       var tierString = '&tiers=' + this.tierId.toString();//changed as per requirements on 9 december 2019
            //       var URL = URL.concat(tierString);
            //   }
            //   if (this.siteIdforGetData != '') {
            //       var siteString = '&siteId=' + this.siteIdforGetData;
            //       var URL = URL.concat(siteString);
            //   }
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {

                let tierIds = []
                this.widgetObj.tiers.map((obj, index) => {
                    tierIds.push(obj.tierId)
                })

                apiData.tierIds = tierIds.toString();
                // var tierString = '&tiers=' + tierIds.toString();
                //   var tierString = '&tierId=' + this.widgetObj.tier.toString();
                // var URL = URL.concat(tierString);
            }
            if (this.widgetObj.sites && this.widgetObj.sites.length) {

                let siteIds = []
                this.widgetObj.sites.map((obj, index) => {
                    siteIds.push(obj.siteId)
                })
                apiData.siteIds = siteIds.toString();

                // var siteString = '&siteId=' + siteIds.toString();
                // var siteString = '&siteId=' + this.widgetObj.site.toString();
                // var URL = URL.concat(siteString);
            }
            if (this.widgetObj.assets && this.widgetObj.assets.length) {

                let assetIDs = []
                this.widgetObj.assets.map((obj, index) => {
                    assetIDs.push(obj.assetId)
                })

                apiData.assetIds = assetIDs.toString();
                // var siteString = '&assetId=' + assetIDs.toString();
                // var siteString = '&siteId=' + this.widgetObj.site.toString();
                // var URL = URL.concat(siteString);
            }

            if ($.fn.DataTable.isDataTable('#data_table_Event' + this.index)) {
                $('#data_table_Event' + this.index).DataTable().destroy();
            };

            $('#notificationListModel' + this.index).modal("show");
            this.eventDatalist = [];

            this.apiService.create(URL, apiData).subscribe(
                data => {
                    this.stopLoading();
                    if (data.statusCode == 100) {
                        this.eventDatalist = data.responseList;
                        if ($.fn.DataTable.isDataTable('#data_table_Event' + this.index)) {
                            $('#data_table_Event' + this.index).DataTable().destroy();
                        };
                        // this.chRef.detectChanges();
                        const table: any = $('#data_table_Event' + this.index);
                        let self = this
                        setTimeout(() => {
                            table.DataTable();
                        }, 1000);


                    } else {
                        this.eventDatalist = [];
                        if ($.fn.DataTable.isDataTable('#data_table_Event' + this.index)) {
                            $('#data_table_Event' + this.index).DataTable().destroy();
                        };
                        //  this.chRef.detectChanges();
                        const table: any = $('#data_table_Event' + this.index);

                        let self = this
                        setTimeout(() => {
                    /* self.dataTable =  */table.DataTable();
                        }, 1000);


                    }
                },
                error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again");
                }
            );
        }
    }

    clickSystemHelthCheckEvent(event) {

        console.log(this.isPreview, event);
        if (!this.isPreview) {
            let dataEvent = null;
            if (event.data.categoryLabel == "Down") {
                console.log(event.data.categoryLabel);
                dataEvent = false;
            } else if (event.data.categoryLabel == "Running") {
                console.log(event.data.categoryLabel);
                dataEvent = true;
            }
            // Destroy Data Table if is already and clear the data
            if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH' + this.index)) {
                $('#DATATABLEhEALTH' + this.index).DataTable().destroy();
                this.healthCheckList = [];
            }

            var URL = 'healthCheck/getAllhealthCheck?status=' + dataEvent;
            this.healthCheckList = [];

            $('#healthCheckModel' + this.index).modal("show");
            this.apiService.getAll(URL).subscribe(
                data => {
                    this.stopLoading();
                    if (data.statusCode == 100) {


                        this.healthCheckList = JSON.parse(JSON.stringify(data.responseList));

                        // this.chRef.detectChanges();
                        if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH' + this.index)) {
                            $('#DATATABLEhEALTH' + this.index).DataTable().destroy();
                        }
                        setTimeout(() => {
                            const table: any = $('#DATATABLEhEALTH' + this.index);
                            this.dataTable = table.DataTable({
                                "columnDefs": [{
                                    "orderable": false,
                                    "targets": 2
                                }]
                            }
                            );
                        }, 1000);

                    }
                    else {
                        this.assetDatalistPopup = [];
                        if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH' + this.index)) {
                            $('#DATATABLEhEALTH' + this.index).DataTable().destroy();
                            this.healthCheckList = [];
                        }
                        const table: any = $('#DATATABLEhEALTH' + this.index);
                        this.dataTable = table.DataTable(
                            {
                                "columnDefs": [{
                                    "orderable": false,
                                    "targets": 2
                                }]
                            }
                        );

                    }
                },
                error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    }

    clickEventUpDownChart(event) {
        console.log(event);

    }

    clickEvenFirstFaltChart(event) {
        console.log(this.isPreview, event, this.widgetObj);
        if (!this.isPreview) {
            // var URL = "runTimeEvent/getFirstFaultDetail?firstFaultDec=" + event.category
            var URL = "runTimeEvent/getFirstFaultDetail"//?clientId=" + this.clientId
            let apidata:any = {
                firstFaultDec :event.category,
            }
            if (this.widgetObj.tiers && this.widgetObj.tiers.length) {

                let tierIds = []
                this.widgetObj.tiers.map((obj, index) => {
                    tierIds.push(obj.tierId)
                })
                var tierString = '&tierIds=' + tierIds.toString();
                //   var tierString = '&tierId=' + this.widgetObj.tier.toString();
                // var URL = URL.concat(tierString);
                apidata.tierIds = tierIds.toString()
            }
            if (this.widgetObj.sites && this.widgetObj.sites.length) {

                let siteIds = []
                this.widgetObj.sites.map((obj, index) => {
                    siteIds.push(obj.siteId)
                })
                var siteString = '&siteId=' + siteIds.toString();
                // var siteString = '&siteId=' + this.widgetObj.site.toString();
                // var URL = URL.concat(siteString);
                apidata.siteId = siteIds.toString()

            }

            // if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
            //     var tierString = '&tierId=' + this.widgetObj.tier.toString();
            //     var URL = URL.concat(tierString);
            // }
            // if (this.widgetObj.sites && this.widgetObj.sites.length) {
            //     var siteString = '&siteId=' + this.widgetObj.site.toString();
            //     var URL = URL.concat(siteString);
            // }
            if (this.widgetObj.assets && this.widgetObj.assets.length) {
                let assetIds = []
                this.widgetObj.assets.map((obj, index) => {
                    assetIds.push(obj.assetId)
                })
                var assetString = '&assetIds=' + assetIds.toString();
                // var siteString = '&siteId=' + this.widgetObj.site.toString();
                // var URL = URL.concat(assetString);
                apidata.assetIds = assetIds.toString()

            }
            if (this.widgetObj.dashboardWidget && this.widgetObj.dashboardWidget.timeRange) {
                var timeRange = '&days=' + this.widgetObj.dashboardWidget.timeRange;
                // var URL = URL.concat(timeRange);
                apidata.days = this.widgetObj.dashboardWidget.timeRange;
            }
            // /runTimeEvent/getFirstFaultDetail?days=730&tierIds=278&firstFaultDec=MANUAL SHUTDOWN&assetId=24383

            $('#FirstFaultChartpopupListModel' + this.index).modal("show");

            this.apiService.create(URL, apidata).subscribe(
                data => {
                    this.stopLoading();
                    if (data.statusCode == 100) {


                        this.firstFaultChartPopupList = JSON.parse(JSON.stringify(data.responseList));

                        // this.chRef.detectChanges();
                        if ($.fn.DataTable.isDataTable('#FirstFaultChartpopupList' + this.index)) {
                            $('#FirstFaultChartpopupList' + this.index).DataTable().destroy();
                        }
                        setTimeout(() => {
                            const table: any = $('#FirstFaultChartpopupList' + this.index);
                            this.dataTable = table.DataTable({
                                "columnDefs": [{
                                    "orderable": false,
                                    "targets": 2
                                }]
                            }
                            );
                        }, 1000);

                    }
                    else {
                        this.firstFaultChartPopupList = [];
                        if ($.fn.DataTable.isDataTable('#FirstFaultChartpopupList' + this.index)) {
                            $('#FirstFaultChartpopupList' + this.index).DataTable().destroy();
                            this.firstFaultChartPopupList = [];
                        }
                        setTimeout(() => {
                            const table: any = $('#FirstFaultChartpopupList' + this.index);
                            this.dataTable = table.DataTable(
                                {
                                    "columnDefs": [{
                                        "orderable": false,
                                        "targets": 2
                                    }]
                                }
                            );
                        }, 1000);

                    }
                },
                error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    }

    getGaugeChartData(obj) {
        console.log(obj);
        if (this.amchartObj) {
            this.amchartObj.dispose()
        }
        if (obj.assetChannelId) {
            this.startLoading();
            this.apiService.getAll("asset/getChartHighLowLimitsById?assetChannelId=" + obj.assetChannelId).pipe(delay(5000),finalize(()=> {
                this.stopLoading();
            })).subscribe(
                data => {
                    this.stopLoading();
                    if (data.statusCode == 100) {

                        let ResObj = data.response
                       
                        let DataObj = {
                            chart: {
                                caption: (ResObj.assetName ? "Asset : " + ResObj.assetName + "<br>" : '') + ResObj.assetChannelName,
                                // subCaption:moment(ResObj.lastEventTime, "YYYY-MM-DD hh:mm:ss:sss").format("YYYY-MM-DD HH:mm:ss") + " " + ResObj.tz,
                                subCaption: moment(ResObj.lastEventTime).format("MM-DD-YYYY HH:mm:ss") + " " + ResObj.tz,
                                lowerlimit: ResObj.lowLowLimit ? ResObj.lowLowLimit.toString() : '',
                                upperlimit: ResObj.highHighLimit ? ResObj.highHighLimit.toString() : '',
                                showvalue: "1",
                                "showBorder": "0",
                                "bgColor": "#ffffff",
                                numbersuffix: ResObj.unitOfMeasureName ? " " + ResObj.unitOfMeasureName.toString() : '',
                                theme: "fusion",
                                showtooltip: "1"
                            },
                            colorrange: {
                                color: [
                                    {
                                        minvalue: ResObj.lowLowLimit ? ResObj.lowLowLimit.toString() : '',
                                        maxvalue: ResObj.lowLimit ? ResObj.lowLimit.toString() : '',
                                        code: "#F2726F"
                                    },
                                    {
                                        minvalue: ResObj.lowLimit ? ResObj.lowLimit.toString() : '',
                                        maxvalue: ResObj.highLimit ? ResObj.highLimit.toString() : '',
                                        code: "#FFC533"
                                    },
                                    {
                                        minvalue: ResObj.highLimit ? ResObj.highLimit.toString() : '',
                                        maxvalue: ResObj.highHighLimit ? ResObj.highHighLimit.toString() : '',
                                        code: "#62B58F"
                                    }
                                ]
                            },
                            dials: {
                                dial: [
                                    {
                                        value: ResObj.lastValue ? ResObj.lastValue.toString() : '0'
                                    }
                                ]
                            }
                        }

                        if (this.amchartObj) {

                        }

                        setTimeout(() => {
                            // Create chart instance
                            let chart: any = am4core.create("chart_amchart_" + this.index, am4charts.GaugeChart);

                            // Add data
                            // chart.data = ResObj

                            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

                            chart.innerRadius = -40;
                            // chart.height = am4core.percent(50);
                            chart.align = "bottom"
                            chart.numberFormatter.numberFormat = "#a";

                            var topContainer = chart.chartContainer.createChild(am4core.Container);
                            topContainer.layout = "absolute";
                            topContainer.toBack();
                            // topContainer.paddingBottom = 5;
                            topContainer.width = am4core.percent(100);

                            var caption = topContainer.createChild(am4core.Label);
                            caption.text = `${(ResObj.assetName ? "[bold]Asset:[/] " + ResObj.assetName : '')}${ResObj.assetChannelName ? ", [bold]Data point:[/] " + (ResObj.assetChannelName.charAt(0).toUpperCase() +
                                ResObj.assetChannelName.slice(1)) : ''}`;
                            caption.fontSize = 20;
                            caption.align = "center";

                            var caption2 = topContainer.createChild(am4core.Label);
                            caption2.text = `\n[bold] Value[/] : ${ResObj.lastValue ? ResObj.lastValue : 0}`;
                            caption2.fontSize = 20;
                            caption2.align = "center";

                            if (ResObj.lastEventTime) {
                                let date = moment(ResObj.lastEventTime, "YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss") ? moment(ResObj.lastEventTime).format("MM-DD-YYYY HH:mm:ss") + " " + ResObj.tz : ''
                                var subCaption = topContainer.createChild(am4core.Label);
                                subCaption.text = `${date}`;
                                subCaption.fontSize = 20;
                                subCaption.align = "center";
                                subCaption.y = 30;
                                subCaption.paddingBottom = 30
                            }

                            let axis = chart.xAxes.push(new am4charts.ValueAxis());
                            axis.min = ResObj.lowLowLimit ? ResObj.lowLowLimit : -1;
                            axis.max = ResObj.highHighLimit ? ResObj.highHighLimit : 100;
                            axis.strictMinMax = true;
                            axis.renderer.grid.template.stroke = new am4core.InterfaceColorSet().getFor("background");
                            axis.renderer.grid.template.strokeOpacity = 0.3;

                            let colorSet = new am4core.ColorSet();

                            let range0 = axis.axisRanges.create();
                            range0.value = ResObj.lowLowLimit ? ResObj.lowLowLimit : -1;
                            range0.endValue = ResObj.lowLimit ? ResObj.lowLimit : 10;
                            range0.axisFill.fillOpacity = 1;
                            range0.axisFill.fill = am4core.color("yellow");
                            let gradient = new am4core.LinearGradient();

                            range0.axisFill.zIndex = - 1;
                            // range0.gradient =  am4core.color("yellow")
                            range0.axisFill.gradient = am4core.color("yellow");
                            // range0.axisFill.gradient = am4core.color("yellow");

                            let range1 = axis.axisRanges.create();
                            range1.value = ResObj.lowLimit ? ResObj.lowLimit : 10;
                            range1.endValue = ResObj.highLimit ? ResObj.highLimit : 50;
                            range1.axisFill.fillOpacity = 1;
                            range1.axisFill.fill = am4core.color("green");
                            // range1.axisFill.fill = colorSet.getIndex(2);
                            range1.axisFill.zIndex = -1;
                            range1.axisFill.gradient = am4core.color("green");
                            // range1.axisFill.gradient = am4core.color("green")

                            let range2 = axis.axisRanges.create();
                            range2.value = ResObj.highLimit ? ResObj.highLimit : 50;
                            range2.endValue = ResObj.highHighLimit ? ResObj.highHighLimit : 100;
                            range2.axisFill.fillOpacity = 1;
                            range2.axisFill.fill = am4core.color("red");
                            // range2.axisFill.fill = colorSet.getIndex(4);
                            range2.axisFill.zIndex = -1;
                            // let gradient = new am4core.LinearGradient();

                            gradient.stops.push({ color: am4core.color("red") })
                            gradient.stops.push({ color: am4core.color("yellow") })
                            gradient.stops.push({ color: am4core.color("green") })
                            axis.renderer.line.stroke = gradient;

                            console.log(ResObj, ResObj.lastValue ? ResObj.lastValue : 0);

                            if (ResObj.lowLimit == ResObj.highLimit && ResObj.lowLimit == ResObj.highHighLimit && ResObj.lowLimit != null && ResObj.lastValue == null) {
                                // if (ResObj.length == 0) {
                                    let indicator = chart.tooltipContainer.createChild(am4core.Container);
                                    indicator.background.fill = am4core.color("#fff");
                                    indicator.background.fillOpacity = 1;
                                    indicator.width = am4core.percent(100);
                                    indicator.height = am4core.percent(100);
            
                                    var indicatorLabel = indicator.createChild(am4core.Label);
                                    indicatorLabel.text = "No data...";
                                    indicatorLabel.align = "center";
                                    indicatorLabel.valign = "middle";
                                    indicatorLabel.fontSize = 20;
                                // }
                            }

                            let hand = chart.hands.push(new am4charts.ClockHand());
                            hand.showValue((ResObj.lastValue == 0 || !ResObj.lastValue) ? 0 : ResObj.lastValue);
                            hand.tooltipText = (ResObj.assetName ? "Asset : " + ResObj.assetName + "\n" : '') + ResObj.assetChannelName + "\n " + moment(ResObj.lastEventTime, "YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss") + " " + (ResObj.tz ? ResObj.tz : '') + "\n " + ResObj.lastValue;

                            this.amchartObj = chart
                            // // using chart.setTimeout method as the timeout will be disposed together with a chart
                            // chart.setTimeout(randomValue, 2000);

                            // function randomValue() {
                            //     hand.showValue(Math.random() * 100, 1000, am4core.ease.cubicOut);
                            //     chart.setTimeout(randomValue, 2000);
                            // }

                        }, 500);


                        // setTimeout(() => {
                        this.fusionChartArrayDataObj.dataSource = DataObj
                        console.log(this.fusionChartArrayDataObj);

                        // }, 1000);


                    } else {
                        // this.fusionChartArrayDataObj.dataSource.data = {}
                    }
                }, error => {
                    this.stopLoading();
                })

        } else {
            this.fusionChartArrayDataObj.dataSource = {}
        }
    }

    getUpDownChart(obj) {
        console.log(obj);

        if (this.chartObj) {
            // this.chartObj.dispose()
            this.chartObj.removeEventListener('dataplotClick', this.handler);
        }

        var URL = "runTimeEvent/getAssetUpTimeDownTime"//?clientId=" + this.clientId
        let apidata:any = {
            clientId :this.clientId,
            isWidget: true
        }
        if (obj.tier && obj.tier.length) {
            let tierIds = [];
            obj.tier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            apidata.tierIds = tierIds.toString();

            var tierString = '&tierId=' + tierIds.toString();
            // var URL = URL.concat(tierString);
        } else {
            this.fusionChartArrayDataObj.dataSource = {}
            this.timeRange = 1;
            // return
        }
        // if (obj.asset && obj.asset.length) {
        //     let assetIds = []
        //     obj.asset.map((obj2, index) => {
        //         assetIds.push(obj2.assetId)
        //     })
        //     apidata.assetIds = assetIds.toString();
        //     var assetString = '&assetId=' + assetIds.toString();
        //     // var URL = URL.concat(assetString);
        // }

        if (obj.asset && obj.asset.length) {
            let assetIds = []
            obj.asset.map((obj, index) => {
              
                assetIds.push(obj.Rcpid)
            })
            apidata.rcpIds = assetIds.toString();
          }
      
        if (obj.range) {
            // URL = URL + "&days=" + obj.range
            apidata.days = obj.range;
        } else {
            // URL = URL + "&days=1"
            apidata.days = 1;
            this.timeRange = 1;
        }
        // if (obj.assetChannelId) {
        this.startLoading()
        this.apiService.create(URL/* "runTimeEvent/getAssetUpTimeDownTime?clientId="+this.clientId+"&tierId="+obj.tier+"&days="+obj.range */, apidata).pipe(delay(5000),finalize(()=> {
            this.stopLoading();
        })).subscribe(
            data => {
                // console.log(data, "===============================================runTimeEvent/getAssetUpTimeDownTime?clientId");
                this.stopLoading()
                if (data.statusCode == 100 || data.statusCode == 103) {

                    let ResObj = data.responseList ?  data.responseList  : []

                    let Category = []
                    let DataSetUpTime = []
                    let DataSetDownTime = []


                    ResObj.map((obj, index) => {
                        Category.push({ label: obj.assetName/*  +"<br> Unit Number : " +obj.unitNumber */ })
                        DataSetUpTime.push({
                            value: obj.runtimeHours,
                            tooltext: "Up Time: " + obj.runtimeHours + " Hours"
                            // eventId:obj.assetId,
                            // datasetIndex: obj.assetId
                        })
                        DataSetDownTime.push({
                            value: obj.downtimeHours,
                            tooltext: "Down Time: " + obj.downtimeHours + " Hours"
                            // eventId: obj.assetId,
                            // datasetIndex: obj.assetId
                        })
                    })


                    let DataSetUpDownTimeObj = [
                        {
                            seriesname: "Down Time",
                            data: DataSetDownTime,
                        },
                        {
                            seriesname: "UP Time",
                            data: DataSetUpTime
                        }
                    ]




                    // Create chart instance
                    let chart = am4core.create("chart_amchart_" + this.index, am4charts.XYChart3D);

                    // Add data
                    chart.data = ResObj
                    // Create axes
                    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "assetNameUnit";
                    categoryAxis.renderer.grid.template.location = 0;

                    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.title.text = "Hours";
                    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
                        return text;
                    });

                    // Create series
                    let series = chart.series.push(new am4charts.ColumnSeries());
                    series.clustered = false;
                    series.columns.template.tooltipText = "{name}: {categoryX} : [bold]{valueY}[/] Hours ";
                    series.columns.template.fillOpacity = 0.9;
                    series.columns.template.width = am4core.percent(25);
                    series.dataFields.valueY = "downtimeHours";
                    series.dataFields.categoryX = "assetNameUnit";
                    series.name = "Down Time";
                    series.fill = am4core.color("red")
                    series.stacked = true
                    // series.columns.template.column.cornerRadiusTopLeft = 10;
                    // series.columns.template.column.cornerRadiusTopRight = 10;

                    let series2 = chart.series.push(new am4charts.ColumnSeries());
                    series2.dataFields.valueY = "runtimeHours";
                    series2.dataFields.categoryX = "assetNameUnit";
                    series2.name = "Up Time";
                    series2.clustered = false;
                    series2.columns.template.tooltipText = "{name}: {categoryX} : [bold]{valueY}[/] Hours";
                    series2.columns.template.width = am4core.percent(25);
                    series2.propertyFields.fill = "green";
                    series2.fill = am4core.color("green")
                    let label = categoryAxis.renderer.labels.template;
                    series2.stacked = true
                    // series2.columns.template.column.cornerRadiusTopLeft = 10;
                    // series2.columns.template.column.cornerRadiusTopRight = 10;

                    let series3 = chart.series.push(new am4charts.ColumnSeries());
                    series3.dataFields.valueY = "standByHours";
                    series3.dataFields.categoryX = "assetNameUnit";
                    series3.name = "Stand By";
                    series3.clustered = false;
                    series3.columns.template.tooltipText = "{name}: {categoryX} : [bold]{valueY}[/] Hours";
                    series3.columns.template.width = am4core.percent(25);
                    series3.propertyFields.fill = "blue";
                    series3.fill = am4core.color("blue")
                    let label11 = categoryAxis.renderer.labels.template;
                    series3.stacked = true


                    // set lavel width
                    label.wrap = true;
                    // label.maxWidth = 120;
                    label.width = am4core.percent(7);
                    label.truncate = true;
                    label.tooltipText = "{category}";
                    // label.padding //(left:2);
                    categoryAxis.renderer.labels.template.location = 0.5//0.5
                    chart.scrollbarX = new am4core.Scrollbar();

                    chart.legend = new am4charts.Legend()
                    // let labelTemplate = categoryAxis.renderer.labels.template;
                    // labelTemplate.rotation = -90;
                    // labelTemplate.horizontalCenter = "left";
                    // labelTemplate.verticalCenter = "middle";
                    // labelTemplate.dy = 10; 
                    // labelTemplate.inside = false;
                    // categoryAxis.renderer.minGridDistance = 90;
                    if (ResObj.length == 0) {
                        let indicator = chart.tooltipContainer.createChild(am4core.Container);
                        indicator.background.fill = am4core.color("#fff");
                        indicator.background.fillOpacity = 0.8;
                        indicator.width = am4core.percent(100);
                        indicator.height = am4core.percent(100);

                        var indicatorLabel = indicator.createChild(am4core.Label);
                        indicatorLabel.text = "No data...";
                        indicatorLabel.align = "center";
                        indicatorLabel.valign = "middle";
                        indicatorLabel.fontSize = 20;
                    }
                    chart.zoomToIndexes(0, 5)
                    series.columns.template.events.on("inited", function (ev) {
                        // let series = ev.target.dataItem.component;
                        // console.log(ev);
                        // ev.target.zoomToIndexes(0,5)
                        // ev.chart.valueAxes[0].zoomToValues(0, 40);
                        // series.slices.each(function(item) {
                        //   if (item.isActive && item != ev.target) {
                        //     item.isActive = false;
                        //   }
                        // })
                    });


                    // chart.scrollbarX.properties.maxColumns = 20
                    // setTimeout(() => {      
                    let DataObj = {
                        "chart": {
                            "caption": "Up-Time / Downtime (In hours)",
                            // "subCaption": "In top 5 stores last month",
                            // "yAxisname": "Sales (In USD)",
                            // "numberPrefix": "$",
                            "placevaluesInside": "1",
                            // "numbersuffix":"hours",
                            "xAxisLineColor": "#999999",
                            "theme": "fusion",
                            "decimals": "1",
                            usePlotGradientColor: 0
                        },
                        categories: [{ category: Category }],
                        dataset: DataSetUpDownTimeObj,
                        trendlines: [
                            {
                                line: [
                                    {
                                        //   startvalue: "16200",
                                        color: "#5D62B5",
                                        thickness: "1.5",
                                        //   displayvalue: "2017's Avg."
                                    },
                                    {
                                        //   startvalue: "23500",
                                        color: "#29C3BE",
                                        thickness: "1.5",
                                        //   displayvalue: "2018's Avg."
                                    }
                                ]
                            }
                        ]
                    }

                    // }, 1000);


                    /* 
                        dataset:[
                            {
                                seriesname: "2018",
                                data: [
                                  {
                                    value: "25400"
                                  },
                                  {
                                    value: "29800"
                                  },
                                  {
                                    value: "21800"
                                  },
                                  {
                                    value: "19500"
                                  },
                                  {
                                    value: "21200"
                                  }
                                ]
                              }
                        ],
                    
                    */
                    // setTimeout(() => {
                    this.fusionChartArrayDataObj.dataSource = DataObj
                    console.log(this.fusionChartArrayDataObj);

                    // }, 1000);

                    this.handler = this.clickEventUpDownChart.bind(this);
                    // this.message = this.clickPlotMsg;
                    // this.attached = true;
                    // this.chartObj.addEventListener('dataplotClick', this.handler);




                } else {
                    this.fusionChartArrayDataObj.dataSource = {}
                }
            }, error => {
                this.stopLoading();
            })

        // }
    }

    getMultipleDataPointChart(obj) {
        console.log(obj);
        let datapointID = []
        if (obj.dataPoint && obj.dataPoint.length) {
            for (let index = 0; index < obj.dataPoint.length; index++) {
                const element = obj.dataPoint[index];
                datapointID.push(element.assetChannelId)
            }
        }

        if (datapointID.length) {
            let reqObj: any = {
                assetChannelIds:  datapointID.toString()               
            }
            this.apiService.create("/asset/getMultipleDataPointLastValueByAssetChannel", reqObj).pipe(delay(5000),finalize(()=> {
                this.stopLoading();
            })).subscribe(
                data => {
                    this.stopLoading();
                    if (data.statusCode == 100) {

                        let ResObj = data.responseList

                        let dataList = data.responseList
                        let dataset = [

                        ]




                        dataList.map((obj, index) => {
                            let objectdata = {
                                value: obj.lastValue, label: obj.fullDescription+" ("+index+")",
                                tooltext: 'Asset:'+(obj.asset.assetName ? obj.asset.assetName : '-')+"\n" + obj.fullDescription + (obj.unitOfMeasureName == null ? '' : `(${obj.unitOfMeasureName})`) + "\n Last Event Time: " + moment(obj.lastEventTime).format("MM-DD-YYYY HH:mm:ss") + " " + obj.tz + "\n Last Value: " + obj.lastValue + "\n" + obj.fullDescription+": [bold]"+ obj.lastValue+"[/]",
                                tooltextlastEventTime: obj.localLastEventTimeWithTZ, color: (obj.alarmState == "N" ? "#62B58F" : (obj.alarmState == "A" ? "#dc3912" : "#ff9900")),
                              
                            }
                            // objectdata[obj.fullDescription] = ""
                            dataset.push(objectdata)

                        })





                        // Create chart instance
                        let chart = am4core.create("chart_amchart_" + this.index, am4charts.XYChart3D);

                        // Add data
                        chart.data = dataset

                        // Create axes
                        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "label";
                        categoryAxis.renderer.labels.template.rotation = 0;
                        categoryAxis.renderer.labels.template.hideOversized = false;
                        categoryAxis.renderer.minGridDistance = 100;
                        categoryAxis.renderer.labels.template.horizontalCenter = "right";
                        categoryAxis.renderer.labels.template.verticalCenter = "middle";
                        categoryAxis.renderer.labels.template.adapter.add("textOutput", function(text) {
                            return text.replace(/ \(.*/, "");
                          });
                          /* Decorate axis tooltip content */
                        categoryAxis.adapter.add("getTooltipText", function(text, target) {
                            return text.replace(/ \(.*/, "");
                        });
                        // categoryAxis.tooltip.label.rotation = 0;
                        // categoryAxis.tooltip.label.horizontalCenter = "right";
                        // categoryAxis.tooltip.label.verticalCenter = "middle";
                        // categoryAxis.renderer.grid.template.location = 0.5;
                        let label = categoryAxis.renderer.labels.template;
                        label.wrap = true;
                        // label.maxWidth = 120;
                        label.width = am4core.percent(10);
                        label.truncate = true;
                        label.tooltipText = "{category}";
                        categoryAxis.renderer.labels.template.location = 0.6
                        // categoryAxis.renderer.grid.template.location = 0;

                        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.title.text = "Events";
                        valueAxis.title.fontWeight = "bold";

                        // Create series
                        let series = chart.series.push(new am4charts.ColumnSeries3D());
                        series.dataFields.valueY = "value";
                        series.dataFields.categoryX = "label";
                        // series.name = "Events";
                        series.tooltipText = "{tooltext}";
                        series.columns.template.fillOpacity = .8;
                        series.columns.template.width = am4core.percent(30);

                        let columnTemplate = series.columns.template;
                        // columnTemplate.strokeWidth = 2;
                        columnTemplate.strokeOpacity = 1;
                        // columnTemplate.stroke = am4core.color("#FFFFFF");

                        columnTemplate.adapter.add("fill", function (fill, target) {
                            return chart.colors.getIndex(target.dataItem.index);
                        })

                        columnTemplate.adapter.add("stroke", function (stroke, target) {
                            return chart.colors.getIndex(target.dataItem.index);
                        })

                        chart.cursor = new am4charts.XYCursor();
                        chart.cursor.lineX.strokeOpacity = 0;
                        chart.cursor.lineY.strokeOpacity = 0;
                        chart.scrollbarX = new am4core.Scrollbar();




                        // setTimeout(() => {
                        this.fusionChartArrayDataObj.dataSource.data = dataset//DataObj
                        console.log(this.fusionChartArrayDataObj);

                        // }, 1000);


                    } else {
                        // this.fusionChartArrayDataObj.dataSource.data = {}
                    }
                }, error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                })
        } else {
            this.fusionChartArrayDataObj.dataSource.data = []
        }



    }

    getHealthCheckPieChart() {


        if (this.chartObj) {
            // this.chartObj.dispose()
            this.chartObj.removeEventListener('dataplotClick', this.handler);
        }
        var URL = 'healthCheck/getHealthCheckPieChart';
        this.apiService.getAll(URL).pipe(delay(5000),finalize(()=> {
            this.stopLoading();
        })).subscribe(
            data => {
                this.stopLoading();
                if (data.statusCode == 100) {
                    let healthCheckChartArr = data.responseList;
                    let Arr = [];
                    let valueArr = healthCheckChartArr
                    valueArr.map((val, key) => {
                        let obj = {
                            'label': val.label,
                            'value': val.value,
                            'color': val.color
                        };
                        Arr.push(obj);

                    });

                    this.fusionChartArrayDataObj.dataSource.data = Arr
                    //     this.fusionChartArrayDataObj.dataSource = {
                    //     "chart": {
                    //         //caption: "Market Share of Web Servers",
                    //         plottooltext: "<b>$percentValue</b> of web servers run on $label servers",
                    //         showlegend: "1",
                    //         showpercentvalues: "1",
                    //         legendposition: "bottom",
                    //         usedataplotcolorforlabels: "1",
                    //         theme: "fusion",
                    //         width: '600', 
                    //         height: '200',
                    //     }, 
                    //     "data" : Arr
                    // };

                    setTimeout(() => {
                        this.handler = this.clickSystemHelthCheckEvent.bind(this);
                        // this.message = this.clickPlotMsg;
                        // this.attached = true;
                        // this.chartObj.addEventListener('dataplotClick', this.handler);

                    }, 1000);


                } else {
                    this.fusionChartArrayDataObj.dataSource.data = [];
                    // this.healthCheckChartArr = [];
                }


                //   setTimeout(() => {
                //     var dataSource = this.dataSource;
                //     this.dataSource.data = this.tempArr; 
                //   //    FusionCharts.ready(function() {
                //   //     var myChart = new FusionCharts({
                //   //       type: "pie2d",
                //   //       renderAt: "chart-container",
                //   //       width: "100%",
                //   //       height: "90%",
                //   //       dataFormat: "json",
                //   //       dataSource
                //   //     }).render();
                //   // });      
                //   }, 1000);
                //   setTimeout(() => {
                //     this.handler = this.clickChart.bind(this);
                //     // this.message = this.clickPlotMsg;
                //     // this.attached = true;
                //     this.chartObj.addEventListener('dataplotClick', this.handler);
                //   }, 1000);

            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    getEventBargraph(obj) {
        if (this.chartObj) {
            // this.chartObj.dispose()
            this.chartObj.removeEventListener('dataplotClick', this.handler);
        }
        var URL = 'runTimeEvent/getClientDashboardCounts';
        let apiData : any = {
            clientId: this.clientId,
            userId: this.userId
          } 
        // if (obj.tier) {
        //     var tierString = '&tierId=' + obj.tier.toString();
        //     var URL = URL.concat(tierString);
        // }
        // if (obj.site) {
        //     var siteString = '&siteId=' + obj.site;
        //     var URL = URL.concat(siteString);
        // }

        if (obj.tier) {
            let tierIds = []
            obj.tier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            apiData.tierIds = tierIds.toString();
            // var tierString = '&tierId=' + tierIds.toString();
            // var URL = URL.concat(tierString);
        }
        if (obj.site) {
            let siteIds = []
            obj.site.map((obj, index) => {
                siteIds.push(obj.siteId)
            })
            apiData.siteIds = siteIds.toString();
            // var siteString = '&siteId=' + siteIds.toString();
            // var URL = URL.concat(siteString);
        }

        if (obj.asset) {
            let assetIds = []
            obj.asset.map((obj, index) => {
                assetIds.push(obj.assetId)
            })
            if (assetIds.length) {
                apiData.assetIds = assetIds.toString();
                // var assetString = '&assetId=' + assetIds.toString();
                // var URL = URL.concat(assetString);
            }
        }
        // if (this.customerId != null && this.customerId != '') {
        //     var customerString = '&customerId=' + this.customerId.toString();
        //     var URL = URL.concat(customerString);
        // }
        this.startLoading();
        this.apiService.create(URL, apiData).pipe(delay(5000),finalize(()=> {
            this.stopLoading();
        })).subscribe(
            data => {
                this.stopLoading();
                if (data.statusCode == 100) {

                    let Arr = [];
                    let valueArr = data.responseList

                    valueArr.map((val, key) => {
                        if (val.fill) {
                            // delete val.fill;
                        }
                        Arr.push(val);
                    })




                    // Create chart instance
                    let chart = am4core.create("chart_amchart_" + this.index, am4charts.XYChart);

                    // Add data
                    chart.data = Arr

                    // Create axes
                    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "label";
                    categoryAxis.renderer.labels.template.rotation = 0;
                    categoryAxis.renderer.labels.template.hideOversized = false;
                    categoryAxis.renderer.minGridDistance = 60;
                    categoryAxis.renderer.labels.template.horizontalCenter = "right";
                    categoryAxis.renderer.labels.template.verticalCenter = "middle";
                    categoryAxis.renderer.labels.template.paddingTop = 20;
                    // categoryAxis.tooltip.label.rotation = 0;
                    // categoryAxis.tooltip.label.horizontalCenter = "right";
                    // categoryAxis.tooltip.label.verticalCenter = "middle";
                    // categoryAxis.renderer.grid.template.location = 0.5;
                    let label = categoryAxis.renderer.labels.template;
                    label.wrap = true;
                    label.width = am4core.percent(25);
                    label.truncate = true;
                    label.tooltipText = "{category}";

                    categoryAxis.renderer.labels.template.location = 1//0.5
                    // categoryAxis.renderer.grid.template.location = 0;

                    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.title.text = "Events";
                    valueAxis.title.fontWeight = "bold";
                    // Create series
                    let series = chart.series.push(new am4charts.ConeSeries());
                    series.dataFields.valueY = "value";
                    series.dataFields.categoryX = "label";
                    // series.name = "Events";
                    series.tooltipText = "{categoryX}\n[bold]{valueY}[/]";
                    // series.columns.template.fillOpacity = .8;
                    series.columns.template.width = am4core.percent(40);
                    series.columns.template.propertyFields.fill = "fill";
                    let columnTemplate = series.columns.template;
                    // columnTemplate.strokeWidth = 2;
                    // columnTemplate.strokeOpacity = 1;
                    // columnTemplate.stroke = am4core.color("#FFFFFF");

                    // columnTemplate.adapter.add("fill", function(fill, target) {
                    //   return chart.colors.getIndex(target.dataItem.index);
                    // })
                    columnTemplate.propertyFields.stroke = 'fill'//am4core.color("#FFFFFF");

                    // columnTemplate.adapter.add("stroke", function(stroke, target) {
                    //   return chart.colors.getIndex(target.dataItem.index);
                    // })

                    chart.cursor = new am4charts.XYCursor();
                    chart.cursor.lineX.strokeOpacity = 0;
                    chart.cursor.lineY.strokeOpacity = 0;

                    series.columns.template.events.on("hit", function (ev) {
                        console.log("clicked on ", ev.target.dataItem);
                        this.clickEventBarGraphList(ev.target.dataItem)
                    }, this);



                    // setTimeout(() => {
                    this.fusionChartArrayDataObj.dataSource.data = Arr
                    this.handler = this.clickEventBarGraphList.bind(this);
                    // this.message = this.clickPlotMsg;
                    // this.attached = true;
                    // this.chartObj.addEventListener('dataplotClick', this.handler);
                    // }, 1000);

                } else {
                    this.fusionChartArrayDataObj.dataSource.data = [];
                }
            },
            error => {
                this.stopLoading();
                // this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    getFirstFaultgraph(obj) {

        if (this.chartObj) {
            // this.chartObj.dispose()
            this.chartObj.removeEventListener('dataplotClick', this.handler);
        }

        var URL = 'runTimeEvent/getFirstFaultSummary';
        let reqObj: any = {
            clientId: this.clientId,

        }
        if (obj.tier && obj.tier.length) {
            let tierIds = []
            obj.tier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            reqObj.tierIds =  tierIds.toString();
            var tierString = '&tierIds=' + tierIds.toString();
            // var URL = URL.concat(tierString);
        }
        // if (obj.site) {
        //   var siteString = '&siteId=' + obj.site;
        //   var URL = URL.concat(siteString);
        // }
        // if (obj.asset.length) {
        //     var assetString = '&assetId=' + obj.asset;
        //     var URL = URL.concat(assetString);
        // }
        if (obj.asset && obj.asset.length) {
            let assetIds = []
            obj.asset.map((obj, index) => {
                assetIds.push(obj.assetId)
            })
            reqObj.assetIds = assetIds.toString();
            var assetString = '&assetIds=' + assetIds.toString();
            // var URL = URL.concat(assetString);
        }
        if (obj.range) {
            var valString = '&days=' + obj.range;
            reqObj.days =  obj.range;
            // var URL = URL.concat(valString);
            this.ShowFormValue = false;
        } else {
            this.ShowFormValue = true;
            var valString = '&days=1' //+ obj.range;
            reqObj.days = 1;
            // var URL = URL.concat(valString);
            this.ShowFormValue = false;
            this.timeRange = 1
            // return
        }
        this.startLoading();
        this.apiService.create(URL, reqObj).pipe(delay(5000),finalize(()=> {
            this.stopLoading();
        })).subscribe(
            data => {
                this.stopLoading();
                if (data.statusCode == 100 || data.statusCode == 103) {

                    let Arr = [];
                    let valueArr = [] // data.responseList
                    // let list = [{"timeFrame":"past 7 days","label":"volts1","value":16},{"timeFrame":"past 7 days","label":"volts2","value":16},{"timeFrame":"past 7 days","label":"volts3","value":16},{"timeFrame":"past 7 days","label":"volts4","value":16},{"timeFrame":"past 7 days","label":"volts5","value":16},{"timeFrame":"past 7 days","label":"volts6","value":16},{"timeFrame":"past 7 days","label":"volts7","value":16},{"timeFrame":"past 7 days","label":"volts8","value":16},{"timeFrame":"past 7 days","label":"volts9","value":16},{"timeFrame":"past 7 days","label":"volts11","value":16},{"timeFrame":"past 7 days","label":"volts12","value":16},{"timeFrame":"past 7 days","label":"volts13","value":16},{"timeFrame":"past 7 days","label":"volts14","value":16},{"timeFrame":"past 7 days","label":"volts15","value":16},{"timeFrame":"past 7 days","label":"volts16","value":16},{"timeFrame":"past 7 days","label":"volts17","value":16},{"timeFrame":"past 7 days","label":"volts18","value":16},{"timeFrame":"past 7 days","label":"volts19","value":16},{"timeFrame":"past 7 days","label":"volts20","value":16},{"timeFrame":"past 7 days","label":"volts20","value":16},{"timeFrame":"past 7 days","label":"volts","value":16},{"timeFrame":"past 7 days","label":"LOW 1ST STAGE PRESSURE","value":5},{"timeFrame":"past 7 days","label":"HIGH 1ST STAGE  PRESSURE","value":5},{"timeFrame":"past 7 days","label":"LOW 2ND STAGE DISCHARGE PRESSURE","value":3},{"timeFrame":"past 7 days","label":"LOW DISCHARGE PRESSURE","value":1},{"timeFrame":"past 7 days","label":"LOW COMPRESSOR OIL PRESSURE","value":1},{"timeFrame":"past 7 days","label":"HIGH 1ST STAGE DISCHARGE PRESSURE","value":1}]
                    data.responseList.map((obj, index)=>{
                        // console.log(obj.label.match(/\[([^[]*)\]/g), "=========================================")
                        var text = obj.label//'This note is created on [date] by [admin;operator] for []'
                        let textArr = text.split(' ')
                        let arr = text.match(/\[([^[]*)\]/g)
                        console.log(arr, "=============================+++++++++++");
                        if (arr) {
                            
                            arr.map((str, index)=>{
                                // console.log(textArr.indexOf(str), textArr);
                                let sindex = textArr.indexOf(str)
                                textArr.splice(sindex, 1, "["+str+"]")
                            })
                            // console.log(arr, "----------------------------------------1-1--1-1-1-1-1--1",textArr, textArr.join(' '));
                            obj.label =  textArr.join(' ')
                        }    
                        valueArr.push(obj)
                        
                        
                    })
                    // console.log(valueArr, ")_)_)_)_)_)_)_)_)_)_)_)_)_)_)_)_)__");
                    console.log('valueArr------>>>>',valueArr)
                    

                    let self = this

                    let chart = am4core.create("chart_amchart_" + this.index, am4charts.PieChart3D);
                    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
                    chart.data = valueArr

                    let legend = new am4charts.Legend();
                    legend.position = "right"
                    legend.labels.template.fontSize = 10
                    legend.valueLabels.template.textAlign = "start";
                    // legend.labels.template.width =  am4core.percent(400);
                    // legend.labels.template.truncate = true;
                    // legend.labels.template.wrap = true;
                    legend.itemContainers.template.tooltipText = "{category}";
                    // legend.zIndex = 9999
                    // legend.tooltip.label.wrap = true;
                    // legend.tooltip.label.maxWidth = 150;
                    // legend.paddingBottom = 10
                    // legend.paddingLeft = -20
                    // legend.paddingRight = 10
                    legend.valueLabels.template.disabled = true;
                    legend.labels.template.text = "{category}";
                    chart.legend = legend   
                    chart.legend.scrollable = true;


                    var markerTemplate = chart.legend.markers.template;
                    markerTemplate.width = 10;
                    markerTemplate.height = 10;


                    var legendContainer = am4core.create("legenddiv", am4core.Container);
                    legendContainer.width = am4core.percent(100);
                    legendContainer.height = am4core.percent(100);
                    
                    chart.legend.parent = legendContainer;

                    // chart.events.on("datavalidated", resizeLegend1);
                    // chart.events.on("maxsizechanged", resizeLegend1);

                    // function resizeLegend1(ev) {
                    // document.getElementById("legenddiv").style.height = chart.legend.contentHeight + "px";
                    // }




                    // chart.legend.width = am4core.percent(50)
                    // chart.legend.height = am4core.percent(50)
                    // let legendContainer = am4core.create("legenddiv"+this.index, am4core.Container);
                    // legendContainer.width = am4core.percent(100);
                    // legendContainer.height = am4core.percent(100);
                    // chart.legend.parent = legendContainer;

                    // chart.legend.maxHeight = 150;
                    // chart.legend.scrollable = true;

                    // var element = document.getElementById('legenddiv'+this.index);
                    // element.style.height = "100px";
                    // element.style.overflow = "auto";

                    // var legendContainer:any = am4core.create("legenddivfirstFault", am4core.Container);
                    // legendContainer.width = am4core.percent(100);
                    // legendContainer.height = am4core.percent(100);
                    // legendContainer.overflow = 'auto'
                    // chart.legend.parent = legendContainer;

                    // chart.events.on("datavalidated", function resizeLegend(ev) {
                    //     console.log(chart.legend.contentHeight);

                    //     document.getElementById("legenddivfirstFault").style.height = chart.legend.contentHeight + "px";
                    //   });
                    // chart.events.on("maxsizechanged", function resizeLegend(ev) {
                    //     console.log(chart.legend.contentHeight, "Resize");

                    //     document.getElementById("legenddivfirstFault").style.height = chart.legend.contentHeight + "px";
                    //   });

                    // chart.legend.scrollable = true;
                    // chart.legend.sc
                    // chart.data = [{"timeFrame":"past 100 days","label":"LOW SUCTION PRESSURE","value":382},{"timeFrame":"past 100 days","label":"MANUAL SHUTDOWN","value":149},{"timeFrame":"past 100 days","label":"NO-FLOW COMPRESSOR LUBRICATOR","value":103},{"timeFrame":"past 100 days","label":"LOW COMPRESSOR OIL PRESSURE","value":97},{"timeFrame":"past 100 days","label":"ENGINE PANEL SHUTDOWN","value":94},{"timeFrame":"past 100 days","label":"HIGH/LOW 3RD STAGE DISCHARGE PRESSURE","value":67},{"timeFrame":"past 100 days","label":"HIGH 3RD INTERSTAGE SCRUBBER LIQUID LEVEL","value":65},{"timeFrame":"past 100 days","label":"HIGH 3RD STAGE DISCHARGE PRESSURE","value":64},{"timeFrame":"past 100 days","label":"HIGH SUCTION PRESSURE","value":64},{"timeFrame":"past 100 days","label":"LOW 3RD STAGE DISCHARGE PRESSURE","value":40},{"timeFrame":"past 100 days","label":"LOW 3RD STAGE DISCHARGE PRESSURE 1","value":41},{"timeFrame":"past 100 days","label":"LOW 3RD STAGE DISCHARGE PRESSURE2","value":42},{"timeFrame":"past 100 days","label":"LOW 3RD STAGE DISCHARGE PRESSURE3","value":43},{"timeFrame":"past 100 days","label":"LOW 3RD STAGE DISCHARGE PRESSURE4","value":44},{"timeFrame":"past 100 days","label":"LOW 3RD STAGE DISCHARGE PRESSURE5","value":45}]

                    // chart.innerRadius = 50;
                    chart.colors.list = [
                        am4core.color("#b85f40"),
                        am4core.color("#2d802f"),
                        am4core.color("#cb9918"),
                        am4core.color("#52a39f"),
                        am4core.color("#222222"),
                    ];
                    let series = chart.series.push(new am4charts.PieSeries3D());
                    series.dataFields.value = "value";
                    series.dataFields.category = "label";
                    series.configField = "config";
                    chart.radius = am4core.percent(70)
                    // series.fill = 
                    // let colorlist = [
                    //     new am4core.color("#b85f40")
                    // ]
                    // chart.colors.list = ["#b85f40", "#2d802f", "#cb9918", "#52a39f", "#222222"].map((color:any)=> {
                    //    return new am4core.color(color);
                    //   });
                    series.slices.template.events.on("hit", function (ev) {
                        let series = ev.target.dataItem.component;
                        console.log(ev, "-----------------------------------", ev.target.dataItem);
                        // this.clickEvenFirstFaltChart(ev.target.dataItem)
                        self.clickEvenFirstFaltChart(ev.target.dataItem)
                        // series.slices.each(function(item) {
                        //   if (item.isActive && item != ev.target) {
                        //     item.isActive = false;
                        //   }
                        // })
                    });
                    series.alignLabels = true;
                    series.labels.template.radius = am4core.percent(10);
                    //   series.ticks.template.disabled = true;
                    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";//"{value}";//
                    //   series.minBulletDistance = 100
                    series.labels.template.fontSize = 8;
                    series.slices.template.fontSize = 8
                    // series.labels.template.width = am4core.percent(10); // maxWidth = 200;
                    // series.labels.template.maxWidth = 350;
                    // series.labels.template.wrap = true;
                    series.labels.template.tooltipText = "{category} : {value.percent.formatNumber('#.0')}%";
                    // series.labels.template.truncate = true;
                    //   series.labels.template.adapter.add("radius", function(radius, target) {
                    //     if (target.dataItem && (target.dataItem.values.value.percent < 10)) {
                    //       return 0;
                    //     }
                    //     return radius;
                    //   });
                    //   series.labels.template. = 10
                    // chart. = new am4core.Scrollbar();
                  
                    // chart.legend.
                    if (valueArr.length == 0) {
                        let indicator = chart.tooltipContainer.createChild(am4core.Container);
                        indicator.background.fill = am4core.color("#fff");
                        indicator.background.fillOpacity = 0.8;
                        indicator.width = am4core.percent(100);
                        indicator.height = am4core.percent(100);

                        var indicatorLabel = indicator.createChild(am4core.Label);
                        indicatorLabel.text = "No data...";
                        indicatorLabel.align = "center";
                        indicatorLabel.valign = "middle";
                        indicatorLabel.fontSize = 20;
                    }




                    // valueArr.map((val, key) => {
                    //     val.label = val.firstFaultCodeDesc
                    //     val.value = val.total
                    //     Arr.push(val);
                    // })
                    // setTimeout(() => {
                    this.fusionChartArrayDataObj.dataSource.data = JSON.parse(JSON.stringify(valueArr))
                    this.handler = this.clickEvenFirstFaltChart.bind(this);
                    // this.message = this.clickPlotMsg;
                    // this.attached = true;
                    // this.chartObj.addEventListener('dataplotClick', this.handler);

                    // }, 1000);
                } else {
                    this.fusionChartArrayDataObj.dataSource.data = [];
                }
            },
            error => {
                this.stopLoading();
                // this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    getDataPointListOnAssetChange(event, isMap?: any) {
        let assetIds = []
        setTimeout(() => {
            console.log(event/* .target.value, */, this.selectedAssetList);
            this.selectedAssetList.map((obj, index) => {
                assetIds.push(obj.assetId)
            })
            if (assetIds.length) {
                this.startLoading();
                var URL = "dashboard/getDataPointByAsset";
                let reqObj: any = {
                    assetIds: assetIds.toString(),
                    isEnabled: 1
                };
                this.apiService.create(URL, reqObj).pipe(delay(5000),finalize(()=> {
                    this.stopLoading();
                })).subscribe(
                    data => {
                        this.stopLoading();
                        if (data.statusCode == 100) {
                            this.DataPointList = data.responseList;
                        } else {
                            this.DataPointList = [];
                        }

                        // if (isMap) {
                        //     console.log(isMap, "=============================================isMap", this.selectedDataPointId, this.DataPointList);
                        //     let ArrObj = []
                        //     this.DataPointList.map((obj, index) => {

                        //         // this.selectedDataPointId.indexOf()

                        //         let indexValue = this.selectedDataPointId.indexOf(obj.assetChannelId)
                        //         console.log(indexValue, "++++++++++++++++++++++++++++++++++++indexValue");
                        //         if (index != -1) {
                        //             ArrObj.push({ assetChannelId: obj.assetChannelId, value: obj.value })
                        //         }


                        //     })
                        //     this.selectedDataPoint = ArrObj
                        // }
                    },
                    error => {
                        this.stopLoading();
                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    }
                );
            } else {
                this.DataPointList = [];
            }
        }, 500)
    }

    getDataPointListOnAssetChangeByID(event, isMap?: any) {
        let assetIds = []
        setTimeout(() => {
            console.log(event/* .target.value, */, this.selectedAssetList);
            this.selectedAssetList.map((obj, index) => {
                assetIds.push(obj.assetId)
            })
            if (event.target.value) {
                this.startLoading();
                var URL = "dashboard/getDataPointByAsset";
                let reqObj: any = {
                    assetIds: event.target.value,
                    isEnabled: 1
                };
                this.apiService.create(URL, reqObj).subscribe(
                    data => {
                        this.stopLoading();
                        if (data.statusCode == 100) {
                            this.DataPointList = data.responseList;
                        } else {
                            this.DataPointList = [];
                        }
                        this.stopLoading();
                    },
                    error => {
                        this.stopLoading();
                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    }
                );
            } else {
                this.DataPointList = [];
            }
        }, 500)
    }

    resetForm() {
        this.dataForm.reset();
    }

    // Function to format Date
    DateFormat(startDate) {
        if (startDate) {
            return moment(startDate, "YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss")
        } else {
            return "-";
        }
    }

    changeCss(event, id, dataForm, index) {
        // console.log('from change css', id, dataForm, index);
        if (this.name == 'Gauge Chart') {
            const element = document.getElementById(`${id}`)
            element.style.width = '500px'
            element.style.margin = '0 auto'
        }
    }
    
    getHistory(){
        if (this.name == 'Asset Status Pie Chart') {
           this.isShowSite = true;
           this.isShowAsset = false;
           this.isShowDatapoint = false;
           this.isShowRange = false;
           this.isShowUnitNumber = false;
        } else if (this.name == 'Event Status') {
            this.isShowSite = false;
            this.isShowAsset = true;
            this.isShowDatapoint = false;
            this.isShowRange = false;
            this.isShowUnitNumber = false;
        } else if (this.name == 'First Fault pie chart summary') {
            this.isShowSite = false;
            this.isShowAsset = true;
            this.isShowDatapoint = false;
            this.isShowRange = true; 
            this.isShowUnitNumber = false;
        }
        else if (this.name == 'UP/Down time first fault code') {
            this.isShowSite = false;
            this.isShowAsset = true;
            this.isShowDatapoint = false;
            this.isShowRange = true;
            this.isShowUnitNumber = true;
        }
        else if (this.name == 'Gauge Chart') {
            this.isShowSite = false;
            this.isShowAsset = true;
            this.isShowDatapoint = true;
            this.isShowRange = false;
            this.isShowUnitNumber = false;
        }
        else if (this.name == 'Multiple data point last values bar graph') {
            this.isShowSite = false;
            this.isShowAsset = true;
            this.isShowDatapoint = true;
            this.isShowRange = false;
            this.isShowUnitNumber = false;
        }
        else if (this.name == 'Up-Time/Downtime runtime chart') {
            this.isShowSite = false;
            this.isShowAsset = true;
            this.isShowDatapoint = false;
            this.isShowRange = true;
            this.isShowUnitNumber = true;
        }

        $('#HistoryModel'+this.index).modal('show')
        this.HistoryList = []
        if ($.fn.DataTable.isDataTable('#HistoryDatatable'+this.index)) {
          $('#HistoryDatatable'+this.index).DataTable({ "order": [], "destroy": true }).destroy();
        };
        this.apiService.getAll('dashboardWidget/getDashboardWidgetHistoryById?dashboardWidgetId=' + this.widgetObj.dashboardWidget.dashboardWidgetId).subscribe(
            data => {
            if (data.statusCode == 100) {
                this.HistoryList = data.responseList;
                setTimeout(() => {
                const table: any = $('#HistoryDatatable'+this.index);
                this.dataTable = table.DataTable({ "order": [], "destroy": true });
                }, 500);
            } else {
                setTimeout(() => {
                const table: any = $('#HistoryDatatable'+this.index);
                this.dataTable = table.DataTable({ "order": [], "destroy": true });
                }, 500);
                this.alertService.sweetMessage('error',data.responseMessage)
                
            }
            },
            error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        ); 
    }

    gettierName(list){
        let str = ''
        if (list.length) {
            list.map((obj,i)=>{
                if (i==0) {
                    str+=obj.tierName
                } else {
                    str+= ', '+obj.tierName
                }
            })
        }
        return str;
    } 
    getSiteName(list){
        let str = ''
        if (list.length) {
            list.map((obj,i)=>{
                if (i==0) {
                    str+=obj.siteName
                } else {
                    str+= ', '+obj.siteName
                }
            })
        }
        return str;
    } 
    getAssetName(list){
        let str = ''
        if (list.length) {
            list.map((obj,i)=>{
                if (i==0) {
                    str+=obj.assetName
                } else {
                    str+= ', '+obj.assetName
                }
            })
        }
        return str;
    } 
    getDatapointName(list){
        let str = ''
        if (list.length) {
            list.map((obj,i)=>{
                if (i==0) {
                    str+=obj.value
                } else {
                    str+= ', '+obj.value
                }
            })
        }
        return str;
    }    

}
