import { Component, OnInit, ChangeDetectorRef, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, FormatDateService } from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $: any;
declare var jQuery: any,Dygraph:any,Highcharts:any;
declare var d3: any, moment: any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-asset-graph',
  templateUrl: './asset-graph.component.html',
  styleUrls: ['./asset-graph.component.css']
})
export class AssetGraphComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId: any = 0;
  assetChannelData: any = [];
  graphRenderData: any = [];
  checkAssetArray: any = [];
  unitArray: any = ['date'];
  tempArray: any = [];
  assetsumaryData: any = {};
  data: any = [];
  options1: any = {};
  dataTable: any;
  dropdownSettingsAsset: any = {};
  assetData: any = [];
  assetDataPoint: any = { "graphName": "", "asset": { "assetId": "" }, "datapoint": [], "assetGraphId" : 0 };
  siteLat: number = 39.381266;
  siteLong: number = -97.922211;
  clientCode: any = '';
  permissionData: any = [];
  checkAsset: any = [];
  assetGraphId : any = 0;
  TimeZone = ""
  assetDataObj :any = {}
  DateError:any = null;
  graphNameError = ''
  breadcrumbObj: any;
  urlCustomerId: any;
  CdName: any;
  HistoryList: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset) {
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.assetId = params['id'];
      }
    });
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          this.urlCustomerId = params.cus_id;
          this.CdName = params.cusD_name;
      })
    if (this.assetId) {
      this.getAssetSummaryData();
      this.getAssetChannel();
      this.getAllAssetGraphData();


      this.dropdownSettingsAsset = {
        singleSelection: false,
        idField: 'assetChannelId',
        textField: 'assetChannelDescription',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
      };
    }


    jQuery('.rst-date').datetimepicker({
      language: 'en',
      pickTime: true,
      autoclose: true,
      pickDate: true,
      minDate: 0,
      inline: true,
      sideBySide: true
    });
    jQuery('.rst-time').datetimepicker({
        pickDate: false,
        autoclose: true,
        pickTime: true,
        pickSeconds: false,
        pickMinutes: true,
        timeFormat: "HH:mm"
    });


    let today = moment().format('YYYY-MM-DD HH:MM:ss')
    
    $('#endDate').val(today);
    $('#startDate').val(moment().subtract(45,'days').format('YYYY-MM-DD HH:MM:ss'));
    // console.log($('#startDate').val(),$('#endDate').val())

  }

  /* On Item Select State */
  onItemSelectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetChannelId);
    if (exist == '-1') {
      this.checkAssetArray.push(item.assetChannelId);
    }
  }

  /* On Item DeSelect State */
  onItemDeselectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetChannelId);
    if (exist != '-1') {
      this.checkAssetArray.splice(exist, 1);
    }
    console.log(this.checkAssetArray);
    
  }
  /* On All Select State */
  onSelectAllAsset(items: any) {
    this.checkAssetArray = [];
    Object.keys(items).forEach(key => {
      this.checkAssetArray.push(items[key]['assetChannelId'].toString());
    });
  }
  /* On All DeSelect State */
  onDeSelectAllAsset() {
    this.checkAssetArray = [];
  }

  // Function to set latitude and longitude
  setLatLong(lat, long) {
    this.siteLat = lat;
    this.siteLong = long;
  }


  reciveAsset() {
    this.getAssetSummaryData();
  }

  /* Function for get Asset Summary Data */
  getAssetSummaryData() {
    this.apiService.getAll('asset/getAssetSummaryById?clientId=' + this.clientId + '&userId=' + this.userId + '&assetId=' + this.assetId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetsumaryData = data.response;
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Function for get Asset Channel Data */
  getAssetChannel() {
    this.apiService.getAll('asset/getAssetChannelsByAssetId?clientId=' + this.clientId + '&userId=' + this.userId + '&assetId=' + this.assetId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetChannelData = data.responseList;
        } else {
          //this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Function for Create Asset Data Point */
  creatAssetsDataPoint() {
    $('#graphNameError').text('');
    let graphName = $('#graphname').val();
    if(!graphName) {
      this.graphNameError = 'Enter Graph Name';
      return;
    }   
    this.graphNameError = '';
    if(this.assetGraphId == 0){

      this.assetDataPoint.datapoint = this.checkAssetArray;
      this.assetDataPoint.asset.assetId = this.assetId;

      this.apiService.create('asset/createAssetGraph', this.assetDataPoint).subscribe(
        data => {
          if (data.statusCode == 100) {
            $("#addAssetDataPoint").click();
            this.alertService.sweetMessage('success', 'Asset Graph Added Successfully.');
            this.checkAssetArray = [];
            this.checkAsset = [];
            this.assetDataPoint = { "graphName": "", "asset": { "assetId": this.assetId }, "datapoint": [] };
            this.graphNameError = ''
            this.getAllAssetGraphData();
            var close = document.getElementById("closePop");
            close.click();
            // $('#addAssetDataPointModel').model('hide');
            $('#addAssetDataPointModel').modal('hide');
          } else {
            this.graphNameError = data.responseMessage;

            // this.alertService.sweetMessage('error', data.responseMessage);

          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }else {

      this.assetDataPoint.datapoint = this.checkAssetArray;
      this.assetDataPoint.asset.assetId = this.assetId;
      this.assetDataPoint.assetGraphId = this.assetGraphId;


      this.apiService.update('asset/updateAssetDataPointGraph',this.assetDataPoint).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.assetGraphId = 0;
            this.checkAssetArray = [];
            this.graphNameError = ''
            this.checkAsset = []
            this.getAllAssetGraphData();
            $('#addAssetDataPointModel').modal('hide');
            this.alertService.sweetMessage('success', "Graph updated Successfully");
          } else {
            this.graphNameError = data.responseMessage
            // this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

  }

  changGraphName(){
    this.graphNameError = ''
  }

  /* Function for get All Asset Graph Data */
  getAllAssetGraphData() {

    if ($.fn.DataTable.isDataTable('#asset_data')){
      $('#asset_data').DataTable().destroy();
      this.assetData=[];
    }

    this.apiService.getAll('asset/getAssetDatapointGraph?assetId=' + this.assetId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetData = data.response;
          if ($.fn.DataTable.isDataTable('#asset_data')){
            $('#asset_data').DataTable().destroy();
          };
          setTimeout(() => {
            const table: any = $('#asset_data');
            this.dataTable   = table.DataTable({
              "order": [],"columnDefs": [{ "orderable": false, "targets": 2 }]
            });
          }, 1000);

         
        } else {
          this.assetData = [];
          this.chRef.detectChanges();
          if ($.fn.DataTable.isDataTable('#asset_data')){
            $('#asset_data').DataTable().destroy();
          };
          setTimeout(() => {
            const table: any = $('#asset_data');
            this.dataTable   = table.DataTable({
              "order": [],"columnDefs": [{ "orderable": false, "targets": 2 }]
            });
          }, 1000);

          //this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  ResetDate(){
    let today = moment().format('YYYY-MM-DD HH:MM:ss')
    
    $('#endDate').val(today);
    $('#startDate').val(moment().subtract(45,'days').format('YYYY-MM-DD HH:MM:ss'));
    this.getAssetDataD(this.assetDataObj);
  }

  /* Get Asset Data By Specfic Channel Numbers*/
  getAssetDataD(assetChannelData) {

    this.assetDataObj = JSON.parse(JSON.stringify(assetChannelData))
    var dataPointArray = [];
    this.tempArray = [];
    this.graphRenderData = [];
    this.data = [];
    this.unitArray = ['date'];
    if (assetChannelData.assetGraphDataPointDTO) {
      Object.keys(assetChannelData.assetGraphDataPointDTO).forEach(key => {
        dataPointArray.push(assetChannelData.assetGraphDataPointDTO[key]['datapointId']);
      })
    }
    var channelNumber = dataPointArray.toString();
    // this.apiService.getAll('asset/drawAssetEventGraph?clientId=' + this.clientId + '&assetId=' + this.assetId +/*  '&assetGraphId=' + assetChannelData.assetGraphId */ '&channelNumber=' + channelNumber).subscribe(
    let start = $('#startDate').val();
    let end = $('#endDate').val()

    let diff = moment(end).diff(moment(start),'days')

    if(diff <0){
      this.DateError = "Please Enter valid Start Date and End Date"
  } 
  else {
    this.DateError = null
    // console.log('asset/drawAssetEventGraph?clientId='+this.clientId+'&assetId='+this.assetId+'&assetGraphId=' + assetChannelData.assetGraphId+ (start ? '&fromDate='+moment(start).format('YYYY-MM-DD HH:mm:ss'):'')+ (end ? "&toDate="+moment(end).format('YYYY-MM-DD HH:mm:ss'):''))
    this.apiService.getAll('asset/drawAssetEventGraph?clientId='+this.clientId+'&assetId='+this.assetId+'&assetGraphId=' + assetChannelData.assetGraphId+ (start ? '&fromDate='+moment(start).format('YYYY-MM-DD HH:mm:ss'):'')+ (end ? "&toDate="+moment(end).format('YYYY-MM-DD HH:mm:ss'):'')).subscribe(
      data => {
        if (data.statusCode == 100 || data.statusCode == 103 ) {
          this.graphRenderData = data.responseList ? data.responseList : [];

          var graphData = [];

          let tempGraphObj = {}

          var self = this;

        

          /* ************************************Highchart start********************************************** */
          let channelidArray = []

          let yAxisArray = []
          let SeriesName = []
          let SerieslabelName = []


          let isDataObj = false;
          this.graphRenderData.map((obj,index)=>{
            // if (index == 0) {
              tempGraphObj[index] = []
            // }
            // SeriesName.push(obj.description)
            yAxisArray.push({ // third yAxis
              gridLineWidth: 0,
              lineWidth: 2,
              title: {
                  text: obj.description,
                  // style: {
                  //     color: Highcharts.getOptions().colors[index]
                  // }
              },
              labels: {
                  format: '{value} ',
                  // style: {
                  //     color: Highcharts.getOptions().colors[index]
                  // }
              },
              opposite: index%2==0 ? true :false,
          })
          let TZ = ""

          isDataObj = isDataObj ? true : (obj.dataPoints.length>0 ? true : false )
          if (obj.dataPoints.length) {
            SeriesName.push(obj.description /* dataPoints[0].dataChannelDescription*/ ? obj.description : null)//dataPoints[0].dataChannelDescription
          }else{
            SeriesName.push(obj.description)
          }
          SerieslabelName.push(obj.textstate+'('+obj.description+')')
          // isDataObj = isDataObj ? true : (obj.dataPoints.length ? true : false )

            obj.dataPoints.map((pointObj,pointIndex)=>{
              TZ = pointObj['tzt']
            
              tempGraphObj[index].push([new Date( moment( pointObj.eventTime , "YYYY-MM-DD HH:mm:ss:sss").toDate()).getTime(),pointObj.originalValue])
            })
            this.TimeZone = TZ
          })

          let seriesData = []
          
          for (const key in tempGraphObj) {
            if (tempGraphObj.hasOwnProperty(key)) {
              const element = tempGraphObj[key];
             
              seriesData.push({
                data: element,
                lineWidth: 1,
                marker: {
                  symbol: 'circle'
                },
                yAxis: Number(key),
                name: SerieslabelName[key]
            })
            }
          }

          let title =  data.statusCode == 103 ?  data.responseMessage : (!isDataObj ? "No Data Available" :assetChannelData.graphName)
          setTimeout(() => {


            (function(H) {
              H.wrap(H.Legend.prototype, 'positionCheckboxes', function(p, scrollOffset) {
                var alignAttr = this.group.alignAttr,
                  translateY,
                  clipHeight = this.clipHeight || this.legendHeight;
            
                if (alignAttr) {
                  translateY = alignAttr.translateY;
                  H.each(this.allItems, function(item) {
                    var checkbox = item.checkbox,
                      bBox = item.legendItem.getBBox(true),
                      top;
            
                    if (checkbox) {
                      top = (translateY + checkbox.y + (scrollOffset || 0) + 2);
                      H.css(checkbox, {
                        left: (alignAttr.translateX + item.checkboxOffset + checkbox.x - 80 - bBox.width) + 'px',
                        top: (top+4) + 'px',
                        display: top > translateY - 6 && top < translateY + clipHeight - 6 ? '' : 'none'
                      });
                    }
                  });
                }
              });
            })(Highcharts);



              let chartObj = Highcharts.chart('chart-container-highchart', {

                chart: {
                    zoomType: 'x'
                },
                // exporting: { enabled: false },
                title: {
                    text: title
                },
                time:{
                  useUTC:false,
                },
                // subtitle: {
                //     text: ''
                // },
                // noData: "no data available",
                tooltip: {
                    // valueDecimals: 2,
                    // crosshairs: true,
                    shared: true
                },
                legend: {
                  enabled : true,
                  // itemDistance: 50,
                  
                  itemMarginTop: 4,
                  itemMarginBottom: 4,
                  // enabled: true,
                  // symbolPadding: 20
                //   useHTML: true,
                //   symbolWidth: 0,
                //   labelFormatter: function() {
                //     return '<input type="checkbox">' + series.name
                //   }
                },
                plotOptions:{
                  series:{
                    showCheckbox:true,
                    selected:true,
                    showInLegend: true,
                    events: {
                      legendItemClick: function() {
                        return false;
                      },
                      checkboxClick: function (event) {
                          if (event.checked) {
                              this.show();
                          } else {
                              this.hide();
                          }
                      }
                  }
                  }
                },


                exporting: {
                  enabled:data.statusCode == 103 ? false : true,
                  buttons: {
                      contextButton: {
                          // text: 'DOWNLOAD',
                          theme: {
                              fill: 'transparent',
                              //'stroke-width': 1,
                              //stroke: 'white',
                              //r: 1,
                              style: {
                                  color: 'white',
                                  fontSize: '10px'
                              },   
  
                              // states: {
                              //     hover: {
                              //         fill: 'transparent'
                              //     },
                              //     select: {
                              //         fill: 'transparent'
                              //     }
                              // }
                          },
                          menuItems: [{
                              text: 'Download PNG image',
                              onclick: function () {
                                  this.exportChart({
                                      type: 'image/png'
                                  });
                              }
                          }, {
                              text: 'Download JPEG image',
                              onclick: function () {
                                  this.exportChart({
                                      type: 'image/jpeg'
                                  });
                              }
                          }, {
                              text: 'Download PDF document',
                              onclick: function () {
                                  this.exportChart({
                                      type: 'application/pdf'
                                  });
                              }
  
                          }]
                      }
                  },
                  // filename: 'Map',
                  allowHTML: true,
                  chartOptions: {
                      chart: {
                          style: {
                              fontFamily: '"Gotham SSm A" "Gotham SSm B"'
                          },
                          height: 880,
                          spacingBottom: 300,
                          marginTop: 50,
                          width: 1010,
                          // backgroundColor: 'rgba(0, 0, 0, 0)',
  //                         events: {
  //                             load: function () {
  //                                 this.series[0].update({
  //                                     nullColor: 'rgba(245, 245, 245, 1)',
  //                                     borderColor: 'rgba(192, 192, 192, 1)'
  //                                 });
  //                                 //DisableGeographiesOnExport(this);
  //                                 //this.renderer.image('http://www.eiu.com/images/logos/EIU_logo2x.png', 0, 740, 100, 25).add();
  //                                 //this.renderer.image('http://data.eiu.com/assets/images/charts/bubbles-legend.png', 600, 565, 20, 16).add();
  //                                 this.renderer.label('<b>Size of opportunity</b>', 620, 565, null, null, null, true).add();
  //                                 //var label = this.renderer.label(getExportPropertiesTable(), null, null, null, null, null, true).add();
  
  // //                                label.align(Highcharts.extend(label.getBBox(), {
  // //                                    align: 'center',
  // //                                    x: 0, // offset
  // //                                    verticalAlign: 'bottom',
  // //                                    y: 160 // offset
  // //                                }), null, 'spacingBox');  
  //                             }
  //                         }
                      },
                      // legend: {
                      //     enabled: true,
                      //     verticalAlign: 'bottom',
                      //     align: 'center',
                      //     itemMarginTop: -10,
                      //     // itemStyle: {
                      //     //     color: 'rgb(0,0,0)'
                      //     // },
                      //     symbolRadius: 6,
                      //     symbolHeight: 13,
                      //     symbolWidth: 13                                                
                      // },
                
                      title: {
                          text: assetChannelData.graphName,
                          style: {
                              fontWeight: 'bold'
                          }
                      }
                  }
              },    

                
                xAxis: {
                    type: 'datetime'
                },
                yAxis: yAxisArray,

                series: seriesData
            });


            // chartObj.exportChart()
          }, 200);


          /* ************************************Highchart end********************************************** */

          this.graphCreate();
        } else {
          this.graphRenderData = [];
          this.alertService.sweetMessage('error', data.responseMessage);
        }



      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }
  }


  exportChart(assetChannelData){
    
    this.assetDataObj = JSON.parse(JSON.stringify(assetChannelData))
    var dataPointArray = [];
    this.tempArray = [];
    this.graphRenderData = [];
    this.data = [];
    this.unitArray = ['date'];
    if (assetChannelData.assetGraphDataPointDTO) {
      Object.keys(assetChannelData.assetGraphDataPointDTO).forEach(key => {
        dataPointArray.push(assetChannelData.assetGraphDataPointDTO[key]['datapointId']);
      })
    }
    var channelNumber = dataPointArray.toString();

    let start = $('#startDate').val();
    let end = $('#endDate').val()

    let diff = moment(end).diff(moment(start),'days')

    if(diff <0){
      this.DateError = "Please Enter valid Start Date and End Date"
  } 
  else {
    this.DateError = null
    this.apiService.getAll('asset/drawAssetEventGraph?clientId='+this.clientId+'&assetId='+this.assetId+'&assetGraphId=' + assetChannelData.assetGraphId+ (start ? '&fromDate='+moment(start).format('YYYY-MM-DD HH:mm:ss'):'')+ (end ? "&toDate="+moment(end).format('YYYY-MM-DD HH:mm:ss'):'')).subscribe(
      data => {
        if (data.statusCode == 100 || data.statusCode == 103 ) {
          this.graphRenderData = data.responseList ? data.responseList : [];

          var graphData = [];

          let tempGraphObj = {}

          var self = this;


          /* ************************************Highchart start********************************************** */
          let channelidArray = []

          let yAxisArray = []
          let SeriesName = []
          let SerieslabelName = []



          let isDataObj = false;
          this.graphRenderData.map((obj,index)=>{
            // if (index == 0) {
              tempGraphObj[index] = []
            yAxisArray.push({ // third yAxis
              gridLineWidth: 0,
              lineWidth: 2,
              title: {
                  text: obj.description,
              },
              labels: {
                  format: '{value} ',
              },
              opposite: index%2==0 ? true :false,
          })
          let TZ = ""

          isDataObj = isDataObj ? true : (obj.dataPoints.length>0 ? true : false )
          if (obj.dataPoints.length) {
            SeriesName.push(obj.description /* dataPoints[0].dataChannelDescription*/ ? obj.description : null)//dataPoints[0].dataChannelDescription
          }else{
            SeriesName.push(null)
          }
          SerieslabelName.push(obj.textstate)

            obj.dataPoints.map((pointObj,pointIndex)=>{
              TZ = pointObj['tzt']              
              // tempGraphObj[index].push([new Date(moment(pointObj.eventTime, "YYYY-MM-DD HH:mm:ss:sssZ").toDate()).getTime(),pointObj.originalValue])
              tempGraphObj[index].push([new Date( moment( pointObj.eventTime , "YYYY-MM-DD HH:mm:ss:sss").toDate()).getTime(),pointObj.originalValue])

            })
            this.TimeZone = TZ
          })

          let seriesData = []
          for (const key in tempGraphObj) {
            if (tempGraphObj.hasOwnProperty(key)) {
              const element = tempGraphObj[key];
              seriesData.push({
                data: element,
                lineWidth: 1,
                marker: {
                  symbol: 'circle'
                },
                yAxis: Number(key),
                name: SerieslabelName[key]
            })
            }
          }

          let title =  data.statusCode == 103 ?  data.responseMessage : (!isDataObj ? "No Data Available" :assetChannelData.graphName);
          setTimeout(() => {


            (function(H) {
              H.wrap(H.Legend.prototype, 'positionCheckboxes', function(p, scrollOffset) {
                var alignAttr = this.group.alignAttr,
                  translateY,
                  clipHeight = this.clipHeight || this.legendHeight;
            
                if (alignAttr) {
                  translateY = alignAttr.translateY;
                  H.each(this.allItems, function(item) {
                    var checkbox = item.checkbox,
                      bBox = item.legendItem.getBBox(true),
                      top;
            
                    if (checkbox) {
                      top = (translateY + checkbox.y + (scrollOffset || 0) + 2);
                      H.css(checkbox, {
                        left: (alignAttr.translateX + item.checkboxOffset + checkbox.x - 100 - bBox.width) + 'px',
                        top: top + 'px',
                        display: top > translateY - 6 && top < translateY + clipHeight - 6 ? '' : 'none'
                      });
                    }
                  });
                }
              });
            })(Highcharts);



              let chartObj = Highcharts.chart('chart-container-highchart', {

                chart: {
                    zoomType: 'x'
                },
                // exporting: { enabled: false },
                title: {
                    text: title
                },
                time:{
                  useUTC:false,
                },
                tooltip: {
                    shared: true
                },
                legend: {
                  enabled: true,
                  symbolPadding: 20
                },
                plotOptions:{

                  series:{
                    showCheckbox:true,
                    selected:true,
                    showInLegend: true,
                    events: {
                      legendItemClick: function() {
                        return false;
                      },
                      checkboxClick: function (event) {
                          if (event.checked) {
                              this.show();
                          } else {
                              this.hide();
                          }
                      }
                  }
                  }
                },


                exporting: {
                  // width:800,
                  buttons: {
                      contextButton: {
                          // text: 'DOWNLOAD',
                          theme: {
                              fill: 'transparent',
                              style: {
                                  color: 'white',
                                  fontSize: '10px'
                              },   
  
                          },
                          menuItems: [{
                              text: 'Download PNG image',
                              onclick: function () {
                                  this.exportChart({
                                      type: 'image/png'
                                  });
                              }
                          }, {
                              text: 'Download JPEG image',
                              onclick: function () {
                                  this.exportChart({
                                      type: 'image/jpeg'
                                  });
                              }
                          }, {
                              text: 'Download PDF document',
                              onclick: function () {
                                  this.exportChart({
                                      type: 'application/pdf'
                                  });
                              }
  
                          }]
                      }
                  },
                  // filename: 'Map',
                  allowHTML: true,
                  chartOptions: {
                      chart: {
                          style: {
                              fontFamily: '"Gotham SSm A" "Gotham SSm B"'
                          },
                          height: 880,
                          spacingBottom: 300,
                          marginTop: 50,
                          width: 1010,
                      },
                      // legend: {
                      //     enabled: true,
                      //     verticalAlign: 'bottom',
                      //     align: 'center',
                      //     itemMarginTop: 10,
                      //     symbolRadius: 6,
                      //     symbolHeight: 13,
                      //     floating: false, 
                      //     symbolWidth: 13,
                      //     //  padding: 5,
                      //      //symbolPadding: 3,
                      //     itemDistance: 10                                            
                      // },
                      title: {
                          text: data.statusCode == 103 ? 'No Record Found' : assetChannelData.graphName,
                          style: {
                              fontWeight: 'bold'
                          }
                      }
                  }
              },    
                xAxis: {
                    type: 'datetime'
                },
                yAxis: yAxisArray,

                series: seriesData
            });
            console.log("-------------------------------------------", data);
            
            if (data.statusCode == 103) {
             self.alertService.sweetMessage('error', 'No data to export.');
            }else{
              chartObj.exportChart()
            }
          }, 200)

          /* ************************************Highchart end********************************************** */

          this.graphCreate();
        } else {
          this.graphRenderData = [];
          this.alertService.sweetMessage('error', data.responseMessage);
        }



      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }
  }

  /* Create Graph */
  graphCreate() {
  
  }

  getGraphById(assetGraphId) {
    this.assetGraphId = assetGraphId;
    this.apiService.getAll('asset/getAssetDataPointGraphById?assetGraphId='+this.assetGraphId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetDataPoint.graphName = data.response.graphName;

          if (data.response.assetGraphDataPoints) {
            var tempData=[];
            setTimeout(()=>{
              // this.checkAsset = [{assetChannelId: 452347}];
              Object.keys(data.response.assetGraphDataPoints).forEach(key => {
                tempData.push({assetChannelId:parseInt(data.response.assetGraphDataPoints[key]['dataPoint'])})
                this.checkAssetArray.push(parseInt(data.response.assetGraphDataPoints[key]['dataPoint']));
              })
              this.checkAsset = tempData;
            },500)
          }
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );


  }


deleteGraphData(id) {

  this.apiService.delete('asset/deleteAssetGraph?assetGraphId='+id, "").subscribe(
    data => {
      if (data.statusCode == 100) {
        this.assetGraphId = 0;
        this.getAllAssetGraphData();
        this.alertService.sweetMessage('success', "Graph deleted Successfully");
      } else {
        this.alertService.sweetMessage('error', data.responseMessage);
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );

}

  getDataPointName(datapoint){
    if (datapoint) {
      let name = ''
      if (datapoint.length) {
        datapoint.map((val,index)=>{
          if (name) {
            if (val.datapointName) {
              name = name.trim() + ', ' +val.datapointName
            }
          } else {
            name += val.datapointName            
          }          
        })
        return name
      }
    } else {
      return '-'
    }
  }

  resetGrapgdataObj(){
    this.graphNameError = '';
  this.assetDataPoint = { "graphName": "", "asset": { "assetId": "" }, "datapoint": [], "assetGraphId" : 0 };

  }
  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('asset/getAssetGraphHistoryById?assetGraphId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

  getDatapointName(datapoint){
    let str = '';
    if (datapoint.length) {
      datapoint.map((obj,index)=>{
        if (index==0) {
          str += obj.datapointName
        } else {
          str += ', '+obj.datapointName
        }
      })
    }
    return str;
  }
}
