import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService } from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    // Initialized Variables
    email: any = "";
    loginPageContentData:any;
    imageFile:any;
    isLoading:any = false;

    // Constructor
    constructor(private router: Router, private apiService: ApiService, private alertService: AlertService) {}

    ngOnInit() {
        // get Page content 
        this.getContent();
        $(document).ready(function() {
            // CSS
            $("body").css({
                "padding-top": "0px"
            });
        });

    }

    /* Get page content data by domain name */
    getContent() {
        var url = this.apiService.appUrl;
        this.apiService.getLoginDatawithoutToken('client/getClientByDomain?domain=' + url).subscribe(
            data => {
                if(data.statusCode == 100){
                    this.loginPageContentData=data.response.loginPageContent;
                    if(data.response.logoFile){
                      this.imageFile = data.response.logoFile;
                    }else{
                      this.imageFile = "assets/img/default_logo.png";
                      // this.logoClass = true;
                    }
                    // Loader Image for client
                    let loaderImg = data.response.loaderImg;
                    localStorage.setItem("loaderImg", loaderImg);
                    document.getElementById('imageData').setAttribute( 'src',data.response.logoFile ? this.apiService.imageBasePath+this.imageFile :this.imageFile );
                }else{
                  this.imageFile = "assets/img/default_logo.png";
                  localStorage.removeItem("loaderImg");
                  
                  document.getElementById('imageData').setAttribute( 'src',this.imageFile );
                } 
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    /* Function to reset Password */
    resetPassword() {
        this.isLoading = true;
        this.apiService.getLoginDatawithoutToken('user/forgetPassword?login=' + this.email.trim()).subscribe(
            data => {
                this.isLoading = false;
                if (data.statusCode == 100) {
                    // Link is sent to Email for changing password
                    Swal({
                        position: 'top-end',
                        type: 'success',
                        title: 'Reset Password link has been sent to your email, Please check.',
                        showConfirmButton: false,
                        timer: 3500
                    })
                    this.router.navigate(['/login']);
                } else {
                    this.isLoading = false;
                    Swal({
                        position: 'top-end',
                        type: 'error',
                        // title: "Please enter valid Email-Id.",
                        title: data.responseMessage/* 'Please enter valid Email-Id.' */,
                        showConfirmButton: false,
                        timer: 3500
                    })
                    this.email = "";
                }
            },
            error => {
                this.isLoading = false;
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

}