import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentCanDeactivate } from '../../../../../pending-changes.guard';
import { HostListener } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3: any, moment: any;
import * as _ from "lodash";

import { ApiService, AlertService } from '../../../../../services/index';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'


@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.css']
})
export class AddSiteComponent implements OnInit, ComponentCanDeactivate {
  breadcrumbObj: any;
  pathAssetId: any;
  pathCustID: any;
  pathcust_name: any;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.unsavedChanges;
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
  }

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;


  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tiersId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  countryList: any = [];
  stateList: any = [];
  assetList: any[] = [];
  assetFilter: any = [];
  siteAssetFilter: any = [];
  assetDataRecord: any = [];
  customerList: any = [];
  totalSiteAssets: number = 0;
  Sitelocation: boolean = false;
  totalAssets: number = 0;
  tierId: number = 0;
  siteId: number = 0;
  assetArray: any = [];
  dataAdapter: any = [];
  source: any = {};
  data: any = [];
  disableTreeArray: any = [];
  tierListData: any = [];
  records: any = [];
  permissionData: any = [];
  latLongEnable: any = false;
  unsavedChanges: any = false;
  apiParamData: any;
  isAssetAdd: any = false;
  assetCount: any = 0;
  clientCode: any = '';
  isCountryId: boolean = false;
  isdisabled: boolean = false;
  site: any = {
    "siteName": "", "city": "", "isActive": 1, "zipCode": "",
    "longitude": 0.0, "latitude": 0,
    "client": { "clientId": this.clientId },
    "customer": { "customerId": "" },
    "country": { "countryId": "", "countryName": "" },
    "state": { "stateId": "" },
    "isAutoFetchAssetLocation": false,
    "tier": { "tierId": "" }
  };

  assetLat: any;
  assetLong: any;
  default: number = 0;
  userAgent: any;
  ErroObj: any = {}

  isSiteErrorMsg:string = '';

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService, private formBuilder: FormBuilder) {
    this.permissionData = ApiService.permission;
  }

  DateFormat(startDate) {
    if (startDate) {
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    } else {
      return "";
    }
  }

  modelChanged(oldVal, newVal) {
    //  console.log(this.unsavedChanges);
    if (oldVal != newVal && this.unsavedChanges == false) {
      this.unsavedChanges = true;
    }
    //do something with new value
  }
  ngOnInit() {
    var _this = this;
    $(document).ready(function () {
      if ((screen.width >= 1024) && (screen.height >= 768)) {
        // alert('Screen size: 1024x768 or larger');
        _this.userAgent = 'desktop';
      }
      else {
        // alert('Screen size: less than 1024x768, 800x600 maybe?');
        _this.userAgent = 'tablet';
      }
    });

    // this.userAgent    = navigator.platform
    this.clientCode = this.alertService.getClientCode();
    this.Sitelocation = this.site.isAutoFetchAssetLocation;

    if (this.Sitelocation == true) {
      this.isdisabled = true;
    }
    else {
      this.isdisabled = false;
    }

    this.getAssetList();
    this.getCountryList();
    this.getTierList();
    //this.getCustomerList();

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.siteId = params['id'];
      }
    });
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          this.pathCustID = params['cus_id'];
          this.pathAssetId = params['aId'];
          this.pathcust_name = params['cust_name']; 
      })

    if (this.siteId) {
      this.getSiteById();
    }
  }

  /* Show tier label in dropdown on tree select */
  myTreeOnSelect(event: any) {
    this.myDropDownButton.close();
    if ((this.disableTreeArray).indexOf(parseInt(event.args.element.id)) != -1) {
      let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
      this.myDropDownButton.setContent(dropDownContent);
      this.tierId = 0;
    } else {
      this.tierId = event.args.element.id;
      let item = this.myTree.getItem(event.args.element);
      let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + item.label + '</div>';
      this.myDropDownButton.setContent(dropDownContent);
    }
  };

  /****************
  Fill Adress Detail if user select Obtain Site Location Automatically
  **************************/
  setLatLog() {

    setTimeout(() => {
      if (this.Sitelocation == true) {
        this.isdisabled = true;
        this.site.latitude = this.assetLat
        this.site.longitude = this.assetLong
        var tempLat;
        var tempLong;

        if (this.assetCount > 0) {
          // console.log(this.assetLat && this.assetLong)
          if (Math.abs(this.assetLat) && Math.abs(this.assetLong)) {
            tempLat = this.assetLat;
            tempLong = this.assetLong;
          } else {
            tempLat = this.site.latitude
            tempLong = this.site.longitude;
          }
          this.getAssetLatLongOnDrop(tempLat, tempLong);
        }

      } else {
        this.isdisabled = true;
      }
    }, 100);

    // console.log(this.assetLat , this.assetLong)

  }

  /* Drop Asset  */
  onItemDrop(e: any) {

    this.unsavedChanges = true;
    console.log(e)

    if (e.hasOwnProperty('dragData')) {
      var assetData = e.dragData;
    } else {
      var assetData = e;
    }

    this.assetCount = (this.assetCount + 1)

    if (this.isAssetAdd == false) {
      this.assetCount = 1
      this.isAssetAdd = true;

      if (this.Sitelocation == true) {
        this.getAssetLatLongOnDrop(assetData.latitude, assetData.longitude);
      }
    }

    // /console.log("asset == > "+ JSON.stringify(assetData));
    var index = this.assetList.findIndex(x => x.assetId == assetData.assetId);

    this.assetLat = assetData.latitude;
    this.assetLong = assetData.longitude;

    if (this.assetDataRecord.length == 0 && this.Sitelocation == true) {
      this.site.latitude = assetData.latitude;
      this.site.longitude = assetData.longitude;
      this.site.country.countryId = assetData.countryId != null ? assetData.countryId : "";
      if (assetData.countryId != null) { this.getStateListByCountryId(assetData.countryId); }
      this.site.state.stateId = assetData.stateId != null ? assetData.stateId : "";
      this.site.city = assetData.city != null ? assetData.city : "";
      this.latLongEnable = true;
    }


    this.assetList.splice(index, 1);
    this.assetDataRecord.push(assetData);

    this.assetArray.push({ "assetId": assetData.assetId });
    this.totalSiteAssets = this.totalSiteAssets + 1;
    this.totalAssets = this.totalAssets - 1;
    this.getAssetLatLongOnDrop(null, null);

  }

  /* Remove Asset  */
  removeAsset(assetDataR, index) {
    this.assetCount = (this.assetCount - 1)

    if (this.Sitelocation == true && (this.assetCount == 0 || this.assetCount == -1)) {
      this.isAssetAdd = false;
      //this.getAssetLatLongOnDrop(assetDataR.latitude , assetDataR.longitude)
    }
    this.unsavedChanges = true;
    this.assetDataRecord.splice(index, 1);
    this.assetArray.splice(index, 1);
    this.assetList.push(assetDataR)
    this.totalSiteAssets = this.totalSiteAssets - 1;
    this.totalAssets = this.totalAssets + 1;

    if (this.assetDataRecord.length == 0) {
      this.site.city = "";
      this.latLongEnable = false;
      this.site.latitude = "";
      this.site.longitude = "";
      this.site.country.countryId = "";
      this.site.state.stateId = "";
      this.Sitelocation = false
    }
    this.getAssetLatLongOnDrop(null, null)
  }


  /****************************************************
    API Call
    to Get Country and State from Lat Long
  *****************************************************/

  getAssetLatLongOnDrop(lat, long) {

    console.log(this.assetDataRecord);
    setTimeout(() => {
      let id = ''
      this.assetDataRecord.map((obj, index) => {
        if (index == 0) {
          id += obj.assetId
        }
        if (index > 0) {
          id += ',' + obj.assetId
        }

      })
      // console.log(id);
      if (id == '') {
        id = "0"
      }
      var URL = 'asset/getUpdateSiteByAsset?assetIds=' + id// + lat + '&longitude=' + long;
      this.apiService.getAll(URL).subscribe(
        data => {
          if (data.statusCode == 100) {

            if (data.response.countryId == null) {
              this.isCountryId = true;
            }
            this.site.latitude = data.response.latitude;
            this.site.longitude = data.response.longitude
            this.site.city = data.response.city;
            this.site.zipCode = data.response.zipCode;
            this.site.county = data.response.county;
            this.site.country.countryName = data.response.countryName;
            this.site.country.countryId = data.response.country ? data.response.country.countryId : '';
            this.site.address = data.response.address ? data.response.address : '';
            this.site.state.stateId = data.response.state ? data.response.state.stateId : '';
            if (data.response.country)
              this.getStateListByCountryId(data.response.country.countryId)
          }
          else { this.alertService.sweetMessage('error', 'No records found'); }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }, 1000);




    // if(lat && long){
    // var URL = 'asset/getAddressByLongLat?latitude=' + lat + '&longitude=' + long;
    // var URL = 'asset/getUpdateSiteByAsset?assetIds=21268' + lat + '&longitude=' + long;
    // this.apiService.getAll(URL).subscribe(
    //   data => {
    //     if (data.statusCode == 100) {

    //         if(data.response.countryId == null){
    //           this.isCountryId = true;
    //         }
    //         this.site.latitude  = lat;
    //         this.site.longitude = long
    //         this.site.city = data.response.city;
    //         this.site.country.countryName = data.response.countryName;
    //         this.site.country.countryId = data.response.countryId;
    //         this.site.state.stateId = data.response.stateId;
    //         this.getStateListByCountryId(data.response.countryId)
    //     }
    //     else
    //       { this.alertService.sweetMessage('error', 'No records found'); }
    //   },
    //  error =>{
    //            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    //          }
    // );

    // }

  }

  /* Show tier label in dropdown on get by IdApi */
  showLableInTierOnLoad() {
    if (this.site.tier) {
      this.tierId = this.site.tier.tierId;
      let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + this.site.tier.tierName + '</div>';
      this.myDropDownButton.setContent(dropDownContent);
    } else {
      let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
      this.myDropDownButton.setContent(dropDownContent);
    }

  }

  /* Get assets list */
  getAssetList() {
    var URL = 'asset/getUnassignedSiteAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId;
    if (this.customerId != null && this.customerId != '') {
      var customerString = '&customerId=' + this.customerId.toString();
      var URL = URL.concat(customerString);
    }
    if (this.tiersId != null && this.tiersId != '') {
      var tierString = '&tierId=' + this.tiersId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetList = data.responseList;
          this.totalAssets = this.assetList.length;
        } else {
          this.assetList = [];
          /*Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })*/
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }



  /* Add and update Site */
  SubmitSite(formValue) {
    // var siteName = $('#siteName').val();
    // if (this.tierId.toString() == '' && siteName == '') {
    //   $('#siteName_err').css('display', 'block');
    //   $('#tier_err').css('display', 'block');
    //   return;
    // } else {
    //   $('#siteName_err').css('display', 'none');
    //   $('#tier_err').css('display', 'none');
    // }

    // if (siteName == '') {
    //   $('#siteName_err').css('display', 'block');
    //   return;
    // } else {
    //   $('#siteName_err').css('display', 'none');
    // }

    if (this.tierId.toString() == '') {
      $('#tier_err').css('display', 'block');
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
      return;
    } else {
      $('#tier_err').css('display', 'none');
    }

    this.unsavedChanges = false;
    if (!formValue.invalid) {

      this.site.isAutoFetchAssetLocation = this.Sitelocation;
      this.site.tier = { "tierId": this.tierId };
      this.site.assets = this.assetArray;

      // this.apiParamData = this.site;
      this.site.siteId = this.siteId;
      // Deep copy
      // var newObject = jQuery.extend(true, {}, this.site);
      var newObject = JSON.parse(JSON.stringify(this.site));

      if (!this.Sitelocation) {
        if (!this.site.country.countryId) {
          this.ErroObj.countryErr = 'Please Select Country';
        } else {
          this.ErroObj.countryErr = null;
        }

        // console.log(this.site.state.stateId ,!this.site.state.stateId,"counrty" , this.site.country.countryId)
        if (!this.site.state.stateId) {
          this.ErroObj.stateErr = 'Please Select State';
        } else {
          this.ErroObj.stateErr = null;
        }

        if (!this.site.country.countryId || !this.site.state.stateId) {
          return;
        }
      }

      if (!this.site.country.countryId) {
        newObject.country = null;
        this.ErroObj.countryErr = null;
      }

      if (!this.site.state.stateId) {
        newObject.state = null;
        this.ErroObj.stateErr = null;
      }

      if (!this.site.customer.customerId) {
        newObject.customer = null;
      }

      if (this.tierId != 0) {
        if (this.siteId == 0) {
          this.apiService.create('site/registerSite', newObject).subscribe(
            data => {
              if (data.statusCode == 100) {
                this.site.country = { "countryId": "", "countryName": "" }
                this.site.state = { "stateId": 0 }
                this.site.customer = { "customerId": "" }
                this.alertService.sweetMessage('success', 'Site added Successfully');
                this.router.navigate(['/site-management']);
              } else if (data.statusCode == 101) {
                // this.isSiteErrorMsg = data.responseMessage;
                this.alertService.sweetMessage('error', data.responseMessage);
              }else if(data.statusCode == 103) {
                this.alertService.sweetMessage('error',data.responseMessage);
              }else {
                this.site.country = { "countryId": "", "countryName": "" }
                this.site.state = { "stateId": 0 }
                this.site.customer = { "customerId": "" }
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        } else {
          this.apiService.update('site/updateSite', newObject).subscribe(
            data => {
              // console.clear();
              if (data.statusCode == 100) {
                this.alertService.sweetMessage('success', 'Site Updated Successfully');
                this.router.navigate(['/site-management']);
              } else { this.alertService.sweetMessage('error', data.responseMessage); }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        }
      }
      else {
        this.alertService.sweetMessage('error', 'Please Select Tier');
      }
    }else {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
    }
    
    // else {
    //   console.log("site error");
    // //    this.alertService.sweetMessage('error', 'Please fill in all the required fields'); 
    // }
  }

  /* Get Site by Id*/
  getSiteById() {
    this.apiService.getAll('site/getSiteDetailById?siteId=' + this.siteId).subscribe(
      data => {
        if (data.statusCode == 100) {

          this.site = {
            "siteName": data.response.siteName || "",
            "city": data.response.city || "",
            "address": data.response.address || "",
            "county": data.response.county || "",
            "isActive": 1,
            "zipCode": data.response.zipCode || "",
            "longitude": data.response.longitude || 0.0,
            "latitude": data.response.latitude || 0.0,
            "client": data.response.client || { "clientId": this.clientId },
            "customer": data.response.customer || { "customerId": "" },
            "country": data.response.country || { "countryId": "", "countryName": "" },
            "state": data.response.state || { "stateId": 0 },
            "isAutoFetchAssetLocation": data.response.isAutoFetchAssetLocation || true,
            "tier": data.response.tier || { "tierId": "" }
          };
          this.assetLat = JSON.parse(JSON.stringify(data.response.latitude))
          this.assetLong = JSON.parse(JSON.stringify(data.response.longitude))
          // this.site = data.response;

          if (data.response.latitude != "" && data.response.longitude != "") {
            this.latLongEnable = true;
          }
          if (data.response.country) {
            this.getStateListByCountryId(data.response.country.countryId);
          } else {
            this.site.country = { countryId: "" };
          }
          if (data.response.customer == null) {
            this.site.customer = {};
          }
          //alert(JSON.stringify(this.site));
          if (data.response.state == null) {
            this.site.state = { stateId: "" };
          } else {
            this.site.state.stateId = data.response.state.stateId;
          }
          this.Sitelocation = data.response.isAutoFetchAssetLocation;


          if (this.Sitelocation == false) {
            this.isdisabled = false;
          }
          else {
            this.isdisabled = true;
          }
          // console.log(this.Sitelocation)
          //  console.log("DATA ====>", JSON.stringify(data.response.assets));
          this.assetDataRecord = data.response.assets ? data.response.assets : [];
          //  console.log(this.assetDataRecord);
          this.totalSiteAssets = data.response.assets ? data.response.assets.length : [];
          // this.assetDataRecord = data.response.assets;
          //  console.log(this.assetDataRecord);
          // this.totalSiteAssets = data.response.assets.length;
          for (let assetsM of this.assetDataRecord) {
            this.assetArray.push({ "assetId": assetsM.assetId });
            var index = this.assetList.findIndex(x => x.assetId == assetsM.assetId);
            if (index != -1) {
              this.assetList.splice(index, 1);
            }
          }
          this.showLableInTierOnLoad();
        } else {
          // Swal({
          //   position: 'top-end',
          //   type: 'error',
          //   title: data.responseMessage,
          //   showConfirmButton: false,
          //   timer: 1500
          // })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // /* Get Role list */
  // getCustomerList() {
  //   var URL = 'customer/getCustomerByClient?clientId=' + this.clientId;
  //   if (this.customerId != null && this.customerId != '') {
  //     var customerString = '&customerId=' + this.customerId.toString();
  //     var URL = URL.concat(customerString);
  //   }
  //   if (this.tiersId != null && this.tiersId != '') {
  //     var tierString = '&tierId=' + this.tiersId.toString();
  //     var URL = URL.concat(tierString);
  //   }
  //   this.apiService.getAll(URL).subscribe(
  //     data => {
  //       if (data.statusCode == 100) {
  //         this.customerList = data.responseList;
  //       } else {
  //         this.customerList = [];
  //        /*Swal({
  //            position: 'top-end',
  //            type: 'error',
  //            title: data.responseMessage,
  //            showConfirmButton: false,
  //            timer: 1500
  //          })*/
  //       }
  //     },
  //    error =>{
  //       this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  //     }
  //   );
  // }

  /* Get tier list */
  getTierList() {
    this.apiService.getAll('tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.tierListData = data.responseList;
          this.data = this.tierListData
          this.source = {
            datatype: 'json',
            datafields: [
              { name: 'tierId' },
              { name: 'parentTierId' },
              { name: 'tierName' },
            ],
            id: 'tierId',
            localdata: this.data
          };
          this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
          Object.keys(this.data).forEach(key => {
            if (this.data[key]['flag'] == false) {
              this.disableTreeArray.push(this.data[key]['tierId']);
            }
          });
          var self = this;
          setTimeout(function () {
            self.treeItemDisable();
          }, 1000);
          let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
          this.myDropDownButton.setContent(dropDownContent);
        } else {
          let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> No Record Found</div>';
          this.myDropDownButton.setContent(dropDownContent);

        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  treeItemDisable() {
    Object.keys(this.disableTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.disableTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      this.myTree.disableItem(checkedItem);
    });
  }
  /* Get state list by country id */
  getStateListByCountryId(countryId) {
    if (countryId && countryId != "" && countryId != "null") {

      this.isCountryId = false;
      this.apiService.getAll('state/getStateList/' + countryId).subscribe(
        data => {
          if (data.statusCode == 100) {
            //this.site.state.stateId = '';
            this.stateList = data.responseList;
          } else {
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    } else {
      this.stateList = [];
    }
  }

  /* Get Country list */
  getCountryList() {
    this.apiService.getAll('country/getCountry').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.countryList = data.responseList;
        } else {
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


}
