import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, CommonService } from '../../../../../services/index';
declare var $: any;
import * as _ from "lodash";
declare var tinyMCE: any;
declare var jQuery: any;
declare var d3: any, moment: any;;
import Swal from 'sweetalert2'
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../../environments/environment"
import 'datatables.net';
import 'datatables.net-bs4';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Location } from '@angular/common';
@Component({
    selector: 'app-role-based-notification',
    templateUrl: './role-based-notification.component.html',
    styleUrls: ['./role-based-notification.component.css']
})
export class RoleBasedNotificationComponent implements OnInit {
    @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
    @ViewChild('myTree') myTree: jqxTreeComponent;
    @ViewChild('notificationForm') notificationForm: any;

    dropdownList: any = [];
    planId: number = 0;
    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    assetList: any = []
    assetDataRecord: any = [];
    tempAssetList: any = [];
    assetFilter: any = {};
    planAssetFilter: any = {};

    selectedItems: any = [];
    dropdownSettings: any = {};

    tierListData: any = [];
    disableTreeArray: any = [];
    source: any = {};
    dataAdapter: any = [];
    records: any = [];
    checkTreeArray: any = [];
    assetArray: any = [];
    CallType: any = "false";
    notificationEsce: any = "false";
    notifiPermissionObj: any = {}
    assetRoleList: any = [];
    assetRoleListNoti: any = [];
    notificationObj = {
        // "notificationPlanId": 1,
        "notificationPlanName": "",
        "callType": null,
        "escalateType": null,
        "callSchedule": null,
        "waitInterval": null,
        "assetRoles": [],
        "client": {
            "clientId": this.clientId
        },
        "tiers": [],
        "assets": [],
        'alarmEscalateType': null,
        'normalEscalateType': null,
        'warningEscalateType': null,
        'adminEscalateType': null
    }
    notificationArray: any = []
    notificationMethodArray = ["Email", "Email and SMS", "SMS", "User Choice"]

    notificationTypeArray = ["Alarms", "Admin", "Normals", "Warnings"]
    CallTypeOptions: any = [{ key: 0, value: "No call on this type of notification" }]
    showEscTime = false;
    count = 6;
    templateArea: any = '';
    document: any = document;
    isOpenCk: boolean = false;
    closeEditor: boolean = true;
    ctl: any;
    templateData: any = '';
    ckeditorObject: any;
    permissionData: any = [];
    startPos: any = '';
    endPos: any = '';
    template_html: string = "";
    selectCall: any;
    notiObt: any;
    clientCode: any;
    showAlarmEscSec: any = false
    showWarningEscSec: any = false
    showNormalEscSec: any = false
    showAdminEscSec: any = false

    // variables for error msg
    userRoleerror = false
    ErrorMessage = ''
    breadcrumbName = null
    breadcrumbId = null;
    titleTextHelp = `<b class="font-weight-bold">Admin Notifications</b> occur if there is an issue with AMCi<br>
Hardware that may effect the reliability of the product.<br>
<br>
Examples include:<br>
- Underactivity, which is usually caused by power loss or <br>
signal loss.<br>
- Overactivity, which is usually caused by loose external<br>
wiring or program errors on an Assets' end device.`
    breadcrumbObj: any;
    pathAssetId: any;
    pathCustID: any;
    pathcust_name: any;
    assetdropdownSettings = {
        singleSelection: false,
        idField: 'assetId',
        textField: 'assetName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
    };
    allassetList: any;
    //     `Admin Notifications occur if there is an issue with AMCi
    // Hardware that may effect the reliability of the product.

    // Examples include:
    // - Underactivity, which is usually caused by power loss or 
    // signal loss.
    // - Overactivity, which is usually caused by loose external
    // wiring or program errors on an Assets' end device.`

    constructor(private commonService: CommonService, private apiService: ApiService, private alertService: AlertService, private route: ActivatedRoute, private router: Router, private loading: LoadingBarService, private location: Location) { }


    ngOnInit() {
        console.log(this.titleTextHelp);

        this.route
        .queryParams
        .subscribe(params => {
            this.breadcrumbObj = params['source'];
            this.pathCustID = params['cus_id'];
            this.pathAssetId = params['aId'];
            this.pathcust_name = params['cust_name'];            
        })
        this.getAssetList();
        this.breadcrumbName = this.commonService.currentBreadcrumb;
        this.breadcrumbId = this.commonService.dashboardId;

        this.clientCode = this.alertService.getClientCode();
        // this.dropdownList = [
        //     { item_id: 1, item_text: 'Technician' },
        //     { item_id: 2, item_text: 'Manager' },
        //     { item_id: 3, item_text: 'Primary1' },
        //     { item_id: 4, item_text: 'Primary2' },
        // ];
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'assetRoleId',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.planId = params['id'];
            }
        });

        this.getTierList();
        this.getAssetRole();

        this.getPermission();
        this.getTemplate();
        tinyMCE.init({
            oninit: this.onReady,
            height: "480"
        });

        $('.cursor_check_disable input[type="checkbox"]').each(function () {
            $(this).parent().find('.form-check-inline').addClass("cursor_check");
        });

    }

    onReady() {
        // console.log(tinyMCE)
        tinyMCE.init({
            oninit: this.onReady,
            height: "480"
        });
        this.ckeditorObject = tinyMCE;
    }

    getAssetRole() {
        this.apiService.getAll('assetRole/getAllAssetRole').subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.assetRoleList = data.responseList;
                    this.dropdownList = data.responseList;

                    // this.dropdownList = [{
                    //     assetRoleId:1,
                    //     name:"User1"
                    // },
                    // {
                    //     assetRoleId:1,
                    //     name:"User2"
                    // },{
                    //     assetRoleId:1,
                    //     name:"user3"
                    // },
                    // {
                    //     assetRoleId:1,
                    //     name:"sdsss"
                    // }];

                    this.assetRoleListNoti = JSON.parse(JSON.stringify(this.dropdownList));

                } else {
                    this.alertService.sweetMessage('error', data.responseMessage);
                }
                // if (this.planId) {
                //     this.getNotificationPlanByID();
                // }
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    getPermission() {
        this.apiService.getAll('notification_plan/getNewNPPermissionByClient?clientId=' + this.clientId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.notifiPermissionObj = data.response

                    if (!this.notifiPermissionObj.isSMSActive) {
                        this.notificationMethodArray = ["Email"]
                    }

                } else {
                    this.alertService.sweetMessage('error', data.responseMessage);
                }
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );

    }

    AddRoleUser() {
        if (this.assetRoleListNoti.length) {

            let obj = {
                "assetRoleId": this.assetRoleListNoti[0].assetRoleId,
                "newEscalationLevels": [
                ]
            }

            let methodValue = this.notifiPermissionObj.isSMSActive ? null : 'Email'

            let arr = [
                {
                    "escalationLevelId": null,
                    "notificationType": "Alarms",
                    "isNotificationType": false,
                    "notificationMethod": methodValue,
                    "isAck": false,
                    "isCall": false,
                    "isEscalate": false,
                    "escalationNotificationType": methodValue,
                    "isEscalationCall": false
                },
                {
                    "escalationLevelId": null,
                    "notificationType": "Warnings",
                    "isNotificationType": false,
                    "notificationMethod": methodValue,
                    "isAck": false,
                    "isCall": false,
                    "isEscalate": false,
                    "escalationNotificationType": methodValue,
                    "isEscalationCall": false
                },
                {
                    "escalationLevelId": null,
                    "notificationType": "Normals",
                    "isNotificationType": false,
                    "notificationMethod": methodValue,
                    "isAck": false,
                    "isCall": false,
                    "isEscalate": false,
                    "escalationNotificationType": methodValue,
                    "isEscalationCall": false
                },
                {
                    "escalationLevelId": null,
                    "notificationType": "Admin",
                    "isNotificationType": false,
                    "notificationMethod": methodValue,
                    "isAck": false,
                    "isCall": false,
                    "isEscalate": false,
                    "escalationNotificationType": methodValue,
                    "isEscalationCall": false
                }
            ]
            obj.newEscalationLevels = arr;
            this.notificationArray.push(obj)
            this.assetRoleListNoti.splice(0, 1)
        }
        $('.cursor_check_disable input[type="checkbox"]').each(function () {
            $(this).parent().find('.form-check-inline').addClass("cursor_check");
        });
    }

    getRoleName(assetRoleId) {
        let value = this.assetRoleList.find((val) => {
            if (val.assetRoleId == assetRoleId) {
                return val
            }
            // console.log(val)
        })
        return value ? value.name : "-"
    }

    /* Get tier list */
    getTierList() {
        this.apiService.getAll('tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId).subscribe(
            (data: any) => {
                if (data.statusCode == 100) {
                    this.tierListData = data.responseList;
                    let dataRes = this.tierListData;
                    Object.keys(dataRes).forEach(key => {
                        if (dataRes[key]['flag'] == false) {
                            this.disableTreeArray.push(dataRes[key]['tierId']);
                        }
                    });
                    this.source = {
                        datatype: 'json',
                        datafields: [{
                            name: 'tierId'
                        },
                        {
                            name: 'parentTierId'
                        },
                        {
                            name: 'tierName'
                        },
                        ],
                        id: 'tierId',
                        localdata: dataRes
                    };
                    this.dataAdapter = new jqx.dataAdapter(this.source, {
                        autoBind: true
                    });
                    this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{
                        name: 'tierName',
                        map: 'label'
                    }, {
                        name: 'tierId',
                        map: 'id'
                    }]);
                    var self = this;
                    setTimeout(function () {
                        self.treeItemDisable();
                    }, 2000);
                    if (this.planId) {
                        this.getNotificationPlanByID();
                    }
                } else {
                    this.alertService.sweetMessage('error', data.responseMessage);
                }
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    /* Handle tier tree chacked unchecked Event */
    CheckChange(event) {
        // console.log(event.args.element.id,event.args,this.checkTreeArray)
        if (event.args.checked == true) {
            var exist = this.checkTreeArray.indexOf(Number(event.args.element.id));
            if (exist == '-1') {
                this.checkTreeArray.push(Number(event.args.element.id));
                // this.tempIdArr.push(event.args.element.id)

                //Call getAssetList() to remove multiple api call when checkChange function called
                setTimeout(() => {
                    this.getAssetList();
                }, 2000);
            }
            // setTimeout(() => {
            //     this.getAssetList();
            // }, 5000);
        } else if (event.args.checked == false) {
            var exist = this.checkTreeArray.indexOf(Number(event.args.element.id));
            if (exist != '-1') {
                this.checkTreeArray.splice(exist, 1);
                //Call getAssetList() to remove multiple api call when checkChange function called
                setTimeout(() => {
                    this.getAssetList();
                }, 2000);

                // let arr = [];
                // // console.log(event.args.element.id,this.tempAssetList)
                // this.tempAssetList.map((obj, index) => {
                //     let indexid = this.checkTreeArray.indexOf(event.args.element.id)
                //     // console.log(indexid,event.args.element.id,this.checkTreeArray)
                //     if (indexid == -1) {
                //         if (obj.site.tier && obj.site.tier.tierId != event.args.element.id) {
                //             arr.push(obj)
                //         }
                //     }
                // })

                // // console.log(arr)
                // this.tempAssetList = arr;
                // this.assetDataRecord = arr;
                // setTimeout(() => {
                //     this.getAssetList();
                // }, 5000);
            }
        } else if (event.args.checked == null) {
            var exist = this.checkTreeArray.indexOf(Number(event.args.element.id));
            if (exist != '-1') {
                this.checkTreeArray.splice(exist, 1);
                //Call getAssetList() to remove multiple api call when checkChange function called
                setTimeout(() => {
                    this.getAssetList();
                }, 2000);

                // let arr = [];
                // this.tempAssetList.map((obj, index) => {
                //     let indexid = this.checkTreeArray.indexOf(event.args.element.id)
                //     // console.log(indexid,event.args.element.id,this.checkTreeArray)
                //     if (indexid == -1) {
                //         if (obj.site.tier && obj.site.tier.tierId != event.args.element.id) {
                //             arr.push(obj)
                //         }
                //     }
                // })

                // this.tempAssetList = arr;
                // this.assetDataRecord = arr;
                // setTimeout(() => {
                //     this.getAssetList();
                // }, 5000);

            }
        }
    }

    getAssetListCall() {
        setTimeout(() => {
            this.getAssetList();
        }, 2000);
    }

    ChangeCall($event, i, j) {
        // console.log(this.notificationArray[i].newEscalationLevels[j], this.notificationArray[i].newEscalationLevels[j].isEscalate, this.notificationArray[i].newEscalationLevels[j].isEscalationCall)
        let objData = this.notificationArray[i].newEscalationLevels[j]
        objData.isEscalate = false;
        // objData.isEscalationCall = false;
        // objData.escalationNotificationType = null;
    }

    ChangeCallAck(event, i, j) {
        let objD = this.notificationArray[i].newEscalationLevels[j]
        objD.escalationNotificationType = null;
        objD.isEscalationCall = false;
    }


    isEscelateChange(i, j) {
        // this.notificationArray.map((roleObj,roleIndex)=>{
        //     roleObj.newEscalationLevels.map((obj,index)=>{
        // console.log(this.notificationArray, i,j)
        this.notificationArray[i].newEscalationLevels[j].escalationNotificationType = null;
        //     })
        // })
    }

    getAssetList() {
        let str = "&tierId=0"
        if (this.checkTreeArray.length) {
            str = '&tierId=' + this.checkTreeArray.toString()
        }
        if (this.planId) {
            this.apiService.getAll('asset/getUnassignedNPAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId + str).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.assetList = data.responseList;
                        this.allassetList = JSON.parse(JSON.stringify(data.responseList));
                    } else {
                        this.assetList = [];
                    }

                    let arr = []
                    let arr1 = []
                    this.assetDataRecord.map((obj, index) => {
                        let val = _.find(this.assetList, { assetId: obj.assetId })
                        if (val) {
                            arr.push(val);
                        }
                    })

                    this.tempAssetList.map((obj, index) => {
                        let val = _.find(this.assetList, { assetId: obj.assetId })
                        if (val) {
                            arr1.push(val);
                        }
                    })

                    let assetArr = []
                    this.assetList.map((obj, index) => {
                        let val = _.find(arr, { assetId: obj.assetId })
                        if (!val) {
                            assetArr.push(obj);
                        }
                    })
                    // this.assetDataRecord = arr;
                    arr1 = arr.concat(this.tempAssetList);
                    let ids = [], arryObj = []
                    arr1.map((val, index) => {
                        if (ids.indexOf(val.assetId) == -1) {
                            arryObj.push(val);
                            ids.push(val.assetId)
                        }
                    })
                    this.assetList = assetArr;
                    this.assetDataRecord = arryObj;
                    // this.totalAssets = this.assetList.length;

                    // this.totalNotificationAssets = this.assetDataRecord.length;
                },
                error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        } else {
            this.apiService.getAll('asset/getUnassignedNPAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId + str).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.assetList = data.responseList;
                        this.allassetList = JSON.parse(JSON.stringify(data.responseList));

                        // this.totalAssets = this.assetList.length;
                    } else {
                        this.assetList = [];
                        // this.totalAssets = this.assetList.length;
                    }

                    let arr = []
                    let arr1 = []
                    this.assetDataRecord.map((obj, index) => {
                        let val = _.find(this.assetList, { assetId: obj.assetId })
                        if (val) {
                            arr.push(val);
                        }
                    })

                    this.assetList.map((obj, index) => {
                        let val = _.find(arr, { assetId: obj.assetId })
                        if (!val) {
                            arr1.push(obj);
                        }
                    })

                    // this.notificationPlan.assets.map((obj,index)=>{
                    //     // console.log(obj.assetId)
                    //     let val = _.find(this.assetList, {assetId:obj.assetId})
                    //     if (val) {
                    //         arr1.push(val);
                    //     }
                    // })

                    this.assetDataRecord = arr;
                    this.assetList = arr1;
                    // this.totalAssets = this.assetList.length;
                    // this.totalNotificationAssets = this.assetDataRecord.length;
                },
                error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    }

    /* check tree checkbox */
    checkedCheckboxOnLoad() {
        // console.log("object", this.checkTreeArray)
        Object.keys(this.checkTreeArray).forEach(key => {
            // console.log(this.disableTreeArray, this.checkTreeArray, this.disableTreeArray.indexOf(key));
            if (this.disableTreeArray.indexOf(key) == -1) {
                let checkedItem = document.getElementById(this.checkTreeArray[key]);
                this.myTree.expandItem(checkedItem);
                this.myTree.checkItem(checkedItem, true);
            }
        });
    }

    treeItemDisable() {
        Object.keys(this.disableTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.disableTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.disableItem(checkedItem);
        });
    }

    /* Drop Asset  */
    onItemDrop(e: any) {
        // this.unsavedChanges = true;
        var assetData = e.dragData;
        var index = this.assetList.findIndex(x => x.assetId == assetData.assetId);
        this.assetList.splice(index, 1);
        this.assetDataRecord.push(assetData);
        this.tempAssetList.push(assetData);
        this.assetArray.push({
            "assetId": assetData.assetId
        });
        // this.totalNotificationAssets = this.totalNotificationAssets + 1;
        // this.totalAssets = this.totalAssets - 1;
    }

    /* / Remove Asset  / */
removeAsset(assetDataR, index) {
    Swal({
        title: 'Are you sure?',
        text: "You want to Remove this Asset.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Remove it!'
    }).then((result) => {
        if (result.value) {
            // this.unsavedChanges = true;
            var indexAssetDataRecord = this.assetDataRecord.findIndex(x => x.assetId == assetDataR.assetId);
            var indexAssetArray = this.assetArray.findIndex(x => x.assetId == assetDataR.assetId);
            var indexTempAssetList = this.tempAssetList.findIndex(x => x.assetId == assetDataR.assetId);

            if (indexAssetDataRecord != -1) { this.assetDataRecord.splice(indexAssetDataRecord, 1); }
            if (indexAssetArray != -1) { this.assetArray.splice(indexAssetArray, 1); }
            if (indexTempAssetList != -1) { this.tempAssetList.splice(indexAssetArray, 1); }
            this.assetList.push(assetDataR)

        }
    })


    // this.totalNotificationAssets = this.totalNotificationAssets - 1;
    // this.totalAssets = this.totalAssets + 1;
}

changeEscData(event) {
    if (this.notificationEsce == true || this.notificationEsce == 'true') {
        this.showEscTime = true;
        if (this.CallType == 'true' || this.CallType == true) {
            let obj2 = this.CallTypeOptions.find(x => x.key === 2);
            if (!obj2) {
                this.CallTypeOptions.push({ key: 2, value: "Call after escalation" });
            }
            // this.CallTypeOptions.push({key:2,value:"Call after escalation"});
        }
        //   this.count += 1;

        this.notificationObj.waitInterval = 5
    } else {

        let index = null;
        for (let i = 0; i < this.CallTypeOptions.length; i++) {
            const element = this.CallTypeOptions[i];
            if (element.key == 2) {
                index = i;
                break;
            }
        }
        if (index != null) {
            this.CallTypeOptions.splice(index, 1)
        }
        this.showEscTime = false;
        this.notificationObj.adminEscalateType = 0;
        this.notificationObj.alarmEscalateType = 0;
        this.notificationObj.normalEscalateType = 0;
        this.notificationObj.warningEscalateType = 0;
        //    this.count -= 1;

        this.notificationObj.waitInterval = null
    }
    this.notificationArray.map((roleObj, roleIndex) => {
        roleObj.newEscalationLevels.map((obj, index) => {
            this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = null;
            // this.notificationArray[roleIndex].newEscalationLevels[index].isAck = true;
            this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
        })
    })

}

ChangeCallType(event){
    // console.log(event,this.CallType)
    if (this.CallType == 'false') {
        let index = null;
        // for (let i = 0; i < this.CallTypeOptions.length; i++) {
        //     const element = this.CallTypeOptions[i];
        //     if(element.key == 1){
        //         index = i;
        //         break;
        //     }

        // }
        this.notificationObj.callSchedule = null;
        this.notificationObj.callType = null;
        // console.log(this.CallTypeOptions)
        if (this.notificationEsce == true || this.notificationEsce == 'true') {
            this.CallTypeOptions.splice(2, 1)
        }
        this.CallTypeOptions.splice(1, 1)

        this.notificationObj.adminEscalateType = 0;
        this.notificationObj.alarmEscalateType = 0;
        this.notificationObj.normalEscalateType = 0;
        this.notificationObj.warningEscalateType = 0;

        // console.log(this.CallTypeOptions)
        // this.notificationArray.map((roleObj,roleIndex)=>{
        //     roleObj.newEscalationLevels.map((obj,index)=>{
        //         this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
        //         this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = false;
        //         // this.notificationArray[roleIndex].newEscalationLevels[index].isAck = false;}

        //    });
        // })
        //   this.count -= 1;
    }
    if (this.CallType == 'true') {
        //    this.count += 1;
        let obj = this.CallTypeOptions.find(x => x.key === 1);
        if (!obj) {
            // this.CallTypeOptions.push({key:1,value:"Call when event generated"})
        }


        if (this.notificationEsce == true || this.notificationEsce == 'true') {
            let obj2 = this.CallTypeOptions.find(x => x.key === 2);
            if (!obj2) {
                this.CallTypeOptions.push({ key: 2, value: "Call after escalation" });
            }
        }
    }
    // this.notificationArray.map((roleObj,roleIndex)=>{
    //     roleObj.newEscalationLevels.map((obj,index)=>{
    //         // if(obj.notificationType == 'Admin'){
    //         // this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;

    //     })
    // })
}

trackByFn(index, item) {
    // console.log(index,item)
    return index; // or item.id
}


changeWarningAck(event) {
    // console.log("WarningAck");
    if (event.target.checked) {
        let showEscwarning = false;
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Warnings') {
                    if (this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate == true && this.notificationArray[roleIndex].newEscalationLevels[index].isAck == true) {
                        showEscwarning = true;
                    }
                }
            })
        })
        this.showWarningEscSec = showEscwarning;
        setTimeout(() => {

            let dataObj = JSON.parse(JSON.stringify(this.notificationArray))

            if (showEscwarning) {
                this.notificationArray.map((roleObj, roleIndex11) => {
                    roleObj.newEscalationLevels.map((obj, index11) => {
                        if (obj.notificationType == 'Warnings') {
                            // console.log("change 111222");
                            this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true
                            // console.log(this.notificationArray);

                        }
                    })
                })
            } else {
                // this.notificationArray.map((roleObj,roleIndex11)=>{
                //     roleObj.newEscalationLevels.map((obj,index11)=>{
                //         if(obj.notificationType == 'Alarms'){
                //             console.log("change 111222");
                //             this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true 
                //             console.log(this.notificationArray);

                //         }
                //     })
                // })
            }
        }, 1000)
    }
}

changeAdminAck(event) {
    // console.log("changeAdminAck");
    if (event.target.checked) {

        let showEscAdmin = false;
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Admin') {
                    if (this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate == true && this.notificationArray[roleIndex].newEscalationLevels[index].isAck == true) {
                        showEscAdmin = true;
                    }
                }
            })
        })
        this.showAdminEscSec = showEscAdmin;
        setTimeout(() => {

            let dataObj = JSON.parse(JSON.stringify(this.notificationArray))

            if (showEscAdmin) {
                this.notificationArray.map((roleObj, roleIndex11) => {
                    roleObj.newEscalationLevels.map((obj, index11) => {
                        if (obj.notificationType == 'Admin') {
                            // console.log("change 111222");
                            this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true
                            // console.log(this.notificationArray);

                        }
                    })
                })
            } else {
                // this.notificationArray.map((roleObj,roleIndex11)=>{
                //     roleObj.newEscalationLevels.map((obj,index11)=>{
                //         if(obj.notificationType == 'Alarms'){
                //             console.log("change 111222");
                //             this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true 
                //             console.log(this.notificationArray);

                //         }
                //     })
                // })
            }
        }, 1000)
    }

}

changeNormalAck(event) {
    // console.log("changeNormalAck");
    if (event.target.checked) {
        let showEscNormal = false;
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Normals') {
                    if (this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate == true && this.notificationArray[roleIndex].newEscalationLevels[index].isAck == true) {
                        showEscNormal = true;
                    }
                }
            })
        })
        this.showNormalEscSec = showEscNormal;
        setTimeout(() => {

            let dataObj = JSON.parse(JSON.stringify(this.notificationArray))

            if (showEscNormal) {
                this.notificationArray.map((roleObj, roleIndex11) => {
                    roleObj.newEscalationLevels.map((obj, index11) => {
                        if (obj.notificationType == 'Normals') {
                            // console.log("change 111222");
                            this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true
                            // console.log(this.notificationArray);

                        }
                    })
                })
            } else {
                // this.notificationArray.map((roleObj,roleIndex11)=>{
                //     roleObj.newEscalationLevels.map((obj,index11)=>{
                //         if(obj.notificationType == 'Alarms'){
                //             console.log("change 111222");
                //             this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true 
                //             console.log(this.notificationArray);

                //         }
                //     })
                // })
            }
        }, 1000);
    }
}

changeAlarmAck(event) {
    // console.log("changeAlarmAck",this.notificationArray);
    if (event.target.checked) {
        let showEscAlarm = false;
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Alarms') {
                    // console.log(this.notificationArray[roleIndex].newEscalationLevels[index]);

                    if (this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate == true && this.notificationArray[roleIndex].newEscalationLevels[index].isAck == true) {
                        // console.log("change 111");

                        showEscAlarm = true;
                        // console.log('channge alarm',showEscAlarm);
                    }
                }
            })
        })
        this.showAlarmEscSec = showEscAlarm;
        setTimeout(() => {

            let dataObj = JSON.parse(JSON.stringify(this.notificationArray))

            if (showEscAlarm) {
                this.notificationArray.map((roleObj, roleIndex11) => {
                    roleObj.newEscalationLevels.map((obj, index11) => {
                        if (obj.notificationType == 'Alarms') {
                            // console.log("change 111222");
                            this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true
                            // console.log(this.notificationArray);

                        }
                    })
                })
            } else {
                // this.notificationArray.map((roleObj,roleIndex11)=>{
                //     roleObj.newEscalationLevels.map((obj,index11)=>{
                //         if(obj.notificationType == 'Alarms'){
                //             console.log("change 111222");
                //             this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true 
                //             console.log(this.notificationArray);

                //         }
                //     })
                // })
            }
        }, 1000);
        // console.log(this.notificationArray);

    } else {

    }

}

onItemSelect(event){
    let roleNotificationObject = null;
    if (this.notificationObj.assetRoles.length > 0) {
        this.notificationObj.assetRoles.map((notifObj, index) => {
            if(notifObj.assetRoleId==event.assetRoleId){
                roleNotificationObject = notifObj;
            }
        });
    }
    let obj = {
        "assetRoleId": event.assetRoleId,
        "newEscalationLevels": [
        ]
    }
    let methodValue = this.notifiPermissionObj.isSMSActive ? null : 'Email'
    let a = this.notificationObj.adminEscalateType;
    let b = this.notificationObj.normalEscalateType;
    let c = this.notificationObj.alarmEscalateType;
    let d = this.notificationObj.warningEscalateType;
    let arr = [
        {
            "escalationLevelId": (roleNotificationObject==null?null:roleNotificationObject.newEscalationLevels[0].escalationLevelId),
            "notificationType": "Alarms",
            "isNotificationType": false,
            "notificationMethod": methodValue,
            "isAck": c == '2' ? true : false,
            // "isCall": false,
            "isEscalate": c == '2' ? true : false,
            "escalationNotificationType": methodValue,
            // "isEscalationCall": false
        },
        {
            "escalationLevelId": (roleNotificationObject==null?null:roleNotificationObject.newEscalationLevels[1].escalationLevelId),
            "notificationType": "Warnings",
            "isNotificationType": false,
            "notificationMethod": methodValue,
            "isAck": d == '2' ? true : false,
            // "isCall": false,
            "isEscalate": d == '2' ? true : false,
            "escalationNotificationType": methodValue,
            // "isEscalationCall": false
        },
        {
            "escalationLevelId": (roleNotificationObject==null?null:roleNotificationObject.newEscalationLevels[2].escalationLevelId),
            "notificationType": "Normals",
            "isNotificationType": false,
            "notificationMethod": methodValue,
            "isAck": b == '2' ? true : false,
            // "isCall": false,
            "isEscalate": b == '2' ? true : false,
            "escalationNotificationType": methodValue,
            // "isEscalationCall": false
        },
        {
            "escalationLevelId": (roleNotificationObject==null?null:roleNotificationObject.newEscalationLevels[3].escalationLevelId),
            "notificationType": "Admin",
            "isNotificationType": false,
            "notificationMethod": methodValue,
            "isAck": a == '2' ? true : false,
            // "isCall": false,
            "isEscalate": a == '2' ? true : false,
            "escalationNotificationType": methodValue,
            // "isEscalationCall": false
        }
    ]
    obj.newEscalationLevels = arr;
    this.notificationArray.push(obj)
    // this.assetRoleListNoti.splice(0, 1)
    // }
    if (this.notificationArray.length == 0) {
        this.userRoleerror = true
        return
    } else {
        this.userRoleerror = false
    }

}
onItemDeselect(event){
    let index = null;
    this.notificationArray.map((obj, indexObj) => {
        if (obj.assetRoleId == event.assetRoleId) {
            index = indexObj;
        }
    })
    if (index != null) {
        this.notificationArray.splice(index, 1)
    }
    if (this.notificationArray.length == 0) {
        this.userRoleerror = true
        return
    } else {
        this.userRoleerror = false
    }
}
onSelectAll(event){

    this.dropdownList.map((obj, index) => {

        let findObj = _.find(this.selectedItems, { assetRoleId: obj.assetRoleId });
        let roleNotificationObject = null;
        if (this.notificationObj.assetRoles.length > 0) {
            this.notificationObj.assetRoles.map((notifObj, index) => {
                if(notifObj.assetRoleId==obj.assetRoleId){
                    roleNotificationObject = notifObj;
                }
            });
        }
        if (!findObj) {
            let obj2 = {
                "assetRoleId": obj.assetRoleId,
                "newEscalationLevels": [
                ]
            }

            let methodValue = this.notifiPermissionObj.isSMSActive ? null : 'Email'

            let arr = [
                {
                    "escalationLevelId": (roleNotificationObject==null?null:roleNotificationObject.newEscalationLevels[0].escalationLevelId),
                    "notificationType": "Alarms",
                    "isNotificationType": false,
                    "notificationMethod": methodValue,
                    "isAck": false,
                    "isEscalate": false,
                    "escalationNotificationType": methodValue
                },
                {
                    "escalationLevelId": (roleNotificationObject==null?null:roleNotificationObject.newEscalationLevels[1].escalationLevelId),
                    "notificationType": "Warnings",
                    "isNotificationType": false,
                    "notificationMethod": methodValue,
                    "isAck": false,
                    "isEscalate": false,
                    "escalationNotificationType": methodValue
                },
                {
                    "escalationLevelId": (roleNotificationObject==null?null:roleNotificationObject.newEscalationLevels[2].escalationLevelId),
                    "notificationType": "Normals",
                    "isNotificationType": false,
                    "notificationMethod": methodValue,
                    "isAck": false,
                    "isEscalate": false,
                    "escalationNotificationType": methodValue
                },
                {
                    "escalationLevelId": (roleNotificationObject==null?null:roleNotificationObject.newEscalationLevels[3].escalationLevelId),
                    "notificationType": "Admin",
                    "isNotificationType": false,
                    "notificationMethod": methodValue,
                    "isAck": false,
                    "isEscalate": false,
                    "escalationNotificationType": methodValue
                }
            ]
            obj2.newEscalationLevels = arr;
            this.notificationArray.push(obj2)
        }

    })
    if (this.notificationArray.length == 0) {
        this.userRoleerror = true
        return
    } else {
        this.userRoleerror = false
    }

}
onDeSelectAll(){
    this.notificationArray = [];
    if (this.notificationArray.length == 0) {
        this.userRoleerror = true
        return
    } else {
        this.userRoleerror = false
    }
}

getNotificationPlanByID() {

    this.apiService.getAll('notification_plan/getNewNotificationPlanById?notificationId=' + this.planId).subscribe(
        data => {
            // this.getAssetList();
            if (data.statusCode == 100) {
                this.notificationObj = data.response
                let notificationPlan = JSON.parse(JSON.stringify(data.response))

                if (notificationPlan.adminEscalateType >= 1 || notificationPlan.normalEscalateType >= 1 || notificationPlan.alarmEscalateType >= 1 || notificationPlan.warningEscalateType >= 1) {
                    this.CallTypeOptions.push({ key: 2, value: "Call after escalation" });
                    // this.CallTypeOptions.push({key:1,value:"Call when event generated"});
                    this.showEscTime = true;
                }
                // if(notificationPlan.adminEscalateType == 1 || notificationPlan.normalEscalateType == 1 || notificationPlan.alarmEscalateType == 1 || notificationPlan.warningEscalateType == 1){
                //     this.CallTypeOptions.push({key:1,value:"Call when event generated"});
                //     this.showEscTime = true;
                // }
                if (notificationPlan.waitInterval) {
                    this.notificationEsce = "true";
                    this.showEscTime = true;

                } else {
                    this.notificationEsce = "false";
                    this.showEscTime = false;
                }
                if (notificationPlan.waitInterval && (notificationPlan.callSchedule || notificationPlan.callType)) {
                    this.notificationEsce = "true";

                    let obj = this.CallTypeOptions.find(x => x.key === 1);
                    let obj2 = this.CallTypeOptions.find(x => x.key === 2);
                    // console.log(obj,obj2)
                    if (!obj) {
                        // this.CallTypeOptions.push({key:1,value:"Call when event generated"});        
                    }
                    if (!obj2) {
                        this.CallTypeOptions.push({ key: 2, value: "Call after escalation" });
                    }

                    this.showEscTime = true;
                    //  this.count++;
                } else {
                    // this.notificationEsce = "false";
                    // this.showEscTime = false;
                }

                if (notificationPlan.callSchedule || notificationPlan.callType) {
                    this.CallType = notificationPlan.callSchedule || notificationPlan.callType ? "true" : "false";
                    //    this.count ++;
                    let obj = this.CallTypeOptions.find(x => x.key === 1);
                    // console.log(obj)
                    let obj2 = this.CallTypeOptions.find(x => x.key === 2);

                    if (!obj) {
                        // this.CallTypeOptions.push({key:1,value:"Call when event generated"});
                    }
                    if (!obj2) {
                        this.CallTypeOptions.push({ key: 2, value: "Call after escalation" });
                    }
                }

                let assetNotiArray = [];
                let selectedItems = [];
                this.assetDataRecord = notificationPlan.assets

                if (notificationPlan.tiers && notificationPlan.tiers.length) {
                    notificationPlan.tiers.map((objTier, indexTier) => {
                        this.checkTreeArray.push(objTier.tierId)
                    })
                    this.loading.start();
                    //Call getAssetList() to remove multiple api call when checkChange function called
                    setTimeout(() => {
                        this.getAssetList();
                    }, 2000);
                    setTimeout(() => {
                        this.loading.stop();
                    }, 4000);
                }

                notificationPlan.assetRoles.map((obj, objIndex) => {
                    let notiObj = {
                        "assetRoleId": obj.assetRoleId,
                        "newEscalationLevels": [
                        ]
                    }

                    let findObj = this.dropdownList.find((object) => {
                        return object.assetRoleId == obj.assetRoleId
                    })
                    // console.log(findObj)
                    delete findObj.assetRoleBit
                    // delete findObj.name
                    selectedItems.push(findObj)

                    obj.newEscalationLevels.map((escObj, escIndex) => {
                        if (this.notificationTypeArray.indexOf(escObj.notificationType) != -1) {
                            let escLevelObj: any = escObj;
                            escLevelObj.isNotificationType = escObj.escalationLevelId ? true : false;
                            escLevelObj.notificationMethod = escObj.escalationLevelId ? escObj.notificationMethod : (this.notifiPermissionObj.isSMSActive ? "" : 'Email');
                            escLevelObj.escalationNotificationType = escObj.escalationLevelId ? escObj.escalationNotificationType : (this.notifiPermissionObj.isSMSActive ? "" : 'Email');
                            notiObj.newEscalationLevels.push(escLevelObj);


                            if (escObj.notificationType == 'Admin') {
                                if (!escObj.escalationLevelId) {
                                    if (notificationPlan.adminEscalateType == '2') {
                                        // console.log(notificationPlan.adminEscalateType)
                                        escObj.isAck = true;
                                        escObj.isEscalate = true;
                                    }
                                }
                                if (notificationPlan.assetRoles[objIndex].newEscalationLevels[escIndex].isEscalate == true && notificationPlan.assetRoles[objIndex].newEscalationLevels[escIndex].isAck == true) {
                                    // console.log("change 111");

                                    this.showAdminEscSec = true;
                                    // console.log('channge alarm',showEscAlarm);
                                }
                            }

                            if (escObj.notificationType == 'Normals') {
                                if (!escObj.escalationLevelId) {
                                    if (notificationPlan.normalEscalateType == '2') {
                                        // console.log(notificationPlan.normalEscalateType)
                                        escObj.isAck = true;
                                        escObj.isEscalate = true;
                                    }
                                }
                                if (notificationPlan.assetRoles[objIndex].newEscalationLevels[escIndex].isEscalate == true && notificationPlan.assetRoles[objIndex].newEscalationLevels[escIndex].isAck == true) {
                                    // console.log("change 111");

                                    this.showNormalEscSec = true;
                                    // console.log('channge alarm',showEscAlarm);
                                }
                            }

                            if (escObj.notificationType == 'Warnings') {
                                if (!escObj.escalationLevelId) {
                                    if (notificationPlan.warningEscalateType == '2') {
                                        escObj.isAck = true;
                                        // console.log(notificationPlan.warningEscalateType)
                                        escObj.isEscalate = true;
                                    }
                                }
                                if (notificationPlan.assetRoles[objIndex].newEscalationLevels[escIndex].isEscalate == true && notificationPlan.assetRoles[objIndex].newEscalationLevels[escIndex].isAck == true) {
                                    // console.log("change 111");

                                    this.showWarningEscSec = true;
                                    // console.log('channge alarm',showEscAlarm);
                                }
                            }

                            if (escObj.notificationType == 'Alarms') {
                                if (!escObj.escalationLevelId) {
                                    if (notificationPlan.alarmEscalateType == '2') {
                                        // console.log(notificationPlan.alarmEscalateType)
                                        escObj.isAck = true;
                                        escObj.isEscalate = true;
                                    }
                                }
                                if (notificationPlan.assetRoles[objIndex].newEscalationLevels[escIndex].isEscalate == true && notificationPlan.assetRoles[objIndex].newEscalationLevels[escIndex].isAck == true) {
                                    // console.log("change 111");

                                    this.showAlarmEscSec = true;
                                    // console.log('channge alarm',showEscAlarm);
                                }
                            }


                            // this.notificationArray.map((roleObj,roleIndex)=>{
                            //     roleObj.newEscalationLevels.map((obj,index)=>{
                            //         if(obj.notificationType == 'Alarms'){
                            //             // console.log(this.notificationArray[roleIndex].newEscalationLevels[index]);

                            //             if (this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate == true && this.notificationArray[roleIndex].newEscalationLevels[index].isAck == true) {
                            //                 console.log("change 111");

                            //                 showEscAlarm = true;
                            //                 // console.log('channge alarm',showEscAlarm);
                            //             }
                            //         }
                            //     })
                            // })

                            // if(escObj.notificationType == 'Admin' || escObj.notificationType == 'Normals' || escObj.notificationType == 'Warnings' || escObj.notificationType == 'Alarms'){
                            //     if(!escObj.escalationLevelId){
                            //         if(notificationPlan.adminEscalateType == '2'){
                            //             console.log(notificationPlan.adminEscalateType)
                            //             escObj.isAck = true;
                            //             escObj.isEscalate = true;
                            //         }
                            //         if(notificationPlan.normalEscalateType == '2'){
                            //             console.log(notificationPlan.normalEscalateType)
                            //             escObj.isAck = true;
                            //             escObj.isEscalate = true;
                            //         }
                            //         if(notificationPlan.warningEscalateType == '2'){
                            //             escObj.isAck = true;
                            //             console.log(notificationPlan.warningEscalateType)
                            //             escObj.isEscalate = true;
                            //         } 
                            //         if(notificationPlan.alarmEscalateType == '2'){
                            //             console.log(notificationPlan.alarmEscalateType)
                            //             escObj.isAck = true;
                            //             escObj.isEscalate = true;
                            //         }
                            //     }
                            // }
                        }
                    })
                    let AssetRoleindex = _.findIndex(this.assetRoleListNoti, function (obj2) { return obj.assetRoleId == obj2.assetRoleId })
                    if (AssetRoleindex > -1) {
                        this.assetRoleListNoti.splice(AssetRoleindex, 1)
                    }

                    assetNotiArray.push(notiObj)
                })

                this.selectedItems = selectedItems;
                // console.log(this.selectedItems)

                setTimeout(() => {
                    this.checkedCheckboxOnLoad();
                }, 2000);

                // console.log(assetNotiArray,this.assetRoleListNoti)
                this.notificationArray = assetNotiArray;

            } else {
                // this.getAssetList();
                // this.alertService.sweetMessage('error', data.responseMessage);
            }
        },
        error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );

}

saveNotificationPlan() {

    this.notificationForm.submitted = true;

    // let escselect = false;
    // if (this.notificationArray.length == 0 ) {
    //     escselect = true;
    //     return;    
    // }else{
    //     this.notificationArray.map((obj, index) => {
    //         if (obj.newEscalationLevels.length == 0) {

    //         } else {

    //         }
    //     })

    // }

    this.ErrorMessage = ''
    if (!this.notificationForm.valid) {
        document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' }); 
        if (this.notificationArray.length == 0) {
            this.userRoleerror = true
            return
        } else {
            this.userRoleerror = false
        }

        return;
    }
    if (this.notificationArray.length == 0) {
        document.getElementById("role-section").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' }); 
        this.userRoleerror = true
        return
    } else {
        this.userRoleerror = false
    }

    let tiers = [];
    this.checkTreeArray.map((val, index) => {
        tiers.push({ tierId: val })
    })



    let ArrayObject = []
    let indexArr = []
    let escLevel = JSON.parse(JSON.stringify(this.notificationArray))
    let isOneSelect = true;
    this.notificationArray.map((obj, index) => {

        if (obj.newEscalationLevels.length == 0) {
            // escLevel.splice(index,1)
            indexArr.push(index);
        } else {

        }

        obj.newEscalationLevels.map((escalationObj, escalationIndex) => {
            if (escalationObj.isNotificationType) {

                isOneSelect = false;
                escLevel[index].newEscalationLevels.map((RemoveKeyObj, RemoveKeyIndex) => {
                    if (RemoveKeyObj.notificationType == escalationObj.notificationType) {
                        delete escLevel[index].newEscalationLevels[RemoveKeyIndex].isNotificationType
                        // escLevel[index].newEscalationLevels.splice(RemoveKeyIndex,1);//  [escalationIndex].isNotificationType
                    }
                })

            } else {
                // console.log(escLevel[index].newEscalationLevels)
                escLevel[index].newEscalationLevels.map((RemoveObj, RemoveIndex) => {
                    if (RemoveObj.notificationType == escalationObj.notificationType) {
                        escLevel[index].newEscalationLevels.splice(RemoveIndex, 1);//  [escalationIndex].isNotificationType
                    }
                })
            }
        })
    })
    // console.log(escLevel);

    let tempEsc = []

    for (let index = 0; index < escLevel.length; index++) {
        const element = escLevel[index];
        if (element.newEscalationLevels.length) {
            tempEsc.push(element)
        }
    }
    let assetArray = []
    this.assetDataRecord.map((asset, assetIndex) => {
        assetArray.push({ assetId: asset.assetId })
    })

    if (isOneSelect) {
        this.ErrorMessage = "Please select at least one Notification Type";
        return;
    }
    this.notificationObj.assetRoles = escLevel;
    this.notificationObj.tiers = tiers;
    this.notificationObj.assets = assetArray;


    // console.log(escLevel, "tiers :: ", tiers, "asset ::", assetArray)

    // http://localhost:8764/api/v1/notification_plan/createNewNotificationPlan

    // this.notificationObj.client = {
    //     clientId : this.clientId
    // }


    console.log("---------------update plan", this.notificationObj, tempEsc)
    if (this.planId) {
        // return

        if (assetArray.length > 0) {
            Swal({
                title: 'Are you sure?',
                text: `Updating this Notification Plan will effect how notifications are delivered on ${assetArray.length} assets. Would you like to proceed?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    this.apiService.update('/notification_plan/updateNewNotificationPlan', this.notificationObj).subscribe(
                        data => {
                            if (data.statusCode == 100) {
                                // this.saveTemplate();
                                this.notificationForm.submitted = false;

                                 this.alertService.sweetMessage('success', 'Plan Updated successfully');
                                // this.router.navigate(['/new-notification-plan-list']);
                                this.location.back();
                            } else {
                                this.notificationObj.tiers = [];
                                this.alertService.sweetMessage('error', data.responseMessage);
                            }
                        },
                        error => {
                            this.notificationObj.tiers = [];
                            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                        }
                    )
                }
            })
        } else {
            this.apiService.update('/notification_plan/updateNewNotificationPlan', this.notificationObj).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        // this.saveTemplate();
                        this.alertService.sweetMessage('success', 'Plan Updated successfully');
                        // this.router.navigate(['/new-notification-plan-list']);
                        this.location.back();
                    } else {
                        this.notificationObj.tiers = [];
                        this.alertService.sweetMessage('error', data.responseMessage);
                    }
                },
                error => {
                    this.notificationObj.tiers = [];
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            )
        }



    } else {
        // console.log(this.notificationObj)
        this.apiService.create('notification_plan/createNewNotificationPlan', this.notificationObj).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.alertService.sweetMessage('success', 'Notification Plan added successfully');
                    this.planId = data.response;
                    // this.saveTemplate();
                    // this.router.navigate(['/new-notification-plan-list']);
                    this.location.back();
                } else {
                    this.notificationObj.tiers = [];
                    this.alertService.sweetMessage('error', data.responseMessage);
                }
            },
            error => {
                this.notificationObj.tiers = [];
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        )
    }

}

OnChangeAdmin(event){
    if (event.target.value == 2) {
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Admin') {
                    if (obj.isNotificationType) {
                        this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
                        this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = true;
                        this.notificationArray[roleIndex].newEscalationLevels[index].isAck = true;
                        this.showAdminEscSec = true;
                    }
                }
            })
        })
    } else {
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Admin') {
                    this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
                    this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = false;
                    this.notificationArray[roleIndex].newEscalationLevels[index].isAck = false;
                }
            })
        })
    }
}


OnChangeAlarm(event){
    if (event.target.value == 2) {
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Alarms') {
                    if (obj.isNotificationType) {
                        this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
                        this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = true;
                        this.notificationArray[roleIndex].newEscalationLevels[index].isAck = true;
                        this.showAlarmEscSec = true;
                    }
                }
            })
        })
    } else {
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Alarms') {
                    this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
                    this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = false;
                    this.notificationArray[roleIndex].newEscalationLevels[index].isAck = false;
                }
            })
        })
    }
}


OnChangeWarning(event){
    if (event.target.value == 2) {
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Warnings') {
                    if (obj.isNotificationType) {
                        this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
                        this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = true;
                        this.notificationArray[roleIndex].newEscalationLevels[index].isAck = true;
                        this.showWarningEscSec = true
                    }
                }
            })
        })
    } else {
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Warnings') {
                    this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
                    this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = false;
                    this.notificationArray[roleIndex].newEscalationLevels[index].isAck = false;
                }
            });
        });
    }
}

OnChangeNormal(event){
    if (event.target.value == 2) {
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Normals') {
                    if (obj.isNotificationType) {
                        this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
                        this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = true;
                        this.notificationArray[roleIndex].newEscalationLevels[index].isAck = true;
                        this.showNormalEscSec = true;
                    }
                }
            });
        });
    } else {
        this.notificationArray.map((roleObj, roleIndex) => {
            roleObj.newEscalationLevels.map((obj, index) => {
                if (obj.notificationType == 'Normals') {
                    this.notificationArray[roleIndex].newEscalationLevels[index].escalationNotificationType = null;
                    this.notificationArray[roleIndex].newEscalationLevels[index].isEscalate = false;
                    this.notificationArray[roleIndex].newEscalationLevels[index].isAck = false;
                }
            });
        });
    }
}


onChangeisNotificationType(event, value, i, j){
    // console.log(event.target.checked, value);
    if (event.target.checked) {
        // this.notificationArray.map((roleObj,roleIndex)=>{
        //     roleObj.newEscalationLevels.map((obj,index)=>{
        //         if(obj.notificationType == 'Alarms'){
        // if (obj.isNotificationType) {
        if (value == 'Alarms' && (this.notificationObj.alarmEscalateType == 2 || this.notificationObj.alarmEscalateType == '2')) {
            this.notificationArray[i].newEscalationLevels[j].escalationNotificationType = null;
            this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
            this.notificationArray[i].newEscalationLevels[j].isAck = true;
            this.showAlarmEscSec = true
        } else if (value == 'Alarms' && (this.notificationObj.alarmEscalateType == 0 || this.notificationObj.alarmEscalateType == '0')) {
            // this.notificationArray[i].newEscalationLevels[j].escalationNotificationType = null;
            // this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
            // this.notificationArray[i].newEscalationLevels[j].isAck = true;
            if (this.showAlarmEscSec) {

                // this.notificationArray.map((roleObj,roleIndex11)=>{
                //     roleObj.newEscalationLevels.map((obj,index11)=>{
                //         if(obj.notificationType == 'Alarms'){
                // console.log("change 111222");
                this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
                // this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true 
                // console.log(this.notificationArray);

                //         }
                //     })
                // })
            }
        }

        if (value == 'Warnings' && (this.notificationObj.warningEscalateType == 2 || this.notificationObj.warningEscalateType == '2')) {
            this.notificationArray[i].newEscalationLevels[j].escalationNotificationType = null;
            this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
            this.notificationArray[i].newEscalationLevels[j].isAck = true;
            this.showWarningEscSec = true
        } else if (value == 'Warnings' && (this.notificationObj.warningEscalateType == 0 || this.notificationObj.warningEscalateType == '0')) {
            if (this.showWarningEscSec) {

                // this.notificationArray.map((roleObj,roleIndex11)=>{
                //     roleObj.newEscalationLevels.map((obj,index11)=>{
                //         if(obj.notificationType == 'Alarms'){
                // console.log("change 111222");
                this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
                // this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true 
                // console.log(this.notificationArray);

                //         }
                //     })
                // })
            }
        }

        if (value == 'Admin' && (this.notificationObj.adminEscalateType == 2 || this.notificationObj.adminEscalateType == '2')) {
            this.notificationArray[i].newEscalationLevels[j].escalationNotificationType = null;
            this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
            this.notificationArray[i].newEscalationLevels[j].isAck = true;
            this.showAdminEscSec = true
        } else if (value == 'Admin' && (this.notificationObj.adminEscalateType == 0 || this.notificationObj.adminEscalateType == '0')) {
            if (this.showAdminEscSec) {

                // this.notificationArray.map((roleObj,roleIndex11)=>{
                //     roleObj.newEscalationLevels.map((obj,index11)=>{
                //         if(obj.notificationType == 'Alarms'){
                // console.log("change 111222");
                this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
                // this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true 
                // console.log(this.notificationArray);

                //         }
                //     })
                // })
            }
        }

        if (value == 'Normals' && (this.notificationObj.normalEscalateType == 2 || this.notificationObj.normalEscalateType == '2')) {
            this.notificationArray[i].newEscalationLevels[j].escalationNotificationType = null;
            this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
            this.notificationArray[i].newEscalationLevels[j].isAck = true;
            this.showNormalEscSec = true
        } else if (value == 'Normals' && (this.notificationObj.normalEscalateType == 0 || this.notificationObj.normalEscalateType == '0')) {
            if (this.showNormalEscSec) {

                // this.notificationArray.map((roleObj,roleIndex11)=>{
                //     roleObj.newEscalationLevels.map((obj,index11)=>{
                //         if(obj.notificationType == 'Alarms'){
                // console.log("change 111222");
                this.notificationArray[i].newEscalationLevels[j].isEscalate = true;
                // this.notificationArray[roleIndex11].newEscalationLevels[index11].isEscalate = true 
                // console.log(this.notificationArray);

                //         }
                //     })
                // })
            }
        }
        // }
        // }
        //     })
        // })
    } else {

        this.notificationArray[i].newEscalationLevels[j].escalationNotificationType = null;
        this.notificationArray[i].newEscalationLevels[j].isEscalate = false;
        this.notificationArray[i].newEscalationLevels[j].isAck = false;
        this.notificationArray[i].newEscalationLevels[j].isAck = false;
        this.notificationArray[i].newEscalationLevels[j].notificationMethod = null;
        this.notificationArray[i].newEscalationLevels[j].escalationNotificationType = null;

    }
}


// --------------------------------------------------------------------------------------------------------

getTemplate() {

    /**************************************
     Get Default Email Temaplte  on create Notification Plan
    **********/
    if (this.planId == 0) {
        this.apiService.getAll('/EMailTemplate/getEmailTemplateByTemplateName?templateName=DefaultNPCallTemplate').subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.templateArea = data.response.body;
                } else { /*this.alertService.sweetMessage('error',data.responseMessage); */ }
            },
            error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
    } else {

        /*********************************
          Get Email Temaplte on edit notification plan
        **********/
        this.apiService.getAll('notification_plan/getCallTemplateByNotificationId?notificationId=' + this.planId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.templateArea = data.response.liveCallTemplate
                } else { /*this.alertService.sweetMessage('error',data.responseMessage); */ }
            },
            error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
    }

}



textbox(){
    this.ctl = this.document.getElementById('text');
    if (this.ctl) {
        this.startPos = this.ctl.selectionStart;
        this.endPos = this.ctl.selectionEnd;
    }
}

insertAtCursor(myField, myValue) {
    //IE support
    if (this.document.selection) {
        myField.focus();
        var sel = this.document.selection.createRange();
        sel.text = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
        myField.value = myField.value.substring(0, (this.startPos + 1))
            + myValue
            + myField.value.substring(this.endPos, myField.value.length);
    } else {
        myField.value += myValue;
    }
}


/********************
 Add Text in Template during Edit
**********************/

addParamName(param) {
    var iframeWin = document.getElementById('text');

    if (param == 'sitename') {
        this.insertAtCursor(iframeWin, "${siteName}");
    }
    else if (param == 'assetname') {
        this.insertAtCursor(iframeWin, "${assetName}");
    }
    else if (param == 'tier') {
        this.insertAtCursor(iframeWin, "${tierName}");
    }
    else if (param == 'datapointdesc') {
        this.insertAtCursor(iframeWin, "${dataPointDesc}");
    }
    else if (param == 'eventstatus') {
        this.insertAtCursor(iframeWin, "${eventStatus}");
    }
    else if (param == 'eventtime') {
        this.insertAtCursor(iframeWin, "${eventTime}");
    }
    else if (param == 'faultcode') {
        this.insertAtCursor(iframeWin, "${faultCode}");
    }
    else if (param == 'notificationplan') {
        this.insertAtCursor(iframeWin, "${notificationPlan}");
    }
    else if (param == 'callrecipient') {

        this.insertAtCursor(iframeWin, " #foreach( $product in $allProducts )<tr> <td>$product.callSequence</td>  <td>$product.userDTO.title $product.userDTO.firstName $product.userDTO.lastName</td> <td>$product.userDTO.phoneNumber</td>  <td>$product.userDTO.mobileNumber</td>  <td>Remark</td>  </tr> #end");
    }

}

/********************
 Show Template Preview In ckeditor
*************************************/
showPrev(){
    this.isOpenCk = true;
    this.closeEditor = false;
    this.template_html = this.document.getElementById('text').value;
}

/********************
 Open Editor
*************************************/
openEditor(){
    this.isOpenCk = false;
    this.closeEditor = true;
    // let ckdata = this.ckeditorObject.getData();
    let ckdata = this.ckeditorObject.activeEditor.getContent({ format: 'html' });
    this.templateArea = this.template_html;
}

// Clear Textarea
clear() {
    this.templateArea = '';
}

/********************
 Save Template after Edit
********************************/
saveTemplate() {

    this.templateData = {
        "notificationPlanId": this.planId,
        "liveCallTemplate": this.templateArea
    }

    this.apiService.update('notification_plan/updateNPCallTemplate', this.templateData).subscribe(
        data => {
            if (data.statusCode == 100) {
                //   this.alertService.sweetMessage('success','Template Successfully Saved');
            }
            else { this.alertService.sweetMessage('error', data.responseMessage); }
        },
        error => {
            //    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}

addTemplate() {
    this.alertService.sweetMessage('success', 'Changes Saved');
}

onSelectNotiType(event, i){
    this.selectCall = event.target.value;
    this.notiObt = i;
    //   console.log("i----"+i);
    //  console.log(event.target.value);
}

}


