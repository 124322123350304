import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
// import { HttpClientModule} from '@angular/common/http';
// import { Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StructureModule }  from './structure/structure.module';
import { LoginModule }  from './structure/login/login.module';
import { ForgotPasswordComponent } from './structure/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './structure/reset-password/reset-password.component';
import { UserActiveComponent } from './structure/user-activation/user-activation.component';
import { ApiService } from './services/index';
import { AlertService, CommonService } from './services/index';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { PendingChangesGuard } from './pending-changes.guard';
import { PermissionGuard } from './services/permission.guard';
import { NgDygraphsModule } from 'ng-dygraphs';
import { EventResolve } from './services/event.resolve.service';
import { APP_BASE_HREF } from '@angular/common';
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
// import { LoadingBarModule } from '@ngx-loading-bar/core';

// import { FusionChartsModule } from 'angular-fusioncharts';

// import * as FusionCharts from './structure/admin/js/fusioncharts';
// import * as Charts from './structure/admin/js/fusioncharts.charts';
// import * as Widgets from './structure/admin/js/fusioncharts.widgets'
// import * as FintTheme from './structure/admin/js/themes/fusioncharts.theme.fint';

// FusionChartsModule.fcRoot(FusionCharts, Charts, FintTheme, Widgets)
// FusionCharts.options.SVGDefinitionURL='absolute'
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserActiveComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    // HttpClientModule,
    // FusionChartsModule,
    LoadingBarHttpModule,
    // LoadingBarModule,
	  FormsModule,
    AppRoutingModule,
    StructureModule,
    LoginModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgDygraphsModule
  ],
  providers: [
  	ApiService,
    CookieService,
    AlertService,
    CommonService,
    PendingChangesGuard,
    PermissionGuard,
    EventResolve,
    {provide: LocationStrategy, useClass:  PathLocationStrategy}
    // {provide: APP_BASE_HREF, useValue: '/'}
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
