import { Component, OnInit, ViewChild,ElementRef,Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
/*import { jqxTreeComponent } from '../../jqwidgets-ts/angular_jqxtree';*/
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3:any,moment:any;;


@Component({
  templateUrl: './tier.component.html'
})
export class TierComponent implements OnInit {
/* @ViewChild('myTree') myTree: jqxTreeComponent;*/
clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
tierListData: any = [];
data: any = [];
source: any = {};
dataAdapter: any = [];
records: any = [];
statusofsiteData: any =[];
sitelifecycleData: any = [];
tierId : number = 0;
itemId : number = 0;
assetbytierData : any =[];
item : any ="";
positions: any = [];
marker = {display: true,lat: null,lng: null,siteName:'',zipCode:'',country:'',state:'',city:'',address:''};
siteData: any = [];
pieChartData : any = [];
permissionData:any=[];

constructor(private elementRef:ElementRef, private renderer:Renderer,private router:Router, private apiService: ApiService , private alertService: AlertService) {
    this.permissionData = ApiService.permission;
}    
    width = "100%";
    height = "100%";
    type = 'pie3d';
    dataFormat = 'json';
    dataSource: any  = {
    "chart": {
        "caption": "Site Status Chart",
        "startingangle": "150",
        "showlabels": "0",
        "showlegend": "2",
        "enablemultislicing": "0",
        "slicingdistance": "4",
        "showpercentvalues": "1",
        "showpercentintooltip": "0",
        "plottooltext": "$label :- $datavalue"
    },
    "data": this.pieChartData
  };  

ngOnInit(){
   /* this.getTierList();
    this.getAllSites();
    this.getStatusOfSite();
    this.getSiteLifeCycleCount();
    this.getAssetByTierId();
    this.getPieChartData();*/
}
/* Set value in secelbox on select */
ItemClick(event: any){
    this.itemId = event.args.element.id;
    this.tierId = this.itemId;
    this.getAllSites();
    this.getSiteLifeCycleCount();   
    this.getStatusOfSite();     
    this.getAssetByTierId();
    this.getPieChartData();
}
/* show site information on click on map marker*/
clicked({target: marker},pos) {
  this.marker.lat = marker.getPosition().lat();
  this.marker.lng = marker.getPosition().lng();
  this.marker.siteName = pos[2];
  this.marker.zipCode = pos[3];
  this.marker.country = pos[4];
  this.marker.state = pos[5];
  this.marker.city = pos[6];
  this.marker.address = pos[7];
  marker.nguiMapComponent.openInfoWindow('iw', marker);
}

updatePieData(){
   this.dataSource = {
    "chart": {
        "caption": "Site Status Chart",
        "startingangle": "150",
        "showlabels": "0",
        "showlegend": "2",
        "enablemultislicing": "0",
        "slicingdistance": "4",
        "showpercentvalues": "1",
        "showpercentintooltip": "0",
        "plottooltext": "$label :- $datavalue"
    },
    "data": this.pieChartData
  }
}
/* Function for getting critical site data */
getPieChartData(){
   if(this.tierId == 0){
        var URL = 'asset/getAssetPieChartData?companyId='+this.clientId;
    }else{
        var URL = 'asset/getAssetPieChartData?companyId='+this.clientId+'&tierId='+this.tierId;
    }
  this.apiService.getAll(URL).subscribe(
    data => {
      if(data.statusCode == 100){
        this.pieChartData = data.responseList; 
        this.updatePieData();                                
      }else{
        this.pieChartData = [];
        this.updatePieData();                                
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
    

}
/* Function for getting critical site data */
getAllSites(){
   if(this.tierId == 0){
        var URL = 'site/getSiteByUserCompanyTier?userId='+this.userId+'&companyId='+this.clientId;
    }else{
        var URL = 'site/getSiteByUserCompanyTier?userId='+this.userId+'&companyId='+this.clientId+'&tierId='+this.tierId;
    }
  this.apiService.getAll(URL).subscribe(
    data => {
      if(data.statusCode == 100){
        this.siteData = data.responseList;
          this.positions = [];
            Object.keys(this.siteData).forEach(key=> {
              if(this.siteData[key]['latitude'] != null && this.siteData[key]['longitude'] != null){
                this.positions.push([this.siteData[key]['latitude'], this.siteData[key]['longitude'], this.siteData[key]['siteName'], this.siteData[key]['zipCode'], this.siteData[key]['country']['countryName'], this.siteData[key]['state']['stateName'], this.siteData[key]['city'], this.siteData[key]['address']]); 
              }
           });                         
      }else{
        this.positions = [];
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}
/* Get tier list */
getTierList(){
  this.apiService.getAll('tier/allTier/'+this.clientId).subscribe(
    data => {
        if(data.statusCode == 100){
            this.tierListData = data.responseList; 
            this.data = this.tierListData
            this.source = {
                datatype: 'json',
                datafields: [
                    { name: 'tierId' },
                    { name: 'parentTierId' },
                    { name: 'tierName' },                            
                ],
                id: 'tierId',
                localdata: this.data
            };
              /*this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
              this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' },{ name: 'tierId', map: 'id'}]);*/
            }else{
              this.alertService.sweetMessage('error',data.responseMessage); 
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
  }




/* STATUS OF SITES TABLE */
getStatusOfSite(){
      if(this.tierId == 0){
        var URL = 'asset/assetStatusCountforTierDashboard?companyId='+this.clientId;
    }else{
        var URL = 'asset/assetStatusCountforTierDashboard?companyId='+this.clientId+'&tierId='+this.tierId;
    }
  this.apiService.getAll(URL).subscribe(
    data => {
        if(data.statusCode == 100){
            this.statusofsiteData = data.response;
        }else{
              this.statusofsiteData = [];
            }
          },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }


/* SiteLifeCycle STATUS Count TABLE */
getSiteLifeCycleCount(){
    if(this.tierId == 0){
        var URL = 'site/getSiteLifeCycleStatusCount?companyId='+this.clientId;
    }else{
        var URL = 'site/getSiteLifeCycleStatusCount?companyId='+this.clientId+'&tierId='+this.tierId;
    }
    
  this.apiService.getAll(URL).subscribe(
    data => {
        if(data.statusCode == 100){
            this.sitelifecycleData = data.response;
        }else{
          this.sitelifecycleData = [];
            }
          },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }



   /* SiteLifeCycle STATUS Count TABLE */
getAssetByTierId(){ 
  if(this.tierId == 0){
        var URL = 'asset/getAssetByTierIdTierDashBoard?companyId='+this.clientId;
    }else{
        var URL = 'asset/getAssetByTierIdTierDashBoard?companyId='+this.clientId+'&tierId='+this.tierId;
    } 
  this.apiService.getAll(URL).subscribe(
    data => {
        if(data.statusCode == 100){
            this.assetbytierData = data.response.assetDetail;
        }else{
            this.assetbytierData = [];
            }
          },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }
}