import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, Response, ResponseType, ResponseContentType } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import "rxjs/Rx"
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
// import * as NProgress from 'nprogress';
import Swal from 'sweetalert2'
declare var moment:any;

var ip = location.host;
    var apiUrl: String = "https://www.amci1440.com/client/api/v1";
    var imgUrl: String = "https://www.amci1440.com/";
    var loginUrl: String = "https://www.amci1440.com";
    var CustomerUrl  : String = "https://customer.amci1440.com/login?";



var routeNavigate:any='';
@Injectable()
export class ApiService {

    public static permission: any={};
    public static isPermission:number=0;
    // NProgress: any;
    AppUrlService = apiUrl
    LoginUrlService = loginUrl
    Customer_Url = CustomerUrl;

    constructor(private router: Router, private http: Http) {
        routeNavigate = this.router;
    }


    /*Function for getting user token*/
    getUserToken(): Promise<any> {
        return this.http.get('/token').map((response: Response) =>{
            return response.json();
        }).toPromise();
    }
    appUrl:String= ip;
    loaderImg:any;

    baseBath : String = apiUrl;
    //imageBasePath:string="http://client.satalarm.com/";
    imageBasePath:String = imgUrl == "http://192.168.0.112" || imgUrl == "https://s09.cdnsolutionsgroup.com" ? imgUrl + '/' : imgUrl;
    end_point: String;
    data: any;
    id: Number;


    getAllDashboard(end_point){
        // if (this.checkTokenExist()) {
            return this.http.get(apiUrl+'/'+end_point,this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        // }
    }

    getLoginDatawithoutToken(end_point){
        // NProgress.start();
        this.clinetLoader();

        return this.http.get(apiUrl+'/'+end_point)
            .map(this.handleSuccess)
            .catch(this.handleError);
    }
    
    refreshToken(end_point, obj){
        let headers = new Headers(/* { 'Authorization': token.access_token} */);
        headers.append("Content-Type" , "application/x-www-form-urlencoded");
        const httpOptions = new RequestOptions({ headers: headers });
        let finalRequestBody = "refresh_token=";
        finalRequestBody=finalRequestBody+obj.refresh_token+"&grant_type=refresh_token"
        // finalRequestBody=finalRequestBody+obj.password+""
        // let headers = {
        //     headers: new HttpHeaders(
        //         {
        //             'Content-Type': 'application/x-www-form-urlencoded',
        //             // 'Authorization': 'bearer '+token.access_token
        //         })
        // }
        return this.http.post(loginUrl+'/'+end_point,finalRequestBody,httpOptions)
        .map((res: Response)=>{
            return res.json();
        })
        .catch((error:any)=>{
            return Observable.throw(error.json());
          });
    }

    getUserInfoData(end_point){
        // if (this.checkTokenExist()) {
        if (this.isTokenAvailable()) {
            
        
            return this.http.get(apiUrl+'/'+end_point,this.AuthorizationHeader())
            .map((res: Response)=>{
                return res.json();
            })
            .catch((error:any)=>{
                return Observable.throw(error.json());
            });
        }
    }

    /*: Observable<any>*/
    getAll(end_point){
        //console.log('start', end_point, this.AuthorizationHeader());
        // console.log(this.http.get(apiUrl+'/'+end_point, this.AuthorizationHeader())
        //.map(this.handleSuccess)
        //.catch(this.handleError)
        // ); 
        if (this.isTokenAvailable()) {
            // NProgress.start();
            this.clinetLoader();       
            return this.http.get(apiUrl+'/'+end_point, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
        // else{
        //     return
        // }
    }

    getById(end_point, id: number){
        if (this.isTokenAvailable()) {
            // NProgress.start();
            this.clinetLoader()
            return this.http.get(apiUrl+'/'+end_point+'/' + id , this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }


    login(end_point, data) {
        let headers = new Headers();
        headers.append("Content-Type" , "application/x-www-form-urlencoded");
        const httpOptions = new RequestOptions({ headers: headers });
        // let headers = {
        //     headers: new HttpHeaders(
        //         {
        //             'Content-Type': 'application/x-www-form-urlencoded',
        //             // 'Authorization': 'bearer '+token.access_token
        //         })
        // }
        // NProgress.start();
        this.clinetLoader()
        let finalRequestBody = "username=";
        finalRequestBody=finalRequestBody+data.username+"&password="
        finalRequestBody=finalRequestBody+data.password+"&grant_type="
        finalRequestBody=finalRequestBody+data.grant_type+"&user_type=client";;

        return this.http.post(loginUrl+'/'+end_point, finalRequestBody, httpOptions)
            .map(this.handleSuccessLogin)
            .catch(this.handleErrorLogin);
    }

    Impersonate(end_point, data) {
        let headers = new Headers();
        headers.append("Content-Type" , "application/x-www-form-urlencoded");
        const httpOptions = new RequestOptions({ headers: headers });

        // let headers = {
        //     headers: new HttpHeaders(
        //         {
        //             'Content-Type': 'application/x-www-form-urlencoded',
        //             // 'Authorization': 'bearer '+token.access_token
        //         })
        // }

        // NProgress.start();
        this.clinetLoader()
        let finalRequestBody = "admin_access_token="/* "username=";
        // finalRequestBody=finalRequestBody+data.username+" &*/""
        finalRequestBody=finalRequestBody+data.admin_access_token+"&grant_type="
        finalRequestBody=finalRequestBody+data.grant_type+"&param=";
        finalRequestBody=finalRequestBody+data.param/* +"&admin_user_id="
        finalRequestBody=finalRequestBody+data.admin_user_id */
        finalRequestBody=finalRequestBody+"&user_type=client";

        return this.http.post(loginUrl+'/'+end_point, finalRequestBody, httpOptions)
            .map(this.handleSuccessLogin)
            .catch(this.handleErrorLogin);
    }

    logout(end_point){
            // NProgress.start();
            this.clinetLoader()
            return this.http.delete(loginUrl+'/'+end_point, this.AuthorizationHeader())
                .map(this.handleSuccessLogin)
                .catch(this.handleErrorLogin);
    }
    /*login(end_point, data) {
        NProgress.start();
        return this.http.post(apiUrl+'/'+end_point, data)
            .map(this.handleSuccess)
            .catch(this.handleError);
    }*/

    create(end_point, data){
        // if (this.isTokenAvailable()) {
            // NProgress.start();
            this.clinetLoader()
            return this.http.post(apiUrl+'/'+end_point, data, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        // }
    }

    downloadFile(end_point, data) {
        this.clinetLoader() 
        let token = JSON.parse(localStorage.getItem('Token'));
        let headers = new Headers({ 'Authorization': 'bearer '+token.access_token});
        var options = new RequestOptions({headers: headers, responseType: ResponseContentType.Blob})
        return this.http.post(apiUrl+'/'+end_point, data, options)
        .map((res: Response) => {return res} )
        .catch(this.handleError)
    }

// post form data using multipart
  insert(end_point, data){
    let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token) {
  let headers = new Headers({ 'Authorization': token.access_token});
    headers.append("Content-Type" , "multipart/form-data");
    const httpOptions = new RequestOptions({ headers: headers });

    // let headers = {
    //     headers: new HttpHeaders(
    //         {
    //             // 'Content-Type': 'application/json',
    //             'Authorization': 'bearer '+token.access_token
    //         })
    // }
    // NProgress.start();
    this.clinetLoader()
      return this.http.post(apiUrl+'/'+end_point, data , httpOptions)
          .map(this.handleSuccess)
          .catch(this.handleError);
    }
  }

    update(end_point, data){
        // if (this.isTokenAvailable()) {
            // NProgress.start();
            this.clinetLoader()
            return this.http.put(apiUrl+'/'+end_point, data, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        // }
    }

    resetPassword(end_point, data){
        // if (this.isTokenAvailable()) {
            // NProgress.start();
            this.clinetLoader()
            return this.http.put(apiUrl+'/'+end_point, data)
                .map(this.handleSuccess)
                .catch(this.handleError);
        // }
    }

    userActivate(end_point, data){
        // if (this.isTokenAvailable()) {
            // NProgress.start();
            this.clinetLoader()
            return this.http.put(apiUrl+'/'+end_point, data)
                .map(this.handleSuccess)
                .catch(this.handleError);
        // }
    }

    delete(end_point, data){
        if (this.isTokenAvailable()) {
            // NProgress.start();
            this.clinetLoader()
            return this.http.delete(apiUrl + '/'+end_point,this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }

    private handleSuccessStatus(res: Response){
        return res.json();
    }


    private handleSuccess(res: Response){
        var statusCode = res.json().statusCode;
        var responseMessage = res.json().responseMessage;
        localStorage.setItem("t11",new Date().getTime().toString())
        document.cookie = "t11=;"//+data.refresh_token
        // document.cookie = "t11="+new Date().getTime().toString()+'; path=/';
        document.cookie = "t11="+new Date().getTime().toString()+'; path=/; expires=' + new Date(moment().add(600, 'days')).toUTCString();
        $("#loaderImgIcon").css("display","none");
        if(statusCode == 100){
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        }else if(statusCode == 101){
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        }else if(statusCode == 102){
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        }else if(statusCode == 103){
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        }else if(statusCode == 104){
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        }else if(statusCode == 199){
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        }else if(statusCode == 110){
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
                    //localStorage.clear();
                    //routeNavigate.navigate(['/login']);
        }else if(statusCode == 105){
         localStorage.setItem("errorText" , responseMessage);
         routeNavigate.navigate(['/error']);

        }else if(statusCode == 109){
            localStorage.clear();
            localStorage.setItem("logoutMessage" , responseMessage);
            routeNavigate.navigate(['/login']);
        }

        // NProgress.done();
        return res.json();
    }

    private handleError(error:any) {
        var errorstatusFlag = error.ok;
        var errorStatus = error.status;
        console.log(errorStatus);
        
        if((errorstatusFlag == false) && ( errorStatus == 401)) {
            // Swal({
            //         position: 'top-end',
            //         type: 'error',
            //         title: 'Session has been expired, login again',
            //         showConfirmButton: false,
            //         timer: 4000
            //       })
            localStorage.clear();
            localStorage.removeItem("Token");
            routeNavigate.navigate(['/login']);
            return Observable.throw(error.json());;
        }else if(errorStatus == 0 ){
            let token = JSON.parse(localStorage.getItem('Token'));
            // if (token && token.access_token) {
            //     console.log('-----------------------1');
            //     let headers = new Headers({ 'Authorization': 'bearer '+token.access_token});
            //     // "Access-Control-Allow-Headers":"*", "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS", 'Authorization': 'bearer '+token.access_token,
            //     fetch(apiUrl+'/user/isUserSessionExpaired', {method:"POST",headers :{ 'Content-Type': 'application/json',  /*  mode: 'no-cors' */},body:JSON.stringify({sessionToken:token.access_token})})
            //         .then((response) => {
            //             console.log("-----------------------------11");
                        
            //             return response.json();
            //         })
            //         .then((myJson) => {
            //             console.log(myJson);
            //             if (myJson.statusCode != 100) {
            //                 localStorage.clear();
            //                 // localStorage.removeItem("Token");
            //                 routeNavigate.navigate(['/login']);
            //             }
            //         }).catch((error)=>{
            //             console.log(error.status, error.statusCode, error.errorStatus, error);
                        
            //         });
            //     }
        }
        var statusCode = error.json().status;
        var responseMessage = error.json().message;
        $("#loaderImgIcon").css("display","none");
        if(statusCode == 100){
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        }else if(statusCode == 101){
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        }else if(statusCode == 102){
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        }else if(statusCode == 103){
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        }else if(statusCode == 104){
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        }else if(statusCode == 199){
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
            localStorage.clear();
            localStorage.removeItem("Token");
            routeNavigate.navigate(['/login']);
        }else if(statusCode == 105){
           localStorage.setItem("errorText" , responseMessage);
           routeNavigate.navigate(['/error']);
        }else if(statusCode == 109){
            localStorage.clear();
            localStorage.setItem("logoutMessage" , responseMessage);
            routeNavigate.navigate(['/login']);
        }
        else{
            //console.log('Api Error Status Code 500');
            //console.log(statusCode);
        }
        // NProgress.done();
        return Observable.throw(error.json());
    }

     private handleSuccessLogin(res: Response){

       var statusCode = res.json().statusCode;
       var responseMessage = res.json().responseMessage;
       localStorage.setItem("t11",new Date().getTime().toString())
       document.cookie = "t11=;"//+data.refresh_token
    //    document.cookie = "t11="+new Date().getTime().toString()+'; path=/';
        document.cookie = "t11="+new Date().getTime().toString()+'; path=/; expires=' + new Date(moment().add(600, 'days')).toUTCString();
        // NProgress.done();
        $("#loaderImgIcon").css("display","none");
        if(statusCode == 109){
        }else{
          return res
        }
          return responseMessage;
    }

    private handleErrorLogin(error:any) {
      var statusCode = error.json().status;
      var responseMessage = error.json().message;
    //   NProgress.done();
      $("#loaderImgIcon").css("display","none");
      if(statusCode == 109){
      }
      return Observable.throw(error.json());
    }

	// create authorization header with jwt token

    private AuthorizationHeader() {
        let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token) {
            let headers = new Headers({ 'Authorization': 'bearer '+token.access_token});
            return new RequestOptions({ headers: headers });
            // let headers = {
            //     headers: new HttpHeaders(
            //         {
            //             // 'Content-Type': 'application/json',
            //             'Authorization': 'bearer '+token.access_token
            //         })
            // }
            // return headers
        }else{
            routeNavigate.navigate(['/login']);            
        }

        
    }

    getToken(){
        let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token) {
            return 'bearer '+token.access_token
            // let headers = {
            //     headers: new HttpHeaders(
            //         {
            //             // 'Content-Type': 'application/json',
            //             'Authorization': 'bearer '+token.access_token
            //         })
            // }
            // return headers
        }
    }

    checkTokenExist(){
        let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token) {
            return true;
        }else{
            return false;
        }
    }



    /* end private helper methods */

    getThirdPartyAPI(end_point){
		// NProgress.start();
        return this.http.get(end_point, this.AuthorizationHeader())
			.map(this.handleSuccess)
			.catch(this.handleError);
    }

    clinetLoader(){
        // console.log(localStorage.getItem("loaderImg"), $("#loaderImgIcon"), $("#loading-bar-spinner"));
        this.loaderImg =  localStorage.getItem("loaderImg") && localStorage.getItem("loaderImg") != 'null' ? this.imageBasePath+localStorage.getItem("loaderImg") : 'assets/img/load.gif'
    //   this.loaderImg = this.imageBasePath+localStorage.getItem("loaderImg")
    $("#loaderImgIcon").attr("src",this.loaderImg);
    //   $("#loaderImgIcon").css("display","block");
    //   background: url(assets/img/load.gif) rgba(255, 255, 255, 0.53);
    // $("#loading-bar-spinner").css("background-image" , "url("+this.loaderImg+")");
    setTimeout(() => {
        $("#loading-bar-spinner").css("background-image" , "url('"+this.loaderImg+"')");
        }, 200);
    }

    // clinetLoader() {
        
    //     // console.log(localStorage.getItem("loaderImg"), $("#loaderImgIcon"), $("#loading-bar-spinner"));
    // this.loaderImg =  localStorage.getItem("loaderImg") && localStorage.getItem("loaderImg") != 'null' ? this.imageBasePath+localStorage.getItem("loaderImg") : 'assets/img/load.gif'


    // console.log(`----------------------------${this.loaderImg}--------------------${typeof localStorage.getItem("loaderImg")}------------`)


    // //   this.loaderImg = this.imageBasePath+localStorage.getItem("loaderImg")
    // $("#loaderImgIcon").attr("src",this.loaderImg);
    // //   $("#loaderImgIcon").css("display","block");
    // //   background: url(assets/img/load.gif) rgba(255, 255, 255, 0.53);
    // // $("#loading-bar-spinner").css("background-image" , "url("+this.loaderImg+")");
    // setTimeout(() => {
    //     $("#loading-bar-spinner").css("background-image" , "url('"+this.loaderImg+"')");
    //     }, 200);
    // }

    checkToken(obj){
        var statusCode = obj.json().status;
        console.log("----------------------------------",statusCode);
        
        this.http.get(apiUrl+'/user/isUserSessionExpaired' , this.AuthorizationHeader())
        .map((res:any)=>{
            console.log(res);
            return res
            
        })
        .catch(this.handleError);
    }

    isTokenAvailable(){
        let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token){
            return true
        } else{
            routeNavigate.navigate(['/login']);
            return false;
        }
    }
}
