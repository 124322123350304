import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2'

@Injectable()
export class CommonService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;
    public errorMsg : Subject<string> = new BehaviorSubject<any>({});
    // public campaignId: Subject<any> = new BehaviorSubject<any>(0);
    // public componentName: Subject<any> = new BehaviorSubject<any>('');
    currentBreadcrumb:string = '';
    dashboardId:any ;

  constructor(
    private router: Router
  ) {
    // clear message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        }
      }
    });
  }



// var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");

/****** check password ******
 1. Min 8 character.
 2. 1 uppercase.
 3. 1 special Character.
 4. 1 numeric.
 5. No spaces
***********************/

// PassInfo  : any = `
//   1. Password must be at least 8 characters
//   2. Password must contain at least 1 numeric character
//   3. Password must contain at least 1 uppercase character
//   4. Password must contain at least 1 lowercase character
//   5. Password cannot contain 3 identical consecutive characters
//   6. Password must contain at least 1 special character !@#/\$%^&*()
//   7. Password cannot be the same as the login Id
//   8. Password cannot contain spaces`;

PassInfo  : any = `
  1. Password must be at least 8 characters.<br>
  2. Password must contain at least 1 numeric character.<br>
  3. Password must contain at least 1 uppercase character.<br>
  4. Password must contain at least 1 special character !@#/\$%^&*().<br>
  5. Password cannot contain spaces.<br>`;


  checkPassword (key : any) {
    var regx = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$");
    if(regx.test(key) == false){
        // this.errorMsg.result = false;
        // this.errorMsg.msg    = this.PassInfo;
    }
    return this.errorMsg;
  }





  }
