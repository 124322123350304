import { Component, OnInit, ChangeDetectorRef, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService } from '../../../../services/index';
import Swal from 'sweetalert2'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);


declare var $: any;
declare var jQuery: any;
declare var d3: any, moment: any;
import 'datatables.net';
import 'datatables.net-bs4';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
    selector: 'app-asset-management',
    templateUrl: './asset-availability.component.html',
    styleUrls: ['./asset-availability.component.css']
})
export class AssetAvailabilityComponent implements OnInit {

    width = "900";
    height = "300";
    // type = 'Line';
    dataFormat = 'json';
    //     chartInstance = /* new FusionCharts */({
    //       type: 'Line',
    //       width: '700', // Width of the chart
    //       height: '300', // Height of the chart
    //       dataFormat: 'json', // Data type
    //     //   renderAt:'chart-container', //container where the chart will render
    //       dataSource: {
    //           // Chart Configuration
    //           "chart": {
    //           "yaxisname": 'Asset Availability (in minutes)',
    //           "rotatelabels": "1",
    //           "anchorradius": "5",
    //           "showhovereffect": "1",
    //           "plottooltext": "Asset Availability in $label is <b>$dataValue</b>",
    //           "setadaptiveymin": "1",
    //           "theme": "fusion",
    //           "anchorbgcolor": "#0193cf",
    //           "palettecolors": "#0193cf",
    //           "formatNumberScale": "0",
    //           "xFormatNumberScale": "0",
    //           "FormatNumber": "0",
    //           "showvalues": "0",
    //           },
    //           // Chart Data
    //           "data": null
    //     }
    //   })

    showAssetName: any;

    data: any = {
        chart: {
            // caption: "Last 25 Days",
            // numvisibleplot: "6",
            // showvalues: "1",
            // decimals: "2",
            // exportEnabled: "1",
            // stack100percent: "1",
            // valuefontcolor: "#FFFFFF",
            // plottooltext: this.showAssetName+" has  $dataValue $seriesName Minutes (<b>$percentValue</b>) on $label",
            theme: "fusion"
        },
        categories: [
            {
                category: []
            }
        ],
        dataset: [
            {
                // seriesname: "Male",
                data: []
            }
        ]
    };

    type = "scrollstackedcolumn2d";
    dataSource = this.data;
    selectedAsset: any = { site: {} }
    DateError = null

    // dataSource : any = {
    //     "chart": {
    //         "yaxisname": 'Asset Availability (in minutes)',
    //         "rotatelabels": "1",
    //         "anchorradius": "5",
    //         "showhovereffect": "1",
    //         "plottooltext": "Asset Availability in $label is <b>$dataValue</b>",
    //         "setadaptiveymin": "1",
    //         "theme": "fusion",
    //         "anchorbgcolor": "#0193cf",
    //         "palettecolors": "#0193cf",
    //         "formatNumberScale": "0",
    //         "xFormatNumberScale": "0",
    //         "FormatNumber": "0",
    //         "showvalues": "0",
    //         },
    //     data:[]
    // };

    chartConfig = {
        width: '700',
        height: '400',
        type: 'Line',
        dataFormat: 'json',
    };

    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
    tierId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
    assetId: any = 0;
    assetsAvailabilityData: any = [];
    dataTable: any;
    clientCode: any = '';
    permissionData: any = [];
    Chart: any;

    private chart: am4charts.XYChart;

    runtimeValue: number = 0;
    runtimePercent: any = '';
    downtimeValue: number = 0;
    downtimePercent: any = '';
    up_downChart: am4charts.XYChart;
    standByPercent: any = 0;
    standByValue: any = 0;


    constructor(private zone: NgZone, private router: Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService, private chRef: ChangeDetectorRef, private loading: LoadingBarService) {
        this.permissionData = ApiService.permission;
    }

    // Function to format Date
    DateFormat(startDate) {
        if (startDate) {
            return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
        } else {
            return "";
        }
    }

    ngOnInit() {
        this.clientCode = this.alertService.getClientCode();
        jQuery('.rst-date').datetimepicker({
            language: 'en',
            pickTime: false,
            autoclose: true,
            pickDate: true,
            minDate: 0,
            inline: true,
            sideBySide: true
        }).on('changeDate', function () {
            $('.bootstrap-datetimepicker-widget').hide();
            $(this).datepicker('hide');
        });
        jQuery('.rst-time').datetimepicker({
            pickDate: false,
            autoclose: true,
            pickTime: true,
            pickSeconds: false,
            pickMinutes: true,
            timeFormat: "HH:mm"
        });


        let today = moment().format('YYYY-MM-DD')

        $('#endDate').val(today);
        $('#startDate').val(moment().subtract(19, 'days').format('YYYY-MM-DD'));
        this.getAllApiData();
    }

    // Getting data for Assets are available or not according to client and User
    getAllApiData() {
        var URL = 'asset/getAssetAvailabiltyByClientAndUser?clientId=' + this.clientId + '&userId=' + this.userId;


        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        if (this.tierId != null && this.tierId != '') {
            var tierString = '&tierId=' + this.tierId.toString();
            var URL = URL.concat(tierString);
        }

        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.assetsAvailabilityData = data.responseList;
                    if ($.fn.DataTable.isDataTable('#avail_table')) {
                        $('#avail_table').DataTable().destroy();
                    };
                    this.chRef.detectChanges();
                    const table: any = $('#avail_table');
                    this.dataTable = table.DataTable({ "oSearch": { "bSmart": false }, "order": [], "columnDefs": [{ "orderable": false, "targets": 7 }] });
                } else {
                    this.assetsAvailabilityData = [];
                    this.chRef.detectChanges();
                    const table: any = $('#avail_table');
                    this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": 7 }] });
                }
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    ResetDate() {
        let today = moment().format('YYYY-MM-DD')
        $('#endDate').val(today);
        $('#startDate').val(moment().subtract(19, 'days').format('YYYY-MM-DD'));
        this.getDrawEventGraph(this.selectedAsset);
    }

    ResetDateFromTable() {
        let today = moment().format('YYYY-MM-DD')
        $('#endDate').val(today);
        $('#startDate').val(moment().subtract(19, 'days').format('YYYY-MM-DD'));
        // this.getDrawEventGraph(this.selectedAsset);
    }

    
    ngOnDestroy() {
        // this.Chart.dispose()
        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.dispose();
            }
        });
    }

    initializedChart($event) {
        this.Chart = $event.chart;
    }
    isLoadingAssetGraph: boolean = false;

    // Getting asset Data for Drawing Graph
    getDrawEventGraph(assetData) {
        // this.dataSource.data = [] // commented by 
        this.selectedAsset = assetData
        let start = $('#startDate').val();
        let end = $('#endDate').val()
        let diff = moment(end).diff(moment(start), 'days')
        let sameCheck= moment(end).isAfter(moment(start))
        this.runtimeValue = 0;
        this.runtimePercent = '0';
        this.downtimeValue = 0;
        this.downtimePercent = '0';
        this.standByValue  = 0
        this.standByPercent = 0

        console.log('sameCheck',diff);

        if(!sameCheck && !(diff == 0)) {
            this.DateError = "Start date cannot greater than end date."
            return ;
        }

        // if (diff > 19) {
        //     this.DateError = "The difference of Start Date and End Date should be less then 20 days."
        // } else if (/* diff == 0 ||  */diff < 0) {
        //     this.DateError = "Please select valid Start Date and End Date."
        // }
        // else {
            this.DateError = null;
            // this.startLoading();
            if (this.up_downChart) {
                console.log("chart available");
                this.up_downChart.dispose();
            }else{
                console.log("not available");
            }
            this.apiService.getAll('asset/getAssetAvaiabilityGraph?rcpId='+assetData.Rcpid+'&assetId=' + assetData.assetId + (start && end ? "&fromDate=" + moment(start).format('YYYY-MM-DD') + "&toDate=" + moment(end).format('YYYY-MM-DD') : '')).subscribe(
                data => {

                    let dataArray = [];

                    let category = [];
                    let dataset = []
                    let Faulted = []
                    let StandBy = []
                    let sumOfValue = 0;
                    let numOfDays = 0;

                    let responseList = data.responseList ? data.responseList : [];
                    numOfDays = data.responseList ? data.responseList.length : 0;
                    // if(data.responseList){
                    $("#graphModalAssetAvail").modal('show');
                    // }

                    let totalDownTime = 0
                    let upTime = 0
                    let standByTime = 0

                    responseList.map(function (value, index) {
                        let obj = data.responseList[index]
                        obj.dateVal = value.label
                        sumOfValue = sumOfValue + value.value;
                        obj.label = value.label.slice(5);
                        dataArray.push(obj)
                        category.push({ label: value.label })
                        dataset.push({ value: value.uptime })
                        Faulted.push({ value: value.downtime })
                        StandBy.push({ value: value.standByTime })
                        upTime = upTime + value.uptime
                        totalDownTime = totalDownTime + value.downtime
                        standByTime = standByTime + value.standBytime
                    });

                    if(numOfDays) {

                        let RTP = (upTime/(1440 * numOfDays)) * 100;
                        let DTP = (totalDownTime/(1440 * numOfDays)) * 100;
                        let SBTP = (standByTime/(1440 * numOfDays)) * 100;
                        console.log(RTP.toFixed(2), DTP.toFixed(2),SBTP.toFixed(2), '--------------------------------------------------------------------pervent',upTime,totalDownTime,standByTime, "++++", sumOfValue);
                        
                        this.runtimeValue = upTime;
                        // this.runtimePercent = //(sumOfValue/(1440 * numOfDays)) * 100;
                        this.runtimePercent = RTP.toFixed(2)//this.runtimePercent.toFixed(2);
    
                        this.downtimeValue = totalDownTime//(1440 * numOfDays) - sumOfValue;
                        // this.downtimePercent = (this.downtimeValue/(1440 * numOfDays)) * 100;
                        this.downtimePercent = DTP.toFixed(2)//this.downtimePercent.toFixed(2);

                        this.standByValue  = standByTime
                        this.standByPercent = SBTP.toFixed(2)
                    }
                    // console.log('this.runtimeValue======',this.runtimeValue,'-',this.runtimePercent,'this.downtimeValue-------',this.downtimeValue,'-',this.downtimePercent)
                    // categories: [
                    //     {
                    //       category: []
                    //     }
                    //   ],
                    //   dataset: [
                    //     {
                    //       seriesname: "Male",
                    //       data: []
                    //     }
                    //   ]


                    setTimeout(() => {
                        // this.dataSource.data = dataArray//data.responseList;

                        this.data.categories = category
                        this.data.dataset[0].data = dataset


                     /*    this.dataSource = {
                            chart: {
                                caption: start && end ? (start + " to " + end) : '',
                                // numVisiblePlot: "15",

                                // "bgColor":"#208000,#dc133c",
                                // bgRatio :"100,0", 
                                // bgAlpha:"50,10",
                                // canvasBgColor:"#208000,#dc133c",
                                usePlotGradientColor: 0,
                                scrollheight: "12",
                                showvalues: "1",
                                numvisibleplot: "20",
                                decimals: "2",
                                stack100percent: "1",
                                // "exportEnabled": "1",
                                valuefontcolor: "#FFFFFF",
                                plottooltext: this.showAssetName + " has  $dataValue $seriesName Minutes (<b>$percentValue</b>) on $label",
                                dataEmptyMessage: 'No data available',
                                theme: "fusion"
                            },
                            categories: [
                                {
                                    category: category
                                }
                            ],
                            dataset: [
                                {
                                    seriesname: "Running",
                                    data: dataset,
                                    color: "#208000"
                                },
                                {
                                    seriesname: "Faulted",
                                    data: Faulted,
                                    color: "#DC133C"
                                }
                            ]
                        }; */

                    }, 100);

                    let self = this
                    //getting data for stacked cluster chart
                    let chart = am4core.create("assetsChart", am4charts.XYChart); 
                    let indicator;
                    function showIndicator() {
                        indicator = chart.tooltipContainer.createChild(am4core.Container);
                        indicator.background.fill = am4core.color("#fff");
                        indicator.background.fillOpacity = 0.8;
                        indicator.width = am4core.percent(100);
                        indicator.height = am4core.percent(100);

                        let indicatorLabel = indicator.createChild(am4core.Label);
                        indicatorLabel.text = "Loading Graph...";
                        indicatorLabel.align = "center";
                        indicatorLabel.valign = "middle";
                        indicatorLabel.fontSize = 24;
                    }

                    showIndicator(); 

                    function hideIndicator() {
                        indicator.hide();
                        // clearInterval(indicatorInterval);
                    }     
                    setTimeout(() => {
                        hideIndicator();
                    }, responseList.length <100 ? 2000 : responseList.length>400 ? 7000 : 5000);                
                    setTimeout(() => {
                        responseList.map(function (value, index) {
                            let obj = {
                                date:moment(value.dateVal,'YYYY-MM-DD').format('MM-DD-YY'),
                                "category": value.label,
                                "running": value.uptime ,
                                "faulted": ( value.downtime),
                                "standby": (value.standBytime)
                            }
                            chart.data.push(obj);
                        });
                        console.log("clusterStackChartData", chart.data)
                        

                        // chart.data = clusterStackChartData;
                        // Create axes                       
                        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "category";
                        // categoryAxis.title.text = "Local country offices";
                        categoryAxis.renderer.grid.template.location = 0;                      
                        // categoryAxis.renderer.minGridDistance = 20;

                       


                        // categoryAxis.renderer.cellStartLocation = 0.1;
                        // categoryAxis.renderer.cellEndLocation = 0.9;

                        // labelBullet.label.text = "{valueY.percent}%";
                        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.min = 0;
                        
                        // valueAxis.renderer.minGridDistance = ;
                        valueAxis.max = 1440;
                        valueAxis.strictMinMax = true; 
                        valueAxis.numberFormatter = new am4core.NumberFormatter();
                        valueAxis.numberFormatter.numberFormat = "#,###.##";
                        // valueAxis.title.text = "Expenditure (M)";

                        valueAxis.renderer.grid.template.disabled = true;
                        valueAxis.renderer.labels.template.disabled = true;

                        function createGrid(value) {
                            let range = valueAxis.axisRanges.create();
                            range.value = value;
                            range.label.text = "{value}";
                        }

                        // Create series
                        function createSeries(field, name, stacked) {
                            let series = chart.series.push(new am4charts.ColumnSeries());
                            series.dataFields.valueY = field;
                            series.dataFields.categoryX = "category";
                            series.name = name;
                            // series.calculatePercent = true;
                            series.columns.template.fill = field == 'running' ? am4core.color("#358615") :(field == 'faulted' ? am4core.color("#dc2342") : am4core.color("#206cb7"));
                            // series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
                            // CDN Asset1 has 1440 Minutes of Running on 2020-09-07

                          /*   series.columns.template.tooltipText = self.showAssetName + " has {categoryX} Minutes ([bold]{valueY}[/]) on [bold]{name}[/]" */

                          series.columns.template.tooltipText = "[bold]{date}[/] - "+self.showAssetName+" has {valueY} minutes [bold]{name}[/]."// self.showAssetName + " has {valueY} Minutes of [bold]{name}[/] on [bold]{categoryX}{date}[/]"
                            
                            //+ "[bold]{name}[/]\n[font-size:14px]{categoryX}: ({valueY})";//[bold]{valueY.percent}%[/] 
                            series.cursorTooltipEnabled = false;
                            series.stacked = true;
                            series.columns.template.width = am4core.percent(90);
                            series.legendSettings.itemValueText = "{valueY.percent}";
                            // series.sequencedInterpolation = true;
                            // Add label
                            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                            // labelBullet.label.text = "{valueY.percent}%";
                            labelBullet.locationY = 0.5;
                            labelBullet.label.fill = am4core.color("#fff");

                            return series;
                        }

                        // createSeries("europe", "Europe", false);
                        // createSeries("namerica", "North America", true);
                        // createSeries("asia", "Asia", false);
                        // createSeries("lamerica", "Latin America", true);
                        // createSeries("meast", "Middle East", true);
                        // createSeries("africa", "Africa", true);
                        let series1 = createSeries("running", "Running", true);
                        createSeries("faulted", "Faulted", true);
                        createSeries("standby", "Stand by", true);
                        // chart.scrollbarX.series.push(series1);
                        // createGrid(0);                          
                        responseList.map(function (value, index) {
                            createGrid(360 * index);
                        });
                        // createGrid(50);
                        // createGrid(75);
                        // createGrid(100);
                        chart.cursor = new am4charts.XYCursor();
                        valueAxis.cursorTooltipEnabled = false;
                        // chart.scrollbarX = new am4charts.XYChartScrollbar();
                        chart.scrollbarX = new am4core.Scrollbar();
                        chart.scrollbarX.parent = chart.topAxesContainer;
                        chart.scrollbarX.thumb.minWidth = 50;
                        
                        // Zoom events
                        categoryAxis.events.on("startchanged", categoryAxisZoomed);
                        categoryAxis.events.on("endchanged", categoryAxisZoomed);
                        function categoryAxisZoomed(ev) {
                        var axis = ev.target;
                        var start = axis.getPositionLabel(axis.start);
                        var end = axis.getPositionLabel(axis.end);
                        // console.log("New range: " + start + " -- " + end);
                        }

                        // Add legend
                        chart.legend = new am4charts.Legend();
                       /*  chart.legend.valueLabels.template.align = "left";
                        chart.legend.valueLabels.template.textAlign = "start";  */
                        let markerTemplate = chart.legend.markers.template;
                        markerTemplate.marginLeft = 0;
                        // markerTemplate.marginRight = -50;
                        markerTemplate.paddingLeft = 0;
                        markerTemplate.paddingRight = 0;

                        if (responseList.length == 0) {
                            let indicator = chart.tooltipContainer.createChild(am4core.Container);
                            indicator.background.fill = am4core.color("#fff");
                            indicator.background.fillOpacity = 0.8;
                            indicator.width = am4core.percent(100);
                            indicator.height = am4core.percent(100);
    
                            var indicatorLabel = indicator.createChild(am4core.Label);
                            indicatorLabel.text = "No Data Available";
                            indicatorLabel.align = "center";
                            indicatorLabel.valign = "middle";
                            indicatorLabel.fontSize = 20;
                        }
                     self.up_downChart = chart;
                    }, 1000);


                    // this.dataSource  = {
                    //     "chart": {
                    //         "yaxisname": 'Asset Availability (in minutes)',
                    //         "rotatelabels": "1",
                    //         "anchorradius": "5",
                    //         "showhovereffect": "1",
                    //         "plottooltext": "Asset Availability in $label is <b>$dataValue</b>",
                    //         "setadaptiveymin": "1",
                    //         "theme": "fusion",
                    //         "anchorbgcolor": "#0193cf",
                    //         "palettecolors": "#0193cf",
                    //         "formatNumberScale": "0",
                    //         "xFormatNumberScale": "0",
                    //         "FormatNumber": "0",
                    //         "showvalues": "0",
                    //         },
                    //     data:dataArray
                    // };
                    this.showAssetName = assetData.assetNameUnit;
                    // console.log(this.chartInstance);

                    //   this.chartInstance.options.dataSource.data = data.responseList;
                    // Render
                    //   this.chartInstance.render();


                }
            );
        // }

    }

    // Not In Use Now
    leftArrowClick() {
    }

    // Not In Use Now
    rightArrowClick() {
    }

    startLoading() {
        this.loading.start();
    }

    stopLoading() {
        this.loading.complete();
    }
}
