import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-customermenu',
  templateUrl: './customer-menu.component.html',
  styleUrls: ['./customer-menu.component.css']
})
export class CustomerMenuComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId : any = 0;
  permissionData:any=[];
  breadcrumbObj: any;

  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService) {
    this.permissionData = ApiService.permission;
   }

  DateFormat(startDate){
      if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
      }else{
        return "";    
      }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
        if(params['id']){
        this.customerId = params['id'];
        localStorage.setItem("CustomerID", this.customerId);

      }
    }); 
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          // this.urlCustomerId = params.cus_id;
      })
  }
}
