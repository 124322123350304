import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AlertService } from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3: any, moment: any;
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AddUserComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('imageUrlName') imageUrlName: ElementRef;

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  isGlobalUser: any = JSON.parse(localStorage.getItem("userData")).isGlobalUser;
  isCusDisable: any = false;
  isTreeDisable: any = false;
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tiersId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  newUserId: number = 0;
  holdFlag: number = 0;
  roleList: any = [];
  roles: any = [];
  customerList: any = [];
  tierList: any = [];
  tierListData: any = [];
  dataAdapter: any = [];
  commMode: number = 0;

  source: any = {};
  data: any = [];
  tierArray: any = [];
  records: any = [];
  roleIds: any = [];
  imageName: any = {};
  imageUrl: any = "assets/img/upload-img.png";
  imgBasePath: any = "";

  phoneCarrierList: any = [];
  checkCustomerArray: any = [];
  holdcheckTreeArray: any = [];
  disableTreeArray: any = [];
  dropdownSettings: any = {};
  checkTreeArray: any = [];
  selectedItems: any = [];
  customerArray: any = [];
  clientDomain: any = null
  FormObject: any = new FormData();

  cnfrmPasswordError: any = '';
  passwordError: any = 'Password is required';
  ifClient: any = true;
  customerValue: any;
  isAllCust: any;
  isAllCustomerSelected: any;

  PassInfo: any = `
  1. Password must be at least 8 characters.<br>
  2. Password must contain at least 1 numeric character.<br>
  3. Password must contain at least 1 uppercase character.<br>
  4. Password must contain at least 1 special character !@#/\$%^&*().<br>
  5. Password cannot contain spaces.<br>`;

  PassInfoToolTip: any = `
  1. Password must be at least 8 characters.
  2. Password must contain at least 1 numeric character.
  3. Password must contain at least 1 uppercase character.
  4. Password must contain at least 1 special character !@#/\$%^&*().
  5. Password cannot contain spaces.`;

  user: any = {
    "title": "",
    "firstName": "",
    "middleName": "",
    "secondaryPhoneNumber": "",
    "lastName": "",
    "phoneCarrier": "Verizon",
    "secondaryPhoneCarrier": "",
    "note": "",
    "login": "",
    "password": "",
    "phonePin": "",
    "isActive": false,
    "isAppUser": true,
    "isGlobalUser": false,
    "communicationMode": "",
    "userType": "Client",
    "client": { "clientId": this.clientId },
    "emailId": "",
    "phoneNumber": "",
    "customers": [],
    "file": '',
    'newPassword': '',
    'cnfrmPassword': '',
    'OldNewpassword': ''
  };

  comm: any = { "Email": false, "SMS": false, "IVRS": false, "Call": false };
  clientCode: any = '';
  defaultSelect: any = 'client';
  permissionData: any = [];
  imageError = "";
  showGlobalUser = false;
  isAllCustTier: any = false;
  imageTitle = "";
  isCustTreeDisable = false
  SelectCustomerError = false
  checkEmailDomain: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private ck: CookieService,
    private alertService: AlertService,
    private formBuilder: FormBuilder) {
    this.permissionData = ApiService.permission;
  }

  DateFormat(startDate) {
    if (startDate) {
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    } else {
      return "";
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.getCustomerList();
    // this.getRoleList();
    // this.getTierList();
    this.getClientDomain();
    this.getPhoneCarrierList();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.newUserId = params['id'];
        // this.initialiseState(); // reset and set based on new parameter this time
      }
    });
    if (this.newUserId) {
      // this.getUserById();
        this.getRoleList();
    this.getTierList();
    } else {
      this.ClientORCustomer(this.defaultSelect)
    }


    let client = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : []

    // this.clientDomain = localStorage.getItem('domain') //client.clients ? (client.clients.length ? client.clients[0].emailDomain : '') : ''
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'customerId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      displayProp: 'name'
    };

    function setInputFilter(textbox, inputFilter) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        if (textbox) {
          textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
              this.oldValue = this.value;
              this.oldSelectionStart = this.selectionStart;
              this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
              this.value = this.oldValue;
              this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
          });
        }
      });
    }

    setInputFilter(document.getElementById("phoneNumber"), function (value) {
      return /^[0-9+]*$/i.test(value);
    });

    setInputFilter(document.getElementById("secondaryPhone"), function (value) {
      return /^[0-9+]*$/i.test(value);
    });
  }


  // Check if its Customer or Client
  ClientORCustomer(value) {
    if (value == 'client') {
      this.ifClient = true;
      this.user.userType = 'Client';

      setTimeout(() => {
        this.isAllCustomerSelected = true;
        this.selectedItems = this.customerList;
        // this.checkCustomerArray = this.customerList;
        this.onSelectAll(this.customerList)
        var obj = {
          "target":
            { 'checked': true }
        }
        // this.selectAllCustTiers(obj)
        this.isCusDisable = true;
        this.isAllCust = true;
        // this.isAllCustTier = true;

      }, 2000)

      for (var i = 0; i < this.roles.length; i++) {
        this.roles.splice(i, 1);
      }
      this.getTierList();

      this.getRoleList();

    } else {
      this.tierListData = []
      this.ifClient = false;
      this.user.userType = 'Customer';
      this.customerValue = '';
      this.checkCustomerArray = [];
      this.user.customers = this.checkCustomerArray;

      for (var i = 0; i < this.roles.length; i++) {
        this.roles.splice(i, 1);
      }
      this.isTreeDisable = true

      this.getCustomerRoleValue();
    }
  }


  // Select All Customers
  selectAllCustomers(e) {
    if (e.target.checked == true) {
      this.isAllCustomerSelected = true;
      this.selectedItems = this.customerList;
      this.isCusDisable = true;
    } else {
      this.isAllCustomerSelected = false;
      this.selectedItems = [];
      this.isCusDisable = false;
    }
  }

  // Select All Tiers
  selectAllCustTiers(e) {
    // console.log(e);

    if (e.target.checked == true) {
      // this.isAllCustTierSelected = true;
      this.checkTreeArray = [];
      // console.log(this.data,e);

      Object.keys(this.data).forEach(key => {
        // if (this.data[key].flag) {
        this.checkTreeArray.push(this.data[key]['tierId'].toString());
        // }
      });
      this.checkedCheckboxOnLoad()
      this.isTreeDisable = true;
    } else {
      // this.isAllCustomerSelected = false;
      this.isTreeDisable = false;
      // this.checkTreeArray = [];
      this.checkedCheckboxOnLoad()
      this.data.map((obj) => {

        let checkedItem = document.getElementById(obj.tierId);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem, false);
      })
      // this.treeItemDisable();
    }

  }


  /* Get Client Domain */
  getClientDomain() {
    this.checkEmailDomain = false;
    this.apiService.getAll('client/getEmailDomainbyClient?clientId=' + this.clientId).subscribe(
      data => {
        this.clientDomain = data.response ? data.response.emailDomain : '';
        if (this.permissionData['User-Create-Client-Global-User'] && (this.clientDomain == '' || this.clientDomain == null)) {
          this.checkEmailDomain = false;
        } else if (this.permissionData['User-Create-Client-Global-User'] && (this.clientDomain != '' && this.clientDomain != null)) {
          this.checkEmailDomain = true;
        }
        this.changeEmail();
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  changeEmail() {

    if (this.checkEmailDomain === true) {
      let domain = this.user.login ? (this.user.login.indexOf('@') >= 0 ? this.user.login.split('@') : this.user.login.split('@')) : this.user.login.split('@')
      if (domain.length > 1) {
        if (domain[1] && domain[1] == this.clientDomain) {
          this.showGlobalUser = true;
        }
        else {
          this.showGlobalUser = false;
        }

      }
      else {
        this.showGlobalUser = false;
      }
    }
  }

  initialiseState() {
    this.getUserById();
  }

  /* Handle tier tree chacked unchecked Event */
  CheckChange(event) {
    //console.log(event.args.checked,event.args.element.id,this.checkTreeArray)
    if (event.args.checked == true) {
      if ($('input[name="isGlobalUser"]:checked').length == 0) {
        var exist1 = this.holdcheckTreeArray.indexOf(event.args.element.id);
        if (exist1 == -1) {
          this.holdcheckTreeArray.push(event.args.element.id);
        }
      }

      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist == -1) {
        this.checkTreeArray.push(event.args.element.id);
        /*Code for selct sub child*/
        Object.keys(this.data).forEach(key => {
          if (this.data[key]['parentTierId']==event.args.element.id) {
            this.checkTreeArray.push(this.data[key]['tierId'].toString());
          }
        });
        this.checkedCheckboxOnLoad();
      }
      
    } else if (event.args.checked == false) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist != -1) {
        this.checkTreeArray.splice(exist, 1);
        /*Unchecked sub child*/
        var parentTierId = '' ;
        this.data.map((obj) => {
          if (obj.parentTierId==event.args.element.id) {
            let checkedItem = document.getElementById(obj.tierId);
            this.myTree.expandItem(checkedItem);
            this.myTree.checkItem(checkedItem, false);            
          }
          // if(obj.tierId==event.args.element.id){
          //   parentTierId= obj.parentTierId;
          // }
        })
        // if(parentTierId !='' ){
        //   var parentCheckedItem = document.getElementById(parentTierId);
        //   this.myTree.expandItem(parentCheckedItem);
        //   this.myTree.checkItem(parentCheckedItem, false);
        // }
      }

      if (this.holdFlag != 0) {
        var exist1 = this.holdcheckTreeArray.indexOf(event.args.element.id);
        if (exist1 != -1) {
          this.holdcheckTreeArray.splice(exist1, 1);
        }
      }
    }
    if (event.args.checked == null) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist != -1) {
        this.checkTreeArray.splice(exist, 1);
      }
    }
    // console.log(this.checkTreeArray)
  }

  /* On Item Select Customer */
  onItemSelect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if (exist == '-1') {
      this.checkCustomerArray.push(item.customerId.toString());
      this.user.customers = this.checkCustomerArray;
    }
    if (this.checkCustomerArray.length == this.customerList.length ) {
      this.isAllCust = true;
    }else{
      this.isAllCust = false;
    }
  }

  /* On Item DeSelect Customer */
  onItemDeselect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if (exist != '-1') {
      this.checkCustomerArray.splice(exist, 1);
    }
    this.user.customers = this.checkCustomerArray;
    if (this.checkCustomerArray.length == this.customerList.length ) {
      this.isAllCust = true;
    }else{
      this.isAllCust = false;
    }
  }

  /* On All Select Customer */
  onSelectAll(items: any) {
    this.isAllCust = true;
    this.checkCustomerArray = [];
    Object.keys(items).forEach(key => {
      this.checkCustomerArray.push(items[key]['customerId'].toString());
    });
    this.user.customers = this.checkCustomerArray;
    this.isAllCustomerSelected = true;
    // this.isCusDisable = true;
  }

  /* On All DeSelect Customer */
  onDeSelectAll() {
    this.isAllCust = false;
    this.checkCustomerArray = [];
    this.user.customers = this.checkCustomerArray;
  }

  /* Create Tier array */
  createTierArray() {
    this.tierArray = []

    if (this.defaultSelect == "customer") {
      Object.keys(this.data).forEach(key => {
        if (this.data[key].flag) {
          this.tierArray.push({ "tierId": this.data[key].tierId });
        }
      });
      this.user.tiers = this.tierArray
      this.FormObject.append("tiers", JSON.stringify(this.tierArray));
    } else {
      Object.keys(this.checkTreeArray).forEach(key => {
        if (this.checkTreeArray[key] != 0) {
          this.tierArray.push({ "tierId": this.checkTreeArray[key] });
        }
      });
      this.user.tiers = this.tierArray
      this.FormObject.append("tiers", JSON.stringify(this.tierArray));
    }


  };


  createCustomerArray() {
    this.customerArray = []
    Object.keys(this.selectedItems).forEach(key => {
      if (this.selectedItems[key] != 0) {
        // var exist = this.customerArray.indexOf(this.selectedItems[key]);
        // if(exist == '-1'){
        this.customerArray.push({ "customerId": this.selectedItems[key].customerId });
        // }
      }
    });
    this.user.customers = this.customerArray;
    this.FormObject.append("customers", JSON.stringify(this.customerArray));
  };

  /* check tree checkbox */
  checkedCheckboxOnLoad() {
    // console.log(this.checkTreeArray);

    Object.keys(this.checkTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.checkTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      this.myTree.checkItem(checkedItem, true);
    });
  }


  checkItemCommunication() {
    this.commMode = this.user.communicationMode;
    var number = this.user.communicationMode;
    if ((number - 8) >= 0) {
      number = number - 8;
      this.comm.Call = true;
    }
    if ((number - 4) >= 0) {
      number = number - 4;
      this.comm.IVRS = true;
    }
    if ((number - 2) >= 0) {
      number = number - 2;
      this.comm.SMS = true;
    }
    if ((number - 1) >= 0) {
      number = number - 1;
      this.comm.Email = true;
    }
  }

  /* Create communication logic */
  getCommMode(e) {
    if (e.target.name == 'emailId') {
      if (e.target.checked) {
        this.commMode = this.commMode + 1;
      } else {
        this.commMode = this.commMode - 1;
      }
    } else if (e.target.name == 'SMS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 2;
      } else {
        this.commMode = this.commMode - 2;
      }
    } else if (e.target.name == 'IVRS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 4;
      } else {
        this.commMode = this.commMode - 4;
      }
    } else if (e.target.name == 'Call') {
      if (e.target.checked) {
        this.commMode = this.commMode + 8;
      } else {
        this.commMode = this.commMode - 8;
      }
    }
    this.user.communicationMode = this.commMode;
    this.FormObject.append("communicationMode", this.commMode);
  }

  /* Create role array */
  updateRoleOptions(rlist, event) {
    if (event.target.checked == true) {
      this.roles.push({ "roleId": rlist.roleId });
    } else {
      for (var i = 0; i < this.roles.length; i++) {
        if (this.roles[i].roleId == rlist.roleId) {
          this.roles.splice(i, 1);
        }
      }
    }
    this.user.roles = this.roles;
    this.FormObject.append("roles", JSON.stringify(this.roles));
  }

  treeItemDisable() {
    //  console.log(this.disableTreeArray);
    if (this.defaultSelect == 'customer') {
      // this.myTree.disableItem(checkedItem);
    }
    Object.keys(this.disableTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.disableTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      // If permission is not there for updating tier than disable the tier item
      if (this.defaultSelect == 'customer') {
        // console.log("-----------");

        this.myTree.disableItem(checkedItem);
      }
    });
  }

  // Handel Global User
  isGlobalUserChange(event) {
    if (event.target.checked == true) {
      if (this.defaultSelect == 'client') {
        this.isTreeDisable = true;
        this.isCusDisable = true;
        this.selectedItems = [];
        this.isAllCustTier = true;
        this.isAllCust = true;
        // $('#customer .multiselect-item-checkbox input').prop("checked", true);
        let customerUser = [];
        Object.keys(this.customerList).forEach(key => {
          this.checkCustomerArray.push(this.customerList[key]['customerId'].toString());
          customerUser.push({ customerId: this.customerList[key]['customerId'], name: this.customerList[key]['name'] })
        });
        this.user.customers = this.checkCustomerArray;

        this.selectedItems = customerUser;

        Object.keys(this.tierListData).forEach(key => {
          let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
          this.myTree.expandItem(checkedItem);
          this.myTree.checkItem(checkedItem, true);
        });

        setTimeout(function () {
          $('#customer .multiselect-dropdown .dropdown-list .multiselect-item-checkbox input').attr('disabled', 'disabled');
        }, 1000);
      }
      else {
        Object.keys(this.tierListData).forEach(key => {
          let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
          this.myTree.expandItem(checkedItem);
          this.myTree.checkItem(checkedItem, true);
        });
      }

    }
    else {
      if (this.defaultSelect == 'client') {
        Object.keys(this.tierListData).forEach(key => {
          let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
          this.myTree.expandItem(checkedItem);
          this.myTree.checkItem(checkedItem, false);
        });
        this.isAllCustTier = false;

        // Object.keys(this.holdcheckTreeArray).forEach(key=> {
        //   let checkedItem = document.getElementById(this.holdcheckTreeArray[key]);
        //   this.myTree.expandItem(checkedItem);
        //   this.myTree.checkItem(checkedItem,true);
        // });

        this.user.customers = this.checkCustomerArray = [];
        this.isTreeDisable = false;

        // setTimeout(() => {
        if (this.permissionData['User-Update-Customer']) {
          // this.selectedItems = []
          // this.isCusDisable = false;
          // this.isAllCust = false;
          // setTimeout(function(){
          //   $('#customer .multiselect-dropdown .dropdown-list .multiselect-item-checkbox input').removeAttr('disabled');
          //   $('#customer .multiselect-dropdown .dropdown-list .multiselect-item-checkbox input').prop("checked", false);
          //   $('#customer .multiselect-dropdown .dropdown-list .item1 .multiselect-item-checkbox input').prop("checked", false);
          // }, 1000);
        }
        // }, 1000);
      } else {
        Object.keys(this.tierListData).forEach(key => {
          let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
          this.myTree.expandItem(checkedItem);
          this.myTree.checkItem(checkedItem, false);
        });
        this.isAllCustTier = false;

        // Object.keys(this.holdcheckTreeArray).forEach(key=> {
        //   let checkedItem = document.getElementById(this.holdcheckTreeArray[key]);
        //   this.myTree.expandItem(checkedItem);
        //   this.myTree.checkItem(checkedItem,true);
        // });

        this.user.customers = this.checkCustomerArray = [];
        this.isTreeDisable = false;
      }
    }

  }

  /* Get tier list */
  getTierList() {
    this.apiService.getAll('tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId + '&section=editUser').subscribe(
      data => {
      /*   if (this.newUserId) {
          this.getUserById();
        } else {
          // this.ClientORCustomer(this.defaultSelect)
        } */
        if (data.statusCode == 100) {
          this.tierListData = data.responseList ? data.responseList : [];
          this.data = this.tierListData;
          // Check for Flag and Disable the Tier Checkbox
          Object.keys(this.data).forEach(key => {
            if (this.data[key]['flag'] == false) {
              this.disableTreeArray.push(this.data[key]['tierId']);
            }
          });
          this.source = {
            datatype: 'json',
            datafields: [
              { name: 'tierId' },
              { name: 'parentTierId' },
              { name: 'tierName' },
            ],
            id: 'tierId',
            localdata: this.data
          };

          this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
          var self = this;
          // console.log(this.disableTreeArray);
          // if(this.user.tiers && this.user.tiers.length === this.tierListData.length){
          //   // this.isAllCustTier  = true;
          //   this.isTreeDisable  = true; 
          // }
          setTimeout(function () {
            self.treeItemDisable();
          }, 1000);
        } else {
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
        if (this.newUserId) {
          this.getUserById();
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        if (this.newUserId) {
          this.getUserById();
        } else {
          this.ClientORCustomer(this.defaultSelect)
        }
      }
    );
  }

  /***************************
   Upload Image On Change
  **************************/
  onFileChanged(event) {
    this.imageTitle = "";
    this.imageError = "";
    // this.imageName = file;
    // let imageInput:any = document.getElementById('imageInput');
    // var isValid = /\.jpe?g$/i.test(imageInput.value);
    // if (!isValid) {
    //   alert('Only jpg files allowed!');
    //   return;
    // }
    const file = event.target.files;
    const fileType = file[0]['type'];
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    // invalid file type code goes here.
    if (!validImageTypes.includes(fileType)) {
      this.imageUrl = "assets/img/upload-img.png";
      this.imageName = null;
      this.imageTitle = "";
      this.imageError = "Please select valid image type such as jpg, jpeg, png"; return;
    }

    if (this.imageError == "") {
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          if (img.width <= 300 && img.height <= 300) {
            this.imageUrl = reader.result as string;
            this.imageName = file;
            this.imageTitle = file[0]['name'];
          }
          else {
            this.imageUrl = "assets/img/upload-img.png";
            this.imageName = null;
            this.imageTitle = "";
            this.imageError = "Image Size should be less than 300X300"
            return;
          }
        };
      }
    }

    // if (this.userId != 0) { }
    // setTimeout(() => {
    //   this.imageError = ''
    // }, 6000);
  }

  removeFileImage(){
        // clear file value after opearation 
        // setTimeout(() => {
          this.imageUrlName.nativeElement.value = '';
          this.imageTitle = ""
        // }, 2500);
  }

  removeBrandingImage() {
    Swal({
      title: 'Are you sure?',
      text: 'You want to remove Image.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        if(this.newUserId ) {
          this.apiService.delete("uploadImage/removeImage?section=1&sectionId=" + this.newUserId, '').subscribe(
            data => {
              if (data.statusCode == 100) {
                this.alertService.sweetMessage('success', 'Image removed successfully');
                // if(section == 6) {
                this.imageUrl = "assets/img/upload-img.png";
                // }
              } else {
                this.alertService.sweetMessage('error', data.responseMessage);
              }
            },
            error => {
              this.alertService.sweetMessage('error', "Something went wrong, please try again ");
            }
          );
        }else {
          this.alertService.sweetMessage('success', 'Image removed successfully');
          // if(section == 6) {
          this.imageUrl = "assets/img/upload-img.png";
        }
      }
    })
  }

  // function to save the image
  imageSave(newUserId) {
    if (this.imageError == "") {
      const uploadData = new FormData();
      if (this.imageName.length) {
        uploadData.append('file', this.imageName[0], this.imageName[0].name);
        // this.apiService.create('uploadImage/uploadImage?section=2&sectionId=' + this.customerId, uploadData).subscribe(
        // uploadImage/uploadImage?section=1&sectionId=4
        this.apiService.create('uploadImage/uploadImage?section=1&sectionId=' + newUserId, uploadData).subscribe(
          data => {
            if (data.statusCode == 100) {
              // this.getCustomerById();
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    }
  }


  hideError() {
    $("#passerror").css("display", "none");
  }


  getCustomerTierValue(custId) {
    // this.myTree.destroy();
    this.apiService.getAll('tier/getAllTierByCustomer?customerId=' + custId + '&clientId=' + this.clientId)
      .subscribe(
        data => {
          this.disableTreeArray = []
          if (data.statusCode == 100) {
            // this.records = data.responseList;
            this.tierListData = data.responseList ? data.responseList : [];
            this.data = this.tierListData;
            let arr = []
            Object.keys(this.data).forEach(key => {
              if (this.data[key]['flag'] == false) {
                this.disableTreeArray.push(this.data[key]['tierId']);
              } else {
                arr.push(this.data[key]['tierId'].toString());
              }
            });
            this.checkTreeArray = arr
            // console.log(this.checkTreeArray);
            this.checkedCheckboxOnLoad();
            var obj = {
              "target":
                { 'checked': true }
            }

            // this.selectAllCustTiers()

            this.source = {
              datatype: 'json',
              datafields: [{ name: 'tierId' },
              { name: 'parentTierId' },
              { name: 'tierName' },],
              id: 'tierId',
              localdata: this.data
            };
            this.isCustTreeDisable = false;

            this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
            this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
            var self = this;
            setTimeout(function () {
              self.treeItemDisable();
              self.checkedCheckboxOnLoad();
              self.selectAllCustTiers(obj)
            }, 1000);
            this.isTreeDisable = false;
            setTimeout(() => {
              this.isTreeDisable = true;
              this.isCustTreeDisable = true;
            }, 2000);

            // console.log(this.disableTreeArray);
            this.isGlobalUserChange({ target: { checked: false } })
            if (this.permissionData['User-Update-Tier']) {

            }
            this.myTree.refresh();

          } else { this.alertService.sweetMessage('error', data.responseMessage); }
        },
        error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
  }


  getCustomerRoleValue() {
    this.apiService.getAll('role/getRoleByUser?isCustomerRole=true&clientId=' + this.clientId)
      .subscribe(
        data => {
          if (data.statusCode == 100) { this.roleList = data.responseList; }
          else { this.roleList = []; }
        },
        error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
  }



  /*****************
    Function to Add and update user
  ******************/
  addUser(data) {
 
    $('#err_msg').html("");
    this.SelectCustomerError = false

    if (!data.form.valid && (this.user.phoneNumber == this.user.secondaryPhoneNumber)) {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
    if (data.form.valid && (this.user.phoneNumber != this.user.secondaryPhoneNumber)) {
      if (this.user.userType == 'Client' || this.user.userType == 'client') {
        if (this.user.isGlobalUser == false) {
          this.createTierArray();
          this.createCustomerArray();
        }
        if (this.user.isGlobalUser) {
          delete this.user.customers;
          delete this.user.tiers;
        }
        delete this.user.customer;
        if (this.roles.length == 0) {
          //this.alertService.sweetMessage('error','please select at least one Role.');
          document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
          $('#role_err').css('display', 'inline-block');
          return;
        } else {
          $('#role_err').css('display', 'none');
        }
        let phoneCarrierVal = $("#phoneCarrier option:selected").val();
        this.user.phoneCarrier = phoneCarrierVal;
        // return
        if (this.newUserId == 0) {
          this.apiService.create('user/registerUser', this.user).subscribe(
            data => {
              if (data.statusCode == 100) {
                this.imageSave(data.user.userId)
                this.alertService.sweetMessage('success', 'User added successfully');
                this.router.navigate(['/user-list']);
              } else {
                this.tierArray = [];
                this.customerArray = [];
                //this.alertService.sweetMessage('error',data.responseMessage);
                $('#err_msg').html(data.responseMessage);
                $('#err_msg').css('display', 'block');
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        } else {
          this.apiService.update('user/updateUser', this.user).subscribe(
            data => {
              if (data.statusCode == 100) {
                this.imageSave(this.newUserId);
                this.alertService.sweetMessage('success', 'User updated successfully');
                this.router.navigate(['/user-list']);
              } else {
                this.alertService.sweetMessage('error', data.responseMessage);
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        }

      } else {
        /************************** 
          Add user if USER SELECT the Customer from Drop Down 
        **************************************/
        this.createTierArray();
        var custArray = []
        // custArray.push({"customerId":this.customerValue})
        // this.user.customers = custArray;
        if (!this.customerValue || this.roles.length == 0) {
          if (!this.customerValue) {
            this.SelectCustomerError = true
          }
          if (this.roles.length == 0) {
            //this.alertService.sweetMessage('error','please select at least one Role.');
            $('#role_err').css('display', 'inline-block');
            return;
          } else {
            $('#role_err').css('display', 'none');
          }
          return
        }
        this.user.customer = { "customerId": this.customerValue };
        delete this.user.customers;
        this.apiService.create('user/createCustomerUser', this.user).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.imageSave(data.user)
              this.alertService.sweetMessage('success', 'User added Successfully');
              this.router.navigate(['/user-list']);
            }
            else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    }
  }

  /* Get Phone Carrier List */
  getPhoneCarrierList() {
    this.apiService.getAll('user/getPhoneCarrierList').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.phoneCarrierList = data.responseList;
          this.phoneCarrierList.forEach(key => {
            if (key == 'Verizon') {
              setTimeout(function () {
                $('.' + key).attr('selected', 'true');
              }, 500);
            }
          });
        } else {
          this.phoneCarrierList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /* Get Role list */
  getRoleList() {
    var URL = 'role/getRoleByUser?clientId=' + this.clientId;

    if (this.customerId != null && this.customerId != '') {
      var customerString = '&customerId=' + this.customerId.toString();
      var URL = URL.concat(customerString);
    }
    if (this.tiersId != null && this.tiersId != '') {
      var tierString = '&tierId=' + this.tiersId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.roleList = data.responseList;
        } else {
          this.roleList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /* Get Customer list */
  getCustomerList() {
    var URL = 'customer/getCustomerByClient?clientId=' + this.clientId;
    if (this.customerId != null && this.customerId != '') {
      var customerString = '&customerId=' + this.customerId.toString();
      var URL = URL.concat(customerString);
    }
    if (this.tiersId != null && this.tiersId != '') {
      var tierString = '&tierId=' + this.tiersId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.customerList = data.responseList;
        } else {
          this.customerList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  // che cked
  getUserById() {
    this.apiService.getAll('user/getUserByUserId?userId=' + this.newUserId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.user = data.user;
          if (this.user.imageLocation != null) {
            this.imageUrl = this.apiService.imageBasePath + this.user.imageLocation;
            let imageArr = this.user.imageLocation.split('/');
            this.imageTitle = imageArr[1];
          }

          if (this.user.secondaryPhoneCarrier === null) {
            this.user.secondaryPhoneCarrier = "";
          }

          if (this.user.phoneCarrier === null) {
            this.user.phoneCarrier = "Verizon";
          }

          this.checkItemCommunication();
          this.checkTreeArray = [];
          if (this.user.tiers.length != 0) {
            Object.keys(this.user.tiers).forEach(key => {
              this.checkTreeArray.push(this.user.tiers[key]['tierId'].toString());
            });
          }

          var that = this;
          // setTimeout(function(){
          that.checkedCheckboxOnLoad();
          // },2000);

          this.selectedItems = this.user.customers;
          if (this.user.customers.length === this.customerList.length) {
            this.isCusDisable = true;
            this.isAllCust = true;

          }

          if (this.user.tiers.length == this.tierListData.length && this.user.tiers.length!=0) {
            this.isAllCustTier = true;
            this.isTreeDisable = true;
          }

          if (this.user.customers.length != 0) {
            Object.keys(this.user.customers).forEach(key => {
              var id = this.user.customers[key]['customerId'].toString();
              var exist = this.checkCustomerArray.indexOf(id);
              if (exist == '-1') {
                this.checkCustomerArray.push(id);
              }
            });
          }

          this.roles = data.user.roles;

          this.roleIds = _.map(this.user.roles, 'roleId');
          var self = this;
          _.map(this.user.roleIds, function (value, key) {
            self.user.roleIds.push({ roleId: value.id });
          });
          let domain = this.user.login ? (this.user.login.indexOf('@') >= 0 ? this.user.login.split('@') : this.user.login.split('@')) : this.user.login.split('@')

          if (domain.length > 0) {
            if (domain[1] && domain[1] == this.clientDomain) {
              this.showGlobalUser = true;
            } else {
              this.showGlobalUser = false;
            }
          } else {
            this.showGlobalUser = false;
          }
          if (this.user.isGlobalUser) {
            setTimeout(() => {
              this.isGlobalUserChange({ target: { checked: true } })
            }, 500);
          }

        } else { // this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
  }

  clearError() {
    $("#Uppasserror").css("display", "none");
  }

  updatePassword() {
    // var regx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var regx = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$");
    var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");

    if (regx.test(this.user.newPassword) == false) {
      this.passwordError = this.PassInfo;
      $("#Uppasserror").css("display", "block");
      $("#newpass").focus();
      return;
    }

    if (this.user.newPassword != this.user.cnfrmPassword) {
      this.cnfrmPasswordError = "Password does not match";
      $("#cnfrmPassErr").css("display", "block");
      $("#cnfrmpass").focus();
      return;
    }

    if (!this.newUserId || !this.user.OldNewpassword) {
      this.alertService.sweetMessage('error', "please fil required fields");
      return;
    }

    /* === base64 encry pass=== */
    var obj = {
      "userId": this.newUserId,
      "password": window.btoa(this.user.OldNewpassword),
      "newPassword": window.btoa(this.user.newPassword)
    }

    // var obj = {
    //   "userId": this.newUserId,
    //   "password": this.user.OldNewpassword ,
    //   "newPassword": this.user.newPassword
    //   }

    this.apiService.update('user/updatePassword', obj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.alertService.sweetMessage('success', 'Password Updated Successfully');
          this.user.OldNewpassword = '';
          this.user.newPassword = '';
          this.user.cnfrmPassword = '';
          this.logout();
          $('#closeModal').click();
        }
        else {
          this.alertService.sweetMessage('error', data.responseMessage);
          this.user.OldNewpassword = '';
          this.user.newPassword = '';
          this.user.cnfrmPassword = '';
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  resetModal() {
    this.user.OldNewpassword = '';
    this.user.newPassword = '';
    this.user.cnfrmPassword = '';
    $("#Uppasserror").css("display", "none");
  }

  logout() {
    this.apiService.logout('userauth/oauth/revokeToken').subscribe(
      data => {
        if (data.status == 200) {
          localStorage.clear();
          $("body").removeClass('body-smt-active');
          this.router.navigate(['/login']);
          this.ck.delete('islogin');
          document.cookie = "islogin=;"
          document.cookie = "islogin=;"
          this.ck.delete('refresh_token');
          this.ck.deleteAll()
        } else {
        }
      },
      error => {
        //this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


}
