import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  templateUrl: './roles.component.html'
})

export class RolesComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerName : any = localStorage.getItem("customerName");
  roleDatalist: any = [];
  dataTable: any;
  customerId:number=0;
  permissionData:any=[];
  clientCode : any = '';
  breadcrumbObj: any;
  HistoryList: any = [];
  permissionListAssign: any = [];

  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
  }

  // Function to format a date
  DateFormat(startDate){
    if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
        return "";
      }
  }

  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
        if(params['id']){
        this.customerId = params['id'];
      }

    });
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          // this.urlCustomerId = params.cus_id;
      })
    if(this.customerId){
      this.getRoleByCustomerList();
    }

$(document).on('hidden.bs.modal', function () {
  if($('.modal.show').length)
  {
    $('body').addClass('modal-open');
  }
});
  }

  /* Get Role By Customer list */
  getRoleByCustomerList(){
     this.apiService.getAll('customer/getRoleListByCustomerAndClient?customerId='+this.customerId+'&clientId='+this.clientId).subscribe(
        data => {
           if(data.statusCode == 100){
              this.roleDatalist = data.responseList;
              if ($.fn.DataTable.isDataTable('#custRole_table')){
                $('#custRole_table').DataTable().destroy();
              };
              this.chRef.detectChanges();
              const table: any = $('#custRole_table');
              this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [] }]});
            }else{
              this.roleDatalist = [];
              this.chRef.detectChanges();
              const table: any = $('#custRole_table');
              this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [] }]});
              /*this.alertService.sweetMessage('error',data.responseMessage); */
             }
          },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }

  deleteRoleActions(roleId){
     Swal({
        title: 'Are you sure?',
        text: "You want to delete this actions.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
          if (result.value) {
           this.apiService.delete('role/deleteRole?roleId='+roleId, "").subscribe(
           data => {
               if(data.statusCode == 100){
                this.getRoleByCustomerList();
                  Swal({
                      position: 'top-end',
                      type: 'success',
                      title: 'permissions Deleted Successfully',
                      showConfirmButton: false,
                      timer: 1500
                    }) ;
                }else{
                  this.alertService.sweetMessage('error',data.responseMessage);
                 }
              },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
        }
    })
  }

  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('role/getRoleHistory?roleId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

  setPermissonList(permissions){
    this.permissionListAssign = permissions;
  }

  replacePermissionName(permission){
    let str = ''
    if (permission.groupName) {
      let strName = permission.groupName;
      let Name = strName.replace(/-/g," ")
      str = str+Name
    }
    if (permission.permission) {
      let strName = permission.permission;
      let Name = strName.replace(/-/g," ")
      str = str + ' '+Name
    }    
    return str;
  }
}
