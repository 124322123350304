import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-leave-management',
  templateUrl: './leave-management.component.html',
  styleUrls: ['./leave-management.component.css']
})
export class LeaveManagementComponent implements OnInit {

  clientId: any   = localStorage.getItem("clientId");
  userId    : any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId    : any = (localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  dataTable : any;
  clientCode: any;
  leaveId   : any = 0;
  isApproved : any = false;
  LeaveList : any = [];
  LeaveListById : any = {
    user:{},
    replacementUser:{}
  };
  permissionData : any;
  userDropDown : any = [];
  userLeaveId : any = 0;
  isError : any = false;
  leaveData : any = {
    // "user"    : {"userId":this.userId},
    user    : {},
    "fromDate": "",//moment(),//.format("YYYY-MM-DD"),
    "toDate"  : "",//moment(),//.format("YYYY-MM-DD"),
    "remark"  : "",
    replacementUser:{}
  }
  userDropDownfrom :any = []
  userDropDownto :any = []
  dateError = ""
  toDateError = ''
  @ViewChild ('f') f:any;
  HistoryList: any = [];
  constructor(
    private router      : Router,
    private apiService  : ApiService ,
    private alertService: AlertService,
    private chRef       : ChangeDetectorRef
  ) {
      this.permissionData = ApiService.permission;
    }


  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    // this.getUserDropDown();
    this.changeDateRequest();
    this.getLeaveList();
    jQuery('.rst-date').datetimepicker({
      language: 'en',
      pickTime: false,
      pickDate: true,
      minDate: 0,
      inline: true,
      sideBySide: true
  }).on('changeDate', function(){
    this.dateError = "";
    this.toDateError = '';
    $('.bootstrap-datetimepicker-widget').hide();
    $(this).datepicker('hide');
  });
  }

  getUserDropDown(){
    // var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+'&isActive=true&isDeactivated=false';
    var URL = 'timeOffRequest/getTimeOffRequestUser?clientId='+this.clientId+'&sDate='+moment(this.leaveData.fromDate).format('YYYY-MM-DD')+'&eDate='+moment(this.leaveData.toDate).format('YYYY-MM-DD');
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
             this.userDropDown = data.responseList;
             this.userDropDownfrom = JSON.parse(JSON.stringify(this.userDropDown));
            //  this.userDropDownto = JSON.parse(JSON.stringify(this.userDropDown));
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

  changeDateRequest(){
    var fromDate  = $('#fromDate').val();
    var toDate    = $('#toDate').val();
    if (fromDate) {
      this.dateError = ""
      $('#fromDate').removeClass('is-invalid');
    }
    if (toDate) {
      $('#toDate').removeClass('is-invalid');
      this.toDateError = ""
    }
    console.log(moment(this.leaveData.toDate).format('YYYY-MM-DD'), moment(this.leaveData.fromDate).format('YYYY-MM-DD'), fromDate,toDate);
    if (fromDate && toDate) {
      setTimeout(() => { 
        this.getUserDropDown();
        this.getReplacementUserDropDown();
      }, 500);
    }
    // http://172.20.1.165:8764/api/v1/timeOffRequest/getTimeOffRequestUser?clientId=5&sDate=2019-10-22&eDate=2019-10-25
    // this.getUserDropDown()
  }

  getReplacementUserDropDown(){
    // var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+'&isActive=true&isDeactivated=false';
    var URL = 'timeOffRequest/getTimeOffReplacementUser?clientId='+this.clientId+'&sDate='+moment(this.leaveData.fromDate).format('YYYY-MM-DD')+'&eDate='+moment(this.leaveData.toDate).format('YYYY-MM-DD');

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
            // this.userDropDown = data.responseList;
            this.userDropDownto = JSON.parse(JSON.stringify(data.responseList))
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
      error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

// Get all Leave Applied List
  getLeaveList(){
    var URL = 'timeOffRequest/getTimeOffRequestList?userId='+this.userId;

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {

          this.LeaveList = data.responseList;

          if ( $.fn.DataTable.isDataTable('#timeOff_table') ) {
            $('#timeOff_table').DataTable().destroy();
          }

          this.chRef.detectChanges();
          const table: any = $('#timeOff_table');
          this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [5,6] }]});
        }
        else
          {
            this.LeaveList = [];

            if ( $.fn.DataTable.isDataTable('#timeOff_table') ) {
              $('#timeOff_table').DataTable().destroy();
            }

            this.chRef.detectChanges();
            const table: any = $('#timeOff_table');
            this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [5,6] }]});
            // this.alertService.sweetMessage('error', data.responseMessage);
          }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

  resetForm(){
    this.userLeaveId = 0;
    this.leaveId = 0;
    this.isApproved = false;
    this.f.reset()
    this.f.submitted = false;
    var note = $('#note').val('')
    // var fromDate = $('#fromDate').val(moment().format("YYYY-MM-DD"));
    // var toDate = $('#toDate').val(moment().format("YYYY-MM-DD"));
    var user = $('#userLeaveId').val('');
    var replacementUser = $('#userLeaveIdto').val('');
    this.dateError = '';
    this.toDateError = '';
    this.leaveData   = {
      "fromDate": "",//moment().format("YYYY-MM-DD"),
      "toDate": "",//moment().format("YYYY-MM-DD"),
      "user": {"userId":''},
      "remark": "",
      replacementUser:{}
    }
  }

/*Get Leave Data By Id*/
  getDataByID(id){

    this.leaveId = id;
    var URL = 'timeOffRequest/getTimeOffRequest?requestId='+id;
    this.LeaveListById = {
      user:{},
      replacementUser:{}
    };
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
             let obj = data.response;
             if (data.response.replacementUser == null) {
               obj.replacementUser = {}
             }
             this.LeaveListById   = obj //data.response;

            //  this.leaveData = data.response;
             this.leaveData.user.userId  = this.LeaveListById.user.userId;
            //  this.leaveData.user.userId  = this.userId;
             this.isApproved = this.LeaveListById.isApproved;
             if(this.isApproved == true){
               // $('.rst-date').attr('readonly','readonly');
               // $(".rst-date").datepicker({'disable' : 'true'});
               // $(".datepicker").disableSelection = true;
               // $('.rst-date').datepicker({"options":{"pickDate" : 'false'}});
               // $('.rst-date').datepicker("option", "maxDate", -2);
             }
            //  var note      = $('#note').val(this.LeaveListById.remark)
            //  var fromDate  = $('#fromDate').val(this.LeaveListById.fromDate);
            //  var toDate    = $('#toDate').val(this.LeaveListById.toDate);
            this.changeDateRequest()
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

/* Delete Leave Data By Id*/
  RemoveLeave(id){

    this.leaveId = id;
    var URL = 'timeOffRequest/deleteTimeOffRequest?timeOffRequestId='+id;

    this.apiService.delete(URL, "").subscribe(
      data => {
        if (data.statusCode == 100) {
          this.getLeaveList();
          this.alertService.sweetMessage('success', 'Leave deleted successfully');
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

  applyLeave(formValue){
    this.dateError = ''
    this.toDateError = ''
    var fromDate  = $('#fromDate').val();
    var toDate    = $('#toDate').val();
    // console.log(this.leaveData);
    
    if(moment(toDate).diff(moment(fromDate)) < 0){
      this.dateError = "From Date should not be greater than To Date."
      return;
    }
    if(!fromDate){
      this.dateError = "From Date is required."
      
    }
    if(!toDate){
      this.toDateError = "To Date is required."
      
    }

    if (!formValue.invalid) {
      
    if(this.leaveId == 0){

      var URL = 'timeOffRequest/createTimeOffRequest';

      var note      = $('#note').val()
      var fromDate  = $('#fromDate').val();
      var toDate    = $('#toDate').val();

      this.leaveData.fromDate = fromDate;
      this.leaveData.toDate   = toDate;
      this.leaveData.remark   = note;
      // console.log(this.leaveData, fromDate, toDate, note);

      
      this.apiService.create(URL, this.leaveData).subscribe(
        data => {
          if (data.statusCode == 100) {
              $('#closepopup').click();
              this.getLeaveList();
              this.alertService.sweetMessage('success', 'Leave apply successfully');
          }
          else
            { this.alertService.sweetMessage('error', data.responseMessage); }
        },
         error =>
         { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    }else {

      var URL = 'timeOffRequest/updateTimeOffRequest';

      var note      = $('#note').val()
      var fromDate  = $('#fromDate').val();
      var toDate    = $('#toDate').val();

      this.leaveData.fromDate = fromDate;
      this.leaveData.toDate   = toDate;
      this.leaveData.remark   = note;
      this.leaveData.timeOffRequestId   = this.leaveId;

      this.apiService.update(URL, this.leaveData).subscribe(
        data => {
          if (data.statusCode == 100) {
              $('#closepopup').click();
              this.getLeaveList();
              this.alertService.sweetMessage('success', 'Leave Updated successfully');
          }
          else
            { this.alertService.sweetMessage('error', data.responseMessage); }
        },
         error =>
         { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    }
    }
    
  }

  approveLeave(timeofid,uid){


    var URl = '/timeOffRequest/leaveApproved';
    var obj = {
      "timeOffRequestId":timeofid,
      "user":{ "userId":uid },
      "isApproved":true
    }

    Swal({
     title: 'Are you sure?',
     text: "You want to approve this leave.",
     type: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#3085d6',
     cancelButtonColor: '#d33',
     confirmButtonText: 'Yes, approve it!'
    }).then((result) => {
    if (result.value) {
      this.apiService.update('/timeOffRequest/leaveApproved', obj).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.getLeaveList();
            this.alertService.sweetMessage('success', 'Leave Approved successfully');
          }
          else
            { this.alertService.sweetMessage('error', data.responseMessage); }
        },
        error =>
          { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    }
  })
}


changeUserFron(){
  let userID = this.leaveData.user.userId
  this.userDropDownto = JSON.parse(JSON.stringify(this.userDropDown))
  let findIndex = null
  let index = this.userDropDownto.find(function(obj,index){
    // console.log(obj, index);
      
    if (obj.userId == userID) {
      findIndex = index;
      return
    }
    
  })
  // console.log(index , "------------------------------");
  this.userDropDownto.splice(findIndex,1)
}

getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('timeOffRequest/getTimeOffRequestHistory?requestId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}
}
