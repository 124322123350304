import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.css']
})
export class AssetManagementComponent implements OnInit {

    @ViewChild('updateAssetForm') updateAssetForm:any;

    public labels: any = {
      previousLabel: 'Previous',
      nextLabel: 'Next',
      screenReaderPaginationLabel: 'Pagination',
      screenReaderPageLabel: 'page',
      screenReaderCurrentLabel: `You're on page`
  };
  public maxSize: number = 7;

  public config: PaginationInstance = { id: 'server', itemsPerPage: 10, currentPage: 1, totalItems: 10 }
  tableSearchCon:any = ''
  columnSortOrder: any;
  HistoryList: any = [];
  assetUserList: any = [];
  onPageChange(number: number) {
    console.log(number);
    
    this.config.currentPage = number;
    this.getAllAssetList()
}
pageSizeChange(){
  this.config.currentPage = 1;;
  this.getAllAssetList()
}

searchInputChange(event){
  console.log(event.target.value, this.tableSearchCon);
  setTimeout(() => {
    if (this.tableSearchCon.length==0) {
      this.getAllAssetList()
    }
  }, 200);
}
SearchAssetTable(){
  this.config.currentPage = 1
  this.getAllAssetList()
}


    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
    tierId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
    assetDatalist: any = [];
    dataTable: any;
    assetId: any = 0;
    dropdownSettingsSite = {};
    dropdownSettingsState = {};
    dropdownSettingsStatus = {};
    search: any = '';
    site: any = '';
    county: any = '';
    status: any = '';
    numbers:any=[];
    checkStateArray: any = [];
    checkSiteArray: any = [];
    checkStatusArray: any = [];
    checkSiteArraylist:any = [];
    checkStateArraylist:any = [];
    checkStatusArraylist:any = [];
    countryId: number = 0;
    state: any = '';
    countryList: any = [];
    countyList: any = [];
    stateList: any = [];
    siteList: any = [];
    customerListData: any = [];
    notificationListData: any = [];
    shiftGroupListData: any = [];
    siteListData: any = [];
    dataForFilteringFrom:any=[];
    updateDataForAsset:any = {};
    userassetList:any=[];
    total:any=1;
    UserToAsset:any = {"createdTime":"","callSequence":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
    assetDataId : any;
    roleList:any=[];
    UserList:any=[];
    statusList = [{
        "status": "A",
        "statusName": "Alarm"
    }, {
        "status": "W",
        "statusName": "Warning"
    }, {
      "status": "N",
      "statusName": "Normal"
    }, {
        "status": "C",
        "statusName": "No Comm"
    }, {
        "status": "S",
        "statusName": "Suspended"
    }];
    assetData: any = {shiftGroup:{},notificationPlan:{},site:{},customer:{}};
    clientCode: any = '';
    permissionData: any = [];
    isShowCalendar : any = false;
    isShowCritical : any = false;
    assetIdForGraph : any = "";
    isShowCallOrder:any = false;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService ,
        private alertService: AlertService,
        private formatDateService: FormatDateService,
        private chRef: ChangeDetectorRef
        ) {
        this.permissionData = ApiService.permission;
    }


    ngOnInit() {
      $(document).on('hidden.bs.modal', function () {
        if($('.modal.show').length)
        {
          $('body').addClass('modal-open');
        }
      });
        this.clientCode = this.alertService.getClientCode();
        this.route.params.subscribe(params => {
          if (params['status']) {
              this.status = params['status'];
              this.assetIdForGraph = params['assetId'];
          }
        });

        this.getAllAssetList();
        this.getCountryList();
        this.getSiteManagementList();
        this.getCountyList();

        this.dropdownSettingsSite = {
            singleSelection: false,
            idField: 'siteId',
            textField: 'siteName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
        this.dropdownSettingsState = {
            singleSelection: false,
            idField: 'stateId',
            textField: 'stateName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
        this.dropdownSettingsStatus = {
            singleSelection: false,
            idField: 'status',
            textField: 'statusName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };

        // this.getAllNotificationList();
        this.getAllCustomerList();
        this.getAllShiftList();
        this.getAllSiteList();

    }

    // Function to format Date
    DateFormat(startDate, gmtOffset){
        return this.formatDateService.formatDate(startDate, gmtOffset);
    }

    /* On Item Select Status */
    onItemSelectStatus(item: any) {




        var exist = this.checkStatusArray.indexOf(item.status);
        if (exist == '-1') {
            this.checkStatusArray.push(item.status);
            this.checkStatusArraylist.push(item.status);
        }
    }

    /* On Item DeSelect Status */
    onItemDeselectStatus(item: any) {


        var exist = this.checkStatusArray.indexOf(item.status);
        if (exist != '-1') {
            this.checkStatusArray.splice(exist, 1);
            this.checkStatusArraylist.splice(exist, 1);
        }
    }

    /* On All Select Status */
    onSelectAllStatus(items: any) {

        Object.keys(items).forEach(key => {
            this.checkStatusArray.push(items[key]['status'].toString());
            this.checkStatusArraylist.push(items[key]['status'].toString());
        });
    }

    /* On All DeSelect Status */
    onDeSelectAllStatus() {
        this.checkStatusArray = [];
        this.checkStatusArraylist = [];
    }

    /* On Item Select State */
    onItemSelectState(item: any) {


        var exist = this.checkStateArray.indexOf(item.stateId);
        if (exist == '-1') {
            this.checkStateArray.push(item.stateId);
            this.checkStateArraylist.push(item.stateId);
        }
    }

    /* On Item DeSelect State */
    onItemDeselectState(item: any) {

		var exist = this.checkStateArray.indexOf(item.stateId);
        if (exist != '-1') {
            this.checkStateArray.splice(exist, 1);
            this.checkStateArraylist.splice(exist, 1);
        }
    }

    /* On All Select State */
    onSelectAllState(items: any) {
      this.checkStateArray = [];
      this.checkStateArraylist = [];
        Object.keys(items).forEach(key => {
            this.checkStateArray.push(items[key]['stateId'].toString());
            this.checkStateArraylist.push(items[key]['stateId'].toString());
        });
    }

    /* On All DeSelect State */
    onDeSelectAllState() {
        this.checkStateArray = [];
        this.checkStateArraylist = [];
    }

    /* On Item Select Site */
    onItemSelectSite(item: any) {

        var exist = this.checkSiteArray.indexOf(item.siteId);
        if (exist == '-1') {
            this.checkSiteArray.push(item.siteId);
            this.checkSiteArraylist.push(item.siteId);
        }
    }

    /* On Item DeSelect Site */
    onItemDeselectSite(item: any) {



        var exist = this.checkSiteArray.indexOf(item.siteId);
        if (exist != '-1') {
            this.checkSiteArray.splice(exist, 1);
            this.checkSiteArraylist.splice(exist, 1);
        }
    }

    /* On All Select Site */
    onSelectAllSite(items: any) {
      this.checkSiteArray = []
      this.checkSiteArraylist = [];
        Object.keys(items).forEach(key => {
            this.checkSiteArray.push(items[key]['siteId'].toString());
            this.checkSiteArraylist.push(items[key]['siteId'].toString());
        });
    }

    /* On All DeSelect Site */
    onDeSelectAllSite() {
        this.checkSiteArray = [];
        this.checkSiteArraylist = [];
    }

    /* On Search */
    seachRecord() {
        this.site = this.checkSiteArray.toString();
        this.state = this.checkStateArray.toString();
        this.status = this.checkStatusArray.toString();
        this.getAllAssetList();
    }

    /* Reset Search Record */
    resetSearch() {
        this.search = "";
        this.site = "";
        this.county = "";
        this.status = "";
        this.countryId = 0;
        this.state = "";
        this.checkSiteArray = [];
        this.checkStateArray = [];
        this.checkStatusArray = [];
        this.checkSiteArraylist = [];
        this.checkStateArraylist = [];
        this.checkStatusArraylist = [];
        this.getAllAssetList();
    }

    /* Get Site Management list */
    getSiteManagementList() {
        var URL = 'site/getSiteByClient?clientId=' + this.clientId + '&userId=' + this.userId+'&alphabateSortingOreder=1';

        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        if (this.tierId != null && this.tierId != '') {
            var tierString = '&tierId=' + this.tierId.toString();
            var URL = URL.concat(tierString);
        }
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.siteList = data.responseList;
                } else {
                    this.siteList = [];
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get state list by country id */
    getStateListByCountryId(countryId: any) {
      if (countryId != 0) {

            this.apiService.getAll('state/getStateList/' + countryId).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.stateList = data.responseList;
                    } else {
                        this.stateList = [];
                    }
                },
                error =>{
                  this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );




        }else{
            this.stateList = [];
            this.checkStateArraylist = [];
        }


    }

    /* Get CountyList list */
    getCountyList() {
        var URL = 'site/getSiteCountyByClient?clientId=' + this.clientId + '&userId=' + this.userId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        if (this.tierId != null && this.tierId != '') {
            var tierString = '&tierId=' + this.tierId.toString();
            var URL = URL.concat(tierString);
        }
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.countyList = data.responseList;
                } else {
                    this.countyList = [];
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get Country list */
    getCountryList() {
        this.apiService.getAll('country/getCountry').subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.countryList = data.responseList;
                } else {
                    this.countryList = [];
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Function for getting Root level tier data */
    getAllAssetList() {
        var URL = 'asset/getAssetByClient';
        let apiData: any = {
          clientId: this.clientId,
          userId: this.userId,
          isAssetLive: true,
        }
        
        if (this.assetIdForGraph != '' && this.assetIdForGraph != undefined) {
            apiData.assetIds = this.assetIdForGraph.toString();
            // var assetString = '&assetId=' + this.assetIdForGraph;
            // var URL = URL.concat(assetString);
        }

        if (this.customerId != null && this.customerId != '') {
            apiData.customerIds = this.customerId.toString();
            // var customerString = '&customerId=' + this.customerId.toString();
            // var URL = URL.concat(customerString);
        }
        if (this.tierId != null && this.tierId != '') {
           apiData.tierIds = this.tierId.toString();
            // var tierString = '&tierId=' + this.tierId.toString();
            // var URL = URL.concat(tierString);
        }
        if (this.site != '') {
            apiData.siteIds = this.site.toString();
            // var siteString = '&s
            // var URL = URL.concat(siteString);
        }
        if (this.countryId != 0) {
            apiData.countryId = this.countryId.toString();
            // var countryString = '&countryId=' + this.countryId;
            // var URL = URL.concat(countryString);
        }
        if (this.state != '') {
            apiData.stateId = this.state.toString();
            // var stateString = '&stateId=' + this.state;
            // var URL = URL.concat(stateString);
        }
        if (this.status != '') {
          apiData.status = this.status.split(',');
            // var statusString = '&status=' + this.status;
            // var URL = URL.concat(statusString);
        }
        if (this.search != '') {
            apiData.search = this.search;
            // var searchString = '&search=' + this.search;
            // var URL = URL.concat(searchString);
        }
        if (this.county != '') {
            apiData.county = this.county;
            // var countyString = '&county=' + this.county;
            // var URL = URL.concat(countyString);
        }
        this.assetDatalist = [];
        if ($.fn.DataTable.isDataTable('#asset_data_table')) {
            $('#asset_data_table').DataTable().destroy();
        };
        apiData.startlimit = this.config.itemsPerPage*this.config.currentPage-this.config.itemsPerPage;

        apiData.endlimit = this.config.itemsPerPage
        // URL= URL+'&startlimit='+(this.config.itemsPerPage*this.config.currentPage-this.config.itemsPerPage)+"&endlimit="+this.config.itemsPerPage
        if (this.tableSearchCon.length) {
          apiData.search = this.tableSearchCon;
          // URL = URL + '&search='+this.tableSearchCon
        }

        this.apiService.create(URL, apiData).subscribe(
            data => {
                if (data.statusCode == 100) {
                    let Arr = []
                    this.assetDatalist = [];
                    this.config.totalItems = data.recordsTotal

                    console.log(this.config.totalItems, this.config.itemsPerPage, "======================", Number(this.config.totalItems/this.config.itemsPerPage));
                    
                    let objArr =  data.responseList ? data.responseList : [];
                    // let isShowCallOrder = false;
                    objArr.map((val,key)=>{
                        // if(val.callSequencestatus){
                        //   isShowCallOrder = true;
                        // }
                        let objValue = val
                        if (!val.notificationPlan) {
                            objValue.notificationPlan = {};
                        }
                        if (!val.shiftGroup) {
                            objValue.shiftGroup = {};
                        }
                        if (!val.customer) {
                            objValue.customer = {};
                        }
                        if (!val.site) {
                            objValue.site = {};
                        }
                        Arr.push(objValue);
                        // this.isShowCallOrder = isShowCallOrder
                    });
                    this.assetDatalist = JSON.parse(JSON.stringify(Arr));
                    this.chRef.detectChanges();
                    if ($.fn.DataTable.isDataTable('#asset_data_table')) {
                        $('#asset_data_table').DataTable().destroy();
                    };

                    setTimeout(() => {
                      const table: any = $('#asset_data_table');
                      this.dataTable = table.DataTable({
                          "order": this.columnSortOrder ? [this.columnSortOrder] : [], "search": { regex: false, smart: false },
                          "bDestroy": true,
                          paging:false,
                          searching:false,
                          "destroy":true,
                          "columnDefs": [{ "orderable": false, "targets": [8,10] }],
                      });
                    }, 300);
                }
                else {
                    this.assetDatalist = [];
                    this.chRef.detectChanges();
                    const table: any = $('#asset_data_table');
                    this.dataTable = table.DataTable({
                        "order": [],
                        "destroy":true,
                        searching:false,
                        paging:false,
                        "bDestroy": true,
                        "columnDefs": [{
                            "orderable": false,
                            "targets": [8,10]
                        }],
                    });
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }
    columnSortOrderChange(val, event){
      setTimeout(() => {
        console.log(val,event.target.className);
        
        if(event.target.className == 'sorting_desc'){
          this.columnSortOrder = [val,'desc']
        }else if(event.target.className= 'sorting_asc' || event.target.className == 'sorting'){
          this.columnSortOrder = [val,'asc'] 
        }
      }, 500);
      
    }
    // Getting Asset Id
    getAssetId(data) {
        this.assetData = JSON.parse(JSON.stringify(data));
        this.assetData.site.siteId = data.site.siteId;
        this.updateAssetForm.submitted = false;
        // console.log(this.assetData)
        if(this.assetData.site.isRuntime == true) {
          this.isShowCritical = true
        }

        if(this.assetData.notificationPlan.callSchedule == 2) {
            this.isShowCalendar = true;
        }
        else {
            this.isShowCalendar = false;
        }
        this.getAllNotificationList();
    }

    // Getting all Customers
    getAllCustomerList() {
        var URL = 'customer/getCustomerDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.customerListData = data.responseList;
                } else {
                    this.customerListData = [];
                    /* this.alertService.sweetMessage('error',data.responseMessage); */
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

// Getting All Notifications
getAllNotificationList() {
  // var URL = 'notification_plan/getNoficationPlanDropDownListByClient?clientId=' + this.clientId + '&userId=' + this.userId
  var URL = 'notification_plan/getNotificationPlanByTierORisGlobalNp?clientId=' + this.clientId + '&assetId=' + this.assetData.assetId+'&alphabetSortingOrder=1'
  this.apiService.getAll(URL).subscribe(
    data => {
      if (data.statusCode == 100) {

        this.notificationListData = data.responseList;
        var _this = this;
        /* Check if call schedule value is 2
          at the time of get all the data then show calender DropDrown
        */
        var _this = this;
        this.notificationListData.forEach(function (arrayItem) {
        let SelectedId = _this.assetData.notificationPlan.notificationPlanId;
        let notifyId   = arrayItem.notificationPlanId;

          if(SelectedId == notifyId && arrayItem.callSchedule == 2) {
            _this.isShowCalendar = true;
          }
        });

      } else {
          this.notificationListData = [];
          /* this.alertService.sweetMessage('error',data.responseMessage); */
      }
    },
    error =>
    { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
  );
}

    // Getting shift List
    getAllShiftList() {
        var URL = 'shiftGroup/getShiftGroupDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.shiftGroupListData = data.responseList;
                } else {
                    this.shiftGroupListData = [];
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

// Getting Site List
getAllSiteList() {
  var URL = 'site/getSiteDropDownListByClient?clientId=' + this.clientId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
  this.apiService.getAll(URL).subscribe(
      data => {
      if (data.statusCode == 100) {
          this.siteListData = data.responseList;

      } else {
          this.siteListData = [];
      }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
       }
  );
}

  // Update Assets
  updateAssets(){

  var _this = this;
    // Deep copy
    console.log(this.updateAssetForm.valid);

    if(this.updateAssetForm.valid) {
      var newObject = jQuery.extend(true, {}, _this.assetData);

    let obj = {
      assetId     : newObject.assetId,
      site        : newObject.site,
      customer    : newObject.customer.customerId ? newObject.customer : null,
      name        : newObject.name,
      shiftGroup  : newObject.shiftGroup,
      criticalThresHold : newObject.criticalThresHold,
      notificationPlan  :newObject.notificationPlan,
    }


    if(this.assetData.notificationPlan.notificationPlanId == "" || this.assetData.notificationPlan.notificationPlanId == undefined) {
      obj.notificationPlan = null;
    }
    if(this.assetData.shiftGroup.shiftGroupId == "" || this.assetData.shiftGroup.shiftGroupId == undefined) {
      obj.shiftGroup = null;
    }
    $('#updateAsset').modal('hide');
      // this.updateDataForAsset = obj;
      this.updateAssetForm.submitted = false
      this.apiService.update('asset/updateAssetForDropDown', obj).subscribe(
        data => {
              if(data.statusCode == 100){
                  this.alertService.sweetMessage('success','Asset Updated successfully');
                  // Get aa assets
                  this.getAllAssetList();
              }else{
                // this.assetData.notificationPlan.notificationPlanId = "";
                  this.alertService.sweetMessage('error',data.responseMessage);
              }
          },
          error =>{
             this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
    }else{

    }

    }

    // Delete User
    DeleteUser(assetdata){
        Swal({
          title: 'Are you sure?',
          text: "You want to delete this Asset User.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.apiService.delete('assetUser/deleteAssetUser?assetId='+this.assetDataId+'&userId='+assetdata.userId,'').subscribe(
              data => {
                if(data.statusCode == 100){
                    this.numbers.pop();
                    $("#closemodal").click();
                    this.getAllAssetList();
                    this.alertService.sweetMessage('success','Asset User deleted successfully');
                  this.getAssetDataAfterUserAdd(this.assetDataId);
                }else{
                  this.alertService.sweetMessage('error',data.responseMessage);
                }
              },
              error =>{
               this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
              );
          }
        })
    }

    // Add Asset User
    AddUserByAsset(){
        this.UserToAsset.asset.assetId = this.assetDataId;
        this.apiService.create('assetUser/addUserToAsset', this.UserToAsset).subscribe(
          data => {
            if(data.statusCode == 100){
              // $("#closemodal").click();
              this.getAssetDataAfterUserAdd(this.assetDataId);
              this.getUserByAsset();
              this.getAllAssetList();
              this.alertService.sweetMessage('success','Asset User added successfully');
              this.UserToAsset = {"createdTime":"","callSequence":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
            }else{
              this.UserToAsset = {"createdTime":"","callSequence":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};

            this.alertService.sweetMessage('error',data.responseMessage);

            }
          },
          error =>{
               this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Getting Asset Data
    getAssetData(data22){
      $('.callSequence').val('');
      this.isShowCallOrder = data22.notificationPlan ?  (data22.notificationPlan.callSchedule == 1 ? true : false) : false
      console.log(this.isShowCallOrder,data22);
      
      this.assetDataId = JSON.parse(JSON.stringify(data22.assetId));
      this.apiService.getAll('user/getUserByAsset?clientId='+this.clientId+'&assetId='+this.assetDataId).subscribe(
        data => {
          if(data.statusCode == 100){
            this.userassetList = data.userList;
            this.total = this.userassetList.length + 2;
            this.numbers = [];
              for (var i = 1; i < this.total; i++) {
                  this.numbers.push(i);
                  //visit(children[i], visitFn, childrenFn);
              }
          }else{
            this.userassetList = [];
            this.numbers = ['1'];
          }
        },
       error =>{
             this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );

      // Getting User and Role List
      this.getUserList();
      this.getRoleList();
    }

    getAssetDataAfterUserAdd(data22){

      // this.isShowCallOrder = data22.notificationPlan ?  (data22.notificationPlan.callSchedule == 1 ? true : false) : false
      // console.log(this.isShowCallOrder,data22);
      
      // this.assetDataId = JSON.parse(JSON.stringify(data22.assetId));
      this.apiService.getAll('user/getUserByAsset?clientId='+this.clientId+'&assetId='+this.assetDataId).subscribe(
        data => {
          if(data.statusCode == 100){
            this.userassetList = data.userList;
            this.total = this.userassetList.length + 2;
            this.numbers = [];
              for (var i = 1; i < this.total; i++) {
                  this.numbers.push(i);
                  //visit(children[i], visitFn, childrenFn);
              }
          }else{
            this.userassetList = [];
            this.numbers = ['1'];
          }
        },
       error =>{
             this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );

      // Getting User and Role List
      this.getUserList();
      this.getRoleList();
    }

    // Getting User List
    getUserList(){
        // this.apiService.getAll('public/user/getUserByTier?clientId='+this.clientId+'&assetId='+this.assetDataId+"&isActive=true").subscribe(
        this.apiService.getAll('user/getUserByTierWithCustomerUser?assetId='+this.assetDataId +"&isActive=true" ).subscribe(
          data => {
            if(data.statusCode == 100){
              this.UserList = data.userList;
            }else{
              this.UserList =[];
             /* this.alertService.sweetMessage('error',data.responseMessage); */
            }
          },
          error =>{
               this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Getting Role List
    getRoleList(){
        this.apiService.getAll('assetRole/getAllAssetRole').subscribe(
          data => {
            if(data.statusCode == 100){
              this.roleList = data.responseList;
            }else{
              this.roleList =[];
              /*this.alertService.sweetMessage('error',data.responseMessage); */
            }
          },
         error =>{
               this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get User by Asset list */
    getUserByAsset(){
        this.apiService.getAll('user/getUserByAsset?clientId='+this.clientId+'&assetId='+this.assetDataId).subscribe(
          data => {
            if(data.statusCode == 100){
              this.userassetList = data.userList;
            }else{
              this.userassetList = [];
            }
          },
          error =>{
               this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

  // check if call schedule value is 2 then show calender DropDown
  isCallSchedule (event) {

    let callScheduleValue = event.target.selectedOptions[0].id;

    if(callScheduleValue == 2) {
      this.isShowCalendar = true;
    }
    else {
      this.isShowCalendar = false;
    }
  }


  // check if call schedule value is 2 then show calender DropDown
  isRuntime (event) {

    let isRuntimeValue = event.target.selectedOptions[0].id;

    if(isRuntimeValue == "true") {
      this.isShowCritical = true;
    }
    else {
      this.isShowCritical = false;
    }
  }

// When user click on site name then it will add site name to the page filter
  addSiteToFilter (keyValue) {
    $('#asset_data_table').DataTable().search(keyValue).draw();
  }


getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('asset/getAssetHistoryByAsset?assetId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
  }

  setList(users){
    this.assetUserList = users;
  }
}
