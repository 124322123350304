import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { LoadingBarService } from '@ngx-loading-bar/core';
import { isThisISOWeek } from 'date-fns';

@Component({
    selector: 'app-add-report',
    templateUrl: './add-report.component.html',
    styleUrls: ['./add-report.component.css']
})

export class AddReportComponent implements OnInit {
    @ViewChild('myTree') myTree: jqxTreeComponent;
    @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
    tierId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
    dropdownSettingsSite:any = {};
    dropdownSettings:any = {};
    dropdownSettingsUser:any ={};
    dropdownSettingsAsset:any ={};
    siteList: any = [];
    customerList: any = [];
    tierListData: any = [];
    data: any = [];
    dataAdapter: any = [];
    source: any = {};
    records: any = [];
    disableTreeArray: any = [];
    checkTreeArray: any = [];
    tiersArray: any = [];
    reportObjects:any=[];
    reportUsers:any=[];
    reportId: any = 0;
    reportperiod: any = "";
    weekdayMapData:any = 0;
    userListDataForTable1:any=[];
    userListDataForTable:any=[];
    userListTableData: any = [];
    emailListTableData: any = [];
    emailArray : any = [];
    userListData:any = {
      "firstName":"",
      "email":"",
      "phoneNumber":"",
      "mobileNumber":"",
      "title":""
    };


    recurringPattern:any = {
      "isMonday":"",
      "isTuesday":"",
      "isWednesday":"",
      "isThursday":"",
      "isFriday":"",
      "isSaturday":"",
      "isSunday":""
    }

    frequencyDataForCheck:any = {
      "frequencyStep":"",
      "weekdayMap":"",
      "dateOfMonth":"",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":""
    }

    addFormData:any = {
      "reportTime":"00:00:00",
      "isActive":true,
      "name":"",
      "description":"",
      "frequencyStep":1,
      "weekdayMap":"",
      "dateOfMonth":"1",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":"",
      "emailAddress" : "",
      "durationType" : ""
    }

    reportData:any = {
      "isActive":true,
      "durationType" : "",
      "frequencyType":"",
      "name":"",
      "description":"",
      "frequencyStep":1,
      "reportTime":"",
      "weekdayMap":"",
      "dateOfMonth":"",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":"",
      "users":[],
      "customers":[],
      "tiers":[],
      "sites":[],
      "reportType" : 'Runtime With Root Cause',
      "client" : {
            "clientId" : (localStorage.getItem("clientId") ? JSON.parse(localStorage.getItem("clientId")) : [])
        }
      }

    frequencyData: any = {};
    checkSiteArray: any = [];
    checkCustomerArray: any = [];
    checkAssetArray: any = [];
    checkUserArray: any = [];
    radioSelected: string = 'Daily';
    checkTreeArrayData: any = [];
    siteIdForApi:any;
    tierIdForApi:any;
    customerIdForApi:any;
    reportForUser:any=[];
    dataForShowTable:any = [];
    userIdForshow :any = 0;

    selectedItems: any = [];
    selectedSite: any = [];
    selectedcustomer: any = [];
    selectedsite: any = [];
    selecteuser: any = [];
    selectedUser: any = [];
    user : any = [];
    afterEditData:any=[];
    showSelectedUserTable:any=[];
    checkDataForchecked:any=0;

    monthlyRadioSelected : string;
    YearlyRadioSelected : string;

    resultForSite:any      =  [];
    resultForUser:any      = [];
    resultForAsset:any      = [];
    resultForCustomer:any  = [];
    clientCode: any = '';
    preivewData: any = [];
    permissionData:any=[];
    assetDatalist: any = [];
    selectedAsset: any = [];
    selectedasset: any = [];
    durationType:any = 1;
      yearlyMonth:any = null
      monthDay = null
      selectAllTier = false;
    ReportErrorMsg = "";
    ReportErrorMsgMonthly = "";
    ReportErrorMsgYearly = "";
    disableTreeLength : any = 0;
    enableTreeArray : any = [];
    newDisableTreeArray : any = [];
    alreadyAddedEmail:boolean = false;

    constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private loading: LoadingBarService, private chRef: ChangeDetectorRef) {
        this.permissionData = ApiService.permission;
    }

    // Function to format Date
    DateFormat(startDate){
        if(startDate){
            return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
        }else{
            return "";
        }
    }

    ngOnInit() {
        // setTimeout(function(){
        //     // $('.frequencyStep').val(1);
        // }, 200);

        jQuery('.rst-time').datetimepicker({
            pickDate: false,
            pickTime: true,
            pickSeconds: false,
            pickMinutes: true,
            timeFormat: "HH:mm",
            endDate:new Date()
        });

        this.clientCode = this.alertService.getClientCode();
        this.route.params.subscribe(params => {
            if (params['reportId']) {
                this.reportId = params['reportId'];
            }
        });
        if (this.reportId) {
            // this.getReportById();
            this.getSiteManagementList();
            this.getCustomerList();
            this.getAllAssetList();
            // this.getReportById();
        }

        this.getTierList();

        this.dropdownSettingsSite = {
            singleSelection: false,
            idField: 'siteId',
            textField: 'siteName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true,    
            placeholder : "Select Site" 
        };
         

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'customerId',
            textField: 'name',
            placeholder : "Select Customer" ,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };

        this.dropdownSettingsAsset = {
            singleSelection: false,
            idField: 'assetId',
            textField: 'assetName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            placeholder : "Select Asset",
            allowSearchFilter: true
        };

        this.dropdownSettingsUser = {
            singleSelection: false,
            idField: 'userId',
            textField: 'userName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
    }

    initilaiseCal(){
        if (this.reportId == 0) {
            let self =  this
            setTimeout(function(){
                $('.daily-check-month').prop('checked', true);
                $('#year-check-first').prop('checked', true);
                $('input[name=dateOfMonth]').val('1');
                $('#weekOfMonth').val(1);
                $('#weekOfMonth1').val(1);
                $('#weekDayOfMonth').val(2);
                $('#weekdayOfMonth-year').val(2);
                $('#monthOfYear').val(1);
                $('#monthOfYear-first').val(1);
                $('.frequencyStep').val(1);
                self.addFormData.monthOfYear = 1

            }, 200);
            if (this.radioSelected == "Yearly") {
                this.addFormData.monthOfYear = 1
            }
        }

    }

    addEmail() {
        var email_id = $('#newEmail').val();
        if(email_id!=""){
            var exist = this.emailListTableData.indexOf(email_id);
            if (exist == '-1') {
                this.alreadyAddedEmail = false;
                this.emailListTableData.push(email_id);   
                setTimeout(function(){
                    $('#newEmail').val('');
                }, 100);
            }else{
                this.alreadyAddedEmail = true;
            }
        }
        this. hideUserandEmailValidatonMsg();
    }

    removeEmail(email)
    {
        var exist = this.emailListTableData.indexOf(email);
        if (exist != '-1') {
            this.emailListTableData.splice(exist, 1);
        }
    }

    /* Get Site Management list */
    getSiteManagementList() {
        // var URL = 'site/getSiteByClient?alphabateSortingOreder=1&clientId=' + this.clientId + '&userId=' + this.userId;
        var URL = 'site/getSiteDropDownListByClient?clientId=' + this.clientId + '&userId=' + this.userId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        // if (this.tierId != null && this.tierId != '') {
        //     var tierString = '&tierId=' + this.tierId.toString();
        //     var URL = URL.concat(tierString);
        // }
        let Tier = this.checkTreeArray.join();
        if (Tier != null && Tier != '') {
            var tierString = '&tierId=' + Tier.toString();
            var URL = URL.concat(tierString);
        }


        if (this.checkTreeArray.length == 0) {
            this.siteList = []
            this.selectedSite = [];
        }else {
            this.startLoading();
            this.apiService.getAllDashboard(URL).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        if(this.checkTreeArray.length != 0) {
                            this.siteList = data.responseList;
                            let tempSelected = [];
                            this.selectedSite.map((obj, index)=>{
                                let findObj = _.find(this.siteList, { siteId: obj.siteId});
                                if (findObj) {
                                    tempSelected.push(obj);
                                }
                            })
                            this.selectedSite = tempSelected;
                        }                        
                    } else {
                        this.siteList = [];
                    }
                    this.stopLoading();
                },
                error =>{
                    this.stopLoading();
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }

    }

    /* Get Customer list */
    getCustomerList() {
        var URL = 'customer/getCustomerByClient?clientId=' + this.clientId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        // if (this.tierId != null && this.tierId != '') {
        //     var tierString = '&tierId=' + this.tierId.toString();
        //     var URL = URL.concat(tierString);
        // }
        this.tierIdForApi = this.checkTreeArray.join();
        if (this.tierIdForApi != null && this.tierIdForApi != '') {
            var tierString = '&tierId=' + this.tierIdForApi.toString();
            var URL = URL.concat(tierString);
        }



        if (this.checkTreeArray.length == 0) {    
            this.customerList = []
            this.selectedItems = [];
        }else {
            this.apiService.getAll(URL).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        if(this.checkTreeArray.length != 0) {
                            this.customerList = data.responseList;
                            let tempSelected = [];
                            this.selectedItems.map((obj, index)=>{
                                let findObj = _.find(this.customerList, { customerId: obj.customerId});
                                if (findObj) {
                                    tempSelected.push(obj);
                                }
                            })
                            this.selectedItems = tempSelected;
                        }                        
                    } else {
                        this.customerList = [];
                        /*this.alertService.sweetMessage('error',data.responseMessage);*/
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                 }
            );
        }

    }


    getReportperiod(value) {
        this.reportperiod = value;
    }

    onItemSelectSite(item: any) {
        var exist = this.checkSiteArray.indexOf(item.siteId.toString());
        if (exist == '-1') {
            this.checkSiteArray.push(item.siteId.toString());
            this.siteIdForApi = this.checkSiteArray.join();
            //this.getUserListData();
            this.getAllAssetList();
        }
    }

    onItemDeselectSite(item: any) {

        var exist = this.checkSiteArray.indexOf(item.siteId.toString());
        if (exist != '-1') {
            this.checkSiteArray.splice(exist, 1);
            this.siteIdForApi = this.checkSiteArray.join();
            //this.getUserListData();
            this.getAllAssetList();
        }
    }

    onSelectAllSite(items: any) {
        this.checkSiteArray = [];
        this.siteIdForApi = this.checkSiteArray.join();

        Object.keys(items).forEach(key => {
            this.checkSiteArray.push(items[key]['siteId'].toString());
            this.siteIdForApi = this.checkSiteArray.join();
            //this.getUserListData();
            // this.getAllAssetList();
        });
        setTimeout(()=> {	
            this.getAllAssetList();	
        }, 500);
    }

    onDeSelectAllSite() {
        this.checkSiteArray = [];
        this.siteIdForApi = this.checkSiteArray.join();
        //this.getUserListData();
        this.getAllAssetList();
    }

    onItemSelectCustomer(item: any) {
        var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
        if (exist == '-1') {
            this.checkCustomerArray.push(item.customerId.toString());
            this.customerIdForApi = this.checkCustomerArray.join();
            // this.getUserListData();
        }

    }

    onItemDeselectCustomer(item: any) {
        var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
        if (exist != '-1') {
            this.checkCustomerArray.splice(exist, 1);
            this.customerIdForApi = this.checkCustomerArray.join();
            //this.getUserListData();
        }

    }

    onSelectAllCustomer(items: any) {
        this.checkCustomerArray = [];
        this.customerIdForApi = this.checkCustomerArray.join();
        //this.getUserListData();

        Object.keys(items).forEach(key => {
            this.checkCustomerArray.push(items[key]['customerId'].toString());
            this.customerIdForApi = this.checkCustomerArray.join();
            //this.getUserListData();
        });
    }

    onDeSelectAllCustomer() {
        this.checkCustomerArray = [];
        this.customerIdForApi = this.checkCustomerArray.join();
        //this.getUserListData();
    }


    //******
    onItemSelectUser(item: any) {
        var exist = this.checkUserArray.indexOf(item.userId.toString());
        if (exist == -1) {
            this.checkUserArray.push(item.userId.toString());
        }
        this.showTableInfo(item.userId);
        this.hideUserandEmailValidatonMsg();
    }

    onItemDeselectUser(item: any) {
        var exist = this.checkUserArray.indexOf(item.userId.toString());
        if (exist != '-1') {
            this.checkUserArray.splice(exist, 1);
            this.removeTableInfo(item.userId);
        }
        this.hideUserandEmailValidatonMsg();
    }

    onSelectAllUser(items) {
        this.checkUserArray = [];
        this.userListTableData = [];
        // this.checkUserArray = _.map(items, 'userId');
        this.checkUserArray = items.map(item => item.userId.toString());
        this.showTableInfo(this.checkUserArray);
        this.hideUserandEmailValidatonMsg();
    }

    onDeSelectAllUser(item: any) {
        this.checkUserArray = [];
        this.userListTableData = [];
        this.hideUserandEmailValidatonMsg();
    }

    // function to create tier
    createTierArray() {
        for (var tierId in this.checkTreeArray) {
            this.tiersArray.push({
                tierId: this.checkTreeArray[tierId],
            })
        }
    };

    createEmailArray() {
        for (var emailId in this.emailListTableData) {
            this.emailArray.push({
                user: null,
                emailAddress: this.emailListTableData[emailId],
            })
        }
    };

    hideUserandEmailValidatonMsg() {

        if((this.checkUserArray.length != 0 || this.emailListTableData.length != 0))
        {
            $('#user_err').css('display', 'none');         
        }
    }

    // Function to add report
    addReportData(data) {
        let reportName = $('#reportName').val();
        let description = $('#description').val();
        let reportType = $('#reportType').val();
        this.ReportErrorMsg = ""
        this.ReportErrorMsgYearly = ""
        this.ReportErrorMsgMonthly = ""
       /* if(this.checkUserArray.length == 0 )
        {
            $('#user_err').css('display', 'block');
            $('.site-invalid').addClass('is-invalid');
           
        }
        else
        {
            $('#user_err').css('display', 'none');
            $('.site-invalid').removeClass('is-invalid');
        }  */

        if(reportName != '') {
            $('#reportName').removeClass('is-invalid');
        }

        if(reportName == '' && description == '' && this.checkTreeArray.length == 0 && (this.checkUserArray.length == 0 && this.emailListTableData.length == 0))
        {
            $('#report_name_err').css('display', 'block');
            $('#report_desc_err').css('display', 'block');
            $('#tier_err').css('display', 'block');
            $('#user_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#report_name_err').css('display', 'none');
            $('#report_desc_err').css('display', 'none');
            $('#tier_err').css('display', 'none');
            $('#user_err').css('display', 'none');
        }

        if((this.checkUserArray.length == 0 && this.emailListTableData.length == 0) && description == '' && this.checkTreeArray.length == 0)
        {
            $('#user_err').css('display', 'block');
            $('#report_desc_err').css('display', 'block');
            $('#tier_err').css('display', 'block');
            return;
        }
        else
        {
            $('#user_err').css('display', 'none');
            $('#report_desc_err').css('display', 'none');
            $('#tier_err').css('display', 'none');
        }

        if(reportName == '' && (this.checkUserArray.length == 0 && this.emailListTableData.length == 0) && this.checkTreeArray.length == 0)
        {
            $('#report_name_err').css('display', 'block');
            $('#user_err').css('display', 'block');
            $('#tier_err').css('display', 'block');
            return;
        }
        else
        {
            $('#report_name_err').css('display', 'none');
            $('#user_err').css('display', 'none');
            $('#report_desc_err').css('display', 'none');
        }

        if(reportName == '' && (this.checkUserArray.length == 0 && this.emailListTableData.length == 0) && description == '')
        {
            $('#report_name_err').css('display', 'block');
            $('#user_err').css('display', 'block');
            $('#report_desc_err').css('display', 'block');
            return;
        }
        else
        {
            $('#report_name_err').css('display', 'none');
            $('#user_err').css('display', 'none');
            $('#report_desc_err').css('display', 'none');
        }

        if(reportName == '' && this.checkTreeArray.length == 0 && description == '')
        {
            $('#report_name_err').css('display', 'block');
            $('#tier_err').css('display', 'block');
            $('#report_desc_err').css('display', 'block');
            return;
        }
        else
        {
            $('#report_name_err').css('display', 'none');
            $('#tier_err').css('display', 'none');
            $('#report_desc_err').css('display', 'none');
        }

        if(reportName == '' && (this.checkUserArray.length == 0 && this.emailListTableData.length == 0))
        {
            $('#report_name_err').css('display', 'block');
            $('#user_err').css('display', 'block');
            return;
        }
        else
        {
            $('#report_name_err').css('display', 'none');
            $('#user_err').css('display', 'none');
        }

        if((this.checkUserArray.length == 0 && this.emailListTableData.length == 0) && description == '')
        {
            $('#user_err').css('display', 'block');
            $('#report_desc_err').css('display', 'block');
            return;
        }
        else
        {
            $('#user_err').css('display', 'none');
            $('#report_desc_err').css('display', 'none');
        }

        if(reportName == '' && description == '')
        {
            $('#report_name_err').css('display', 'block');
            $('#report_desc_err').css('display', 'block');
            return;
        }
        else
        {
            $('#report_name_err').css('display', 'none');
            $('#report_desc_err').css('display', 'none');
        }

         

         if(reportName == '' && this.checkTreeArray.length == 0)
        {
            $('#report_name_err').css('display', 'block');
            $('#tier_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#report_name_err').css('display', 'none');
            $('#tier_err').css('display', 'none');
        }

        if(reportName == '' && this.checkTreeArray.length == 0)
        {
            $('#report_desc_err').css('display', 'block');
            $('#tier_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#report_desc_err').css('display', 'none');
            $('#tier_err').css('display', 'none');
        }

        if(reportName == '')
        {
            $('#report_name_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#report_name_err').css('display', 'none');
        }

        if(description == '')
        {
            $('#report_desc_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#end_date_err').css('display', 'none');
        }

        if(this.checkTreeArray.length == 0)
        {
            $('#tier_err').css('display', 'block');
            return;
        }
        else
        {
            $('#tier_err').css('display', 'none');
        }

        if((this.checkUserArray.length == 0 && this.emailListTableData.length == 0))
        {
            $('#user_err').css('display', 'block');
            return;
        }
        else
        {
            $('#user_err').css('display', 'none');
        } 
        

        // if(data.invalid){
        //   this.alertService.sweetMessage('error','Please fill in all the required fields');
        //   return;
        // }
        for (var siteId in this.checkSiteArray) {
           this.resultForSite.push({
              siteId: this.checkSiteArray[siteId],
            })
        }
        for (var userId in this.checkUserArray) {
            this.resultForUser.push({
               userId: this.checkUserArray[userId],
            })
        }
        for (var customerId in this.checkCustomerArray) {
            this.resultForCustomer.push({
              customerId: this.checkCustomerArray[customerId],
            })
        }

        for (var assetId in this.checkAssetArray) {
            this.resultForAsset.push({
              assetId: this.checkAssetArray[assetId],
            })
        }

        this.createTierArray();
        this.createEmailArray();
        // console.log(this.addFormData ,"===============",this.durationType);
        

        this.reportData.users       = this.resultForUser;
        this.reportData.assets       = this.resultForAsset;
        this.reportData.customers   = this.resultForCustomer;
        this.reportData.sites       = this.resultForSite;
        this.reportData.tiers       = this.tiersArray;
        this.reportData.name        = this.addFormData.name;
        this.reportData.description = this.addFormData.description;
        this.reportData.isActive    = this.addFormData.isActive;
        this.reportData.reportTime    = $('#reportTime').val();
        this.reportData.emails       = this.emailArray;

        if (this.radioSelected == 'OnDemand') {
            this.reportData.frequencyType = "OnDemand";

        } else if (this.radioSelected == 'Daily') {
            this.reportData.frequencyType = "Daily";
            // console.log(Number(this.addFormData.frequencyStep),"Number(this.addFormData.frequencyStep)");
            
            if (Number(this.addFormData.frequencyStep) > 31) {
                this.ReportErrorMsg = "Please select valid date."
                // this.alertService.error("Please select valid date.");
                return
            }
            this.reportData.frequencyStep = this.addFormData.frequencyStep;

        } else if (this.radioSelected == 'Weekly') {

            this.reportData.frequencyType = "Weekly";
            if (Number(this.addFormData.frequencyStep) > 31) {
                this.ReportErrorMsg = "Please select valid date."
                // this.alertService.error("Please select valid date.");
                return
            }
            this.reportData.frequencyStep = this.addFormData.frequencyStep;

            if (this.recurringPattern.isSunday == true) {
                // this.weekdayMapData = this.weekdayMapData + 64;
                this.weekdayMapData = this.weekdayMapData + 1;
            }
            if (this.recurringPattern.isMonday == true) {
                // this.weekdayMapData = this.weekdayMapData + 1;
                this.weekdayMapData = this.weekdayMapData + 2;
            }
            if (this.recurringPattern.isTuesday == true) {
                // this.weekdayMapData = this.weekdayMapData + 2;
                this.weekdayMapData = this.weekdayMapData + 4;
            }
            if (this.recurringPattern.isWednesday == true) {
                // this.weekdayMapData = this.weekdayMapData + 4;
                this.weekdayMapData = this.weekdayMapData + 8;
            }
            if (this.recurringPattern.isThursday == true) {
                // this.weekdayMapData = this.weekdayMapData + 8;
                this.weekdayMapData = this.weekdayMapData + 16;
            }
            if (this.recurringPattern.isFriday == true) {
                // this.weekdayMapData = this.weekdayMapData + 16;
                this.weekdayMapData = this.weekdayMapData + 32;
            }
            if (this.recurringPattern.isSaturday == true) {
                // this.weekdayMapData = this.weekdayMapData + 32;
                this.weekdayMapData = this.weekdayMapData + 64;
            }

            this.reportData.weekdayMap = this.weekdayMapData;

        } else if (this.radioSelected == 'Monthly') {

            this.reportData.frequencyType = "Monthly";
            if (this.durationType == 1) {
                if (Number(this.monthDay) > 31) {
                    this.ReportErrorMsgMonthly = "Please select valid date."
                    // this.alertService.error("Please select valid date.");
                    return
                }
                if (Number(this.addFormData.frequencyStep) > 12) {
                    this.ReportErrorMsgMonthly = "Please select valid month."
                    // this.alertService.error("Please select valid date.");
                    return
                }
                this.reportData.dateOfMonth = this.monthDay//this.addFormData.dateOfMonth;
                this.reportData.frequencyStep = this.addFormData.frequencyStep;
                this.reportData.durationType  = 1;
            } else if (this.durationType == 2) {
                // console.log("this.durationType", this.durationType);
                if (Number(this.addFormData.dateOfMonth) > 12) {
                    this.ReportErrorMsgMonthly = "Please select valid month."
                    // this.alertService.error("Please select valid date.");
                    return
                }
                this.reportData.weekOfMonth = this.addFormData.weekOfMonth;
                this.reportData.weekdayOfMonth = this.addFormData.weekdayOfMonth;
                this.reportData.dateOfMonth = ""//this.addFormData.dateOfMonth;
                this.reportData.durationType  = 2; 
                this.reportData.frequencyStep = this.addFormData.dateOfMonth;
            }
            /* if (!this.addFormData.weekOfMonth) {
                this.reportData.dateOfMonth = this.addFormData.dateOfMonth;
                this.reportData.frequencyStep = this.addFormData.frequencyStep;
                this.reportData.durationType  = 1;
            } else {
                this.reportData.weekOfMonth = this.addFormData.weekOfMonth;
                this.reportData.weekdayOfMonth = this.addFormData.weekdayOfMonth;
                this.reportData.dateOfMonth = this.addFormData.dateOfMonth;
                this.reportData.durationType  = 2;
            } */
        } else if (this.radioSelected == 'Yearly') {
            // this.YearlyRadioSelected = $('.year-ckeck-box').val();
            this.reportData.frequencyType = "Yearly";

            if (this.YearlyRadioSelected == "yearFirst") {
                // console.log(this.addFormData.frequencyStep);
                
                if (Number(this.addFormData.frequencyStep) > 31) {
                    this.ReportErrorMsgYearly = "Please select valid date."
                    // this.alertService.error("Please select valid date.");
                    return;
                }
                this.reportData.monthOfYear = this.yearlyMonth//this.addFormData.monthOfYear;
                this.reportData.frequencyStep = 1
                this.reportData.durationType  = 1;
                this.reportData.weekOfMonth = ""
                this.reportData.dateOfMonth = this.addFormData.frequencyStep;
                
            }else if (this.YearlyRadioSelected == "yearSecond") {
                // console.log(this.YearlyRadioSelected );
                
                this.reportData.weekdayOfMonth = this.addFormData.weekdayOfMonth;
                this.reportData.weekOfMonth = this.addFormData.weekOfMonth;
                this.reportData.monthOfYear = this.addFormData.monthOfYear;
                this.reportData.durationType  = 2;
                this.reportData.frequencyStep = 1;
            }

            // if (!this.addFormData.weekOfMonth) {
            //     this.reportData.monthOfYear = this.yearlyMonth//this.addFormData.monthOfYear;
            //     this.reportData.frequencyStep = this.addFormData.frequencyStep;
            //     this.reportData.durationType  = 1;
            // } else {
            //     this.reportData.weekdayOfMonth = this.addFormData.weekdayOfMonth;
            //     this.reportData.weekOfMonth = this.addFormData.weekOfMonth;
            //     this.reportData.monthOfYear = this.addFormData.monthOfYear;
            //     this.reportData.durationType  = 2;
            // }
        }
        // console.log(this.reportData);
        if(this.checkUserArray.length == 0 && this.checkTreeArray.length == 0)
        {
            // $('#user_err').css('display', 'block');
            $('#tier_err').css('display', 'block');
            // return;
        }
        else 
        {
            $('#user_err').css('display', 'none');
            $('#tier_err').css('display', 'none');
        }
        
        // return
        this.addReportDataList();
    }

    changeMonthlyMonthval(){
        this.ReportErrorMsgMonthly = ""
    }


  // Preview Report
    previewReport() {
        var URL = 'report/getPreviewReport?reportId=' + this.reportId;
        this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.preivewData = data.responseList;
              }
              else
                { this.alertService.sweetMessage('error',"No Data Found"); }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }


  // get tier List
    getTierList() {
        var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.data = data.responseList ? data.responseList : [] ;
                    Object.keys(this.data).forEach(key => {
                        if (this.data[key]['flag'] == false) {
                            this.disableTreeArray.push(this.data[key]['tierId']);
                        }
                    });

                    // disable tree length
                    this.getEnableDisableTier(this.data);

                    this.source = {
                        datatype: 'json',
                        datafields: [{
                            name: 'tierId'
                        }, {
                            name: 'parentTierId'
                        }, {
                            name: 'tierName'
                        }],
                        id: 'tierId',
                        localdata: this.data
                    };
                    this.dataAdapter = new jqx.dataAdapter(this.source, {
                        autoBind: true
                    });
                    this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{
                        name: 'tierName',
                        map: 'label'
                    }, {
                        name: 'tierId',
                        map: 'id'
                    }]);
                    var self = this;
                    setTimeout(function() {
                        self.treeItemDisable();
                    }, 2000);
                }
                if (this.reportId) {
                    this.getReportById();
                }

                // let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> No Record Found</div>';
                //     this.myDropDownButton.setContent(dropDownContent);
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Function to disable items
    treeItemDisable() {
        Object.keys(this.disableTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.disableTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.disableItem(checkedItem);
        });
    }

    /* Handle tier tree chacked unchecked Event */
    CheckChange(event) {
        if (event.args.checked == true) {
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist == '-1') {
                this.checkTreeArray.push(event.args.element.id);
                setTimeout(() => {
                    this.getUserListData();
                    this.getSiteManagementList();
                    this.getCustomerList();
                    //this.getAllAssetList();
                }, 1500);
                // localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
            }
        } else if (event.args.checked == false || event.args.checked == null) {
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist != '-1') {
                this.checkTreeArray.splice(exist, 1);
                this.checkUserArray = [];
                this.selectedUser = [];
                this.userListTableData = [];
                this.userListDataForTable1 = [];
                this.userListDataForTable = [];

            }
            setTimeout(() => {
                this.getUserListData();
                this.getSiteManagementList();
                this.getCustomerList();
                this.getAllAssetList();
            }, 1500);
        }
        
        if (this.checkTreeArray.length == 0) {
            this.dropdownSettingsSite.placeholder = "Select a tier first" 
            this.dropdownSettings.placeholder = "Select a tier first" 
            this.dropdownSettingsAsset.placeholder = "Select a tier first" 
        }else{
            this.dropdownSettingsSite.placeholder = "Select Site" 
            this.dropdownSettings.placeholder = "Select Customer" 
            this.dropdownSettingsAsset.placeholder = "Select Asset" 
        }

        if(this.disableTreeLength==0) {
            if (this.data.length == this.checkTreeArray.length) {
                this.selectAllTier = true            
            }else{
                this.selectAllTier = false
            }
        }else{
            let actualLength = this.data.length-this.disableTreeLength;
            if (actualLength == this.checkTreeArray.length) {
                this.selectAllTier = true;            
            }else{
                this.selectAllTier = false;
            }
        }
    }
    // Getting User List Data
    getUserListData() {
        this.stopLoading();
        this.tierIdForApi = this.checkTreeArray.join();
        // var URL = 'user/getUserClientByClient?clientId=' + this.clientId + '&userId=' + this.userId
        var URL = 'user/getUserClientByClient?clientId=' + this.clientId+'&isActive=true'

        if (this.tierIdForApi != null && this.tierIdForApi != '') {
            var tierString = '&tierId=' + this.tierIdForApi.toString();
            var URL = URL.concat(tierString);
        }
        // if (this.customerIdForApi != null && this.customerIdForApi != '') {
        //     var customerString = '&customerId=' + this.customerIdForApi.toString();
        //     var URL = URL.concat(customerString);
        // }
        // if (this.siteIdForApi != null && this.siteIdForApi != '') {
        //     var siteString = '&siteId=' + this.siteIdForApi.toString();
        //     var URL = URL.concat(siteString);
        // }
        this.selectedUser = []
        if(this.checkTreeArray != "")
        {
            this.apiService.getAll(URL).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.afterEditData = JSON.parse(JSON.stringify(data.userList));
                        let responseList = JSON.parse(JSON.stringify(data.userList));
                        this.userListDataForTable1 = [];
                        this.userListDataForTable = [];
                        this.userListDataForTable1 = data.userList;
                        this.userListDataForTable = this.userListDataForTable.concat(data.userList);


                        let user = []
                        if (this.addFormData.users) {
                            for (let object of this.addFormData.users) {

                                let findObj = _.find(responseList, { userId: object.userId});
    
                                if (findObj) {
                                    // this.userListDataForTable1.push({
                                    //     'userId': object['userId']
                                    // });
                                    this.userListDataForTable.push(object);
                                    user.push({userId:findObj.userId,userName:findObj.userName})
    
    
                                    let findObjTable = _.find(this.userListTableData, { userId: object.userId});
                                    if (!findObjTable) {
                                        this.userListTableData.push(object);
                                        //pushing value from getreportbyid function
                                        // this.checkUserArray.push(object['userId'].toString());
                                    }
    
    
    
                                }
                            }
                            this.selectedUser = user;    
                        }                        
                    } else {
                        this.userListDataForTable1 = [];
                        this.userListDataForTable = [];
                    }
                },
               error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                 }
            );
        }
    }

    // Function to show table Info
    showTableInfo(data) {
        var opbj = JSON.parse(JSON.stringify(data));
        if(Array.isArray(opbj) && opbj.length == 1){
            var opbj = opbj[0];
        }
        if (opbj && Array.isArray(opbj) && opbj.length > 0) {
            for (var i = 0; i < opbj.length; i++) {
                this.userListTableData.push(this.userListDataForTable[i]);
            }
        }else{
            for(var i = 0; i < this.userListDataForTable.length; i++) {
                if(this.userListDataForTable[i]['userId'] === opbj) {
                    
                    let findObjTable = _.find(this.userListTableData, { userId: opbj});
                    if (!findObjTable) {
                        this.userListTableData.push(this.userListDataForTable[i]);
                    }

                    
                    // this.userListTableData.push(this.userListDataForTable[i]);
                }
            }
        }
    }

    // Function to remove table Info
    removeTableInfo(data) {
        this.userListTableData.splice(_.indexOf(this.userListTableData, _.find(this.userListTableData, function(item) {
            return item.userId == data;
        })), 1);
    }

    // Function to add Report Data
    addReportDataList() {
        // return
         if (this.reportId == 0) {
            this.apiService.create('report/createReport', this.reportData).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        Swal({
                            position: 'top-end',
                            type: 'success',
                            title: 'Report details added successfully',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.router.navigate(['/report']);
                    } else {
                        this.alertService.sweetMessage('error', data.responseMessage);
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        } else {
            // console.log('update');
            // console.log(this.addFormData);
            // console.log(this.reportData);

            // return;
            // if (data.response.frequencyType == 'Monthly') {
            //             if (!data.response.weekdayOfMonth) {
            //                 this.addFormData.dateOfMonth = data.response.dateOfMonth;
            //                 this.addFormData.frequencyStep = data.response.frequencyStep;
            //                 this.reportData.durationType  = 1;
            //                 setTimeout(function(){
            //                     $('#dateOfMonth-second').val('');
            //                     $('#monthly-check-first').prop('checked', true);
            //                 },500);
            //             } else {
            //                 this.addFormData.weekOfMonth = data.response.weekOfMonth;
            //                 this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
            //                 this.addFormData.dateOfMonth = data.response.dateOfMonth;
            //                 this.reportData.durationType  = 2;
            //                 setTimeout(function(){
            //                     $('#dateOfMonth-first').val('');
            //                     $('#monthly-check-second').prop('checked', true);
            //                 },500);
            //             }
            //         }
            //         if (data.response.frequencyType == 'Yearly') {
            //             if (!data.response.weekOfMonth) {
            //                 this.addFormData.monthOfYear = data.response.monthOfYear;
            //                 this.addFormData.frequencyStep = data.response.frequencyStep;
            //                 setTimeout(function(){
            //                     $('#monthOfYear').val(1);
            //                     $('#year-check-first').prop('checked', true);
            //                 },500);
            //             } else {
            //                 this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
            //                 this.addFormData.weekOfMonth = data.response.weekOfMonth;
            //                 this.addFormData.monthOfYear = data.response.monthOfYear;
            //                 setTimeout(function(){
            //                     $('#monthOfYear-first').val(1);
            //                     $('#year-check-second').prop('checked', true);
            //                 },500);
            //             }
            //         }
            
            this.reportData.reportId = this.reportId;
            this.apiService.update('report/updateReport', this.reportData).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.alertService.sweetMessage('success', "Report details updated successfully");

                        this.router.navigate(['/report']);
                    } else {
                        this.alertService.sweetMessage('error', data.responseMessage);
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        }
    }

    // getting report by Id
    getReportById() {
        this.apiService.getAll('report/getReportById?reportId=' + this.reportId).subscribe(
            data => {
                this.startLoading();
                    if (data.statusCode == 100) {
                    this.addFormData = data.response;
                    
                    this.user = data.response;
                    this.reportData.reportType = data.response.reportType;
                    this.radioSelected = data.response.frequencyType;
                    //this.checkItemCommunication();
                    if (data.response.frequencyType == 'Monthly') {
                        this.durationType = data.response.durationType



                        if (data.response.durationType == 1) {
                            this.monthDay = data.response.dateOfMonth;
                            this.addFormData.dateOfMonth = data.response.dateOfMonth;
                            this.addFormData.frequencyStep = data.response.frequencyStep;
                            setTimeout(function(){
                                $('#dateOfMonth-second').val('');
                                $('#monthly-check-first').prop('checked', true);
                            },500);
                        } else if(data.response.durationType == 2) {
                            this.addFormData.weekOfMonth = data.response.weekOfMonth;
                            this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
                            this.addFormData.dateOfMonth = data.response.frequencyStep//data.response.dateOfMonth;
                            setTimeout(function(){
                                $('#dateOfMonth-first').val('');
                                $('#monthly-check-second').prop('checked', true);
                            },500);
                        }

/*                         if (!data.response.weekdayOfMonth) {
                            this.monthDay = data.response.dateOfMonth;
                            this.addFormData.dateOfMonth = data.response.dateOfMonth;
                            this.addFormData.frequencyStep = data.response.frequencyStep;
                            setTimeout(function(){
                                $('#dateOfMonth-second').val('');
                                $('#monthly-check-first').prop('checked', true);
                            },500);
                        } else {
                            this.addFormData.weekOfMonth = data.response.weekOfMonth;
                            this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
                            this.addFormData.dateOfMonth = data.response.dateOfMonth;
                            setTimeout(function(){
                                $('#dateOfMonth-first').val('');
                                $('#monthly-check-second').prop('checked', true);
                            },500);
                        } */
                    }
                    // console.log(data.response.frequencyType,data.response.frequencyType== 'Yearly');
                    
                    if (data.response.frequencyType == 'Yearly') {

                        if (this.addFormData.durationType == 1) {
                            // console.log("----------------------");
                            
                            this.YearlyRadioSelected = 'yearFirst'
                            // this.addFormData.monthOfYear = data.response.monthOfYear;
                            this.yearlyMonth = data.response.monthOfYear;
                            this.addFormData.frequencyStep = data.response.dateOfMonth;

                        } else if (this.addFormData.durationType == 2) {
                            this.YearlyRadioSelected = 'yearSecond'

                            this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
                            this.addFormData.weekOfMonth = data.response.weekOfMonth;
                            this.addFormData.monthOfYear = data.response.monthOfYear;
                        }

                        // if (!data.response.weekOfMonth) {
                        //     this.addFormData.monthOfYear = data.response.monthOfYear;
                        //     this.addFormData.frequencyStep = data.response.frequencyStep;
                        //     this.YearlyRadioSelected = 'yearSecond'
                        //     setTimeout(function(){
                        //         $('#monthOfYear').val(1);
                        //         $('#year-check-first').prop('checked', true);
                        //         this.yearlyMonth = data.response.monthOfYear
                        //     },500);
                        // } else {
                        //     this.YearlyRadioSelected = 'yearFirst'
                        //     this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
                        //     this.addFormData.weekOfMonth = data.response.weekOfMonth;
                        //     this.addFormData.monthOfYear = data.response.monthOfYear;
                        //     setTimeout(function(){
                        //         $('#monthOfYear-first').val(1);
                        //         $('#year-check-second').prop('checked', true);
                        //     },500);
                        // }
                    }

                    this.checkDataForchecked = data.response.weekdayMap;

                    if (this.checkDataForchecked >= 64) {
                        // this.checkDataForchecked = this.checkDataForchecked - 32;
                        this.checkDataForchecked = this.checkDataForchecked - 64;
                        this.recurringPattern.isSaturday = true;
                    }
                    if (this.checkDataForchecked >= 32) {
                        // this.checkDataForchecked = this.checkDataForchecked - 16;
                        this.checkDataForchecked = this.checkDataForchecked - 32;
                        this.recurringPattern.isFriday = true;
                    }
                    if (this.checkDataForchecked >= 16) {
                        // this.checkDataForchecked - 8;
                        this.recurringPattern.isThursday = true;
                        this.checkDataForchecked = this.checkDataForchecked - 16;
                    }
                    if (this.checkDataForchecked >= 8) {
                        // this.checkDataForchecked = this.checkDataForchecked - 4;
                        this.checkDataForchecked = this.checkDataForchecked - 8;
                        this.recurringPattern.isWednesday = true;
                    }
                    if (this.checkDataForchecked >= 4) {
                        // this.checkDataForchecked = this.checkDataForchecked - 2;
                        this.checkDataForchecked = this.checkDataForchecked - 4;
                        this.recurringPattern.isTuesday = true;
                    }
                    if (this.checkDataForchecked >= 2) {
                        // this.checkDataForchecked = this.checkDataForchecked - 1;
                        this.checkDataForchecked = this.checkDataForchecked - 2;
                        this.recurringPattern.isMonday = true;
                    }
                     if (this.checkDataForchecked >= 1) {
                        // this.checkDataForchecked = this.checkDataForchecked - 64;
                        this.checkDataForchecked = this.checkDataForchecked - 1;
                        this.recurringPattern.isSunday = true;
                    }

                    for (let object of this.addFormData.tiers) {
                        let findObj = _.find(this.data, ['tierId', object.tierId]);
                        if (findObj) {
                            this.checkTreeArray.push(object.tierId.toString());
                        }

                    }

                    var self = this;
                    setTimeout(function(){
                        self.checkedCheckboxOnLoad();
                        if (self.data.length == self.checkTreeArray.length) {
                            self.selectAllTier = true            
                        }else{
                            self.selectAllTier = false
                        }
                    },1500); 

                    for (let siteObject of this.addFormData.sites) {
                        this.selectedsite.push({
                            'siteId': siteObject.siteId
                        });
                        this.checkSiteArray.push(siteObject.siteId.toString());
                    }

                    for (let assetObject of this.addFormData.assets) {
                        this.selectedasset.push({
                            'assetId': assetObject.assetId
                        });
                        this.checkAssetArray.push(assetObject.assetId.toString());
                    }

                    for (let customerObject of this.addFormData.customers) {
                        this.selectedcustomer.push({
                            'customerId': customerObject.customerId
                        });
                        this.checkCustomerArray.push(customerObject.customerId.toString());
                    }
                    if(this.addFormData.users){
                        if(this.addFormData.users.length == 1){
                            this.userListDataForTable.push(this.addFormData.users);
                            this.userListDataForTable1.push({'userId': this.addFormData.users[0]['userId']});
                            this.checkUserArray.push(this.addFormData.users[0]['userId'].toString());                           

                        }else{
                            for (let object of this.addFormData.users) {
                                this.userListDataForTable1.push({
                                    'userId': object['userId']
                                });
                                this.checkUserArray.push(object['userId'].toString());
                                this.userListDataForTable.push(object);
                            }
                        }
                    }

                    this.showTableInfo(this.checkUserArray);
                    this.customerIdForApi = this.checkCustomerArray.join();
                    this.siteIdForApi = this.checkSiteArray.join();
                    this.selectedItems = this.selectedcustomer;
                    this.selectedSite = this.selectedsite;
                    this.selectedAsset = this.selectedasset;
                    // this.selectedUser = this.userListDataForTable1;
                    
                    // if (this.data.length == this.checkTreeArray.length) {
                    console.log("this.checkTreeArray.length--",this.checkTreeArray.length);
                    console.log("this.addFormData.tiers.length--",this.addFormData.tiers.length);

                    console.log("this.selectedSite.length--",this.selectedSite.length);
                    console.log("this.selectedAsset.length--",this.selectedAsset.length);

    

                    // this.getUserListData();
                    // this.getSiteManagementList();
                    // this.getCustomerList();
                    // this.getAllAssetList();

                    for (var i = 0; i < this.addFormData.emails.length; i++) {
                        var add = this.addFormData.emails[i]['emailAddress'];
                         this.emailListTableData.push(add);
                    }

                    /*calling all function for first time load of all use site customer asset at the time of updating*/
                    if(this.checkTreeArray.length > 0) {
                        setTimeout(() => {
                            this.getUserListData();
                            this.getSiteManagementList();
                            this.getCustomerList();
                            this.getAllAssetList();
                        }, 1500);
                    }

                } else {
                    // this.alertService.sweetMessage('error', data.responseMessage);
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );

    }
    changeMonthlyThe(){
        this.addFormData.weekOfMonth = 1
        this.addFormData.weekdayOfMonth = 2
    }

    changeYearlytheval(){
        this.addFormData.weekOfMonth = 1
        this.addFormData.weekdayOfMonth = 2
        this.addFormData.monthOfYear = 1
    }

    // checked checkbox on load of tree
    checkedCheckboxOnLoad() {
        Object.keys(this.checkTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.checkTreeArray[key]);            
            this.myTree.expandItem(checkedItem);
            this.myTree.checkItem(checkedItem, true);
        });
    }

    // Getting Asset List
    getAllAssetList() {
        // var URL = 'asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId+'&isAssetLive=true';

        var URL = 'asset/getAssetDropDownByClient'

        let apiData: any = {
            clientId: this.clientId ? this.clientId : null,
            userId: this.userId ? this.userId : null
        }

        if (this.customerId != null && this.customerId != '') {
            apiData.customerId = this.customerId.toString();
            // var customerString = '&customerId=' + this.customerId.toString();
            // var URL = URL.concat(customerString);
        }
        // if (this.tierId != null && this.tierId != '') {
        //     var tierString = '&tierId=' + this.tierId.toString();
        //     var URL = URL.concat(tierString);
        // }
        this.tierIdForApi = this.checkTreeArray.join();
        if (this.tierIdForApi != null && this.tierIdForApi != '') {
            apiData.tierId = this.tierIdForApi.toString();
            // var tierString = '&tierId=' + this.tierIdForApi.toString();
            // var URL = URL.concat(tierString);
        }
        // if (this.checkSiteArray.length != 0) {
        //     this.site = this.checkSiteArray.toString();
        //     var siteString = '&siteId=' + this.site;
        //     var URL = URL.concat(siteString);
        // }
        if (this.siteIdForApi != null && this.siteIdForApi != '') {
            apiData.siteId = this.siteIdForApi.toString();
            // var siteString = '&siteId=' + this.siteIdForApi.toString();
            // var URL = URL.concat(siteString);
        }


        if (this.checkTreeArray.length == 0) {    
            this.assetDatalist = [];
            this.selectedAsset = [];
        }else {
            this.startLoading();
            this.apiService.create(URL, apiData).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        if(this.checkTreeArray.length != 0) {
                            this.assetDatalist = data.responseList;                        
                            let tempSelected = [];
                            this.selectedAsset.map((obj, index)=>{
                                let findObj = _.find(this.assetDatalist, { assetId: obj.assetId});
                                if (findObj) {
                                    tempSelected.push(obj);
                                }
                            })
                            this.selectedAsset = tempSelected;
                        }                        
                    } else {
                        this.assetDatalist = [];
                        this.selectedAsset = [];
                    }
                    //this.selectedAsset = [];
                    this.stopLoading();
                },
                error =>{
                    this.stopLoading();
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    }

    /* On Item Select Asset */
    onItemSelectAsset(item: any) {
        var exist = this.checkAssetArray.indexOf(item.assetId.toString());
        if (exist == '-1') {
            this.checkAssetArray.push(item.assetId.toString());
        }
    }

    /* On Item DeSelect Asset */
    onItemDeselectAsset(item: any) {
        var exist = this.checkAssetArray.indexOf(item.assetId.toString());
        if (exist != '-1') {
            this.checkAssetArray.splice(exist, 1);
        }
    }

    /* On All Select Asset */
    onSelectAllAsset(items: any) {
        this.checkAssetArray = [];
        Object.keys(items).forEach(key => {
            this.checkAssetArray.push(items[key]['assetId'].toString());
        });
    }

    /* On All DeSelect Site */
    onDeSelectAllAsset() {
        this.checkAssetArray = [];
    }

    getEnableDisableTier(data){
        for (let object of data) {
          if(object.flag){
            this.enableTreeArray.push(object.tierId.toString());
          }else{
            this.newDisableTreeArray.push(object.tierId.toString());
          }
        }
        this.disableTreeLength = this.newDisableTreeArray.length;
    }

    selectAllTree(event){
        this.startLoading();
        if (event.target.checked) {
            this.checkTreeArray = []
            for (let object of this.data) {
                this.checkTreeArray.push(object.tierId.toString());
            }
            this.checkedCheckboxOnLoad()
        }else{
            this.checkTreeArray = []
            this.checkedCheckboxOnLoad();
            for (let object of this.data) {
                let checkedItem = document.getElementById(object.tierId.toString());
                this.myTree.expandItem(checkedItem);
                this.myTree.checkItem(checkedItem, false);
            }
        }
        setTimeout(() => {
            this.getUserListData();
            this.getSiteManagementList();
            this.getCustomerList();
            this.getAllAssetList();
            this.stopLoading();
        }, 1000);

    }

    startLoading() {
        this.loading.start();
    }
    
    stopLoading() {
        this.loading.complete();
    }

}
