import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CLIENT-ADMIN';

constructor() {}
 
  ngOnInit(): void {
   /* const perm = ["ADMIN", "EDITOR"];
  
    this.permissionsService.loadPermissions(perm);
    
     this.http.get('url').subscribe((permissions) => {
       //const perm = ["ADMIN", "EDITOR"]; example of permissions
       this.permissionsService.loadPermissions(permissions);
    })*/
    $('.parentelement ngx-loading-bar').addClass('loading-bar-fixed');
    setTimeout( () => {
      setInterval( () => { 
        if($("#loading-bar-spinner").length == 0) {
          // console.log("id not exist");
          $('.parentelement ngx-loading-bar').removeClass('loading-bar-fixed');
        } else {
          // console.log("id exist");
          $('.parentelement ngx-loading-bar').addClass('loading-bar-fixed');
        }
      },500);
    }, 500);
  }
}


