import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerName : any = localStorage.getItem("customerName");
  assetDatalist: any = [{}];
  dataTable: any;
  customerId:number=0;
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  clientCode: any = '';
  permissionData:any=[];
  breadcrumbObj: any;

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private chRef: ChangeDetectorRef
    ) {
    this.permissionData = ApiService.permission;
   }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
      return this.formatDateService.formatDate(startDate, gmtOffset);
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
        if(params['id']){
        this.customerId = params['id'];
      }
    });
    if(this.customerId){
      this.getAssetListbyCustomer();
    }
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          // this.urlCustomerId = params.cus_id;
      })
  }

  // Function set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }

  /* Function for getting AssetList by Customer */
  getAssetListbyCustomer(){
    var URL = 'customer/getAssetListByCustomerAndClient?customerId='+this.customerId+'&clientId='+this.clientId;
    if(this.tierId != null && this.tierId !=''){
      var tierString = '&tierId='+this.tierId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetDatalist = data.responseList;
          if ($.fn.DataTable.isDataTable('#userAsset')){
                $('#userAsset').DataTable().destroy();
              };
          this.chRef.detectChanges();
          const table: any = $('#userAsset');
          this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]});
        }else{
          this.assetDatalist = [];
          this.chRef.detectChanges();
          const table: any = $('#userAsset');
          this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]});
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }
}
