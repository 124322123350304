import { Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  customerDatalist: any = [];
  dataTable: any;
  statusValue:any={};
  countryId:number=0;
  state:any='';
  countryList:any=[];
  dropdownSettingsState={};
  stateList:any=[];
  search:any='';
  checkStateArray:any=[];
  permissionData:any=[];
  clearstate : any = '';
  hrefUrl : any;
  imageUrl = "assets/img/user_icon.png";
  clientCode : any = '';
  userStatusFilter : any = 1;
  assignAsset:any=[];
  userList:any = [];
  selectedCustomerID:any = null;
  HistoryList: any = [];

  constructor(private router:Router, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
      this.permissionData = ApiService.permission;
  }

  // Function to format a date
  DateFormat(startDate){
    if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
      return "";
    }
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.getCustomerList();
    this.getCountryList();

    this.dropdownSettingsState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    
    this.hrefUrl = this.apiService.Customer_Url;

    // if(location.host == "demo.satalarm.com"){
    //   this.hrefUrl = "https://customer.satalarm.com/login?";
    // }else{
    //   this.hrefUrl = "https://s14.cdnsolutionsgroup.com/login?";
    // }
  }

  // Function for searching a record
  seachRecord(){
    this.state  = this.checkStateArray;
    this.getCustomerList();
  }

  /* Reset Search Record */
  resetSearch(){
    this.search     = "";
    this.countryId  = 0;
    this.state      = "";
    this.stateList  = [];
    this.checkStateArray = [];
    this.clearstate = "";
    this.userStatusFilter = 1;
    this.getCustomerList();
  }

  /* On Item Select State */
  onItemSelectState(item: any) {
      var exist = this.checkStateArray.indexOf(item.stateId.toString());
      if(exist == '-1'){
          this.checkStateArray.push(item.stateId.toString());
        }
    }

  /* On Item DeSelect State */
  onItemDeselectState(item: any){
    var exist = this.checkStateArray.indexOf(item.stateId.toString());
      if(exist != '-1'){
          this.checkStateArray.splice(exist,1);
        }
  }

  /* On All Select State */
  onSelectAllState(items: any) {
    this.checkStateArray = [];
    Object.keys(items).forEach(key=> {
        this.checkStateArray.push(items[key]['stateId'].toString());
      });
  }
  /* On All DeSelect State */
  onDeSelectAllState(){
    this.checkStateArray = [];
  }

  /* Get state list by country id */
  getStateListByCountryId(countryId){
    if(countryId != 0){
    this.clearstate = "";
    this.apiService.getAll('state/getStateList/'+countryId).subscribe(
     data => {
         if(data.statusCode == 100){
            this.stateList = data.responseList;
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
           }
        },
       error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
          );
    }
  }

  /* Get Country list */
  getCountryList(){
     this.apiService.getAll('country/getCountry').subscribe(
         data => {
           if(data.statusCode == 100){
              this.countryList = data.responseList;
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
             }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }


    /* Get Customer Management list */
    getCustomerList(){
      //customer/getCustomerListByClient?clientId=1&userId=3&countryId=1&stateId=1,2&tierId=1,32&search=a
       var URL = 'customer/getCustomerListByClient?clientId='+this.clientId;
      
      //  if(this.userId != 0){
      //   var userString  = '&userId='+this.userId;
      //   var URL = URL.concat(userString);
      // }

      if(this.countryId != 0){
        var countryString  = '&countryId='+this.countryId;
        var URL = URL.concat(countryString);
      }

      if(this.state != ''){
        var stateString    = '&stateId='+this.state;
        var URL = URL.concat(stateString);
      }
      if(this.customerId != null && this.customerId !=''){
        var customerString     = '&customerId='+this.customerId.toString();
        var URL = URL.concat(customerString);
      }
      // if(this.tierId != null && this.tierId !=''){
      //   var tierString     = '&tierId='+this.tierId.toString();
      //   var URL = URL.concat(tierString);
      // }
       if(this.search !=''){
        var searchString   = '&search='+this.search;
        var URL = URL.concat(searchString);
      }


      if(this.userStatusFilter == 0 && this.userStatusFilter.trim() != ''){
        var searchFilter   = '&isActive=0';
        var URL = URL.concat(searchFilter); 
      }
      if(this.userStatusFilter == 1){
        var searchFilter2   = '&isActive=1';
        var URL = URL.concat(searchFilter2);
      }

       this.apiService.getAll(URL).subscribe(
       data => {
           if(data.statusCode == 100){
              this.customerDatalist = data.responseList;
              var user = JSON.parse(localStorage.getItem("userData"));
              if(user.customers.length != data.responseList.length){
                user.customers = data.responseList;
                localStorage.setItem("userData", JSON.stringify(user));
                this.router.navigate(['/customers']);
              }
              // if(this.customerDatalist.imageLocation){
              //   this.imageUrl = this.apiService.imageBasePath + this.customerDatalist.imageLocation;
              // }else {
              //   this.imageUrl = "assets/img/user_icon.png"
              // }
              // console.log(this.imageUrl)
              if ($.fn.DataTable.isDataTable('#customer_table')){
                    $('#customer_table').DataTable().destroy();
              };
              this.chRef.detectChanges();
              const table: any = $('#customer_table');
              // this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,7,8,9,10] }]});
              this.dataTable = table.DataTable({"order": [], "search": { regex: false, smart: false },"columnDefs": [{ "orderable": false, "targets": [0,7,8,9,10] }]});
            }else{
              this.customerDatalist = [];
              if ($.fn.DataTable.isDataTable('#customer_table')){
                    $('#customer_table').DataTable().destroy();
              };
              this.chRef.detectChanges();
              const table: any = $('#customer_table');
              // this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,7,8,9,10] }]});
              this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,7,8,9,10] }]});
             }
          },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
    }

    /* Change Customer Status */
    ChangeCustomerStatus(id,status){
      Swal({
          title: 'Are you sure?',
          text: "You want to Change Status.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change it!'
        }).then((result) => {
        if (result.value) {
          if(status == false){
            this.statusValue = {"userId":id,"isActive":1};
          }else{
            this.statusValue = {"userId":id,"isActive":0};
          }

         this.apiService.update('user/updateUserStatus',this.statusValue).subscribe(
         data => {
             if(data.statusCode == 100){
                  this.alertService.sweetMessage('success','Status Changed Successfully');
                  this.getCustomerList();
                  this.router.navigate(['/customer/ref']);
              }else{
                this.alertService.sweetMessage('error',data.responseMessage);
              }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
              );
            }
          })
    }

    /* Delete Customer Management list */
    DeleteCustomer(customerdata){
         Swal({
          title: 'Are you sure?',
          text: "You want to delete this Customer.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
        if (result.value) {
         this.apiService.delete('customer/deleteCustomer?customerId='+customerdata.customerId, "").subscribe(
         data => {
             if(data.statusCode == 100){
               this.alertService.sweetMessage('success',"Customer Deleted Successfully");
               console.log("---------------------");
               this.getCustomerList();

               console.log("================================");
               this.router.navigate(['/customers/del']);
               console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^");
              }else{
               this.alertService.sweetMessage('error',data.responseMessage);
               }
            },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
              );
            }
        })
    }

    getAssignAssets(customerdatalist){
      this.assignAsset = [];
      var URL = 'asset/getAssetByClient';
      if ($.fn.DataTable.isDataTable('#assignAssetTable')){
        $('#assignAssetTable').DataTable().destroy();
      };   
      let apiData: any = {
        clientId: this.clientId,
        userId: this.userId,
        isAssetLive: true,
        customerIds: customerdatalist.customerId.toString()
      }
      this.apiService.create(URL, apiData).subscribe(
            data => {
                if (data.statusCode == 100) {
                  this.assignAsset = [];
                  this.assignAsset = data.responseList;
                  this.chRef.detectChanges();
                  setTimeout(() => {
                    const table: any = $('#assignAssetTable');
                     table.DataTable({});
                  }, 100);
                } else {
                  this.assignAsset = [];
                  setTimeout(() => {
                    const table: any = $('#assignAssetTable');
                     table.DataTable({});
                  }, 100);
                }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
      );
    }

    getUserList(customerdatalist){
      this.userList = [];
      this.selectedCustomerID = customerdatalist.customerId
      var URL = 'customer/getUserListByCustomerAndClient?customerId='+customerdatalist.customerId+'&clientId='+this.clientId
      if ($.fn.DataTable.isDataTable('#userTable')){
        $('#userTable').DataTable().destroy();
      };   
      this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                  this.userList = data.userList;
                  this.chRef.detectChanges();
                  setTimeout(() => {
                    const table: any = $('#userTable');
                    this.dataTable = table.DataTable({"bInfo": false});
                  }, 100);
                } else {
                  this.userList = [];
                }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
      );
    }

  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('customer/getCustomerHistoryById?customerId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0] }]});
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0] }]});
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
}
