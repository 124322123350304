import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, CommonService } from 'src/app/services';
import Swal from 'sweetalert2'
import * as _ from "lodash";
import { number } from '@amcharts/amcharts4/core';

declare var $: any; // JQuery

@Component({
  selector: 'app-manage-dashboard',
  templateUrl: './manage-dashboard.component.html',
  styleUrls: ['./manage-dashboard.component.css']
})
export class ManageDashboardComponent implements OnInit {

  clientId: any = null;
  userId: any = null;
  dashboardId: any = null;
  dashboardData: any = {
    name: "",
    dashboardWidgets:  [],
    isDefault: true,
  }
  widgetsData:any = []
  AllWidgetsList: any = []
  AllWidgetsMapList: any = []
  AllWidgetsRuntimeList: any = []
  AllWidgetsSiteList: any = []
  clientCode: any = null;
  tempArray = [];
  tempDashboardData:any = {}
  PreviewObjData:any = {}
  tierList:any = []
  dashboardOrderData = [];
  isExist:boolean = false;
  isExistDashboardErrorMsg:string = '';
  dashboard:string = '';
  linkDashboardId:any ;

  constructor(private commonService:CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.dashboard = this.commonService.currentBreadcrumb;
    this.linkDashboardId = this.commonService.dashboardId;
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.dashboardId = params['id'];
        if (this.dashboardId) {
          this.getDashboardById();
          this.getOrderSequenceDropdown();

        }
      }
    });
    this.getAllWidgets();
    this.clientCode = this.alertService.getClientCode();

    this.clientId = localStorage.getItem("clientId");
    this.userId = localStorage.getItem("userId");

    this.getTierManagementList();

    // var options = {
    //   cell_height: 40,
    //   vertical_margin: 10,
    //   acceptWidgets: '.grid-stack-item'
    // };
    // $('.grid-stack').gridstack(options);

    // var maxHeight = 0;
    // $(".box-hk").each(function(){
    // if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
    // });
    // $(".box-hk").height(maxHeight);
  }  

  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log("===================================", changes);

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    var options = {
      cell_height: 40,
      vertical_margin: 10,
      acceptWidgets: '.grid-stack-item'
    };
    $('.grid-stack').gridstack(options);
      //change event for get all widgets
    var self = this
    $('.grid-stack').on('added', function(event, items) {
      var grid = $(this).data('gridstack');
      var items = grid.grid.nodes;
      self.tempArray = items;
      self.sortArray()
      self.setOrderId()
    });

    $('.grid-stack').on('change', function(event) {
      // self.sortArray()   
      // self.setOrderId()
    });     
    
    $('.grid-stack').on('removed', function(event, items) {
        var grid = $(this).data('gridstack');
        var items = grid.grid.nodes;
        self.tempArray = items;
        self.sortArray()
        self.setOrderId()
    });
  }
  
  /* Get Site Management list */
  getTierManagementList() {
    var URL = 'tier/getTiersByClient?clientId='+this.clientId+'&userId='+this.userId+'&alphabetSortingOrder=1'
    // var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
    this.apiService.getAll(URL).subscribe(
        data => {
            if (data.statusCode == 100) {
                this.tierList = data.responseList;
            } else {
                this.tierList = [];
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }

  sortArray() {
    this.tempArray.sort(function(a,b){
      if (a.x > b.x) return 1;
      if (a.x < b.x) return -1;        
    })
    this.tempArray.sort(function(a,b){  
      if (a.y > b.y) return 1;
      if (a.y < b.y) return -1;        
    })
  }

  sortDashboard() {
    var self = this;
    if(this.dashboardData.dashboardWidgets.length) {
      this.dashboardData.dashboardWidgets.sort(function(a,b){
        if (a.widgetOrder > b.widgetOrder) return 1;
        if (a.widgetOrder < b.widgetOrder) return -1;        
      })   
    }    
  }

  //setting ID 
  setOrderId() {      
    var self = this
    if(this.tempArray.length && this.tempArray.length == this.dashboardData.dashboardWidgets.length) {
      this.tempArray.map(function(data,index){
        let id = data.id;
        let order = index;
        var setOrderIndex = self.settingWidgetsOrder(id,order)
    })
    } 
    this.sortDashboard()   
  }
  
  //set widgets order as per arrangement 
  settingWidgetsOrder(id,order) {
    var self = this
    if (this.dashboardData.dashboardWidgets.length) {
        this.dashboardData.dashboardWidgets.map(function(data,index){
        if(data.uniqueIndex == id) {
          //putting order value 
          self.dashboardData.dashboardWidgets[index].widgetOrder = order;
          self.dashboardData.dashboardWidgets[index].orderSequence = order;
        }
      })
    }
  } 

  getAllWidgets() {
    this.apiService.getAll('widget/getWidgetsByCategory').subscribe(
      data => {
        if (data.statusCode == 100) {


          this.AllWidgetsList = data.datapointList ? data.datapointList : []
          this.AllWidgetsMapList = data.mapList ? data.mapList : []
          this.AllWidgetsRuntimeList = data.runtimeList ? data.runtimeList : []
          this.AllWidgetsSiteList = data.siteList ? data.siteList : []
        } else {

        }

      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getOrderSequenceDropdown() {
    this.apiService.getAll('dashboard/getOrderSequenceDropDown?type='+'Dashboard').subscribe(data => {
      this.dashboardOrderData = data.responseList ? data.responseList : {} ;
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
    )
  }

  getDashboardById() {
    var self = this;
    this.apiService.getAll('dashboard/getDashboardByDashboardId?dashboardId=' + this.dashboardId).subscribe(
      data => {
        this.dashboardData = data.response ? data.response : {}
        if (this.dashboardData.dashboardWidgets.length) {
          this.dashboardData.dashboardWidgets.map((data,index)=>{
            if(!this.dashboardData.dashboardWidgets.uniqueIndex) {
              self.dashboardData.dashboardWidgets[index].uniqueIndex = Math.floor(1000 + Math.random() * 9000) ;
            }              
          })      
        }
        console.log(this.dashboardData);
        setTimeout(() => {
          var options = {
            cellheight: 40,
            verticalMargin: 10,
            acceptWidgets: '.grid-stack-item'
          };
          $('.grid-stack').gridstack(options);
        }, 1000);

      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  saveDashboard(addDashboard) {
    console.log(addDashboard.valid);
    var self = this;
    this.sortArray()
    this.setOrderId()
    this.dashboardData.dashboardWidgets.forEach(function(item,index){
      var obj:any = {
        "widgetId": item.widgetId,
        "caption": item.caption,
        "widgetOrder":item.widgetOrder,
        "timeRange": item.timeRange ? item.timeRange : null
      };
      if (item.dashboardWidgetId) {
        obj.dashboardWidgetId = item.dashboardWidgetId
      }
      self.widgetsData.push(obj);
    })
    // console.log("widgetData=========",this.widgetsData, this.dashboardData.dashboardWidgets)
    // return
    addDashboard.submitted = true;
    if(!addDashboard.valid) {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
    }
    if (addDashboard.valid) {
      this.tempDashboardData = {};
      this.tempDashboardData = JSON.parse(JSON.stringify(this.dashboardData))
      this.tempDashboardData.dashboardWidgets = []
      this.tempDashboardData.dashboardWidgets = this.widgetsData

      if (!this.dashboardData.name) {
        document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
        this.alertService.sweetMessage('error', "name is required.");
        return
      }

      if (!this.dashboardId) {
        this.apiService.create('dashboard/createDashboard', this.tempDashboardData).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.alertService.sweetMessage('success', data.responseMessage);
              this.router.navigate(["mydashboard"])
              this.widgetsData = [];
            } else {
              this.widgetsData = [];
            }
            if (data.statusCode == 101) {
              document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
              this.isExist = true;
              this.isExistDashboardErrorMsg = data.responseMessage
            }
          },
          error => {
            this.widgetsData = [];
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      } else {
        this.apiService.update('dashboard/updateDashboard', this.tempDashboardData).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.alertService.sweetMessage('success', data.responseMessage);
              this.router.navigate(["mydashboard"])
              this.widgetsData = [];
            } else {
              this.widgetsData = [];
            }
            if (data.statusCode == 101) {
              document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
              this.isExist = true;
              this.isExistDashboardErrorMsg = data.responseMessage
            }
          },
          error => {
            this.widgetsData = [];
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    }
    this.widgetsData = [];
    console.log(this.dashboardData);

    /* {
    "name":"Runtime Dashboard11",
    "isDefault":true,
    "dashboardWidgets":[
          {
              "widgetId":1,
              "caption":"Map Dashboard",
              "widgetOrder":10,
              "timeRange":15
          },
          {
          	"widgetId":2,
              "caption":"Map Dashboard",
              "widgetOrder":11,
              "timeRange":15
          }
    ]
} */

  }
  
  //added key "uniqueIndex,orderSequence,index"
  addWidget(widgetsData) {
    if(this.dashboardData.dashboardWidgets.length >= 12){
      this.alertService.sweetMessage('error', "Can't add more than 12 widgets");return;
    }
    
    let obj = {
      "widgetId": widgetsData.widgetId,
      "caption": widgetsData.widgetName,
      "widgetOrder": widgetsData.ordering,
      "timeRange": null,
      "uniqueIndex":Math.floor(1000 + Math.random() * 9000),
      "orderSequence":0,
      width:widgetsData.width
    }
    if (!this.dashboardData.dashboardWidgets) {
      this.dashboardData.dashboardWidgets = []
      this.dashboardData.dashboardWidgets.push(obj);
    } else {
      this.dashboardData.dashboardWidgets.push(obj);
    }
    // $('.grid-stack').gridstack();
    // var grid = $('.grid-stack').data('gridstack');
    // grid.addWidget(el, 0, 0, 3, 2, true);
  }

  RemoveWidget(widgetObj, i) {
    console.log(widgetObj, i);       
    this.dashboardData.dashboardWidgets.splice(i, 1);
  }

  getTotalSelect(widgetsData) {
    // console.log(widgetsData);

    if (widgetsData) {


      let id = widgetsData.widgetId
      let count = 0;
      if (this.dashboardData.dashboardWidgets) {
        if (this.dashboardData.dashboardWidgets.length) {
          this.dashboardData.dashboardWidgets.map((obj, index) => {
            if (obj.widgetId == id) {
              count++;
            }
          })
        }
        return count;
      } else {
        return 0
      }
    } else
      return 0

  }

  removeAndSave(addDashboard) {
    Swal({
      title: 'Are you sure?',
      text: "You want to Delete All Widgets & Save?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.dashboardData.dashboardWidgets = [];
        this.saveDashboard(addDashboard);
      }
    })
  }

  onDragStart(event){ 
    console.log("i am dragged")
  }

  onDragStop(event){
    this.sortArray()   
    this.setOrderId()
  }  

  PreviewModel(Widgets){
    this.PreviewObjData = Widgets;
  }
  
  PreviewModelClose(){
    this.PreviewObjData = {}
  }

  checkDashbaordName() {
    this.isExist = false;
  }
}
