import { Component, OnInit, ChangeDetectorRef, Output, Input, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, FormatDateService } from 'src/app/services';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import * as _ from "lodash";
import Swal from 'sweetalert2';
import { timeout } from 'rxjs-compat/operator/timeout';

@Component({
  selector: 'app-tew-edit',
  templateUrl: './tew-edit.component.html',
  styleUrls: ['./tew-edit.component.css']
})
export class TEWEditComponent implements OnInit {

  

  // runTimeEventId = null;
  runTimeEventObj:any = {};
  runTimeObj:any = {};

  // eventType:any = '';
  clientId:any = null;
  clientCode:any = null;
  userId:any = null;
  rollUpdataList:any = [];
  // assetId:any = null;
  rcpId:any = null;
  assetsumaryData:any = {};
  reasonListData : any = [];
  technicianList : any = [];
  responsbilityList : any = [];
  firstFaultResponsibilityArr : any = [];
  tempArr : any = [];
  runTimeEventResponsibilities : any = [];
  responsibilityArr : any = [];

  reasonCodeId : any = '';
  technicianId : any = '';
  comment : any = '';
  notificationId : any = '';

  updateruntimeObj : any =  {
    "runTimeEventId": '',
    "reasonCode": {
        "reasonCodeId": ''
    }
  }

  currClientId: any = localStorage.getItem("clientId");
  assignedContactListData : any = [];
  eventIdArr : any = [];
  sMSMsg : any = '';
  emailMsg : any = '';

  addReasonCodeData:any = {
    "name" : "",
    "client" : {
       "clientId" : (localStorage.getItem("clientId") ? JSON.parse(localStorage.getItem("clientId")) : '')
    }
  }    
  rollUpList : any = [];
  performAction : any = 0;
  permissionData : any = [];
  remainingTimeToAllocate : any = '';
  dataTable: any;
  licenseIsTechEntry : any = '';
  notficationType : any = '';
  assignOwnerTechObj : any = {};
  eventStatus : any = '';
  // isParent : any = '';
  sumOfDowntime : number = 0;
  listMemberIdArr : any = [];
  wffEventDetailData : any = [];
  childEventFirstFaultCode : any = '';
  isChildEventsInDetail : any;
  total_duration : number = 0;
  remaining_mins : number = 0;
  unassignedTechnicianList : any = [];
  usersArr : any = [];
  newTechnicianId : any = '';
  userIdArr : any = [];
  mode : any = 'edit';
  // eventListType : any;
  totalDowntime : number = 0;    
  /***
   This parameter is user for showing Update and close button 
    i.e. if all responsilbilities are filled then it will be active
  ***/
 closeActive : number = 0; 
  heading : any = '';
  testArr :any = [];
  firstFaultCodeSelected : number = 0;
  dropdownSettings: any = {};
  selectedTechnicians : any = [];
  unassignedSelectedTechnicians : any = [];
  unassignedDropdownSettings: any = {};
  showAckBtn : number = 0;
  showAckErcBtn : number = 0;
  showERC : number = 0;
  isAck : number = 0;

  runtimeAckData : any =  {
    "runtimeEvent":{
      "runTimeEventId":''
    },
    "isAck":true,
    "user":{
      "userId": (localStorage.getItem("userId") ? JSON.parse(localStorage.getItem("userId")) : '')
    },
    "agentType":"WEB"
  };

  ackData : any =  {
    "wffNotification":{
      "wffNotificationId":''
    },
    "isAck":true,
    "user":{
      "userId": (localStorage.getItem("userId") ? JSON.parse(localStorage.getItem("userId")) : '')
    },
    "agentType":"WEB"
  };
  runTimeWffEventObj : any = [];
  showAckTime : any = '';
  // assignToMe : number = 0;
  // isAckTag : number = 0;
  ackUserList : any = [];
  ackUserListString : any;
  
  EditButtonShow:any = true
  ShowResponsibilitySection:any = true
  EventDataFlags:any = true
  UseFirstFault:any = null
  normalAckButtonShow = false
  viewtechnicianList:any = [];
  ReasonCodeError = null;     
  tierLength:number = 0;      
  breadcrumbObj: any;
  pathCustID: any;
  pathcust_name: any;
  pathAssetId: any;
  DashboardName: any;
  dasboardID: any;
  apiCall: any = 0;
  standBy:any = false;
  // licensType: any = 3;
  /* 
  
  this.licensType = params['licensType'] ?  params['licensType'] : this.licensType;
  this.eventType = params['type'];
  this.runTimeEventId = params['id'];
  this.isParent = params['isParent'];
  this.eventListType = params['eventListType'];
  this.assignToMe = params['assignToMe'];
  this.isAckTag = params['isAck'];
  
*/

@Input('licensType') licensType: any;
@Input('type') eventType: any;
@Input('id') runTimeEventId: any;
@Input('isParent') isParent: any;
@Input('eventListType') eventListType: any;
@Input('assignToMe') assignToMe: any;
@Input('isAck') isAckTag: any;
@Input('assetId') assetId: any;


  @Output('myOutputVal') myOutputVal = new EventEmitter(); 

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private apiService: ApiService, 
    private formatDateService: FormatDateService, 
    private alertService: AlertService,
    private chRef: ChangeDetectorRef
    ) { 
    this.permissionData = ApiService.permission;
  }

   ngOnInit() {
      this.dropdownSettings = {
          singleSelection: false,
          idField: 'userId',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 0,
          allowSearchFilter: true
      };

      this.unassignedDropdownSettings = {
          singleSelection: false,
          idField: 'userId',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 0,
          allowSearchFilter: true
      };

      this.clientCode = this.alertService.getClientCode();

      this.route.params.subscribe(params => {
          if (params['id']) {
              // this.assetId = params['id'];                
          }
          
      });
      this.clientId = localStorage.getItem("clientId");
      this.userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : 0 ;

      this.route
          .queryParams
          .subscribe(params => {
            
              // this.licensType = params['licensType'] ?  params['licensType'] : this.licensType;
              // this.eventType = params['type'];
              // this.runTimeEventId = params['id'];
              // this.notificationId = params['notificationId'];
              // this.rcpId = params['rcpId'];
              // this.performAction = params['performAction'];  
              // this.showAckBtn = params['showAckBtn']; 
              // this.showAckErcBtn = params['showAckErcBtn'];
              // this.showERC = params['showERC'];
              // this.notficationType = params['type'];
              // this.licenseIsTechEntry = params['licenseIsTechEntry'];
              // this.isParent = params['isParent'];
              // this.eventListType = params['eventListType'];
              // this.assignToMe = params['assignToMe'];
              // this.isAckTag = params['isAck'];

              console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq", this.apiCall);
              
              // this.route
              // .queryParams
              // .subscribe(params => {
                  // this.breadcrumbObj = this.breadcrumbObj ? this.breadcrumbObj : params['source'];
                  // this.pathCustID = this.pathCustID ? this.pathCustID : params['cus_id'];
                  // this.pathAssetId = this.pathAssetId ? this.pathAssetId : params['aId'];
                  // this.pathcust_name = this.pathcust_name ? this.pathcust_name : params['cust_name'];            
                  // this.dasboardID = this.dasboardID ? this.dasboardID : params['dasboardID'];            
                  // this.DashboardName = this.DashboardName ? this.DashboardName : params['DashboardName'];            
              // })
              if (this.apiCall) {
                this.changeFlag()
              }
          });


          if(this.eventListType == 'liveFeeds') {
            this.heading = 'Runtime Event Root Cause Entry';
          } else if(this.eventListType == 'approval') {
            this.heading = 'Items Waiting for Approval';
          } else if(this.eventListType == 'approved') {
            this.heading = 'Approved Items';
          }


          // if (this.eventType == "Notification") {
          //   if(this.eventListType == 'liveFeeds') {
          //     this.getInstructionsInfo();  
          //   }              
          //   // this.getRunTimeEventDetails();
          //   this.getWffEventDetails();
          //   this.getWffEventByID(); 
          // }else if(this.eventType == "RunTime") {
          //   //this.getRunTimeEvenListForreasonCode();

          //   this.getRunTimeEventDetails();
          //   this.getMemberResponsibility();
          //   if(this.eventListType == 'liveFeeds') {
          //     this.getInstructionsInfo();  
          //   }
          // } 
         
        //  --------------------------------------for page uncomment this section
          if (this.eventType == "Notification") {
            if (this.notificationId) {
              this.apiCall++;
              this.getWffFlagDetails(true)
            }
          }else if(this.eventType == "RunTime") {
            if (this.runTimeEventId) {
              this.apiCall++;
              this.getEventByID(true)
            }
            
          } 
          // --------------------------------------------------------------end
          // this.getWffEventByID();
          console.log(
             this.licensType, this.eventType, this.runTimeEventId, this.isParent, this.eventListType, this.assignToMe,this.isAckTag
            );

            setTimeout(() => {
              console.log(
                this.licensType, this.eventType, this.runTimeEventId, this.isParent, this.eventListType, this.assignToMe,this.isAckTag
               );
            }, 1000);
          
            setTimeout(() => {
              console.log(
                this.licensType, this.eventType, this.runTimeEventId, this.isParent, this.eventListType, this.assignToMe,this.isAckTag
               );
            }, 3000);
          
  }

  changeFlag(){
    console.log("00000000000000000000000000000000000000000000000000");
    
    if (this.eventType == "Notification") {
      if (this.notificationId) {
        this.getWffFlagDetails(false)
      }
    }else if(this.eventType == "RunTime") {
      if (this.runTimeEventId) {
        this.getEventByID(false)
      }
    }
  }

  getWffFlagDetails(flag){
    // https://demo.satalarm.com/client/api/v1/wffnotification/getTEWPermissionByWffNotificationEventId?notificationId=9809056
    this.apiService.getAll('wffnotification/getTEWPermissionByWffNotificationEventId?notificationId='+ this.notificationId ).subscribe(
      data => {
        if (data.statusCode == 100) {

          let eventData = data.response
          // this.EventDataFlags = data.response


          // ack: false
          // acknowledgeByMe: false
          // assignToMe: true
          // isApproved: false
          // isChild: false
          // isParent: false
          // licenseIsAck: true
          // licenseIsTechEntry: true
          // runTimeCollectionPointId: 5711
          // runTimeEventId: 828665
          // runTimeStatus: 1
          // technicianId: null

          let runTimeStatus = eventData.runTimeStatus;
          let licenseIsAck = eventData.licenseIsAck;
          let acknowledgeByMe = eventData.acknowledgeByMe;
          let assignToMe = eventData.assignToMe;
          let technicianId = eventData.technicianId;
          let licenseIsTechEntry = eventData.licenseIsTechEntry;
          let isAck = eventData.isAck;

          if (this.userId == eventData.technicianId && runTimeStatus == 2) {
            this.performAction = 1;
          }


          if (licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
            this.showAckBtn = 1;
          } else {
            this.showAckBtn = 0;
          }
//           if (licenseIsAck == true /* && acknowledgeByMe == false */ && assignToMe == true  /* && licenseIsTechEntry == true */ ) {
//             console.log('in if detail');
//             this.normalAckButtonShow = true;
//           } else {
//             this.normalAckButtonShow = false;
//             console.log('in else detail');
//           }

//           if ( licenseIsAck == true && acknowledgeByMe == false && assignToMe == true && (technicianId == null || technicianId == 0) && licenseIsTechEntry == true && runTimeStatus == 1 ) {
//             console.log('in if detail showAckErcBtn');
//             this.showAckErcBtn = 1;
//           } else {
//             this.showAckErcBtn = 0;
//             console.log('in else detail showAckErcBtn');
//           }

//           if ( licenseIsAck == true /* && assignToMe == true */ && (technicianId == null || technicianId == 0) && runTimeStatus == 1 ) {
//             this.showERC = 1;
//           } else {
//             this.showERC = 0;
//           }

//           let obj = {
//             id: null,
//             type: null
//           }
//           let pVal = 0;
//           if (eventData.isParent == false) {
//             pVal = 0;
//           } else if (eventData.isParent == true) {
//             pVal = 1;
//           }
//           obj.type = eventData.notificationType;
//           if (eventData.notificationType == "Notification") {
//             obj.id = eventData.notificationId;
//           } else if (eventData.notificationType == "RunTime") {
//             obj.id = eventData.runTimeEventId;
//           }
//           if (this.eventListType == 'approval' || this.eventListType == 'approved') {
//             obj.type = 'RunTime';
//             obj.id = eventData.runTimeEventId;
//             this.performAction = 1;
//             eventData.licenseIsTechEntry = true;
//           }

//           // this.router.navigate(["eventDetails", eventData.assetId,], 
//           //   {
//           //     queryParams: 
//           //     { 
//           //       type: obj.type, 
//           //       id :obj.id, 
//           //       rcpId:eventData.runTimeCollectionPointId, 
//           //       notificationId : eventData.notificationId, 
//           //       isParent : pVal, 
//           //       performAction : this.performAction, 
//           //       showAckBtn : this.showAckBtn,
//           //       showAckErcBtn : this.showAckErcBtn,
//           //       showERC : this.showERC,
//           //       licenseIsTechEntry : eventData.licenseIsTechEntry, 
//           //       eventListType : this.eventListType,
//           //       assignToMe : assignToMe == true ? 1 : 0,
//           //       isAck : isAck == true ? 1 : 0
//           //     }
//           // });
// // *********TODO***********TODO*****************TODO****************TODO*******************TODO*********************TODO*******************TODO*******TODO******TODO
//           // this.eventType = obj.type//params['type'];//==================================================take from url params
//           // this.notificationId = eventData.notificationId//params['notificationId'];
//           // this.showAckBtn = params['showAckBtn']; 
//           // this.showAckErcBtn = params['showAckErcBtn'];
//           // this.showERC = params['showERC'];
//           this.runTimeEventId = eventData.runTimeEventId//obj.id//params['id'];
//           this.rcpId = eventData.runTimeCollectionPointId//params['rcpId'];
//           this.performAction = this.performAction//params['performAction'];  
//           this.notficationType = this.eventType//obj.type//params['type'];
//           this.licenseIsTechEntry = eventData.licenseIsTechEntry//params['licenseIsTechEntry'];
//           this.isParent = pVal //params['isParent'];
//           // this.eventListType = params['eventListType'];
//           this.assignToMe = assignToMe == true ? 1 : 0//params['assignToMe'];
//           this.isAckTag = isAck == true ? 1 : 0//params['isAck']; //===================================------------------------  change when available in api response


//           // this.assignedContactListData = data.responseList;

//           console.log(this.notficationType, "runTimeEventId::",this.runTimeEventId,this.eventListType,"performAction::",this.performAction,".showAckBtn :: ", this.showAckBtn );
          
        } else {
          // this.assignedContactListData =[];
          /* this.alertService.sweetMessage('error',data.responseMessage); */
        }

        if (flag) {
          if (this.eventType == "Notification") {
            if (this.eventListType == 'liveFeeds') {
              this.getInstructionsInfo();
            }
            // this.getRunTimeEventDetails();
            this.getWffEventDetails();
            this.getWffEventByID();
          } else if (this.eventType == "RunTime") {
            //this.getRunTimeEvenListForreasonCode();

            this.getRunTimeEventDetails();
            this.getMemberResponsibility();
            if (this.eventListType == 'liveFeeds') {
              this.getInstructionsInfo();
            }
          }
        }

      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

getEventByID(flag) {
  this.apiService.getAll('runTimeEvent/getTEWPermissionByRunTimeEventId?runTimeEventId=' + this.runTimeEventId).subscribe(
    data => {
      if (data.statusCode == 100) {
        let eventData = data.response
        this.EventDataFlags = data.response


        // ack: false
        // acknowledgeByMe: false
        // assignToMe: true
        // isApproved: false
        // isChild: false
        // isParent: false
        // licenseIsAck: true
        // licenseIsTechEntry: true
        // runTimeCollectionPointId: 5711
        // runTimeEventId: 828665
        // runTimeStatus: 1
        // technicianId: null

        let runTimeStatus = eventData.runTimeStatus;
        let licenseIsAck = eventData.licenseIsAck;
        let acknowledgeByMe = eventData.acknowledgeByMe;
        let assignToMe = eventData.assignToMe;
        let technicianId = eventData.technicianId;
        let licenseIsTechEntry = eventData.licenseIsTechEntry;
        let isAck = eventData.isAck;

        if (this.userId == eventData.technicianId && runTimeStatus == 2) {
          this.performAction = 1;
        }
        // {
        //   "runTimeEventId": 1209451,
        //   "runTimeStatus": 1,
        //   "technicianId": null,
        //   "acknowledgeByMe": false,
        //   "assetId": 26397,
        //   "runTimeCollectionPointId": 5971,
        //   "isAck": false,
        //   "isParent": false,
        //   "isChild": false,
        //   "assignToMe": true,
        //   "isApproved": false,
        //   "isNormalAck": false,
        //   "isAlarmAck": false,
        //   "isNormalAvaiable": false,
        //   "licenseIsAck": true,
        //   "licenseIsTechEntry": true
        // }

        if (licenseIsAck == true && acknowledgeByMe == false && assignToMe == true && this.notificationId) {
          this.showAckBtn = 1;
        } else {
          this.showAckBtn = 0;
        }
        if (licenseIsAck == true /* && acknowledgeByMe == false */ && assignToMe == true  /* && licenseIsTechEntry == true */ ) {
          this.normalAckButtonShow = true;
        } else {
          this.normalAckButtonShow = false;
        }

        if ( licenseIsAck == true && acknowledgeByMe == false && assignToMe == true && (technicianId == null || technicianId == 0) && licenseIsTechEntry == true && runTimeStatus == 1 && this.notificationId) {
          this.showAckErcBtn = 1;
        } else {
          this.showAckErcBtn = 0;
        }

        if ( licenseIsAck == true /* && assignToMe == true */ && (technicianId == null || technicianId == 0) && runTimeStatus == 1 ) {
          this.showERC = 1;
        } else {
          this.showERC = 0;
        }

        let obj = {
          id: null,
          type: null
        }
        let pVal = 0;
        if (eventData.isParent == false) {
          pVal = 0;
        } else if (eventData.isParent == true) {
          pVal = 1;
        }
        obj.type = eventData.notificationType;
        if (eventData.notificationType == "Notification") {
          obj.id = eventData.notificationId;
        } else if (eventData.notificationType == "RunTime") {
          obj.id = eventData.runTimeEventId;
        }
        if (this.eventListType == 'approval' || this.eventListType == 'approved') {
          obj.type = 'RunTime';
          obj.id = eventData.runTimeEventId;
          this.performAction = 1;
          eventData.licenseIsTechEntry = true;
        }

        // this.router.navigate(["eventDetails", eventData.assetId,], 
        //   {
        //     queryParams: 
        //     { 
        //       type: obj.type, 
        //       id :obj.id, 
        //       rcpId:eventData.runTimeCollectionPointId, 
        //       notificationId : eventData.notificationId, 
        //       isParent : pVal, 
        //       performAction : this.performAction, 
        //       showAckBtn : this.showAckBtn,
        //       showAckErcBtn : this.showAckErcBtn,
        //       showERC : this.showERC,
        //       licenseIsTechEntry : eventData.licenseIsTechEntry, 
        //       eventListType : this.eventListType,
        //       assignToMe : assignToMe == true ? 1 : 0,
        //       isAck : isAck == true ? 1 : 0
        //     }
        // });
// *********TODO***********TODO*****************TODO****************TODO*******************TODO*********************TODO*******************TODO*******TODO******TODO
        // this.eventType = obj.type//params['type'];//==================================================take from url params
        // this.notificationId = eventData.notificationId//params['notificationId'];
        // this.showAckBtn = params['showAckBtn']; 
        // this.showAckErcBtn = params['showAckErcBtn'];
        // this.showERC = params['showERC'];
        this.runTimeEventId = eventData.runTimeEventId//obj.id//params['id'];
        this.rcpId = eventData.runTimeCollectionPointId//params['rcpId'];
        this.performAction = this.performAction//params['performAction'];  
        this.notficationType = this.eventType//obj.type//params['type'];
        this.licenseIsTechEntry = eventData.licenseIsTechEntry//params['licenseIsTechEntry'];
        this.isParent = pVal //params['isParent'];
        // this.eventListType = params['eventListType'];
        this.assignToMe = assignToMe == true ? 1 : 0//params['assignToMe'];
        this.isAckTag = isAck == true ? 1 : 0//params['isAck']; //===================================------------------------  change when available in api response


        // this.assignedContactListData = data.responseList;

        
      } else {
        // this.assignedContactListData =[];
        /* this.alertService.sweetMessage('error',data.responseMessage); */
      }

      if (flag) {
        if (this.eventType == "Notification") {
          if (this.eventListType == 'liveFeeds') {
            this.getInstructionsInfo();
          }
          // this.getRunTimeEventDetails();
          this.getWffEventDetails();
          this.getWffEventByID();
        } else if (this.eventType == "RunTime") {
          //this.getRunTimeEvenListForreasonCode();

          this.getRunTimeEventDetails();
          this.getMemberResponsibility();
          if (this.eventListType == 'liveFeeds') {
            this.getInstructionsInfo();
          }
        }
      }

    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}


  // To format date
  DateFormat(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
      // return this.formatDateService.formatDate(startDate, gmtOffset);
  }

  changeCheckBox(event, roleup){
      // adding First fault in reason code if first fault is on 
      let firstFaultStatus = $('#location').prop("checked");       
      let startTimeConverted = roleup.startTimeConverted;
      if(startTimeConverted != '' || startTimeConverted != null) {
          let tempArr = [];
          this.eventIdArr.map((val,key)=>{
            tempArr.push(val.runTimeEventId); 
          }); 

          let rolupIndex = _.findIndex(this.rollUpdataList, { 'runTimeEventId': roleup.runTimeEventId });   
          
          this.rollUpdataList.map((val,key)=>{ 
            if(key > rolupIndex) {
              $('#select_'+key).prop('checked', false); 
              this.rollUpdataList[key].select = false;
            } 
          });
          // setTimeout(1000);
          // this.eventIdArr = [];
          this.total_duration = 0;
          this.rollUpdataList.map((val,key)=>{    
            let dd = val.duration;
            let selectVal = val.select;
            if(selectVal) {
              this.total_duration = this.total_duration  + dd;                
            }
          });
          

          // eventIdArr : Child event ids and check whether new id is present in that array or not
          let index = _.findIndex(this.rollUpdataList, { 'runTimeEventId': roleup.runTimeEventId });
          
          // remaining time for adding responsibility
          let rt = this.remaining_mins;
          // total duration at first will be the sum of child runtime that are in detail api (that already selected)
          if (event.target.checked) {
            if(firstFaultStatus) {
              this.pushDataInResonCode(roleup);  
            }   
            this.runTimeObj.startTimeConverted = this.rollUpdataList[index].startTimeConverted

            // this.eventIdArr.push({ 'runTimeEventId': roleup.runTimeEventId });
            // normal date of selected rollup Event 
            let nrml_dt = this.rollUpdataList[index].startTimeConverted ? (this.DateFormat(this.rollUpdataList[index].startTimeConverted, '')+' '+this.rollUpdataList[index].stz) : '--';
            // duration for particular rollup event
            let duration = roleup.duration;
            // plus checked duration frm total
            // this.total_duration = this.total_duration + duration;
            // this.remaining_mins = this.remaining_mins + duration;
            $('#nrml_dt').html(nrml_dt);

            if(roleup.startTimeConverted == null){
              this.remaining_mins = this.total_duration;
              this.sumOfDowntime = 0;
              this.closeActive = 0;
              this.listMemberIdArr = [];
              this.responsibilityArr = [];
              this.runTimeEventResponsibilities = [];
              $('.update_close').attr('disabled', true);
              $('.update_close').attr('id', 'cursor-not-allowed');
              $('.add_resp_btn').attr('disabled', true);
              $('.add_resp_btn').attr('id', 'cursor-not-allowed');
            }
          }else{   
            // this.eventIdArr.splice(index,1);
            // normal date of selected rollup Event at one prvious index
            let nrml_dt_prev = this.rollUpdataList[index - 1].startTimeConverted ? (this.DateFormat(this.rollUpdataList[index - 1].startTimeConverted, '')+' '+this.rollUpdataList[index - 1].stz) : '';
            // duration for particular rollup event
            let duration = roleup.duration;
            this.runTimeObj.startTimeConverted = this.rollUpdataList[index -1].startTimeConverted

            // minus unchecked duration frm tota
            // this.total_duration = this.total_duration - duration;
            $('#nrml_dt').html(nrml_dt_prev);          
            if(rt != 0){ 
              // this.remaining_mins = this.remaining_mins - duration;            
            } else {
              // remaining time = 0 : responsibilities 0
              this.sumOfDowntime = 0;
              this.closeActive = 0;
              this.listMemberIdArr = [];
              this.responsibilityArr = [];
              this.runTimeEventResponsibilities = [];
              this.remaining_mins = this.total_duration;
              $('.update_close').attr('disabled', true);
              $('.update_close').attr('id', 'cursor-not-allowed');
            } 

            if(roleup.startTimeConverted == null){
              $('.add_resp_btn').removeAttr('disabled');
              $('.add_resp_btn').removeClass('disabled');
              $('.add_resp_btn').attr('id', '');
              this.remaining_mins = this.total_duration;
              this.sumOfDowntime = 0;
              this.closeActive = 0;
              this.listMemberIdArr = [];
              this.responsibilityArr = [];
              this.runTimeEventResponsibilities = [];
              $('.update_close').attr('disabled', true);
              $('.update_close').attr('id', 'cursor-not-allowed');
            }
              
          }

          this.remaining_mins = this.total_duration - this.sumOfDowntime;
          // $('#remaining_mins').text(this.remaining_mins);
          console.log(this.remaining_mins, "---------------------------------11-");
          
          if(this.remaining_mins < 0){
            this.remaining_mins = this.total_duration;
            this.sumOfDowntime = 0;
            this.closeActive = 0;
            this.listMemberIdArr = [];
            this.responsibilityArr = [];
            this.runTimeEventResponsibilities = [];
            $('.update_close').attr('disabled', true);
            $('.update_close').attr('id', 'cursor-not-allowed');
          }
          // Changing duration and remaining time dynamically wrt to rollup select deselect
          // $('#duration').html(this.total_duration);
          // $('#remaining_mins').html(this.remaining_mins);
          let objValue = roleup.firstFaultCodeDesc;
          if (roleup.firstFaultCodeDesc) {
            var exist = this.firstFaultResponsibilityArr.indexOf(roleup.firstFaultCodeDesc.toString());
            if (exist == '-1') {
              this.firstFaultResponsibilityArr.push(objValue);
            }
          }
      }
  }

  // Add New Technician
  addTechnician() {
    let newTechnicianId = $('#newTechnician option:selected').val();
    let newTechnicianName = $('#newTechnician option:selected').text();
  }

  // Add New Reason Code Api
  addNewReasonCode() {
    // return
    
    let reasonCode = $('#addNewReasonCode').val();
    this.addReasonCodeData.name = reasonCode;
    this.apiService.create('reasonCode/createReasonCode', this.addReasonCodeData).subscribe(
        data => {
            if (data.statusCode == 100) {
              let reasonCode = $('#addNewReasonCode').val();
              if(reasonCode) {
                $('#reason_code_err').css('display', 'none');
                var exist = this.reasonListData.indexOf(reasonCode.toString());
                if (exist == '-1') {                    
                  let nameObj = {
                    'name' : reasonCode,
                    reasonCodeId:data.response.reasonCodeId
                  }
                  if (!this.UseFirstFault) {
                    this.reasonListData.push(nameObj);
                  }
                }
              } else {
                $('#reason_code_err').css('display', 'block');
              }
              $('#addNewReasonCode').val('');
              $('#reason_code_exist_err').css('display', 'none');
              this.alertService.sweetMessage('success', 'Added successfully');
            } else {
              $('#reason_code_exist_err').html(data.responseMessage);
              $('#reason_code_err').css('display', 'none');
              $('#reason_code_exist_err').css('display', 'block');
            }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }

  getAssignedContactsList(){
    this.apiService.getAll('asset/getAssetContact?assetId=10147418').subscribe(
      data => {
        if(data.statusCode == 100){
          this.assignedContactListData = data.responseList;
        }else{
          this.assignedContactListData =[];
         /* this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // get asset detail for wff nitification 
  getWffEventDetails() {
    this.apiService.getAll('wffnotification/getAssetDetailByWffNotificationId?wffNotificationId='+this.notificationId+'&clientId='+this.clientId).subscribe(
        data => {
          // if(data.statusCode == 100){
            
          if(data){      
            this.wffEventDetailData = data.response;
            this.tierLength = this.wffEventDetailData.asset.tiers ? this.wffEventDetailData.asset.tiers.length : 0;
          }else{
            this.wffEventDetailData = [];
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }

  // Instruction tab data
  getInstructionsInfo(){  
    if ($.fn.DataTable.isDataTable('#instruction_table')) {
      $('#instruction_table').DataTable().destroy();
      this.assignedContactListData = [];
    };    
    // this.apiService.getAll('currrentEscalation/getAssignedContactByWffNotification?wffNotificationId='+this.notificationId).subscribe(
      if (this.notificationId) {
        
          this.apiService.getAll('wffnotification/getEscalationNotificationByEvent?wffNotificationId='+this.notificationId).subscribe(
            data => {
            if(data.statusCode == 100){
              this.chRef.detectChanges();
              if ($.fn.DataTable.isDataTable('#instruction_table')) {
                $('#instruction_table').DataTable().destroy();
              };
              //this.assetsumaryData= data.response;
              this.assignedContactListData = data.responseList;
              this.emailMsg = data.emailMsg;
              this.sMSMsg = data.sMSMsg;
              this.chRef.detectChanges();
              setTimeout(() => {
                const table: any = $('#instruction_table');
                this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": [3, 4, 6, 7, 9] }]});
              }, 500);             
            }else{
              this.assignedContactListData = [];
              if ($.fn.DataTable.isDataTable('#instruction_table')) {
                $('#instruction_table').DataTable().destroy();
              };
              const table: any = $('#instruction_table');
              setTimeout(() => {
                const table: any = $('#instruction_table');
                this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": [3, 4, 6, 7, 9] }]});
              }, 500);  
                //this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
      );
    }
  }
  
  getAssetDetail(){
      this.apiService.getAll('asset/getAssetSummaryById?clientId='+this.clientId+'&userId='+this.userId+'&assetId='+this.assetId).subscribe(
          data => {
            if(data.statusCode == 100){
                this.assetsumaryData= data.response;
            }else{
                this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  removeResponsibility(i, listMemberId, downtime) {
    this.responsibilityArr.splice(i, 1);
    this.runTimeEventResponsibilities.splice(i, 1);

    // let listMemberIdIndex = jQuery.inArray(listMemberId, this.listMemberIdArr);
    // this.listMemberIdArr.splice(jQuery.inArray(listMemberId, this.listMemberIdArr), 1);
    var existListMemberId = this.listMemberIdArr.indexOf(listMemberId.toString());
    if(existListMemberId != '-1'){
      this.listMemberIdArr.splice(existListMemberId,1);
    }


    this.remaining_mins = this.remaining_mins + parseInt(downtime);
    // $('#remaining_mins').html(this.remaining_mins);
    this.sumOfDowntime = this.sumOfDowntime - downtime;
    $('.update_close').attr('id', 'cursor-not-allowed');
    $('.update_close').attr('disabled', true);
  }

  addResponsibility () {
    if(this.remaining_mins != 0 || this.responsibilityArr.length >= 0){
      // console.log('remainingTimeToAllocate', this.remainingTimeToAllocate, this.responsibilityArr);
      let listMemberId = $('#listMemberId').val();
      let responsibility = $('#listMemberId option:selected').text();
      let downtime = $('#downtime').val();
      let downtimeExp = new RegExp("^[0-9]{1,6}$");//regular expression for non decimal positive downtime
      let isValidDownTime = downtimeExp.test(downtime);//check downtime is valid 
      
      if(listMemberId == '' && (downtime == '' || downtime == 0)) {
        $('#responsibility_err').css('display', 'block');
        $('#downtime_err').css('display', 'block');
        return;
      } else {
        $('#responsibility_err').css('display', 'none');
        $('#downtime_err').css('display', 'none');
      }
      if (downtime == '' || downtime == 0) {
        $('#downtime_valid_err').css('display', 'block');return;
      }else{
        $('#downtime_valid_err').css('display', 'none');
      }

      if(listMemberId == '' && !isValidDownTime) {
        $('#responsibility_err').css('display', 'block');
        $('#downtime_valid_err').css('display', 'block');
        return;
      } else {
        $('#responsibility_err').css('display', 'none');
        $('#downtime_valid_err').css('display', 'none');
      }

      if(listMemberId == '') {
        $('#responsibility_err').css('display', 'block');
        return;
      } else {
        $('#responsibility_err').css('display', 'none');
      }

      if(downtime == '') {
        $('#downtime_err').css('display', 'block');
        return;
      } else {
        $('#downtime_err').css('display', 'none');
      }

      if (!isValidDownTime) {
        $('#downtime_valid_err').css('display', 'block');return;
      }else{
        $('#downtime_valid_err').css('display', 'none');
      }

      let tempSum = this.sumOfDowntime + parseInt(downtime);

      if(tempSum > this.total_duration) {
        //this.sumOfDowntime = this.sumOfDowntime - parseInt(downtime);
        if(this.remaining_mins == 0) {
          $('#res_err').html('All responsibilities are already filled !!');  
        } else {
          $('#res_err').html('Downtime must be less than remaining time !!');  
        }          
        $('#res_err').css('display', 'block');  
      } else {
        let obj = {
          'listMemberId' : listMemberId,
          'name' : responsibility,
          'duration' : downtime
        };

        if(jQuery.inArray(listMemberId.toString(), this.listMemberIdArr) == -1) {
          this.sumOfDowntime = this.sumOfDowntime + parseInt(downtime);
          this.listMemberIdArr.push(listMemberId); 
          this.responsibilityArr.push(obj); 

          this.totalDowntime = this.totalDowntime + downtime;

          this.remaining_mins = this.remaining_mins - downtime;
          // if(this.remaining_mins == 0 && (this.rollUpdataList.length == this.eventIdArr.length)) {
          if(this.remaining_mins == 0) {
            this.closeActive = 1;
            $('.update_close').removeAttr('disabled');
            $('.update_close').removeClass('disabled');
            $('.update_close').attr('id', '');
          } else {
            this.closeActive = 0;
            $('.update_close').attr('disabled', true);
            $('.update_close').attr('id', 'cursor-not-allowed');
          }
          // $('#remaining_mins').html(this.remaining_mins);

          let runtimeEventObj = {
              'runTimeEventRcpId' : this.rcpId,
              responsibilityListMember : {
                  'listMemberId' : listMemberId,    
              },            
              'downtime' : downtime
          };
          
          this.runTimeEventResponsibilities.push(runtimeEventObj);  
          $('#listMemberId').val('');  
          $('#downtime').val('');    
          $('#res_err').css('display', 'none');
          setTimeout(function(){
            $('.resb_action_td').css('display', 'table-cell');  
          }, 100);            
        } else {
          //this.sumOfDowntime = this.sumOfDowntime - parseInt(downtime);
          $('#res_err').html('Responsibility already exists.');
          $('#res_err').css('display', 'block');
        }          
      }  
      this.closeActive = 0;     
    } else {
      this.closeActive = 1;
      $('#res_err').html('All responsibilities are filled, check above details and close this event.');
      $('#res_err').css('display', 'block');
    }
    if(this.remaining_mins == 0) {
      this.closeActive = 1;
      $('.update_close').removeAttr('disabled');
      $('.update_close').removeClass('disabled');
      $('.update_close').attr('id', '');
    } else {
      this.closeActive = 0;
      $('.update_close').attr('disabled', true);
      $('.update_close').attr('id', 'cursor-not-allowed');
    }
  }

  // Getting Reason Code
  getRunTimeEvenListForreasonCode(flag) {
      this.apiService.getAll('reasonCode/getReasonCodeList?clientId=' + this.clientId).subscribe(
          data => {
            if (data.statusCode == 100) {
              let objArr =  data.responseList;
              objArr.map((val,key)=>{
                if(val.reasonCodeId != 0) {
                  this.reasonListData.push(val);
                } 
              }); 

                if(flag == 1) {
                  this.reasonListData = []
                  this.tempArr = []
                  if(this.runTimeObj.reasonCode != null){
                    if((data.responseList[0].reasonCodeId == this.runTimeObj.reasonCode.reasonCodeId) && (this.runTimeObj.reasonCodeName != null)) {
                        this.firstFaultCodeSelected = 1;
                        this.UseFirstFault = true
                        //if(this.runTimeObj.childRunTimeEvents.length > 0) {
                          this.reasonListData = [];
                          //console.log('is child');
                          let reasonCodeNameDetail = this.runTimeObj.reasonCodeName;
                          let index = _.findIndex(this.runTimeObj.childRunTimeEvents, { 'firstFaultCodeDesc': reasonCodeNameDetail });
                          //if(index != -1) {
                            // this.runTimeObj.childRunTimeEvents.map((valC, keyC)=>{
                            this.firstFaultResponsibilityArr.map((valC, keyC)=>{  
                              let selected = 0;
                              if(this.runTimeObj.reasonCodeName == valC) {
                                selected = 1;
                                this.reasonCodeId = (this.eventIdArr.length > 0) ? this.eventIdArr[keyC].runTimeEventId : 0
                              }
                              let nameObj = {
                                'reasonCodeId'  : (this.eventIdArr.length > 0) ? this.eventIdArr[keyC].runTimeEventId : 0,
                                'name' : valC,
                                'selectt' : selected
                                //'select' : (this.runTimeObj.reasonCode.name == valC.firstFaultCodeDesc) ? 1 : 0
                              }
                              let check = _.findIndex(this.tempArr, { 'name': valC.firstFaultCodeDesc });
                              if(check == -1) {
                                this.tempArr.push(nameObj);  
                              }                            
                            });
                            this.reasonListData = this.tempArr; 
                          //}
                        //}
                    } else {
                      this.reasonListData = [];
                      this.firstFaultCodeSelected = 0;
                      this.UseFirstFault = false
                      // this.reasonListData = data.responseList;
                      let objArr =  data.responseList;
                      objArr.map((val,key)=>{
                        if(val.reasonCodeId != 0) {
                          this.reasonListData.push(val);
                        } 
                      });

                    }  
                  }else{
                    this.reasonListData = data.responseList;
                  }                  
              }
              

            } else {
                this.reasonListData = [];
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  getUnassignedTechnicianList() {
    this.apiService.getAll('user/getUnAssignedTechnician?clientId=' + this.clientId + '&assetId=' + this.assetId+"&runTimeEventId="+this.runTimeEventId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.unassignedTechnicianList = data.userList;
                  // jQuery.inArray(listMemberId, this.listMemberIdArr)
                  var realArray = $.makeArray( this.runTimeObj.users );
                  // let userIdArr = [];
                  realArray.map((val,key)=>{
                    this.userIdArr.push(val.userId);
                  });
                  this.unassignedTechnicianList.map((val,key)=>{
                    if((jQuery.inArray(parseInt(val.userId), this.userIdArr)) != -1){                        
                      this.unassignedTechnicianList[key].select = true;
                      this.newTechnicianId = val.userId;
                    } else {
                      this.unassignedTechnicianList[key].select = false;
                    }
                  });

                  let eventDetailUsers = this.runTimeObj.users;
                  // console.log('unass eventDetailUsers', eventDetailUsers);
                  let mapArr = [];
                  eventDetailUsers.map((val,key)=>{                      
                    // console.log(eventDetailUsers[key].userId);
                    let i = _.findIndex(this.unassignedTechnicianList, { 'userId': eventDetailUsers[key].userId });
                    // console.log('i', i);
                    if(i != -1) {
                      let obj = {
                        'userId' : eventDetailUsers[key].userId,
                        'userName' : eventDetailUsers[key].userName 
                      };
                      mapArr.push(obj);
                    }
                  });   
                  this.unassignedSelectedTechnicians = mapArr;
                  // console.log('unassignedSelectedTechnicians', this.unassignedSelectedTechnicians);  

                  // this.technicianList.map((val,key)=>{
                  //   if((jQuery.inArray(parseInt(val.userId), this.userIdArr)) != -1){                        
                  //     this.technicianList[key].select = true;
                  //     this.technicianId = val.userId;
                  //   } else {
                  //     this.technicianList[key].select = false;
                  //   }
                  // });
                   // console.log('unassignedTechnicianList skect', this.unassignedTechnicianList);
                  //this.temptechnicianList = data.userList;
              } else {}
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
  }

  /* Get TechnicianList list */
  getTechnicianList() {
      this.apiService.getAll('user/getRunTimeEventTechnician?clientId=' + this.clientId + '&assetId=' + this.assetId+"&runTimeEventId="+this.runTimeEventId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.technicianList = data.userList;
                  this.viewtechnicianList = []
                  // console.log('technicianList', this.technicianList);
                  let eventDetailUsers = this.runTimeObj.users;
                  // console.log('t eventDetailUsers', eventDetailUsers);
                  let mapArr = [];
                  eventDetailUsers.map((val,key)=>{                      
                    // console.log(eventDetailUsers[key].userId);
                    // let i = _.findIndex(this.technicianList, { 'userId': eventDetailUsers[key].userId });
                    // // console.log('i', i);
                    // if(i != -1) {
                    //   let obj = {
                    //     'userId' : eventDetailUsers[key].userId,
                    //     'userName' : eventDetailUsers[key].userName 
                    //   };
                    //   mapArr.push(obj);
                    // }

                     // console.log(eventDetailUsers[key].userId);
                  if (eventDetailUsers[key].userId == 0) {
                    let obj = {
                      'userId' : eventDetailUsers[key].userId,
                      'userName' : eventDetailUsers[key].userName 
                    };
                    this.viewtechnicianList.push(obj);                      
                    // mapArr.push(obj);                      
                  }else{
                    let i = _.findIndex(this.technicianList, { 'userId': eventDetailUsers[key].userId });
                  // console.log('i', i);
                    if(i != -1) {
                      let obj = {
                        'userId' : eventDetailUsers[key].userId,
                        'userName' : eventDetailUsers[key].userName 
                      };
                    this.viewtechnicianList.push(obj);                      
                    mapArr.push(obj);
                    }
                  }
                    
                  });  
                  this.selectedTechnicians = mapArr;
                  // console.log('selectedTechnicians', this.selectedTechnicians);  

                  //this.temptechnicianList = data.userList;
              } else {}
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
  }

   /* Get TechnicianList list */
  getMemberResponsibility() {
      // this.apiService.getAll('listMember/getListMamberResponsibility?runTimeEventId=' + this.runTimeEventId + '&assetId=' + this.assetId).subscribe(
      this.apiService.getAll('listMember/getListMamberResponsibility?runTimeEventId=' + this.runTimeEventId + '&assetId='+this.assetId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.responsbilityList = data.responseList;                     
                  // console.log('test', data, this.responsbilityList);
                  //this.temptechnicianList = data.userList;
              } else {}
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
  }


  getRunTimeEventDetails() {      
    this.responsibilityArr = [];
    this.runTimeEventResponsibilities = [];
      this.apiService.getAll('runTimeEvent/runTimeEventDetail?runTimeEventId=' +this.runTimeEventId).subscribe(
          data => {
            if(data.statusCode == 100){  
              this.runTimeEventResponsibilities = [];
              this.responsibilityArr = [];
              this.sumOfDowntime = 0;
              this.totalDowntime = 0;
              this.runTimeObj = data.response;  
              this.standBy = this.runTimeObj.standBy;              
              if(this.runTimeObj.runTimeCollectionPoint.asset.tiers && this.runTimeObj.runTimeCollectionPoint.asset.tiers.length > 0) {
                let remove_arr_index = this.runTimeObj.runTimeCollectionPoint.asset.tiers.length - 1;
                this.tierLength = this.runTimeObj.runTimeCollectionPoint.asset.tiers.length
                let self = this;
                // setTimeout(function(){
                //   $('#asset_detail').find('span#arr_'+remove_arr_index).html('');
                //   let tiersArr = self.runTimeObj.runTimeCollectionPoint.asset.tiers;
                //   let str = '';
                  
                //   tiersArr.map((val,key)=>{
                //     str += val.tierName;
                //     if(key < tiersArr.length - 1) {
                //       str += " > "; 
                //     }              
                //   });   
                //   let newstr = str.slice(0, -1);
                //   $('#asset_detail').html(str);
                // }, 100);
              }
              this.getUnassignedTechnicianList();
              this.getTechnicianList();
              this.getRollUpData();
              // this.getRunTimeEvenListForreasonCode(1);
              if(this.runTimeObj.childRunTimeEvents.length > 0) {
                //console.log('data found', this.runTimeObj.childRunTimeEvents.length - 1, this.runTimeObj.childRunTimeEvents[this.runTimeObj.childRunTimeEvents.length - 1].firstFaultCodeDesc);
                this.childEventFirstFaultCode = this.runTimeObj.childRunTimeEvents[this.runTimeObj.childRunTimeEvents.length - 1].firstFaultCodeDesc;
                this.isChildEventsInDetail = true;
              } else {
                this.isChildEventsInDetail = false;
              }

              let evntResponsilities = this.runTimeObj.runTimeEventResponsibilities;
              //console.log('evntResponsilities', evntResponsilities, evntResponsilities.length);
              if(evntResponsilities.length > 0) {
                  evntResponsilities.map((val,key)=>{
                    let downtime = parseInt(evntResponsilities[key].downtime);
                    this.totalDowntime = this.totalDowntime + downtime;
                    this.remaining_mins = this.remaining_mins - parseInt(evntResponsilities[key].downtime);
                  });
                  // to show total if responsibilities are there
                  this.sumOfDowntime = this.totalDowntime;
              }
              $('.remaining_mins').html(this.remaining_mins >= 0 ? this.remaining_mins : 0);
              let status =  this.runTimeObj.status;
              this.eventStatus = status;
              
              let eventDetailUsers = data.response.users;                
              if(this.performAction == 0) {                  
                  eventDetailUsers.map((val,key)=>{
                    if(eventDetailUsers[key].userId == this.userId) {
                      this.performAction = 1;         
                    }                      
                  });         
              } else {
                // console.log('already found');
              }
              if(this.runTimeObj.firstFaultCodeDesc!=null){
               var exist = this.firstFaultResponsibilityArr.indexOf(this.runTimeObj.firstFaultCodeDesc.toString());
               if (exist == '-1') {                    
                  this.firstFaultResponsibilityArr.push(this.runTimeObj.firstFaultCodeDesc);                    
               }
              }
              
              this.comment = decodeURIComponent(this.runTimeObj.comment ? this.runTimeObj.comment : '');
             
              this.runTimeObj.runTimeEventResponsibilities.forEach(key=> {
                  let listMemberId = key.responsibilityListMember.listMemberId;
                  let obj = {
                    'listMemberId' : listMemberId,
                    'name' : key.responsibilityName,
                    'duration' : key.downtime
                  };                    
                  this.responsibilityArr.push(obj);   
                  this.listMemberIdArr.push(listMemberId.toString());

                  let runtimeEventObj = {
                      'runTimeEventRcpId' : this.rcpId,
                      responsibilityListMember : {
                          'listMemberId' : listMemberId,    
                      },            
                      'downtime' : key.downtime
                  };
                  // console.log('runtimeEventObj', runtimeEventObj);
                  this.runTimeEventResponsibilities.push(runtimeEventObj);
              }); 
              // console.log('this.listMemberIdArr', this.listMemberIdArr);

              if(this.runTimeObj.reasonCode){
                this.reasonCodeId = this.runTimeObj.reasonCode.reasonCodeId;
              }
              if(this.runTimeObj.users[0]){                   
                this.technicianId = this.runTimeObj.users[0].userId;
              }
              console.log('get responsibility', this.runTimeEventResponsibilities);
              console.log(",???????????????????????????????????????????");

              if((this.total_duration == this.sumOfDowntime && this.remaining_mins == 0) || this.standBy) {
                this.closeActive = 1;
                console.log(",???????????????????????????????????????????");
                
                $('.update_close').removeAttr('disabled');
                $('.update_close').removeClass('disabled');
                $('.update_close').attr('id', '');
              }
            }else{
              this.runTimeObj = [];
            }
            console.log(this.responsibilityArr ,"--------responsibilityArr");
            
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  getWffEventByID(){
      // wffnotification//getEventDetailByWffNotificationId?wffNotificationId=9740208&clientId=5
      this.apiService.getAll('wffnotification/getEventDetailByWffNotificationId?wffNotificationId=' +this.runTimeEventId + '&clientId='+this.clientId).subscribe(
          data => {
            if(data.statusCode == 100){
             this.runTimeWffEventObj = data.response;
             this.showAckTime = this.runTimeWffEventObj.ackTime != 'null' ? this.runTimeWffEventObj.ackTime : '';
            }else{
              this.runTimeEventObj = data.response;
              const table: any = $('#recentStatus');

              // this.dataTable = table.DataTable();

            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  assignOwnerTechnician() {
    this.assignOwnerTechObj = {
      "runTimeEventId" : this.runTimeEventId,
      "technician" : { 
          userId : this.userId
      },
      "isApproved" : true
    };

    this.apiService.update('runTimeEvent/assignOwnerTechnician', this.assignOwnerTechObj).subscribe(
        data => {
          if (data.statusCode == 100) {       
              console.log('in ode');
                this.mode = 'edit';

                 this.showERC = 0;
                 this.showAckBtn = 0;
                 this.showAckErcBtn = 0;
                 this.performAction = 1;
                 
                 console.log(
                  'changed params', 
                  this.mode, this.showAckErcBtn, 
                  this.showERC, this.showAckBtn
                  );
                  this.getRunTimeEventDetails()

                let self = this;
                setTimeout(function() {
                  console.log('after delay');
                  self.getRollUpData(); 
                  if(self.performAction == 1) {
                    // $('#view_btn').css('display', 'inline-block');
                    // $('#edit_btn').css('display', 'none');
                    self.EditButtonShow = false
                    self.ShowResponsibilitySection = false;
                    // $('.add_responsibility_section').css('display', 'flex');
                    $('.resb_action_th').css('display', 'table-cell');
                    $('.resb_action_td').css('display', 'table-cell');
                    $('.add_new_reason_code_section').css('display', 'flex');
                    $('.unassigned_technician_section').css('display', 'flex');
                    $('.technician_edit_mode').css('display', 'block');
                    $('.technician_view_mode').css('display', 'none');
                     $('#erc_btn').css('display', 'none');

                     

                      self.router.navigate(["eventDetails", self.assetId,], 
                      {
                        queryParams: 
                        { 
                          type: self.eventType, 
                          id : self.runTimeEventId, 
                          rcpId : self.rcpId, 
                          notificationId : self.notificationId, 
                          isParent : self.isParent, 
                          performAction : self.performAction, 
                          showAckBtn : self.showAckBtn,
                          showAckErcBtn : self.showAckErcBtn,
                          showERC : self.showERC,
                          licenseIsTechEntry : self.licenseIsTechEntry, 
                          eventListType : self.eventListType,
                          assignToMe : this.assignToMe,
                          isAck : this.isAckTag  
                        }
                      });
                  } else {
                    console.log('not having permission');
                  }                       
                }, 500);             
             
          }
          else
          {                
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error =>
        { 
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }
 
  // re-open event functionality
  reopenEvent() {
    Swal({
      title: 'Are you sure?',
      text: "You want to Re-Open!!!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        let reopenEventRequest = {
          "runTimeEventId" : this.runTimeEventId,
          "status" : 2
        }
        let URL = 'runTimeEvent/updateRunTimeEventStatus';
        this.apiService.update(URL, reopenEventRequest).subscribe(
              data => {
                if (data.statusCode == 100) {
                   this.alertService.sweetMessage('success', 'Re-Opened Successfully');
                   this.router.navigate(["dashboard"]);
                //   this.router.navigate(["eventDetails", this.assetId,], 
                //   {
                //     queryParams: 
                //     { 
                //       type: this.eventType, 
                //       id :this.runTimeEventId, 
                //       rcpId:this.rcpId, 
                //       notificationId : this.notificationId, 
                //       isParent : this.isParent, 
                //       performAction : this.performAction, 
                //       showAckBtn : this.showAckBtn,
                //       showAckErcBtn : this.showAckErcBtn,
                //       showERC : this.showERC,
                //       licenseIsTechEntry : this.licenseIsTechEntry, 
                //       eventListType : 'liveFeeds',
                //       assignToMe : this.assignToMe,
                //       isAck : this.isAck
                //     }
                // });                  
              }
                else
                {                
                  this.alertService.sweetMessage('error', data.responseMessage);
                }
              },
              error =>
              { 
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );   
      }         
    }) 
  }

  // Update, Update and Close & Approval functionality
  updateEventDetail(btnStatus) {
    this.ReasonCodeError = null
    if (btnStatus == 2) {
      console.log(this.reasonCodeId, "+++++++++++++++++++++++++++++++");
      
      if (!this.reasonCodeId) {
        this.ReasonCodeError = "please select reason code";
        return;
      }
    }
    Swal({
      title: 'Are you sure?',
      text: "You want to update!!!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {

        if (this.eventStatus == 1) {
          console.log('Assign owner--------------------------------');
          
          // return

          this.assignOwnerTechObj = {
            "runTimeEventId" : this.runTimeEventId,
            "technician" : { 
                userId : this.userId
            },
            "isApproved" : true
          };

          this.apiService.update('runTimeEvent/assignOwnerTechnician', this.assignOwnerTechObj).subscribe(
              data => {
                if (data.statusCode == 100) {       
                    console.log('in ode');
                      this.mode = 'edit';

                      this.showERC = 0;
                      this.showAckBtn = 0;
                      this.showAckErcBtn = 0;
                      this.performAction = 1;
                      
                      
                      // let self = this;
                      // setTimeout(function() {
                      //   console.log('after delay');
                      //   self.getRollUpData(); 
                      //   if(self.performAction == 1) {
                      //     // $('#view_btn').css('display', 'inline-block');
                      //     // $('#edit_btn').css('display', 'none');
                      //     self.EditButtonShow = false
                      //     self.ShowResponsibilitySection = false;
                      //     // $('.add_responsibility_section').css('display', 'flex');
                      //     $('.resb_action_th').css('display', 'table-cell');
                      //     $('.resb_action_td').css('display', 'table-cell');
                      //     $('.add_new_reason_code_section').css('display', 'flex');
                      //     $('.unassigned_technician_section').css('display', 'flex');
                      //     $('.technician_edit_mode').css('display', 'block');
                      //     $('.technician_view_mode').css('display', 'none');
                      //     $('#erc_btn').css('display', 'none');

                          

                      //       self.router.navigate(["eventDetails", self.assetId,], 
                      //       {
                      //         queryParams: 
                      //         { 
                      //           type: self.eventType, 
                      //           id : self.runTimeEventId, 
                      //           rcpId : self.rcpId, 
                      //           notificationId : self.notificationId, 
                      //           isParent : self.isParent, 
                      //           performAction : self.performAction, 
                      //           showAckBtn : self.showAckBtn,
                      //           showAckErcBtn : self.showAckErcBtn,
                      //           showERC : self.showERC,
                      //           licenseIsTechEntry : self.licenseIsTechEntry, 
                      //           eventListType : self.eventListType,
                      //           assignToMe : this.assignToMe,
                      //           isAck : this.isAckTag  
                      //         }
                      //       });
                      //   } else {
                      //     console.log('not having permission');
                      //   }                       
                      // }, 500);   
                      
          let URL = 'runTimeEvent/updateRunTimeEvents?userId='+this.userId;

          let flag = $('#location').prop("checked");
          console.log(this.UseFirstFault);
          
          // console.log('eventStatus', this.eventStatus);
          // btnStatus = 1 : Update, 2: Update and close, 3: Approval, 4 : Re-Open
          let status = 1;
          if (btnStatus == 1) {
              status = 2;
            } else if (btnStatus == 2) {
              status = 3;
              //this.updateruntimeObj.isClosed = true;
              console.log(this.reasonCodeId);
              
              if (!this.reasonCodeId) {
                console.log("please select reason code");
                this.alertService.sweetMessage("error", "please select reason code")
                return;
              }
            } else if (btnStatus == 3) {
              status = 3;
              URL = 'runTimeEvent/editRollUpEventDetailApprover?userId='+this.userId;
              //this.updateruntimeObj.isApproved = true;
            }
            // parent event or not flag 
            let isParent = this.isParent;            
            // Adding technicians
            this.selectedTechnicians.map((valC, keyC)=>{
                let techObj = {
                    userId : valC.userId
                };
                this.usersArr.push(techObj);      
            });

            this.unassignedSelectedTechnicians.map((valC, keyC)=>{
                let unTechObj = {
                    userId : valC.userId
                };
                this.usersArr.push(unTechObj);      
            });
            let userList = [];
            this.usersArr.map((obj,index)=>{
              let i = _.findIndex(userList, { 'userId': obj.userId });
              console.log(i);
              if (i == -1) {
                userList.push(obj)
              }
              
            }) 
          
            let standByresponsibility = [];
              if (this.standBy) {
    
                this.runTimeObj.runTimeEventResponsibilities.forEach(key=> {
                  let listMemberId = key.responsibilityListMember.listMemberId;
                  let obj = {
                    'listMemberId' : listMemberId,
                    'name' : key.responsibilityName,
                    'duration' : key.downtime
                  };                    
                  // this.responsibilityArr.push(obj);   
                  // this.listMemberIdArr.push(listMemberId.toString());
        
                  let runtimeEventObj = {
                      'runTimeEventRcpId' : this.rcpId,
                      responsibilityListMember : {
                          'listMemberId' : listMemberId,    
                      },            
                      'downtime' : key.downtime
                  };
                  // console.log('runtimeEventObj', runtimeEventObj);
                  standByresponsibility.push(runtimeEventObj);
                }); 
                
              }

            if(btnStatus == 3) {
              this.updateruntimeObj = {
                "runTimeEventId": this.rcpId ,
                "reasonCode": {
                  reasonCodeId : this.reasonCodeId
                },
                "users": userList,
                "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
                "comment" : encodeURIComponent(this.comment),
                "status" : status,
                "isApproved" : true,
                standBy:this.standBy
              };
            } else if(btnStatus == 2) {
              this.updateruntimeObj = {
                "runTimeEventId": this.rcpId ,
                "reasonCode": {
                  reasonCodeId : this.reasonCodeId
                },
                "users": userList,
                "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
                "comment" : encodeURIComponent(this.comment),
                "status" : status,
                "isClosed" : true,
                standBy:this.standBy
              };
            } else {
              this.updateruntimeObj = {
                "runTimeEventId": this.rcpId ,
                "reasonCode": {
                  reasonCodeId : this.reasonCodeId
                },
                "users": userList,
                "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
                "comment" : encodeURIComponent(this.comment),
                "status" : status,
                standBy:this.standBy
              };
            }

            this.currClientId = localStorage.getItem("userId");

            if(this.UseFirstFault) {
              let reasonCodeId = $('#reasonCodeId option:selected').val();
              if(reasonCodeId > 0) {
                this.updateruntimeObj.firstFaultRunTimeEventId = this.reasonCodeId;
              } else {
                this.updateruntimeObj.firstFaultRunTimeEventId = null;
              }
              this.updateruntimeObj.reasonCode = null;
                
            }

            // console.log('this.rollUpdataList', this.rollUpdataList);
            this.eventIdArr = []; 
            if(this.rollUpdataList.length > 0) {
              this.rollUpdataList.map((val,key)=>{    
                // console.log('rollupo 3');
                let selectVal = val.select;                
                if(selectVal) {
                  let index = _.findIndex(this.eventIdArr, { 'runTimeEventId': val.runTimeEventId });
                  if(index == -1) {
                    this.eventIdArr.push({ 'runTimeEventId': val.runTimeEventId });  
                  }                  
                }
              });
            }
            // console.log('update final event arr', this.eventIdArr);

            if(this.isParent == false){
              // console.log('in false');
              this.updateruntimeObj.runTimeEventId     = null;
              this.updateruntimeObj.childRunTimeEvents = this.eventIdArr;
            }else{
              this.updateruntimeObj.runTimeEventId     = this.runTimeEventId ;
              this.updateruntimeObj.childRunTimeEvents = this.eventIdArr;
            }
            
            console.log('final', this.updateruntimeObj, "resion code is", this.reasonCodeId);
            // return
            this.apiService.update(URL, this.updateruntimeObj).subscribe(
                data => {
                  if (data.statusCode == 100) {
                    this.alertService.sweetMessage('success', 'Updated Successfully');
                    // if (btnStatus == 3) {
                      // this.router.navigate(["advance-report"]); 
                      this.sendOutput(true);
                    // } else {
                      // this.router.navigate(["dashboard"]); 
                    // }                 
                  }
                  else
                  {                
                    this.alertService.sweetMessage('error', data.responseMessage);
                  }
                },
                error =>
                { 
                  this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );          
                  
                }
                else
                {                
                  this.alertService.sweetMessage('error', data.responseMessage);
                }
              },
              error =>
              { 
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
        
        } else {
          console.log('Assign owner---------------------22-----------');

          // return
            
          let URL = 'runTimeEvent/updateRunTimeEvents?userId='+this.userId;

          let flag = $('#location').prop("checked");
          console.log(this.UseFirstFault);
          
          // console.log('eventStatus', this.eventStatus);
          // btnStatus = 1 : Update, 2: Update and close, 3: Approval, 4 : Re-Open
          let status = 1;
          if (btnStatus == 1) {
              status = 2;
            } else if (btnStatus == 2) {
              status = 3;
              //this.updateruntimeObj.isClosed = true;
              console.log(this.reasonCodeId);
              
              if (!this.reasonCodeId) {
                console.log("please select reason code");
                this.alertService.sweetMessage("error", "please select reason code")
                return;
              }
            } else if (btnStatus == 3) {
              status = 3;
              URL = 'runTimeEvent/editRollUpEventDetailApprover?userId='+this.userId;
              //this.updateruntimeObj.isApproved = true;
            }
            // parent event or not flag 
            let isParent = this.isParent;            
            // Adding technicians
            this.selectedTechnicians.map((valC, keyC)=>{
                let techObj = {
                    userId : valC.userId
                };
                this.usersArr.push(techObj);      
            });

            this.unassignedSelectedTechnicians.map((valC, keyC)=>{
                let unTechObj = {
                    userId : valC.userId
                };
                this.usersArr.push(unTechObj);      
            });
            let userList = [];
            this.usersArr.map((obj,index)=>{
              let i = _.findIndex(userList, { 'userId': obj.userId });
              console.log(i);
              if (i == -1) {
                userList.push(obj)
              }
              
            }) 
          
            let standByresponsibility = [];
              if (this.standBy) {
    
                this.runTimeObj.runTimeEventResponsibilities.forEach(key=> {
                  let listMemberId = key.responsibilityListMember.listMemberId;
                  let obj = {
                    'listMemberId' : listMemberId,
                    'name' : key.responsibilityName,
                    'duration' : key.downtime
                  };                    
                  // this.responsibilityArr.push(obj);   
                  // this.listMemberIdArr.push(listMemberId.toString());
        
                  let runtimeEventObj = {
                      'runTimeEventRcpId' : this.rcpId,
                      responsibilityListMember : {
                          'listMemberId' : listMemberId,    
                      },            
                      'downtime' : key.downtime
                  };
                  // console.log('runtimeEventObj', runtimeEventObj);
                  standByresponsibility.push(runtimeEventObj);
                }); 
                
              }

            if(btnStatus == 3) {
              this.updateruntimeObj = {
                "runTimeEventId": this.rcpId ,
                "reasonCode": {
                  reasonCodeId : this.reasonCodeId
                },
                "users": userList,
                "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
                "comment" : encodeURIComponent(this.comment),
                "status" : status,
                "isApproved" : true,
                standBy:this.standBy
              };
            } else if(btnStatus == 2) {
              this.updateruntimeObj = {
                "runTimeEventId": this.rcpId ,
                "reasonCode": {
                  reasonCodeId : this.reasonCodeId
                },
                "users": userList,
                "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
                "comment" : encodeURIComponent(this.comment),
                "status" : status,
                "isClosed" : true,
                standBy:this.standBy
              };
            } else {
              this.updateruntimeObj = {
                "runTimeEventId": this.rcpId ,
                "reasonCode": {
                  reasonCodeId : this.reasonCodeId
                },
                "users": userList,
                "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
                "comment" : encodeURIComponent(this.comment),
                "status" : status,
                standBy:this.standBy
              };
            }

            this.currClientId = localStorage.getItem("userId");

            if(this.UseFirstFault) {
              let reasonCodeId = $('#reasonCodeId option:selected').val();
              if(reasonCodeId > 0) {
                this.updateruntimeObj.firstFaultRunTimeEventId = this.reasonCodeId;
              } else {
                this.updateruntimeObj.firstFaultRunTimeEventId = null;
              }
              this.updateruntimeObj.reasonCode = null;
                
            }

            // console.log('this.rollUpdataList', this.rollUpdataList);
            this.eventIdArr = []; 
            if(this.rollUpdataList.length > 0) {
              this.rollUpdataList.map((val,key)=>{    
                // console.log('rollupo 3');
                let selectVal = val.select;                
                if(selectVal) {
                  let index = _.findIndex(this.eventIdArr, { 'runTimeEventId': val.runTimeEventId });
                  if(index == -1) {
                    this.eventIdArr.push({ 'runTimeEventId': val.runTimeEventId });  
                  }                  
                }
              });
            }
            // console.log('update final event arr', this.eventIdArr);

            if(this.isParent == false){
              // console.log('in false');
              this.updateruntimeObj.runTimeEventId     = null;
              this.updateruntimeObj.childRunTimeEvents = this.eventIdArr;
            }else{
              this.updateruntimeObj.runTimeEventId     = this.runTimeEventId ;
              this.updateruntimeObj.childRunTimeEvents = this.eventIdArr;
            }
            
            console.log('final', this.updateruntimeObj, "resion code is", this.reasonCodeId);
            // return
            this.apiService.update(URL, this.updateruntimeObj).subscribe(
                data => {
                  if (data.statusCode == 100) {
                    this.alertService.sweetMessage('success', 'Updated Successfully');
                    // if (btnStatus == 3) {
                      // this.router.navigate(["advance-report"]);
                       this.sendOutput(true)
                    // } else {
                    //   this.router.navigate(["dashboard"]); 
                    // }                 
                  }
                  else
                  {                
                    this.alertService.sweetMessage('error', data.responseMessage);
                  }
                },
                error =>
                { 
                  this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }






      }
    })
    
  }

  rollUpEventSelect(rollupData) {
      let objValue = rollupData.firstFaultCodeDesc;
      var exist = this.firstFaultResponsibilityArr.indexOf(rollupData.firstFaultCodeDesc.toString());
      if (exist == '-1') {
          this.firstFaultResponsibilityArr.push(objValue);
      }
  }

  getRollUpData(){      
    let str = '';
      if(this.eventListType == 'liveFeeds') { 
        str += '';
      } 
      else if(this.eventListType == 'approval') {
        str += '&isApproved=true';
      }
      else if(this.eventListType == 'approved') {
        str += '&isApproved=true';
      }
      this.apiService.getAll('runTimeEvent/rollUpRunTimeEvents?clientId='+this.clientId+'&userId='+this.userId+'&parentRunTimeEventId='+this.runTimeEventId+'&rcpId='+this.rcpId+str).subscribe(
          data => {
            if(data.statusCode == 100){
              this.rollUpdataList = data.responseList ? data.responseList : [];
              //if(this.mode == 'edit') {
                this.total_duration = 0;
                this.eventIdArr = [];
                if(this.runTimeObj.childRunTimeEvents.length == this.rollUpdataList.length) {
                  //this.closeActive = 1;
                }

              //}
              // console.log('rollup child', this.runTimeObj.childRunTimeEvents.length, this.runTimeObj.childRunTimeEvents);
              this.rollUpdataList.map((val, key)=>{                  
                if(!this.isChildEventsInDetail) { 
                  $('.rollupSection').css('display', 'none');
                  $('.noRollups').css('display', 'contents');
                  if(this.runTimeEventId == this.rollUpdataList[key].runTimeEventId){
                    this.rollUpdataList[0].select = true;
                    // this.reasonCodeId = this.rollUpdataList[0].runTimeEventId
                    this.rollUpList = this.rollUpdataList[0];                                          
                    this.eventIdArr.push({ 'runTimeEventId': this.rollUpdataList[0].runTimeEventId });
                  }
                  // console.log('no runtime', this.eventIdArr);
                  this.childEventFirstFaultCode = this.rollUpdataList[0].firstFaultCodeDesc;                    
                  this.total_duration = this.remaining_mins = this.runTimeObj.duration;
                } else {
                  // console.log('this.mode', this.mode);
                  this.runTimeObj.childRunTimeEvents.map((valC, keyC)=>{
                    if(this.runTimeObj.childRunTimeEvents[keyC].runTimeEventId == this.rollUpdataList[key].runTimeEventId) {                        
                      // console.log('is if child events', key);
                      // console.log('before', this.total_duration);
                      this.rollUpdataList[key].select = true;
                      this.rollUpdataList[key].showHideFlag = true;
                      this.total_duration = this.total_duration + valC.duration;
                      this.remaining_mins = this.remaining_mins + valC.duration;
                      // console.log('after', this.total_duration);
                       $('.nrml_dt').html(this.DateFormat(this.rollUpdataList[key].startTimeConverted, '')+' '+this.rollUpdataList[key].stz);
                      this.rollUpList = this.rollUpdataList[key]; 
                      // console.log('length', this.runTimeObj.childRunTimeEvents.length, this.eventIdArr.length);                                         
                      this.eventIdArr.push({ 'runTimeEventId': this.runTimeObj.childRunTimeEvents[keyC].runTimeEventId }); 
                      if (this.runTimeObj.childRunTimeEvents[keyC].firstFaultCodeDesc) {
                        var exist = this.firstFaultResponsibilityArr.indexOf(this.runTimeObj.childRunTimeEvents[keyC].firstFaultCodeDesc.toString());
                       
                       if (exist == '-1') {                    
                           this.firstFaultResponsibilityArr.push(this.runTimeObj.childRunTimeEvents[keyC].firstFaultCodeDesc.toString());
                       }
                      }
                      // 
                      //  var exist = this.firstFaultResponsibilityArr.indexOf(this.runTimeObj.childRunTimeEvents[keyC].firstFaultCodeDesc.toString());
                      
                      // if (exist == '-1') {                    
                      //     this.firstFaultResponsibilityArr.push(this.runTimeObj.childRunTimeEvents[keyC].firstFaultCodeDesc.toString());                    
                      // }
                    }
                  });
                  //this.eventIdArr.push({ 'runTimeEventId': this.rollUpdataList[key].runTimeEventId });                    
                }
              }); 
              // console.log('get 1', this.eventIdArr);
              this.remaining_mins = this.total_duration - this.sumOfDowntime;
              // console.log('final rollup', this.rollUpdataList);
              console.log('time', this.remaining_mins, this.total_duration);
              // $('.duration').html(this.total_duration ? this.total_duration : 0);
              // $('.remaining_mins').html(this.remaining_mins >= 0 ? this.remaining_mins : 0); 

              if(this.mode == 'edit') {
                $('.rollupSection').css('display', 'contents');
                $('.noRollups').css('display', 'none');
                this.rollUpdataList.map((val, key)=>{
                  this.rollUpdataList[key].showHideFlag = true;
                });
              }
            }else{
              this.rollUpdataList = [];
            }
              this.getRunTimeEvenListForreasonCode(1);

          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  // function to switch between edit and view mode of runctime details
  editViewRuntime(mode) {
    this.mode = mode;      
    if(mode == 'ack') {
      // console.log('ack', this.runTimeEventId);
      this.acknowledge(this.runTimeEventId, this.eventType);
    } else if(mode == 'ackErc') {
      // console.log('ackErc', this.runTimeEventId);
      this.acknowledge(this.runTimeEventId, this.eventType);
      
    }
    else if(mode == 'normalack') {
      // console.log('ackErc', this.runTimeEventId);
      this.acknowledge(this.runTimeEventId, this.eventType);
      
    } else if(mode == 'erc') {
      // console.log('erc', this.runTimeEventId);
      this.assignOwnerTechnician();
    } else if(mode == 'edit') { 
      this.getRollUpData();
      // console.log('eventStatus', this.eventStatus);        
      // $('#view_btn').css('display', 'inline-block');
      this.EditButtonShow = false
      // $('#edit_btn').css('display', 'none');
      // $('.add_responsibility_section').css('display', 'flex');
      this.ShowResponsibilitySection = false;

      this.ShowResponsibilitySection = false;

      $('.resb_action_th').css('display', 'table-cell');
      $('.resb_action_td').css('display', 'table-cell');
      $('.add_new_reason_code_section').css('display', 'flex');
      $('.unassigned_technician_section').css('display', 'flex');
      $('.technician_edit_mode').css('display', 'block');
      $('.technician_view_mode').css('display', 'none');
      //$('.firstFaultBlock').css('display', 'block');
      // console.log('in edit', this.total_duration, this.sumOfDowntime,  this.remaining_mins, this.total_duration == this.sumOfDowntime )
      if((this.total_duration == this.sumOfDowntime && this.remaining_mins == 0) || this.standBy) {
        this.closeActive = 1;
        $('.update_close').removeAttr('disabled');
        $('.update_close').removeClass('disabled');
        $('.update_close').attr('id', '');
      }
    } else if(mode == 'view')  {
      this.getRollUpData();        
      // $('#view_btn').css('display', 'none');
      // $('#edit_btn').css('display', 'inline-block');
      this.EditButtonShow = true
      this.ShowResponsibilitySection = true;
      // $('.add_responsibility_section').css('display', 'none');

      $('.resb_action_th').css('display', 'none');
      $('.resb_action_td').css('display', 'none');
      $('.add_new_reason_code_section').css('display', 'none');
      $('.unassigned_technician_section').css('display', 'none');
      $('.technician_edit_mode').css('display', 'none');
      $('.technician_view_mode').css('display', 'block');
      //$('.firstFaultBlock').css('display', 'none');
    } else {
      this.EditButtonShow = true
      // $('#edit_btn').css('display', 'inline-block');
      // $('#view_btn').css('display', 'none');
      this.ShowResponsibilitySection = true;
      // $('.add_responsibility_section').css('display', 'none');
      $('.resb_action_th').css('display', 'none');
      $('.resb_action_td').css('display', 'none');
      $('.add_new_reason_code_section').css('display', 'none');
      $('.unassigned_technician_section').css('display', 'none');
      $('.technician_edit_mode').css('display', 'none');
      $('.technician_view_mode').css('display', 'block');
      //$('.firstFaultBlock').css('display', 'none');
    }
  }

  craeteEventHistory(){
    
  }

// acknowledment functionality 
acknowledge(wffid, notificationType) {

  // if (this.notificationId) {
    
  // }

  this.apiService.getAll('user/getAckUserListByWffNotifcation?wffNotificationId=' + this.notificationId).subscribe(
    data => {
      if (data.statusCode == 100) {
        this.ackUserList = data.userList;
        let ackUserListString = '';
        ackUserListString = "<p>Following users has already acknowledged : </p><ul class='text-left'>";

        this.ackUserList.forEach(function (arrayItem) {
          console.log('for e', arrayItem);
          var x = arrayItem.userName;
          ackUserListString += '<li>' + x.charAt(0).toUpperCase() + x.slice(1) + '</li>';
        });
        ackUserListString += '</ul>';
        this.ackUserListString = ackUserListString;
      } else {
        this.ackUserListString = '';
      }
  Swal({
    title: 'Are you sure?',
    text: "You want to Acknowledge this event!!!",
    type: 'warning',
    html: this.ackUserListString ? this.ackUserListString : '',
    showCancelButton: true,
    confirmButtonColor: '#28a745',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Acknowledge!'
  }).then((result) => {
    if (result.value) {
      if (this.mode == "ackErc" || this.mode == "erc" ) {
        this.assignOwnerTechnician();
      }
      
      // this.apiService.getAll('user/getAckUserListByWffNotifcation?wffNotificationId=' + this.notificationId).subscribe(
      //   data => {
      //     if (data.statusCode == 100) {
      //       this.ackUserList = data.userList;
      //       let ackUserListString = '';
      //       ackUserListString = "<p>Following users has already acknowledged : </p><ul class='text-left'>";

      //       this.ackUserList.forEach(function (arrayItem) {
      //         console.log('for e', arrayItem);
      //         var x = arrayItem.userName;
      //         ackUserListString += '<li>' + x.charAt(0).toUpperCase() + x.slice(1) + '</li>';
      //       });
      //       ackUserListString += '</ul>';
      //       this.ackUserListString = ackUserListString;
      //     } else {
      //       this.ackUserListString = '';
      //     }

          if (notificationType === 'Notification') {
            this.ackData.wffNotification.wffNotificationId = wffid;
          } else if (notificationType === 'RunTime') {
            this.runtimeAckData.runtimeEvent.runTimeEventId = wffid;
            this.ackData = this.runtimeAckData;
          }

          this.apiService.create('wffnotification/createEventHsitory', this.ackData).subscribe(
            data => {
              if (data.statusCode == 100) {
                console.log('in ack mode');
                if (this.mode == 'ack') {
                  this.showERC = 1;
                } else {
                  this.showERC = 0;
                }

                this.showAckBtn = 0;
                this.showAckErcBtn = 0;
                this.assignToMe = 1;
                this.isAckTag = 1;

                this.router.navigate(["eventDetails", this.assetId,],
                  {
                    queryParams:
                    {
                      type: this.eventType,
                      id: this.runTimeEventId,
                      rcpId: this.rcpId,
                      notificationId: this.notificationId,
                      isParent: this.isParent,
                      performAction: this.performAction,
                      showAckBtn: this.showAckBtn,
                      showAckErcBtn: this.showAckErcBtn,
                      showERC: this.showERC,
                      licenseIsTechEntry: this.licenseIsTechEntry,
                      eventListType: this.eventListType,
                      assignToMe: this.assignToMe,
                      isAck: this.isAckTag
                    }
                  });



                // this.getRunTimeEventDetails();
                // this.getWffEventByID();
                // this.getEventByID(false)
                if (this.mode == 'ackERc') {
                  this.performAction = 1;
                  let self = this;
                  setTimeout(function () {
                    console.log('after delay 111', self.performAction);
                    self.getRollUpData();
                    if (self.performAction == 1) {
                      console.log('in perform action');
                      self.mode = 'edit';
                      // self.getRollUpData();
                      self.EditButtonShow = false
                      // $('#view_btn').css('display', 'inline-block');
                      // $('#edit_btn').css('display', 'none');
                      // $('.add_responsibility_section').css('display', 'flex');
                      self.ShowResponsibilitySection = false;

                      $('.resb_action_th').css('display', 'table-cell');
                      $('.resb_action_td').css('display', 'table-cell');
                      $('.add_new_reason_code_section').css('display', 'flex');
                      $('.unassigned_technician_section').css('display', 'flex');
                      $('.technician_edit_mode').css('display', 'block');
                      $('.technician_view_mode').css('display', 'none');
                      $('#erc_btn').css('display', 'none');

                      if (self.mode == 'edit') {
                        $('.rollupSection').css('display', 'contents');
                        $('.noRollups').css('display', 'none');
                        self.rollUpdataList.map((val, key) => {
                          self.rollUpdataList[key].showHideFlag = true;
                        });
                      }

                      self.router.navigate(["eventDetails", self.assetId,],
                        {
                          queryParams:
                          {
                            type: self.eventType,
                            id: self.runTimeEventId,
                            rcpId: self.rcpId,
                            notificationId: self.notificationId,
                            isParent: self.isParent,
                            performAction: self.performAction,
                            showAckBtn: self.showAckBtn,
                            showAckErcBtn: self.showAckErcBtn,
                            showERC: self.showERC,
                            licenseIsTechEntry: self.licenseIsTechEntry,
                            eventListType: self.eventListType,
                            assignToMe: this.assignToMe,
                            isAck: this.isAckTag
                          }
                        });



                    } else {
                      console.log('not having permission');
                    }
                  }, 1500);
                }


                // if(notificationType == 'Notification') {
                //   $('#acknowledged_btn').css('display', 'block');
                // }                
                this.alertService.sweetMessage('success', 'Acknowledged Successfully');
              } else {
                this.alertService.sweetMessage('error', data.responseMessage);
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );

        // },
        // error => {
        //   this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        // }
        // );
      }
    });
  },
  error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  }
  );
      
}

  // Click on first fault code
  firstFaultClick() {
    this.pushDataInResonCode(this.runTimeObj);
    this.reasonCodeId = null;
    // this.ReasonCodeError = ''
  }

  // Adding Reason code
  pushDataInResonCode(data){
    console.log('pushDataInResonCode', data);
    let flag = $('#location').prop("checked");
    var _this = this;
    // console.log('this.firstFaultResponsibilityArr', this.firstFaultResponsibilityArr);
    this.firstFaultResponsibilityArr = this.firstFaultResponsibilityArr.sort();
    // console.log('after sort', this.firstFaultResponsibilityArr.sort());
    this.firstFaultResponsibilityArr.forEach(function(element) {
        _this.testArr.push(element);
    });
    // console.log(this.testArr, jQuery.inArray(data.firstFaultCodeDesc.toString(), this.testArr));
    if(data.firstFaultCodeDesc!=null){
     if(jQuery.inArray(data.firstFaultCodeDesc.toString(), this.testArr) < 0) {
      _this.firstFaultResponsibilityArr.push(data.firstFaultCodeDesc);
     }
    }

    if(flag) {
      // console.log('firstFaultResponsibilityArr', this.firstFaultResponsibilityArr, this.rollUpdataList);        
      let objArr =  this.firstFaultResponsibilityArr;
      this.tempArr = [];
      objArr.map((val, key)=>{
        let getIndex = _.findIndex(this.rollUpdataList, { 'firstFaultCodeDesc': val });
        // console.log('getIndex', getIndex, this.rollUpdataList[getIndex].runTimeEventId);
        let getRuntimeEventId = this.rollUpdataList[getIndex].runTimeEventId;
        let nameObj = {
          'reasonCodeId'  : getRuntimeEventId,
          'name' : val 
        }
        this.tempArr.push(nameObj);
      }); 
      this.reasonListData = this.tempArr;
      if (this.reasonListData.length > 0) {
        this.reasonCodeId = this.reasonListData[0].reasonCodeId
        setTimeout(() => {
          this.reasonCodeId = this.reasonListData[0].reasonCodeId
          console.log(this.reasonCodeId);
            
          }, 2000);
      }else{
        this.ReasonCodeError = 'First fault code not available, please select reason code.'
        setTimeout(() => {
          this.UseFirstFault = false
          // this.firstFaultCodeSelected = 0;
          this.reasonListData = [];
          this.getRunTimeEvenListForreasonCode(0);
        }, 1000);

      }
      console.log(this.reasonCodeId);
      setTimeout(() => {
      console.log(this.reasonCodeId);
        
      }, 2000);
      
    } else {  
      this.reasonListData = [];
      this.getRunTimeEvenListForreasonCode(0);
    }
  }

  changeReasonCode(){
    this.ReasonCodeError = null;
  }
  changeStandBy(event){
    console.log(event.target.checked, '---standBY');
    if (event.target.checked) {
      this.closeActive = 1
      $('.update_close').removeAttr('disabled');
      $('.update_close').removeAttr('id');
      $('.update_close').removeClass('disabled');
    } else {
      this.closeActive = 0;
      $('.update_close').attr('disabled', true);
      $('.update_close').attr('id', 'cursor-not-allowed');
      // this.runTimeObj.runTimeEventResponsibilities.forEach(key=> {
      //   let listMemberId = key.responsibilityListMember.listMemberId;
      //   let obj = {
      //     'listMemberId' : listMemberId,
      //     'name' : key.responsibilityName,
      //     'duration' : key.downtime
      //   };                    
      //   this.responsibilityArr.push(obj);   
      //   // this.listMemberIdArr.push(listMemberId.toString());

      //   // let runtimeEventObj = {
      //   //     'runTimeEventRcpId' : this.rcpId,
      //   //     responsibilityListMember : {
      //   //         'listMemberId' : listMemberId,    
      //   //     },            
      //   //     'downtime' : key.downtime
      //   // };
      //   // // console.log('runtimeEventObj', runtimeEventObj);
      //   // this.runTimeEventResponsibilities.push(runtimeEventObj);
      // }); 
    }
    
  } 
  
  sendOutput(value){
    this.myOutputVal.emit(value); 
  }
}
