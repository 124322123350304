import { Component, OnInit } from '@angular/core';
import { AlertService, ApiService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';

declare var moment:any;

@Component({
  selector: 'app-loginadmin',
  templateUrl: './loginadmin.component.html',
  styleUrls: ['./loginadmin.component.css']
})
export class LoginadminComponent implements OnInit {

  token: any = "";
  clientId: any = ""
  Loading = false;
  resMessage:any = ""
  errorMsg:any = ""
  clientEmail:any = ""
  adminuserid:any = ""

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
      }
      if (params['userId']) {
        this.clientId = params['userId'];
      }
      if (params['user']) {
        this.clientEmail = params['user'];
      }
      if (params['admin_user_id']) {
        this.adminuserid = params['admin_user_id'];
      }
    })
    if (this.token /* && this.clientId */) {
      this.Loading = true;
      this.login();
    }
    console.log(this.token, this.clientId, this.clientEmail);

  }

  login() {
    // this.userForm.password = window.btoa(this.userForm.password);
    // this.apiService.login('userauth/oauth/token', this.userForm).subscribe(
    localStorage.removeItem('logoutMessage')
    let obj = {
      // impersonateUserId: this.clientId,
      admin_access_token: this.token,
      param:"impersonate",
      // username:this.clientEmail,
      grant_type:"password",
      // admin_user_id:this.adminuserid
    }
    this.apiService.Impersonate('userauth/oauth/login', obj).subscribe(
      data => {
        if (data.status == 200) {
          localStorage.setItem("Token", data["_body"]);

          let dataObj = JSON.parse(localStorage.getItem('Token')) //data["_body"]
          // this.cookieService.set("refresh_token",dataObj.refresh_token)
          document.cookie = "refresh_token=" + dataObj.refresh_token + "; expires=" + new Date(moment().add(30, 'days')).toUTCString();
          let UserName = dataObj ? (dataObj.username.indexOf('@') >= 0 ? dataObj.username.split('@') : dataObj.username.split('@')) : dataObj.username.split('@')

          // let UserName = dataObj.username
          let domain = UserName[1]
          localStorage.setItem('domain', domain)

          this.getUserInfo();
        } else {
          this.Loading = false
          this.errorMsg = data.responseMessage ? data.responseMessage : 'Failed...'
          // Swal({
          //   position: 'top-end',
          //   type: 'error',
          //   title: data,
          //   showConfirmButton: false,
          //   timer: 1500
          // })
        }

      },
      error => {
        if (error.error_description) {
          this.errorMsg = error.error_description
        } else {
          this.errorMsg = "Invalid Credentials"
        }
        this.Loading = false
        // Swal({
        //   position: 'top-end',
        //   type: 'error',
        //   title: this.errorMsg,
        //   showConfirmButton: false,
        //   timer: 1500
        // })
      }
    );
  }

  getUserInfo() {
    this.resMessage = null;
    this.apiService.getAll('user/getUserInfo').subscribe(
      data => {
        if (data.statusCode == 100) {

          
          // if (this.rememberMe) {
          //     this.cookieService.set('#1bcd3',window.btoa(this.userForm.username) );
          // 	this.cookieService.set('zxcv',window.btoa(this.userForm.password));
          // 	document.cookie = "islogin=;"//+data.refresh_token
          // 	document.cookie = "islogin="+true
          // }else{
          //     this.cookieService.delete('#1bcd3');
          // 	this.cookieService.delete('zxcv');
          //     this.cookieService.delete('islogin');

          // }
          ApiService.permission = data.response.permissions;
          if (ApiService.permission) {
            ApiService.isPermission = 1;
          }
          localStorage.setItem("permission", JSON.stringify(data.response.permissions));

          localStorage.setItem("userData", JSON.stringify(data.response));
          localStorage.setItem("userId", data.response.userId);
          localStorage.setItem("clientId", data.response.defaultClientId);
          localStorage.setItem("clientCode", data.response.clients[0].clientCode);
          this.router.navigate(['/dashboard']);
          // Swal({
          //   position: 'top-end',
          //   type: 'success',
          //   title: 'Login Successful',
          //   showConfirmButton: false,
          //   timer: 1500
          // })
        } else if (data.statusCode == 105) {
          var msg = data.responseMessage;
          this.resMessage = data.responseMessage
        } else {
          this.resMessage = data.responseMessage
        }
      },
      error => {
        this.Loading = false
      }
    );
  }

}
