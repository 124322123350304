import { Component, OnInit,ViewChild } from '@angular/core';
import { ApiService , AlertService, CommonService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var jQuery : any;
declare var d3:any,moment:any;
declare var moment: any;
import { CalendarEvent,CalendarEventAction,CalendarEventTimesChangedEvent,CalendarView  } from 'angular-calendar';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import { Subject } from 'rxjs';
import * as _ from "lodash";


@Component({
  selector: 'app-shift-view-calender',
  templateUrl: './shift-view-calender.component.html',
  styleUrls: ['./shift-view-calender.component.css']
})
export class ShiftViewCalenderComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId_for_Team: any = localStorage.getItem("userId");
  userId: any = localStorage.getItem("userId");

  TeamData : any ;
  ShiftListData : any ;
  ShiftGroupName:any=(localStorage.getItem("shiftGroupNameData")?JSON.parse(localStorage.getItem("shiftGroupNameData")):[]);
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
selectMonth:any= moment().format("MMMM");

  actions: CalendarEventAction[] = [];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  EventListData:any = [];
  createdDate:any;
  shiftgroupId: any = 0;
  shiftGroupData:any=[];
  assetId:any = 0;
  timeBlock:any = [];
  EventDateData:any=[];
  userListEdit:any = [];
  selectDate:any="";
  eventDate:any= moment().format("YYYY-MM-DD");
  printMonth :any = moment().format("MM/YYYY");
  NewUser:any = {
    callOrder:'',
    user:{userId:''}
  };
  OldUser:any= {
    callOrder:'',
    user:{userId:''},
    shiftCallOrderId:'',
    shift:{shiftId:''}
  };
  userName:any="";
    clientCode : any = '';
  permissionData:any=[];
  shiftId:any="";
  errorMsgSchedule = ''

  addShift: any = {
    "isBusinessHour": true,
    "name": "",
    "client": {
        "clientId": this.clientId
    },
    "startTime": "",
    "endTime": "",
    "isDeleted": false,
    "startDate": "",
    "endDate": "",
    "isRecurring": false,
    "shiftGroup": {
        "shiftGroupId": this.shiftgroupId
    },
    "isActive": true,
    "recurringPattern": {}
};
recurringPattern: any = {
  "separationCount": 0,
  "maxOccurrence": -1,
  "isSunday": false,
  "isMonday": false,
  "isTuesday": false,
  "isWednesday": false,
  "isThursday": false,
  "isFriday": false,
  "isSaturday": false,
  "dayOfMonth": 0,
  "weekOfMonth": 0,
  "recurringType": {}
};

  recurringTypeData: any = [];
  isRecurring: any = false;
  maxOccurrence:any = 'date'
  maxOccurrenceValue:any = 1
  yearMonthObj:any = {}
  type_value:any = "ON";
  shiftErr = '';
  shiftNameerr = ''
  shiftsDateerr = ''
  shifteDateerr = ''
  shiftsTimeerr = ''
  shifteTimeerr = ''
  RecStartDateErr = ''
  RecEndDateErr = ''
  RecPatternErr = ''
  userLeaveId : any = 0;
  isError : any = false;
  leaveData : any = {
    "user"    : {"userId":this.userId},
    "fromDate": "",//.format("YYYY-MM-DD"),
    "toDate"  : "",//moment().format("YYYY-MM-DD"),
    "remark"  : "",
    replacementUser:{}
  }
  leaveId   : any = 0;
  isApproved : any = false;
  userDropDown :any = []
  userDropDownReplacementAll:any = [];
  userDropDownfrom :any = []
  userDropDownto :any = []
  shiftUserList:any = [];
  dateError = ""
  toDateError = ''
  selectedStartDate = ''
  selectedEndDate = ''
  breadcrumbName = null
  breadcrumbId = null

  @ViewChild ('f') f:any;
  breadcrumbObj: any;
  pathCustID: any;
  pathAssetId: any;
  pathcust_name: any;

  shiftHistoryList: any = [] ;
  groupUserList: any = [];


  constructor(private commonService:CommonService, private router: Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService,private modal: NgbModal) {
    this.permissionData = ApiService.permission;
  }

  ngOnInit(){
    $(document).on('hidden.bs.modal', function () {
      if($('.modal.show').length)
      {
        $('body').addClass('modal-open');
      }
    });
      
    this.breadcrumbName = this.commonService.currentBreadcrumb;
    this.breadcrumbId = this.commonService.dashboardId;
    this.route
        .queryParams
        .subscribe(params => {
            this.breadcrumbObj = params['source'];
            this.pathCustID = params['cus_id'];
            this.pathAssetId = params['aId'];
            this.pathcust_name = params['cust_name'];
            
        })
    jQuery('.rst-date').datetimepicker({
          language: 'en',
          pickTime: false,
          pickDate: true,
          minDate: 0,
          inline: true,
          sideBySide: true,
      }).on('changeDate', function(){
        this.dateError = ""
        this.toDateError = ''
      }).datetimepicker("setDate", new Date());

      // jQuery('.rst-time').datetimepicker({
      //   widgetPositioning:{
      //       horizontal: 'auto',
      //       vertical: 'auto'
      //   },
      //     pickDate: false,
      //     pickTime: true,
      //     pickSeconds: false,
      //     pickMinutes: true,
      //     timeFormat: "HH:mm",
      //     orientation: "auto",
      //     container:".rst-time"
      // });
    this.getShiftGroupList();
    // this.getUserDropDown();
    this.changeDateRequest();
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params=>{
      if(params['shiftGroupId']){
        this.shiftgroupId = params['shiftGroupId']
      }
    })
    this.getScheduleGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));

    setTimeout(()=>{
        this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
        this.getDayData(this.eventDate);
     }, 100);

  }
  
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    
  }
  
  DateFormatDateOnly(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY");
      return formattedDate;
    } else {
      return '-'
    }
  }

  setShiftId(id,users){
    this.shiftId = id;
    this.shiftUserList = users
    this.errorMsgSchedule = '';
    this.getUserList();
    this.NewUser = {
      callOrder:'',
      user:{userId:''}
    };
    // $("#startDate").val(this.eventDate)
    // $("#endDate").val(this.eventDate)
  }

  // Getting Shift Group Id's
  getShiftGroupId(shiftId){
    if($("#shiftGroup :selected").text()){
      this.ShiftGroupName = $("#shiftGroup :selected").text();
    }
    this.shiftgroupId = shiftId;
    this.selectMonth = moment().format("MMMM");
    this.eventDate =  moment().format("YYYY-MM-DD");
    this.viewDate =  new Date(moment().format())
    this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
    this.getScheduleGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));
    this.getDayData(this.eventDate);
    this.getAssetDataList ();
    this.getTeam();
  }

  getDateClassName(date, view){
    // console.log(date, view, moment(date).format("dddd DD MMMM"));
    if (moment(date).format("dddd DD MMMM") == view) {
      return "calender-section1"
    }
    
  }

  // Getting Shift Group List
  getShiftGroupList() {
      this.apiService.getAll('shiftGroup/getShiftGroupByClient?clientId=' + this.clientId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.shiftGroupData = data.responseList;
                  let self = this
                  setTimeout(() => {
                    if($("#shiftGroup :selected").text()){
                      self.ShiftGroupName = $("#shiftGroup :selected").text();
  
                    }
                    
                  }, 1000);
              } else {
                  this.shiftGroupData = [];
              }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        let val = _.filter(this.events, function(o) {
            return moment(o.start).format("YYYY-MM-DD")== moment(date).format("YYYY-MM-DD")
        });
        let userIdList:any = [],userData = []
        this.EventListData = val;
        this.getDayEventList(this.EventListData);

    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  getDayEventList(EventListData){
    if(EventListData.length > 0){
    if(EventListData[0].start){
       this.eventDate = moment(EventListData[0].start).format("YYYY-MM-DD");
       /*this.dateData = EventListData[0].start;*/
       this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
       this.getDayData(this.eventDate);
      }
  }
}
// Calendar Events
  selectPreDate(){
    this.eventDate =moment(this.eventDate).subtract(1, 'days').format("YYYY-MM-DD")
    this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
    this.getDayData(this.eventDate);
  }

  selectNextDate(){
    this.eventDate =moment(this.eventDate).add(1, 'days').format("YYYY-MM-DD")
    this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
    this.getDayData(this.eventDate);
  }

  // Calendar Events
  getDayData(eventDate){
    // $("#").val(this.eventDate)
    this.apiService.getAll('shiftCallOrder/getShiftUserDetail?&date='+this.eventDate+'&shiftGroupId='+this.shiftgroupId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.EventDateData = data.responseList;

                  if (this.shiftId) {
                    for (let index = 0; index < this.EventDateData.length; index++) {
                      const element = this.EventDateData[index];
                      if (this.shiftId == element.shiftId) {
                        this.shiftUserList = element.shiftUsers;
                        break;
                      }
                      
                    }
                  } else {
                    
                  }

              } else {
                  this.EventDateData = [];
              }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }

  ChangeEvent(date){
      this.printMonth = moment(date).format("MM/YYYY")
      this.selectMonth = moment(date).format("MMMM");
      this.getScheduleGroupData(moment(date).startOf(this.view).format("YYYY-MM-DD"),moment(date).endOf(this.view).format("YYYY-MM-DD"));
  }

  eventTimesChanged({event,newStart,newEnd}: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: any): void {
    this.modalData = { event, action };
  }


 /* handleEventWeek(action: string, event: any): void {
    this.modalData = { event, action };
    this.getUserAssetList(event.event)
    this.getUserList();
    this.userList = event.event.users
  }*/


  addEvent(): void {
    this.refresh.next();
  }


/* Get Month view Data from shiftgroup and asset id */
  getScheduleGroupData(start,end) {
    this.events = []
    var URL = 'schedule/getScheduleCalenderViewData?shiftGroupId='+this.shiftgroupId+'&startDate='+start+'&endDate='+end+'&clientId='+this.clientId;
    this.createdDate = moment().format('YYYY-MM-DD');
    this.apiService.getAll(URL).subscribe(
        data => {
            if (data.statusCode == 100) {
                this.timeBlock = data.response.scheduleList;
                let Arr = [];
                let idArr = []
                  this.timeBlock.map((schedule,index)=>{
                    
                        let id = idArr.indexOf(schedule.shiftId);
                        let obj = {
                            start: moment(schedule.startDate, "YYYY-MM-DD HH:mm:ss.sssZ").toDate(),//new Date(schedule.startDate),
                            end: moment(schedule.endDate, "YYYY-MM-DD HH:mm:ss.sssZ").toDate(),//new Date(schedule.endDate),
                            title: schedule.name /* "Schedule #"+schedule.scheduleId */,
                            sheduleCount:schedule.sheduleCount,
                            users:schedule.users,
                            color: {primary:schedule.colorCode,secondary:schedule.colorCode},
                            id:schedule.shiftId,
                            actions: this.actions,
                            resizable: {
                              beforeStart: true,
                              afterEnd: true
                            },
                            meta:{
                              //add users array
                              users : schedule.dtolist
                            },
                            draggable: true
                          }
                          Arr.push(obj);
                          idArr.push(schedule.shiftId)
                          
                    })
                this.events = Arr;
                this.refresh.next()
                
            } else {
                this.timeBlock = [];
            }
        },
       error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
}

/*get Schedule shift users by shiftId and assetId*/
  getUserList(){
    this.apiService.getAll('user/getUserByShiftGroupIdAndShiftAndDate?shiftGroupId='+this.shiftgroupId+'&shiftId='+this.shiftId+'&date='+this.eventDate).subscribe(
      data => {
          if(data.statusCode == 100){
            this.userListEdit = data.userList;
          }else{
            this.userListEdit = [];
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }
/* Reset calender start and end date when model close */
  cancelChange(){
    $("#startDate").val('')
    $("#endDate").val('')
    $("#OldstartDate").val('')
    $("#OldendDate").val('')

  }

  deleteShift(){
    $('#createNewShiftPopup').modal('hide');
    Swal({
          title: 'Are you sure?',
          text: "You want to delete this Shift.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
          if (result.value) {
              this.apiService.delete('shift/deleteShift?shiftId=' + this.shiftId, "").subscribe(
                  data => {
                      if (data.statusCode == 100) {
                        this.getScheduleGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));
                        this.getDayData(this.eventDate);
                          this.alertService.sweetMessage('success','Shift Deleted Successfully');
                      } else {
                          this.alertService.sweetMessage('error',data.responseMessage);
                      }
                  },
                  error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
               }
              );
          }
      })
  }

/* Delete Shift user  */
  deleteShiftUser(Id){
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Shift User.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
          this.apiService.delete('shiftCallOrder/deleteshiftCallOrderById?shiftCallOrderId='+Id,'').subscribe(
            data => {
                if(data.statusCode == 100){
                  this.getDayData(this.eventDate);
                  this.getScheduleGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));
                  this.getUserList()

                  this.alertService.sweetMessage('success','User Deleted Successfully');
                }
              },
             error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        /*this.pushObjectUser();*/
      }
    })


  }

editShiftUser(data,shiftId){
  this.shiftId = shiftId;
  this.errorMsgSchedule = '';
  this.getUserList();
  this.userName = (data.firstName?data.firstName:'')+' '+(data.middleName?data.middleName:'')+' '+(data.lastName?data.lastName:'');
  this.OldUser= {
    callOrder:data.callOrder,
    user:{userId:data.userId},
    shiftCallOrderId:data.shiftCallOrderId,
    shift:{shiftId:shiftId},
    startDate:data.startDate,
    endDate:data.endDate
  };
  // $("#OldstartDate").val(data.startDate)
  // $("#OldendDate").val(data.endDate)
}

updateUserData(){
  let start = $("#OldstartDate").val()
    // console.log(start)
    let end = $("#OldendDate").val()
    this.errorMsgSchedule = ''

    if (!this.OldUser.callOrder || this.OldUser.callOrder < 0 || Number(this.OldUser.callOrder) < 0) {
      this.errorMsgSchedule = 'Call order must be 1 or greater.'
      return
    }
    if (!this.OldUser.user.userId) {
        this.errorMsgSchedule = 'Please select user.'
        return
    }
    
    if (moment(end,"YYYY-MM-DD").diff(moment(start,"YYYY-MM-DD"))>=0) {
      this.OldUser.endDate  = end;
      this.OldUser.startDate = start;
    this.apiService.update('shiftCallOrder/updateShiftCallOrder',this.OldUser).subscribe(
      data => {
          if (data.statusCode == 100) {
            this.getDayData(this.eventDate);
            this.getScheduleGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));

            $("#updateUser").modal('hide')

            this.alertService.sweetMessage('success','User Updated Successfully');
            this.OldUser = {
                    callOrder:'',
                    user:{userId:''},
                    shiftCallOrderId:'',
                    shift:{shiftId:''}
                  };
          } else {
              this.errorMsgSchedule = data.responseMessage
              // this.alertService.sweetMessage('error',data.responseMessage);
          }
          this.cancelChange();
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  } else{
    this.errorMsgSchedule = 'End date should be after start date'

      // Swal({
      //   position: 'top-end',
      //   type: 'error',
      //   title: 'End date should be after start date',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
    }

}

  saveScheduleData(){
    let start = $("#startDate").val()
    console.log(start)
    let end = $("#endDate").val()
    this.errorMsgSchedule = ''
    if (this.NewUser.callOrder == 0 || this.NewUser.callOrder == '0') {
      this.errorMsgSchedule = 'Call order 0 not allow.'
      return
    }

    if ( this.NewUser.callOrder < 0 || Number(this.NewUser.callOrder) < 0) {
      this.errorMsgSchedule = 'Call order must be 1 or greater.'
      return
    }
    if (!this.OldUser.callOrder && this.OldUser.callOrder == 1) {
      this.errorMsgSchedule = 'Please select call order.'
      return
    }
    if (!this.NewUser.user.userId) {
        this.errorMsgSchedule = 'Please select user.'
        return
    }

   
    
    console.log(start, end);
    
    if (moment(end,"YYYY-MM-DD").diff(moment(start,"YYYY-MM-DD"))>=0) {
    // console.log(end)
    /*if (this.NewUser.userId) {*/
      this.NewUser.endDate  = end;
      this.NewUser.startDate = start;
      this.NewUser.shift = {shiftId:this.shiftId}
    this.apiService.create('shiftCallOrder/createShiftCallOrder',this.NewUser).subscribe(
      data => {
          if (data.statusCode == 100) {
            this.shiftUserList.push(this.NewUser)
            this.getUserList()
            this.getDayData(this.eventDate);
            this.getScheduleGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));
            // $("#createUser").modal('hide')
            this.alertService.sweetMessage('success','User Added Successfully');
            this.NewUser = {
                        callOrder:1,
                        user:{userId:''}
                      };
          this.cancelChange();

          } else {
            this.errorMsgSchedule = data.responseMessage
              // this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  } else{
    this.errorMsgSchedule = 'End date should be after start date'

      Swal({
        position: 'top-end',
        type: 'error',
        title: 'End date should be after start date',
        showConfirmButton: false,
        timer: 1500
      })
    }

}

// Get Team Member List
getTeam () {
  // user/getTeamListByShiftGroupId?shiftGroupId=31
  this.apiService.getAll('user/getTeamListByShiftGroupId?shiftGroupId='+this.shiftgroupId).subscribe(
    data => {
      if (data.statusCode == 100) {
          this.TeamData = data.userList;
      } else {
        this.alertService.sweetMessage('error',data.responseMessage);
      }
    },
  error =>
    { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
  );

}

// Get Asset List
getAssetDataList () {
  this.apiService.getAll('schedule/getAssetNameByShiftGroup?shiftGroupId='+this.shiftgroupId).subscribe(
    data => {
      if (data.statusCode == 100) {
          this.ShiftListData = data.responseList;
      } else {
        this.alertService.sweetMessage('error',data.responseMessage);
      }
    },
  error =>
    { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
  );

}

getDate (date) {
  // console.log(moment(new Date(date)).format(),  moment(new Date(date)).format('YYYY-MM-DD HH:mm Z'), date);
  
  var formattedDate = moment(date).format("HH:mm");
  return formattedDate;
  // console.log(formattedDate);
}


ClearEndRecurringDate(){
  $('#recurringShiftEndDate').val('')
}

setShiftdataId() {
  this.shiftId = 0;
  $('#startShiftDate').val('');
  $('#endShiftDate').val('');
  $('#startShiftTime').val('');
  $('#endShiftTime').val('');
  this.isRecurring = false;
  this.recurringPattern = {
      "separationCount": 0,
      "maxOccurrence": -1,
      "isSunday": false,
      "isMonday": false,
      "isTuesday": false,
      "isWednesday": false,
      "isThursday": false,
      "isFriday": false,
      "isSaturday": false,
      "dayOfMonth": 0,
      "weekOfMonth": 0,
      "recurringType": {}
  };

  this.addShift = {
      "isBusinessHour": true,
      "name": "",
      "client": {
          "clientId": this.clientId
      },
      "startTime": "",
      "endTime": "",
      "isDeleted": false,
      "startDate": "",
      "endDate": "",
      "isRecurring": false,
      "shiftGroup": {
          "shiftGroupId": this.shiftgroupId
      },
      "isActive": true,
      "recurringPattern": {}
  };
  this.shiftNameerr = ''
  this.shiftsDateerr = ''
  this.shifteDateerr = ''
  this.shifteTimeerr = ''
  this.shiftsTimeerr = ''
  this.selectedStartDate = ''
  this.selectedEndDate = ''
  this.RecStartDateErr = ''
  this.RecEndDateErr = ''
  this.shiftErr = ''
  this.RecPatternErr = ''
}


addShiftData() {
  this.addShift.startDate = $('#startShiftDate').val();
  this.addShift.endDate = $('#endShiftDate').val();
  this.addShift.startTime = $('#startShiftTime').val();
  this.addShift.endTime = $('#endShiftTime').val();
  let RecStartDate = $('#recurringShiftStartDate').val();
  console.log(this.addShift, this.view, this.viewDate);
  
  this.shiftNameerr = ''
  this.shiftsDateerr = ''
  this.shifteDateerr = ''
  this.shifteTimeerr = ''
  this.shiftsTimeerr = ''
  this.RecStartDateErr = ''
  this.RecEndDateErr = ''
  this.RecPatternErr = ''

  if (!this.addShift.name) {
      this.shiftNameerr = "Please enter Shift name."
  }
  if (!this.addShift.startDate) {
      this.shiftsDateerr = "Please select Start date.";
  }

  if (!this.addShift.endDate) {
      this.shifteDateerr = "Please select End date";
  }

  if (!this.addShift.endTime) {
    this.shifteTimeerr = "Please select end time";
  }
  if (!this.addShift.startTime) {
    this.shiftsTimeerr = "Please select start time";
  }
  if (!RecStartDate) {
    this.RecStartDateErr = "Please select recurrence start date"
  }
  if (!this.recurringPattern.recurringType.reccuringTypeId) {
    this.RecPatternErr = "Please select Recurrence Pattern "
  }

  if (!this.addShift.name || !this.addShift.startDate || !this.addShift.endDate || !this.addShift.startTime || !this.addShift.endTime || !RecStartDate) {
    return
  }


  if (this.addShift.name && this.addShift.startDate && this.addShift.endDate) {
      this.recurringPattern.recurrenceStartDate = $('#recurringShiftStartDate').val();
      this.recurringPattern.recurrenceEndDate = $('#recurringShiftEndDate').val();
      this.addShift.shiftGroup.shiftGroupId = parseInt(this.shiftgroupId);
      delete this.addShift.shiftName
      // console.log('this.addShift.shiftGroup.shiftGroupId',this.yearMonthObj,this.recurringPattern,this.addShift);
      if (this.recurringPattern.recurringType.reccuringTypeId != '2') {
          this.recurringPattern.isMonday = false
          this.recurringPattern.isTuesday = false
          this.recurringPattern.isWednesday = false
          this.recurringPattern.isThursday = false
          this.recurringPattern.isFriday = false
          this.recurringPattern.isSaturday = false
          this.recurringPattern.isSunday = false
      }
      if (this.recurringPattern.recurringType.reccuringTypeId == '3' && this.type_value=='ON') {
          this.recurringPattern.dayOfMonth = this.yearMonthObj.dayOfMonth
          this.recurringPattern.separationCount = this.yearMonthObj.separationCount;

      }
      if (this.recurringPattern.recurringType.reccuringTypeId == '3' && this.type_value=='THE') {
          this.recurringPattern.dayOfMonth = this.yearMonthObj.dayOfMonth;
          this.recurringPattern.weekOfMonth = this.yearMonthObj.weekOfMonth;

      }
      if (this.recurringPattern.recurringType.reccuringTypeId == '4' && this.type_value=='ON') {
          this.recurringPattern.monthOfYear = this.yearMonthObj.monthOfYear;
          this.recurringPattern.separationCount = this.yearMonthObj.separationCount;

      }
      if (this.recurringPattern.recurringType.reccuringTypeId == '3' && this.type_value=='THE') {
          this.recurringPattern.dayOfMonth = this.yearMonthObj.dayOfMonth;
          this.recurringPattern.weekOfMonth = this.yearMonthObj.weekOfMonth;
          this.recurringPattern.monthOfYear = this.yearMonthObj.monthOfYear;
      }
      if (this.maxOccurrence==-1) {
          this.recurringPattern.maxOccurrence = -1;
          this.recurringPattern.recurrenceEndDate  = null;
      }else if(this.maxOccurrence == 'occurence'){
          this.recurringPattern.recurrenceEndDate  = null;
          this.recurringPattern.maxOccurrence = this.maxOccurrenceValue
      }else if(this.maxOccurrence == 'date'){
          // this.recurringPattern.recurringEndDate  = null;
          this.recurringPattern.maxOccurrence = 0;// this.maxOccurrenceValue
          if (!this.recurringPattern.recurrenceEndDate) {
            this.RecEndDateErr = "Please select date."
            return;
          }
      }


      this.addShift.recurringPattern = this.recurringPattern;

      // if (this.isRecurring == true) {
      //     this.addShift.recurringPattern = this.recurringPattern;

      // }
      console.log(this.addShift);
      
      this.shiftErr = ''
      if (this.shiftId == 0) {
          this.apiService.create('shift/createShift', this.addShift).subscribe(
              data => {
                  if (data.statusCode == 100) {
                      // this.getShiftGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));
                      this.setShiftdataId();
                      this.alertService.sweetMessage('success','Shift Added Successfully');
                      this.getScheduleGroupData(moment(this.viewDate).startOf(this.view).format("YYYY-MM-DD"),moment(this.viewDate).endOf(this.view).format("YYYY-MM-DD"));
                      this.getDayData(this.eventDate);
                      $("#createNewShiftPopup").modal('hide')
                  } else {
                      // this.alertService.sweetMessage('error',data.responseMessage);
                      this.shiftErr = data.responseMessage
                  }
              },
             error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
          );
      } else {
          this.apiService.update('shift/updateShift', this.addShift).subscribe(
              data => {
                  if (data.statusCode == 100) {
                      // this.getShiftGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));
                      this.setShiftdataId();
                      this.getScheduleGroupData(moment(this.viewDate).startOf(this.view).format("YYYY-MM-DD"),moment(this.viewDate).endOf(this.view).format("YYYY-MM-DD"));
                      this.getDayData(this.eventDate);
                      $("#createNewShiftPopup").modal('hide')
                      this.alertService.sweetMessage('success','Shift Updated Successfully');
                  } else {
                      // this.alertService.sweetMessage('error',data.responseMessage);
                      this.shiftErr = data.responseMessage
                  }
              },
             error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
          );
      }
  }else{
  this.alertService.sweetMessage('error','Please enter shift name, Start date and End date');
  }
}

getShiftId(shiftId) {
  this.shiftId = shiftId;
  this.shiftErr = '';
  this.shiftNameerr = '';
  this.shifteDateerr = ''
  this.shiftsDateerr = ''
  this.shifteTimeerr = ''
  this.shiftsTimeerr = ''
  this.RecStartDateErr = ''
  this.RecEndDateErr = ''
  this.shiftErr = ''
  this.RecPatternErr = ''
  this.apiService.getAll('shift/getShiftById?shiftId=' + shiftId).subscribe(
      data => {
          if (data.statusCode == 100) {
              this.addShift = data.response;
              this.addShift.name = this.addShift.shiftName;
              $('#startShiftDate').val(this.addShift.startDate);
              $('#endShiftDate').val(this.addShift.endDate);
              // $('#startShiftTime').val(moment(this.addShift.startTime, "hmm").format("HH:mm"))
              // $('#endShiftTime').val(moment(this.addShift.endTime, "hmm").format("HH:mm"))
              this.selectedStartDate = this.addShift.startDate;
              this.selectedEndDate = this.addShift.endDate;
              this.isRecurring = this.addShift.isRecurring;
              this.recurringPattern = this.addShift.recurringPattern;
              this.recurringPattern.recurringType.reccuringTypeId = this.recurringPattern.recurringType.reccuringTypeId.toString();
                this.yearMonthObj = this.addShift.recurringPattern;
                if (Date.parse(this.recurringPattern.recurrenceEndDate)) {
                    this.maxOccurrence = 'date'
                }else if (this.recurringPattern.maxOccurrence > 0) {
                    this.maxOccurrence = "occurence"
                }else if(this.recurringPattern.maxOccurrence==-1){
                    this.maxOccurrence = "-1"
                }
                $('#recurringShiftStartDate').val(this.recurringPattern.recurrenceStartDate);
                $('#recurringShiftEndDate').val(this.recurringPattern.recurrenceEndDate);
              if (this.isRecurring == true) {
                  this.getrecurringTypeData();
              }
              $('#createNewShiftPopup').modal('show');
          } else {
              this.alertService.sweetMessage('error',data.responseMessage);
          }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
         }
  );
}

/* Function for getting recurring type */
getrecurringTypeData() {
  this.apiService.getAll('recurringType/getAllRecurringType').subscribe(
      data => {
          if (data.statusCode == 100) {
              this.recurringTypeData = data.responseList;
          } else {
              this.recurringTypeData = [];
          }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
         }
  );
}

printCalander() {
// onload this.viewDate

  var URL = this.apiService.baseBath+"/schedule/getCalenderPdf?userId="+this.userId+"&shiftGroupId="+this.shiftgroupId+"&clientId="+this.clientId+"&month="+this.printMonth.split('/')[0]+"&year="+this.printMonth.split('/')[1];

  console.log(URL);

  $("#pdflink").attr("href", URL)

  // setTimeout(()=>{
    var achonr = document.getElementById("pdflink");
    achonr.click()
    // // $("#pdflink").click();
    //   console.log('click')
  // },1000)

  // var downloadUrl = "http://172.20.1.35:8764/api/v1/schedule/getCalenderPdf?shiftGroupId=1&clientId=1&month=1&year=2019";
  //
  // var downloading = browser.downloads.download({
  //   url : downloadUrl,
  //   filename : 'my-image-again.png',
  //   conflictAction : 'uniquify'
  // });
}


  resetForm(){
    this.userLeaveId = 0;
    this.leaveId = 0;
    this.isApproved = false;
    this.f.reset()
    this.f.submitted = false;
    var note = $('#note').val('')
    // var fromDate = $('#lfromDate').val(moment().format("YYYY-MM-DD"));
    // var toDate = $('#ltoDate').val(moment().format("YYYY-MM-DD"));
    var user = $('#userLeaveId').val('');
    var replacementUser = $('#userLeaveIdto').val('');
    this.dateError = '';
    this.toDateError = '';
    this.leaveData   = {
      "fromDate": "",//moment().format("YYYY-MM-DD"),
      "toDate": "",//moment().format("YYYY-MM-DD"),
      "user": {"userId":''},
      "remark": "",
      replacementUser:{}
    }
    this.userDropDownfrom = []
    this.userDropDownto = []
  }

  applyLeave(formValue){
    this.dateError = '';
    this.toDateError = '';
    var fromDate  = $('#lfromDate').val();
    var toDate    = $('#ltoDate').val();
    if(moment(toDate).diff(moment(fromDate)) < 0){
      this.dateError = "From Date should not be greater than To Date."
      return;
    }
    if(!fromDate){
      this.dateError = "From Date is required."
      return;
    }
    if(!toDate){
      this.toDateError = "To Date is required."
      return;
    }

    if (!formValue.invalid) {
    if(this.leaveId == 0){

      var URL = 'timeOffRequest/createTimeOffRequest';

      var note      = $('#note').val()
      var fromDate  = $('#lfromDate').val();
      var toDate    = $('#ltoDate').val();

      this.leaveData.fromDate = fromDate;
      this.leaveData.toDate   = toDate;
      this.leaveData.remark   = note;

      this.apiService.create(URL, this.leaveData).subscribe(
        data => {
          if (data.statusCode == 100) {
              $('#closepopup').click();
              // this.getLeaveList();
              this.getDayData(this.eventDate);
              this.getScheduleGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));           
              this.alertService.sweetMessage('success', 'Leave apply successfully');
          }
          else
            { this.alertService.sweetMessage('error', data.responseMessage); }
        },
        error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    }else {

      var URL = 'timeOffRequest/updateTimeOffRequest';

      var note      = $('#note').val()
      var fromDate  = $('#lfromDate').val();
      var toDate    = $('#ltoDate').val();

      this.leaveData.fromDate = fromDate;
      this.leaveData.toDate   = toDate;
      this.leaveData.remark   = note;
      this.leaveData.timeOffRequestId   = this.leaveId;

      this.apiService.update(URL, this.leaveData).subscribe(
        data => {
          if (data.statusCode == 100) {
              $('#closepopup').click();
              // this.getLeaveList();
              this.getDayData(this.eventDate);
              this.getScheduleGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));
              
              this.alertService.sweetMessage('success', 'Leave Updated successfully');
          }
          else
            { this.alertService.sweetMessage('error', data.responseMessage); }
        },
        error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    }
  }
  }

  changeDateRequest(){
    var fromDate  = $('#lfromDate').val();
    var toDate    = $('#ltoDate').val();
    this.dateError = ''
    // console.log(moment(this.leaveData.toDate).format('YYYY-MM-DD'), moment(this.leaveData.fromDate).format('YYYY-MM-DD'), fromDate,toDate);
    var fromDate  = $('#lfromDate').val();
    var toDate    = $('#ltoDate').val();
    if(moment(toDate).diff(moment(fromDate)) < 0){
      this.dateError = "From Date should not be greater than To Date."
      return;
    }
    if (fromDate && toDate) {
      setTimeout(() => { 
        this.getUserDropDown();
        this.getReplacementUserDropDown();
      }, 500);
    }
    // http://172.20.1.165:8764/api/v1/timeOffRequest/getTimeOffRequestUser?clientId=5&sDate=2019-10-22&eDate=2019-10-25
    // this.getUserDropDown()
  }

  getUserDropDown(){
    // var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+'&isActive=true&isDeactivated=false';

    // http://172.20.1.104:8764/api/v1/timeOffRequest/getTimeOffReplacementUser?clientId=5&sDate=2019-10-31&eDate=2019-10-31
    var URL = 'timeOffRequest/getTimeOffRequestUser?clientId='+this.clientId+'&sDate='+moment(this.leaveData.fromDate).format('YYYY-MM-DD')+'&eDate='+moment(this.leaveData.toDate).format('YYYY-MM-DD');

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
            this.userDropDown = data.responseList;
            this.userDropDownfrom = JSON.parse(JSON.stringify(this.userDropDown))
            // this.userDropDownto = JSON.parse(JSON.stringify(this.userDropDown))
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
      error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }
  getReplacementUserDropDown(){
    // var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+'&isActive=true&isDeactivated=false';
    var URL = 'timeOffRequest/getTimeOffReplacementUser?clientId='+this.clientId+'&sDate='+moment(this.leaveData.fromDate).format('YYYY-MM-DD')+'&eDate='+moment(this.leaveData.toDate).format('YYYY-MM-DD');

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
            this.userDropDownReplacementAll = data.responseList;
            this.userDropDownto = JSON.parse(JSON.stringify(data.responseList))
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
      error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

  changeUserFron(){
    let userID = this.leaveData.user.userId
    this.userDropDownto = JSON.parse(JSON.stringify(this.userDropDownReplacementAll))
    let findIndex = null
    let index = this.userDropDownto.find(function(obj,index){
      // console.log(obj, index);
        
      if (obj.userId == userID) {
        findIndex = index;
        return
      }
      
    })
    // console.log(index , "------------------------------");
    this.userDropDownto.splice(findIndex,1)
  }


  TimeChange(time){    
    return moment(moment().format('DD-MM-YYYY')+' '+time,'DD-MM-YYYY HH:mm:ss').format('HH:mm')
  }
  getShifthistory(groupdata) {

    this.shiftHistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
    this.apiService.getAll('shift/getShiftHistory?shiftId=' + groupdata.shiftId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.shiftHistoryList = data.responseList;
          setTimeout(() => {
            const table: any = $('#HistoryDatatable');
                /* this.dataTable = */ table.DataTable({ "order": [], "destroy": true });
          }, 500);
        } else {
          setTimeout(() => {
            const table: any = $('#HistoryDatatable');
                /* this.dataTable =  */table.DataTable({ "order": [], "destroy": true });
          }, 500);
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getShiftUserTooltip(shiftUsers){
    let str = ''
    if (shiftUsers) {
      if (shiftUsers.length) {
        shiftUsers.map((obj,index)=>{
          if (index==0) {
            str += obj.userName
          }else{
            str = str+', '+obj.userName
          }
        })
      }
    }
    return str;
  }

  setGroupList(list){
   
    this.groupUserList = list;
    
  }
}
