import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { CanActivate,Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    dataTable: any;
    reportListData: any = [];
    clientCode : any = ''; 
    permissionData:any=[];
  HistoryList: any = [];


    constructor(private router: Router, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
      this.permissionData = ApiService.permission;
    }

     DateFormat(startDate){
  if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
  }else{
      return "";
    }
 }
  ngOnInit() {
        this.clientCode = this.alertService.getClientCode();
        this.getAllReportList();
  }


    getAllReportList() {
        var URL = 'report/getAllReport?userId='+this.userId+'&clientId='+this.clientId;
        this.apiService.getAll(URL).subscribe(
            data => {
                    if (data.statusCode == 100) {
                    this.reportListData = data.responseList;
                    this.sortDataTableData(this.reportListData,'reportId',"desc");
                    if ($.fn.DataTable.isDataTable('#report_table')){
                        $('#report_table').DataTable().destroy();
                    };
                    this.chRef.detectChanges();
                    const table: any = $('#report_table');
                    this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 4 }]});
                } else {
                    this.reportListData = [];
                    this.chRef.detectChanges();
                    const table: any = $('#report_table');
                    this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 4 }]});
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /** Function to sort data **/
    sortDataTableData(items,sortBy,desc){
         if (sortBy && sortBy != null) {
                items = items.sort((a, b) => {
                 const sortA = a[sortBy];
                 const sortB = b[sortBy];
                 if (desc) {
                     if (sortA < sortB) return 1;
                     if (sortA > sortB) return -1;
                     return 0;
                 } else {
                     if (sortA < sortB) return -1;
                     if (sortA > sortB) return 1;
                     return 0;
                 }
             });
            return items;
         }else{
            return items;
         }
    }

   deleteReport(reportId){
  	  Swal({
    	  title: 'Are you sure?',
    	  text: "You want to delete this report.",
    	  type: 'warning',
    	  showCancelButton: true,
    	  confirmButtonColor: '#3085d6',
    	  cancelButtonColor: '#d33',
    	  confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.apiService.getAll('report/deleteReport?reportId='+reportId).subscribe(
            data => {
              if(data.statusCode == 100){
                Swal({
                  position: 'top-end',
                  type: 'success',
                  title: 'Report deleted successfully',
                  showConfirmButton: false,
                  timer: 1500
                });
                this.getAllReportList();
              }else{
                this.alertService.sweetMessage('error',data.responseMessage);
              }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        }
    })
  }

showReportInfo(reportId){
	console.log(reportId);
}

getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('report/getReportHistory?reportId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

getClientTooltip(clients){
  let str = ''
  if (clients) {
    if (clients.length) {
      clients.map((obj,index)=>{
        if (index==0) {
          str += obj.login ? obj.login : obj.emailId
        }else{
          str = str+', '+(obj.login ? obj.login : obj.emailId)
        }
      })
    }
  }
  // console.log(clients, str);
  
  return str;
}

getTierTooltip(tierList){
  let str = ''
  if (tierList) {
    if (tierList.length) {
      tierList.map((obj,index)=>{
        if (index==0) {
          str += obj.tierName ? obj.tierName : ''
        }else{
          str = str+', '+(obj.tierName ? obj.tierName : '')
        }
      })
    }
  }
  // console.log(clients, str);
  return str;
}

getSiteTooltip(List){
  let str = ''
  if (List) {
    if (List.length) {
      List.map((obj,index)=>{
        if (index==0) {
          str += obj.siteName ? obj.siteName : ''
        }else{
          str = str+', '+(obj.siteName ? obj.siteName : '')
        }
      })
    }
  }
  // console.log(clients, str);
  return str;
}
getAssetTooltip(List){
  let str = ''
  if (List) {
    if (List.length) {
      List.map((obj,index)=>{
        if (index==0) {
          str += obj.assetName ? obj.assetName : ''
        }else{
          str = str+', '+(obj.assetName ? obj.assetName : '')
        }
      })
    }
  }
  // console.log(clients, str);
  return str;
}
getCustomerTooltip(List){
  let str = ''
  if (List) {
    if (List.length) {
      List.map((obj,index)=>{
        if (index==0) {
          str += obj.name ? obj.name : ''
        }else{
          str = str+', '+(obj.name ? obj.name : '')
        }
      })
    }
  }
  // console.log(clients, str);
  return str;
}

getWeekName(count){
  let dataCount = count
  let Str = ''
  if (dataCount >= 64) {
    dataCount = dataCount - 64;
    // if (Str) {
      Str = Str+'Saturday'
    // }
}
if (dataCount >= 32) {
    dataCount = dataCount - 32;
    if (Str) {
      Str = Str+', Friday'
    }else{
      Str = Str+' Friday'
    }
}
if (dataCount >= 16) {
  dataCount = dataCount - 16;
  if (Str) {
    Str = Str+', Thursday'
  }else{
    Str = Str+' Thursday'
  }
}
if (dataCount >= 8) {
    dataCount = dataCount - 8;
    if (Str) {
      Str = Str+', Wednesday'
    }else{
      Str = Str+' Wednesday'
    }
}
if (dataCount >= 4) {
    dataCount = dataCount - 4;
    if (Str) {
      Str = Str+', Tuesday'
    }else{
      Str = Str+' Tuesday'
    }
}
if (dataCount >= 2) {
    dataCount = dataCount - 2;
    if (Str) {
      Str = Str+', Monday'
    }else{
      Str = Str+' Monday'
    }
}
 if (dataCount >= 1) {
    dataCount = dataCount - 1;
    if (Str) {
      Str = Str+', Sunday'
    }else{
      Str = Str+' Sunday'
    }
}
return Str;
}
getMonthName(val){
  let month = ''
  switch (val) {
    case 1:
      month = 'January'
      break;
    case 2:
      month = 'February'
      break;
    case 3:
      month = 'March'
      break;
    case 4:
      month = 'April'
      break;
    case 5:
      month = 'May'
      break;
    case 6:
      month = 'June'
      break;
    
    case 7:
      month = 'July'
      break;
    case 8:
      month = 'August'
      break;
    case 9:
      month = 'September'
      break;
    
    case 10:
      month = 'October'
      break;
    case 11:
      month = 'November'
      break;
    case 12:
      month = 'December'
      break;
    default:
      break;
  }
  return month
}
}
