import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ViewComponent } from './view.component';
import { DashboardComponent } from './section/dashboard/dashboard.component';
/* tier */
import { TierComponent } from './section/tier/tier.component';
import { TiersComponent } from './section/tier/tiers/tiers.component';
/* user */
import { CustomersComponent } from './section/customers/customers.component';
/* Roles */
import { RolesComponent } from './section/customers/roles/roles.component';
/* Site */
import { SitesComponent } from './section/customers/sites/sites.component';
/* Package */
import { LicenseComponent } from './section/customers/license/license.component';
/* Assets */
import { AssetsComponent } from './section/customers/assets/assets.component';
/* Shift  */
import { ShiftManagementComponent } from './section/shift-management/shift-management.component';
// import { ShiftComponent } from './section/shift-management/shift/shift.component';
// import { ShiftCalenderComponent } from './section/shift-management/shift-calender/shift-calender.component';
import { ShiftViewCalenderComponent } from './section/shift-management/shift-view-calender/shift-view-calender.component';
/* MySchedule*/
// import { MyScheduleComponent } from './section/my-schedule/my-schedule.component';
/* TeamSchedule*/
import { TeamScheduleComponent } from './section/team-schedule/team-schedule.component';
/* FixedSchedule*/
import { FixedScheduleComponent } from './section/fixed-schedule/fixed-schedule.component';
/* Notification-plan */
import { NotificationPlanComponent } from './section/customers/notification-plan/notification-plan.component';
/* Users */
import { UsersComponent } from './section/customers/users/users.component';
/* Role Management */
import { RoleManagementComponent } from './section/role-management/role-management.component';
import { AddRoleComponent } from './section/role-management/addrole/addrole.component';
/* Site Management */
import { SiteManagementComponent } from './section/site-management/site-management.component';
import { AddSiteComponent } from './section/site-management/add-site/add-site.component';
/* User Management */
// import { UserManagementComponent } from './section/user-management/user-management.component';
import { UserListComponent } from './section/user-management/user-list/user-list.component';
import { AddUserComponent } from './section/user-management/adduser/adduser.component';
/* Asset Management */
import { AssetManagementComponent } from './section/asset-management/asset-management.component';
/* Notification Plan Management */
// import { NotificationPlanManagementComponent } from './section/notification-plan-management/notification-plan-management.component';
// import { AdminNotificationComponent } from './section/notification-plan-management/admin-notification/admin-notification.component';

// import { AddNotitficationComponent } from './section/notification-plan-management/add-notitfication/add-notitfication.component';

// import { AddNotificationPlanComponent } from './section/notification-plan-management/add-notification-plan/add-notification-plan.component';
import { RoleBasedNotificationComponent } from './section/notification-plan-management/role-based-notification/role-based-notification.component';

/* Statistics */
// import { StatisticsComponent } from './section/statistics/statistics.component';
/* Report */
import { ReportComponent } from './section/report/report.component';
import { AddReportComponent } from './section/report/add-report/add-report.component';
import { AdhocReportComponent } from './section/report/adhoc-report/adhoc-report.component';
import { AssetSummaryReportComponent } from './section/report/asset-summary-report/asset-summary-report.component';
/* Setting */
import { SettingComponent } from './section/setting/setting.component';
import { ClientSettingComponent } from './section/setting/client-setting/client-setting.component';
/* Customer */
import { CustomerComponent } from './section/customers/customer/customer.component';
/* AddCustomer */
import { AddCustomerComponent } from './section/customers/customer/add-customer/add-customer.component';
/* CustomerAdd */
import { CustomerAddComponent } from './section/customers/customer-add/customer-add.component';
/* notificationEvent */
// import { NotificationEventComponent } from './section/notification-event/notification-event.component';
/* AssetSummary Section  */
/* Customer Menu  */
import { CustomerMenuComponent } from './section/customers/customer-menu/customer-menu.component';
/* LiveFeed  */
// import { LiveFeedComponent } from './section/live-feed/live-feed.component';
/* Asset Summary  */
import { AssetSummaryComponent } from './section/asset-summary/asset-summary.component';

import { AssetAvailabilityComponent } from './section/asset-availability/asset-availability.component';

/* Data Point  */
import { DataPointComponent } from './section/asset-summary/data-point/data-point.component';
/* Asset Graph  */
import { AssetGraphComponent } from './section/asset-summary/asset-graph/asset-graph.component';
/* Modem Traffic  */
import { ModemTrafficComponent } from './section/asset-summary/modem-traffic/modem-traffic.component';
/* Comments  */
// import { CommentsComponent } from './section/asset-summary/comments/comments.component';
/* Documents  */
import { DocumentsComponent } from './section/asset-summary/documents/documents.component';
/* mEvents */
import { EventsComponent } from './section/asset-summary/events/events.component';
/* Partlets  */
// import { PartletsComponent } from './section/asset-summary/partlets/partlets.component';
// /* Products  */
// import { ProductsComponent } from './section/asset-summary/products/products.component';
/* Activities  */
// import { ActivitiesComponent } from './section/asset-summary/activities/activities.component';
/* Assets Log */
import { AssetsLogComponent } from './section/asset-summary/assets-log/assets-log.component';
/* Copy Schedule */
import { CopyScheduleComponent } from './section/asset-summary/copy-schedule/copy-schedule.component';

// import { AssetCalenderComponent } from './section/asset-summary/assets-calender/assets-calender.component';

/* Assets Data Pocket  */
// import { AssetsDataPocketComponent } from './section/asset-summary/assets-data-pocket/assets-data-pocket.component';
/* Template  */
import { TemplateComponent } from './section/setting/template/template.component';
import { AssetsummaryMenuComponent } from './section/asset-summary/assetsummary-menu/assetsummary-menu.component';
import { AssetEditComponent } from './section/asset-summary/asset-edit/asset-edit.component';
import { AssetUserComponent } from './section/asset-summary/asset-user/asset-user.component';
import { ApprovedListComponent } from './section/approved-list/approved-list.component';
// import { NgxPermissionsGuard } from 'ngx-permissions';
// import { ScheduleCalenderComponent } from './section/schedule-calender/schedule-calender.component';
import { AddCustomerUserComponent } from './section/customers/users/adduser/adduser.component';
import { ViewCustomerUserComponent } from './section/customers/users/viewuser/viewuser.component';

import { PendingChangesGuard } from '../../pending-changes.guard';
import { PermissionGuard } from '../../services/permission.guard';

import { TicketComponent } from './section/ticket/ticket.component';
import { GlobalSearchComponent } from './section/global-search/global-search.component';

import { UserProfileComponent } from './section/user-management/user-profile/user-profile.component';

import { ErrorHandlingComponent } from './section/error-handling/error-handling.component';
import { NewNotificatioPlanListComponent } from './section/notification-plan-management/new-notificatio-plan-list/new-notificatio-plan-list.component';

import { EventDetailsComponent } from './section/event-details/event-details.component';

import { LeaveManagementComponent } from './section/leave-management/leave-management.component';

import { TeamTimeOffRequestComponent } from './section/leave-management/team-time-off-request/team-time-off-request.component';
import { EventResolve } from 'src/app/services/event.resolve.service';
import { GraphReportComponent } from './section/graph-report/graph-report.component';
import { ManageDashboardComponent } from './section/setting/manage-dashboard/manage-dashboard.component';
import { MydashboardComponent } from './section/setting/mydashboard/mydashboard.component';
import { CustomizeDashboardComponent } from './section/setting/customize-dashboard/customize-dashboard.component';
import { HourMeterReportComponent } from './section/report/hour-meter-report/hour-meter-report.component';
import { SiteLocationReportComponent } from './section/report/site-location-report/site-location-report.component';
import { SiteDataPointSummaryReportComponent } from './section/report/site-data-point-summary-report/site-data-point-summary-report.component';
import { RuntimeEventReportComponent } from './section/report/runtime-event-report/runtime-event-report.component';
import { SiteMessageHistoryReportComponent } from './section/report/site-message-history-report/site-message-history-report.component';
import { SiteEventNotificationReportComponent } from './section/report/site-event-notification-report/site-event-notification-report.component';

// import { UserInfoResolve } from 'src/app/services/userInfo.resolve';
/* Site Message Activity Report */
import { SiteMessageActivityReportComponent } from './section/report/site-message-activity-report/site-message-activity-report.component';
import { TechEntryWizardReportComponent } from './section/report/tech-entry-wizard-report/tech-entry-wizard-report.component';
import { AssetActivationStatusComponent } from './section/asset-summary/asset-activation-status/asset-activation-status.component'; 
import { InAppNotifcationComponent } from './section/asset-summary/in-app-notifcation/in-app-notifcation.component';
import { ReportDataComponent } from './section/report/report-data/report-data.component';
import { TEWEditComponent } from './section/tew-edit/tew-edit.component';
import { UnitsComponent } from './section/asset-summary/units/units.component';
const routes: Routes = [{
  path: '',
  component: ViewComponent, resolve :{event:EventResolve/* , userInfo:UserInfoResolve */},
  children: [
    {
    path: 'error',
    component: ErrorHandlingComponent,
  },
  // {
  //   path:'',
  //   loadChildren:'./section/user-management/user-management.module#UserManagementModule'
  // },
  {
    path: 'dashboard',
    component: DashboardComponent, //resolve :{event:EventResolve/* , userInfo:UserInfoResolve */},
    //canActivate: [PermissionGuard]
  },
  {
    path: 'role-management',
    component: RoleManagementComponent,
  },
  {
    path: 'site-management',
    component: SiteManagementComponent,
  },/* {
    path: 'user-management',
    component: UserManagementComponent,
  }, */
  {
    path: 'user-list',
    component: UserListComponent,
    // loadChildren: ()=>UserListModule

  },
  {
    path: 'user-profile/:id',
    component: UserProfileComponent,
  },
  {
    path: 'shift-management',
    component: ShiftManagementComponent,
  // },{
  //   path: 'shiftgroup/:id',
  //   component: ShiftComponent,
  // },{
  //   path: 'shiftcalender/:id',
  //   component: ShiftCalenderComponent,
  },{
    path: 'shiftviewcalender/:shiftGroupId',
    component: ShiftViewCalenderComponent,
  },
  // {
  //   path: 'my-schedule',
  //   component: MyScheduleComponent,
  // },
  {
    path: 'team-schedule',
    component: TeamScheduleComponent,
  },{
    path: 'fixed-schedule',
    component: FixedScheduleComponent,
  },
  {
    path: 'asset-management',
    component: AssetManagementComponent,
   /* canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ApiService.permission.Asset_View,
        redirectTo: '/login'
      }
    }*/
  },
  {
    path: 'asset-management/:status',
    component: AssetManagementComponent,
  },
  {
    path: 'asset-management/:status/:assetId',
    component: AssetManagementComponent,
  },
  // {
  //   path: 'notification-management',
  //   component: NotificationPlanManagementComponent,
  // },
  // {
  //   path: 'admin-notification',
  //   component: AdminNotificationComponent,
  // },
  // {
  //   path: 'statistics',
  //   component: StatisticsComponent,
  // },
  {
    path: 'report',
    component: ReportComponent,
  },{
    path: 'assetsummaryreport',
    component: AssetSummaryReportComponent,
  },{
    path: 'addreport',
    component: AddReportComponent,
  },{
    path: 'adhocreport',
    component: AdhocReportComponent,
  },{
    path: 'addreport/:reportId',
    component: AddReportComponent,
  },{
    path: 'adhocreport/:reportId',
    component: AdhocReportComponent,
  },{
    path: 'setting',
    component: SettingComponent,
  },
  {
    path: 'company-profile',
    component: ClientSettingComponent,
  },
  // {
  //   path: 'schedule_calender/:assetId/:shiftGroupId',
  //   component: ScheduleCalenderComponent
  // },
  {
    path: 'global-search/:key',
    component: GlobalSearchComponent
  },
  {
    path: 'customers',
    component: CustomersComponent,
   /* canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'ADMIN',
        redirectTo: '/login'
      }
    }*/
  },{
    path: 'customers/:key',
    component: CustomersComponent,
  },{
    path: 'customer/:id',
    component: CustomerComponent,
  },{
    path: 'addcustomer',
    component: CustomerAddComponent,
  },{
    path: 'addcustomer/:id',
    component: CustomerAddComponent,
  },{
    path: 'customerdetail/:id',
    component: AddCustomerComponent,
  },{
    path: 'sites/:id',
    component: SitesComponent,
  },{
    path: 'license/:id',
    component: LicenseComponent,
  },{
    path: 'assets/:id',
    component: AssetsComponent,
  },{
    path: 'notifications/:id',
    component: NotificationPlanComponent,
  },
  // {
  //   path: 'notification-event',
  //   component: NotificationEventComponent,
  // },{
  //   path: 'notification-event/:status',
  //   component: NotificationEventComponent,
  // },
  {
    path: 'roles/:id',
    component: RolesComponent,
  },{
    path: 'users/:id',
    component: UsersComponent,
  },{
    path: 'tier/dashboard',
    component: TierComponent,
  },{
    path: 'tier/tiers',
    component: TiersComponent,
    // canDeactivate: [PendingChangesGuard]
  },{
    path:'site/addsite',
    component:AddSiteComponent,
    canDeactivate: [PendingChangesGuard]
  },{
    path: 'site/addsite/:id',
    component: AddSiteComponent,
    canDeactivate: [PendingChangesGuard]
  },/* {
    path:'addnotification',
    component:AddNotitficationComponent,
    canDeactivate: [PendingChangesGuard]
  },{
    path:'addnotification/:id',
    component:AddNotitficationComponent,
    canDeactivate: [PendingChangesGuard]
  }, */
// {
//     path:'addnotificationPlan',
//     component:AddNotificationPlanComponent,
//   },
  {
    path:'role-management/addrole',
    component:AddRoleComponent,
  },{
    path:'role-management/addrole/:id',
    component:AddRoleComponent,
  },{
    path:'user/adduser',
    component:AddUserComponent,
    // loadChildren:()=>AdduserModule
  },{
    path: 'user/adduser/:id',
    component: AddUserComponent
    // loadChildren:()=>(AdduserModule)

  },{
    path: 'assetsummary/:id',
    component: AssetSummaryComponent
  },{
    path: 'documents/:id',
    component: DocumentsComponent
  },{
    path: 'events/:id',
    component: EventsComponent
  // },{
  //   path: 'partlets/:id',
  //   component: PartletsComponent
  // },{
  //   path: 'products/:id',
  //   component: ProductsComponent
  // },{
  //   path: 'activities/:id',
  //   component: ActivitiesComponent
  },{
    path: 'assetsLog/:id',
    component: AssetsLogComponent
  },{
    path: 'copy-schedule/:id',
    component: CopyScheduleComponent
  // },{
  //   path: 'assetsdatapocket/:id',
  //   component: AssetsDataPocketComponent
  },{
    path: 'modemtraffic/:id',
    component: ModemTrafficComponent
  },{
    path: 'datapoint/:id',
    component: DataPointComponent
  },{
    path: 'units/:id',
    component: UnitsComponent
  },{
    path:'assetGraph/:id',
    component:AssetGraphComponent
  },{
    path: 'assetsummarymenu',
    component: AssetsummaryMenuComponent
  },{
    path:'asset-edit',
    component:AssetEditComponent
  },{
    path: 'customermenu',
    component: CustomerMenuComponent
  },{
    path: 'assetUser/:id',
    component: AssetUserComponent
  },
  // {
  //   path: 'LiveFeed/:id',
  //   component: LiveFeedComponent
  // },
  {
    path: 'approvedlist',
    component: ApprovedListComponent
  },
  // {
  //   path: 'comments/:id',
  //   component: CommentsComponent
  // },
    {
    path: 'asset-availability',
    component: AssetAvailabilityComponent
  },
  {
    path: 'template',
    component: TemplateComponent
  },
  //  {
  //   path: 'assets-calender/:id',
  //   component: AssetCalenderComponent
  // },

  {
   path: 'add-customer-user',
    component: AddCustomerUserComponent

  },
  {
    path: 'add-customer-user/:id',
    component: AddCustomerUserComponent
  },{
    path: 'view-user/:id',
    component: ViewCustomerUserComponent
  },
  {
    path: 'ticket',
    component: TicketComponent
  },
  {
    path: 'new-notification-plan-list',
    component: NewNotificatioPlanListComponent
  },
  {
    path: 'add-rolebase-plan',
    component: RoleBasedNotificationComponent
  },
  {
    path: 'add-rolebase-plan/:id',
    component: RoleBasedNotificationComponent
  },
  {
    path: 'eventDetails/:id',
    component: EventDetailsComponent
  },
  {
    path: 'my-time-off-request',
    component: LeaveManagementComponent
  },
  {
    path: 'team-time-off-request',
    component: TeamTimeOffRequestComponent
  },
  {
    path: 'graph-report',
    component: GraphReportComponent
  },
  {
    path: 'manage-dashboard',
    component: ManageDashboardComponent
  },
  {
    path: 'manage-dashboard/:id',
    component: ManageDashboardComponent
  },
  {
    path: 'mydashboard',
    component: MydashboardComponent
  },
  {
    path: 'customize-dashboard/:id',
    component: CustomizeDashboardComponent
  },
  {
    path: 'customize-dashboard',
    component: CustomizeDashboardComponent
  },{
    path: 'hour-meter-report',
    component: HourMeterReportComponent
  },{
    path: 'asset-message-activity-report',
    component: SiteMessageActivityReportComponent,
  },{
    path: 'asset-location-report',
    component: SiteLocationReportComponent,
  },{
    path: 'asset-data-point-summary-report',
    component: SiteDataPointSummaryReportComponent,
  },{
    path: 'runtime-event-report',
    component: RuntimeEventReportComponent,
  },{
    path: 'asset-message-history-report',
    component: SiteMessageHistoryReportComponent,
  },{
    path: 'asset-event-notification-report',
    component: SiteEventNotificationReportComponent,
  },
  {
    path: 'asset-activation-status/:id',
    component: AssetActivationStatusComponent
  },
  {
    path: 'in-app-notification/:id',
    component: InAppNotifcationComponent
  },
  {
    path: 'advance-report',
    component: ReportDataComponent
  },
  {
    path: 'event-edit/:id',
    component: TEWEditComponent
  },
  // {
  //   path: 'tech-entry-wizard-report',
  //   component: TechEntryWizardReportComponent,
  // },
  {
    path: '**',
    redirectTo: '/login'
  },
  ]
}
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewsRoutingModule {
}
