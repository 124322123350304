// import 'flatpickr/dist/flatpickr.css'; // you may need to adjust the css import depending on your build tool
import { NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, ActivatedRoute,RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipeModule } from 'ngx-filter-pipe';
/* Partial */
import { HeaderComponent } from './partials/header/header.component';
import { FooterComponent } from './partials/footer/footer.component';
/* Dashboard */
import { DashboardComponent } from './section/dashboard/dashboard.component';
/* View */
import { ViewComponent } from './view.component';
import { ViewsRoutingModule } from './view-routing.module';
/* tier */
import { TierComponent } from './section/tier/tier.component';
import { TiersComponent } from './section/tier/tiers/tiers.component';
/* Customers */
import { CustomersComponent } from './section/customers/customers.component';
/* Roles */
import { RolesComponent } from './section/customers/roles/roles.component';
/* Site */
import { SitesComponent } from './section/customers/sites/sites.component';
/* Package */
import { LicenseComponent } from './section/customers/license/license.component';
/* Notification-plan */
import { NotificationPlanComponent } from './section/customers/notification-plan/notification-plan.component';
/* Users */
import { UsersComponent } from './section/customers/users/users.component';
import { AddUserComponent } from './section/user-management/adduser/adduser.component';
/* Role Management */
import { RoleManagementComponent } from './section/role-management/role-management.component';
import { AddRoleComponent } from './section/role-management/addrole/addrole.component';
/* Site Management */
import { SiteManagementComponent } from './section/site-management/site-management.component';
import { AddSiteComponent } from './section/site-management/add-site/add-site.component';
/* User Management */
// import { UserManagementComponent } from './section/user-management/user-management.component';
import { UserListComponent } from './section/user-management/user-list/user-list.component';
/* Asset Management */
import { AssetManagementComponent } from './section/asset-management/asset-management.component';
/* Notification Plan Management */
// import { NotificationPlanManagementComponent } from './section/notification-plan-management/notification-plan-management.component';
// import { AdminNotificationComponent } from './section/notification-plan-management/admin-notification/admin-notification.component';
// import { AddNotitficationComponent } from './section/notification-plan-management/add-notitfication/add-notitfication.component';
// import { AddNotificationPlanComponent } from './section/notification-plan-management/add-notification-plan/add-notification-plan.component';
import { RoleBasedNotificationComponent } from './section/notification-plan-management/role-based-notification/role-based-notification.component';


/* Statistics */
// import { StatisticsComponent } from './section/statistics/statistics.component';
/* Report */
import { ReportComponent } from './section/report/report.component';
import { AddReportComponent } from './section/report/add-report/add-report.component';

/* Setting */
import { SettingComponent } from './section/setting/setting.component';
import { ClientSettingComponent } from './section/setting/client-setting/client-setting.component';
/* Assets */
import { AssetsComponent } from './section/customers/assets/assets.component';
import { CustomerComponent } from './section/customers/customer/customer.component';
import { AddCustomerComponent } from './section/customers/customer/add-customer/add-customer.component';
/* Shift  */
import { ShiftManagementComponent } from './section/shift-management/shift-management.component';
// import { ShiftComponent } from './section/shift-management/shift/shift.component';
/* Add customer  */
import { CustomerAddComponent } from './section/customers/customer-add/customer-add.component';
import { AddCustomerUserComponent } from './section/customers/users/adduser/adduser.component';
import { ViewCustomerUserComponent } from './section/customers/users/viewuser/viewuser.component';

/* menu customer  */
import { CustomerMenuComponent } from './section/customers/customer-menu/customer-menu.component';
/* Notification event  */
// import { NotificationEventComponent } from './section/notification-event/notification-event.component';
/* AssetSummary Section  */
import { AssetSummaryComponent } from './section/asset-summary/asset-summary.component';
import { DataPointComponent } from './section/asset-summary/data-point/data-point.component';
/* Asset Graph  */
import { AssetGraphComponent } from './section/asset-summary/asset-graph/asset-graph.component';
import { ModemTrafficComponent } from './section/asset-summary/modem-traffic/modem-traffic.component';
// import { CommentsComponent } from './section/asset-summary/comments/comments.component';
import { DocumentsComponent } from './section/asset-summary/documents/documents.component';
import { EventsComponent } from './section/asset-summary/events/events.component';
// import { PartletsComponent } from './section/asset-summary/partlets/partlets.component';
// import { ProductsComponent } from './section/asset-summary/products/products.component';
// import { ActivitiesComponent } from './section/asset-summary/activities/activities.component';
import { AssetsLogComponent } from './section/asset-summary/assets-log/assets-log.component';
/* Copy Schedule */
import { CopyScheduleComponent } from './section/asset-summary/copy-schedule/copy-schedule.component';
// import { AssetCalenderComponent } from './section/asset-summary/assets-calender/assets-calender.component';
// import { ShiftCalenderComponent } from './section/shift-management/shift-calender/shift-calender.component';
import { ShiftViewCalenderComponent } from './section/shift-management/shift-view-calender/shift-view-calender.component';
// import { ScheduleCalenderComponent } from './section/schedule-calender/schedule-calender.component';
// import { DayViewSchedulerComponent } from './section/schedule-calender/day-schedule';
// import { CalendarHeaderComponent } from './section/schedule-calender/calender-header.component';
/* MySchedule*/
// import { MyScheduleComponent } from './section/my-schedule/my-schedule.component';
/* TeamSchedule*/
import { TeamScheduleComponent } from './section/team-schedule/team-schedule.component';
/* FixedSchedule*/
import { FixedScheduleComponent } from './section/fixed-schedule/fixed-schedule.component';
// import { AssetsDataPocketComponent } from './section/asset-summary/assets-data-pocket/assets-data-pocket.component';
import { AssetsummaryMenuComponent } from './section/asset-summary/assetsummary-menu/assetsummary-menu.component';
import { AssetEditComponent } from './section/asset-summary/asset-edit/asset-edit.component';
import { AssetUserComponent } from './section/asset-summary/asset-user/asset-user.component';
// import { LiveFeedComponent } from './section/live-feed/live-feed.component';
import { ApprovedListComponent } from './section/approved-list/approved-list.component';
import { AssetAvailabilityComponent } from './section/asset-availability/asset-availability.component';
import { TemplateComponent } from './section/setting/template/template.component';
import { jqxTreeComponent } from './jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from './jqwidgets-ts/angular_jqxdropdownbutton';
import { jqxCheckBoxComponent } from './jqwidgets-ts/angular_jqxcheckbox';
import { Ng2DragDropModule } from 'ng2-drag-drop';
import { NguiMapModule} from '@ngui/map';


// import { FusionChartsModule } from 'angular-fusioncharts';

// import * as FusionCharts from './js/fusioncharts';
// import * as Charts from './js/fusioncharts.charts';
// import * as Widgets from './js/fusioncharts.widgets'
// import * as FintTheme from './js/themes/fusioncharts.theme.fint';
// Add dependencies to FusionChartsModule
// FusionCharts.options.SVGDefinitionURL='absolute'
// FusionChartsModule.fcRoot(FusionCharts, Charts, FintTheme, Widgets)


// import * as FusionCharts from'fusioncharts';

// import * as Charts from 'fusioncharts/fusioncharts.charts';
// import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme)
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { SortablejsModule } from 'angular-sortablejs';
import { TicketComponent } from './section/ticket/ticket.component';
import { GlobalSearchComponent } from './section/global-search/global-search.component';
import { AdhocReportComponent } from './section/report/adhoc-report/adhoc-report.component';
import { AssetSummaryReportComponent } from './section/report/asset-summary-report/asset-summary-report.component';
import { UserProfileComponent } from './section/user-management/user-profile/user-profile.component';
import { NgDygraphsModule } from 'ng-dygraphs';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ErrorHandlingComponent } from './section/error-handling/error-handling.component';
import { NewNotificatioPlanListComponent } from './section/notification-plan-management/new-notificatio-plan-list/new-notificatio-plan-list.component';

import { EventDetailsComponent } from './section/event-details/event-details.component';

import { LeaveManagementComponent } from './section/leave-management/leave-management.component';
import { TeamTimeOffRequestComponent } from './section/leave-management/team-time-off-request/team-time-off-request.component';
import { GraphReportComponent } from './section/graph-report/graph-report.component';
import { ManageDashboardComponent } from './section/setting/manage-dashboard/manage-dashboard.component';
// import { GridstackComponent } from './section/setting/manage-dashboard/gridstack/gridstack.component';
import { MydashboardComponent } from './section/setting/mydashboard/mydashboard.component';
import { CustomizeDashboardComponent } from './section/setting/customize-dashboard/customize-dashboard.component';
import { GridstackModule } from '@libria/gridstack';
import { TableComponent } from './section/setting/customize-dashboard/table/table.component';
import { HourMeterReportComponent } from './section/report/hour-meter-report/hour-meter-report.component';
import { SiteMessageActivityReportComponent } from './section/report/site-message-activity-report/site-message-activity-report.component';
import { ChartComponent } from './section/setting/customize-dashboard/chart/chart.component';
import { SiteLocationReportComponent } from './section/report/site-location-report/site-location-report.component';
import { SiteDataPointSummaryReportComponent } from './section/report/site-data-point-summary-report/site-data-point-summary-report.component';
import { RuntimeEventReportComponent } from './section/report/runtime-event-report/runtime-event-report.component';
import { SiteMessageHistoryReportComponent } from './section/report/site-message-history-report/site-message-history-report.component';
import { SiteEventNotificationReportComponent } from './section/report/site-event-notification-report/site-event-notification-report.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TechEntryWizardReportComponent } from './section/report/tech-entry-wizard-report/tech-entry-wizard-report.component';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module

import { AssetActivationStatusComponent } from './section/asset-summary/asset-activation-status/asset-activation-status.component';
import { InAppNotifcationComponent } from './section/asset-summary/in-app-notifcation/in-app-notifcation.component'; // <-- import the module
import { ReportDataComponent } from './section/report/report-data/report-data.component';
import { TEWEditComponent } from './section/tew-edit/tew-edit.component';
import { UnitsComponent } from './section/asset-summary/units/units.component';

const VIEWS_COMPONENTS = [
      ViewComponent,
      HeaderComponent,
      FooterComponent,
      DashboardComponent,
      TierComponent,
      TiersComponent,
      CustomersComponent,
      RolesComponent,
      SitesComponent,
      LicenseComponent,
      NotificationPlanComponent,
      UsersComponent,
      RoleManagementComponent,
      SiteManagementComponent,
      // UserManagementComponent,
      UserListComponent,
      AssetManagementComponent,
      // NotificationPlanManagementComponent,
      // AdminNotificationComponent,
      // NotificationEventComponent,
      // StatisticsComponent,
      ReportComponent,
      AddReportComponent,
      AdhocReportComponent,
      AssetSummaryReportComponent,
      SettingComponent,
      ClientSettingComponent,
      AssetsComponent,
      AddSiteComponent,
      // AddNotitficationComponent,
      AddUserComponent,
      AddRoleComponent,
      CustomerComponent,
      AddCustomerComponent,
      CustomerAddComponent,
      AssetSummaryComponent,
      // ShiftComponent,
      ShiftManagementComponent,
      DataPointComponent,
      AssetGraphComponent,
      ModemTrafficComponent,
      // CommentsComponent,
      DocumentsComponent,
      EventsComponent,
      // PartletsComponent,
      // ProductsComponent,
      // ActivitiesComponent,
      AssetsLogComponent,
      CopyScheduleComponent,
      // AssetsDataPocketComponent,
      AssetsummaryMenuComponent,
      AssetEditComponent,
      jqxTreeComponent,
      CustomerMenuComponent,
      AssetUserComponent,
      // LiveFeedComponent,
      jqxDropDownButtonComponent,
      ApprovedListComponent,
      jqxCheckBoxComponent,
      AssetAvailabilityComponent,
      // ShiftCalenderComponent,
      TemplateComponent,
      // DayViewSchedulerComponent,
      // CalendarHeaderComponent,
      // AssetCalenderComponent,
      // MyScheduleComponent,
      TeamScheduleComponent,
      FixedScheduleComponent,
      // ScheduleCalenderComponent,
      AddCustomerUserComponent,
      ViewCustomerUserComponent,
      ShiftViewCalenderComponent,
      TicketComponent,
    //  AddNotificationPlanComponent,
     RoleBasedNotificationComponent,
     GlobalSearchComponent,
     UserProfileComponent,
     ErrorHandlingComponent,
     NewNotificatioPlanListComponent,
     EventDetailsComponent,
     LeaveManagementComponent,
     TeamTimeOffRequestComponent,
     HourMeterReportComponent,
     AssetActivationStatusComponent,
     InAppNotifcationComponent,
      ReportDataComponent
];

@NgModule({
  imports: [
        ViewsRoutingModule,
        FormsModule,
      //   FusionChartsModule,
        EditorModule,
        CommonModule,
        NgDygraphsModule,
        FilterPipeModule,
        NgMultiSelectDropDownModule.forRoot(),
        ReactiveFormsModule,
        NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyAe4Hco7c34YBcSrtUSYpZomLxf_MOMArY'}),
     //    NguiMapModule.forRoot({apiUrl: 'https://maps.googleapis.com/maps/api/js?callback=initialize&client=gme-americanmillennium'}),
        Ng2DragDropModule.forRoot(),
        NgbModalModule,
        SortablejsModule.forRoot({ animation: 150 }),
        BrowserAnimationsModule,
        FlatpickrModule.forRoot(),
        GridstackModule.forRoot(),
        CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
     }),
     LoadingBarModule,
     NgxPaginationModule
  ],
  declarations: [
       ...VIEWS_COMPONENTS,
       GraphReportComponent,
       ManageDashboardComponent,
      //  GridstackComponent,
       MydashboardComponent,
       CustomizeDashboardComponent,
       TableComponent,
       SiteMessageActivityReportComponent,
       ChartComponent,
       SiteLocationReportComponent,
       SiteDataPointSummaryReportComponent,
       RuntimeEventReportComponent,
       SiteMessageHistoryReportComponent,
       SiteEventNotificationReportComponent,
       TechEntryWizardReportComponent,
       TEWEditComponent,
       UnitsComponent,
      
       ]
  })


export class ViewModule {
}
