import { Component, OnInit, Input, SimpleChanges, ViewChild } from '@angular/core';
import 'datatables.net';
import 'datatables.net-bs4';
import { AlertService, ApiService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { object } from '@amcharts/amcharts4/core';
declare var $: any, moment: any;
import * as _ from "lodash"
import flatpickr from "flatpickr";
import { LoadingBarService } from '@ngx-loading-bar/core';
import { delay, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  clientId: any = null;
  userId: any = null;

  tierName: any = ""
  siteName: any = ""
  assetName: any = ""
  timeRange: any = ""
  dataPoint: any = ""
  callOutSummary: any = ""
  permissionData: any = [];
  assetList: any = []
  assetDatalistPopup: any = [];
  selectedAssetList = [];

  isLoading: boolean = false;

  dropdownSettingsAssetList = {
    singleSelection: false,
    idField: 'assetId',
    textField: 'assetName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };

  dropdownSettingsAssetUnitList = {
    singleSelection: false,
    idField: 'Rcpid',
    textField: 'assetNameUnit',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
};


  dropdownSettingsAssetListSingleSelect = {
    singleSelection: false,
    idField: 'assetId',
    textField: 'assetName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };
  selectedMapStatus = null
  selectedTier: any = [];
  dropdownSettingsTierList = {
    singleSelection: false,
    idField: 'tierId',
    textField: 'tierName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };

  @ViewChild('dataForm') dataForm: any

  /* @Input('dataList') */ dataList: any;
  @Input('type') type: any;
  @Input('isDataTable') isDataTable: any;
  @Input('tableId') tableId: any;
  @Input('TierList') TierList: any;
  @Input('widgetObj') widgetObj: any
  @Input('isPreview') isPreview: any;
  @Input('index') index: any
  @Input('dashboardId') dashboardId: any
  @Input('DashboardName') DashboardName: any

  dataTable: any;

  AssetStatusWidgetDataObj: any = {}
  liveFeedlist: any = []
  runTimeData: any = []
  RecentOpenRuntimeEvents: any = [];
  FindSiteCallOutInstruction: any = [];

  KeyArr: any = []
  HeaderArr: any = []
  ShowFormValue: any = false;

  curMonth: any = '';
  yearArray: any = [];
  curYear: any = '';
  activeDeactiveArr: any = [];
  totalActivated: number;
  totalDeactivated: number;
  totalActiveDeactiveCount: number;
  monthId: string;
  yearId: string;
  DateObj: any;
  scheduleType: any = ""

  // *************************************************** key header value object 
  Last10AssetsVisitedKey = [

    "assetName",
    "clientDescription",
    "siteName",
    "tierName",
  ]
  Last10AssetsVisitedHeader =
    {
      assetName: "Asset Name",
      clientDescription: "Client Name",
      siteName: "Site Name",
      tierName: "Tier Name"
    }
  // *************************************************** key header value object for alarming site
  Last10AlarmingSiteKey = [
    // "assetId",
    "assetDescription",
    "siteOrVehicleName",
    "lastMessageEventTime",
    "tz",
    // "companyId",
  ]
  Last10AlarmingSiteHeader =
    {
      "assetId": null,
      "assetDescription": "Asset Name",
      "siteOrVehicleName": "Site",
      "lastMessageEventTime": "Last Event Time",
      "tz": "CDT",
      "companyId": null
    }
  // *************************************************** key header value object for aging event
  RuntimeEventAgingKey = [
    "ageDescription",
    // ageOrder: "1"
    "siteCountAlarm",
    "siteCountFollowUp",
    "siteCountNormal",
  ]
  RuntimeEventAgingHeader =
    {
      ageDescription: "Event Age",
      ageOrder: "Age Order",
      siteCountAlarm: "Alarm Site Count",
      siteCountFollowUp: "Follow Up Site Count",
      siteCountNormal: "Normal Site Count",
    }

  /*  ************************************************* map Variables **************** */

  mapPointerData: any = [];
  positions: any = [];
  errorText: any = "";
  styleExp: any = {
    'display': 'none'
  };
  private bounds: google.maps.LatLngBounds;
  private map: google.maps.Map;
  zoomLevel: any;
  latitude: number;
  longitude: number;
  eventAgeInterval: any;
  agingUpdatedTime: any = null
  performAction : number = 0;
  showAckBtn : number = 0;
  showAckErcBtn : number = 0;
  showERC : number = 0;

  faultCodeData: any = [];
  faultCodeDataPopup: any = [];
  days: any = '';
  daysList: any = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
  HistoryList: any[];
  isShowAsset: boolean;
  isShowDatapoint: boolean;
  isShowRange: boolean;
  isShowSite: boolean;
  isShowTier: boolean;
 

  constructor(private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private loading: LoadingBarService,
    private alertService: AlertService) {
    this.permissionData = ApiService.permission;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.


  }

  ngOnInit() {



    this.clientId = localStorage.getItem("clientId");
    this.userId = localStorage.getItem("userId");

    if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      $(`#${this.tableId}`).DataTable().destroy();
    };
    // this.chRef.detectChanges();


    console.log("table data=============>", this.dataList, this.type, this.isDataTable, this.tableId);
    if (this.type == 'Last 10 Assets Visited') {
      this.getLastAssetsVisited(1)
      // this.KeyArr = this.Last10AssetsVisitedKey;
      // this.HeaderArr = this.Last10AssetsVisitedHeader;
      // if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      //   $(`#${this.tableId}`).DataTable().destroy();
      // };
      // if (this.isDataTable) {
      //   setTimeout(() => {
      //     if (!$.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      //       // $(`#${this.tableId}`).DataTable().destroy();
      //       const table: any = $(`#${this.tableId}`);
      //       this.dataTable = table.DataTable({});
      //     };
      //   }, 500);
      // }
    }
    else if (this.type == 'Last 10 Alarming Asset') {
      this.getLastAlarmingSite(2)
      this.KeyArr = this.Last10AlarmingSiteKey;
      this.HeaderArr = this.Last10AlarmingSiteHeader;
      if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
        $(`#${this.tableId}`).DataTable().destroy();
      };
      if (this.isDataTable) {
        // setTimeout(() => {
        //   if (!$.fn.DataTable.isDataTable(`#${this.tableId}`)) {
        //     // $(`#${this.tableId}`).DataTable().destroy();
        //     const table: any = $(`#${this.tableId}`);
        //     this.dataTable = table.DataTable({});
        //   };
        // }, 500);
      }
    }
    else if (this.type == 'Runtime event aging') {
      this.getRuntimeEventAging(3);
      this.eventAgeInterval = setInterval(() => {
        this.getRuntimeEventAging(3);
      }, 180000)

    }
    else if (this.type == 'Asset Status Widget') {
      let dataObj: any = {}
      if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
        this.tierName = this.widgetObj.tiers//[0]
        // this.FirstFaultPieChartTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.tier = this.tierName
        this.selectedTier = this.widgetObj.tiers
      }
      // if (this.widgetObj.asset.length) {
      //     this.assetName = this.widgetObj.asset[0]
      //     dataObj.asset = this.assetName
      //     this.getDataPointListOnAssetChange({target:{value:this.assetName}})
      // }
      // if (this.widgetObj.datapoint.length) {
      //     this.dataPoint = this.widgetObj.datapoint[0];
      //     dataObj.assetChannelId = this.dataPoint
      // }

      this.getAssetStatusWidget(dataObj);
      // this.KeyArr = this.RuntimeEventAgingKey;
      // this.HeaderArr = this.RuntimeEventAgingHeader;
      // if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      //   $(`#${this.tableId}`).DataTable().destroy();
      // };
      // if (this.isDataTable) {
      //   setTimeout(() => {
      //     if (!$.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      //       // $(`#${this.tableId}`).DataTable().destroy();
      //       const table: any = $(`#${this.tableId}`);
      //       this.dataTable = table.DataTable({});
      //     };
      //   }, 500);
      // }
    }
    else if (this.type == 'Live Feed Events') {
      let dataObj: any = {}

      if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
        this.tierName = this.widgetObj.tiers//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.tier = this.tierName;
        this.selectedTier = this.widgetObj.tiers
        this.ShowFormValue = false;
        this.OnTierChangeGetAsset({ target: { value: this.widgetObj.tiers } });
      }
      if (this.widgetObj.assets && this.widgetObj.assets.length) {
        this.assetName = this.widgetObj.assets//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.asset = this.assetName;
        this.selectedAssetList = this.widgetObj.assets
        this.ShowFormValue = false;
      }

      this.getLiveFeedList(dataObj);
    }
    else if (this.type == 'Items Waiting for Approval') {
      let dataObj: any = {}
      if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
        this.tierName = this.widgetObj.tiers//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.tier = this.tierName;
        this.selectedTier = this.widgetObj.tiers
        this.ShowFormValue = false;
        this.OnTierChangeGetAsset({ target: { value: this.widgetObj.tiers } });
      }
      if (this.widgetObj.assets && this.widgetObj.assets.length) {
        this.assetName = this.widgetObj.assets//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.asset = this.assetName;
        this.selectedAssetList = this.widgetObj.assets
        this.ShowFormValue = false;
      }
      this.getApprovalEventsData(dataObj);
    }
    else if (this.type == 'Repeat Offender Widget') {
      let dataObj: any = {}
      if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
        this.tierName = this.widgetObj.tiers//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.tier = this.tierName;
        this.selectedTier = this.widgetObj.tiers
        this.ShowFormValue = false;
        this.TierChangeGetAssetUnitDropdownList({ target: { value: this.widgetObj.tiers } });
      }
      // if (this.widgetObj.assets && this.widgetObj.assets.length) {
      //   this.assetName = this.widgetObj.assets//[0]
      //   // this.onTierChangeGetAsset({target:{value:this.tierName}})
      //   dataObj.asset = this.assetName;
      //   this.selectedAssetList = this.widgetObj.assets
      //   this.ShowFormValue = false;        
      // }
      if (this.widgetObj.rcps && this.widgetObj.rcps.length) {
               
        let rcpList = []; 
        for (const iterator of this.widgetObj.rcps) {
            rcpList.push({Rcpid:iterator.runTimeCollectionPointId})
        }
        dataObj.asset = rcpList;
        this.selectedAssetList = rcpList;
    }
console.log('this.widgetObj.dashboardWidget.timeRange-------',this.widgetObj)
      if (this.widgetObj.dashboardWidget) {
        if(this.widgetObj.dashboardWidget.timeRange) {
          dataObj.days = this.widgetObj.dashboardWidget.timeRange;
          this.days = parseInt(dataObj.days);
          this.ShowFormValue = false;
        }else {
          this.days = '';
        }
      }/*  else {
        this.ShowFormValue = true;
      }  */
      this.getFaultCodeCountData(dataObj);
    }
    else if (this.type == 'Most Recent Open Runtime Events') {
      let dataObj: any = {}
      if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
        this.tierName = this.widgetObj.tiers//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.tier = this.tierName;
        this.selectedTier = this.widgetObj.tiers
        this.ShowFormValue = false;
        // this.OnTierChangeGetAsset({target:{value:this.widgetObj.tiers}});
      }
      this.getRecentOpenRuntimeEvents(dataObj);

    } else if (this.type == 'Monthly Activations & Deactivations') {
      this.monthId = 'months' + this.tableId
      this.yearId = 'year' + this.tableId
      let selectedMonth = $('#' + this.monthId).children("option:selected").val();
      let selectedYear = $('#' + this.yearId).children("option:selected").val();

      if (selectedMonth == undefined && selectedYear == undefined) {
        let d = new Date();
        var y = d.getFullYear();
        this.curYear = y;
        y -= 29;
        for (var i = 0; i < 30; i++) {
          this.yearArray.push(y + i);
        }
        var date = new Date();
        let curMonth = date.getMonth() + 1;
        this.curMonth = curMonth;
        selectedMonth = curMonth;
        selectedYear = y;
        this.getActiveDeactivebyProvider(selectedMonth, selectedYear)
      } else {
        this.getActiveDeactivebyProvider(selectedMonth, selectedYear)

      }
    }
    else if (this.type == 'Find Site Call-Out Instructions by Start Time') {
      let dataObj: any = {}
      this.DateObj = new Date()

      if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
        $(`#${this.tableId}`).DataTable().destroy();
      };
      if (this.widgetObj.assets && this.widgetObj.assets.length) {
        this.assetName = this.widgetObj.assets//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.asset = this.assetName;
        this.selectedAssetList = this.widgetObj.assets
        this.ShowFormValue = false;
        this.getFindSiteCallOutInstruction(dataObj)
      }
      // this.chRef.detectChanges();
      setTimeout(() => {
        const table: any = $(`#${this.tableId}`);
        this.dataTable = table.DataTable();
      }, 1000);
      this.getAssetList()
    }

    else if (this.type == 'Asset Status Map') {
      let dataObj: any = {}
      if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
        this.tierName = this.widgetObj.tiers//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.tier = this.tierName;
        this.selectedTier = this.widgetObj.tiers
        // this.ShowFormValue = false;
      } else {
        // this.ShowFormValue = true;
      }
      this.getMapPointerData(dataObj);
    }
    else if (this.type == 'Map of Site Location') {
      let dataObj: any = {}
      if (this.widgetObj.tiers && this.widgetObj.tiers.length) {
        this.tierName = this.widgetObj.tiers//[0]
        // this.onTierChangeGetAsset({target:{value:this.tierName}})
        dataObj.tier = this.tierName;
        this.selectedTier = this.widgetObj.tiers
        this.ShowFormValue = false;
      } else {
        this.ShowFormValue = true;
      }
      this.getMapByAssetSitePointerData(dataObj);
    }



    // if (this.widgetObj.asset && this.widgetObj.asset.length) {
    //   this.assetName = this.widgetObj.asset[0]
    //   dataObj.asset = this.assetName

    // }


    console.log(this.dataList);
    this.clientId = localStorage.getItem("clientId");
    this.userId = localStorage.getItem("userId");
    //faltpicke config for start Date
    let self = this
    flatpickr('#startDate', {
      enableTime: true,
      enableSeconds: true,
      dateFormat: "Y-m-d H:i:S",
      defaultDate: new Date(),
      time_24hr: true,
      onChange: function (selectedDates, dateStr, instance) {
        self.DateObj = selectedDates.length ? selectedDates[0] : "";
        console.log("selectedDates", selectedDates, "dateStr", dateStr, "instance", instance, "self.DateObj", self.DateObj)
      },
    })
    this.DateObj = new Date()
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    let self = this
    flatpickr('#startDate', {
      enableTime: true,
      enableSeconds: true,
      dateFormat: "Y-m-d H:i:S",
      defaultDate: new Date(),
      time_24hr: true,
      onChange: function (selectedDates, dateStr, instance) {
        self.DateObj = selectedDates.length ? selectedDates[0] : "";
        console.log("selectedDates", selectedDates, "dateStr", dateStr, "instance", instance, "self.DateObj", self.DateObj)
      },
    })
    this.DateObj = new Date()
  }

  startLoading() {
    this.loading.start();
  }

  stopLoading() {
    this.loading.complete();
  }

  monthChange() {
    this.monthId = 'months' + this.tableId
    this.yearId = 'year' + this.tableId
    let selectedMonth = $('#' + this.monthId).children("option:selected").val();
    let selectedYear = $('#' + this.yearId).children("option:selected").val();
    this.getActiveDeactivebyProvider(selectedMonth, selectedYear);
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.eventAgeInterval) {
      clearInterval(this.eventAgeInterval)
    }
  }

  showForm() {
    this.ShowFormValue = !this.ShowFormValue
  }

  /* ----------------------------------------------------------------------- get asset by tier */
  onTierChangeGetAsset(event) {
    console.log(event.target.value);
    if (event.target.value) {

      // var URL = 'asset/getAssetDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId+"&isAssetLive=true" + '&tierId=' + event.target.value;
      var URL = 'asset/getAssetDropDownByClient';

    let ObjData:any = {
      clientId: this.clientId,
      userId: this.userId,
      tierId: event.target.value
    }
   
      this.startLoading();
      this.apiService.create(URL, ObjData).subscribe(
        data => {
          this.isLoading = false;
          this.stopLoading();
          if (data.statusCode == 100) {
            this.assetList = data.responseList;
          } else {
            this.assetList = [];
          }
        },
        error => {
          this.stopLoading();          
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

  }


  tierChange() {
    // this.siteName = null
    // this.assetName = null
    // this.dataPoint = null
    // this.selectedSite = [];
    this.selectedAssetList = [];
    // this.selectedDataPoint = [];
  }


  getActiveDeactivebyProvider(selectedMonth, selectedYear) {
    this.isLoading = true;
    this.activeDeactiveArr = [];
    this.totalActivated = 0;
    this.totalDeactivated = 0;
    this.totalActiveDeactiveCount = 0;

    var date = new Date();
    let curMonth = date.getMonth() + 1;
    this.curMonth = curMonth;
    var URL = 'asset/getActiveDeactivebyProvider?month=' + selectedMonth + '&year=' + selectedYear;
    this.apiService.getAll(URL).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          // if($.fn.DataTable.isDataTable('#data_table_subscriptions')){
          //   $('#data_table_subscriptions').DataTable().destroy();       
          // }  
          //  this.assetVisitArr = [];
          this.activeDeactiveArr = data.responseList;
          this.totalActivated = data.activeCount;
          this.totalDeactivated = data.deactiveCount;
          this.totalActiveDeactiveCount = data.totalActiveDeactiveCount;
          setTimeout(() => {
            const table: any = $('#data_table_subscriptions');
            this.dataTable = table.DataTable();
          }, 300);
        } else {
          this.activeDeactiveArr = [];
          const table: any = $('#data_table_subscriptions');
          this.dataTable = table.DataTable();
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");

      }
    );
  }


  getLastAssetsVisited(index) {


    this.KeyArr = this.Last10AssetsVisitedKey;
    this.HeaderArr = this.Last10AssetsVisitedHeader;
    if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      $(`#${this.tableId}`).DataTable().destroy();
    };
    this.isLoading = true;
    this.dataList = [];
    this.apiService.getAll("asset/getLast10AssetVisitByUser?userId=" + this.userId + "&clientId=" + this.clientId).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.dataList = data.responseList
          // this.fusionChartArrayDataObj[index].dataList = data.responseList
        } else {
          this.dataList = []
          // this.fusionChartArrayDataObj[index].dataList = [];
        }
        if (this.isDataTable) {
          // setTimeout(() => {
          //   if (!$.fn.DataTable.isDataTable(`#${this.tableId}`)) {
          //     // $(`#${this.tableId}`).DataTable().destroy();
          //     const table: any = $(`#${this.tableId}`);
          //     this.dataTable = table.DataTable({});
          //   };
          // }, 500);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getLastAlarmingSite(index) {
    this.isLoading = true;
    this.dataList = [];
    this.apiService.getAll("asset/getLast10AlarmingAssets?userId=" + this.userId + "&clientId=" + this.clientId).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.dataList = data.responseList
          // this.fusionChartArrayDataObj[index].dataList = data.responseList
        } else {
          this.dataList = []
          // this.fusionChartArrayDataObj[index].dataList = [];
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.sweetMessage('error', "Can not get alarming asset list, please try again ");
      }
    );
  }

  getRuntimeEventAging(index) {
    this.isLoading = true;
    this.dataList = [];
    this.agingUpdatedTime = moment().format("MM-DD-YYYY HH:mm:ss");

    this.KeyArr = this.RuntimeEventAgingKey;
    this.HeaderArr = this.RuntimeEventAgingHeader;
    if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      $(`#${this.tableId}`).DataTable().destroy();
    };
    // if (this.isDataTable) {

    // }

    this.apiService.getAll("runTimeEvent/getRuntimeEventAgingByClientAndUser?userId=" + this.userId + "&clientId=" + this.clientId).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.dataList = data.responseList
          // this.fusionChartArrayDataObj[index].dataList = data.responseList
        } else {
          this.dataList = []
          // this.fusionChartArrayDataObj[index].dataList = [];
        }
        // setTimeout(() => {
        //   if (!$.fn.DataTable.isDataTable(`#${this.tableId}`)) {
        //     // $(`#${this.tableId}`).DataTable().destroy();
        //     const table: any = $(`#${this.tableId}`);
        //     this.dataTable = table.DataTable({});
        //   };
        // }, 500);
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getAssetStatusWidget(obj) {
    var URL = "/asset/getAssetCurrentStatus?clientId=" + this.clientId + "&userId=" + this.userId;
    // tierIds=15 ,14"+"&
    if (obj.tier) {
      let tierIds = []
      obj.tier.map((obj, index) => {
        tierIds.push(obj.tierId)
      })
      if (tierIds.length) {
        URL = URL + "&tierIds=" + tierIds.toString();
      }
    }
    this.isLoading = true;
    this.AssetStatusWidgetDataObj = [];
    this.apiService.getAll(URL).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {

          let DataObj: any = {}
          let dataList = data.responseList
          for (let index = 0; index < dataList.length; index++) {
            const element = dataList[index];

            if (element.alarmState == 'Alarm') {
              DataObj.alarmCount = element.value;
            } else if (element.alarmState == 'No comm') {
              DataObj.noCommCount = element.value;
            } else if (element.alarmState == 'Suspended') {
              DataObj.offlineCount = element.value;
            } else if (element.alarmState == 'Warning') {
              DataObj.warningCount = element.value;
            } else if (element.alarmState == 'Normal') {
              DataObj.normalCount = element.value;
            } else if (element.alarmState == 'totalActiveCount') {
              DataObj.totalActiveCount = element.value;
            } else if (element.alarmState == 'New') {
              DataObj.newCount = element.value;
            } else if (element.alarmState == 'Delivered') {
              DataObj.deliveredCount = element.value;
            } else if (element.alarmState == 'Repair') {
              DataObj.repairCount = element.value;
            } else if (element.alarmState == 'Scrapped') {
              DataObj.scrappedCount = element.value;
            } else if (element.alarmState == 'totalSitesCount') {
              DataObj.totalSitesCount = element.value;
            } else if (element.alarmState == 'Deactive') {
              DataObj.deactivatedCount = element.value;
            }

          }

          this.AssetStatusWidgetDataObj = DataObj
          console.log(this.AssetStatusWidgetDataObj,'sssssssssssssssssssssssssssssssssssss');

          // this.dataList

          // this.fusionChartArrayDataObj[index].dataList = data.responseList
        } else {
          this.AssetStatusWidgetDataObj = {}
          // this.fusionChartArrayDataObj[index].dataList = [];
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Get Live Feed Data */
  getLiveFeedList(obj) {
    // var URL = 'asset/getLatestLiveFeed?clientId=' + this.clientId + '&userId=' + this.userId + '&startlimit=0&endlimit=20';
    var URL = 'wffnotification/getEventList';
    let apiData: any = {
      clientId: this.clientId,
      userId: this.userId,
      startlimit: 0,
      endlimit: 2000
    }

    if (obj.tier) {
      let tierIds = []
      obj.tier.map((obj, index) => {
        tierIds.push(obj.tierId)
      })
      apiData.tierIds = tierIds.toString();
      // URL = URL + "&tiers=" + tierIds.toString();
    }
    if (obj.asset) {
      let assetIds = []
      obj.asset.map((obj, index) => {
        // console.log(obj, "}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}");

        assetIds.push(obj.assetId)
      })
      apiData.assetIds = assetIds.toString();
      // URL = URL + "&assets=" + assetIds.toString();
    }

    this.isLoading = true;
    this.liveFeedlist = [];
    // // if (this.tierId != null && this.tierId != '') {
    // if (this.tierId != null && this.tierId != '') {
    //     var tierString = '&tiers=' + this.tierId.toString();
    //     var URL = URL.concat(tierString);
    // }
    // if (this.site != '') {
    //     // var siteString = '&siteId=' + this.site;
    //     var siteString = '&sites=' + this.site;
    //     var URL = URL.concat(siteString);
    // }
    // if (this.asset != '') {
    //     // var assetString = '&assetId=' + this.asset;
    //     var assetString = '&assets=' + this.asset;
    //     var URL = URL.concat(assetString);
    // }
    // if (this.customerId != null && this.customerId != '') {
    //     var customerString = '&customers=' + this.customerId.toString();
    //     var URL = URL.concat(customerString);
    // }

    // this.isWffLoading = true;


    /*if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      $(`#${this.tableId}`).DataTable().destroy();
      $(`#${this.tableId}`).empty(); 
    };*/
    // if (this.isDataTable) {
    //   setTimeout(() => {
    //     if (!$.fn.DataTable.isDataTable(`#${this.tableId}`)) {
    //       // $(`#${this.tableId}`).DataTable().destroy();
    //       const table: any = $(`#${this.tableId}`);
    //       this.dataTable = table.DataTable({});
    //     };
    //   }, 500);
    // }

    this.apiService.create(URL, apiData).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        // this.isWffLoading = false;
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.liveFeedlist = data.responseList;
          if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
            $(`#${this.tableId}`).DataTable().destroy();
            $(`#${this.tableId}`).empty();
          };
          
          // this.chRef.detectChanges();
          /*setTimeout(() => {
            if (!$.fn.DataTable.isDataTable(`#${this.tableId}`)) {
              const table: any = $(`#${this.tableId}`);
              this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": [0,8] }], "search": { regex: false, smart: false } });
            }
          }, 1000);*/
        } else {
          this.liveFeedlist = [];
          if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
            $(`#${this.tableId}`).DataTable().destroy();
            $(`#${this.tableId}`).empty(); 
          };
          // this.chRef.detectChanges();
          /*setTimeout(() => {
            if (!$.fn.DataTable.isDataTable(`#${this.tableId}`)) {
              const table: any = $(`#${this.tableId}`);
              this.dataTable = table.DataTable({});
            }
          }, 1000);*/

        }
      },
      error => {
        // this.isWffLoading = false;
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


getFirstFaultCodeDetail(data) {
  if (!this.isPreview) {
    if (!data.firstFaultCodeCount) {
      return;
    }
    // Destroy Data Table if is already and clear the data
    if ($.fn.DataTable.isDataTable('#asset_data_table' + this.index)) {
      $('#asset_data_table' + this.index).DataTable().destroy();
      this.faultCodeDataPopup = [];
    }

    
    var URL = 'runTimeEvent/getfirstFaultCodeWidgetCountDetail' ;
    let apiData: any = {
      clientId : this.clientId,
      type: 'Detail'
    }
    /* {"clientId": "104",
   "tierIds": "137,139,141,150,151,155",
   "assetIds": "22524",
   "days": "100",
   "type": "Detail"
    } */

    this.faultCodeDataPopup = [];

    
    if (this.widgetObj.tiers.length) {
      let tierIds = []
      this.widgetObj.tiers.map((obj, index) => {
        tierIds.push(obj.tierId)
      })
      apiData.tierIds = tierIds.toString();
      // var tierString = '&tierIds=' + tierIds.toString();
      // var URL = URL.concat(tierString);
    }
    // console.log(this.widgetObj , "_________________________");
    

    // if (this.widgetObj.assets.length) {
    //   let assetIds = []
    //   this.widgetObj.assets.map((obj, index) => {
    //     assetIds.push(obj.assetId)
    //   })
    //   apiData.assetIds = assetIds.toString();
    //   // var tierString = '&tierIds=' + tierIds.toString();
    //   // var URL = URL.concat(tierString);
    // }

    if (this.widgetObj.rcps && this.widgetObj.rcps.length) {
      let assetIds = []
      this.widgetObj.rcps.map((obj, index) => {
        console.log(obj);
        
          assetIds.push(obj.runTimeCollectionPointId)
      })
      apiData.rcpIds = assetIds.toString();
    }

    if (this.widgetObj.dashboardWidget.timeRange) {
      apiData.days = this.widgetObj.dashboardWidget.timeRange 
    }
console.log('data.firstFaultCodeDescription',data)
    if(data.firstFaultCodeDescription && data.firstFaultCodeDescription != undefined) {
      apiData.firstFaultDec = data.firstFaultCodeDescription;
    }

    $('#assetListModel' + this.index).modal("show");
    this.apiService.create(URL, apiData).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          let Arr = []
          this.faultCodeDataPopup = [];
          let objArr = data.responseList;

          objArr.map((val, key) => {
            let objValue = val
            if (!val.notificationPlan) {
              objValue.notificationPlan = {};
            }
            if (!val.shiftGroup) {
              objValue.shiftGroup = {};
            }
            if (!val.customer) {
              objValue.customer = {};
            }
            if (!val.site) {
              objValue.site = {};
            }
            Arr.push(objValue);
          });

          this.faultCodeDataPopup = JSON.parse(JSON.stringify(Arr));

          //  this.chRef.detectChanges();
          let self = this
          setTimeout(() => {
            const table: any = $('#asset_data_table' + self.index);
            table.DataTable();
          }, 1000);

        }
        else {
          this.faultCodeDataPopup = [];
          if ($.fn.DataTable.isDataTable('#asset_data_table' + this.index)) {
            $('#asset_data_table' + this.index).DataTable().destroy();
            this.faultCodeDataPopup = [];
          }
          const table: any = $('#asset_data_table' + this.index);
          this.dataTable = table.DataTable();

        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }
}
  /* GetRepeat Offender WidgetData */
  getFaultCodeCountData(obj) {
    // this.daysList = JSON.stringify(this.daysList);
    // http://172.20.1.203:8764/api/v1/runTimeEvent/getfirstfaultcode
    var URL = 'runTimeEvent/getfirstFaultCodeWidgetCountSummary'

    let apiData: any = {
      clientId: this.clientId,
      type: "Summary",
      // days: '20'
      // userId: this.userId,
      // isApproved: true,
  }
    if (obj.tier && obj.tier.length) {
      let tierIds = []
      obj.tier.map((obj, index) => {
        tierIds.push(obj.tierId)
      })
      apiData.tierIds = tierIds.toString();
      // URL = URL + "&tierIds=" + tierIds.toString();
    }
    // if (obj.asset && obj.asset.length) {
    //   let assetIds = []
    //   obj.asset.map((obj, index) => {  

    //     assetIds.push(obj.assetId)
    //   })
    //   apiData.assetIds = assetIds.toString();
    // }
    if (obj.asset && obj.asset.length) {
      let assetIds = []
      obj.asset.map((obj, index) => {  

        assetIds.push(obj.Rcpid)
      })
      apiData.rcpIds = assetIds.toString();
    }

    if(obj.days) {
      let day = obj.days;
      apiData.days = day.toString();
    }
    if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      $(`#${this.tableId}`).DataTable().destroy();
    };
    this.isLoading = true;
    this.faultCodeData = [];
    this.apiService.create(URL, apiData).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.faultCodeData = data.responseList;
          if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
            $(`#${this.tableId}`).DataTable().destroy();
          };
          // this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $(`#${this.tableId}`);
            this.dataTable = table.DataTable({
              columnDefs: [], "search": { regex: false, smart: false }
            });
          }, 1000);
        } else {
          this.faultCodeData = [];
          if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
            $(`#${this.tableId}`).DataTable().destroy();
          };
          // this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $(`#${this.tableId}`);
            this.dataTable = table.DataTable({
              columnDefs: [], "search": { regex: false, smart: false }
            });
          }, 1000);
          // if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
          //   $(`#${this.tableId}`).DataTable().destroy();
          // };
          
          // setTimeout(() => {
          //   const table: any = $(`#${this.tableId}`);
          //   this.dataTable = table.DataTable({
          //     columnDefs: [{ "type": "date", targets: 4 }, { "orderable": false, "targets": [5] }],
          //     "aaSorting": [[4, "desc"]], "search": { regex: false, smart: false }
          //   });
          // }, 1000);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Get Run Time Event list */
  getApprovalEventsData(obj) {
    var URL = 'runTimeEvent/getWaitingApprovalEvents'
     //+ '&startlimit=0&endlimit=10';
    //var URL = 'runTimeEvent/runTimeEventList?clientId=' + this.clientId + '&userId=' + this.userId + '&startlimit=0&endlimit=10';
    // if (this.tierId != null && this.tierId != '') {
    //     var tierString = '&tierId=' + this.tierId.toString();
    //     var URL = URL.concat(tierString);
    // }
    // if (this.site != '') {
    //     var siteString = '&siteId=' + this.site;
    //     var URL = URL.concat(siteString);
    // }
    // if (this.asset != '') {
    //     var assetString = '&assetId=' + this.asset;
    //     var URL = URL.concat(assetString);
    // }
    // if (this.customerId != null && this.customerId != '') {
    //     var customerString = '&customerId=' + this.customerId.toString();
    //     var URL = URL.concat(customerString);
    // }
    let apiData: any = {
      clientId: this.clientId,
      userId: this.userId,
      // isApproved: true,
  }
    if (obj.tier) {
      let tierIds = []
      obj.tier.map((obj, index) => {
        tierIds.push(obj.tierId)
      })
      apiData.tierIds = tierIds.toString();
      // URL = URL + "&tierIds=" + tierIds.toString();
    }
    if (obj.asset) {
      let assetIds = []
      obj.asset.map((obj, index) => {
        // console.log(obj, "}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}");

        assetIds.push(obj.assetId)
      })
      apiData.assetIds = assetIds.toString();
      // URL = URL + "&assetIds=" + assetIds.toString();
    }
    if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      $(`#${this.tableId}`).DataTable().destroy();
    };
    this.isLoading = true;
    this.runTimeData = [];
    this.apiService.create(URL, apiData).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.runTimeData = data.responseList;
          if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
            $(`#${this.tableId}`).DataTable().destroy();
          };
          // this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $(`#${this.tableId}`);
            this.dataTable = table.DataTable({
              columnDefs: [{ "type": "date", targets: 4 }, { "orderable": false, "targets": [5] }],
              "aaSorting": [[4, "desc"]], "search": { regex: false, smart: false }
            });
          }, 1000);
        } else {
          this.runTimeData = [];
          if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
            $(`#${this.tableId}`).DataTable().destroy();
          };
          // this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $(`#${this.tableId}`);
            this.dataTable = table.DataTable({
              columnDefs: [{ "type": "date", targets: 4 }, { "orderable": false, "targets": [5] }],
              "aaSorting": [[4, "desc"]], "search": { regex: false, smart: false }
            });
          }, 1000);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Get Run Time Event list */
  getRecentOpenRuntimeEvents(obj) {
    var URL = 'runTimeEvent/getRecentOpenRuntimeEvents';
    //var URL = 'runTimeEvent/runTimeEventList?clientId=' + this.clientId + '&userId=' + this.userId + '&startlimit=0&endlimit=10';
    // if (this.tierId != null && this.tierId != '') {
    //     var tierString = '&tierId=' + this.tierId.toString();
    //     var URL = URL.concat(tierString);
    // }
    // if (this.site != '') {
    //     var siteString = '&siteId=' + this.site;
    //     var URL = URL.concat(siteString);
    // }
    // if (this.asset != '') {
    //     var assetString = '&assetId=' + this.asset;
    //     var URL = URL.concat(assetString);
    // }
    // if (this.customerId != null && this.customerId != '') {
    //     var customerString = '&customerId=' + this.customerId.toString();
    //     var URL = URL.concat(customerString);
    // }
    if (obj.tier) {
      let tierIds = []
      obj.tier.map((obj, index) => {
        tierIds.push(obj.tierId)
      })
      URL = URL + "?tierIds=" + tierIds.toString();
    }
    if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
      $(`#${this.tableId}`).DataTable().destroy();
    };
    this.isLoading = true;
    this.RecentOpenRuntimeEvents = [];

    this.apiService.getAll(URL).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.RecentOpenRuntimeEvents = data.responseList;
          if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
            $(`#${this.tableId}`).DataTable().destroy();
          };
          // this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $(`#${this.tableId}`);
            this.dataTable = table.DataTable({
              "order": [[4, "desc"]],
              "search": { regex: false, smart: false }, "columnDefs": [{ "orderable": false, "targets": [7] }]
            });
          }, 1000);
        } else {
          this.RecentOpenRuntimeEvents = [];
          if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
            $(`#${this.tableId}`).DataTable().destroy();
          };
          // this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $(`#${this.tableId}`);
            this.dataTable = table.DataTable({
              "order": [[4, "desc"]],
              "search": { regex: false, smart: false }, "columnDefs": [{ "orderable": false, "targets": [7] }]
            });
          }, 1000);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  DateFormat(startDate, gmtOffset) {
    var d = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS");
    if (startDate) {
      var formattedDate = moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return "";
    }
  }

  formSubmit($event, dataForm) {
    console.log(dataForm);
    let SaveObj: any = {
      selectedType: [],
      selectedValue: []
    }
    if (this.type == 'Asset Status Widget') {
      this.getAssetStatusWidget(dataForm.value)
      if (dataForm.value.tier) {
        SaveObj.selectedType.push('tier')
        SaveObj.selectedValue.push(dataForm.value.tier)
      }
      // if (dataForm.value.site) {
      //   SaveObj.selectedType.push("site");
      //   SaveObj.selectedValue.push(dataForm.value.site)
      // }
    }
    else if (this.type == 'Find Site Call-Out Instructions by Start Time') {
      this.getFindSiteCallOutInstruction(dataForm.value)
      if (dataForm.value.tier) {
        SaveObj.selectedType.push('tier')
        SaveObj.selectedValue.push(dataForm.value.tier)
      }
      // if (dataForm.value.site) {
      //   SaveObj.selectedType.push("site");
      //   SaveObj.selectedValue.push(dataForm.value.site)
      // }
    }
    else if (this.type == 'Asset Status Map') {

      dataForm.value.statusUrl = this.selectedMapStatus

      if (dataForm.value) {
        this.getMapPointerData(dataForm.value)
      } else {
        this.getMapPointerData({})
      }

      if (dataForm.value.tier) {
        SaveObj.selectedType.push('tier')
        SaveObj.selectedValue.push(dataForm.value.tier)
      }
      if (dataForm.value.asset) {
        SaveObj.selectedType.push("asset");
        SaveObj.selectedValue.push(dataForm.value.asset)
      }
    }
    else if (this.type == 'Live Feed Events') {
      this.getLiveFeedList(dataForm.value)
      if (dataForm.value.tier) {
        SaveObj.selectedType.push('tier')
        SaveObj.selectedValue.push(dataForm.value.tier)
      }
      if (dataForm.value.asset) {
        SaveObj.selectedType.push("asset");
        SaveObj.selectedValue.push(dataForm.value.asset)
      }
    }
    else if (this.type == 'Items Waiting for Approval') {
      this.getApprovalEventsData(dataForm.value)
      // if (dataForm.value.tier) {
      //   SaveObj.selectedType.push('tier')
      //   SaveObj.selectedValue.push(dataForm.value.tier)
      // }
      // if (dataForm.value.asset) {
      //   SaveObj.selectedType.push("asset");
      //   SaveObj.selectedValue.push(dataForm.value.asset)
      // }
    }
    else if (this.type == 'Repeat Offender Widget') {
      this.getFaultCodeCountData(dataForm.value)
      if (dataForm.value.tier) {
        SaveObj.selectedType.push('tier')
        SaveObj.selectedValue.push(dataForm.value.tier)
      }
      // if (dataForm.value.asset) {
      //   SaveObj.selectedType.push("asset");
      //   SaveObj.selectedValue.push(dataForm.value.asset)
      // }

        if (dataForm.value.asset) {
          SaveObj.selectedType.push('rcpids')
          let rcp = JSON.parse(JSON.stringify(dataForm.value.asset))
          delete dataForm.value.asset;
          dataForm.value.rcpids = rcp;
          SaveObj.selectedValue.push(dataForm.value.rcpids)        
      }
      if(dataForm.value.dayValue) {
        SaveObj.selectedType.push("days");
        SaveObj.selectedValue.push(dataForm.value.days) 
      }
    }
    else if (this.type == 'Most Recent Open Runtime Events') {
      this.getRecentOpenRuntimeEvents(dataForm.value)
      // if (dataForm.value.tier) {
      //   SaveObj.selectedType.push('tier')
      //   SaveObj.selectedValue.push(dataForm.value.tier)
      // }
      // if (dataForm.value.asset) {
      //   SaveObj.selectedType.push("asset");
      //   SaveObj.selectedValue.push(dataForm.value.asset)
      // }
    }
    else if (this.type == 'Map of Site Location') {
      if (dataForm.value.tier) {
        SaveObj.selectedType.push('tier')
        SaveObj.selectedValue.push(dataForm.value.tier)
        this.getMapByAssetSitePointerData(dataForm.value);
        this.ShowFormValue = false;
      } else {
        this.mapPointerData = []
        this.positions = []
        this.ShowFormValue = true;
      }
      // if (dataForm.value.asset) {
      //   SaveObj.selectedType.push("asset");
      //   SaveObj.selectedValue.push(dataForm.value.asset)
      // }
    }
    else if (this.type == 'Map of Site Location') {
      if (dataForm.value.tier) {
        SaveObj.selectedType.push('tier')
        SaveObj.selectedValue.push(dataForm.value.tier)
        this.getMapByAssetSitePointerData(dataForm.value);
        this.ShowFormValue = false;
      } else {
        this.mapPointerData = []
        this.positions = []
        this.ShowFormValue = true;
      }
      if (dataForm.value.asset) {
        SaveObj.selectedType.push("asset");
        // SaveObj.selectedValue.push(dataForm.value.asset)
      }
    }
    // else if (this.type == 'Event Status') {
    //     this.getEventBargraph(dataForm.value)
    // }
    this.ShowFormValue = false;
    // if (this.type != 'Find Site Call-Out Instructions by Start Time') {
    this.SaveWidgetObj(SaveObj, dataForm.value);
    // }

  }

  resetForm() {
    this.dataForm.reset();
    this.days = '';
  }

  /* ----------------------------------------------------------------------- get asset by tier */
  OnTierChangeGetAsset(event) {
    let tierIds = []
    setTimeout(() => {
      console.log(event/* .target.value, */, this.selectedTier);
      this.selectedTier.map((obj, index) => {
        tierIds.push(obj.tierId)
      })
      // var URL = 'asset/getAssetDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId+"&isAssetLive=true";
      var URL = 'asset/getAssetDropDownByClient';
      let ObjData:any = {
        clientId: this.clientId,
        userId: this.userId
      }
      if (this.selectedTier.length) {
        // URL = URL + '&tierId=' + tierIds.toString();
        ObjData.tierId = tierIds.toString();
      }
      // if (this.selectedTier.length) {
      //     let tierIds = []
      //     this.selectedTier.map((obj,index)=>{
      //       tierIds.push(obj.tierId)
      //     })  
      //     if (tierIds.length) {
      //         URL = URL + "&tierId="+tierIds.toString();
      //     }
      // }

      if (tierIds.length) {
        this.startLoading()
        this.apiService.create(URL, ObjData).pipe(delay(5000),finalize(()=> {
          this.stopLoading();
      })).subscribe(
          data => {
            this.isLoading = false;
            this.stopLoading();
            if (data.statusCode == 100) {
              this.assetList = data.responseList;
            } else {
              this.assetList = [];
            }
          },
          error => {
            this.stopLoading();
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      } else {
        this.assetList = []
      }
    }, 500)

  }

  /* ----------------------------------------------------------------------- get asset by tier */
  getAssetList() {
    let tierIds = []
    // setTimeout(() => {
    // console.log(event/* .target.value, */ ,this.selectedTier);
    // this.selectedTier.map((obj,index)=>{
    //     tierIds.push(obj.tierId)
    // })
    // var URL = 'asset/getAssetDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId+"&isAssetLive=true";
    var URL = 'asset/getAssetDropDownByClient';

    let ObjData:any = {
      clientId: this.clientId,
      userId: this.userId
    }
    if (this.selectedTier.length) {
      // URL = URL + '&tierId=' + tierIds.toString();
      ObjData.tierId = tierIds.toString();
    }
    // if (this.selectedTier.length) {
    //     let tierIds = []
    //     this.selectedTier.map((obj,index)=>{
    //       tierIds.push(obj.tierId)
    //     })  
    //     if (tierIds.length) {
    //         URL = URL + "&tierId="+tierIds.toString();
    //     }
    // }

    // if (tierIds.length) {

    this.apiService.create(URL, ObjData).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.assetList = data.responseList;
        } else {
          this.assetList = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
    // } else {
    //     this.assetList = []
    // }
    // },500)

  }



  // SaveWidgetObj(options, value) {
  //   if (this.widgetObj.dashboardWidgetId) {

  //     let obj = {
  //       "dashboardWidget": {
  //         "dashboardWidgetId": this.widgetObj.dashboardWidgetId,
  //         // range:""
  //       },
  //       // "optionId":3,
  //       selectionValues: options.selectedValue,
  //       selectionTypes: options.selectedType
  //     }
  //     let objKeyData = {

  //     }
  //     options.selectedType.map((val,index)=>{
  //       if (objKeyData[val] && objKeyData[val].length) {
  //         objKeyData[val].push(options.selectedValue[index]);
  //       }else{
  //         objKeyData[val] = []
  //         objKeyData[val].push(options.selectedValue[index]);
  //       }
  //     })


  //     console.log(obj, "::::::::::::::::::::::::::::::",objKeyData);

  //   this.apiService.create('dashboardWidgetOption/createDashBoardWidgetOption', obj).subscribe(
  //     data => {
  //       if (data.statusCode == 100) {
  //         this.alertService.sweetMessage('success', data.responseMessage);
  //       } else {

  //       }
  //     },
  //     error => {
  //       this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  //     }
  //     );
  //   }
  // }

  SaveWidgetObj(options, value) {
    console.log(value, options);

    if (this.widgetObj.dashboardWidget && this.widgetObj.dashboardWidget.dashboardWidgetId) {




      let obj: any = {
        widgetId: this.widgetObj.widgetId,
        "dashboardWidget": {
          "dashboardWidgetId": this.widgetObj.dashboardWidget.dashboardWidgetId,
          timeRange: value.range ? value.range : null
        },
        // "optionId":3,
        tiers: [],
        sites: [],
        assets: [],
        datapoints: []
      }
      let objKeyData: any = {

      }

      if(this.type == 'Repeat Offender Widget') {
        obj.dashboardWidget.timeRange = value.days;
      }

      if (value.site) {
        let siteObjArr = []
        value.site.map((objSite, index) => {
          var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.sites, function (obj) {
            return obj.siteId == objSite.siteId;
          }), 'dashboardWidgetOptionId');
          // console.log(dashboardWidgetOptionId, "++++++++++++++++++++++++++++++++++++++++++++++++++++++sites++++++dashboardWidgetOptionId");
          if (dashboardWidgetOptionId) {
            objSite.dashboardWidgetOptionId = dashboardWidgetOptionId
          }
          siteObjArr.push(objSite)
        })



        obj.sites = siteObjArr//value.site
      }
      if (value.tier) {
        let tierObjArr = []
        value.tier.map((objTier, index) => {
          var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.tiers, function (obj) {
            return obj.tierId == objTier.tierId;
          }), 'dashboardWidgetOptionId');
          if (dashboardWidgetOptionId) {
            objTier.dashboardWidgetOptionId = dashboardWidgetOptionId
          }
          console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++tiers+++++++dashboardWidgetOptionId");
          tierObjArr.push(objTier)
        })

        obj.tiers = tierObjArr//value.tier
      }

      if (value.asset) {
        let assetObjArr = []
        value.asset.map((objasset, index) => {
          var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.assets, function (obj) {
            return obj.assetId == objasset.assetId;
          }), 'dashboardWidgetOptionId');
          if (dashboardWidgetOptionId) {
            objasset.dashboardWidgetOptionId = dashboardWidgetOptionId
          }
          console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++assets+++++dashboardWidgetOptionId");
          assetObjArr.push(objasset)
        })

        obj.assets = assetObjArr//value.tier
      }

      if (value.rcpids) {
        let assetObjArr = []
        value.rcpids.map((objasset, index) => {
            var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.rcps, function (obj) {
                return obj.runTimeCollectionPointId == objasset.Rcpid;
            }), 'dashboardWidgetOptionId');
            if (dashboardWidgetOptionId) {
                objasset.dashboardWidgetOptionId = dashboardWidgetOptionId
            }
            // console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++assets+++++dashboardWidgetOptionId");
            assetObjArr.push({runTimeCollectionPointId:objasset.Rcpid, dashboardWidgetOptionId:objasset.dashboardWidgetOptionId})
        })

        obj.rcpids = assetObjArr//value.tier
    }


      if (value.dataPoint) {
        let dataPointObjArr = []
        value.dataPoint.map((objdataPoint, index) => {
          var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.datapoints, function (obj) {
            return obj.assetChannelId == objdataPoint.assetChannelId;
          }), 'dashboardWidgetOptionId');
          if (dashboardWidgetOptionId) {
            objdataPoint.dashboardWidgetOptionId = dashboardWidgetOptionId
          }
          console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++datapoints+++++dashboardWidgetOptionId");
          dataPointObjArr.push(objdataPoint)
        })

        obj.datapoints = dataPointObjArr//value.tier
      } else if (value.assetChannelId) {
        let dataPointObjArr = []
        // value.dataPoint.map((objdataPoint,index)=>{
        var dashboardWidgetOptionId = _.result(_.find(this.widgetObj.datapoints, function (obj) {
          return obj.assetChannelId == value.assetChannelId;
        }), 'dashboardWidgetOptionId');
        let objDatapoint: any = {
          assetChannelId: value.assetChannelId
        }
        if (dashboardWidgetOptionId) {
          objDatapoint.dashboardWidgetOptionId = dashboardWidgetOptionId
        }
        console.log(dashboardWidgetOptionId, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++assetChannelId+++++dashboardWidgetOptionId");
        dataPointObjArr.push(objDatapoint)
        // })

        obj.datapoints = dataPointObjArr//value.tier
      }
      // options.selectedType.map((val,index)=>{
      //   if (objKeyData[val] && objKeyData[val].length) {
      //     objKeyData[val].push(options.selectedValue[index]);
      //   }else{
      //     objKeyData[val] = []
      //     objKeyData[val].push(options.selectedValue[index]);
      //   }
      // })


      /* 
      var delete_id = _.result(_.find(savedViews, function(obj) {
          return obj.description === view;
      }), 'id');
      */



      console.log(obj, "::::::::::::::::::::::::::::::", this.widgetObj);
      // return
      this.apiService.create('dashboardWidgetOption/createDashBoardWidgetOption', obj).subscribe(
        data => {
          this.isLoading = false;
          this.stopLoading();
          if (data.statusCode == 100) {
            this.alertService.sweetMessage('success', data.responseMessage);
            if (objKeyData.tier) {
              this.widgetObj.tier = objKeyData.tier
            } else {
              this.widgetObj.tier = []
            }
            if (objKeyData.asset) {
              this.widgetObj.asset = objKeyData.asset
            } else {
              this.widgetObj.asset = []
            }
            if (objKeyData.dataPoint) {
              this.widgetObj.datapoint = objKeyData.dataPoint
            } else {
              this.widgetObj.datapoint = []
            }
            if (objKeyData.site) {
              this.widgetObj.site = objKeyData.site
            } else {
              this.widgetObj.site = []
            }
            if (objKeyData.range && objKeyData.range.length) {
              this.widgetObj.timeRange = objKeyData.range[0]
            } else {
              this.widgetObj.timeRange = null
            }

            this.widgetObj = data.response
            // response

            if (this.widgetObj.rcps && this.widgetObj.rcps.length) {
               
              let rcpList = []; 
              for (const iterator of this.widgetObj.rcps) {
                  rcpList.push({Rcpid:iterator.runTimeCollectionPointId})
              }
              // this.assetName = this.widgetObj.asset//[0]
              // dataObj.asset = rcpList;
              this.selectedAssetList = rcpList;
             
              // dataObj.asset = this.assetName
          }

          } else {

          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  getFindSiteCallOutInstruction(obj) {
    // 
    this.callOutSummary = ""
    // console.log(obj);
    // console.log(obj, "}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}",this.DateObj , moment(this.DateObj).format("YYYY-MM-DD HH:mm:ss"));

    var URL = "asset/getCallInstructionBySearch?clientId=" + this.clientId + (this.DateObj ? "&date=" + moment(this.DateObj).format("YYYY-MM-DD HH:mm:ss") : "")
    let assetIds = []
    if (obj.asset) {
      obj.asset.map((obj, index) => {

        assetIds.push(obj.assetId)
      })
      URL = URL + "&assetId=" + assetIds.toString();
    }
    if ($.fn.DataTable.isDataTable(`#Find_Site_Call_out_table`)) {
      $(`#Find_Site_Call_out_table`).DataTable().destroy();
      // $(`#Find_Site_Call_out_table`).empty(); 
    };
    
    this.isLoading = true;
    this.FindSiteCallOutInstruction = [];
    this.scheduleType = [];

    if (assetIds.length) {
      this.apiService.getAll(URL).pipe(delay(5000),finalize(()=> {
        this.stopLoading();
    })).subscribe(
        data => {
          this.isLoading = false;
          this.stopLoading();
          if (data.statusCode == 100) {
  
            console.log("----------------------------------1");
            
            this.FindSiteCallOutInstruction = data.responseList;
            this.scheduleType = data.scheduleType
            if ($.fn.DataTable.isDataTable(`#Find_Site_Call_out_table`)) {
              $(`#Find_Site_Call_out_table`).DataTable().destroy();
              // $(`#Find_Site_Call_out_table`).empty(); 
            };
            // this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $(`#Find_Site_Call_out_table`);
              // this.dataTable = table.DataTable();
              this.dataTable = table.DataTable({});
            }, 1000);
          } else {
            console.log("--------------------2");
            
            if ($.fn.DataTable.isDataTable(`#Find_Site_Call_out_table`)) {
              $(`#Find_Site_Call_out_table`).DataTable().destroy();
              // $(`#Find_Site_Call_out_table`).empty(); 
            };
            this.FindSiteCallOutInstruction = [];
            // this.chRef.detectChanges();
            setTimeout(() => {
              if (!$.fn.DataTable.isDataTable(`#Find_Site_Call_out_table`)) {
              const table: any = $(`#Find_Site_Call_out_table`);
              // this.dataTable = table.DataTable();
              this.dataTable = table.DataTable({});
              }
            }, 1000);
          }
        },
        error => {
          this.isLoading = false;
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    } else {
      if ($.fn.DataTable.isDataTable(`#Find_Site_Call_out_table`)) {
        $(`#Find_Site_Call_out_table`).DataTable().destroy();
        $(`#Find_Site_Call_out_table`).empty(); 
      };
      this.FindSiteCallOutInstruction = [];
      // this.chRef.detectChanges();
      setTimeout(() => {
        if (!$.fn.DataTable.isDataTable(`#Find_Site_Call_out_table`)) {
        const table: any = $(`#Find_Site_Call_out_table`);
        // this.dataTable = table.DataTable();
        this.dataTable = table.DataTable();
        }
      }, 1000);
    }
   
  }

  GetCallInstructionDetail(assetId) {

    this.apiService.getAll("asset/getCallInstructionDetail?assetId=" + assetId).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        console.log(data, "=============================================");

        if (data.statusCode == 100) {
          this.callOutSummary = data.response.callOutSummary
        } else {
          this.callOutSummary = ''
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getMapByAssetSitePointerData(obj) {

    if (obj.tier && obj.tier.length) {
      var URL = 'site/getSiteLocationByTier?'//?tierIds='+ obj.tier;

      if (obj.tier.length) {
        let tierIds = []
        obj.tier.map((obj, index) => {
          tierIds.push(obj.tierId)
        })


        var tierString = 'tierIds=' + tierIds.toString();
        var URL = URL.concat(tierString);
      }

      //   if (obj.tier) {
      //     var tierString = '&tierId=' + obj.tier.toString();
      //     var URL = URL.concat(tierString);
      // }
      // if (obj.site) {
      //   var siteString = '&siteId=' + obj.site;
      //   var URL = URL.concat(siteString);
      // }
      // if (obj.asset) {
      //     var assetString = '&assetId=' + obj.asset;
      //     var URL = URL.concat(assetString);
      // }


      // if (this.tierId != null && this.tierId != '') {
      //     var tierString = '&tierId=' + this.tierId.toString();
      //     var URL = URL.concat(tierString);
      // }
      // if (this.site != '') {
      //     var siteString = '&siteId=' + this.site;
      //     var URL = URL.concat(siteString);
      // }
      // if (this.customerId != null && this.customerId != '') {
      //     var customerString = '&customerId=' + this.customerId.toString();
      //     var URL = URL.concat(customerString);
      // }
      if (obj.statusUrl) {
        var statusString = '&status=' + obj.statusUrl.toString();
        var URL = URL.concat(statusString);
      }
      // if (this.asset != '') {
      //     var assetString = '&assetId=' + this.asset;
      //     var URL = URL.concat(assetString);
      // }
      this.isLoading = true;
      this.mapPointerData = [];

      this.apiService.getAll(URL).pipe(delay(5000),finalize(()=> {
        this.stopLoading();
    })).subscribe(
        data => {
          this.isLoading = false;
          this.stopLoading();
          if (data.statusCode == 100) {
            this.mapPointerData = data.responseList;
            this.positions = [];
            this.errorText = "";
            this.styleExp.display = "none";
            // this.zoomLevel = 6;

            let position = [];
            Object.keys(this.mapPointerData).forEach(key => {
              if (this.mapPointerData[key]['latitude'] != null && this.mapPointerData[key]['longitude'] != null) {

                if (this.mapPointerData[key]['alarmState'] == "Offline") {
                  this.mapPointerData[key]['alarmState'] = "Suspended";
                }
                // this.latitude = this.mapPointerData[key]['latitude']
                // this.longitude = this.mapPointerData[key]['longitude']
                position.push([
                  this.mapPointerData[key]['latitude'],
                  this.mapPointerData[key]['longitude'],
                  this.mapPointerData[key]['asset']['alarmState'],
                  this.mapPointerData[key]['asset']['assetName'],
                  this.mapPointerData[key]['asset']['lastEventTime'],
                  // this.mapPointerData[key]['customerName'],
                  // this.mapPointerData[key]['site'],
                  // this.mapPointerData[key]['city'],
                  // this.mapPointerData[key]['state'],
                  // this.mapPointerData[key]['country'],
                  // this.mapPointerData[key]['assetId'],
                  // this.mapPointerData[key]['name'],
                  // this.mapPointerData[key]['proximity'],
                ]);
              }
            });

            var self = this;
            setTimeout(() => {
              self.positions = position;
            }, 1000);

          } else {
            if (data.responseMessage != 'Permission not granted') {
              this.errorText = data.responseMessage;
              this.styleExp.display = "block";
              this.mapPointerData = [];
              this.positions = [];
            } else {
              // this.errorText = data.responseMessage;
              // this.styleExp.display = "block";
              this.mapPointerData = [];
              this.positions = [];
            }
          }
        },
        error => {
          this.isLoading = false;
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    } else {
      console.log("------------------------------else case ");

      this.mapPointerData = []
      this.positions = []
    }
    // 
  }



  getLiveFilterData(e) {
    let obj: any = { statusUrl: "" }
    if (e.target.value == 'alarm') {
      obj.statusUrl = "A"
      this.selectedMapStatus = "A"
    } else if (e.target.value == 'warning') {
      this.selectedMapStatus = "W"
      obj.statusUrl = "W"
    } else if (e.target.value == 'normal') {
      this.selectedMapStatus = "N"
      obj.statusUrl = "N"
    } else if (e.target.value == 'offline') {
      this.selectedMapStatus = "S"
      obj.statusUrl = "S"
    } else if (e.target.value == 'nocomm') {
      this.selectedMapStatus = "C"
      obj.statusUrl = "C"
    } else if (e.target.value = 'ALL') {
      this.selectedMapStatus = null
      obj.statusUrl = null
    }
    obj.tier = this.selectedTier
    this.getMapPointerData(obj);
  }

  /* Get tier list */
  getMapPointerData(obj) {
    var URL = 'asset/getAssetByClient';
    let apiData: any = {
      clientId: this.clientId,
      userId: this.userId,
      isAssetLive: true,
    };
    //   if (obj.tier) {
    //     obj.tier.map
    //     var tierString = '&tierId=' + obj.tier.toString();
    //     var URL = URL.concat(tierString);
    // }
    let tierIds = []
    if (this.selectedTier) {
      this.selectedTier.map((obj, index) => {
        tierIds.push(obj.tierId)
      })
      // var URL = 'asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId 
      if (this.selectedTier.length) {
        apiData.tierIds = tierIds.toString();
        // URL = URL + '&tierId=' + tierIds.toString();
      }
    }
    // if (obj.site) {
    //   var siteString = '&siteId=' + obj.site;
    //   var URL = URL.concat(siteString);
    // }
    if (obj.asset) {
      apiData.assetIds = obj.asset.toString();
      var assetString = '&assetId=' + obj.asset;
      // var URL = URL.concat(assetString);
    }
    // if (this.tierId != null && this.tierId != '') {
    //     var tierString = '&tierId=' + this.tierId.toString();
    //     var URL = URL.concat(tierString);
    // }
    // if (this.site != '') {
    //     var siteString = '&siteId=' + this.site;
    //     var URL = URL.concat(siteString);
    // }
    // if (this.customerId != null && this.customerId != '') {
    //     var customerString = '&customerId=' + this.customerId.toString();
    //     var URL = URL.concat(customerString);
    // }
    if (this.selectedMapStatus) {
      apiData.status = [this.selectedMapStatus]
      // var statusString = '&status=' + this.selectedMapStatus.toString();
      // var URL = URL.concat(statusString);
    }
    // if (this.asset != '') {
    //     var assetString = '&assetId=' + this.asset;
    //     var URL = URL.concat(assetString);
    // }

    this.isLoading = true;
    this.mapPointerData = [];

    this.apiService.create(URL, apiData).pipe(delay(5000),finalize(()=> {
      this.stopLoading();
  })).subscribe(
      data => {
        this.isLoading = false;
        this.stopLoading();
        if (data.statusCode == 100) {
          this.mapPointerData = data.responseList ? data.responseList : [];
          this.positions = [];
          this.errorText = "";
          this.styleExp.display = "none";
          // this.zoomLevel = 6;

          let position = [];
          Object.keys(this.mapPointerData).forEach(key => {
            if (this.mapPointerData[key]['latitude'] != null && this.mapPointerData[key]['longitude'] != null) {

              if (this.mapPointerData[key]['alarmState'] == "Offline") {
                this.mapPointerData[key]['alarmState'] = "Suspended";
              }
              // this.latitude = this.mapPointerData[key]['latitude']
              // this.longitude = this.mapPointerData[key]['longitude']
              position.push([
                this.mapPointerData[key]['latitude'],
                this.mapPointerData[key]['longitude'],
                this.mapPointerData[key]['alarmState'],
                this.mapPointerData[key]['customerName'],
                this.mapPointerData[key]['assetDescription'],
                this.mapPointerData[key]['site'],
                this.mapPointerData[key]['city'],
                this.mapPointerData[key]['state'],
                this.mapPointerData[key]['country'],
                this.mapPointerData[key]['lastEventTime'],
                this.mapPointerData[key]['assetId'],
                this.mapPointerData[key]['name'],
                this.mapPointerData[key]['proximity'],
              ]);
            }
          });

          var self = this;
          setTimeout(() => {
            self.positions = position;
          }, 1000);

        } else {
          if (data.responseMessage != 'Permission not granted') {
            this.errorText = data.responseMessage;
            this.styleExp.display = "block";
            this.mapPointerData = [];
            this.positions = [];
          } else {
            // this.errorText = data.responseMessage;
            // this.styleExp.display = "block";
            this.mapPointerData = [];
            this.positions = [];
          }
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // Graph functions
  onMapReady(map?: any) {
    this.map = map;
    this.bounds = new google.maps.LatLngBounds();
  }

  // Graph functions
  onMarkerInit(marker) {
    let loc = new google.maps.LatLng(marker.position[0], marker.position[1]);
    if (this.bounds) {
      this.bounds.extend(loc);
      this.zoomLevel = this.getBoundsZoomLevel(this.bounds, {
        "height": 330,
        "width": 550
      });

      var data = this.bounds.getCenter();
      this.map.fitBounds(this.bounds);
      this.map.setCenter(this.bounds.getCenter());

      var that = this;
      setTimeout(() => {
        that.latitude = data.lat();
        that.longitude = data.lng();
        that.zoomLevel = that.zoomLevel;
      }, 3000)
    }

    // this.chRef.detectChanges();
  }


  // Zoom Functionality
  getBoundsZoomLevel(bounds, mapDim) {
    var WORLD_DIM = {
      height: 256,
      width: 256
    };
    var ZOOM_MAX = 21;

    function latRad(lat) {
      var sin = Math.sin(lat * Math.PI / 180);
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  redirectToAsset(link) {
    console.log(link);
    this.router.navigate([link]);

  }

  assetDetail(statusValue, flag) {
    console.log(this.isPreview);
    if (!this.isPreview) {

      // Destroy Data Table if is already and clear the data
      if ($.fn.DataTable.isDataTable('#asset_data_table' + this.index)) {
        $('#asset_data_table' + this.index).DataTable().destroy();
        this.assetDatalistPopup = [];
      }

      // var URL = 'asset/getAssetStateValue?userId=' + this.userId+((this.clientFilterId && this.clientFilterId != 0) ? '&clientId='+this.clientFilterId : '&clientId='+this.clientTopDropdownValue);
      // changed as per the requirements on 15/11/2019
      var URL = 'asset/getAssetStateValue?clientId=' + this.clientId //+ this.userId//+((this.clientFilterId && this.clientFilterId != 0) ? '&clientId='+this.clientFilterId : '');

      this.assetDatalistPopup = [];

      if (statusValue != false && flag == 1) {
        var statusString = '&alarmState=' + statusValue;
        var URL = URL.concat(statusString);
      } else if (statusValue != false && flag == 2) {
        var statusString = '&status=' + statusValue;
        var URL = URL.concat(statusString);
      }
      if (this.widgetObj.tiers.length) {
        let tierIds = []
        this.widgetObj.tiers.map((obj, index) => {
          tierIds.push(obj.tierId)
        })
        var tierString = '&tierIds=' + tierIds.toString();
        var URL = URL.concat(tierString);
      }

      $('#assetListModel' + this.index).modal("show");
      this.apiService.getAll(URL).subscribe(
        data => {
          this.isLoading = false;
          this.stopLoading();
          if (data.statusCode == 100) {
            let Arr = []
            this.assetDatalistPopup = [];
            let objArr = data.responseList;

            objArr.map((val, key) => {
              let objValue = val
              if (!val.notificationPlan) {
                objValue.notificationPlan = {};
              }
              if (!val.shiftGroup) {
                objValue.shiftGroup = {};
              }
              if (!val.customer) {
                objValue.customer = {};
              }
              if (!val.site) {
                objValue.site = {};
              }
              Arr.push(objValue);
            });

            this.assetDatalistPopup = JSON.parse(JSON.stringify(Arr));

            //  this.chRef.detectChanges();
            let self = this
            setTimeout(() => {
              const table: any = $('#asset_data_table' + self.index);
              table.DataTable();
            }, 1000);

          }
          else {
            this.assetDatalistPopup = [];
            if ($.fn.DataTable.isDataTable('#asset_data_table' + this.index)) {
              $('#asset_data_table' + this.index).DataTable().destroy();
              this.assetDatalistPopup = [];
            }
            const table: any = $('#asset_data_table' + this.index);
            this.dataTable = table.DataTable();

          }
        },
        error => {
          this.isLoading = false;
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  // Function to format Date
  DateFormatEvent(startDate, gmtOffset) {
    let d1 = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    // console.log(d, startDate,d1, moment(d1).format("MM-DD-YYYY HH:mm:ss"));

    if (startDate) {
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return "";
    }
    // return this.formatDateService.formatDate(startDate, gmtOffset);
  }



  notificationDetail(eventData, eventListType) {
    let runTimeStatus = eventData.runTimeStatus;
    let licenseIsAck = eventData.licenseIsAck;
    let acknowledgeByMe = eventData.acknowledgeByMe;
    let assignToMe = eventData.assignToMe;
    let technicianId = eventData.technicianId;      
    let licenseIsTechEntry = eventData.licenseIsTechEntry;
    let isAck = eventData.isAck;

    if(this.userId == eventData.technicianId && runTimeStatus == 2) {
       this.performAction = 1;
    } 

    // console.log('notification detail', eventData.acknowledgeByMe, eventData.licenseIsAck, eventData.assignToMe);
    
    // console.log('notification detail 2', eventData.technicianId, eventData.runTimeStatus, eventData.licenseIsTechEntry);
    

    if(licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
      console.log('in if detail');
      this.showAckBtn = 1;
    } else {
      this.showAckBtn = 0;
      console.log('in else detail');
    }

    if(
      licenseIsAck == true && 
      acknowledgeByMe == false && 
      assignToMe == true && 
      (technicianId == null || technicianId == 0) && 
      licenseIsTechEntry == true && 
      runTimeStatus == 1
      ) {
      console.log('in if detail showAckErcBtn');
      this.showAckErcBtn = 1;
    } else {
      this.showAckErcBtn = 0;
      console.log('in else detail showAckErcBtn');
    }

    if(
      licenseIsAck == true && 
      assignToMe == true && 
      (technicianId == null || technicianId == 0) &&         
      runTimeStatus == 1
      ) {
      this.showERC = 1;
    } else {
      this.showERC = 0;
    }

    let obj = {
      id:null,
      type:null
    }
    let pVal = 0;
    if(eventData.isParent == false) {
      pVal = 0;
    } else if(eventData.isParent == true) {
      pVal = 1;
    }
    obj.type = eventData.notificationType;
    if (eventData.notificationType == "Notification") {
        obj.id = eventData.notificationId;
    } else if (eventData.notificationType == "RunTime") {
        obj.id = eventData.runTimeEventId;
    }
    if(eventListType == 'approval' || eventListType == 'approved') {
      obj.type = 'RunTime';
      obj.id = eventData.runTimeEventId;
      this.performAction = 1;
      eventData.licenseIsTechEntry = true;
    }

    this.router.navigate(["eventDetails", eventData.assetId,], 
      {
        queryParams: 
        { 
          type: obj.type, 
          id :obj.id, 
          rcpId:eventData.runTimeCollectionPointId, 
          notificationId : eventData.notificationId, 
          isParent : pVal, 
          performAction : this.performAction, 
          showAckBtn : this.showAckBtn,
          showAckErcBtn : this.showAckErcBtn,
          showERC : this.showERC,
          licenseIsTechEntry : eventData.licenseIsTechEntry, 
          eventListType : eventListType,
          assignToMe : assignToMe == true ? 1 : 0,
          isAck : isAck == true ? 1 : 0,
          source:'CD',
          dasboardID:this.dashboardId,
          DashboardName:this.DashboardName
        }
    });
  }
   
  getHistory(){
      if (this.type == 'Asset Status Widget') {
      this.isShowTier = true;
      this.isShowSite = false;
      this.isShowAsset = false;
      this.isShowDatapoint = false;
      this.isShowRange = false;
    }
    else if (this.type == 'Find Site Call-Out Instructions by Start Time') {
      this.isShowTier = false;
      this.isShowSite = false;
      this.isShowAsset = true;
      this.isShowDatapoint = false;
      this.isShowRange = false;
    }
    else if (this.type == 'Asset Status Map') {
      this.isShowTier = true;
      this.isShowSite = false;
      this.isShowAsset = false;
      this.isShowDatapoint = false;
      this.isShowRange = false;
    }
    else if (this.type == 'Live Feed Events') {
      this.isShowTier = true;
      this.isShowSite = false;
      this.isShowAsset = true;
      this.isShowDatapoint = false;
      this.isShowRange = false;
    }
    else if (this.type == 'Items Waiting for Approval') {
      this.isShowTier = true;
      this.isShowSite = false;
      this.isShowAsset = true;
      this.isShowDatapoint = false;
      this.isShowRange = false;
    }
    else if (this.type == 'Repeat Offender Widget') {
      this.isShowTier = true;
      this.isShowSite = false;
      this.isShowAsset = true;
      this.isShowDatapoint = false;
      this.isShowRange = true;
    }
    else if (this.type == 'Most Recent Open Runtime Events') {
      this.isShowTier = true;
      this.isShowSite = false;
      this.isShowAsset = false;
      this.isShowDatapoint = false;
      this.isShowRange = false;
    }
    else if (this.type == 'Map of Site Location') {
      this.isShowTier = true;
      this.isShowSite = false;
      this.isShowAsset = false;
      this.isShowDatapoint = false;
      this.isShowRange = false;
    }
    
    $('#HistoryModel'+this.index).modal('show')

    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable'+this.index)) {
      $('#HistoryDatatable'+this.index).DataTable({ "order": [], "destroy": true }).destroy();
    };
    this.apiService.getAll('dashboardWidget/getDashboardWidgetHistoryById?dashboardWidgetId=' + this.widgetObj.dashboardWidget.dashboardWidgetId).subscribe(
        data => {
        if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
            const table: any = $('#HistoryDatatable'+this.index);
            this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
        } else {
            setTimeout(() => {
            const table: any = $('#HistoryDatatable'+this.index);
            this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            this.alertService.sweetMessage('error',data.responseMessage)
            
        }
        },
        error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    ); 
}

gettierName(list){
    let str = ''
    if (list.length) {
        list.map((obj,i)=>{
            if (i==0) {
                str+=obj.tierName
            } else {
                str+= ', '+obj.tierName
            }
        })
    }
    return str;
} 
getSiteName(list){
    let str = ''
    if (list.length) {
        list.map((obj,i)=>{
            if (i==0) {
                str+=obj.siteName
            } else {
                str+= ', '+obj.siteName
            }
        })
    }
    return str;
} 
getAssetName(list){
    let str = ''
    if (list.length) {
        list.map((obj,i)=>{
            if (i==0) {
                str+=obj.assetName
            } else {
                str+= ', '+obj.assetName
            }
        })
    }
    return str;
} 
getDatapointName(list){
    let str = ''
    if (list.length) {
        list.map((obj,i)=>{
            if (i==0) {
                str+=obj.value
            } else {
                str+= ', '+obj.value
            }
        })
    }
    return str;
}    

      /* ----------------------------------------------------------------------- get asset /unit by tier */
      TierChangeGetAssetUnitDropdownList(event) {
        let tierIds = []
        setTimeout(() => {
            console.log(event/* .target.value, */, this.selectedTier);
            this.selectedTier.map((obj, index) => {
                tierIds.push(obj.tierId)
            })
            // var URL = 'asset/getAssetDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId + "&isAssetLive=true";
            var URL = 'asset/getAssetDropDownUnitByClient';
            let ObjData:any = {
                clientId: this.clientId,
                userId: this.userId
            }
            if (this.selectedTier.length) {
                // URL = URL + '&tierId=' + tierIds.toString();
                ObjData.tierId = tierIds.toString();
            }
            // if (this.selectedSite.length) {
            //     let siteIds = []
            //     this.selectedSite.map((obj, index) => {
            //         siteIds.push(obj.siteId)
            //     })
            //     if (siteIds.length) {
            //         // URL = URL + "&siteId=" + siteIds.toString();
            //         ObjData.siteId = siteIds.toString();

            //     }
            // }

            if (tierIds.length) {
                this.startLoading();
                this.apiService.create(URL, ObjData).pipe(delay(5000),finalize(()=> {
                    this.stopLoading();
                })).subscribe(
                    data => {
                        this.stopLoading();
                        if (data.statusCode == 100) {
                            this.assetList = data.responseList;
                        } else {
                            this.assetList = [];
                        }
                    },
                    error => {
                        this.stopLoading();
                        this.alertService.errorMessage(error, error.status, "Can not get asset list, please try again");
                    }
                );
            } else {
                this.assetList = []
            }
        }, 500)

    }

}


// runTimeEvent/getRuntimeEventAgingByClientAndUser?userId=17&clientId=5