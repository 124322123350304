import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
declare var d3:any,moment:any;

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html'
})
export class ViewComponent implements OnInit {
  
  isAuth = false
constructor(private router:Router) { }

ngOnInit() {
  let user = JSON.parse(localStorage.getItem("userData"))

    if (!user) {

        setTimeout(()=>{
          this.router.navigate(['/login']);
        //    this.alertService.sweetMessage('error',"Please login!");
        },1000)
        this.router.navigate(["/login"])
    }else{
      this.isAuth = true;
    }
}
}
