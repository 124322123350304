import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService , AlertService}  from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

token      : any  = "";
resetParam : any  = {"forgotPasswordToken":"","password":""};
userData   : any  = {"password":"","confirmPassword":""};

// PassInfo  : any = `
//   1. Password must be at least 8 characters<br>
//   2. Password must contain at least 1 numeric character<br>
//   3. Password must contain at least 1 uppercase character<br>
//   4. Password must contain at least 1 lowercase character<br>
//   5. Password cannot contain 3 identical consecutive characters<br>
//   6. Password must contain at least 1 special character !@#/\$%^&*()<br>
//   7. Password cannot be the same as the login Id<br>
//   8. Password cannot contain spaces<br>`;

PassInfo  : any = `
  1. Password must be at least 8 characters.<br>
  2. Password must contain at least 1 numeric character.<br>
  3. Password must contain at least 1 uppercase character.<br>
  4. Password must contain at least 1 special character !@#/\$%^&*().<br>
  5. Password cannot contain spaces.<br>`;

  styleExp : any  = {
    'display' : 'none'
  };
  passwordError :any ="";
  loginPageContentData:any;
  imageFile:any;

  isForgetting: boolean = false

constructor(
  private router:Router,
  private route: ActivatedRoute,
  private apiService: ApiService,
  private alertService: AlertService
) { }

  ngOnInit() {
		$(document).ready(function () {
		    $("body").css({"padding-top": "0px"});
	    });
    this.getLoginData();
	    this.route.params.subscribe(params => {
		    if(params['id']){
		      this.token = params['id'];
		    }
	  	});
	}

	// Function to Reset Password
	resetPassword(){
    this.isForgetting = true
    // var regx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var regx = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$");
    var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");

    if(regx.test(this.userData.password) == false) {
      this.passwordError = this.PassInfo;
      $("#passerror").css("display", "block");
      $("#pass").focus();
      this.isForgetting = false
      return;
    }


// Check if password contain 3 identical consecutive characters
    // if( RegExpConsicutive.test(this.userData.password) == false ) {
    //   this.passwordError = "Password cannot contain 3 identical consecutive characters";
    //   $("#passerror").css("display", "block");
    //   $("#pass").focus();
    //   return;
    // }

		if(this.userData.password != this.userData.confirmPassword){
      this.alertService.sweetMessage('error', 'Password and Confirm Password should be same.');
      this.userData = {"password":"","confirmPassword":""};
      this.isForgetting = false
		}
    else{
			if(!this.token){
        this.isForgetting = false
        this.alertService.sweetMessage('error', 'Invalid token.');
			}
      else{
				this.resetParam = {"forgotPasswordToken":this.token,"password":window.btoa(this.userData.confirmPassword)};
        // this.apiService.update('public/user/resetPassword', this.resetParam).subscribe(
				this.apiService.resetPassword('user/resetPassword', this.resetParam).subscribe(
					data => {
            this.isForgetting = false
						if(data.statusCode == 100){
              this.alertService.sweetMessage('success',data.responseMessage);
              if(data.authToken){
                this.router.navigate(['/user-activation/'+data.authToken]);
              }else{
                this.router.navigate(['/login']);
              }							
						}else{
              // if(data.responseMessage == 'Invalid Password can not contain 3 Indentical Consecutive Character') {
              //   this.passwordError = "Password cannot contain 3 identical consecutive characters";
              //   $("#passerror").css("display", "block");
              //   $("#pass").focus();
              //   return;
              // }
              // else {
                this.isForgetting = false
                this.alertService.sweetMessage('error',data.responseMessage);
                this.userData.OldNewpassword = '';
                this.userData.newPassword = '';
              // }

						}
					},
					error =>
            { this.isForgetting = false; this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
				);
			}
		}
	}

    // Getting Login Data
  getLoginData(){
    var url = this.apiService.appUrl;
      this.apiService.getLoginDatawithoutToken('client/getClientByDomain?domain='+url).subscribe(
        data => {
          if(data.statusCode == 100){
            this.loginPageContentData=data.response.loginPageContent;
            if(data.response.logoFile){
              this.imageFile = data.response.logoFile;
            }else{
              this.imageFile = "assets/img/default_logo.png";
              // this.logoClass = true;
            }
            // Loader Image for client
            let loaderImg = data.response.loaderImg;
            localStorage.setItem("loaderImg", loaderImg);
            document.getElementById('imageData').setAttribute( 'src',data.response.logoFile ? this.apiService.imageBasePath+this.imageFile :this.imageFile );
        }else{
          this.imageFile = "assets/img/default_logo.png";
          localStorage.removeItem("loaderImg");
          
          document.getElementById('imageData').setAttribute( 'src',this.imageFile );
        }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
    }

}
