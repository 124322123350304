import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxDropDownButtonComponent } from '../../jqwidgets-ts/angular_jqxdropdownbutton';
import { jqxTreeComponent } from '../../jqwidgets-ts/angular_jqxtree';



@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  clientId: any = localStorage.getItem("clientId");
  userId: any   = localStorage.getItem("userId");
  customerId:any = (localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  dataTable: any;
  state:any ='';
  countryList:any  = [];
  countryId:number = 0;

  dropdownSettingsSite  = {}
  dropdownSettingsAsset = {};
  dropdownSettingsTier  = {};

  permissionData:any = [];
  ticketsList:any    = [];
  recipientList:any  = [];

  checkTierArray:any  = [];
  checkSiteArray:any  = [];
  checkAssetArray:any = [];

  tierDatalist:any   = [];
  siteDatalist:any   = [];
  assetDatalist:any  = [];

  selectedSites : any = [];
  TiersItem : any = [];

  clientCode : any   = '';
  isError : any;
  ticketId:number = 0;
  tempObj : any;
  ticket : any = {
                 	"reporter":{
                 		"userId":this.userId
                 	},
                 	"note":"",
                 	"asset":{
                 		"assetId":""
                 	},
                 	"assignedUser":{
                 		"userId":""
                 	}
                }
    checkTreeArray = []
    disableTreeArray = []
    data:any = [];
    source:any = {}
    dataAdapter: any = [];
    records: any = [];
  HistoryList: any = [];


  constructor(
    private router:Router,
    private apiService: ApiService ,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef
  ) {
      this.permissionData = ApiService.permission;
    }


  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.getAllTierList();
    this.getTicketsList();
    this.getRecipientList();

    // this.dropdownSettingsState = {
    //   singleSelection: false,
    //   idField: 'stateId',
    //   textField: 'stateName',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 0,
    //   allowSearchFilter: true
    // };

  // For Asset Drop Down
      this.dropdownSettingsAsset = {
          singleSelection: false,
          idField: 'assetId',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 0,
          allowSearchFilter: true
      };

  // For Tier Drop Down
      this.dropdownSettingsTier = {
          singleSelection: false,
          idField: 'tierId',
          textField: 'tierName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 0,
          allowSearchFilter: true
      };

  // For Tier Site Down
      this.dropdownSettingsSite = {
          singleSelection: false,
          idField: 'siteId',
          textField: 'siteName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 0,
          allowSearchFilter: true
      };

  }

/*********************************
*  Create all Methods Below
**********************************/

/* Get Country list */
getCountryList(){
   this.apiService.getAll('country/getCountry').subscribe(
       data => {
         if(data.statusCode == 100){
            this.countryList = data.responseList;

          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
           }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}

getRecipientList () {
  this.apiService.getAll('user/getAssignedUsersBehalfAsset?userId='+this.userId).subscribe(
    data => {
      if(data.statusCode == 100){
          if(data.userList)
              {
                this.recipientList = data.userList;
              }
         }else{
           this.alertService.sweetMessage('error',data.responseMessage);
          }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}

/* Get Tickets List */
getTicketsList() {
  // Destroy Data Table if is already and clear the data
  if ($.fn.DataTable.isDataTable('#ticket_table')){
    $('#ticket_table').DataTable().destroy();
    this.ticketsList=[];
  }
  var URL = 'ticket/getTicketByClientId?clientId='+this.clientId+'&userId='+this.userId

  // console.log(this.customerId,this.tierId);
  
  if(this.customerId != null && this.customerId !=''){
    var customerString = '&customerId='+this.customerId.toString();
    URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierIdString = '&tierId='+this.tierId.toString();
    URL = URL.concat(tierIdString);
  }

this.apiService.getAll(URL).subscribe(
  data => {
    if(data.statusCode == 100){
      this.ticketsList = data.responseList;

      this.chRef.detectChanges();

      const table: any = $('#ticket_table');
      this.dataTable   = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 3 },{ "orderable": false, "targets": 4 }]});

    }else{
      // Destroy Data Table if is already and clear the data
      if ($.fn.DataTable.isDataTable('#ticket_table')){
        $('#ticket_table').DataTable().destroy();
        this.ticketsList=[];
      }
      const table: any = $('#ticket_table');
      this.dataTable   = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 3 },{ "orderable": false, "targets": 4 }]});

      this.alertService.sweetMessage('error',data.responseMessage);
    }
  },
  error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
     }
 );
}


/***********************
* Get All Tier List
***********************/
getAllTierList() {
    // var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
    var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100){ 
            this.tierDatalist = data.responseList; 
          //   var self = this;

          //   Object.keys(this.data).forEach(key => {
          //       if (this.data[key]['flag'] == false) {
          //           this.disableTreeArray.push(this.data[key]['tierId']);
          //       }
          //   });
          //   this.source = {
          //       datatype: 'json',
          //       datafields: [{
          //           name: 'tierId'
          //       },
          //       {
          //           name: 'parentTierId'
          //       },
          //       {
          //           name: 'tierName'
          //       },
          //       ],
          //       id: 'tierId',
          //       localdata: this.data
          //   };
          //   this.dataAdapter = new jqx.dataAdapter(this.source, {
          //       autoBind: true
          //   });
          //   this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{
          //       name: 'tierName',
          //       map: 'label'
          //   }, {
          //       name: 'tierId',
          //       map: 'id'
          //   }]);
               
          //   setTimeout(function() {
          //       self.treeItemDisable();
          //       self.checkedCheckboxOnLoad();
          //   }, 3000);

          //   if (this.tierDatalist.length == 0) {
          //     let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"><span class="textRight">No data available </span></div>';
          // this.myDropDownButton.setContent(dropDownContent);
          //   }
          }
        else
        { this.tierDatalist = []; }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
}


    /* Handle tier tree chacked unchecked Event */
    CheckChange(event) {
      if (event.args.checked == true) {
          var exist = this.checkTreeArray.indexOf(event.args.element.id);
          if (exist == -1) {
              this.checkTreeArray.push(event.args.element.id);
              localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
          }
      } else if (event.args.checked == false) {
          var exist = this.checkTreeArray.indexOf(event.args.element.id);
          if (exist != -1) {
              this.checkTreeArray.splice(exist, 1);
              localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
          }
      }
      if (this.checkTreeArray.length > 0) {
          let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"><span class="textRight">+' + this.checkTreeArray.length + ' </span></div>';
          this.myDropDownButton.setContent(dropDownContent);
      } else {
          let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
          this.myDropDownButton.setContent(dropDownContent);
      }

  }


    /* check tree checkbox */
    checkedCheckboxOnLoad() {
      Object.keys(this.checkTreeArray).forEach(key => {
          let checkedItem = document.getElementById(this.checkTreeArray[key]);
          this.myTree.expandItem(checkedItem);
          this.myTree.checkItem(checkedItem, true);
      });
  }


  treeItemDisable() {
    Object.keys(this.disableTreeArray).forEach(key => {
        let checkedItem = document.getElementById(this.disableTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.disableItem(checkedItem);
    });
}

  /*******************************************
        Event Handling On Select Tier
  *******************************************/

  /* On Item Select Tier */
  onItemSelectTier(item: any) {

    var exist = this.checkTierArray.indexOf(item.tierId.toString());

    if (exist == '-1') {

        this.checkTierArray.push(item.tierId.toString());

        var URL = 'site/getSiteByClient?tierId=' + this.checkTierArray + '&clientId=' + this.clientId +'&userId='+this.userId;

        this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100)
                { this.siteDatalist = data.responseList; }
              else
                { this.siteDatalist = []; }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }

        );
    }
  }


  /* On Item DeSelect Tier */
  onItemDeselectTier(item: any) {
      var exist = this.checkTierArray.indexOf(item.tierId.toString());
      if (exist != '-1') {
          this.checkTierArray.splice(exist, 1);
          // console.log(this.checkTierArray);
          //
          // Object.keys(this.checkTierArray).forEach(key => {
          //   console.log({"tierId":this.checkTierArray[key]});
          //   this.onItemSelectTier({"tierId":this.checkTierArray[key]})
          // })

           if(this.checkTierArray.length == 0) {
            this.siteDatalist = [];
            this.selectedSites = [];

          }
      }
  }

  /* On All Select Tier */
  onSelectAllTier(items: any) {
      this.checkTierArray = [];
      Object.keys(items).forEach(key => {
          this.checkTierArray.push(items[key]['tierId'].toString());
      })
          var URL = 'site/getSiteByClient?tierId=' + this.checkTierArray + '&clientId=' + this.clientId +'&userId='+this.userId;

          // 'asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId +'&tierId='+this.checkTierArray

          this.apiService.getAll(URL).subscribe(
              data => {
                if (data.statusCode == 100)
                  { this.siteDatalist = data.responseList; }
                else
                  { this.siteDatalist = []; }
              },
              error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
          );
      // });
  }

  /* On All DeSelect Tier */
  onDeSelectAllTier() {
      this.checkTierArray = [];
      this.siteDatalist   = [];
  }



/*******************************************
      Event Handling On Select Site
*******************************************/

  /* On Item Select Site */
  onItemSelectSite(item: any) {

      var exist     = this.checkSiteArray.indexOf(item.siteId.toString());

      if (exist == '-1') {

        this.checkSiteArray.push(item.siteId.toString());

      //   var URL = 'asset/getAssetByClient?clientId=' + this.clientId + '&userId='+this.userId + '&tierId=' + this.checkTierArray + '&siteId='+this.checkSiteArray ;

      //     this.apiService.getAll(URL).subscribe(
      //       data => {
      //         if (data.statusCode == 100)
      //           { this.assetDatalist = data.responseList; }
      //         else
      //           { this.assetDatalist = []; }
      //       },
      //  error =>{
      //          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      //        }
      //     );
      }
      setTimeout(() => {
        console.log(this.selectedSites, "==========1==================");
        this.getAssetBySite()
        }, 1000);
  }

  /* On Item DeSelect Site */
  onItemDeselectSite(item: any) {
      var exist = this.checkSiteArray.indexOf(item.siteId.toString());
      if (exist != '-1') {
          this.checkSiteArray.splice(exist, 1);
          if(this.checkSiteArray.length == 0) {
            this.assetDatalist = [];
          }
      }
      // setTimeout(() => {
      //   console.log(this.selectedSites, "==========1==================");
        this.getAssetBySite()
          
        // }, 1000);
      // this.getAssetBySite();
  }


  /* On All Select Tier */
  onSelectAllSite(items: any) {
      this.checkSiteArray = [];
      console.log(this.selectedSites, "============================");
      setTimeout(() => {
      console.log(this.selectedSites, "==========1==================");
      this.getAssetBySite()
        
      }, 1000);
      Object.keys(items).forEach(key => {
        // console.log(items[key]);
        // this.checkTierArray.push(items[key]['tierId'].toString());
          this.checkSiteArray.push(items[key]['siteId'].toString());
        });
        // console.log(this.checkSiteArray  );
  }

    /* On All DeSelect Site */
    onDeSelectAllSite() {
        this.assetDatalist = [];
        this.selectedSites = [];
        this.getAssetBySite()
    }

    getAssetBySite(){
      let siteIdArr = [];
      this.selectedSites.map((obj,index)=>{
        siteIdArr.push(obj.siteId)
      })
      let urlstr = 'asset/getAssetDropDownByClient'
      
      var URL = 'asset/getAssetByClient?clientId=' + this.clientId + '&userId='+this.userId + '&tierId=' + this.checkTierArray + (siteIdArr.length ? '&siteId='+siteIdArr.toString() : '');
      let self = this
      setTimeout(() => {
        console.log(
          siteIdArr
        );
        let obj1 = {
          "clientId": this.clientId,
          "userId": this.userId,
          "siteId": siteIdArr.toString(),
          "tierId": this.checkTierArray.toString()
        }
        self.apiService.create(urlstr, obj1).subscribe(
          data => {
            if (data.statusCode == 100)
              { this.assetDatalist = data.responseList; }
            else
              { this.assetDatalist = []; }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
        );
        
      }, 2000);
    }




/*******************************************
      Event Handling On Select Asset
*******************************************/

/* On Select single Asset */
onItemSelectAsset (items: any) {
  var exist = this.checkAssetArray.indexOf(items.assetId.toString());
  if (exist == '-1') {
    this.checkAssetArray.push(items.assetId.toString());
  }
}


/* Add New Ticket */

addTicket (formData) {
  if(!formData.invalid){
    var note = $('#note').children("option:selected").val()
    var assignedUser = $('#assignedUser').text();
    // console.log(this.ticket.assignedUser.userId);
    // Deep copy
    var newObject = jQuery.extend(true, {}, this.ticket);
    // console.log(newObject);
    // console.log(newObject.note);
    if(this.ticket.assignedUser.userId == '' && newObject.note == ''){
      $('#recipient_err').css('display', 'block');
      $('#note_err').css('display', 'block');
      return;
    } else {
      $('#recipient_err').css('display', 'none');
      $('#note_err').css('display', 'none');
    }

    if(this.ticket.assignedUser.userId === ''){
      $('#recipient_err').css('display', 'block');
      return;
    } else {
      $('#recipient_err').css('display', 'none');
    }

    if(newObject.note == ''){
      $('#note_err').css('display', 'block');
      return;
    }else{
      $('#note_err').css('display', 'none');
    }

    if(this.ticket.asset.assetId == ""){
      newObject.asset = null;
    }

    // if(this.ticket.assignedUser.userId == "") {
    //   newObject.assignedUser = null;
    // }

    if(newObject.assignedUser && newObject.assignedUser.userId == 0){
      newObject.assignedUser.userId = 0;
    }

    if(!this.ticketId){
      this.apiService.create('ticket/addTicket' , newObject).subscribe(
        data => {
          if (data.statusCode == 100)
            {
              $('#closepopup').click();
              this.getTicketsList();
              this.isError = false;
              this.alertService.sweetMessage('success', 'Ticket added Successfully');
              this.ticket = { "reporter":{ "userId":this.userId },
                              "note":"", "asset":{ "assetId":"" },
                              "assignedUser":{ "userId":"" }
                            }
            }
          else
          {
            //this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
      );
    }
    else{
      formData.submitted = false; 
      newObject.ticketId = this.ticketId;
      this.apiService.update('ticket/updateTicket' , newObject).subscribe(
        data => {
          if (data.statusCode == 100)
            {
              $('#closepopup').click();
              this.getTicketsList();
              // console.clear();
              this.alertService.sweetMessage('success', 'Ticket updated Successfully');
              this.ticket = { "reporter":{ "userId":this.userId }, "note":"",
              "asset":{ "assetId":"" },"assignedUser":{ "userId":"" } }
              this.TiersItem     = [];
              this.selectedSites = [];

            }
          else
            { this.alertService.sweetMessage('error', data.responseMessage); }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
      );
    }
  }
}


resetForm (f) {
  f.submitted = false; 
  this.ticketId = 0;
  this.ticket = { "reporter":{ "userId":this.userId }, "note":"",
  "asset":{ "assetId":"" },"assignedUser":{ "userId":"" } }
  this.TiersItem     = [];
  this.selectedSites = [];
  this.assetDatalist = [];
  this.siteDatalist  = [];
  $('#recipient_err').css('display', 'none');
  $('#note_err').css('display', 'none');

}

getTicketsById (ticketId) {
  $('#recipient_err').css('display', 'none');
  $('#note_err').css('display', 'none');
  this.ticketId = ticketId;
  this.apiService.getAll('ticket/getTicketDetailById?ticketId='+ticketId).subscribe(
    data => {
      if (data.statusCode == 100) {

        this.ticket = {
          "reporter":{
            "userId":this.userId
          },
          "note": data.response.note || "",
          "asset": data.response.asset || { "assetId":"" },
          "assignedUser":data.response.assignedUser || { "userId":"" }
        }

          // this.ticket = data.response;

          this.TiersItem     = data.response.tiers;
          this.selectedSites = data.response.sites;
          if(data.response.tiers.length > 0 || data.response.sites.length > 0){
            this.onItemSelectTier({"tierId":data.response.tiers[0].tierId})
            this.onItemSelectSite({"siteId":data.response.sites[0].siteId})
          }
      }
      else
        { this.alertService.sweetMessage('error', data.responseMessage); }
    },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
  );
}


/* Delete Customer Management list */
deleteTicket(ticketId){
     Swal({
      title: 'Are you sure?',
      text: "You want to delete this Ticket.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
    if (result.value) {
     this.apiService.delete('ticket/deleteTicket?ticketId='+ticketId, "").subscribe(
     data => {
         if(data.statusCode == 100){
           this.alertService.sweetMessage('success',"Ticket Deleted Successfully");
           this.getTicketsList();

          }else{
           this.alertService.sweetMessage('error',data.responseMessage);
           }
        },
       error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }

          );
        }
    })
}



getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('ticket/getTicketHistoryByTicketId?ticketId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.response;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}
DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}
}
