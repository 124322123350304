import { Component, OnInit ,ChangeDetectorRef,ViewChild, ChangeDetectionStrategy, TemplateRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash"
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent,CalendarEventAction,CalendarEventTimesChangedEvent,CalendarView  } from 'angular-calendar';



@Component({
  selector: 'app-team-schedule',
  templateUrl: './team-schedule.component.html',
  styleUrls: ['./team-schedule.component.css']
})
export class TeamScheduleComponent implements OnInit {

 clientId: any = localStorage.getItem("clientId");
 userId: any = localStorage.getItem("userId");
  shiftGroupName:any=(localStorage.getItem("shiftGroupNameData")?JSON.parse(localStorage.getItem("shiftGroupNameData")):[]);
  defaultShiftGroup:any="";
  selectMonth:any= moment().format("MMMM");
  EventListData:any = [];
  createdDate:any;
  shiftgroupId: any = 0;
  shiftGroupData:any=[];
  callAssetData:any=[];
  assetId:any = 0;
  timeBlock:any = [];
  EventDateData:any=[];
  userListEdit:any = [];
  smsShow:boolean = true;
  emailShow:boolean = true;
  smsData:any=[];
  emailData:any = [];
  selectDate:any="";
  eventDate:any=moment().format("YYYY-MM-DD");
  NewUser:any = {
    callOrder:1,
    user:{userId:''}
  };
  OldUser:any= {
    callOrder:'',
    user:{userId:''},
    shiftCallOrderId:'',
    shift:{shiftId:''}
  };
  userName:any="";
    clientCode : any = '';
  permissionData:any=[];
  shiftId:any="";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private modal: NgbModal
    ) {
    this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
      return this.formatDateService.formatDate(startDate, gmtOffset);
  }

ngOnInit() {

    this.clientCode = this.alertService.getClientCode();
    this.getShiftGroupList();
    jQuery('.rst-date').datetimepicker({
          language: 'en',
          pickTime: false,
          pickDate: true,
          minDate: 0,
          inline: true,
          sideBySide: true
      });

    this.eventDate = moment().format("YYYY-MM-DD");
    setTimeout(()=>{
        this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
        this.getDayData(this.eventDate);
     }, 100);
  }


 getShiftGroupId(shiftId){
  if($("#shiftGroup :selected").text()){
    this.shiftGroupName = $("#shiftGroup :selected").text();
  }
  this.shiftgroupId = shiftId;
  this.selectMonth = moment().format("MMMM");
  this.getShiftGroupData(moment().startOf(this.view).format("YYYY-MM-DD"),moment().endOf(this.view).format("YYYY-MM-DD"));
  this.getDayData(this.eventDate);
 }

getAssetCallData(shiftId,data){

  var URL = 'schedule/getTeamScheduleDaySummary?userId='+this.userId+'&date='+this.eventDate+'&type=UserByShift&commMode=Call&shiftGroupId='+this.shiftgroupId+'&shiftId='+shiftId;
    this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.callAssetData = data.responseList;
              } else {
                  this.callAssetData = [];
              }
          },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
}

getSmsData(){

  var URL = 'schedule/getTeamScheduleDaySummary?userId='+this.userId+'&date='+this.eventDate+'&type=AssetByShift&commMode=SMS&shiftGroupId='+this.shiftgroupId;
  this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.smsData = data.responseList;
              } else {
                  this.smsData = [];
              }
          },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
}

getEmailData(){
  
  var URL = 'schedule/getTeamScheduleDaySummary?userId='+this.userId+'&date='+this.eventDate+'&type=AssetByShift&commMode=Email&shiftGroupId='+this.shiftgroupId;
  this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.emailData = data.responseList;
              } else {
                  this.emailData = [];
              }
          },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
}
  /* Function for getting Roster dashboard data by company */
  getShiftGroupData(start,end) {
      var URL = 'schedule/getShedulebMobileTeamMonthView?endDate='+end+'&userId='+this.userId+'&shiftGroupId='+this.shiftgroupId+'&startDate='+start;
      this.EventDateData = [];
      this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.timeBlock = data.responseList;
                  let Arr = [];
                  let idArr = []
                    this.timeBlock.map((schedule,index)=>{
                          let obj = {
                            start: new Date(schedule.date),
                            title: schedule.day,
                            email:schedule.email,
                            sms:schedule.sms,
                            sheduleCount:(schedule.call!= '-1' ? Array(schedule.call).fill(0).map((x,i)=>i):0),
                            colorSms: {primary:"#f99c9c73"},
                            colorEmail: {primary:"#2d84a552"},
                          }
                            Arr.push(obj);

                      })
                  this.events = Arr;
                  this.refresh.next()
              } else {
                  this.timeBlock = [];
              }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }

// Calendar Events
  getDayData(eventDate){

    var URL = 'schedule/getTeamScheduleDaySummary?userId='+this.userId+'&date='+this.eventDate+'&type=ScheduleByDay&shiftGroupId='+this.shiftgroupId;
    this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.EventDateData = data.responseList;
              } else {
                  this.EventDateData = [];
              }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }


getShiftGroupList() {
      this.apiService.getAll('shiftGroup/getShiftGroupByClient?clientId=' + this.clientId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.shiftGroupData = data.responseList;
                  this.defaultShiftGroup = this.shiftGroupData[0]['shiftGroupId'];
                  this.shiftGroupName = this.shiftGroupData[0]['groupName'];
                  this.getShiftGroupId(this.defaultShiftGroup);
              } else {
                  this.defaultShiftGroup = "";
                  this.shiftGroupData = [];
              }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }

 @ViewChild('modalContent') modalContent: TemplateRef<any>;

view: CalendarView = CalendarView.Month;

CalendarView = CalendarView;

viewDate: Date = new Date();

modalData: {
  action: string;
  event: CalendarEvent;
};

/*actions: CalendarEventAction[] = [
  {
    label: '<i class="fa fa-fw fa-pencil"></i>',
    onClick: ({ event }: { event: CalendarEvent }): void => {
      }
  },
];
*/
refresh: Subject<any> = new Subject();

events: CalendarEvent[] = [];

activeDayIsOpen: boolean = true;


dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
      let val = _.filter(this.events, function(o) {

          return moment(o.start).format("YYYY-MM-DD")== moment(date).format("YYYY-MM-DD")
      });
    this.EventListData = val;
    this.getDayEventList(this.EventListData);
  if (isSameMonth(date, this.viewDate)) {
    this.viewDate = date;
    if (
      (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
    }
  }
}

ChangeEvent(date){
  if(this.shiftgroupId){
    this.selectMonth = moment(date).format("MMMM");
    this.getShiftGroupData(moment(date).startOf(this.view).format("YYYY-MM-DD"),moment(date).endOf(this.view).format("YYYY-MM-DD"));
  }else{
  this.alertService.sweetMessage('error','Please Select Shift Group');
  }
}

eventTimesChanged({event,newStart,newEnd}: CalendarEventTimesChangedEvent): void {
  event.start = newStart;
  event.end = newEnd;
  this.handleEvent('Dropped or resized', event);
  this.refresh.next();
}

handleEvent(action: string, event: any): void {
  this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
}
handleEventDay(action: string, event: any): void {
    this.modalData = { event, action };
  }

addEvent(): void {
  this.refresh.next();
}

getDayEventList(EventListData){
  if(EventListData[0].start){
     this.eventDate = moment(EventListData[0].start).format("YYYY-MM-DD");
     this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
     this.getDayData(this.eventDate);
    }
  }

selectPreDate(){
  this.eventDate =moment(this.eventDate).subtract(1, 'days').format("YYYY-MM-DD")
  this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
  this.getDayData(this.eventDate);
}

selectNextDate(){
  this.eventDate =moment(this.eventDate).add(1, 'days').format("YYYY-MM-DD")
  this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
  this.getDayData(this.eventDate);
}

}
