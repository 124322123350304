import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './structure/login/login.component';
import { ForgotPasswordComponent } from './structure/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './structure/reset-password/reset-password.component';
import { UserActiveComponent } from './structure/user-activation/user-activation.component';
import { EventResolve } from './services/event.resolve.service';
import { LoginadminComponent } from './structure/loginadmin/loginadmin.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent /* , resolve :{event:EventResolve} */ },//, resolve :{event:EventResolve},
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { path: 'impersonateuser', component: LoginadminComponent },
  { path: 'user-activation', component: UserActiveComponent },
  { path: 'user-activation/:id', component: UserActiveComponent }

  /*{ path: '**', redirectTo: '/login' }*/
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


