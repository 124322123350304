import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-team-time-off-request',
  templateUrl: './team-time-off-request.component.html',
  styleUrls: ['./team-time-off-request.component.css']
})
export class TeamTimeOffRequestComponent implements OnInit {

  clientId: any   = localStorage.getItem("clientId");
  userId    : any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId    : any = (localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  dataTable : any;
  clientCode: any;
  leaveId   : any = 0;
  isApproved : any = false;
  LeaveList : any = [];
  LeaveListById : any = [];
  permissionData : any;
  userDropDown : any = [];
  userLeaveId : any = 0;
  isError : any = false;
  leaveData : any = {
    "user"    : {"userId":''},
    "fromDate": "",
    "toDate"  : "",
    "remark"  : ""
  }
  constructor(
    private router      : Router,
    private apiService  : ApiService ,
    private alertService: AlertService,
    private chRef       : ChangeDetectorRef
  ) {
    this.permissionData = ApiService.permission;
  }


  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.getUserDropDown();
    this.getLeaveList();
    jQuery('.rst-date').datetimepicker({
      language: 'en',
      pickTime: false,
      pickDate: true,
      minDate: 0,
      inline: true,
      sideBySide: true
  }).on('changeDate', function(){
    $('.bootstrap-datetimepicker-widget').hide();
    $(this).datepicker('hide');
  });
  }

  getUserDropDown(){
    var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+'&isActive=true&isDeactivated=false';

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
             this.userDropDown = data.userList;
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

// Get all Leave Applied List
  getLeaveList(){
    var URL = 'timeOffRequest/getTimeOffRequestList?userId='+this.userId;

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {

          this.LeaveList = data.responseList;

          if ( $.fn.DataTable.isDataTable('#timeOff_table') ) {
            $('#timeOff_table').DataTable().destroy();
          }

          this.chRef.detectChanges();
          const table: any = $('#timeOff_table');
          this.dataTable = table.DataTable();
        }
        else
          {
            this.LeaveList = [];

            if ( $.fn.DataTable.isDataTable('#timeOff_table') ) {
              $('#timeOff_table').DataTable().destroy();
            }

            this.chRef.detectChanges();
            const table: any = $('#timeOff_table');
            this.dataTable = table.DataTable();
            // this.alertService.sweetMessage('error', data.responseMessage);
          }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

  resetForm(){
    this.userLeaveId = 0;
    this.leaveId = 0;
    this.isApproved = false;
    var note      = $('#note').val('')
    var fromDate  = $('#fromDate').val();
    var toDate    = $('#toDate').val();
    this.leaveData   = {
      "user"    : {"userId":''},
      "fromDate": moment().format("YYYY-MM-DD"),
      "toDate"  : moment().format("YYYY-MM-DD"),
      "remark"  : ""
    }
  }

/*Get Leave Data By Id*/
  getDataByID(id){

    this.leaveId = id;
    var URL = 'timeOffRequest/getTimeOffRequest?requestId='+id;

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
             this.LeaveListById   = data.response;
             this.leaveData.user.userId  = this.LeaveListById.user.userId;
             this.isApproved = this.LeaveListById.isApproved;
             if(this.isApproved == true){
               // $('.rst-date').attr('readonly','readonly');
               // $(".rst-date").datepicker({'disable' : 'true'});
               // $(".datepicker").disableSelection = true;
               // $('.rst-date').datepicker({"options":{"pickDate" : 'false'}});
               // $('.rst-date').datepicker("option", "maxDate", -2);
             }
             var note      = $('#note').val(this.LeaveListById.remark)
             var fromDate  = $('#fromDate').val(this.LeaveListById.fromDate);
             var toDate    = $('#toDate').val(this.LeaveListById.toDate);
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

/* Delete Leave Data By Id*/
  RemoveLeave(id){

    this.leaveId = id;
    var URL = 'timeOffRequest/deleteTimeOffRequest?timeOffRequestId='+id;

    this.apiService.delete(URL, "").subscribe(
      data => {
        if (data.statusCode == 100) {
          this.getLeaveList();
          this.alertService.sweetMessage('success', 'Leave deleted successfully');
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }

  applyLeave(){

    if(this.leaveId == 0){

      var URL = 'timeOffRequest/createTimeOffRequest';

      var note      = $('#note').val()
      var fromDate  = $('#fromDate').val();
      var toDate    = $('#toDate').val();

      this.leaveData.fromDate = fromDate;
      this.leaveData.toDate   = toDate;
      this.leaveData.remark   = note;

      this.apiService.create(URL, this.leaveData).subscribe(
        data => {
          if (data.statusCode == 100) {
              $('#closepopup').click();
              this.getLeaveList();
              this.alertService.sweetMessage('success', 'Leave apply successfully');
          }
          else
            { this.alertService.sweetMessage('error', data.responseMessage); }
        },
         error =>
         { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    }else {

      var URL = 'timeOffRequest/updateTimeOffRequest';

      var note      = $('#note').val()
      var fromDate  = $('#fromDate').val();
      var toDate    = $('#toDate').val();

      this.leaveData.fromDate = fromDate;
      this.leaveData.toDate   = toDate;
      this.leaveData.remark   = note;
      this.leaveData.timeOffRequestId   = this.leaveId;

      this.apiService.update(URL, this.leaveData).subscribe(
        data => {
          if (data.statusCode == 100) {
              $('#closepopup').click();
              this.getLeaveList();
              this.alertService.sweetMessage('success', 'Leave Updated successfully');
          }
          else
            { this.alertService.sweetMessage('error', data.responseMessage); }
        },
         error =>
         { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );

    }


  }

  approveLeave(timeofid,){

    var URl = 'timeOffRequest/leaveApproved';
    var obj = {
    	"timeOffRequestId":timeofid,
    	"user":{
    		"userId":16
    	},
    	"isApproved":true
    }
    this.apiService.update(URL, obj).subscribe(
      data => {
        if (data.statusCode == 100) {
            $('#closepopup').click();
            this.getLeaveList();
            this.alertService.sweetMessage('success', 'Leave Updated successfully');
        }
        else
          { this.alertService.sweetMessage('error', data.responseMessage); }
      },
       error =>
       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );

  }



}
