import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, FormatDateService } from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $: any;
declare var jQuery: any;
declare var d3: any, moment: any;
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'


@Component({
  selector: 'app-in-app-notifcation',
  templateUrl: './in-app-notifcation.component.html',
  styleUrls: ['./in-app-notifcation.component.css']
})
export class InAppNotifcationComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;


  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId: any = []
  tierId: any = []
  assetId: number = 0;
  dataTable: any;
  site: any = '';
  assetDatalist: any = [];
  shiftGroupList: any = [];
  assetsumaryData: any = [];
  assetList: any = [];
  siteList: any = [];
  siteLat: number = 39.381266;
  siteLong: number = -97.922211;
  dataAdapter: any = [];
  tierListData: any = [];
  tierArray: any = [];
  data: any = [];
  userassetList: any = [];
  checkTreeArray: any = [];
  disableTreeArray: any = [];
  records: any = [];
  source: any = {};
  assetArray: any = [];
  finalAssetArray: any = [];
  copyData: any = { "assetId": "", "assets": [] };
  client: any = {
    "assetId": "",
    "clientRequest": {
      "clientId": ""
    },
    "shiftGroup": {
      "shiftGroupId": ""
    },
    "site": {
      "siteId": ""
    },
    "scheduleAssetId": ""
  }
  clearsite: any = [];
  clientCode: any = '';
  shiftGroupId: any = '';
  permissionData: any = [];

  dropdownSettingsSite = {
    singleSelection: false,
    idField: 'siteId',
    textField: 'siteName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };
  breadcrumbObj: any;
  urlCustomerId: any;
  CdName: any;

  assetDetails: any = {
    newStatus: 'Active',
    clearNotification: ''
  };
  customerList: any = [];
  notificationList: any = [];
  adminnotificationList: any = [];
  assetStatusType: any = [];
  actionFor: any = '';
  rcpTableModalData: any = '';
  rcpTableModalHead: any = '';
  inAppSetting: any = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private chRef: ChangeDetectorRef,

  ) {
    this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset) {
    if (startDate) {
      var formattedDate = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.assetId = params['id'];
      }
    });

    if (this.assetId) {
      /*  this.getSIteListDataForDropdown();
       this.getAssetSummaryData();
       this.getTierList();
       this.getUserByAsset();
       setTimeout(()=>{
           this.getAllAssetList();
       }, 3000); */
      // this.getAssetDetail() ;
      this.getAssetSummaryData();

    } this.route
      .queryParams
      .subscribe(params => {
        this.breadcrumbObj = params['source'];
        this.urlCustomerId = params.cus_id;
        this.CdName = params.cusD_name;
      })
  }

  /* Function for getting Asset Summary Data */
  getAssetSummaryData() {
    this.apiService.getAll('asset/getAssetInAppNotificationStatusById?assetId=' + this.assetId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.inAppSetting = data.response.inAppEnable;
          console.log('this.inAppSetting---',this.inAppSetting)
        } else {
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  submitInApp(formData) {
    if (formData.invalid) {
      return;
    }
    let reqObj: any = {
      "assetId": this.assetId,
      "inAppEnable": (this.inAppSetting.toString() == 'true' ? 1 : 0)
    }
    var URL = 'asset/assetInAppNotificationSetting';
    this.apiService.create(URL, reqObj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.alertService.sweetMessage('success', data.responseMessage);
        }else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }





  rcpTable(data) {
    if (data == 'Unit Number') {
      let arr = [];
      this.assetDetails.assetChannels.map(data => {
        if (data.unitNumber) {
          arr.push(data.unitNumber);
        }
      });
      return arr.toString();
    } else if (data == 'Data Point Number') {
      let arr = [];
      this.assetDetails.assetChannels.map(data => {
        if (data.channelNumber) {
          arr.push(data.channelNumber);
        }
      });
      return arr.toString();
    } else if (data == 'Data Point Description') {
      let arr = [];
      this.assetDetails.assetChannels.map(data => {
        if (data.channelDescription) {
          arr.push(data.channelDescription);
        }
      });
      return arr.toString();
    }
  }
  rcpTableModel(data) {
    this.rcpTableModalData = '';
    this.rcpTableModalHead = '';
    if (data == 'Unit Number') {
      let arr = [];
      this.assetDetails.assetChannels.map(data => {
        if (data.unitNumber) {
          arr.push(data.unitNumber);
        }
      });
      this.rcpTableModalHead = 'Unit Number';
      this.rcpTableModalData = arr.toString();
    } else if (data == 'Data Point Number') {
      let arr = [];
      this.assetDetails.assetChannels.map(data => {
        if (data.channelNumber) {
          arr.push(data.channelNumber);
        }
      });
      this.rcpTableModalHead = 'Data Point Number';
      this.rcpTableModalData = arr.toString();
    } else if (data == 'Data Point Description') {
      let arr = [];
      this.assetDetails.assetChannels.map(data => {
        if (data.channelDescription) {
          arr.push(data.channelDescription);
        }
      });
      this.rcpTableModalHead = 'Data Point Description';
      this.rcpTableModalData = arr.toString();
    }
  }
  getCustomerList() {
    var URL = 'customer/getCustomerDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId + '&alphabetSortingOrder=1';
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.customerList = []
          this.customerList = data.responseList ? data.responseList : {};
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getNotificationList() {
    var URL = 'notification_plan/getNotificationPlanByTierORisGlobalNp?clientId=' + this.clientId + '&assetId=' + this.assetId + '&alphabetSortingOrder=1';
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.notificationList = []
          this.notificationList = data.responseList ? data.responseList : {};
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getAdminNotificationList() {
    var URL = 'notification_plan/getadminNotificationPlanDropDownList?clientId=' + this.clientId + '&assetId=' + this.assetId + '&alphabetSortingOrder=1';
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.adminnotificationList = []
          this.adminnotificationList = data.responseList ? data.responseList : [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getAssetStatusTypeList() {
    var URL = 'asset/getAssetStatusProcess';
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetStatusType = []
          let self = this;
          this.assetStatusType = data.responseList ? data.responseList : [];

          let statusData: any = [];
          if (this.assetDetails.assetStatus == 'active') {
            this.assetStatusType.map(item => {
              if (item.processId == 2) {
                statusData.push(item);
              }
            })
          }

          if (this.assetDetails.assetStatus == 'deactivated') {
            this.assetStatusType.map(item => {
              if (item.processId == 1) {
                statusData.push(item);
              }
            })
          }

          this.assetStatusType = statusData

        }


      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }




  getAssetDetail() {
    var URL = 'asset/getAssetSummaryById?clientId=' + this.clientId + '&userId=' + this.userId + '&assetId=' + this.assetId;
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetDetails = {};
          this.assetDetails = data.response ? data.response : {};
          this.actionFor = '';
          this.getCustomerList();
          this.getNotificationList();
          this.getAdminNotificationList();
          this.getAssetStatusTypeList();
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  submitDeActivation(formData) {
    if (formData.invalid) {
      return;
    }
    let reqObj: any = {
      "comment": this.assetDetails.comment ? this.assetDetails.comment : '',
      "assetId": this.assetId,
      "description": this.assetDetails.description,
      "clientname": this.assetDetails.clientName,
      "manufacturerSerialNumber": this.assetDetails.manufacturSererialNumber,
      "siteOrVehicleName": this.assetDetails.site.siteName,
      "assetDescription": this.assetDetails.assetDescription,
      "typeApprovalNumber": this.assetDetails.typeApprovalNumber,
      "isIDPSite": this.assetDetails.isIDPSite,
      "isCellSite": this.assetDetails.isCellSite,
      "site": {
        "siteId": this.assetDetails.site.siteId,
        "siteName": this.assetDetails.site.siteName
      },
      "clearNotificationPlan": this.assetDetails.clearNotification
    }
    console.log('acti reqobj======', reqObj);
    this.apiService.update('clientModem/deactivateModem', reqObj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.getAssetDetail();
          this.alertService.sweetMessage('success', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  submitActivation(formData) {
    if (formData.invalid) {
      return;
    }

    let reqObj: any = {
      "comment": this.assetDetails.comment ? this.assetDetails.comment : '',
      "assetId": this.assetId,
      "description": this.assetDetails.description,
      "clientname": this.assetDetails.clientName,
      "manufacturerSerialNumber": this.assetDetails.manufacturSererialNumber,
      "isIDPSite": this.assetDetails.isIDPSite,
      "isCellSite": this.assetDetails.isCellSite,
      "siteOrVehicleName": this.assetDetails.site.siteName,
      "assetDescription": this.assetDetails.assetDescription,
      "typeApprovalNumber": this.assetDetails.typeApprovalNumber,
      "site": {
        "siteId": this.assetDetails.site.siteId,
        "siteName": this.assetDetails.site.siteName,
        "customerName": this.assetDetails.site ? this.assetDetails.site.customer.name : '',
        "disableNotifications": this.assetDetails.notificationPlanStatus
      },
      "notificationPlan": {
        "notificationPlanId": "",
        "notificationPlanName": ""
      },
      "adminNotificationPlan": {
        "notificationPlanId": "",
        "notificationPlanName": ""
      },
      "enableDisableRuntimeCollectionPoints": {}
    }
    if (this.notificationList.length) {
      this.notificationList.map(data => {
        if (data.notificationPlanId == this.assetDetails.notificationPlan) {
          reqObj.notificationPlan.notificationPlanId = data.notificationPlanId;
          reqObj.notificationPlan.notificationPlanName = data.notificationPlanName;
        }
      })
    }

    if (this.adminnotificationList.length) {
      this.adminnotificationList.map(data => {
        if (data.notificationPlanId == this.assetDetails.adminNotificationPlan) {
          reqObj.notificationPlan.notificationPlanId = data.notificationPlanId;
          reqObj.notificationPlan.notificationPlanName = data.notificationPlanName;
        }
      })
    }

    this.assetDetails.RunTimeCollectionPoint.map(data => {
      reqObj.enableDisableRuntimeCollectionPoints[`${data.runTimeCollectionPointId}`] = data.runtimeEnabled;
    })

    console.log('acti reqobj======', reqObj);
    this.apiService.update('clientModem/activateModem', reqObj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.getAssetDetail();
          this.alertService.sweetMessage('success', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }
}
