import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AlertService } from '../../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3: any, moment: any;
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AddCustomerUserComponent implements OnInit {
  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('imageUrlName') imageUrlName: ElementRef;

  clientId: any = localStorage.getItem("clientId");
  customerName: any = localStorage.getItem("customerName");
  userId: any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tiersId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  newUserId: number = 0;
  roleList: any = [];
  roles: any = [];
  customerList: any = [];
  phoneCarrierList: any = [];
  tierList: any = [];
  tierListData: any = [];
  checkCustomerArray: any = [];
  commMode: number = 0;
  dataAdapter: any = [];
  source: any = {};
  isTreeDisable: any = false;
  dropdownSettings: any = {};
  data: any = [];
  tierArray: any = [];
  selectedItems: any = [];
  customerArray: any = [];
  records: any = [];
  checkTreeArray: any = [];
  disableTreeArray: any = [];
  roleIds: any = [];
  CustomerID = localStorage.getItem("CustomerID")
  passwordError: any;
  isAllCustTier: any;

  // PassInfo  : any = `
  //   1. Password must be at least 8 characters
  //   2. Password must contain at least 1 numeric character
  //   3. Password must contain at least 1 uppercase character
  //   4. Password must contain at least 1 lowercase character
  //   5. Password cannot contain 3 identical consecutive characters
  //   6. Password must contain at least 1 special character !@#/\$%^&*()
  //   7. Password cannot be the same as the login Id
  //   8. Password cannot contain spaces`;

  PassInfo: any = `
  1. Password must be at least 8 characters.<br>
  2. Password must contain at least 1 numeric character.<br>
  3. Password must contain at least 1 uppercase character.<br>
  4. Password must contain at least 1 special character !@#/\$%^&*().<br>
  5. Password cannot contain spaces.<br>`;

  user: any = {
    "title": "",
    "firstName": "",
    "middleName": "",
    "lastName": "",
    "phoneCarrier": "Verizon",
    "secondaryPhoneNumber": "",
    "secondaryPhoneCarrier": "",
    "note": "",
    "login": "",
    "password": "",
    "phonePin": "",
    "isActive": false,
    "isAppUser": true,
    "communicationMode": "",
    "userType": "Customer",
    "client": { "clientId": this.clientId },
    "emailId": "", "phoneNumber": "",
    "roles": [],
    // "customers":[{"customerId" :this.CustomerID}]};
    "customer": { "customerId": this.CustomerID }
  };

  defaultSelect: any = 'customer';
  customerValue: any = '';
  ifClient: any = false;

  comm: any = { "Email": false, "SMS": false, "IVRS": false, "Call": false };

  permissionData: any = [];

  imageName: any = {};
  imageUrl = "assets/img/upload-img.png";
  clientCode: any;
  imageError = "";
  imageTitle = "";
  breadcrumbObj: any;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService, private formBuilder: FormBuilder) {
    this.permissionData = ApiService.permission;
  }

  DateFormat(startDate) {
    if (startDate) {
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    } else {
      return "";
    }
  }


  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();

    this.getTierList();
    this.getPhoneCarrierList();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.newUserId = params['id'];
      }
    });
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          // this.urlCustomerId = params.cus_id;
      })

    if (this.newUserId) {
      this.getUserById();
    } else {
      this.ClientORCustomer(this.defaultSelect)
    }

    this.getRoleList();
    // this.getCustomerList();
    setTimeout(() => {
      this.getCustomerTierValue();
    }, 500)


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'customerId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      displayProp: 'name'
    };

    function setInputFilter(textbox, inputFilter) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        textbox.addEventListener(event, function () {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
          }
        });
      });
    }

    setInputFilter(document.getElementById("phoneNumber"), function (value) {
      return /^[0-9+]*$/i.test(value);
    });

    setInputFilter(document.getElementById("secondaryPhoneNumber"), function (value) {
      return /^[0-9+]*$/i.test(value);
    });
  }


  // Check if its Customer or Client
  ClientORCustomer(value) {
    if (value == 'client') {
      this.ifClient = true;
      this.user.userType = 'Client';

      for (var i = 0; i < this.roles.length; i++) {
        this.roles.splice(i, 1);
      }

      // this.getRoleList();

    } else {

      this.ifClient = false;
      this.user.userType = 'Customer';
      this.customerValue = '';
      this.isAllCustTier = true;
      for (var i = 0; i < this.roles.length; i++) {
        this.roles.splice(i, 1);
      }
      // this.records = [];
      setTimeout(() => {
        var obj = {
          "target":
            { 'checked': true }
        }
        this.selectAllCustTiers(obj)
      }, 2000)


      // this.getCustomerRoleValue();
    }
  }


  /* Create Tier array */
  // createTierArray(){
  //   Object.keys(this.checkTreeArray).forEach(key=> {
  //     if(this.checkTreeArray[key] != 0){
  //         this.tierArray.push({"tierId":this.checkTreeArray[key]});
  //       }
  //     });
  //   this.user.tiers = this.tierArray
  //   this.FormObject.append("tiers" , JSON.stringify(this.tierArray));

  // };

  /* Create Tier array */
  createTierArray() {
    this.tierArray = []
    Object.keys(this.checkTreeArray).forEach(key => {
      if (this.checkTreeArray[key] != 0) {
        this.tierArray.push({ "tierId": this.checkTreeArray[key] });
      }
    });
    this.user.tiers = this.tierArray
  };


  /* Handle tier tree chacked unchecked Event */
  CheckChange(event) {
    if (event.args.checked == true) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist == '-1') {
        this.checkTreeArray.push(event.args.element.id);
      }
    } else if (event.args.checked == false) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist != '-1') {
        this.checkTreeArray.splice(exist, 1);
      }
    }
  }

  /* On Item Select Customer */
  onItemSelect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if (exist == '-1') {
      this.checkCustomerArray.push(item.customerId.toString());
    }
  }

  /* On Item DeSelect Customer */
  onItemDeselect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if (exist != '-1') {
      this.checkCustomerArray.splice(exist, 1);
    }
  }
  /* On All Select Customer */
  onSelectAll(items: any) {
    this.checkCustomerArray = [];
    Object.keys(items).forEach(key => {
      this.checkCustomerArray.push(items[key]['customerId'].toString());
    });
  }
  /* On All DeSelect Customer */
  onDeSelectAll() {
    this.checkCustomerArray = [];
  }

  /* Create Tier array */
  createCustomerArray() {
    Object.keys(this.checkCustomerArray).forEach(key => {
      if (this.checkCustomerArray[key] != 0) {
        this.customerArray.push({ "customerId": this.checkCustomerArray[key] });
      }
    });
    this.user.customers = this.customerArray
  };
  /* check tree checkbox */
  checkedCheckboxOnLoad() {
    Object.keys(this.checkTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.checkTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      this.myTree.checkItem(checkedItem, true);
    });
  }




  // Select All Tiers
  selectAllCustTiers(e) {
    if (e.target.checked == true) {
      // this.isAllCustTierSelected = true;
      this.checkTreeArray = [];

      Object.keys(this.data).forEach(key => {
        if (this.data[key].flag) {
          this.checkTreeArray.push(this.data[key]['tierId'].toString());
        }
      });
      this.checkedCheckboxOnLoad()
      this.isTreeDisable = true;
    } else {
      // this.isAllCustomerSelected = false;
      this.isTreeDisable = false;
      // this.checkTreeArray = [];
      this.checkedCheckboxOnLoad()
      this.data.map((obj) => {

        let checkedItem = document.getElementById(obj.tierId);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem, false);
      })
      this.disableTreeArray = []

      // Object.keys(this.user.tiers).forEach(key=> {
      //   this.checkTreeArray.push(this.user.tiers[key]['tierId'].toString());
      // });

      Object.keys(this.data).forEach(key => {
        if (this.data[key]['flag'] == false) {
          this.disableTreeArray.push(this.data[key]['tierId'].toString());
        }
      });

      this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
      this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
      setTimeout(() => {
        this.treeItemDisable();
      }, 500);

    }

  }


  checkItemCommunication() {
    this.commMode = this.user.communicationMode;
    var number = this.user.communicationMode;
    if ((number - 8) >= 0) {
      number = number - 8;
      this.comm.Call = true;
    }
    if ((number - 4) >= 0) {
      number = number - 4;
      this.comm.IVRS = true;
    }
    if ((number - 2) >= 0) {
      number = number - 2;
      this.comm.SMS = true;
    }
    if ((number - 1) >= 0) {
      number = number - 1;
      this.comm.Email = true;
    }

  }

  /* Create communication logic */
  getCommMode(e) {
    if (e.target.name == 'emailId') {
      if (e.target.checked) {
        this.commMode = this.commMode + 1;
      } else {
        this.commMode = this.commMode - 1;
      }
    } else if (e.target.name == 'SMS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 2;
      } else {
        this.commMode = this.commMode - 2;
      }
    } else if (e.target.name == 'IVRS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 4;
      } else {
        this.commMode = this.commMode - 4;
      }
    } else if (e.target.name == 'Call') {
      if (e.target.checked) {
        this.commMode = this.commMode + 8;
      } else {
        this.commMode = this.commMode - 8;
      }
    }
    this.user.communicationMode = this.commMode;
  }

  /* Create role array */
  updateRoleOptions(rlist, event) {
    if (event.target.checked == true) {
      this.roles.push({ "roleId": rlist.roleId });
    } else {
      for (var i = 0; i < this.roles.length; i++) {
        if (this.roles[i].roleId == rlist.roleId) {
          this.roles.splice(i, 1);
        }
      }
    }
    $("#role_err").css("display", "none");
    this.user.roles = this.roles;
  }

  treeItemDisable() {
    Object.keys(this.disableTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.disableTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      this.myTree.disableItem(checkedItem);
    });
  }




  /* Get Customer list */
  getCustomerList() {
    var URL = 'customer/getCustomerByClient?clientId=' + this.clientId;
    if (this.customerId != null && this.customerId != '') {
      var customerString = '&customerId=' + this.customerId.toString();
      var URL = URL.concat(customerString);
    }
    if (this.tiersId != null && this.tiersId != '') {
      var tierString = '&tierId=' + this.tiersId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.customerList = data.responseList;
        } else {
          this.customerList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /*********************************** 
  Get Tier Value If USER SELECT OR CHANGE THE CUSTOMER
  *******************************************************/
  getCustomerTierValue() {
    this.apiService.getAll('tier/getAllTierByCustomer?customerId=' + localStorage.getItem('CustomerID') + '&clientId=' + this.clientId)
      .subscribe(
        data => {
          if (data.statusCode == 100) {
            // this.records = data.responseList;
            this.tierListData = data.responseList ? data.responseList : [];
            this.data = JSON.parse(JSON.stringify(this.tierListData));
            Object.keys(this.data).forEach(key => {
              if (this.data[key]['flag'] == false) {
                this.disableTreeArray.push(this.data[key]['tierId'].toString());
              }
            });
            this.source = {
              datatype: 'json',
              datafields: [
                { name: 'tierId' },
                { name: 'parentTierId' },
                { name: 'tierName' },
              ],
              id: 'tierId',
              localdata: this.data
            };
            this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
            this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
            var self = this;
            setTimeout(function () {
              self.treeItemDisable();
            }, 2000);
          } else {
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );

  }




  /* Get tier list */
  getTierList() {
    this.apiService.getAll('tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.tierListData = data.responseList;
          this.data = this.tierListData;
          Object.keys(this.data).forEach(key => {
            if (this.data[key]['flag'] == false) {
              this.disableTreeArray.push(this.data[key]['tierId']);
            }
          });
          this.source = {
            datatype: 'json',
            datafields: [
              { name: 'tierId' },
              { name: 'parentTierId' },
              { name: 'tierName' },
            ],
            id: 'tierId',
            localdata: this.data
          };
          this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);

          if (this.newUserId) {
            this.getUserById();
          } else {
            this.ClientORCustomer(this.defaultSelect)
          }

        } else {
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  removeFileImage(){
    // setTimeout(() => {
        this.imageUrlName.nativeElement.value = '';
        this.imageTitle = ''
    // }, 2500);
}

  /*********************
   Image Uploading
  *********************/
  onFileChanged(event) {
    const file = event.target.files;
    const fileType = file[0]['type'];
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    this.imageError = "";
    // invalid file type code goes here.
    if (!validImageTypes.includes(fileType)) {
      this.imageUrl = "assets/img/upload-img.png";
      this.imageName = null;
      this.imageTitle = "";
      this.imageError = "Please select valid image type such as jpg, jpeg, png."; return;
    }
    if (this.imageError == "") {
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          if (img.width <= 300 && img.height <= 300) {
            this.imageUrl = reader.result as string;
            this.imageName = file;
            this.imageTitle = file[0]['name'];
          }
          else {
            this.imageUrl = "assets/img/upload-img.png";
            this.imageName = null;
            this.imageTitle = "";
            this.imageError = "Image Size should be less than 300X300";
            // this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
            return;
          }
        };
      }
    }
    if (this.userId != 0) {
      // this.imageSave();
      // this.imagedata.append('file', this.imageName[0], this.imageName[0].name);
      // this.imagedata.append('file', this.imageName[0], this.imageName[0].name);
      // this.FormObject.append("file" , this.imageName[0], this.imageName[0].name);
    }
  }

  imageSave(newUserId) {
    if (this.imageError == "") {
      const uploadData = new FormData();
      if (this.imageName.length && newUserId) {
        uploadData.append('file', this.imageName[0], this.imageName[0].name);
        // this.apiService.create('uploadImage/uploadImage?section=2&sectionId=' + this.customerId, uploadData).subscribe(
        // uploadImage/uploadImage?section=1&sectionId=4
        this.apiService.create('uploadImage/uploadImage?section=1&sectionId=' + newUserId, uploadData).subscribe(
          data => {
            if (data.statusCode == 100) {
              // this.getCustomerById();
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    }
  }



  /*************************
      Add New user
  ***********************/
  addUser(data) {
    this.createTierArray();
    // var regx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // var regx = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$");
    // var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
    //
    // if(regx.test(this.user.password) == false && this.newUserId == 0) {
    //   this.passwordError = this.PassInfo
    //     $("#passerror").css("display", "block");
    //     $("#pass").focus();
    //     return;
    // }

    this.user.customer = {
      "customerId": localStorage.getItem('CustomerID')
    }

    if (!data.form.valid) {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }

    console.log(this.user);
    if (this.user.roles.length == 0) {
      $("#role_err").css("display", "block");
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      return;
    }

    if (!data.invalid) {
      // let phoneCarrierVal = $("#phoneCarrier option:selected").val();
      // this.user.phoneCarrier = phoneCarrierVal;
      if (this.newUserId == 0) {
        this.apiService.create('user/createCustomerUser', this.user).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.imageSave(data.user.userId)
              this.alertService.sweetMessage('success', 'User added Successfully');
              setTimeout(() => {
                this.router.navigate(['/users', this.CustomerID]);
              }, 2000)
            }
            else if (data.statusCode == 101) {
              this.alertService.sweetMessage('error', data.responseMessage);
            } else { this.alertService.sweetMessage('error', data.responseMessage); }
          },
          error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
      }
      else {

        // this.apiService.update('user/updateUser', this.user).subscribe(
        this.apiService.update('user/updateCustomerUser', this.user).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.imageSave(this.newUserId)
              this.alertService.sweetMessage('success', 'User Updated successfully');
              this.router.navigate(['/users', this.CustomerID]);
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
      }
    }
  }

  /* Get Role list */
  getPhoneCarrierList() {
    this.apiService.getAll('user/getPhoneCarrierList').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.phoneCarrierList = data.responseList;
          this.phoneCarrierList.forEach(key => {
            if (key == 'Verizon') {
              setTimeout(function () {
                $('.' + key).attr('selected', 'true');
              }, 500);
            }
          });
        } else {
          this.phoneCarrierList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  clearError() {
    $("#passerror").css("display", "none");
  }


  /* Get Role list */
  getRoleList() {
    var URL = 'role/getRoleByUser?isCustomerRole=true&clientId=' + this.clientId;
    if (this.customerId != null && this.customerId != '') {
      var customerString = '&customerId=' + this.customerId.toString();
      var URL = URL.concat(customerString);
    }
    if (this.tiersId != null && this.tiersId != '') {
      var tierString = '&tierId=' + this.tiersId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.roleList = data.responseList;
        } else {
          this.roleList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /* Get Role list */
  // getCustomerList(){
  //   var URL = 'customer/getCustomerByClient?clientId='+this.clientId;
  //    if(this.customerId != null && this.customerId !=''){
  //     var customerString     = '&customerId='+this.customerId.toString();
  //     var URL = URL.concat(customerString);
  //   }
  //   if(this.tiersId != null && this.tiersId !=''){
  //     var tierString     = '&tierId='+this.tiersId.toString();
  //     var URL = URL.concat(tierString);
  //   }
  //    this.apiService.getAll(URL).subscribe(
  //    data => {
  //        if(data.statusCode == 100){
  //           this.customerList = data.responseList;
  //         }else{
  //           this.customerList = [];
  //           /*this.alertService.sweetMessage('error',data.responseMessage); */
  //          }
  //       },
  //      error =>{
  //                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  //              }
  //         );
  //       }

  getUserById() {
    this.apiService.getAll('user/getUserByUserId?userId=' + this.newUserId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.user = data.user;
          if (this.user.imageLocation == null) {
            this.imageUrl = this.imageUrl;
            this.imageTitle = "";
          } else {
            let imgArr = this.user.imageLocation.split('/');
            this.imageTitle = imgArr[1];
            this.imageUrl = this.apiService.imageBasePath + this.user.imageLocation;
          }

          if (this.user.secondaryPhoneCarrier === null) {
            this.user.secondaryPhoneCarrier = "";
          }

          if (this.user.phoneCarrier === null) {
            this.user.phoneCarrier = "Verizon";
          }

          this.checkItemCommunication();
          this.checkTreeArray = [];
          Object.keys(this.user.tiers).forEach(key => {
            this.checkTreeArray.push(this.user.tiers[key]['tierId'].toString());
          });

          if (this.user.tiers.length == this.tierListData.length) {
            this.isTreeDisable = true;
            this.isAllCustTier = true;
          }
          var that = this;
          setTimeout(function () {
            that.checkedCheckboxOnLoad();
          }, 2000);

          // this.selectedItems = this.user.customers;
          // Object.keys(this.user.customers).forEach(key=> {
          //         this.checkCustomerArray.push(this.user.customers[key]['customerId'].toString());
          // });

          this.roles = data.user.roles;
          this.roleIds = _.map(this.user.roles, 'roleId');
          var self = this;
          _.map(this.user.roleIds, function (value, key) {
            self.user.roleIds.push({ roleId: value.id });
          });
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );

  }

  removeBrandingImage() {
    Swal({
      title: 'Are you sure?',
      text: 'You want to remove Image.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
          if (this.newUserId != 0) {  
            this.apiService.delete("uploadImage/removeImage?section=1&sectionId=" + this.newUserId, '').subscribe(
              data => {
                if (data.statusCode == 100) {
                  this.alertService.sweetMessage('success', 'Image removed successfully');
                  // if(section == 6) {
                  this.imageUrl = "assets/img/upload-img.png";
                  // }
                } else {
                  this.alertService.sweetMessage('error', data.responseMessage);
                }
              },
              error => {
                this.alertService.sweetMessage('error', "Something went wrong, please try again ");
              }
            );
          } else {
            this.alertService.sweetMessage('success', 'Image removed successfully');
            this.imageUrl = "assets/img/upload-img.png";
          } 
      }
    })
  }


}
