import { Component, OnInit, ChangeDetectorRef, ViewChild, Inject, Injectable, ElementRef } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AlertService } from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3: any, moment: any;
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('f') form: any;
  @ViewChild('imageUrlName') imageUrlName:ElementRef

  userData: any = JSON.parse(localStorage.getItem("userData"));
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  isGlobalUser: any = JSON.parse(localStorage.getItem("userData")).isGlobalUser;
  isCusDisable: any = false;
  isTreeDisable: any = false;
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tiersId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  newUserId: number = 0;
  holdFlag: number = 0;
  roleList: any = [];
  roles: any = [];
  customerList: any = [];
  phoneCarrierList: any = [];
  tierList: any = [];
  tierListData: any = [];
  checkCustomerArray: any = [];
  commMode: any; //commMode:number=0;
  dataAdapter: any = [];
  source: any = {};
  dropdownSettings: any = {};
  data: any = [];
  tierArray: any = [];
  selectedItems: any = [];
  customerArray: any = [];
  records: any = [];
  checkTreeArray: any = [];
  holdcheckTreeArray: any = [];
  disableTreeArray: any = [];
  roleIds: any = [];
  passwordError: any = 'Password is required';
  imageName: any = {};
  imageUrl = "assets/img/upload-img.png";
  imgBasePath: any = "";
  imageError = "";
  imageTitle = "";
  FormObject: any = new FormData();
  showError = false;
  // PassInfo   : any = `
  //   1. Password must be at least 8 characters
  //   2. Password must contain at least 1 numeric character
  //   3. Password must contain at least 1 uppercase character
  //   4. Password must contain at least 1 lowercase character
  //   5. Password cannot contain 3 identical consecutive characters
  //   6. Password must contain at least 1 special character !@#/\$%^&*()
  //   7. Password cannot be the same as the login Id
  //   8. Password cannot contain spaces`;


  PassInfo: any = `    
1) Password must be at least 8 characters.<br>
2) Password must contain at least 1 numeric character.<br>
3) Password must contain at least 1 uppercase character.<br>
4) Password must contain at least 1 special character !@#/\$%^&*().<br>
5) Password cannot contain spaces.<br>`;

  PassInfoToolTip: any = ` 1. Password must be at least 8 characters.
    2. Password must contain at least 1 numeric character.
    3. Password must contain at least 1 uppercase character.
    4. Password must contain at least 1 special character !@#/\$%^&*().
    5. Password cannot contain spaces.`;


  user: any = {
    "emailId": "",
    "isActive": false,
    "communicationMode": "",
    "client": { "clientId": this.clientId },
    "note": "",
    "firstName": "",
    "middleName": "",
    "lastName": "",
    "userType": "Client",
    "login": "",
    "phonePin": "",
    "isAppUser": true,
    "phoneNumber": "",
    "secondaryPhoneNumber": "",
    "phoneCarrier": "",
    "secondaryPhoneCarrier": "",
    "file": '',
    'newPassword': '',
    'OldNewpassword': '',
    'confirmPassword': '',
    "isGlobalUser": false,
  };

  // "userId": 20,


  comm: any = { "Email": false, "SMS": false, "IVRS": false, "Call": false };
  permissionData: any = [];
  clientCode: any = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private location: Location,
    private ck: CookieService,
    private formBuilder: FormBuilder) { this.permissionData = ApiService.permission; }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    // this.getRoleList();
    // this.getCustomerList();
    // this.getTierList();
    this.getPhoneCarrierList();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.newUserId = params['id'];
        this.initialiseState(); // reset and set based on new parameter this time
      }
    });
    if (this.newUserId) {
      // this.getUserById();
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'customerId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      displayProp: 'name'
    };

    function setInputFilter(textbox, inputFilter) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        if (textbox) {
          textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
              this.oldValue = this.value;
              this.oldSelectionStart = this.selectionStart;
              this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
              this.value = this.oldValue;
              this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
          });
        }
      });
    }

    setInputFilter(document.getElementById("phoneNumber"), function (value) {
      return /^[0-9+]*$/i.test(value);
    });

    setInputFilter(document.getElementById("secondaryPhone"), function (value) {
      return /^[0-9+]*$/i.test(value);
    });

  }


  initialiseState() {
    this.getUserById();
  }


  /* Handle tier tree chacked unchecked Event */
  CheckChange(event) {
    if (event.args.checked == true) {
      if ($('input[name="isGlobalUser"]:checked').length == 0) {
        var exist1 = this.holdcheckTreeArray.indexOf(event.args.element.id);
        if (exist1 == '-1') {
          this.holdcheckTreeArray.push(event.args.element.id);
        }
      }

      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist == '-1') {
        this.checkTreeArray.push(event.args.element.id);
      }
    } else if (event.args.checked == false) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist != '-1') {
        this.checkTreeArray.splice(exist, 1);
      }

      if (this.holdFlag != 0) {
        var exist1 = this.holdcheckTreeArray.indexOf(event.args.element.id);
        if (exist1 != '-1') {
          this.holdcheckTreeArray.splice(exist1, 1);
        }
      }
    }
  }

  /* On Item DeSelect Customer */
  onItemDeselect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if (exist != '-1') {
      this.checkCustomerArray.splice(exist, 1);
    }
    this.user.customers = this.checkCustomerArray;
  }

  /* On All Select Customer */
  onSelectAll(items: any) {
    this.checkCustomerArray = [];
    Object.keys(items).forEach(key => {
      this.checkCustomerArray.push(items[key]['customerId'].toString());
    });
    this.user.customers = this.checkCustomerArray;
  }

  /* On All DeSelect Customer */
  onDeSelectAll() {
    this.checkCustomerArray = [];
    this.user.customers = this.checkCustomerArray;
  }

  /* Create Tier array */
  createTierArray() {
    Object.keys(this.checkTreeArray).forEach(key => {
      if (this.checkTreeArray[key] != 0) {
        this.tierArray.push({ "tierId": this.checkTreeArray[key] });
      }
    });
    this.user.tiers = this.tierArray
    this.FormObject.append("tiers", JSON.stringify(this.tierArray));

  };
  /* Create Tier array */
  createCustomerArray() {
    Object.keys(this.checkCustomerArray).forEach(key => {
      if (this.checkCustomerArray[key] != 0) {
        var exist = this.customerArray.indexOf(this.checkCustomerArray[key]);
        if (exist == '-1') {
          this.customerArray.push({ "customerId": this.checkCustomerArray[key] });
        }
      }
    });
    this.user.customers = this.customerArray;
    this.FormObject.append("customers", JSON.stringify(this.customerArray));
  };
  /* check tree checkbox */
  checkedCheckboxOnLoad() {
    Object.keys(this.checkTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.checkTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      this.myTree.checkItem(checkedItem, true);
    });
  }



  checkItemCommunication() {
    this.commMode = this.user.communicationMode;
    var number = this.user.communicationMode;
    if ((number - 8) >= 0) {
      number = number - 8;
      this.comm.Call = true;
    }
    if ((number - 4) >= 0) {
      number = number - 4;
      this.comm.IVRS = true;
    }
    if ((number - 2) >= 0) {
      number = number - 2;
      this.comm.SMS = true;
    }
    if ((number - 1) >= 0) {
      number = number - 1;
      this.comm.Email = true;
    }

  }


  /* Create communication logic */
  getCommMode(e) {
    if (e.target.name == 'emailId') {
      if (e.target.checked) {
        this.commMode = this.commMode + 1;
      } else {
        this.commMode = this.commMode - 1;
      }
    } else if (e.target.name == 'SMS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 2;
      } else {
        this.commMode = this.commMode - 2;
      }
    } else if (e.target.name == 'IVRS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 4;
      } else {
        this.commMode = this.commMode - 4;
      }
    } else if (e.target.name == 'Call') {
      if (e.target.checked) {
        this.commMode = this.commMode + 8;
      } else {
        this.commMode = this.commMode - 8;
      }
    }
    this.user.communicationMode = this.commMode;
    this.FormObject.append("communicationMode", this.commMode);
  }


  /* Create role array */
  updateRoleOptions(rlist, event) {
    if (event.target.checked == true) {
      this.roles.push({ "roleId": rlist.roleId });
    } else {
      for (var i = 0; i < this.roles.length; i++) {
        if (this.roles[i].roleId == rlist.roleId) {
          this.roles.splice(i, 1);
        }
      }
    }
    this.user.roles = this.roles;
    this.FormObject.append("roles", JSON.stringify(this.roles));
  }

  treeItemDisable() {
    Object.keys(this.disableTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.disableTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      // If permission is not there for updating tier than disable the tier item       
      this.myTree.disableItem(checkedItem);

    });
  }

  isGlobalUserChange(event) {
    if (event.target.checked == true) {
      this.isTreeDisable = true;
      this.isCusDisable = true;
      this.selectedItems = [];
      $('#customer .multiselect-item-checkbox input').click();
      Object.keys(this.customerList).forEach(key => {
        this.checkCustomerArray.push(this.customerList[key]['customerId'].toString());
      });
      this.selectedItems = this.user.customers = this.checkCustomerArray;

      Object.keys(this.tierListData).forEach(key => {
        let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem, true);
      });

      setTimeout(function () {
        $('#customer .multiselect-dropdown .dropdown-list .multiselect-item-checkbox input').attr('disabled', 'disabled');
      }, 1000);
    }
    else {
      Object.keys(this.tierListData).forEach(key => {
        let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem, false);
      });

      Object.keys(this.holdcheckTreeArray).forEach(key => {
        let checkedItem = document.getElementById(this.holdcheckTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem, true);
      });



      this.selectedItems = this.user.customers = this.checkCustomerArray = [];
      this.isTreeDisable = false;
      this.isCusDisable = false;
      setTimeout(function () {
        $('#customer .multiselect-dropdown .dropdown-list .multiselect-item-checkbox input').removeAttr('disabled');
        $('#customer .multiselect-dropdown .dropdown-list .multiselect-item-checkbox input').click();
        $('#customer .multiselect-dropdown .dropdown-list .item1 .multiselect-item-checkbox input').click();
      }, 1000);
    }

  }


  /*********************
   Image Uploading
  *********************/
  onFileChanged(event) {
    const file = event.target.files;
    // this.imageName = file;
    // var reader = new FileReader();
    // reader.readAsDataURL(file[0]);
    // reader.onload = (_event: any) => {
    //   var img = new Image();
    //   img.src = _event.target.result;
    //   img.onload = () => {
    //       this.imageUrl = reader.result as string;
    //   };
    // }

    const fileType = file[0]['type'];
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    this.imageError = "";
    // invalid file type code goes here.
    if (!validImageTypes.includes(fileType)) {
      this.imageUrl = "assets/img/upload-img.png";
      this.imageName = null;
      this.imageTitle = "";
      this.imageError = "Please select valid image type such as jpg, jpeg, png";
      return;
    }

    if (this.imageError == "") {
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          // this.imageUrl = reader.result as string;
          if (img.width <= 300 && img.height <= 300) {
            this.imageUrl = reader.result as string;
            this.imageName = file;
            this.imageTitle = file[0]['name'];
            // if (this.customerId != 0) {
            //     this.imageSave();
            // }
          } else {
            //   this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
            this.imageUrl = "assets/img/upload-img.png";
            this.imageName = null;
            this.imageTitle = "";
            this.imageError = "Image Size should be less than 300X300";
            return;
          }
        };
      }
    }
    // clear value of file after operation 
    
    // setTimeout(() => {
    //   this.imageError = ""
    // }, 5000);
  }

  RemoveInputImage(){
    // setTimeout(() => {
      this.imageUrlName.nativeElement.value = '';
      this.imageTitle = ""
    // }, 2500);
  }

  imageSave(newUserId) {
    console.log(this.imageName);
    
    if (this.imageError == "") {
      const uploadData = new FormData();
      if (this.imageName.length) {
        uploadData.append('file', this.imageName[0], this.imageName[0].name);
        this.apiService.create('uploadImage/uploadImage?section=1&sectionId=' + newUserId, uploadData).subscribe(
          data => {
            if (data.statusCode == 100) {
              // this.getCustomerById();
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
            this.getUserInfo();
          },
          error => {
            this.getUserInfo();
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          });
      } else {
        this.getUserInfo();
      }
    } else {
      this.getUserInfo();
    }
  }

  hideError() {
    $("#passerror").css("display", "none");
  }


  getRoleList() {
    // remove ass per vishnu sir new API integrate

    // var URL = 'role/getRoleByUser?clientId=' + this.clientId;
    // if (this.customerId != null && this.customerId != '') {
    //   var customerString = '&customerId=' + this.customerId.toString();
    //   var URL = URL.concat(customerString);
    // }
    // if (this.tiersId != null && this.tiersId != '') {
    //   var tierString = '&tierId=' + this.tiersId.toString();
    //   var URL = URL.concat(tierString);
    // }
    var URL = "role/getRoleListByUserProfile"
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.roleList = data.responseList;
        } else {
          this.roleList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /* Get Customer list */

  getCustomerList() {
    var URL = 'customer/getCustomerByClient?clientId=' + this.clientId;
    if (this.customerId != null && this.customerId != '') {
      var customerString = '&customerId=' + this.customerId.toString();
      var URL = URL.concat(customerString);
    }
    if (this.tiersId != null && this.tiersId != '') {
      var tierString = '&tierId=' + this.tiersId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.customerList = data.responseList;
        } else {
          this.customerList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Get tier list */
  getTierList() {
    this.apiService.getAll('tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId).subscribe(
      data => {
        setTimeout(() => {
          if (data.statusCode == 100) {
            this.tierListData = data.responseList;
            this.data = this.tierListData;
            Object.keys(this.data).forEach(key => {
              this.disableTreeArray.push(this.data[key]['tierId']);
            });
            this.source = {
              datatype: 'json',
              datafields: [
                { name: 'tierId' },
                { name: 'parentTierId' },
                { name: 'tierName' },
              ],
              id: 'tierId',
              localdata: this.data
            };
            this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
            this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
            var self = this;
            setTimeout(function () {
              self.checkedCheckboxOnLoad();
              self.treeItemDisable();
            }, 1000);

          } else {
            /*this.alertService.sweetMessage('error',data.responseMessage); */
          }
        }, 2000);
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getUserById() {
    this.apiService.getAll('user/getUserByUserId?userId=' + this.newUserId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.user = data.user;

          if (this.user.imageLocation != null) {
            this.imageUrl = this.apiService.imageBasePath + this.user.imageLocation;
            let imageArr = this.user.imageLocation.split('/');
            this.imageTitle = imageArr[1];
          }
          this.roleList = data.user.roles;
          this.getTierList();

          this.checkItemCommunication();
          this.checkTreeArray = [];
          Object.keys(this.user.tiers).forEach(key => {
            this.checkTreeArray.push(this.user.tiers[key]['tierId'].toString());
          });
          var that = this;
          // setTimeout(function(){
          //   that.checkedCheckboxOnLoad();            
          // },1000);
          this.selectedItems = this.user.customers;

          Object.keys(this.user.customers).forEach(key => {
            var id = this.user.customers[key]['customerId'].toString();
            var exist = this.checkCustomerArray.indexOf(id);
            if (exist == '-1') {
              this.checkCustomerArray.push(id);
            }
          });

          this.roles = data.user.roles;

          this.roleIds = _.map(this.user.roles, 'roleId');
          var self = this;
          _.map(this.user.roleIds, function (value, key) {
            self.user.roleIds.push({ roleId: value.id });
          });

          if (this.user.secondaryPhoneCarrier == null) {
            this.user.secondaryPhoneCarrier = "";
          }
          if (this.user.phoneCarrier == null) {
            this.user.phoneCarrier = "";
          }

        } else {
          // this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );

  }



  getPhoneCarrierList() {
    this.apiService.getAll('user/getPhoneCarrierList').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.phoneCarrierList = data.responseList;
        } else {
          this.phoneCarrierList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* ****************
   * Add user
  ******************/
  addUser(data) {
    // if(!data.invalid){
    // var regx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
    if (regx.test(this.user.password) == false && this.newUserId == 0) {
      this.passwordError = this.PassInfo
      $("#passerror").css("display", "block");
      $("#pass").focus();
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
      return;
    }
    // Check if password same as the login Id
    // if(this.user.password == this.user.login) {
    //   this.passwordError = "Password cannot be the same as the login Id";
    //   $("#passerror").css("display", "block");
    //   $("#pass").focus();
    //   return;
    // }
    // Check if password contain 3 identical consecutive characters
    // if( RegExpConsicutive.test(this.user.password) == false ) {
    //   this.passwordError = "Password cannot contain 3 identical consecutive characters";
    //   $("#passerror").css("display", "block");
    //   $("#pass").focus();
    //   return;
    // }
    if (this.form.valid && (this.user.phoneNumber != this.user.secondaryPhoneNumber)) {
      this.showError = false
      this.apiService.update('user/updateUserProfile', this.user).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.imageSave(this.newUserId);
            this.alertService.sweetMessage('success', 'Profile Updated successfully');
            //this.router.navigate(['/user-list']);
          } else {
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    } else {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
      this.showError = true
    }
  }

  // else
  //   { this.alertService.sweetMessage('error','Please fill in all the required fields'); }
  // }

  //
  // getUserById(){
  //   this.apiService.getAll('user/getUserByUserId?userId='+this.newUserId).subscribe(
  //    data => {
  //     if(data.statusCode == 100){
  //       this.user = data.user;
  //
  //       if(this.user.imageLocation != null){
  //         this.imageUrl = this.apiService.imageBasePath +this.user.imageLocation;
  //       }
  //       this.checkItemCommunication();
  //     }else{
  //       this.alertService.sweetMessage('error',data.responseMessage);
  //      }
  //     },
  //     error =>
  //       { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
  //     );
  // }

  clearError() {
    $("#Uppasserror").css("display", "none");
  }

  updatePassword() {
    // var regx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var regx = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$");
    var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
    var regx1 = /\s/;

    if (regx.test(this.user.newPassword) == false) {
      this.passwordError = this.PassInfo
      $("#Uppasserror").css("display", "block");
      $("#newpass").focus();
      return;
    }
    if (regx1.test(this.user.newPassword) == true) {
      this.passwordError = "No space allowed";
      $("#Uppasserror").css("display", "block");
      $('#confirmpass').focus();
      return;
    }
    //Match New password to Confirm Password
    if (this.user.confirmPassword != this.user.newPassword) {
      this.passwordError = "New Password and Confirm Password Should be same";
      $("#Uppasserror").css("display", "block");
      $('#confirmpass').focus();
      return;
    }

    //Match newPassword and UserId
    if (this.user.newPassword == this.userData.login) {
      this.passwordError = "User ID and Password Should Not be same";
      $("#Uppasserror").css("display", "block");
      $('#confirmpass').focus();
      return;
    }
    // Check if password contain 3 identical consecutive characters
    // if( RegExpConsicutive.test(this.user.newPassword) == false ) {
    //   this.passwordError = "Password cannot contain 3 identical consecutive characters";
    //   $("#Uppasserror").css("display", "block");
    //   $("#newpass").focus();
    //   return;
    // }

    /* ===password with encryption=== */
    var obj = {
      "userId": this.newUserId,
      "password": window.btoa(this.user.OldNewpassword),
      "newPassword": window.btoa(this.user.newPassword),
      //"confirmPassword":this.user.confirmPassword
    }

    // var obj = {
    //   "userId": this.newUserId,
    //   "password": this.user.OldNewpassword,
    //   "newPassword": this.user.newPassword,
    //   // "confirmPassword":this.user.confirmPassword
    //   }

    this.apiService.update('user/updatePassword', obj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.alertService.sweetMessage('success', 'Password Updated Successfully');
          this.user.OldNewpassword = '';
          this.user.newPassword = '';
          this.user.confirmPassword = ''
          this.logout();
          $('#closeModal').click();
        }
        else {
          if (data.responseMessage == 'Invalid Password can not contain 3 Indentical Consecutive Character') {
            this.passwordError = "Password cannot contain 3 identical consecutive characters";
            $("#Uppasserror").css("display", "block");
            $("#newpass").focus();
            return;
          }
          else {
            this.alertService.sweetMessage('error', data.responseMessage);
            this.user.OldNewpassword = '';
            this.user.confirmPassword = ''
            this.user.newPassword = '';
          }
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  logout() {
    this.apiService.logout('userauth/oauth/revokeToken').subscribe(
      data => {
        if (data.status == 200) {
          localStorage.clear();
          $("body").removeClass('body-smt-active');
          this.router.navigate(['/login']);
          this.ck.delete('islogin');
          document.cookie = "islogin=;"
          document.cookie = "islogin=;"
          this.ck.delete('refresh_token');
          this.ck.deleteAll()
        } else {
        }
      },
      error => {
        //this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  CancelProfile() {
    this.location.back();
  }

  resetUpdatePassword() {
    this.passwordError = "";
    $("#Uppasserror").css("display", "none");
    $("#oldpass").val("");
    $("#newpass").val("");
    $("#confirmpass").val("");
  }

  getUserInfo() {
    this.apiService.getUserInfoData('user/getUserInfo').subscribe(
      data => {
        if (data.statusCode == 100) {
          ApiService.permission = data.response.permissions;
          if (ApiService.permission) {
            ApiService.isPermission = 1;
          }
          localStorage.setItem("permission", JSON.stringify(data.response.permissions));
          localStorage.setItem("userData", JSON.stringify(data.response));
          localStorage.setItem("userId", data.response.userId);
          localStorage.setItem("clientId", data.response.defaultClientId);
          localStorage.setItem("clientCode", data.response.clients[0].clientCode);
        } else if (data.statusCode == 105) {
          var msg = data.responseMessage;
        } else {
        }
        this.router.navigate(['/dashboard']);
      },
      error => {
      });
  }

  removeBrandingImage() {
    Swal({
      title: 'Are you sure?',
      text: 'You want to remove Image.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete("uploadImage/removeImage?section=1&sectionId=" + this.newUserId, '').subscribe(
          data => {
            if (data.statusCode == 100) {
              this.alertService.sweetMessage('success', 'Image removed successfully');
              // if(section == 6) {
              this.imageUrl = "assets/img/upload-img.png";
              // }
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.sweetMessage('error', "Something went wrong, please try again ");
          }
        );
      }
    })
  }

}
