import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-my-schedule',
  templateUrl: './fixed-schedule.component.html',
  styleUrls: ['./fixed-schedule.component.css']
})

export class FixedScheduleComponent implements OnInit {
  // Initializing Variables
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  fixedScheduleData:any=[];
  assetData:any=[];
  unAssingedAssetList:any=[];
  assetRoleList:any=[];
  clientCode : any = '';
  permissionData:any=[];
  AddAssetData:any={"user":{"userId":this.userId},"asset":{"assetId":""},"assetRole":{"assetRoleId":""}};

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService,private modal: NgbModal) {
    this.permissionData = ApiService.permission;
  }

  // Function to format date
  DateFormat(startDate){
    if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
        return "";
      }
  }

  ngOnInit() {
    // console.log(this.permissionData);
    this.clientCode = this.alertService.getClientCode();
    this.getFixedScheduleData();
  }

  // Get List of FixedSchedule
  getFixedScheduleData() {
      this.apiService.getAll('schedule/getFixedNotificationScheduleData?userId=' + this.userId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.fixedScheduleData = data.responseList;
              } else {
                  this.fixedScheduleData = [];
              }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }

  // Getting Assets Ids
  getAssetsIds(ids){
    if(ids){
      this.apiService.getAll('asset/getAssetByAssets?assetIds='+ids+'&userId=' + this.userId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.assetData = data.responseList;
              } else {
                  this.assetData = [];
              }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
    }else{
      this.assetData = [];
    }
  }

  // Delete Asset Id
  deleteAsset(assetId){
        Swal({
          title: 'Are you sure?',
          text: "You want to remove this Asset.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
          var assetData = {"user":{"userId":this.userId},"asset":{"assetId":assetId},"client":{"clientId":this.clientId}}
               this.apiService.update('asset/removeUserFromAsset',assetData).subscribe(
                data => {
                  if(data.statusCode == 100){
                    this.getFixedScheduleData();
                    this.assetData = [];
                    this.alertService.sweetMessage('success','Asset removed successfully');
                  }else{
                    this.alertService.sweetMessage('error',data.responseMessage);
                  }
                },
              error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
              );
          }
      })
  }
 // Adding Asset Function
    AddAsset(){
       this.apiService.create('asset/addUserToAsset', this.AddAssetData).subscribe(
      data => {
          if(data.statusCode == 100){
              this.getFixedScheduleData();
              this.assetData = [];
              this.alertService.sweetMessage('success','Asset Assigned Successfully');
              this.AddAssetData ={"user":{"userId":this.userId},"asset":{"assetId":""},"assetRole":{"assetRoleId":""}};
          }else{
              this.alertService.sweetMessage('error',data.responseMessage);
              }
            },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

  /* getModelData*/
  getModelData(){
    this.getUnassignAsset();
    this.getassetRole();
  }


 /* Get unassigned assets */
  getUnassignAsset(){
    this.apiService.getAll('asset/getUnAssingedAsset?clientId='+this.clientId+'&userId='+this.userId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.unAssingedAssetList = data.responseList;
                } else {
                    this.unAssingedAssetList = [];
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }
   /* Get Asset Role */
    getassetRole(){
      this.apiService.getAll('asset/getAllAssetRole').subscribe(
              data => {
                  if (data.statusCode == 100) {
                      this.assetRoleList = data.responseList;
                  } else {
                      this.assetRoleList = [];
                  }
              },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
          );
    }
}
