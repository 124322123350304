import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.css']
})
export class SiteManagementComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
sitemanagementDatalist:any = [];
siteId:any = null;
dataTable: any;
dropdownList = [];
countyList:any=[];
dropdownSettings = {};
dropdownSettingsState={};
search:any='';
county:any='';
checkStateArray:any=[];
countryId:number=0;
state:any='';
countryList:any=[];
stateList:any=[];
siteLat:number = 39.381266;
siteLong:number = -97.922211;
permissionData:any=[];
siteName:string="";
mapMarkerPositions:any = []
selectedItemsRoot:any=[];
clientCode:any ='';
zoomLevel : any;
bounds: any;
map: any;
assignAsset:any=[];
selectedUserId:any='';
  siteHistoryList: any = [];

constructor(private router:Router, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {

    this.permissionData = ApiService.permission;
}

 DateFormat(startDate){
  if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
  }else{
      return "";
    }
 }

 DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}
ngOnInit() {
  this.clientCode = this.alertService.getClientCode();
  this.getSiteManagementList();
  this.getCountryList();
  this.getCountyList();

  this.dropdownSettingsState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
}

setLatLong(lat,long,name){
  this.siteLat  = lat;
  this.siteLong = long;
  let arr = []
  // this.mapMarkerPositions = [lat, long];
  arr.push([lat, long]);
  this.mapMarkerPositions = arr
  this.siteName = name;


  this.bounds = new google.maps.LatLngBounds();
  let loc = new google.maps.LatLng(lat, long);

  this.bounds.extend(loc);
  var data = this.bounds.getCenter();
  this.zoomLevel = 21;

}



/* On Search */
seachRecord(){
  this.state  = this.checkStateArray;
  this.getSiteManagementList();
}
/* Reset Search Record */
resetSearch(){
  this.selectedItemsRoot = [];
  this.search = "";
  this.county="";
  this.countryId=0;
  this.state="";
  this.checkStateArray=[];
  this.getSiteManagementList();

  //this.onDeSelectAll();
}
/* On Item Select State */
onItemSelectState(item: any) {
    var exist = this.checkStateArray.indexOf(item.stateId.toString());
    if(exist == '-1'){
        this.checkStateArray.push(item.stateId.toString());
      }
  }

/* On Item DeSelect State */
onItemDeselectState(item: any){
  var exist = this.checkStateArray.indexOf(item.stateId.toString());
    if(exist != '-1'){
        this.checkStateArray.splice(exist,1);
      }
}
/* On All Select State */
onSelectAllState(items: any) {
  this.checkStateArray = [];
  Object.keys(items).forEach(key=> {
      this.checkStateArray.push(items[key]['stateId'].toString());
    });
}
/* On All DeSelect State */
onDeSelectAllState(){
  this.checkStateArray = [];
}

/* Get CountyList list */
getCountyList(){
  var URL = 'site/getSiteCountyByClient?clientId='+this.clientId+'&userId='+this.userId;
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.countyList = data.responseList;
        }else{
          this.countyList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
         }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
      }
/* Get state list by country id */
getStateListByCountryId(countryId){
  if(countryId != 0){
    this.selectedItemsRoot = [];

  this.apiService.getAll('state/getStateList/'+countryId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.stateList = data.responseList;
        }else{
          this.stateList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
         }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
  }
  else{
    this.stateList = [];
    this.selectedItemsRoot = [];
  }
}

/* Get Country list */
getCountryList(){
   this.apiService.getAll('country/getCountry').subscribe(
   data => {
       if(data.statusCode == 100){
          this.countryList = data.responseList;
        }else{
          this.countryList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
         }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
      }
/* Get Site Management list */
getSiteManagementList(){
    //site/getSiteByClient?clientId=1&userId=2&customerId=1,4&tierId=6,10&countryId=1&stateId=3,1025&search=G&county=searchCounty
   var URL = 'site/getSiteByClient?alphabateSortingOreder=1&clientId='+this.clientId+'&userId='+this.userId;
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
  if(this.countryId != 0){
    var countryString  = '&countryId='+this.countryId;
    var URL = URL.concat(countryString);
  }
  if(this.state != ''){
    var stateString    = '&stateId='+this.state;
    var URL = URL.concat(stateString);
  }
  if(this.search !=''){
    var searchString   = '&search='+this.search;
    var URL = URL.concat(searchString);
  }
  if(this.county !=''){
    var countyString   = '&county='+this.county;
    var URL = URL.concat(countyString);
  }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.sitemanagementDatalist = data.responseList;
           if ($.fn.DataTable.isDataTable('#site_table')){
                $('#site_table').DataTable().destroy();
              };
           this.chRef.detectChanges();
           const table: any = $('#site_table');
          this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 6 }]});
        }else{
            if ($.fn.DataTable.isDataTable('#site_table')){
                $('#site_table').DataTable().destroy();
              };
          this.sitemanagementDatalist = [];
          this.chRef.detectChanges();
          const table: any = $('#site_table');
          this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 6 }]});

          /*this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); */
         }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
}


/* Delete Customer Management list */
DeleteSite(sitedatalist){
 Swal({
  title: 'Are you sure?',
  text: "You want to delete this Site.",
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete it!'
}).then((result) => {
  if (result.value) {
   this.apiService.delete('site/deleteSite?siteId='+sitedatalist.siteId, '').subscribe(
   data => {
       if(data.statusCode == 100){
         Swal({
              position: 'top-end',
              type: 'success',
              title: 'Site Deleted Successfully',
              showConfirmButton: false,
              timer: 1500
            }) ;

             this.getSiteManagementList();

        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
}
})
}

getAssignAssets(sitedatalist){
  this.assignAsset = [];
  // var URL = 'asset/getAssetByClient?clientId='+this.clientId+'&userId='+this.userId+'&siteId='+sitedatalist.siteId+"&isAssetLive=true";
  // var URL = 'asset/getAssetByClient?clientId='+this.clientId;
  // $('#assignAssetTable').empty();
  var URL = 'asset/getAssetByClient';
  let apiData: any = {
    clientId: this.clientId,
    userId: this.userId,
    isAssetLive: true,
    siteIds: sitedatalist.siteId.toString()
  }
  if ($.fn.DataTable.isDataTable('#assignAssetTable')){
    $('#assignAssetTable').DataTable().destroy();
  };   
  this.apiService.create(URL, apiData).subscribe(
        data => {
            if (data.statusCode == 100) {
              this.assignAsset = [];
              this.assignAsset = data.responseList;
              this.chRef.detectChanges();
              setTimeout(() => {
                const table: any = $('#assignAssetTable');
                this.dataTable = table.DataTable({"bInfo": false});
              }, 100);
            } else {
              this.assignAsset = [];
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}


SiteHistory(sitedatalist){
  this.siteHistoryList = []
  if ($.fn.DataTable.isDataTable('#siteHistoryDatatable')) {
    $('#siteHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('site/getSiteHistory?siteId=' + sitedatalist.siteId).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.siteHistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#siteHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#siteHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

}
