
import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2';
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

import * as _ from "lodash";


@Component({
  selector: 'app-new-notificatio-plan-list',
  templateUrl: './new-notificatio-plan-list.component.html',
  styleUrls: ['./new-notificatio-plan-list.component.css']
})
export class NewNotificatioPlanListComponent implements OnInit {

  
  // Initializing Variables
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  notificationPlanData:any=[];
  assetDatalist : any = [];
  dataTable: any;
  dropdownSettingsSite = {};
  dropdownSettingsAsset={};
  search:any='';
  site:any='';
  asset:any='';
  checkSiteArray:any=[];
  checkAssetArray:any=[];
  siteList:any=[];
  permissionData:any=[];
  clientCode : any = '';
  cleardata:any;
  clearSearch:any;
  siteSelectAllStatus:boolean = false;
  assetSelectAllStatus:boolean = false;
  HistoryList: any = [];
  groupEscLevel: any = {};


  constructor(private router:Router, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate){
    if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
      return "";
    }
  }

  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

  ngOnInit() {

    $(document).on('hidden.bs.modal', function () {
      if($('.modal.show').length)
      {
        $('body').addClass('modal-open');
      }
    });
      
    this.clientCode = this.alertService.getClientCode();
    this.getNotificationPlanData();
    this.getSiteManagementList();
    this.getAllAssetList();

      this.dropdownSettingsSite = {
        singleSelection: false,
        idField: 'siteId',
        textField: 'siteName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        // limitSelection: 1000,
        allowSearchFilter: true
      };
      this.dropdownSettingsAsset = {
        singleSelection: false,
        idField: 'assetId',
        textField: 'assetName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        // limitSelection: 1000,
        allowSearchFilter: true
      };
  }

    /* On Item Select Site */
    onItemSelectSite(item: any) {
        var exist = this.checkSiteArray.indexOf(item.siteId.toString());
        if(exist == '-1'){
            this.checkSiteArray.push(item.siteId.toString());
            if(this.siteList.length == this.checkSiteArray.length) {
              this.siteSelectAllStatus = true;
            }
          }
        // if(this.checkSiteArray.length == 1000) {
        //   this.alertService.sweetMessage('error','Can not select more than 1000 sites');
        // }
    }

    /* On Item DeSelect Site */
    onItemDeselectSite(item: any){
      var exist = this.checkSiteArray.indexOf(item.siteId.toString());
      this.siteSelectAllStatus = false;
        if(exist != '-1'){
            this.checkSiteArray.splice(exist,1);
          }
    }

    /* On All Select Site */
    onSelectAllSite(items: any) {
      this.checkSiteArray = [];
      this.siteSelectAllStatus = true;
      Object.keys(items).forEach(key=> {
          this.checkSiteArray.push(items[key]['siteId'].toString());
        });
    }

    /* On All DeSelect Site */
    onDeSelectAllSite(){
      this.checkSiteArray = [];
      this.siteSelectAllStatus = false;
    }

    /* On Item Select Asset */
    onItemSelectAsset(item: any) {
        var exist = this.checkAssetArray.indexOf(item.assetId.toString());
        if(exist == '-1'){
            this.checkAssetArray.push(item.assetId.toString());
            if(this.assetDatalist.length == this.checkAssetArray.length) {
              this.assetSelectAllStatus = true;
            }
          }

        // if(this.checkAssetArray.length == 1000) {
        //   this.alertService.sweetMessage('error','Can not select more than 1000 asset');
        // }
    }

    /* On Item DeSelect Asset */
    onItemDeselectAsset(item: any){
      var exist = this.checkAssetArray.indexOf(item.assetId.toString());
      this.assetSelectAllStatus = false;
        if(exist != '-1'){
            this.checkAssetArray.splice(exist,1);
          }
    }

    /* On All Select Asset */
    onSelectAllAsset(items: any) {
      this.checkAssetArray = [];
      this.assetSelectAllStatus = true;
      Object.keys(items).forEach(key=> {
          this.checkAssetArray.push(items[key]['assetId'].toString());
        });
    }

    /* On All DeSelect Site */
    onDeSelectAllAsset(){
      this.checkAssetArray = [];
      this.assetSelectAllStatus = false;
    }

    /* On Search */
    seachRecord(){
      this.site     = this.checkSiteArray;
      this.asset     = this.checkAssetArray;
      this.getNotificationPlanData();
    }

    /* Reset Search Record */
    resetSearch(){
      this.cleardata = [];
      this.clearSearch = "";
      this.site = "";
      this.asset="";
      this.checkSiteArray=[];
      this.checkAssetArray=[];
      this.getNotificationPlanData();
    }

    /* Get Site Management list */
    getSiteManagementList(){
      var URL = 'site/getSiteByClient?clientId='+this.clientId+'&userId='+this.userId+"&alphabateSortingOreder=1";
      if(this.customerId != null && this.customerId !=''){
        var customerString     = '&customerId='+this.customerId.toString();
        var URL = URL.concat(customerString);
      }
      if(this.tierId != null && this.tierId !=''){
        var tierString     = '&tierId='+this.tierId.toString();
        var URL = URL.concat(tierString);
      }
       this.apiService.getAll(URL).subscribe(
       data => {
           if(data.statusCode == 100){
              this.siteList = data.responseList;
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
             }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
    }

    // Get all assets List
    getAllAssetList(){
      var URL = 'asset/getAssetDropDownByClient'
  

      let apiData : any = {
        clientId: this.clientId ? this.clientId : null,
        userId: this.userId ? this.userId : null        
      }

      if(this.customerId != null && this.customerId !=''){
        apiData.customerId = this.customerId;        
        // var customerString     = '&customerId='+this.customerId.toString();
        // apiData.customerId = this.customerId.toString();
        // var URL = URL.concat(customerString);
      }
      if(this.tierId != null && this.tierId !=''){
        apiData.tierId = this.tierId.toString();
        // var tierString = '&tierId='+this.tierId.toString();
        // apiData.tierId = this.tierId.toString();
        // var URL = URL.concat(tierString);
      }

      if (this.siteSelectAllStatus) {
	      apiData.siteId = '0'
	    }
        this.apiService.create(URL, apiData).subscribe(
          data => {
            if(data.statusCode == 100){
              this.assetDatalist = data.responseList;
            }else{
               this.assetDatalist = [];
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get Notification plan data list */
    getNotificationPlanData(){
      //notification_plan/getNotificationPlanList? clientId=1&userId=2&tierId=4,13&siteId=34,35&assetId=12920,13300&customerId=1&search=p
      var URL = 'notification_plan/getNewNotificationPlanList';
      let apiData: any = {
        clientId: this.clientId,
        userId: this.userId
      }
    
    
    /* -------------------------if required in api uncomment code ----------------------- */
        if(this.checkSiteArray !='' && this.checkSiteArray.length){
          apiData.siteIds = this.checkSiteArray.toString();
        // var siteString     = '&siteIdArr='+this.checkSiteArray.toString();
        // var URL = URL.concat(siteString);
      }
      if(this.checkAssetArray !='' && this.checkAssetArray.length){
        apiData.assetIds = this.checkAssetArray.toString();
        // var assetString     = '&assetIdArr='+this.checkAssetArray.toString();
        // var URL = URL.concat(assetString);
      }
     if(this.customerId != null && this.customerId !=''){
       apiData.customerIds = this.customerId.toString();
        // var customerString     = '&customerIdArr='+this.customerId.toString();
        // var URL = URL.concat(customerString);
      }
      if(this.tierId != null && this.tierId !=''){
        apiData.tierIds = this.tierId.toString();
        // var tierString     = '&tierIdArr='+this.tierId.toString();
        // var URL = URL.concat(tierString);
      }
    //    if(this.search !=''){
    //     var searchString   = '&search='+this.search;
    //     var URL = URL.concat(searchString);
    //   }

    	this.apiService.create(URL, apiData).subscribe(
        data => {
            if(data.statusCode == 100){
                this.notificationPlanData = data.responseList;
                if ($.fn.DataTable.isDataTable('#notifi_table')){
                    $('#notifi_table').DataTable().destroy();
                  };

                this.chRef.detectChanges();
                const table: any = $('#notifi_table');
                this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 5 }]});

            }else{
                this.notificationPlanData = [];
                if ($.fn.DataTable.isDataTable('#notifi_table')){
                    $('#notifi_table').DataTable().destroy();
                  };
                this.chRef.detectChanges();
                const table: any = $('#notifi_table');
                this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 5 }]});

                /*this.alertService.sweetMessage('error',data.responseMessage); */
    	            }
                },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Delete Notification Management list */
    DeleteNotification(notification){
      Swal({
          title: 'Are you sure?',
          text: "You want to delete this Notification.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
           this.apiService.delete('notification_plan/deleteNewNotificationPlan?notificationPlanId='+notification.notificationPlanId, "").subscribe(
           data => {
               if(data.statusCode == 100){
                 this.alertService.sweetMessage('success','Notification Deleted Successfully');

                     this.getNotificationPlanData();


                }else{
                  this.alertService.sweetMessage('error',data.responseMessage);
                 }
              },
             error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
        }
        })
    }

    getHistory(id){
      this.HistoryList = []
      if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
        $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
      };
          this.apiService.getAll('notification_plan/getNewNotificationPlanHistoryById?notificationId=' + id).subscribe(
            data => {
              if (data.statusCode == 100) {
                this.HistoryList = data.responseList;
                setTimeout(() => {
                  const table: any = $('#HistoryDatatable');
                  this.dataTable = table.DataTable({ "order": [], "destroy": true });
                }, 500);
              } else {
                setTimeout(() => {
                  const table: any = $('#HistoryDatatable');
                  this.dataTable = table.DataTable({ "order": [], "destroy": true });
                }, 500);
                Swal({
                  position: 'top-end',
                  type: 'error',
                  title: data.responseMessage,
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          ); 
    }

    getAssetTooltip(lists){
      let str = ''
      if (lists) {
        if (lists.length) {
          lists.map((obj,index)=>{
            if (index==0) {
              str += obj.assetName
            }else{
              str = str+', '+obj.assetName
            }
          })
        }
      }
      return str;
    }

    getTierTooltip(list){
      let str = ''
      if (list) {
        if (list.length) {
          list.map((obj,index)=>{
            if (index==0) {
              str += obj.tierName
            }else{
              str = str+', '+obj.tierName
            }
          })
        }
      }
      return str;
    }

    setGroupList(escalationLevellist){
      var grouped = _.groupBy(escalationLevellist, 'rollname')
      console.log(grouped);
      this.groupEscLevel = grouped;
      
    }
}

