import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'

/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: 'app-asset-summary-report',
  templateUrl: './asset-summary-report.component.html',
  styleUrls: ['./asset-summary-report.component.css']
})
export class AssetSummaryReportComponent implements OnInit {
  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('tierArea') tierArea: ElementRef;
  @ViewChild('treeArea') treeArea: ElementRef;

    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    dataTable: any;
    reportListData: any = [];
    clientCode : any = '';
    permissionData:any=[];
    // records: any = [];
    // disableTreeArray: any = [];
    // checkTreeArray: any = null;
    source: any = {};
    // customerId: any = null;
    // tiersId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
    customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
    tiersId:any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")):[]);

    data: any = [];
    dataAdapter: any = [];
    // source: any = {};
    records: any = [];
    disableTreeArray: any = [];
    checkTreeArray: any = [];
    tiersArray: any = [];
    dropdownSettingsSite:any = {};
    siteList: any = [];
    selectedSite: any = [];
    checkSiteArray: any = [];
    siteIdForApi:any;
    selectAllTier = false;
    reportData:any = [];
    tierIdForApi:any;
    filterObj:any = {}
    showTier: boolean = false;
    hideTempTier: boolean = false;
    downloadUrl:any="";
    tierToggleClass: any = "";
    tierPlaceholder:any = "Select Tier";
    emailData:any = {
      emails:'',
      message:''
    };
    emailArray = [];
    showValidEmailErr:boolean = false;
    showMsgErr:boolean = false;
    showEmailErr:boolean = false;
    emailIds:any = "";
    emailBodyMsg:any = "";
    showTable:boolean=false;
    newCheckDateDiff:boolean = false;
    enableTreeArray:any = [];
    disableTreeLength:any=0;
    newDisableTreeArray:any=[];
    emailIdArr:any = [];
    // emailObj : any;
    emailObj:any={};
    excelFile: any = '';

  constructor(
      private router: Router,
      private apiService: ApiService,
      private route: ActivatedRoute,
      private alertService: AlertService,
      private formatDateService: FormatDateService,
      private chRef: ChangeDetectorRef,
      private loading: LoadingBarService,
      private eRef: ElementRef, private sanitizer: DomSanitizer
      ) {
      this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
      return this.formatDateService.formatDate(startDate, gmtOffset);
  }

  onClick(event) {
    // if (!this.eRef.nativeElement.contains(event.target)){
    //   this.showTier = false;
    // }
    if (!(this.tierArea.nativeElement.contains(event.target))) {
      this.showTier = false;
    }

    if (!this.showTier) { 
      this.tierToggleClass = ""; 
    } else { 
      if (!(this.tierArea.nativeElement.contains(event.target)) && !(this.treeArea.nativeElement.contains(event.target))) {        
       this.showTier = false;
       this.tierToggleClass = "";
      }else{
        this.tierToggleClass = "site-m-after";
      } 
    }
  }

  ngOnInit() {
        // this.clientCode = this.alertService.getClientCode();
        // this.checkTreeArray = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
        // this.getAllReportList();

        this.getTierList({value:1});
        this.dropdownSettingsSite = {
          singleSelection: false,
          idField: 'siteId',
          textField: 'siteName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 0,
          allowSearchFilter: true,
          placeholder : "Select Site" 
        };
        this.clientCode = this.alertService.getClientCode();
        this.initializeDataTable()
  }

  initializeDataTable(){
    if ($.fn.DataTable.isDataTable('#report_table')){
      $('#report_table').DataTable().destroy();
    };
    this.dataTable   = $('#report_table').DataTable();
  }

  getAllReportList() {
    	  // var tiersId = this.tiersId;
        // var par = '';
        // if(tiersId != '')
        // {
        //     par = '&tierId='+tiersId;
        // }
        // var URL = 'report/getAssetSummaryReportList?userId='+this.userId+'&clientId='+this.clientId+par
        var URL = 'report/getAssetSummaryReportList?userId='+this.userId+'&clientId='+this.clientId;
        if(this.customerId != null && this.customerId !=''){
          var customerString = '&customerId='+this.customerId.toString();
          var URL = URL.concat(customerString);
        }
        if(this.tiersId != null && this.tiersId !=''){
          var tierString = '&tierId='+this.tiersId.toString();
          var URL = URL.concat(tierString);
        }
        this.apiService.getAll(URL).subscribe(
            data => {
                    if (data.statusCode == 100) {
	                    this.reportListData = data.responseList;
	                    if ($.fn.DataTable.isDataTable('#report_table')){
	                        $('#report_table').DataTable().destroy();
	                    };
	                    this.chRef.detectChanges();
	                    const table: any = $('#report_table');
	                    this.dataTable = table.DataTable({
	                    	"order": [],
	                    	"columnDefs": [{ "orderable": false, "targets": 4 }]
					            });
	                } else {
	                    this.reportListData = [];
	                    this.chRef.detectChanges();
	                    const table: any = $('#report_table');
	                    this.dataTable = table.DataTable({
  	                    	"order": [],
  	                    	"columnDefs": [{ "orderable": false, "targets": 4 }]
        					    });
	                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
  }

  downloadReport(){
    	  var tiersId = this.tiersId;
        var par = '';
        if(tiersId != ''){
            par = '&tierId='+tiersId;
        }
    	  var URL = 'report/getDownloadAssetSummaryReport?userId='+this.userId+'&clientId='+this.clientId+par
        var mainUrl = this.apiService.baseBath+'/'+URL;
          $("#downloadReportBtn").attr("href", mainUrl);
          var te = document.getElementById('downloadReportBtn');
          te.click();
          $("#loaderImgIcon").css("display","none");
  }

  deleteReport(reportId){
	 Swal({
	  title: 'Are you sure?',
	  text: "You want to delete this Report.",
	  type: 'warning',
	  showCancelButton: true,
	  confirmButtonColor: '#3085d6',
	  cancelButtonColor: '#d33',
	  confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.value) {
      this.apiService.getAll('report/deleteReport?reportId='+reportId).subscribe(
      data => {
       if(data.statusCode == 100){
         Swal({
              position: 'top-end',
              type: 'success',
              title: 'Report Deleted Successfully',
              showConfirmButton: false,
              timer: 1500
            }) ;

             this.getAllReportList();

        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
      }
    })
  }

  showReportInfo(reportId){
    console.log(reportId);
  }

  /* Handle tier tree chacked unchecked Event */
  CheckChange(event) {
    
    if (event.args.checked == true) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist == '-1') {
        this.checkTreeArray.push(event.args.element.id);
        setTimeout(() => {
          this.getSiteManagementList();
        }, 100);
      }
    } else if (event.args.checked == false || event.args.checked == null) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist != '-1') {
        this.checkTreeArray.splice(exist, 1);
        setTimeout(() => {
          this.getSiteManagementList();
        }, 100);
      }
    }

    if (this.checkTreeArray.length == 0) {
      this.dropdownSettingsSite.placeholder = "Select a tier first" 
      this.tierPlaceholder = "Select Tier";
    }else{
      this.dropdownSettingsSite.placeholder = "Select Site" 
      this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
    }
        
    // if (this.data.length == this.checkTreeArray.length) {
    //   this.selectAllTier = true            
    // }else{
    //   this.selectAllTier = false
    // }

    if(this.disableTreeLength==0) {
      if (this.data.length == this.checkTreeArray.length) {
        this.selectAllTier = true            
      }else{
        this.selectAllTier = false
      }
    }else{
      let actualLength = this.data.length-this.disableTreeLength;
      if (actualLength == this.checkTreeArray.length) {
        this.selectAllTier = true            
      }else{
        this.selectAllTier = false
      }
    }

  }

  /* Get Site Management list */
  getSiteManagementList() {
    this.siteList = []
    this.selectedSite = [];
    this.checkSiteArray = [];
    this.siteIdForApi = "";

      // var URL = 'site/getSiteByClient?alphabateSortingOreder=1&clientId=' + this.clientId + '&userId=' + this.userId;
      var URL = 'site/getSiteDropDownListByClient?clientId=' + this.clientId + '&userId=' + this.userId;
      let Tier = this.checkTreeArray.join();
      if (Tier != null && Tier != '') {
          var tierString = '&tierId=' + Tier.toString();
          var URL = URL.concat(tierString);
      }
      if (this.checkTreeArray.length == 0) {    
          this.siteList = []
          this.selectedSite = [];
      }else {
          this.startLoading();
          this.apiService.getAllDashboard(URL).subscribe(
              data => {
                  if (data.statusCode == 100) {
                    if(this.checkTreeArray.length != 0) {
                      this.siteList = data.responseList;
                      let tempSelected = []
                      this.selectedSite.map((obj, index)=>{
                          let findObj = _.find(this.siteList, { siteId: obj.siteId});
                          if (findObj) {
                              tempSelected.push(obj);
                          }
                      })
                      this.selectedSite = tempSelected;
                    }
                  } else {
                      this.siteList = [];
                  }
                  this.stopLoading();
              },
              error =>{
                this.stopLoading();
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
      }
  }

  showHideTierTree(event){
    if(!this.showTier){
      this.showTier = true;
      this.tierToggleClass="site-m-after";
    }else{
      this.showTier = false;
      this.tierToggleClass="";
    }
  }

  hideTier(){
    this.showTier = false;
  }

  // Get all tier list
  getTierList(type) {
    var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
    if (type.value != 0 || type.value != '0') {
      URL +="&type="+type.value
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.data = data.responseList;
          
          Object.keys(this.data).forEach(key => {
            if (this.data[key]['flag'] == false) {
              this.disableTreeArray.push(this.data[key]['tierId']);
            }
          });
          // disable tree length
          this.getEnableDisableTier()
          
          this.source = {
            datatype: 'json',
            datafields: [
                {name: 'tierId'}, 
                {name: 'parentTierId'},
                {name: 'tierName'}
              ],
              id: 'tierId',
              localdata: this.data
          };
          this.dataAdapter = new jqx.dataAdapter(this.source, {
            autoBind: true
          });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [
                            {
                              name: 'tierName',
                              map: 'label'
                            }, 
                            {
                              name: 'tierId',
                              map: 'id'
                            }]);
          var self = this;
          setTimeout(function() {
            self.treeItemDisable();
          }, 500);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // Function to disable items
  treeItemDisable() {
    Object.keys(this.disableTreeArray).forEach(key => {
        let checkedItem = document.getElementById(this.disableTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.disableItem(checkedItem);
    });
    if (this.checkTreeArray.length != 0) {
      this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
    }else{
      this.tierPlaceholder = "Select Tier";
    }
  }

  // Select all function for tier
  selectAllTree(event){
    this.checkTreeArray = [];
    if (event.target.checked) {
        for (let object of this.data) {
          if(object.flag){
            this.checkTreeArray.push(object.tierId.toString());
          }
        }
        this.checkedCheckboxOnLoad()
    }else{
        this.checkTreeArray = []
        this.checkedCheckboxOnLoad()
        for (let object of this.data) {
            let checkedItem = document.getElementById(object.tierId.toString());
            this.myTree.expandItem(checkedItem);
            this.myTree.checkItem(checkedItem, false);
        }
    }
    if (this.checkTreeArray.length != 0) {
      this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
    }
    setTimeout(() => {
        this.getSiteManagementList();
    }, 100);
  }

  // checked checkbox on load of tree
  checkedCheckboxOnLoad() {
    Object.keys(this.checkTreeArray).forEach(key => {
        let checkedItem = document.getElementById(this.checkTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem, true);
    });
  }

  /* On item select site */
  onItemSelectSite(item: any) {
    this.showTier = false;
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if (exist == '-1') {
        this.checkSiteArray.push(item.siteId.toString());
        this.siteIdForApi = this.checkSiteArray.join();
    }
  }

  /* On item de-select site */
  onItemDeselectSite(item: any) {
    this.showTier = false;
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if (exist != '-1') {
        this.checkSiteArray.splice(exist, 1);
        this.siteIdForApi = this.checkSiteArray.join();
    }
  }

  /* On select all site */
  onSelectAllSite(items: any) {
    this.showTier = false;
    this.checkSiteArray = [];
    this.siteIdForApi = this.checkSiteArray.join();

    Object.keys(items).forEach(key => {
        this.checkSiteArray.push(items[key]['siteId'].toString());
        this.siteIdForApi = this.checkSiteArray.join();
    });
  }

  /* On de-select all site */
  onDeSelectAllSite() {
    this.showTier = false;
    this.checkSiteArray = [];
    this.siteIdForApi = this.checkSiteArray.join();
  }

  // Run Report
  runReport() {
    this.showTable = true;
    this.showTier = false;
    this.reportData = [];
    if ($.fn.DataTable.isDataTable('#report_table')){
      $('#report_table').DataTable().destroy();
      this.reportData=[];
    }
    this.tierIdForApi = this.checkTreeArray.join();
    var URL = 'report/getAssetSummaryReportList';
    let apiData: any = {
      clientId: this.clientId,
      startLimit: 0,
      endLimit: 1000
      // userId: this.userId,
    }

    if (this.tierIdForApi != null && this.tierIdForApi != '') {
      apiData.tierIds = this.tierIdForApi.toString();
        // var tierString = '&tierId=' + this.tierIdForApi.toString();
        // var URL = URL.concat(tierString);
    }
    
    if (this.siteIdForApi != null && this.siteIdForApi != '') {
      apiData.siteIds = this.siteIdForApi.toString();
        // var siteString = '&siteId=' + this.siteIdForApi.toString();
        // var URL = URL.concat(siteString);
    }

    this.apiService.create(URL, apiData).subscribe(
      data => {
          if (data.statusCode == 100) {
            $('#no-record-found').css('display', 'none');
              this.reportData = [];
              this.reportData = data.responseList;
              this.chRef.detectChanges();
              if ($.fn.DataTable.isDataTable('#report_table')){
                $('#report_table').DataTable().destroy();
              };
              const table: any = $('#report_table');
              this.dataTable   = table.DataTable();
          }
          else
          { 
            $('#no-record-found').css('display', 'block');
              this.reportData = [];
              if ($.fn.DataTable.isDataTable('#report_table')){
                $('#report_table').DataTable().destroy();
              };
              const table: any = $('#report_table');
              this.chRef.detectChanges();
              this.dataTable = table.DataTable();
          }

      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  clickEmail() {
    $('#no-record-found').css('display', 'block');
  }

  // download excel report
  excelReport(){
    this.showTier = false;
    if(this.reportData.length!=0){
      $("#downloadReportBtn").attr("href", '');
      this.tierIdForApi = this.checkTreeArray.join();
      var URL = 'report/getDownloadAssetSummaryReport';
      
      let apiData: any = {
        clientId: this.clientId,
        userId: this.userId,
      }
  
      if (this.tierIdForApi != null && this.tierIdForApi != '') {
        apiData.tierIds = this.tierIdForApi.toString();
          // var tierString = '&tierId=' + this.tierIdForApi.toString();
          // var URL = URL.concat(tierString);
      }
      
      if (this.siteIdForApi != null && this.siteIdForApi != '') {
        apiData.siteIds = this.siteIdForApi.toString();
          // var siteString = '&siteId=' + this.siteIdForApi.toString();
          // var URL = URL.concat(siteString);
      }

      this.apiService.downloadFile(URL, apiData).subscribe(        
        (data: any) => {
          let resData = JSON.stringify(data)
          var blob = new Blob([data._body], { type: 'application/octet-stream' });
          this.excelFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
          setTimeout(() => {
            var te = document.getElementById('downloadReportBtn');
            te.click();
          }, 2000);
        },
        error => {
          this.alertService.sweetMessage('error',"Something went wrong, please try again ");
          // var mainUrl = this.apiService.baseBath + '/' + URL;
          // this.downloadUrl = mainUrl;
          // $("#downloadReportBtn").attr("href", mainUrl);
          // var te = document.getElementById('downloadReportBtn');
          // te.click();
          // $("#loaderImgIcon").css("display", "none");
        }
      );
    } else {
      $('#no-record-found').css('display', 'block');
    }
  }

  // Email Report
  emailReport() {
    this.showTier = false;
    this.emailObj={};
    this.emailObj.type = 'AssetSummaryReport';
    this.emailObj.clientId = this.clientId;
    if(this.reportData.length!=0){
      this.tierIdForApi = this.checkTreeArray.join();
      var URL = 'report/sendAssetSummaryReportEmail';

      if (this.tierIdForApi != null && this.tierIdForApi != '') {
          this.emailObj.tierId = this.checkTreeArray;
      }
      
      if (this.siteIdForApi != null && this.siteIdForApi != '') {
          this.emailObj.siteId = this.checkSiteArray;
      }

      if(this.emailIdArr.length > 0){
        this.emailObj.emailIds = this.emailIdArr;      
      }

      if(this.emailBodyMsg!=""){
        this.emailObj.emailBodyMessage = this.emailBodyMsg;
      }
      
      // console.log('request', this.emailObj);

      this.apiService.create(URL, this.emailObj).subscribe(
        data => {
            if (data.statusCode == 100) {
              $("#closeModal").click();
              this.resetModalData();
              this.alertService.sweetMessage('success',"Report has been sent");
            }
            else
            { 
              this.alertService.sweetMessage('error', data.responseMessage);
            }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  // verify email validations
  emailVerify() {    
    this.emailBodyMsg = "";
    let allEmailValid = false;
    if(this.emailData.emails!=""){
      this.emailArray  = this.emailData.emails.split(',');
      if((this.emailArray.length != 0) && (this.emailArray.length > 0)){
        let emailExp = new RegExp("[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,3}$")
        this.emailArray.forEach((data,index) => {
          // console.log("data=======",data,'++++++++++',emailExp.test(data))
          if (!emailExp.test(data)) {
            this.showValidEmailErr = true
            allEmailValid = true
          }
        })
      }
    }

    // check for email required
    if(this.emailData.emails) {
      this.showEmailErr = false;
    }else {
      this.showEmailErr = true;
      this.showValidEmailErr = false;
    }

    // check for valid email id
    if (allEmailValid) {
      this.showValidEmailErr = true
    }else {
      this.showValidEmailErr = false
    }  

    // check for msg
    // if (this.emailData.message) {
    //   this.showMsgErr = false
    // }else {
    //   this.showMsgErr = true
    // }

    if ((!this.showEmailErr) && (!this.showValidEmailErr)) {
      // let emailString = this.emailArray.toString();
      // let emails = emailString.split(',');
      // this.emailIds = emailString;
      this.emailBodyMsg = this.emailData.message;
      if(this.emailArray.length > 0){
        this.emailIdArr=[];
        this.emailArray.forEach((data,index) => {
          var exist = this.emailIdArr.indexOf(data.trim());
          if (exist == '-1') {
            this.emailIdArr.push(data.trim());
          }
        });
        console.log("emailIdArr---", this.emailIdArr)
      } 
      if(this.emailBodyMsg!=""){
        this.emailBodyMsg = this.emailBodyMsg.replace(new RegExp('\n', 'g'), "<br/>");
      }
      this.emailReport();
    }

    // if ((!this.showEmailErr) && (!this.showValidEmailErr)) {
    //   let emailString = this.emailArray.toString();
    //   this.emailIds = emailString;
    //   this.emailBodyMsg = this.emailData.message;
    //   this.emailReport();
    // }

  }

  resetModalData() {
    this.emailData = {
      emails:'',
      message:''
    };
    this.emailArray = [];
    this.showEmailErr = false;  
    this.showValidEmailErr = false;
    // this.showMsgErr = false;
  }

  getEnableDisableTier(){
    for (let object of this.data) {
      if(object.flag){
        this.enableTreeArray.push(object.tierId.toString());
      }else{
        this.newDisableTreeArray.push(object.tierId.toString());
      }
    }
    this.disableTreeLength = this.newDisableTreeArray.length;
  }

  startLoading() {
    this.loading.start();
  }
  
  stopLoading() {
    this.loading.complete();
  }

}
