import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService } from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3: any, moment: any;
import * as _ from "lodash";

@Component({
  selector: 'app-addrole',
  templateUrl: './addrole.component.html',
  styleUrls: ['./addrole.component.css']
})
export class AddRoleComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  isGlobalUser: any = JSON.parse(localStorage.getItem("userData")).isGlobalUser;
  roleId: number = 0;
  isGlobal: any = false;
  permissionData: any = [];
  permissionPData: any = [];
  groupName: any = {};
  roleArray: any = [];
  roleFilter: any = [];
  AllroleArray: any = [];
  roleDatalist: any = [];
  roleData: any = { "isCustomerRole" : false ,"roleName": "", "client": { "clientId": this.clientId }, "permissions": [] }
  clientCode: any = '';

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService) {
    this.permissionPData = ApiService.permission;
  }

  DateFormat(startDate) {
    if (startDate) {
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    } else {
      return "";
    }
  }
  ngOnInit() {

    this.clientCode = this.alertService.getClientCode();
    this.getAllPermissionList();
    this.getAllRoleList();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.roleId = params['id'];
      }
    });
    
  }


  /* checkall Check Section */
  checkAll(event, permdata) {
    if (event.target.checked == true) {
      this.roleArray = [];
      $('.uroles-type-select input').prop('checked', true);
      for (var i = 0; i < this.permissionData.length; i++) {
        for (var j = 0; j < this.permissionData[i].permissions.length; j++) {
          this.roleArray.push(this.permissionData[i].permissions[j].permissionId);
        }
      }
    } else {
      $('.uroles-type-select input').prop('checked', false);
      this.roleArray = [];
    }
  }


  /* checked permssion by Section */
  checkTick(event, permData) {
    this.groupName = permData.groupName;
    if (event.target.checked == true) {
      for (var i = 0; i < permData.permissions.length; i++) {
        this.roleArray.push(permData.permissions[i].permissionId);
        $('.inner-label input[name=' + this.groupName + ']').prop('checked', true);
        this.SelectAll();
      }
      this.roleArray = _.uniq(this.roleArray);
    } else {
      for (var i = 0; i < permData.permissions.length; i++) {
        $('.inner-label input[name=' + this.groupName + ']').prop('checked', false);
        $('.select-all input').prop('checked', false);
        var index = _.indexOf(this.roleArray, permData.permissions[i].permissionId, 0)
        this.roleArray.splice(index, 1);
      }
    }
  }

  /* single Check permssion */
  checkinnerTick(event, permData1, permData, typeStr?: any) {

    this.groupName = permData;
    if (event.target.checked == true) {
      let permissionListObj = _.find(this.permissionData, function (o) { return o.groupName == permData });

      let addObj = null, updateObj = null, deleteObj = null, viewObj = null;

      if (typeStr == 'Add') {
        addObj = this.findObject(permissionListObj.permissions,'Add')//_.find(permissionListObj.permissions, function (o) { return o.permission == "Add" });
        viewObj = this.findObject(permissionListObj.permissions,'View') //_.find(permissionListObj.permissions, function (o) { return o.permission == "View" });
        updateObj = this.findObject(permissionListObj.permissions,'Update') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Update" });

      } else if (typeStr == "Update") {
        // addObj = _.find(permissionListObj, function(o){return o.permission == "Add"});
        viewObj = this.findObject(permissionListObj.permissions,'View') //_.find(permissionListObj.permissions, function (o) { return o.permission == "View" });
        updateObj = this.findObject(permissionListObj.permissions,'Update') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Update" });

      } else if (typeStr == "Add-Delete") {
        deleteObj = this.findObject(permissionListObj.permissions,'Add-Delete') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Add-Delete" });
        addObj = this.findObject(permissionListObj.permissions,'Add') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Add" });
        viewObj = this.findObject(permissionListObj.permissions,'View') //_.find(permissionListObj.permissions, function (o) { return o.permission == "View" });
        updateObj = this.findObject(permissionListObj.permissions,'Update') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Update" });

      } else if (typeStr == "View") {
        viewObj = this.findObject(permissionListObj.permissions,'View') //_.find(permissionListObj.permissions, function (o) { return o.permission == "View" });
      } else {
        this.roleArray.push(permData1);
      }

      if (addObj) {
        let i = this.roleArray.indexOf(addObj.permissionId)
        if (i == -1) {
          this.roleArray.push(addObj.permissionId);
        }
        $('.inner-label input#' + addObj.permissionId).prop('checked', true);
      }


      if (deleteObj) {
        let i = this.roleArray.indexOf(deleteObj.permissionId)
        if (i == -1) {
          this.roleArray.push(deleteObj.permissionId);
        }
        $('.inner-label input#' + deleteObj.permissionId).prop('checked', true);
      }

      if (updateObj) {
        let i = this.roleArray.indexOf(updateObj.permissionId)
        if (i == -1) {
          this.roleArray.push(updateObj.permissionId);
        }
        $('.inner-label input#' + updateObj.permissionId).prop('checked', true);
      }

      if (viewObj) {
        let i = this.roleArray.indexOf(viewObj.permissionId)
        if (i == -1) {
          this.roleArray.push(viewObj.permissionId);
        }

        $('.inner-label input#' + viewObj.permissionId).prop('checked', true);
      }

      this.SelectgroupName();
      // this.roleArray.push(permData1);
    } else {
      // var index = _.indexOf(this.roleArray, permData1, 0)
      // this.roleArray.splice(index,1) ;
      let permissionListObj = _.find(this.permissionData, function (o) { return o.groupName == permData });

      let addObj = null, updateObj = null, deleteObj = null, viewObj = null;

      if (typeStr == 'Add') {
        addObj = this.findObject(permissionListObj.permissions,'Add') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Add" });
        deleteObj = this.findObject(permissionListObj.permissions,'Add-Delete') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Add-Delete" });

      } else if (typeStr == "Update") {
        // addObj = _.find(permissionListObj, function(o){return o.permission == "Add"});
        addObj = this.findObject(permissionListObj.permissions,'Add') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Add" });
        deleteObj = this.findObject(permissionListObj.permissions,'Add-Delete') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Add-Delete" });
        updateObj = this.findObject(permissionListObj.permissions,'Update') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Update" });
      }
      else if (typeStr == "View") {
        deleteObj = this.findObject(permissionListObj.permissions,'Add-Delete')// _.find(permissionListObj.permissions, function (o) { return o.permission == "Add-Delete" });
        addObj = this.findObject(permissionListObj.permissions,'Add') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Add" });
        viewObj = this.findObject(permissionListObj.permissions,'View') // _.find(permissionListObj.permissions, function (o) { return o.permission == "View" });
        updateObj = this.findObject(permissionListObj.permissions,'Update')//_.find(permissionListObj.permissions, function (o) { return o.permission == "Update" });

      }
      else if (typeStr == "Add-Delete") {
        deleteObj = this.findObject(permissionListObj.permissions,'Add-Delete') //_.find(permissionListObj.permissions, function (o) { return o.permission == "Add-Delete" });
      }
      else {
        var index = _.indexOf(this.roleArray, permData1, 0)
        if (index > -1) {
          this.roleArray.splice(index, 1);
        }
      }

      if (addObj) {
        let index = _.indexOf(this.roleArray, addObj.permissionId, 0)
        if (index > -1) {
          this.roleArray.splice(index, 1);
        }
        $('.inner-label input#' + addObj.permissionId).prop('checked', false);
      }

      if (deleteObj) {
        let index = _.indexOf(this.roleArray, deleteObj.permissionId, 0)
        if (index > -1) {
          this.roleArray.splice(index, 1);
        }
        $('.inner-label input#' + deleteObj.permissionId).prop('checked', false);
      }

      if (updateObj) {
        let index = _.indexOf(this.roleArray, updateObj.permissionId, 0)
        if (index > -1) {
          this.roleArray.splice(index, 1);
        }
        $('.inner-label input#' + updateObj.permissionId).prop('checked', false);
      }

      if (viewObj) {
        let index = _.indexOf(this.roleArray, viewObj.permissionId, 0)
        if (index > -1) {
          this.roleArray.splice(index, 1);
        }
        $('.inner-label input#' + viewObj.permissionId).prop('checked', false);
      }
      $('.outer-label input[name=' + this.groupName + ']').prop('checked', false);
      $('.select-all input').prop('checked', false);
    }
    this.SelectAll()

  }


  findObject(arr,val){
    return _.find(arr, function (o) { return o.permission == val });
  }

  /* Select All  permmsion & checked inputs */
  SelectAll() {
    var total_in = $('.inner-label input').length;
    var checked_in = $('.inner-label input[type="checkbox"]:checked').length;
    if (checked_in == total_in && total_in !=0 && checked_in != 0) {
      $('.select-all input').prop('checked', true);
      $('.checkAllChkbox').prop('checked', true);
    }else{
      $('.checkAllChkbox').prop('checked', false);
    }
  }

  /* Select Section wise permission & checked inputs */
  SelectgroupName() {
    var total_input = $('.inner-label input[name=' + this.groupName + ']').length;
    var checked_input = $('.inner-label input[name=' + this.groupName + ']:checked').length;
    if (checked_input == total_input) {
      $('.outer-label input[name=' + this.groupName + ']').prop('checked', true);
    }
  }

  /* Function for getting All Permission List */
  getAllPermissionList() {
    this.apiService.getAll('permission/getAllPermission').subscribe(
      data => {
        if (this.roleId) {
          this.getRoleById();
        }
        if (data.statusCode == 100) {
          this.permissionData = data.responseList;
        } else {
          //this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        if (this.roleId) {
          this.getRoleById();
        }
      }
    );
  }


  /* Create Role */
  SubmitRole() {
    // console.log(this.roleArray);
    if (this.roleId == 0) {
      this.AllroleArray = [];
      for (var i = 0; i < this.roleArray.length; i++) {
        var AllroleArray = { 'permissionId': this.roleArray[i] };
        this.AllroleArray.push(AllroleArray);
      }
      this.roleData.permissions = this.AllroleArray;
      this.apiService.create('role/createRole', this.roleData).subscribe(
        data => {
          if (data.statusCode == 100) {
            Swal({
              position: 'top-end',
              type: 'success',
              title: 'Role Added Successfully',
              showConfirmButton: false,
              timer: 1500
            })
            this.router.navigate(['/role-management']);
          } else {
            //this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    } else {
      this.AllroleArray = [];
      for (var i = 0; i < this.roleArray.length; i++) {
        var AllroleArray = { 'permissionId': this.roleArray[i] };
        this.AllroleArray.push(AllroleArray);
      }

      this.roleData.permissions = this.AllroleArray;
      this.apiService.update('role/updateRole', this.roleData).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.alertService.sweetMessage('success', 'Role Updated Successfully');
            this.router.navigate(['/role-management']);
          } else {
            //this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  getRoledetailByID(roleId) {
    this.roleId = roleId;
    this.getRoleById();
  }

  /* Get Role by Id*/
  getRoleById() {
    $(document).find(".roleList").removeClass("inner-shadow");
    $("#roleBox_" + this.roleId).addClass("inner-shadow");
    $('.uroles-type-select input').prop('checked', false);
    this.roleArray = [];
    this.apiService.getAll('role/getRole?roleId=' + this.roleId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.roleData = data.response;
          // for (var i = 0; i < this.roleData.permissions.length; i++) {
          //   this.roleArray.push(this.roleData.permissions[i].permissionId);
          // }
          setTimeout(() => {
            
            for (var i = 0; i < this.roleData.permissions.length; i++) {
              this.roleArray.push(this.roleData.permissions[i].permissionId);
              var perID = this.roleData.permissions[i].permissionId;
              var permission = this.roleData.permissions[i].permission;
              $('.inner-label #' + perID).prop('checked', true);
  
              var groupName = $('.inner-label #' + perID).attr('name')
              this.groupName = groupName;
              this.SelectgroupName();
              this.SelectAll();
  
              if (permission == 'Create-Client-Global-User') {
                this.isGlobal = true;
              }
            }
          }, 200);
          
          // if (this.isGlobal || this.isGlobalUser == 'true') {
          //   $('.inner-label #' + perID).prop('checked', false);
          // }

        } else {
          //this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /* Function for getting All Role List */
  getAllRoleList() {
    this.apiService.getAll('role/getRoleByClient?clientId=' + this.clientId + '&userId=' + this.userId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.roleDatalist = data.responseList;
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  replacePermissionName(permissionName){
    if (permissionName) {
      let str = permissionName;
      let name = str.replace(/-/g," ")
      return name;
    }
  }

  changeCustomerRole(){
    setTimeout(() => {
      var total_in = $('.inner-label input').length;
    var checked_in = $('.inner-label input[type="checkbox"]:checked').length;
    if (checked_in == total_in && total_in !=0 && checked_in != 0) {
      $('.select-all input').prop('checked', true);
      $('.checkAllChkbox').prop('checked', true);
    }else{
      $('.checkAllChkbox').prop('checked', false);
    }
    }, 1000);
  }
}
