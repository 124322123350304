import { Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService } from '../../../../services/index';
declare var $ : any;
declare var jQuery : any;
import Swal from 'sweetalert2'
import { Location } from '@angular/common';
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef,
    private location :Location
    ){
      this.permissionData = ApiService.permission;
      this.isPermission = ApiService.isPermission;


      if (ApiService.isPermission == 0 || ApiService.isPermission == undefined) {
          ApiService.permission = (localStorage.getItem("permission")?JSON.parse(localStorage.getItem("permission")):'');

          if(this.permissionData){
              this.permissionData = ApiService.permission;
          }else{
              localStorage.clear();
          }

      }

    //   $( document.body ).click(function() {
    //     $('.sm-toggle').removeClass('smt-active');
    //     $('body').removeClass('body-smt-active');
    //     $('.slide-menu-wrap').removeClass('slide-menu-active');
    // });

  }
  isPermission: any;
  permissionData: any;
  searchResult: any;
  dataTable: any;
  clientId: any = localStorage.getItem("clientId");
  userId  : any = localStorage.getItem("userId");
  tierId  : any = (localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);

  customerId:any = (localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);

  searchKey : any = "";
  clientCode: any ='';

  imageUrl = "assets/img/user_icon.png"


  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
      if (params['key']) {
          this.searchKey = params['key'];
          this.globalSearch()
        }
    });

  }

  handleClik(){
    $('.sm-toggle').removeClass('smt-active');
       $('body').removeClass('body-smt-active');
       $('.slide-menu-wrap').removeClass('slide-menu-active');
  }

  /***************************
    Method for Global Search
  ***********************************/

  globalSearch(){
    var newSearch = this.searchKey.toString()//.replace('#', '');
    var URL = 'asset/globalSearch?&clientId='+this.clientId+'&search='+ encodeURIComponent(newSearch);

    this.searchResult = [];
    if ($.fn.DataTable.isDataTable('#searchTable')) {
        $('#searchTable').DataTable().destroy();
    };
    console.log(URL)
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
            this.searchResult = data.responseList;
            var searchkey = this.searchKey;
            var _this = this;
            jQuery.each(this.searchResult, function( i, val ) {

              var tier = val.tier ? val.tier.tierName : '';
              var customer = val.customer ? val.customer.name : '';
              var assetName = val.assetName ? val.assetName : '';
              var siteName = val.site ? val.site.siteName : '';
              var description = val.description ? val.description : '';
              var manufacturSererialNumber = val.manufacturSererialNumber ? val.manufacturSererialNumber : '';
              var typeApprovalNumber = val.typeApprovalNumber ? val.typeApprovalNumber : '';

              var regex = new RegExp(searchkey,"gi");

              let assetNameNew = assetName.replace(regex, function(str){
                return "<span class='searchHighlight'>"+str+"</span>"
              });
              let siteNameNew = siteName.replace(regex, function(str){
                return "<span class='searchHighlight'>"+str+"</span>"
              });
              let descriptionNew = description.replace(regex, function(str){
                return "<span class='searchHighlight'>"+str+"</span>"
              });
              let manufacturSererialNumberNew = manufacturSererialNumber.replace(regex, function(str){
                return "<span class='searchHighlight'>"+str+"</span>"
              });
              let typeApprovalNumberNew = typeApprovalNumber.replace(regex, function(str){
                return "<span class='searchHighlight'>"+str+"</span>"
              });
              let tierNew = tier.replace(regex, function(str){
                return "<span class='searchHighlight'>"+str+"</span>"
              });
              let customerNew = customer.replace(regex, function(str){
                return "<span class='searchHighlight'>"+str+"</span>"
              });

              if(val.assetName){
                _this.searchResult[i].assetName = assetNameNew;
              }
              if(val.site){
                _this.searchResult[i].site.siteName = siteNameNew;
              }
              if(val.description){
                _this.searchResult[i].description = descriptionNew;
              }
              if(val.manufacturSererialNumber){
                _this.searchResult[i].manufacturSererialNumber = manufacturSererialNumberNew;
              }
              if(val.typeApprovalNumber){
                _this.searchResult[i].typeApprovalNumber = typeApprovalNumberNew;
              }
              if(val.tier){
                _this.searchResult[i].tier.tierName = tierNew;
              }
              if(val.customer){
                _this.searchResult[i].customer.name = customerNew;
              }
            });
            if ($.fn.DataTable.isDataTable('#searchTable')){
                $('#searchTable').DataTable().destroy();
            };
            this.chRef.detectChanges();
            const table: any = $('#searchTable');
            this.dataTable = table.DataTable(
              {
                "order": [],
                "destroy":true,
                "bDestroy":true,
                "columnDefs": [{ "orderable": false, "targets": 5 }]
              }
            );
        }else{
            this.searchResult = [];
            if ($.fn.DataTable.isDataTable('#searchTable')){
              $('#searchTable').DataTable().destroy();
            };
            this.chRef.detectChanges();
            const table: any = $('#searchTable');
            this.dataTable = table.DataTable(
              {
                "order": [],
                "destroy":true,
                "bDestroy":true,
                "columnDefs": [{ "orderable": false, "targets": 5 }]
              }
            );
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
  );
}




}
