import { Component, OnInit } from '@angular/core';
import { AlertService, ApiService, CommonService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-customize-dashboard',
  templateUrl: './customize-dashboard.component.html',
  styleUrls: ['./customize-dashboard.component.css']
})
export class CustomizeDashboardComponent implements OnInit {

  clientId: any = null;
  userId: any = null;

  dashboardId: any = null;
  dashboardDataList: any = []
  ShowFormValue: any = null;
  fusionChartArrayDataObj: any = []
  siteList: any = []
  tierList: any = []
  dashboardDataObj:any = {};
  widgetList:any = [];
  DashbordId:any;
  ClientCode:any = ""

  constructor(private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private commonService:CommonService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.ClientCode = this.alertService.getClientCode();
    this.clientId = localStorage.getItem("clientId");
    this.userId = localStorage.getItem("userId");

    $('#setting-cog').click(function () {
      $('.setting-overlay').toggleClass('show-overlay');
      $('.ohl').toggleClass('oh');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog1').click(function () {
      $('.setting-overlay1').toggleClass('show-overlay1');
      $('.ohl1').toggleClass('oh1');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog2').click(function () {
      $('.setting-overlay2').toggleClass('show-overlay2');
      $('.ohl2').toggleClass('oh2');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog3').click(function () {
      $('.setting-overlay3').toggleClass('show-overlay3');
      $('.ohl3').toggleClass('oh3');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog4').click(function () {
      $('.setting-overlay4').toggleClass('show-overlay4');
      $('.ohl4').toggleClass('oh4');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog5').click(function () {
      $('.setting-overlay5').toggleClass('show-overlay5');
      $('.ohl5').toggleClass('oh5');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog6').click(function () {
      $('.setting-overlay6').toggleClass('show-overlay6');
      $('.ohl6').toggleClass('oh6');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog7').click(function () {
      $('.setting-overlay7').toggleClass('show-overlay7');
      $('.ohl7').toggleClass('oh7');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog8').click(function () {
      $('.setting-overlay8').toggleClass('show-overlay8');
      $('.ohl8').toggleClass('oh8');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog9').click(function () {
      $('.setting-overlay9').toggleClass('show-overlay9');
      $('.ohl9').toggleClass('oh9');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    $('#setting-cog10').click(function () {
      $('.setting-overlay10').toggleClass('show-overlay10');
      $('.ohl10').toggleClass('oh10');
      $(this).find('i').toggleClass('fa-cog fa-times')
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.dashboardId = params['id'];
        this.DashbordId = JSON.parse(JSON.stringify(this.dashboardId))
        if (this.dashboardId) {
          this.getDashboardDataById();
          this.getDashboardById()
        }
      }else {
        this.getDefaultDashboard();
      }
    });

    this.getTierManagementList();
    this.getAllDashboard()
  }

  ChangeDashboard(){
    this.router.navigate(["customize-dashboard",this.DashbordId])
  }

  getAllDashboard(){
      // this.showLoading = true;
      this.apiService.getAll("dashboard/getDashboardByUser").subscribe(
        data => {
          // this.showLoading = false;
          console.log(data);
          if (data.statusCode == 100) {
            this.widgetList = data.responseList;
          }
        },
        error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
  }

  getDashboardById() {
    var self = this;
    this.apiService.getAll('dashboard/getDashboardByDashboardId?dashboardId=' + this.dashboardId).subscribe(
      data => {
        this.dashboardDataObj = data.response ? data.response : {}
        this.commonService.currentBreadcrumb = this.dashboardDataObj.name
        this.commonService.dashboardId = this.dashboardDataObj.dashboardId
        
        
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getDefaultDashboard(){
    var URL = 'dashboard/getDefualtDashboardByOrder';
    this.apiService.getAll(URL).subscribe(
        data => {
            if (data.statusCode == 100) {
              this.dashboardId = data.response ? data.response.dashboardId : null
              this.DashbordId = JSON.parse(JSON.stringify(this.dashboardId))

              if (this.dashboardId) {
                this.getDashboardDataById();
                this.getDashboardById()

              }
            } else {
                this.router.navigate(['mydashboard'])
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }


  getDashboardDataById() {
    this.apiService.getAll('widget/getWidgetsByDashboard?dashboardId=' + this.dashboardId).subscribe(
      data => {
        this.dashboardDataList = data.responseList ? data.responseList : []
        console.log("this.dashboardDataList=====",this.dashboardDataList)

        for (let index = 0; index < this.dashboardDataList.length; index++) {
          let obj = {}
          this.fusionChartArrayDataObj.push(obj);
          let dataObj = this.dashboardDataList[index]
          this.fusionChartArrayDataObj[index].width = "100%";
          // this.fusionChartArrayDataObj[index].height = "100%";
          this.fusionChartArrayDataObj[index].dataFormat = 'json';
          if (dataObj.widgetName == 'Last 10 Assets Visited') {
            // this.getLastAssetsVisited(index)
          }
          else if (dataObj.widgetName == 'Last 10 Alarming site') {
            // this.getLastAlarmingSite(index)
          }
          else if (dataObj.widgetName == 'Runtime event aging') {
            // this.getRuntimeEventAging(index)
          }
          else if (dataObj.widgetName == 'Asset Status Pie Chart') {
            this.fusionChartArrayDataObj[index].type = 'doughnut3d';
            this.fusionChartArrayDataObj[index].dataSource = {
              "chart": {
                // "defaultcenterlabel": ,
                "showlegend": "1",
                "showpercentvalues": "0",
                "aligncaptionwithcanvas": "0",
                "captionpadding": "0",
                "decimals": "1",
                // "plottooltext": "<b>$Value</b> of Assets are <b>$label</b>",
                "plottooltext": "Status.",
                "showPlotBorder": "2",
                "showShadow": "2",
                "use3DLighting": "2",
                "theme": "fusion"
              },
              "data": []
            };
            this.getStatusPieChart(index)
          }
          else if (dataObj.widgetName  == 'Event Status') {
            this.fusionChartArrayDataObj[index].type = 'column3d';
            this.fusionChartArrayDataObj[index].dataSource = {
              "chart": {
                  "caption": "",
                      "subcaption": "",
                      "showlegend": "1",
                      "xaxisname": "Event Status",
                      "yaxisname": "Number of Events",
                      "theme": "fusion",
              },
              "data": []
          };
            this.getEventBargraph(index)
          }

        }

        console.log(this.dashboardDataList);
        $('#setting-cog').click(function () {
          $('.setting-overlay').toggleClass('show-overlay');
          $('.ohl').toggleClass('oh');
          $(this).find('i').toggleClass('fa-cog fa-times')
        });



      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  showForm(i) {
    if (this.ShowFormValue) {
      this.ShowFormValue = null
    } else {
      this.ShowFormValue = i;
    }

  }

  /* Get Site Management list */
  getTierManagementList() {
    var URL = 'tier/getTiersByClient?clientId='+this.clientId+'&userId='+this.userId+'&alphabetSortingOrder=1'
        // var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
    this.apiService.getAll(URL).subscribe(
        data => {
            if (data.statusCode == 100) {
                this.tierList = data.responseList;
            } else {
                this.tierList = [];
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }

  getLastAssetsVisited(index) {

    this.apiService.getAll("asset/getLast10AssetVisitByUser?userId=" + this.userId + "&clientId=" + this.clientId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.fusionChartArrayDataObj[index].dataList = data.responseList
        } else {
          this.fusionChartArrayDataObj[index].dataList = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }
  
  getLastAlarmingSite(index) {

    this.apiService.getAll("asset/getLast10AlarmingAssets?userId=" + this.userId /* + "&clientId=" + this.clientId */).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.fusionChartArrayDataObj[index].dataList = data.responseList
        } else {
          this.fusionChartArrayDataObj[index].dataList = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }
  
  getRuntimeEventAging(index) {

    this.apiService.getAll("runTimeEvent/getRuntimeEventAgingByClientAndUser?userId=" + this.userId + "&clientId=" + this.clientId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.fusionChartArrayDataObj[index].dataList = data.responseList
        } else {
          this.fusionChartArrayDataObj[index].dataList = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getStatusPieChart(index) {
    

    var URL = 'asset/getAssetStatusGraphData';
    let apiData: any = {
      clientId: this.clientId,
      userId: this.userId,
    }
    // if (this.tierId != null && this.tierId != '') {
    //   var tierString = '&tierId=' + this.tierId.toString();
    //   var URL = URL.concat(tierString);
    // }
    // if (this.site != '') {
    //   var siteString = '&siteId=' + this.site;
    //   var URL = URL.concat(siteString);
    // }
    // if (this.asset != '') {
    //   var assetString = '&assetId=' + this.asset;
    //   var URL = URL.concat(assetString);
    // }
    // if (this.customerId != null && this.customerId != '') {
    //   var customerString = '&customerId=' + this.customerId.toString();
    //   var URL = URL.concat(customerString);
    // }
    this.apiService.create(URL, apiData).subscribe(
      data => {
        if (data.statusCode == 100) {

          let Arr = [];
          let valueArr = data.responseList

          valueArr.map((val, key) => {
            if (val.fill) {
              delete val.fill;
            }
            if (val['label'] == "A") {
              val['label'] = 'Alarm';
              // val['link'] = '/asset-management/A/'+this.asset;
          }
          if (val['label'] == "N") {
              val['label'] = 'Normal';
              // val['link'] = '/asset-management/N/'+this.asset;
          }
          if (val['label'] == "W") {
              val['label'] = 'Warning';
              // val['link'] = '/asset-management/W/'+this.asset;
          }
          if (val['label'] == "S") {
              val['label'] = 'Suspended';
              // val['link'] = '/asset-management/O/'+this.asset;
          }
          if (val['label'] == "C") {
            val['label'] = 'No Comm';
            // val['link'] = '/asset-management/O/'+this.asset;
          }


            Arr.push(val);
          })

          setTimeout(() => {
            this.fusionChartArrayDataObj[index].dataSource.data = Arr
            
          }, 1000);


        }
      }, error => {

      })

     

  }

  getEventBargraph(index){
    var URL = 'runTimeEvent/getClientDashboardCounts';
    let apiData : any = {
      clientId: this.clientId,
      userId: this.userId
    } 
// if (this.tierId != null && this.tierId != '') {
//     var tierString = '&tierId=' + this.tierId.toString();
//     var URL = URL.concat(tierString);
// }
// if (this.site != '') {
//     var siteString = '&siteId=' + this.site;
//     var URL = URL.concat(siteString);
// }
// if (this.asset != '') {
//     var assetString = '&assetId=' + this.asset;
//     var URL = URL.concat(assetString);
// }
// if (this.customerId != null && this.customerId != '') {
//     var customerString = '&customerId=' + this.customerId.toString();
//     var URL = URL.concat(customerString);
// }
this.apiService.create(URL, apiData).subscribe(
    data => {
    if (data.statusCode == 100) {

    let Arr = [];
    let valueArr = data.responseList

    valueArr.map((val, key)=>{
      if(val.fill){
        // delete val.fill;
      }
      Arr.push(val);
    })
    setTimeout(() => {
      this.fusionChartArrayDataObj[index].dataSource.data = Arr
      
    }, 1000);

    var that = this;
    // Object.keys(that.EventBarGraph).forEach(key => {
    //   if (that.EventBarGraph[key]['label'] == "Runtime Open") {
    //       // that.EventBarGraph[key]['link'] = '/notification-event/1';
    //   }
    //   if (that.EventBarGraph[key]['label'] == "In-Progress") {
    //       // that.EventBarGraph[key]['link'] = '/notification-event/2';
    //   }
    //   if (that.EventBarGraph[key]['label'] == "Not Acknowledged") {
    //       // that.EventBarGraph[key]['link'] = '/notification-event/0';
    //   }
    // });

    } else {
      this.fusionChartArrayDataObj[index].dataSource.data = [];
    }
    },
    error =>{
      // this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
);
  }

  formSubmit($event, DivId, dataForm, index) {
    console.log($event, DivId, $event.value, dataForm.value);
    let graphname = this.dashboardDataList[index].widgetName
    if (graphname == 'Asset Status Pie Chart') {
      this.getStatusPieChart(index)
    }
  }

  getDataPointBySite() {
    // dashboard/getDataPointBySite?siteId=25
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }

}


// http://172.20.1.145:8764/api/v1/runTimeEvent/getRuntimeEventAgingByClientAndUser?userId=17&clientId=5
