import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId :any = 0;
  assetsumaryData : any ={};
  asseteventData: any = [];
  dataTable: any;
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  clientCode: any = '';
  permissionData:any=[];
  maxRecords:any = 50;
  checkDateDiff = false;

  showAckBtn : number = 0;
  showAckErcBtn : number = 0;
  showERC : number = 0; 
  performAction : any = 0;
  filterObj:any= {
    "maxRecords":50
  }
  breadcrumbObj: any;
  urlCustomerId: any;
  CdName: any;
  HistoryList: any = [];
  responsibilityList: any = [];
  rollUpEventList: any = [];

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private formatDateService: FormatDateService,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef
    ) {
    this.permissionData = ApiService.permission;
   }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          this.urlCustomerId = params.cus_id;
          this.CdName = params.cusD_name;
      })
    this.clientCode = this.alertService.getClientCode();
    // Get current Date and Time for showing default date
    var today = new Date();

    var hr  = (today.getHours() ? (today.getHours() > 9 ? today.getHours() : '0' + today.getHours()) : '00');

    var min = (today.getMinutes() ? (today.getMinutes() > 9 ? today.getMinutes() : '0' + today.getMinutes()) : '00');

    var sec = (today.getSeconds() ? (today.getSeconds() > 9 ? today.getSeconds() : '0' + today.getSeconds()) : '00');

    var month = ((today.getMonth()+1) ? ((today.getMonth()+1) > 9 ? (today.getMonth()+1) : '0' + (today.getMonth()+1)) : '00');

    var Tdate = (today.getDate() ? (today.getDate() > 9 ? today.getDate() : '0' + today.getDate()) : '00');

    var date     = today.getFullYear()+'-'+month+'-'+Tdate;
    var time     = hr + ":" + min + ":" + sec;
    var dateTime = date+' '+time;

  // Fill default date time in the input fields
    $("#startDate").val( moment(date+' '+time).subtract(3,'days').format('YYYY-MM-DD HH:mm:ss')/*  date+' '+"00:00:00" */);
    $("#endDate").val(moment(date+' '+time).format('YYYY-MM-DD HH:mm:ss'));

      this.route.params.subscribe(params => {
          if(params['id']){
            this.assetId = params['id'];
          }
        });
        if(this.assetId){
          this.getAssetSummaryData();
          this.getAssetEventData();
        }

  let self = this
      jQuery('.rst-date').datetimepicker({
          language: 'en',
          pickTime: true,
          pickDate: true,
          minDate: 0,
          inline: true,
          showButtonPanel: true,
          showTodayButton:true,
          showClose:true,
          sideBySide: true,
          endDate:new Date()
      }).on('changeDate', function(e){
        let startDate = $('#startDate').val();
        let endDate = $('#endDate').val();
        if(startDate && endDate){
            if(moment(startDate).isBefore(endDate)){
                self.checkDateDiff = false;
            }else{
                self.checkDateDiff = true;
            }
        }
        if(startDate == ''){
            $('#start_date_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#start_date_err').css('display', 'none');
        }

        if(endDate == '')
        {
            $('#end_date_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#end_date_err').css('display', 'none');
        }

    });
    jQuery('.rst-time').datetimepicker({
        pickDate: true,
        pickTime: true,
        pickSeconds: false,
        pickMinutes: true,
        showClose:true,
        showButtonPanel: true,
        showTodayButton:true,
        timeFormat: "HH:mm:ss"
    });
    $(document).on('hidden.bs.modal', function () {
      if($('.modal.show').length)
      {
        $('body').addClass('modal-open');
      }
    });
  }

  // Function to set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }

  // Listen For event Recive from child
    reciveAsset(){
      this.getAssetSummaryData();
    }

  /* Function for getting Root level tier data */
  getAssetSummaryData(){
    this.apiService.getAll('asset/getAssetSummaryById?clientId='+this.clientId+'&userId='+this.userId+'&assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Function for getting Asset Event data */
  getAssetEventData(){
    // this.apiService.getAll('wffnotification/getEventList?&clientId='+this.clientId+'&userId='+this.userId+'&assetId='+this.assetId).subscribe(

    var fromDate = $("#startDate").val();
    var endDate = $("#endDate").val();
    
    if (fromDate == '' || fromDate == null) {
      
    }
    if (endDate == '' || endDate == null) {
      
    }

    if(fromDate == '')
    {
        $('#start_date_err').css('display', 'block');
        return;
    }
    else 
    {
        $('#start_date_err').css('display', 'none');
    }

    if(endDate == '')
    {
        $('#end_date_err').css('display', 'block');
        return;
    }
    else 
    {
        $('#end_date_err').css('display', 'none');
    }
    
    if(moment(fromDate).isBefore(endDate)){
      // this.addReportDataList();
    }
    else{ 
        this.checkDateDiff = true;
        return;
    }
    if($.fn.DataTable.isDataTable('#eventTable')){
      $('#eventTable').DataTable().destroy();
    };

    // let url = "maxRecord"+this.maxRecords+'&fromDate='+fromDate+'&toDate='+endDate

    // this.apiService.getAll('wffnotification/getAssetEventList?&clientId='+this.clientId+'&userId='+this.userId+'&assets='+this.assetId/* +"maxRecord"+this.maxRecords*/+'&sDate='+fromDate+'&eDate='+endDate ).subscribe(

      let URL = 'wffnotification/getAssetEventList?&clientId='+this.clientId+'&userId='+this.userId+'&assets='+this.assetId+'&sDate='+fromDate+'&eDate='+endDate;

      let maxRecords = this.filterObj.maxRecords;

      if(maxRecords!=0 && maxRecords!="" && maxRecords > 0){
        var endlimitString = '&endlimit=' + maxRecords;
        URL = URL.concat(endlimitString);
      }else{
        maxRecords = 0;
      }

      this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.asseteventData= data.responseList;
          if($.fn.DataTable.isDataTable('#eventTable')){
            $('#eventTable').DataTable().destroy();
          };
          
              this.chRef.detectChanges();
              const table: any = $('#eventTable');
              this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": 6 }]});
              // this.dataTable = table.DataTable({aaSorting: [[3, 'desc']]});
        }else{
          this.asseteventData= [];
           this.chRef.detectChanges();
              const table: any = $('#eventTable');
              this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": 6 }]});
              // this.dataTable = table.DataTable({aaSorting: [[3, 'desc']]});

           /*  this.alertService.sweetMessage('error',data.responseMessage);*/
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /** Function to Sort Response Data **/
  sortDataTableData(items,sortBy,desc){
       if (sortBy && sortBy != null) {
              items = items.sort((a, b) => {
               const sortA = a[sortBy];
               const sortB = b[sortBy];
               if (desc) {
                   if (sortA < sortB) return 1;
                   if (sortA > sortB) return -1;
                   return 0;
               } else {
                   if (sortA < sortB) return -1;
                   if (sortA > sortB) return 1;
                   return 0;
               }
           });
          return items;
       }else{
          return items;
       }
  }

  getEventList(){

    var fromDate = $("#startDate").val();
    var endDate = $("#endDate").val();
    
    if (fromDate == '' || fromDate == null) {
      
    }
    if (endDate == '' || endDate == null) {
      
    }

    if(fromDate == '')
    {
        $('#start_date_err').css('display', 'block');
        return;
    }
    else 
    {
        $('#start_date_err').css('display', 'none');
    }

    if(endDate == '')
    {
        $('#end_date_err').css('display', 'block');
        return;
    }
    else 
    {
        $('#end_date_err').css('display', 'none');
    }
    
    if(moment(fromDate).isBefore(endDate)){
      // this.addReportDataList();
    }
    else{ 
        this.checkDateDiff = true;
        return;
    }

    let url = "maxRecord"+this.maxRecords+'&assetId='+this.assetId+'&fromDate='+fromDate+'&toDate='+endDate
    //console.log(url)
    // this.apiService.getAll('wffnotification/getAssetEventList?&clientId='+this.clientId+'&userId='+this.userId+'&assets='+this.assetId).subscribe(
    //   data => {
    //     if(data.statusCode == 100){
    //       this.asseteventData= data.responseList;
    //       if($.fn.DataTable.isDataTable('#eventTable')){
    //         $('#eventTable').DataTable().destroy();
    //       };
          
    //           this.chRef.detectChanges();
    //           const table: any = $('#eventTable');
    //           this.dataTable = table.DataTable();
    //           // this.dataTable = table.DataTable({aaSorting: [[3, 'desc']]});
    //     }else{
    //       this.asseteventData= [];
    //        this.chRef.detectChanges();
    //           const table: any = $('#eventTable');
    //           this.dataTable = table.DataTable();
    //           // this.dataTable = table.DataTable({aaSorting: [[3, 'desc']]});

    //        /*  this.alertService.sweetMessage('error',data.responseMessage);*/
    //     }
    //   },
    //   error =>{
    //            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    //          }
    // );
  }

  notificationDetailOld(eventData){
    // routerLink="/eventDetails/{{eventData.notificationId}}" { queryParams: { page: pageNum } ['product-list']
      //console.log(eventData);
    let obj = {
      id:null,
      type:null
    }
    obj.type = eventData.notificationType
    if (eventData.notificationType == "Notification") {
        obj.id = eventData.notificationId
    } else if (eventData.notificationType == "RunTime") {
      obj.id = eventData.runTimeEventId
    }

    this.router.navigate(["eventDetails",this.assetId,], {queryParams: 
      { type: obj.type, id :obj.id, rcpId:eventData.runTimeCollectionPointId  }})

  }

  notificationDetail(eventData, eventListType) {
      let runTimeStatus = eventData.runTimeStatus;
      let licenseIsAck = eventData.licenseIsAck;
      let acknowledgeByMe = eventData.acknowledgeByMe;
      let assignToMe = eventData.assignToMe;
      let technicianId = eventData.technicianId;      
      let licenseIsTechEntry = eventData.licenseIsTechEntry;
      let isAck = eventData.isAck;

      if(this.userId == eventData.technicianId && runTimeStatus == 2) {
         this.performAction = 1;
      } 

      // console.log('notification detail', eventData.acknowledgeByMe, eventData.licenseIsAck, eventData.assignToMe);
      
      // console.log('notification detail 2', eventData.technicianId, eventData.runTimeStatus, eventData.licenseIsTechEntry);
      

      if(licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
        // console.log('in if detail');
        this.showAckBtn = 1;
      } else {
        this.showAckBtn = 0;
        // console.log('in else detail');
      }

      if(
        licenseIsAck == true && 
        acknowledgeByMe == false && 
        assignToMe == true && 
        (technicianId == null || technicianId == 0) && 
        licenseIsTechEntry == true && 
        runTimeStatus == 1
        ) {
        // console.log('in if detail showAckErcBtn');
        this.showAckErcBtn = 1;
      } else {
        this.showAckErcBtn = 0;
        // console.log('in else detail showAckErcBtn');
      }

      if(
        licenseIsAck == true && 
        assignToMe == true && 
        (technicianId == null || technicianId == 0) &&         
        runTimeStatus == 1
        ) {
        this.showERC = 1;
      } else {
        this.showERC = 0;
      }

      let obj = {
        id:null,
        type:null
      }
      let pVal = 0;
      if(eventData.isParent == false) {
        pVal = 0;
      } else if(eventData.isParent == true) {
        pVal = 1;
      }
      obj.type = eventData.notificationType;
      if (eventData.notificationType == "Notification") {
          obj.id = eventData.notificationId;
      } else if (eventData.notificationType == "RunTime") {
          obj.id = eventData.runTimeEventId;
      }
      if(eventListType == 'approval' || eventListType == 'approved') {
        obj.type = 'RunTime';
        obj.id = eventData.runTimeEventId;
        this.performAction = 1;
        eventData.licenseIsTechEntry = true;
      }

      this.router.navigate(["eventDetails", eventData.assetId,], 
        {
          queryParams: 
          { 
            type: obj.type, 
            id :obj.id, 
            rcpId:eventData.runTimeCollectionPointId, 
            notificationId : eventData.notificationId, 
            isParent : pVal, 
            performAction : this.performAction, 
            showAckBtn : this.showAckBtn,
            showAckErcBtn : this.showAckErcBtn,
            showERC : this.showERC,
            licenseIsTechEntry : eventData.licenseIsTechEntry, 
            eventListType : eventListType,
            assignToMe : assignToMe == true ? 1 : 0,
            isAck : isAck == true ? 1 : 0,
            source:this.breadcrumbObj ? (this.breadcrumbObj  == 'D' ? 'DE_D': (this.breadcrumbObj == 'CDCU_U_A' ? 'CDCU_U_E_D' : (this.breadcrumbObj == 'Cu_L_AC' ? 'Cu_L_ACE_D' : (this.breadcrumbObj=='S' ? 'SE_D' : (this.breadcrumbObj == 'T' ? 'TE_D' :(this.breadcrumbObj == 'CU_U_A' ? 'CU_U_AE_D' : (this.breadcrumbObj=='DCU_U_A' ? 'DCU_U_AE_D' : (this.breadcrumbObj =='ALCU_U_A' ? 'ALCU_U_A_D': this.breadcrumbObj)))) )))) :'E_D',
            cus_id:this.urlCustomerId,
            aId:this.assetId, 
            cusD_name:this.CdName,
            cust_name:this.assetsumaryData.site ? (this.assetsumaryData.site.customer ? this.assetsumaryData.site.customer.name: null) : null
          }
      });
    }

    getHistory(obj){
      this.HistoryList = []
      let URL = ''
      if (obj.notificationType == 'Notification') {
        URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.notificationId+'&isevent=false'
        // URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+943731+'&isevent=true'
      }else{
        URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.runTimeEventId+'&isevent=true'
      }

      if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
        $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
      };
          this.apiService.getAll(URL).subscribe(
            data => {
              if (data.statusCode == 100) {
                this.HistoryList = data.responseList;
                setTimeout(() => {
                  const table: any = $('#HistoryDatatable');
                   table.DataTable({ "order": [], "destroy": true });
                }, 500);
              } else {
                setTimeout(() => {
                  const table: any = $('#HistoryDatatable');
                   table.DataTable({ "order": [], "destroy": true });
                }, 500);
                Swal({
                  position: 'top-end',
                  type: 'error',
                  title: data.responseMessage,
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          ); 
    }
    setResponsibilityList(list){
      this.responsibilityList = list
    }

  setEventrollupLists(list){
    this.rollUpEventList = list
  }
  getCommentDecoded(comment){
    return decodeURIComponent(comment)
  }
}


