import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})

export class LicenseComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerName : any = localStorage.getItem("customerName");
  dataTable: any;
  customerId:number=0;
  licenseList:any=[];
  clientCode : any = '';
  permissionData:any=[];

  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
   }

  // Function to format a date
  DateFormat(startDate){
    if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
        return "";
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
     this.route.params.subscribe(params => {
        if(params['id']){
        this.customerId = params['id'];
      }

    });
    if(this.customerId){
      this.getAllLicense();
    }
  }

  // Function to get all License
  getAllLicense(){
     this.apiService.getAll('customer/getLicenseListByCustomerAndClient?clientId='+this.clientId+'&customerId='+this.customerId).subscribe(
    //this.apiService.getAll('customer/getRoleListByCustomerAndClient?clientId='+this.clientId+'&customerId='+this.customerId).subscribe(
       data => {
           if(data.statusCode == 100){
              this.licenseList = data.responseList;
              if ($.fn.DataTable.isDataTable('#license_table')){
                    $('#license_table').DataTable().destroy();
                  };
              this.chRef.detectChanges();
               const table: any = $('#license_table');
              this.dataTable = table.DataTable();
            }else{
            	this.licenseList = [];
            	this.chRef.detectChanges();
                const table: any = $('#license_table');
                this.dataTable = table.DataTable();
              /*this.alertService.sweetMessage('error',data.responseMessage); */
             }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
 	}
}
