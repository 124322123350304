import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService, CommonService } from 'src/app/services';
import { Location } from '@angular/common';
declare var $ : any;
declare var moment : any;
import 'datatables.net';
import 'datatables.net-bs4';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-mydashboard',
  templateUrl: './mydashboard.component.html',
  styleUrls: ['./mydashboard.component.css']
})
export class MydashboardComponent implements OnInit {

  permissionData = [];
  widgetList = []
  dashboard:string = ''
  clientCode:any = null
  showLoading:boolean = false;
  HistoryList: any = [];
  constructor(private commonService:CommonService,private router: Router, private apiService: ApiService, private alertService: AlertService, private location: Location) {
    this.permissionData = ApiService.permission;
  }

  ngOnInit() {
    this.getDashboardList();
    this.clientCode = this.alertService.getClientCode();
    this.dashboard = this.commonService.currentBreadcrumb;
  }

  getDashboardList() {
    this.showLoading = true;
    this.apiService.getAll("dashboard/getDashboardByUser").subscribe(
      data => {
        this.showLoading = false;
        console.log(data);
        if (data.statusCode == 100) {
          this.widgetList = data.responseList;
        }
      },
      error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
  }

  editDashboard(dashboardId){
    console.log(dashboardId);
    this.router.navigate(['manage-dashboard',dashboardId])
  }

  GoDashboard(){
    this.location.back();
  }

getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('dashboard/getDashboardHistoryById?dashboardId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              table.DataTable({ "order": [], "destroy": true });
            }, 500);
           this.alertService.sweetMessage('error', data.responseMessage)
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
  }

  getWidgetName(list){
    let str = ''
    if (list.length) {
        list.map((obj,i)=>{
            if (i==0) {
                str+=obj.widgetName
            } else {
                str+= ', '+obj.widgetName
            }
        })
    }
    return str;
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

  deleteDashboard(id){
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Dashboard.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
    if (result.value) {
     this.apiService.delete('dashboard/deleteDashboardById?dashboardId='+id, "").subscribe(
     data => {
         if(data.statusCode == 100){
           this.alertService.sweetMessage('success',"Dashboard Deleted Successfully");
           this.getDashboardList();

          }else{
           this.alertService.sweetMessage('error',data.responseMessage);
           }
        },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
         }
          );
        }
    })

  }
}
