import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { Location } from '@angular/common';


@Component({
  selector: 'app-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.css']
})
export class ErrorHandlingComponent implements OnInit {

  clientId  : any = localStorage.getItem("clientId");
  userId    : any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  errorText : any = localStorage.getItem("errorText") || "this is error page";

  constructor(
    private router:Router,
    private apiService: ApiService ,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef,
    private location :Location
  ) { }

  ngOnInit() {
  }

  goBack(){

    // console.log(window.history, history.go(-1) )
    // console.log(history.go(-2))
    history.go(-2)
    // this.location.back();
    // this.location.back();
    // this.goBack2()
  }

  goBack2(){
    this.location.back();
  }


}
