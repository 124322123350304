import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { CanActivate,Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  roleDatalist : any = [];
  roleDatalistUsers : any = [];
  usersArrayForRoles : any = [];
  dataTable: any;
  roleId :number = 1;
  search:any='';
  clientCode : any ='';
  permissionData:any=[];
  HistoryList: any = [];
  permissionListAssign:any = [];
  
  constructor(private router:Router, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
   }

   DateFormat(startDate){
  if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
  }else{
      return "";
    }
 }
 DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}
ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.getAllRoleList();

$(document).on('hidden.bs.modal', function () {
  if($('.modal.show').length)
  {
    $('body').addClass('modal-open');
  }
});
  }

seachRecord(){
  this.getAllRoleList();
}
/* Reset Search Record */
resetSearch(){
  this.search = "";
  this.getAllRoleList();
}
  

  /* Function for getting All Role List */
  getAllRoleList(){
    var URL = 'role/getRoleByClient?clientId='+this.clientId+'&userId='+this.userId;
    if(this.search !=''){
      var searchString   = '&search='+this.search;
      var URL = URL.concat(searchString);
    }
    if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.roleDatalist = data.responseList;
          if ($.fn.DataTable.isDataTable('#role_table')){
                $('#role_table').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#role_table');
          this.dataTable = table.DataTable({"order": [], "destroy":true,"columnDefs": [{ "orderable": false, "targets": [3,4] }]});
        }else{
          this.roleDatalist = [];
          this.chRef.detectChanges();
          const table: any = $('#role_table');
          this.dataTable = table.DataTable({"order": [],"destroy":true,"columnDefs": [{ "orderable": false, "targets": [3,4] }]});
          this.dataTable = table.DataTable();
         /* this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  getUsersOfRoles(roleId){
    var URL = 'role/getRoleByClient?clientId='+this.clientId+'&userId='+this.userId;
    if(this.search !=''){
      var searchString   = '&search='+this.search;
      var URL = URL.concat(searchString);
    }
    if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          var _this = this;
          this.roleDatalist.forEach(function (arrayItem) {           
              if(roleId == arrayItem.roleId)
              {
                 _this.usersArrayForRoles.roleName = arrayItem.roleName;
                  _this.usersArrayForRoles.users = arrayItem.users;
              }          
            
          });             

          $("#UserRoleModal").click();

        }else{
          this.usersArrayForRoles = [];
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

deleteRoleActions(roleId){
 Swal({
  title: 'Are you sure?',
  text: "You want to delete this role?",
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete it!'
}).then((result) => {
  if (result.value) {
   this.apiService.delete('role/deleteRole?roleId='+roleId, "").subscribe(
   data => {
       if(data.statusCode == 100){
         this.alertService.sweetMessage('success','Role Deleted Successfully');
          this.getAllRoleList();
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
}
})
}

getRoleUserName(users){
    let userName = '';
    if (users) {
      users.map((obj,index)=>{
        if (index==0) {
          userName += (obj && obj.middleName ? obj.firstName+" ("+obj.middleName+") "+obj.lastName : obj.firstName +" " +obj.lastName)
        }else{
          userName = userName+', ' +(obj && obj.middleName ? obj.firstName+" ("+obj.middleName+") "+obj.lastName : obj.firstName +" " +obj.lastName)
        }
      })
    }
    return userName;
  }

  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('role/getRoleHistory?roleId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

  setPermissonList(permissions){
    this.permissionListAssign = permissions;
  }

  replacePermissionName(permission){
    let str = ''
    if (permission.groupName) {
      let strName = permission.groupName;
      let Name = strName.replace(/-/g," ")
      str = str+Name
    }
    if (permission.permission) {
      let strName = permission.permission;
      let Name = strName.replace(/-/g," ")
      str = str + ' '+Name
    }    
    return str;
  }
}
