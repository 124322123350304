import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.css']
})

export class SitesComponent implements OnInit {
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerName : any = localStorage.getItem("customerName");
  siteDatalist: any = [];
  dataTable: any;
  customerId:number=0;
  Sitelocation:any="";
  permissionData:any=[];
  clientCode : any = '';
  breadcrumbObj: any;

  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
   }

  // Function to format a date
  DateFormat(startDate){
    if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
      return "";    
    }
  }

  ngOnInit() {  
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
        if(params['id']){
        this.customerId = params['id'];
      }

    });
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          // this.urlCustomerId = params.cus_id;
      })
    if(this.customerId){
      this.getSiteListByCustomer();
    }  
  }

  /* Get Site  list */
  getSiteListByCustomer(){
    var URL = 'customer/getSiteListByCustomerAndClient?customerId='+this.customerId+'&clientId='+this.clientId;
    if(this.tierId != null && this.tierId !=''){
      var tierString = '&tierId='+this.tierId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
       data => {
           if(data.statusCode == 100){
              this.siteDatalist = data.responseList; 
              if ($.fn.DataTable.isDataTable('#custSite_table')){
                    $('#custSite_table').DataTable().destroy();
              };  
              this.chRef.detectChanges();         
               const table: any = $('#custSite_table');
              this.dataTable = table.DataTable({"order": [],"columnDefs": [/* { "orderable": false, "targets": 6 } */]});
            }else{
              this.siteDatalist = [];
             this.chRef.detectChanges();         
               const table: any = $('#custSite_table');
              this.dataTable = table.DataTable({"order": [],"columnDefs": [/* { "orderable": false, "targets": 6 } */]}); 
              /*this.alertService.sweetMessage('error',data.responseMessage); */
             }
          },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }       
            );
  }
}
