import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService } from '../../../../services/index';
import Swal from 'sweetalert2';
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $: any;
declare var jQuery: any;
declare var d3: any, moment: any;
import 'datatables.net';
import 'datatables.net-bs4';
import * as _ from "lodash";

@Component({
    selector: 'app-approved-list',
    templateUrl: './approved-list.component.html',
    styleUrls: ['./approved-list.component.css']
})

export class ApprovedListComponent implements OnInit {
    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
    tierId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
    approvedData: any = [];
    dataTable: any;
    clientCode : any ='';
    livefeedData:any = {
        runTimeCollectionPoint:{
            asset:{}
        },
        reasonCode:{},
        childRunTimeEvents:[],
        users:[],
        runTimeEventResponsibilities:[]
    };
    permissionData:any=[];
    performAction : any;
    HistoryList: any = [];
    responsibilityList: any= [];
    rollUpEventList: any = [];

    constructor(private router: Router, private apiService: ApiService, private alertService: AlertService, private chRef: ChangeDetectorRef) {
        this.permissionData = ApiService.permission;
     }

    // Function to format Date
    // DateFormat(startDate){
    //   if(startDate){
    //     return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    //   }else{
    //     return "";
    //   }
    // }

    ngOnInit() {
        this.clientCode = this.alertService.getClientCode();
        // Getting Approved List Data
        this.getApprovedListData();
        $(document).on('hidden.bs.modal', function () {
            if($('.modal.show').length)
            {
              $('body').addClass('modal-open');
            }
          });
    }


    /* Get Approved list Data */
    getApprovedListData() {
        // var URL = 'runTimeEvent/getRuntimeEventListIsApproved?clientId=' + this.clientId + '&userId=' + this.userId;
        var URL = 'runTimeEvent/getWaitingApprovalEvents';
        let apiData: any = {
            clientId: this.clientId,
            userId: this.userId,
            isApproved: true,
        }
        if (this.customerId != null && this.customerId != '') {
            apiData.customerIds = this.customerId.toString();
            // var customerString = '&customerIds=' + this.customerId.toString();
            // var URL = URL.concat(customerString);
        }
        if (this.tierId != null && this.tierId != '') {
            apiData.tierIds = this.tierId.toString();
            // var tierString = '&tierIds=' + this.tierId.toString();
            // var URL = URL.concat(tierString);
        }
        this.apiService.create(URL, apiData).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.approvedData = data.responseList;
                    if ($.fn.DataTable.isDataTable('#approve_table')) {
                        $('#approve_table').DataTable().destroy();
                    };
                    this.chRef.detectChanges();
                    const table: any = $('#approve_table');
                    this.dataTable = table.DataTable({
                        "order": [],
                        "bDestroy": true,
                        "destroy":true,
                        "columnDefs": [{
                            "orderable": false,
                            "targets": 5
                        }],
                        //"deferLoading":this.assetDatalist.length
                    });

                } else {
                    this.approvedData = [];
                    this.chRef.detectChanges();
                    const table: any = $('#approve_table');
                    this.dataTable = table.DataTable({
                        "order": [],
                        "bDestroy": true,
                        "destroy":true,
                        "columnDefs": [{
                            "orderable": false,
                            "targets": 5
                        }],                        
                    });                    
                }
            },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    DateFormat(startDate, gmtOffset){
      let d1  = new Date(startDate)
      var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
      // console.log(d, startDate,d1, moment(d1).format("MM-DD-YYYY HH:mm:ss"));
      
      if(startDate){
        var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
        return formattedDate;
      }else{
        return "";
      }
        // return this.formatDateService.formatDate(startDate, gmtOffset);
    }

    getRunTimeEvent(id){        
        this.apiService.getAll('runTimeEvent/runTimeEventDetail?runTimeEventId='+id ).subscribe(
            data => {
                if (data.statusCode == 100) {
                    let dataObj = data.response;
                    this.livefeedData = dataObj;

                } else {}
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    notificationDetail(eventData, eventListType){
      if(this.userId == eventData.technicianId) {
         this.performAction = 1;
      } 

      let obj = {
        id:null,
        type:null
      }
      let pVal = 0;
      if(eventData.isParent == false) {
        pVal = 0;
      } else if(eventData.isParent == true) {
        pVal = 1;
      }
      obj.type = eventData.notificationType;
      if (eventData.notificationType == "Notification") {
          obj.id = eventData.notificationId;
      } else if (eventData.notificationType == "RunTime") {
          obj.id = eventData.runTimeEventId;
      }
      if(eventListType == 'approval' || eventListType == 'approved') {
        obj.type = 'RunTime';
        obj.id = eventData.runTimeEventId;
        this.performAction = 1;
        eventData.licenseIsTechEntry = true;
      }

      this.router.navigate(["eventDetails", eventData.assetId,], 
        {
          queryParams: 
          { 
            type: obj.type, 
            id :obj.id, 
            rcpId:eventData.runTimeCollectionPointId, 
            notificationId : eventData.notificationId, 
            isParent : pVal, 
            performAction : this.performAction, 
            licenseIsTechEntry : eventData.licenseIsTechEntry, 
            eventListType : eventListType  
          }
      });
    }


    getHistory(obj){
        this.HistoryList = []
        let URL = ''
        if (obj.notificationType == 'Notification') {
          URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.notificationId+'&isevent=false'
          // URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+943731+'&isevent=true'
        }else{
          URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.runTimeEventId+'&isevent=true'
        }
  
        if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
          $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
        };
            this.apiService.getAll(URL).subscribe(
              data => {
                if (data.statusCode == 100) {
                  this.HistoryList = data.responseList;
                  setTimeout(() => {
                    const table: any = $('#HistoryDatatable');
                     table.DataTable({ "order": [], "destroy": true });
                  }, 500);
                } else {
                  setTimeout(() => {
                    const table: any = $('#HistoryDatatable');
                     table.DataTable({ "order": [], "destroy": true });
                  }, 500);
                  Swal({
                    position: 'top-end',
                    type: 'error',
                    title: data.responseMessage,
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
              },
              error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
            ); 
      }
      setResponsibilityList(list){
        this.responsibilityList = list
      }
      setEventrollupLists(list){
        this.rollUpEventList = list
      }
      getCommentDecoded(comment){
        return decodeURIComponent(comment)
      }
}
