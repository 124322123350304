import { Component, OnInit ,ChangeDetectorRef,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
userDatalist:any = [];
UserToAsset:any = {"createdTime":"","callSequence":"1","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
roleList:any=[];
assetDatalist:any=[];
assignAsset:any=[];
userData:any={};
dataTable: any ;
search:any='';
selectedUserId:any='';
userStatusFilter : any = '';
clientCode : any='';
permissionData:any=[];
imageUrl = "assets/img/user_icon.png"
numbers = []
  HistoryList: any = [];
  sessionHistoryList: any[];
  customerName: any = '';
  clientModalName: any = '';
constructor(private router:Router, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
  this.permissionData = ApiService.permission;
}


 DateFormat(startDate){
  if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
  }else{
      return "";
    }
 }
 DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}
ngOnInit() {
  this.clientCode = this.alertService.getClientCode();
  this.getUserManagementList();
  

 $(document).on('hidden.bs.modal', function () {
  if($('.modal.show').length)
  {
    $('body').addClass('modal-open');
  }
});
}

/* On Search */
seachRecord(){
  this.getUserManagementList();
}
/* Reset Search Record */
resetSearch(){
  this.search = "";
  this.getUserManagementList();
}

filterUserByStatus(){
  this.getUserManagementList();
}
removeStr(str){
  console.log(str)
}
/* Get Site Management list */
getUserManagementList(){
  //public/user/getUserClientByClient?clientId=1&customerId=1,2&tierId=1,2,3&search=j
  var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+'&activeAndInactive=1'+'&alphabateSortingOreder=1';
  if(this.userStatusFilter != ""){
    if(this.userStatusFilter == 2){
      var statusfilter = '&isDeactivated=1'; 
      var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+statusfilter; 
    }else if(this.userStatusFilter == 1){
      var statusfilter = '&isActive='+this.userStatusFilter;
      var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+'&isActive=1'; 
    } else if(this.userStatusFilter == 0){
      var statusfilter = '&isActive='+this.userStatusFilter;
      var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+'&isActive=0'; 
    } else if(this.userStatusFilter == 4){
      var statusfilter = '&isActive='+this.userStatusFilter;
      var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId; 
    }    
    //var URL = URL.concat(statusfilter);
  } else {
    var statusfilter = '&activeAndInactive=1';
    var URL = 'user/getUserClientByClient?clientId='+this.clientId+'&userId='+this.userId+statusfilter; 
  }
  
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }

  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }

  if(this.search !=''){
    var searchString      = '&search='+this.search;
    var URL = URL.concat(searchString);
  }

  // Destroy Data Table if is already and clear the data
    if ($.fn.DataTable.isDataTable('#user_table')){
      $('#user_table').DataTable().destroy();
      this.userDatalist=[];
    }

   this.apiService.getAll(URL).subscribe(
   data => {
    if(data.statusCode == 100){
      this.userDatalist = data.userList;

      for (let userData of this.userDatalist) {
        if(userData['commModeStr']){
          userData['commModeStr']  = userData['commModeStr'].replace(/[\[\]']+/g, "");
        }
      }

        this.chRef.detectChanges();
        const table: any = $('#user_table');
        this.dataTable = table.DataTable({"order": [], "search": { regex: false, smart: false },"columnDefs": [{ "orderable": false, "targets": [0,4,9,10,11] }]});
        // this.sortDataTableData(this.userDatalist,'userId',"desc");
      }else {
        this.userDatalist = [];
        this.chRef.detectChanges();
        if ($.fn.DataTable.isDataTable('#user_table')){
          $('#user_table').DataTable().destroy();
        };
        const table: any = $('#user_table');
        this.chRef.detectChanges();
        this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,4,9,10,11] }]});
        /*this.alertService.sweetMessage('error',data.responseMessage); */
      }
    },
    error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
  }


/** Sortin **/
sortDataTableData(items,sortBy,desc){
     if (sortBy && sortBy != null) {
            items = items.sort((a, b) => {
             const sortA = a[sortBy];
             const sortB = b[sortBy];
             if (desc) {
                 if (sortA < sortB) return 1;
                 if (sortA > sortB) return -1;
                 return 0;
             } else {
                 if (sortA < sortB) return -1;
                 if (sortA > sortB) return 1;
                 return 0;
             }
         });
        return items;
     }else{
        return items;
     }
}

/* Delete the User */
DeleteUser(userId){
 Swal({
  title: 'Are you sure?',
  text: "You want to delete this User.",
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete it!'
}).then((result) => {
  if (result.value) {
   this.apiService.delete('user/deleteUser?userId='+userId, '').subscribe(
     data => {
         if(data.statusCode == 100){
              this.alertService.sweetMessage('success',"User deleted successfully");
              this.getUserManagementList();
          }else{
            //this.alertService.sweetMessage('error',data.responseMessage);
            // Swal({
            //     title: '',
            //     text: "Deletion of this user isn't possible, because of its association with either shifts or documents or comments. Do you wish to deactivate the user instead?",
            //     type: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Yes'
            //   }).then((result) => {
            //     if(result.value)
            //     {
            //         this.apiService.getAll('user/deleteUser?userId='+userId+'&isDeactivated=1').subscribe(
            //           data => {
            //             if(data.statusCode == 100){
            //                 this.alertService.sweetMessage('success',"User deactivated successfully");
            //                 this.getUserManagementList();
            //             }else{
            //               this.alertService.sweetMessage('error',data.responseMessage);          
            //             }
            //           },
            //           error =>
            //             { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
            //         );
            //       }
                
            //   })

            this.alertService.sweetMessage('error',data.responseMessage);
           }
        },
       error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
      }
  })
}

/* Deactivate the User */
DeactivateUser(userId){
 Swal({
  title: 'Are you sure?',
  text: "If you deactivate this user then it will no longer be able to log into AMCi1440 and not receive the event notifications. Deactivating the user will remove all its association with Tier, Site and Assets. Would you like to proceed?",
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes'
}).then((result) => {
  if (result.value) {
   this.apiService.getAll('user/deactivateUser?userId='+userId+'&isDeactivated=1').subscribe(
     data => {
         if(data.statusCode == 100){
              this.alertService.sweetMessage('success',"User deactivated successfully");
              this.getUserManagementList();
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
            
           }
        },
       error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
      }
  })
}

// Restore Deactivated User 
RestoreUser(userId){
 Swal({
  title: 'Are you sure?',
  text: "You want to restore this User.",
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, restore it!'
}).then((result) => {
  if (result.value) {
   this.apiService.getAll('user/deactivateUser?userId='+userId+'&isDeactivated=0').subscribe(
     data => {
         if(data.statusCode == 100){
              this.alertService.sweetMessage('success',"User restored successfully");
              this.getUserManagementList();
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);          
          }
        },
       error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
      }
  })
}

getAssignAssets(){
  var URL = 'asset/getAssetListAndRoleCountByUser?userId='+this.selectedUserId;
  
  // $('#assignAssetTable').empty();
  if ($.fn.DataTable.isDataTable('#assignAssetTable')){
    $('#assignAssetTable').DataTable().destroy();
  };   
  this.apiService.getAll(URL).subscribe(
        data => {
            if (data.statusCode == 100) {
                this.assignAsset = data.responseList;
                         let numbers = []  
                         let total = this.assignAsset[1].length+2
                for (let index = 0; index < total; index++) {
                  const element = this.assignAsset[index];
                  numbers.push(index+1)
                }
                // this.numbers = numbers
                  this.chRef.detectChanges();
                  setTimeout(() => {
                    const table: any = $('#assignAssetTable');
                    this.dataTable = table.DataTable({"bInfo": false});
                  }, 100);
            } else {
              // if ($.fn.DataTable.isDataTable('#assignAssetTable')){
              //   $('#assignAssetTable').DataTable().destroy();
              // };
              // const table: any = $('#assignAssetTable');
              // this.dataTable = table.DataTable({"bInfo": false});
              this.assignAsset = data.responseList;
              let numbers = []  
              let total = this.assignAsset[1].length+2
              for (let index = 0; index < total; index++) {
                const element = this.assignAsset[index];
                numbers.push(index+1)
              }
              this.numbers = numbers
            }

            

        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}
/* Set User data for asset assign model */
setUserData(userData){
  this.userData = userData;
  this.UserToAsset.user.userId = userData.userId;
  this.UserToAsset.asset.assetId = "";
  this.UserToAsset.assetRole.assetRoleId = "";
  this.selectedUserId = userData.userId;
  this.numbers = [1]
  this.getRoleList();
  this.getAllAssetList(userData.userId);
  this.getAssignAssets();
}

  AddUserByAsset(){
    this.apiService.create('assetUser/addUserToAsset', this.UserToAsset).subscribe(
      data => {
        if(data.statusCode == 100){
          $("#closemodal").click();
          this.alertService.sweetMessage('success','Asset assigned Successfully');
          this.getUserManagementList();
          this.getAssignAssets();
          this.UserToAsset = {"createdTime":"","callSequence":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
        }else{

        this.alertService.sweetMessage('error',data.responseMessage);

        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      );
  }

getAllAssetList(id) {
    var URL = 'asset/getAssetListByUserAndTier?userId='+id;
    /*if (this.customerId != null && this.customerId != '') {
        var customerString = '&customerId=' + this.customerId.toString();
        var URL = URL.concat(customerString);
    }
    if (this.tierId != null && this.tierId != '') {
        var tierString = '&tierId=' + this.tierId.toString();
        var URL = URL.concat(tierString);
    }*/
    this.apiService.getAll(URL).subscribe(
        data => {
            if (data.statusCode == 100) {
                this.assetDatalist = data.responseList;
            } else {
                this.assetDatalist = [];
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}
 getRoleList(){
    this.apiService.getAll('assetRole/getAllAssetRole').subscribe(
      data => {
        if(data.statusCode == 100){
          this.roleList = data.responseList;
        }else{
          this.roleList =[];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      );
  }

DeleteAssetUser(assetdata){
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Asset User.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('assetUser/deleteAssetUser?assetId='+assetdata+'&userId='+this.selectedUserId,'').subscribe(
          data => {
            if(data.statusCode == 100){
              $("#closemodal").click();
                this.alertService.sweetMessage('success','Asset User Deleted Successfully');
                this.getAssignAssets();
                this.getAllAssetList(this.selectedUserId);
                this.getUserManagementList();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
          );
      }
    })
  }
  getAssetCallOrder(event){
    console.log(event.target.value, this.UserToAsset.asset.assetId);
    if (this.UserToAsset.asset.assetId) {
      
      this.apiService.getAll('assetUser/getCallOrderForAssetUser?assetId='+this.UserToAsset.asset.assetId).subscribe(
        data => {
          if(data.statusCode == 100){
            this.numbers = data.responseList
            this.UserToAsset.callSequence = null;
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
        );
    }
    

    
  }

getHistory(id){
  this.HistoryList = []
  this.getSessionHistory(id);
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

getSessionHistory(id){
  this.sessionHistoryList = []
  if ($.fn.DataTable.isDataTable('#sessionHistoryDatatable')) {
    $('#sessionHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserSessionHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.sessionHistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
      
}

setRolesList(roles){
  this.roleList = roles
}


  customeViewMoreModal(data) {
    // $('#HistoryModel').modal('hide');
    this.customerName = '';
    this.clientModalName = data.firstName + ' ' + (data.lastName ? data.lastName : '');
    let tempArr = []
    data.customers.map(items => {
      if (items.name) {
        tempArr.push(items.name)
      }
    });

    this.customerName = tempArr.length ? tempArr.toString() : '';
  }
  getTieToolTip(tiers){
    let str = ''
    if (tiers) {
      if (tiers.length) {
        tiers.map((obj,index)=>{
          if (index==0) {
            str += obj.tierName
          }else{
            str = str+', '+obj.tierName
          }
        })
      }
    }
    return str;
  }
}
